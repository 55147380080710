import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { SessionValidApiOutput } from '../models/api/sessionvalid-api.model';
import { UrlService } from '../../core/url.service';
import { WebSocketService } from '../../core/websocket.service';
import { FeatureService } from '../services/feature.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private userService: UserService,
    private router: Router,
    private urlService: UrlService,
    private webSocketService: WebSocketService,
    public featureService: FeatureService
  ) { }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    try {
      const sessionValidApiOutput = await this.apiService.execute<SessionValidApiOutput>('sessionvalid', {});
      this.urlService.setHostByType('news', sessionValidApiOutput.servers_news);
      const isWsProgressAllowed = await this.featureService.isAllowed('websocketProgress');
      if (isWsProgressAllowed) {
        this.webSocketService.connect();
      }
      return true;
    } catch (err) {
        this.loggerService.error('Session is not valid');
        this.loggerService.error(err);
        const return_to = (window.location.pathname || '') + (window.location.search || '');
        this.router.navigate(['/login'], { queryParams: { return_to: return_to } });
        return false;
    }
  }
}
