<ul
    id="path-item-menu"
    class="dropdown-menu show file-list-menu noselect dropdown-regular"
    style="min-width: 200px; z-index: 10000"
>
    <li ngbDropdownItem role="presentation" class="dropdown-header status">
        <span
            *ngIf="selectedParams.fileAmt === 0 && selectedParams.dirAmt == 0"
        >
            Nothing
        </span>
        <span
            *ngIf="selectedParams.fileAmt"
            [ngPlural]="selectedParams.fileAmt"
        >
            <ng-template ngPluralCase="=1">1 file </ng-template>
            <ng-template ngPluralCase="other"
                >{{ selectedParams.fileAmt }} files </ng-template
            >
        </span>
        <span *ngIf="selectedParams.fileAmt && selectedParams.dirAmt">and</span>
        <span *ngIf="selectedParams.dirAmt" [ngPlural]="selectedParams.dirAmt">
            <ng-template ngPluralCase="=1"> 1 folder</ng-template>
            <ng-template ngPluralCase="other"
                > {{ selectedParams.dirAmt }} folders </ng-template
            >
        </span>
        selected.
    </li>
    <li
        ngbDropdownItem
        role="presentation"
        class="divider"
        *ngIf="isVisible(['isNotWritable'])"
    ></li>
    <li
        ngbDropdownItem
        role="presentation"
        class="dropdown-header status"
        *ngIf="isVisible(['isNotWritable'])"
    >
        <strong>A read only folder was selected</strong>
    </li>

    <li
        ngbDropdownItem
        class="divider"
        *ngIf="
            isVisible([
                'isNotDeleted',
                'isSingle',
                'isDirs',
                'isShared',
                'isWritable'
            ]) ||
            isVisible([
                'isSingle',
                'isNotDeleted',
                'isDirs',
                'isNotShared',
                'isWritable'
            ])
        "
    ></li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible(['isNotDeleted', 'isSingle', 'isNotLink', 'isWritable' , 'hasShareLinkPermission'])
        "
    >
        <a syncDialogLink [selected]="selected" id="share-as-link-path" class="showhand tool syncblue">
            <i class="fas fa-link dropdown-shim-link"></i>
            Share as link
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible([
                'isNotDeleted',
                'isSingle',
                'isDirs',
                'isRootShare',
                'isWritable',
                'isNotHideShare'
            ])
        "
    >
        <a
            class="showhand tool syncblue"
            id="manage-team-share-path-btn"
            (click)="openShareDetailsDailog(selected, '0');"
        >
            <i class="zmdi zmdi-folder-person zmdi-hc-fw zmdi-hc-lg"></i>
            Manage team share
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible([
                'isNotDeleted',
                'isSingle',
                'isDirs',
                'isNotShared',
                'isWritable',
                'hasShareFolderPermission'
            ])
        "
    >
        <a syncDialogShareNew [selected]="selected" id="share-as-team-share-path" class="showhand syncblue">
            <i class="zmdi zmdi-folder-person zmdi-hc-fw zmdi-hc-lg"></i>
            Share as team share
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible([
                'isNotDeleted',
                'isSingle',
                'isDirs',
                'isRootShare',
                'isWritable',
                'isAppShare'
            ])
        "
    >
        <a (click)="openAppList();" class="showhand tool syncblue">
            <i class="fas fa-bolt dropdown-shim-bolt"></i>
            Manage Apps
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible([
                'hasAppsNewFeature',
                'isNotDeleted',
                'isSingle',
                'isDirs',
                'isWritable',
                'canMakeAppShare'
            ])
        "
    >
        <a (click)="openAppList();" class="showhand syncblue">
            <i class="fas fa-bolt dropdown-shim-bolt"></i>
            Connect Apps
        </a>
    </li>
    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isSingle', 'isLink', 'isWritable', 'hasShareLinkPermission'])">
        <a syncDialogLink [selected]="selected" id="manage-link-path" class="showhand tool syncblue">
            <i class="fas fa-link dropdown-shim-link"></i>
            Manage link
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="isVisible(['isNotDeleted', 'isSingle', 'isFiles', 'isViewable'])"
    >
        <a
            [syncPathActivateAction]="selected[0]"
            class="showhand tool syncblue"
        >
            <i class="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg"></i>
            Preview
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isSingle','isOpenable','isFiles'])">
        <a syncStopPropagation (click)="openFile();dropDown?.close();" class="showhand tool syncblue">
            <i class="zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg"></i>
            Open
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isFiles']) && !showRightMenu" class="">
        <a syncStopPropagation (click)="downloadCompat();dropDown?.close();" class="showhand tool syncblue">
            <i class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"></i>
            Download
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isFiles']) && showRightMenu" class="">
        <a syncQueuePathDownload class="showhand tool syncblue">
            <i class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"></i>
            Download
        </a>
    </li>
    <li ngbDropdownItem *ngIf="selectedParams.fileAmt > 1 && selectedParams.dirAmt == 0" syncIsFeatureAllowed="downloadAsZip" class="">
        <a syncStopPropagation (click)="downloadZipFile()"  class="showhand tool syncblue">
            <i class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"></i>
            Download as Zip
        </a>
    </li>


    <li ngbDropdownItem *ngIf="isVisible(['isSingle', 'isFiles', 'isWritable'])">
        <a
            syncStopPropagation
            [routerLink]="['/file', selected[0]?.sync_id, 'history']"
            class="showhand syncblue"
            (click)="dropDown?.close();"
        >
            <i class="zmdi zmdi-time-restore zmdi-hc-fw zmdi-hc-lg"></i>
            Version history
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isSingle', 'isNotDeleted', 'isWritable'])">
        <a
            syncPathRename
            syncStopPropagation
            (click)="dropDown?.close();"
            [selected]="selected"
            class="showhand tool syncblue"
            id="context-menu-rename"
        >
            <i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i>
            Rename
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isMulti', 'isWritable'])" class="" id="copy-path-btn">
        <a class="showhand syncblue" syncStopPropagation (click)="openCopyDialog('copy');dropDown?.close();">
            <i class="zmdi zmdi-copy zmdi-hc-fw zmdi-hc-lg"></i>
            Copy
        </a>
    </li>
    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isMulti', 'isWritable', 'isFiles'])" class="" id="duplicate-path-btn">
        <a class="showhand syncblue" syncStopPropagation (click)="duplicateFile();dropDown?.close();">
            <i class="zmdi zmdi-collection-plus zmdi-hc-fw zmdi-hc-lg"></i>
            Duplicate
        </a>
    </li>
    <li [ngClass]="{'disabled-element': is_tagEnabled}" *ngIf="isEnabled('add-fav') && isVisible(['isNotDeleted'])" ngbDropdownItem>
        <a class="showhand syncblue" id="add-to-starred-btn" syncStopPropagation (click)="addFavoriteTag();">
            <i class="zmdi zmdi-star zmdi-hc-fw zmdi-hc-lg"></i>
            Add to starred
        </a>
    </li>
    <li [ngClass]="{'disabled-element': is_tagEnabled}" *ngIf="isEnabled('remove-fav') && isVisible(['isNotDeleted'])" ngbDropdownItem>
        <a class="showhand syncblue" id="remove-from-starred-btn" syncStopPropagation (click)="removeFavoriteTag();">
            <i class="zmdi zmdi-star-outline zmdi-hc-fw zmdi-hc-lg"></i>
            Remove from starred
        </a>
    </li>
    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isMulti', 'isWritable'])" id="move-path-btn">
        <a class="showhand syncblue" syncStopPropagation (click)="openCopyDialog('move');dropDown?.close()">
            <i class="fas fa-arrow-right dropdown-shim-move"></i>
            Move
        </a>
    </li>
    <li
        ngbDropdownItem
        *ngIf="
            isVisible([
                'isMulti',
                'isDeleted',
                'isWritable',
                'hasPurgePermission'
            ])
        "
    >
        <a class="showhand syncblue" id="purge-file-btn" syncStopPropagation (click)="openPurgeDialog();dropDown?.close();">
            <i class="fas fa-times-circle dropdown-shim-move"></i>
            Purge
        </a>
    </li>
    <li ngbDropdownItem *ngIf="isVisible(['isNotDeleted', 'isMulti', 'isWritable'])">
        <a class="showhand syncblue" id="delete-file-btn" syncStopPropagation  (click)="openDeleteDialog();dropDown?.close();">
            <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
            Delete
        </a>
    </li>
    <li ngbDropdownItem *ngIf="isVisible(['isMulti', 'isDeleted', 'isWritable'])">
        <a class="showhand syncblue" id="restore-file-btn" syncStopPropagation (click)="openRestoreDialog();dropDown?.close();">
            <i class="fa fa-reply dropdown-shim-move"></i>
            Restore
        </a>
    </li>

    <li ngbDropdownItem *ngIf="isVisible(['isMulti', 'isInvalid'])">
        <a class="showhand" href="">
            <i class="fa-fw fa fa-exclamation-triangle fa-lg"></i>
            Select only deleted or non-deleted
        </a>
    </li>
</ul>
