<div class="modal-header">
    <button type="button" class="close" id="close-manage-link" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title ellipsis-text">
        <a class="showhand syncblue" (click)="setState(1)">
            <i class="zmdi zmdi-chevron-left zmdi-hc-lg"></i>
        </a>
        &nbsp;
        <span>Manage link - {{ link.name }}</span>
    </h4>
</div>
<div class="modal-body">
    <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="link.is_expired">
        <i class="fa fa-exclamation-triangle"></i>
        This link has expired and is no longer viewable.
    </div>

    <div class="error-general-overlay error-general-overlay-inline error-push-bottom" role="alert"
        *ngIf="link.is_download_limit">
        <i class="fa fa-exclamation-triangle"></i>
        This link has reached it's download limit and is no longer viewable.
    </div>

    <ng-container *ngIf="link.compat && (isPro && !isOnTrial)">
        <div class="row">
            <div class="col-sm-12">
                <div *ngIf="linkSendResult == 1" class="alert alert-info">
                    <i class="ion-checkmark-circled fa-lg syncblue"></i>
                    Successfully sent email
                </div>

                <sync-error [errcode]="errcode"></sync-error>

                <form [formGroup]="shareForm" method="POST" ngNativeValidate>
                    <label class="" for="">Send link via email</label>
                    <input autocomplete="off" name="email" type="email" formControlName="email"
                        placeholder="Enter email address" class="form-control" id="input-email-share" style="margin-top: 5px" />
                    <textarea *ngIf="isPro && !isOnTrial" class="form-control" name="message" rows="3"
                        style="margin-top: 10px" formControlName="message" placeholder="Message"></textarea>
                </form>
            </div>
        </div>
    </ng-container>
</div>
<!-- modal body  -->

<div class="modal-footer">
    <button type="button" class="btn btn-default pull-left" (click)="setState(1)">Back</button>
    <ng-container *ngIf="link.compat && (isPro && !isOnTrial)">
        <button type="submit" (click)="shareForm.valid && onSubmit()" id="send-email-btn" class="btn btn-primary" [disabled]="!shareForm.valid || spinner">
            <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
            Send email
        </button>
    </ng-container>
    <button type="button" class="btn btn-default" style="margin-left: 10px;" (click)="closeDialog()">Close</button>
</div>