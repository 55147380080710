import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * Filter that uppercases the first letter of a string.  It works off words,
 * so if your input string is multiple words, e.g., "sync agent" it will
 * only return `Sync agent`.  It will not capitalize any other words in the
 * input
 */

@Pipe({
    name: 'ucFirst'
})
export class UcFirstPipe implements PipeTransform {
    transform(str: string): string {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    }
}
