<ng-template #modal>
  <div syncStopPropagation>
    <div class="modal-header">
      <button type="button" class="close" (click)="close()" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
      <h4 class="modal-title">{{title}}</h4>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select="[buttons]"></ng-content>
      <button *ngIf="showCancelButton" type="button" class="btn btn-default" (click)="close()">Cancel</button>
    </div>
  </div>
</ng-template>