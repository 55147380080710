export class ShareNewCryptData {
    invite_key: string;
    enc_password: string;
    link_sharekey: ArrayLike<number>;
    link_sharekeys: {[sharekeyid: string]: string};
    link_share_key_b64: string;
    enc_share_key: string;
    salt: string;
    sharekey: ArrayLike<number>;
    iterations: number;
}
