<div class="modal-header">
  <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="activeModal.close()"
  >
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{ dlgView.title }}</h4>
</div>
<div class="modal-body">
  <div class="row">
      <div class="col-sm-12">
          <sync-error-display-hash
              [errors]="batchView.errors"
          ></sync-error-display-hash>

          Confirm that you want to {{ dlgView.action }}
          {{ dlgView.itemlist.length }} items from Sync.<br />
          <div style="margin-top: 16px" [class.hide]="batchView.spinner <= 0">
              <strong
                  *ngFor="
                      let item of dlgView.itemlist
                          | orderBy: '[type,search_name]':false
                  "
                  [class.hide]="batchView.spinner !== item.sync_id"
              >
                  Processing
                  {{ item.name }} - {{ batchView.percent }}%
              </strong>

              <br /><br />
              Do not close this window (or your web browser) until the
              operation has completed successfully.
          </div>

          <form name="confirmCheckForm" [class.hide]="!dlgView.confirm.show">
              <div class="form-group">
                  <div class="checkbox">
                      <label>
                          <input
                              type="checkbox"
                              [(ngModel)]="dlgView.confirm.check"
                              [ngModelOptions]="{standalone: true}"
                          />
                          <span [innerHtml]="dlgView.confirm.txt"></span>
                      </label>
                  </div>
              </div>
              <ngb-alert *ngFor="let c of dlgView.confirm.notices" type="c.type">
                  <strong>Warning</strong> {{ c.msg }}
              </ngb-alert>
          </form>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button
      (click)="execute()"
      id="restore-dialog-btn"
      id="restore"
      [disabled]="
          (!dlgView.confirm.check && dlgView.confirm.show) ||
          batchView.spinner
      "
      [ngClass]="dlgView.btnExecuteClass"
      class="btn btn-primary"
  >
      <i class="fa fa-spin fa-spinner" [class.hide]="!batchView.spinner"></i>
      <span [innerHtml]="dlgView.btnExecuteText"></span>
  </button>
  <button type="button" class="btn btn-default" style="margin-left: 8px;" (click)="closeDialog()">
      Cancel
  </button>
</div>
