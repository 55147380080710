import { Deserializable } from './deserializable.model';
export class Device implements Deserializable<Device> {

    cre_servtime = 0;
    del_servtime = 0;
    devicetype_name = '';
    devicetype_os_id = 0;
    id = 0;
    is_deleted = 0;
    is_desktop = 0;
    is_mobile = 0;
    is_suspended = false;
    lastaction_servtime = 0;
    name = '';
    needs_activate = 0;
    permission = {
        perCHANGEPASS: 'Change Password'
    };
    version = '';

    constructor(params?: {[k: string]: any}) {
        console.log(params);
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }

        if (this.version == '0.10.0' && this.is_mobile) {
            this.version = '1.1.0';
        }
        return this;
    }
}
