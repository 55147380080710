import { BaseApiOutput } from './base-api.model';

export class ProvisionApiOutput extends BaseApiOutput {
    user_id: number;
    device_id: number;
    sync_id: number;
    web_sync_id: number;
    share_id: number;
    share_sequence: number;
    sharetype: number;
    sso_status: string;
    referral_code: string;
    enc_share_key: string;
    device_sig_b64: string;

}

export class ProvisionApiInput implements sync.IProvisionData {
    username_b64: string;
    password: string;
    source?: string;
    signup?: sync.IProvisionSignupData;
    device?: sync.IProvisionDeviceData;
    sso?: sync.IProvisionSSOData;
    saltData?: string;
}


