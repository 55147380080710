import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BroadcastService } from '../../shared/services/broadcast.service';

@Component({
    selector: 'sync-filter-list',
    templateUrl: './filter-list.component.html'
})
export class FilterListComponent implements OnInit {
    @Input('filterToggle') public filterToggle: boolean;
    @Input('filterText') public filterText: string;
    @Output() filterToggleChange = new EventEmitter<boolean>();
    @Output() filterTextChange = new EventEmitter<string>();

    constructor(private broadcastService: BroadcastService) {}

    ngOnInit() {
        this.broadcastService.on('event:keydown:70').subscribe((evt) => this.handleSearch(evt));
        this.broadcastService.on('event:hide.tools').subscribe(() => this.resetFilter());
    }

    public resetFilter() {
        this.filterToggle = false;
        this.filterText = undefined;
        this.filterToggleChange.emit(false);
        this.filterTextChange.emit(undefined);
    }

    filterTextData(event: any) {
        this.filterTextChange.emit(event.target.value);
    }

    private handleSearch(evt: any) {
        if (evt.ctrlKey && evt.shiftKey) {
            this.filterToggle = !this.filterToggle;
            this.filterToggleChange.emit(this.filterToggle);
        }
    }
}
