<div class="modal-header">
  <button
      type="button"
      class="close"
      (click)="modalInstance.close()"
      aria-label="Close"
  >
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Add user {{ shareData.name }}</h4>
</div>
<div class="modal-body modal-body-overflow">
  <sync-verify-email-required
      *ngIf="!isVerified"
  ></sync-verify-email-required>
  <div *ngIf="isVerified">
      <sync-error-display-hash [errors]="errors"></sync-error-display-hash>
      <div
          class="alert alert-info"
          [class.hide]="!invitedEmails"
          (click)="invitedEmails = ''"
      >
          <i class="ion-checkmark-circled fa-lg syncblue"></i>
          You have successfully invited {{ invitedEmails }}
      </div>
      <sync-sharemember-add
          [(sharememberInvite)]="sharememberInvite"
          [(shareData)]="shareData"
      ></sync-sharemember-add>
  </div>
</div>
<div class="modal-footer">
  <button
      (click)="sendInvites()"
      [disabled]="!isValidInvite()"
      class="btn btn-primary"
      id="add-users-dialog-btn"
  >
      <i class="fa fa-spin fa-spinner" [class.hide]="!spinner"></i>
      Add users
  </button>
</div>
