import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Field } from '../shared/models';

@Injectable()
export class FieldControlService {
    constructor() { }

    toFormGroup(fields: Field<string | number | boolean>[]) {
        const group: any = {};

        fields.forEach(field => {
            group[field.key] = field.required ? new FormControl({ value: field.value, disabled: field.disabled }, Validators.required)
                : new FormControl({ value: field.value, disabled: field.disabled });
            field.subFields.forEach(subField => {
                group[subField.key] = subField.required ? new FormControl({ value: subField.value, disabled: subField.disabled }, Validators.required)
                    : new FormControl({ value: subField.value, disabled: subField.disabled });
            });
        });
        return new FormGroup(group);
    }
}
