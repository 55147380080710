<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Billing profile</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <span *ngIf="loading">
                <i class="fa fa-spin fa-spinner"></i>
                Loading ...
            </span>
            <span *ngIf="!loading">
                This will delete your billing profile.
                <span *ngIf="planExpiry">You will be sent a manual renewal notice on
                    {{planExpiry | date: 'longDate'}}
                </span>
            </span>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="spinner" class="btn btn-default pull-left" (click)="removeProfile()">
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Remove profile
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>

