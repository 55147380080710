import { createSelector } from 'reselect';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { environment } from '../../environments/environment';

/**
 * The compose function is one of our most handy tools. In basic terms, you give
 * it any number of functions and it returns a function. This new function
 * takes a value and chains it through every composed function, returning
 * the output.
 *
 * More: https://drboolean.gitbooks.io/mostly-adequate-guide/content/ch5.html
 */
// import { compose } from '@ngrx/store';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';

/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
// import { combineReducers } from '@ngrx/store';
// import * as fromRouter from '@ngrx/router-store';
import * as fromAuth from './auth.reducer';
import * as fromCore from './core.reducer';
import * as fromFileList from './file-list.reducer';
import * as fromLinkFileList from './link-file-list.reducer';
import * as fromLinkList from './link-list.reducer';
import * as fromUserList from './user-list.reducer';

// import * as fromLinkItem from './link-item.reducer';
// import * as fromShareList from './share-list.reducer';
// import * as fromShareNew from './share-new.reducer';

import { localStorageSync } from 'ngrx-store-localstorage';

import {  MetaReducer } from '@ngrx/store';

import { User } from '../shared/models';
import { state } from '@angular/animations';


export interface State {
    data: any;
    auth: fromAuth.State;
    core: fromCore.State;
    filelist: fromFileList.State;
    linkfilelist: fromLinkFileList.State;

    linklist: fromLinkList.State;
    userlist: fromUserList.State;
    // linkitem: fromLinkItem.State;
    // // router: fromRouter.RouterReducerState;
    // sharelist: fromShareList.State;
    // sharenew: fromShareNew.State;
}

export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    core: fromCore.reducer,
    filelist: fromFileList.reducer,
    linkfilelist: fromLinkFileList.reducer,

    // linkitem: fromLinkItem.reducer,
    linklist: fromLinkList.reducer,
    userlist: fromUserList.reducer,
    data: undefined
};

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */
export const getAuthState = (state: State) => state.auth;
export const getAuthUser = createSelector(getAuthState, fromAuth.getUser);
export const getAuthenticated = createSelector(getAuthState, fromAuth.getAuthenticated);
export const getAuthLoginProgress = createSelector(getAuthState, fromAuth.getLoginProgress);
export const getAuthError = createSelector(getAuthState, fromAuth.getErrCode);

export const getCoreState = (state: State) => state.core;
export const getSignApiData = createSelector(
    getAuthUser,
    getCoreState,
    (user: User, core: fromCore.State) => Object.assign({}, user, core)
);
export const getAccessToken = (state: State) => state.core.access_token;


export const getFileListState = (state: State) => state.filelist;
export const getFavoritsListState = (state: State) => state.filelist;

export const getFileListCwd = (state: State) => state.filelist.cwd;

export const getFileListItems = (state: State) => state.filelist.pathlist;
export const fileSelector = createSelector(getFileListItems, (state) => state);

export const getFileListError = (state: State) => state.filelist.error;
export const getFileListShowDeleted = (state: State) => state.filelist.showdeleted;
export const getFileListDeletedAmt = (state: State) => state.filelist.deletedAmt;

export const getFileListSelected = (state: State) => state.filelist.pathlist.filter((item) => item.active == true);
export const getUserListSelected = (state: State) => state.userlist.list.filter((item) => item.active == true);

export const getLinkFileListState = (state: State) => state.linkfilelist;
export const getLinkFileListCwd = (state: State) => state.linkfilelist.cwd;


export const getLinkListState = (state: State) => state.linklist;
export const getLinkListItems = (state: State) => state.linklist.items;

// export const getLinkItem = (state: State) => state.linkitem.item;

// export const getShareListState = (state: State) => state.sharelist;



export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: ['core', 'auth'],
        rehydrate: true,
        storageKeySerializer: (key) => 'sync_' + key
    })(reducer);
  }
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
