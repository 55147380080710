import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User, UserInfoSetApiOutput, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';

@Component({
  selector: 'sync-dialog-preview-preference',
  templateUrl: './dialog-preview-preference.component.html'
})
export class DialogPreviewPreferenceComponent implements OnInit {

  @Input() user: User;

  public is_office: string;
  public is_optin: string;
  public is_office_personal: string;
  public spinner: boolean;
  public errcode: ErrCode;

  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.spinner = false;
    this.errcode = undefined;
    this.is_office = this.user.is_office ? '1' : '0';
    this.is_optin = this.user.is_displayedofficeoptin ? '1' : '0';
    this.is_office_personal = this.user.is_office_personal ? '1' : '0';
  }

  public async save() {
    this.spinner = true;

    try {
      await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
        is_office: parseInt(this.is_office, 10),
        is_displayedofficeoptin: 1,
        is_office_personal: parseInt(this.is_office_personal, 10),
      });
      this.userService.refresh();
    } catch (ex) {
      this.errcode = ErrCode.fromException(ex);
    }
    this.spinner = false;
  }

  public async reset() {
    this.spinner = true;

    try {
      await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
        is_office: 0,
        is_displayedofficeoptin: 0,
        is_office_personal: 0,
      });
      this.userService.refresh();
    } catch (ex) {
      this.errcode = ErrCode.fromException(ex);
    }
    this.spinner = false;
    this.activeModal.close();

  }
}
