import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { DirtyOutService } from '../core/crypt/dirty-out.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { BatchActionService } from './batch-action.service';
import { BatchMoveService } from './batch-move.service';

@Injectable({
    providedIn: 'root',
})
export class BatchRenameService extends BatchActionService {
    constructor(
        public broadcastService: BroadcastService,
        public loggerService: LoggerService,
        public syncCryptService: SyncCryptService,
        public notificationsService: NotificationsService,
        public apiService: ApiService,
        public dirtyOutService: DirtyOutService,
        public batchMoveService: BatchMoveService
    ) {
        super(
            broadcastService,
            loggerService,
            syncCryptService,
            notificationsService,
            apiService
        );
    }

    /**
     * @ngdoc method
     * @name  execute
     * @methodOf sync.service:BatchRename
     * @description
     * Renames a given sync id to the new pid location recursively
     * @param  {Integer} syncID    The sync id to rename
     * @param  {Integer} syncPid   The destination sync pid
     * @param  {String} folderName The new name for the item
     * @return {Promise}           Resolved on completion of the rename
     */
    public async execute(
        syncId: number,
        syncPid: number,
        folderName: string
    ): Promise<any> {
        let nameData: sync.INameData;
        this.loggerService.info(
            `batchRename(${syncId}, ${syncPid}, ${folderName})`
        );
        this.view.globalCancel = false;
        this.ACTION = this.ACT_RENAME_RECURSIVE;

        try {
            const keyArray = await this.dirtyOutService.executeForSync(
                syncPid,
                folderName
            );
            try {
                const enc_name = await this.syncCryptService.filenameEncrypt(
                    folderName
                );
                nameData = {
                    share_names: keyArray,
                    new_name: enc_name,
                };
                return await this.batchMoveService._moveFolder(
                    [syncId],
                    syncPid,
                    nameData
                );
            } catch (err) {
                this.loggerService.handleError(
                    'batchRename.filenameEncrypt',
                    err
                );
            }
        } catch (errData) {
            this.loggerService.handleError(
                'batchRename.executeForSync',
                errData
            );
        }
    }

    /**
     * @ngdoc method
     * @name  executeFile
     * @methodOf sync.service:BatchRename
     * @description
     * Renames a given sync id to the new pid location for a file only
     * @param  {Integer} syncID    The sync id to rename
     * @param  {Integer} syncPid   The destination sync pid
     * @param  {String} newName The new name for the item
     * @return {Promise}           Resolved on completion of the rename
     */
    public async executeFile(
        syncId: number,
        syncPid: number,
        newName: string
    ): Promise<any> {
        this.loggerService.info(
            `batchRenameFile(${syncId}, ${syncPid}, ${newName})`
        );
        this.view.globalCancel = false;
        this.ACTION = this.ACT_RENAME_SINGLE;

        try {
            const keyArray = await this.dirtyOutService.executeForSync(
                syncPid,
                newName
            );
            for (let i = 0, len = keyArray.length; i < len; i++) {
                keyArray[i].action = 'rename';
            }
            try {
                const encName = await this.syncCryptService.filenameEncrypt(
                    newName
                );
                const data = await this.apiService.execute('pathrename', {
                    sync_id: syncId,
                    new_name: encName,
                    share_names: keyArray,
                });
                return data;
            } catch (err) {
                this.loggerService.handleError(
                    'batchRename.filenameEncrypt',
                    err
                );
            }
        } catch (errData) {
            this.loggerService.handleError('batchRename.executeFile', errData);
        }
    }
}
