
import { Deserializable } from './deserializable.model';
import { ShareListMember } from './share-list-member.model';

export class AvatarUser extends ShareListMember implements Deserializable<AvatarUser> {
  uniqid: number;
  userid: number;
  email: string;
  displayname: string;
  decodedDisplayName: string;
  status: string;
  pubkey_id: number;
  pubkey: string;
  share_id: number;
  permissions: string[];
  disabled: boolean;


  public deserialize(input): AvatarUser {
    Object.assign(this, input);
    return this;
  }

}
