import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LinkListItem, PermissionActions, ErrCode, LinkPreCreateOptions } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FileItemService } from '../../file/file-item.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { LinkListService } from '../services/link-list.service';

@Component({
    selector: 'sync-link-precreate-settings',
    templateUrl: './link-precreate-settings.component.html',
})
export class LinkPrecreateSettingsComponent implements OnInit {
    @Input() openState: number;
    @Input() item: LinkListItem | sync.IFile;
    @Input() linkPrecreateOptions: LinkPreCreateOptions;
    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();
    @Output() linkPrecreateOptionsState = new EventEmitter<LinkPreCreateOptions>();
    public errcode: ErrCode;
    public expDate: NgbDateStruct;
    public expDateMin: NgbDateStruct;

    linkPreSettingsForm: FormGroup;
    submitted = false;
    public isMSOfficeFile = false;

    public linkEnforcedSettings = {
        isLinkExpiryDateRequired: false,
        linkExpiryDateDefault: null,
        isLinkPasswordRequired: false,
        linkPasswordDefault: null
    };
    public isPro = false;

    constructor(
        private userService: UserService,
        private formBuilder: FormBuilder,
        private fileItemService: FileItemService,
        private base64: Base64Service,
        private log: LoggerService,
        private linklistService: LinkListService
    ) { }

    ngOnInit() {
        const dt = new Date();
        this.expDateMin = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
        };
        this.isPro = this.userService.get('is_pro');

        this.isMSOfficeFile = this.fileItemService.isMSOffice(this.item as sync.IFile, 'edit');

        this.linkEnforcedSettings = this.linklistService.getLinkEnforcedSettings();

        this.linkPreSettingsForm = this.formBuilder.group({
            password: [''],
            expiryDate: [null],
            expiryTime: [null],
            upload: ['0', Validators.required],
            previewonly: [
                'download',
                Validators.required,
            ],
        });

        this.linkPreSettingsForm.controls.upload.valueChanges.subscribe((uploadFieldValue) => {
            // file permission radio buttons are disbled according to the upload radio values
            // reset previewonly field value when all file permission radio buttons are disabled
            // or when the edit file radio button is disabled
            if (this.item.type === 'dir') {
                if (uploadFieldValue === '2' || (uploadFieldValue === '0' && this.linkPreSettingsForm.value.previewonly === 'edit')) {
                    this.linkPreSettingsForm.controls.previewonly.setValue('download');
                }
            }
        });

        if (this.linkEnforcedSettings.isLinkExpiryDateRequired) {
            this.linkPreSettingsForm.get('expiryDate').setValidators(Validators.required);
        }

        if (this.linkEnforcedSettings.linkExpiryDateDefault) {
            const today = new Date();
            const currDt = new Date(new Date().setDate(today.getDate() + this.linkEnforcedSettings.linkExpiryDateDefault)); // adjusting according to default value
            this.linkPreSettingsForm.get('expiryDate').setValue({
                year: currDt.getFullYear(),
                month: currDt.getMonth() + 1,
                day: currDt.getDate()
            });
            this.onDateSelection();
        }

        if (this.linkEnforcedSettings.isLinkPasswordRequired) {
            this.linkPreSettingsForm.get('password').setValidators(Validators.required);
        }
    }

    public onDateSelection() {
        const dt = new Date();
        this.linkPreSettingsForm.get('expiryTime').setValue({
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: 0,
        });
    }

    public clearExp() {
        this.linkPreSettingsForm.get('expiryDate').setValue(null);
        this.linkPreSettingsForm.get('expiryTime').setValue(null);
    }

    public async onSubmit() {
        try {
            this.errcode = null;

            const expDate = this.linkPreSettingsForm.get('expiryDate').value;
            let expTime = this.linkPreSettingsForm.get('expiryTime').value;
            let expServtime;
            if (expDate) {
                if (!expTime) {
                    const exp = new Date();
                    expTime = {
                        hour: exp.getHours(),
                        minute: exp.getMinutes(),
                        second: 0,
                    };
                }
                const dt = new Date(
                    expDate.year,
                    expDate.month - 1,
                    expDate.day,
                    expTime.hour,
                    expTime.minute,
                    expTime.second
                );
                expServtime = dt.getTime();
            }

            let uploadVal = parseInt(this.linkPreSettingsForm.get('upload').value, 10);
            if (this.item.type === 'file') {
                uploadVal = this.linkPreSettingsForm.value.previewonly === 'edit' ? 1 : 0;
            }
            this.linkPrecreateOptionsState.emit({
                exp_servtime: parseInt(expServtime, 10),
                // if preview only option is selected from file permissions, set previewonly in link options
                previewonly: this.linkPreSettingsForm.value.previewonly === 'preview' ? 1 : 0,

                // if edit option is selected from file permissions, set file_edit in link options
                // this will set the lnkFILEEDIT in LinkFeatures
                file_edit: this.linkPreSettingsForm.value.previewonly === 'edit' ? 1 : 0,
                password: this.base64.encode(
                    this.linkPreSettingsForm.get('password').value
                ),
                upload: uploadVal,
            });
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public onState(state: number) {
        this.state.emit(state);
    }
}
