<div>
    <div *ngIf="saveFile?.renderFile?.savemethod == 'dlattr'">
        <a
            id="{{ saveFile.uniqid }}"
            (click)="saveDlAttr()"
            download="{{ saveFile.filename }}"
            [href]="bypassSecurityTrustUrl(saveFile?.renderFile?.url)"
            class="syncwhite btn btn-primary btn-xs"
            >Download <i class="fa fa-arrow-down"></i
        ></a>
    </div>
    <div *ngIf="saveFile?.renderFile?.savemethod == 'msblob'">
        <a
            id="{{ saveFile.uniqid }}"
            (click)="msBlobSave(saveFile.renderFile.msblob, saveFile.filename)"
            class="syncwhite btn btn-primary btn-xs"
            >Download <i class="fa fa-arrow-down"></i
        ></a>
    </div>
</div>
