import { ShareKeyData} from './share-key-data.model';
import { PubkeyData } from './pubkey-data.model';
export class ShareListInactiveItem {
    enc_name: string;
    name: string;
    share_id: number;
    invite_status: string;
    cachekey: string;
    uniqid: number;
    sync_id: number;

    pubkeys?: PubkeyData[];
    enc_share_keys?: ShareKeyData[];
    share_key_id?: string;
    enc_share_name?: string;
    label: string;
}
