import { Deserializable } from './deserializable.model';
import { UserRewardItem } from './user-reward-item.model';

export class UserRewards implements Deserializable<UserRewards> {
    total = 0;
    count = 0;
    is_referred = false;
    referral_code = '';
    list: UserRewardItem[] = [];
    from_sync: UserRewardItem[] = [];
    last_reward_date = 0;
    last_reward_amt = 0;


    constructor(params?: {[k: string]: any}) {
        if (params) {
            return this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

