import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { ProvisionWorkerService } from './provision-worker.service';
import { EmailExistsApiOutput, ProvisionApiOutput, NewOrderResponse } from '../../shared/models/api';
import { ErrCode } from '../../shared/models';
import { delay, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, concat, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProvisionService {
    private orderspath = environment.ordershost;
    private cphost = environment.cphost;

    constructor(
        private httpClient: HttpClient,
        private api: ApiService,
        private base64: Base64Service,
        private log: LoggerService,
        private worker: ProvisionWorkerService
    ) { }

    public async emailExists(email: string) {
        try {
            const exists = await this.api.send<EmailExistsApiOutput>('emailexists', {
                email_b64: this.base64.encode(this.sanitizeEmail(email))
            });
            return (exists.exists == 1);
        } catch (ex) {
            this.log.error('An error occurred checking emailexists, return false');
            return false;
        }
    }

    public async provision(source: string,
                           username: string,
                           password: string,
                           referCode: string,
                           reset: number,
                           sso: number
    ) {
        username = this.sanitizeEmail(username);
        const exists = await this.emailExists(username);
        if (!exists) {
            let provisionData: sync.IProvisionData;
            try {
                provisionData = await this.mkData(username, password, reset, sso);
            } catch (ex) {
                throw new ErrCode(8106);
            }
            if (!provisionData) {
                throw new ErrCode(8106);
            }
            provisionData.source = source;
            provisionData.signup.referral_code = referCode;

            const result = await this.api.send<ProvisionApiOutput>('provision2', provisionData);
            this.log.info('Successful provision ' + username + ' with id: ' + result.user_id);
            return result;
        } else {
            throw new ErrCode(8102);
        }

    }

    public async mkData(username: string, password: string, passReset: number, sso: number): Promise<sync.IProvisionData> {
        const data = await this.worker.execute(username, password, passReset, sso);
        this.worker.completed();
        return data;
    }

    public validEmail(email: string) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
    }

    private sanitizeEmail(email: string) {
        return email.toLowerCase().trim();
    }

    /**
     * We have created the order when placeholder create for legacy to team+ migration.
     * @param acctkey string
     * @returns
     */
    async createNewOrderForMigration(acctkey: string) {
        try {
            //api call to Zporxy server, To create new orders.
            const result = await this.api.send<NewOrderResponse>('neworderformigration', { acctkey: acctkey });
            return (result.success == 1);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

     /**
     * Since provision tends to have a large payload such as encrypted keys, we don't need to JWT
     * token sign them.  Provision is traditionally a public API so this will ensure that sending
     * this call without JWT authentication.
     *
     * If this becomes JWT authenticated, the request headers end up containing the entire request
     * which can (given other headers) result in the server rejecting the connection due to
     * "Request Headers Too Large".
     *
     * The only real difference between this and "post()" function is that we do not sign via the
     * getHeaders() function.
     */
     async handleAcceptedInviteConsent<T>(
        params: sync.IProvisionData,
        retryEnabled = true
    ): Promise<T> {
        const url = `${this.orderspath}/api/v1/accounts/provision`;
        return await this.httpClient.post<T>(url, params)
            .pipe(
                map((result) => this.success<T>(result, url)),
                retryWhen((errors) => this.retry(errors, params, retryEnabled))
            )
            .toPromise();
    }

    /**
     * Account tranfer then we call on success.
     */
    private success<T>(result, url): T {
        if (result.success === false) {
            this.handleError(result);
        } else if (result.success === true) {
            this.log.info(`200 ${url}  successful`);
            return result as T;
        } else {
            this.log.warn(`Received a response without a success param for ${url}`);
            return result as T;
        }
    }
    handleError(result: any) {
        throw new Error('Method not implemented.');
    }

    /**
     * Facing error on retry call.
     * @param errors
     * @param params
     * @param retryEnabled
     * @returns
     */
    private retry(errors: Observable<any>, params, retryEnabled) {
        return errors.pipe(
            delay(1000),
            take(3),
            switchMap((httpErr) => {
                if ((httpErr instanceof HttpErrorResponse) && retryEnabled === true) {
                    let shouldRetry = of(httpErr) ; // observableThrowError(httpErr);
                    this.log.error(`ERROR INPUT: ${JSON.stringify(params)}`);
                    this.log.error(`ERROR OUTPUT: ${httpErr.status} ${httpErr.statusText} :: ${httpErr.url}`);
                    // this.log.error(`Error output ${JSON.stringify(response)}`);

                    switch (httpErr.status) {
                        case 401:
                        case 400:
                        case 429:
                        case 500:
                            // do not retry, this is an error
                            shouldRetry = observableThrowError(httpErr);
                            break;
                        case 501:
                        case 502:
                        case 503:
                        case 504:
                            // this may be a temporary error
                            // shouldRetry = observableThrowError(httpErr);
                            break;
                        default:
                            // default is to retry
                            shouldRetry = observableThrowError(httpErr);
                            break;
                    }
                    return shouldRetry;
                } else {
                    // httpErr is an API result
                    return observableThrowError(httpErr);
                }
            }),
            (o) => concat(o, observableThrowError(new Error('90002')))
        );
    }

    /**
     * We have add not in SPAIN when user declined to invitation.
     * @param userId number;
     * @returns boolean;
     */
    async addDeclinedNoteInSPIN(userId: number) {
        try {
            //api call to Zporxy server, To create new orders.
            const result = await this.api.send<NewOrderResponse>('useraddnote', {user_id: userId, action: 'declined', });
            return (result.success == 1);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }
}
