import { BaseApiOutput } from './base-api.model';

export class ShareMailInviteApiOutput extends BaseApiOutput {
}

export class ShareMailInviteApiInput {
    share_id: number;
    emaillist: string[];
    pm_b64?: string;
    enc_password?: string;
}


