
<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-cog"></i> Password reset</h4>
</div>

<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p>
                <i class="fa fa-exclamation-triangle fa-lg"></i> <strong>Important:</strong>
            </p>
            <p>
                By default, Sync does not provide a password reset option, due to the
                zero-knowledge nature of our storage platform. This means that if you've
                forgotten your password you could get locked out of your account
                permanently.
            </p>
            <p>
                Enabling this feature allows you to reset a forgotten password, ensuring
                that you cannot get locked out of your Sync account. However, doing so
                gives Sync temporary access to your encryption keys when the feature is
                enabled or used.
            </p>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">Set status:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="status"
                        id="passreset-enabled"
                        value="1"
                        [(ngModel)]="status"
                    /> Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="status"
                        id="passreset-disabled"
                        value="0"
                        [(ngModel)]="status"
                    /> Disabled
                </label>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">Your current password:</div>
        <div class="col-sm-8">
            <input
                [disabled]="user.is_resetpassword.toString() == status"
                id="passreset-curpass"
                class="form-control"
                name="curPass"
                type="password"
                required
                placeholder="Enter your password"
                [(ngModel)]="curPass"
                syncShowHidePassword
                identity="dialogresetpass"
            >
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        [disabled]="spinner || !curPass || status == user.is_resetpassword.toString()"
        id="resetpass-save"
        type="button"
        class="btn btn-primary"
        (click)="save()"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        {{(user.is_resetpassword) ? 'Update' : 'Save'}}
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>
