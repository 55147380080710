
<div class="container-fluid container-fluid-wrap">


  <!-- success -->
 <div class="error-general-overlay" [hidden]="!success">
 <div class="row" >
     <div class="col-xs-9" >

         <i class="zmdi zmdi-check-circle zmdi-hc-lg"></i>
         Successfully restored version

     </div>
     <div class="col-xs-3 text-right">
         <span class="showhand" (click)="dismiss()">&times;</span>
     </div>

 </div>
 </div>




 <sync-error-display-hash [errors]="alerts"></sync-error-display-hash>

 <div class="row" *ngIf="initialized">
     <div class="col-sm-12 affix header-box-withmenu">
         <div class="header-title">
             <strong>{{currentItem.name}}</strong> - Version history
         </div>
         <table class="table list-table list-table-header">
             <thead>
                 <tr>
                 <th class="table-filename">Name</th>
                 <th class="table-preview"></th>
                 <th class="table-date">Date <span class="caret"></span></th>
                 <th class="table-size hidden-sm hidden-xs">Size</th>
                 <th class="table-actions"></th>
                 </tr>
             </thead>
         </table>
     </div> <!-- col-sm-12 affix -->

 </div> <!-- row -->
 <div id="upload-modal"></div>

 <div class="row">
     <div class="col-sm-12 content-box-withmenu">
         <table class="table list-table">
             <tbody>
             <tr *ngFor="let e of events |
                 orderBy:predicate:reverse; let first = first"
             >
                 <td class="table-preview">
                     <img (click)="download(e)" src="/images/icons/{{currentItem.cssclass}}.svg" />
                 </td>
                 <td class="table-filename">
                     <div class="tdmore">
                         <a (click)="download(e)">
                             {{currentItem.name}}
                         </a>
                         <br />
                     </div>
                     <div class="tdmore subtext">
                         {{e.action_text}} by {{e.event_username| b64}}
                         <span *ngIf="e.event_device_name | b64">({{e.event_device_name | b64}})</span>
                     </div>
                 </td>
                 <td class="table-date">
                     <div class="tdmore">{{e.date | date:'short'}}</div>

                 </td>
                 <td class="table-size hidden-sm hidden-xs">
                     <div class="tdmore">{{e.size | bytesToSize}}</div>

                 </td>
                 <td class="table-actions">
                     <div *ngIf="isReadOnly">--</div>
                     <div *ngIf="!isReadOnly">
                         <button
                             class="btn btn-default"
                             *ngIf="first"
                             disabled
                         >
                             Current<span class="hidden-xs hidden-sm"> version</span>
                         </button>

                         <button
                             *ngIf="!first && e.blob_id != currentItem.blob_id"
                             (click)="restoreVersion(e.blob_id)"
                             class="btn btn-default"
                         >Restore</button>

                         <div class="subtext" *ngIf="!first && e.blob_id == currentItem.blob_id">
                         Same as current
                         </div>

                     </div>
                 </td>
              </tr>
             </tbody>
         </table>
         <div class="table-pagination text-center">
             <button class="btn btn-default btn-lg" *ngIf="hasNextPage" (click)="getHistory()">
                 <i class="fa fa-spin fa-spinner" [class.hide]="isLoaded"></i>
                 <span [hidden]="!isLoaded">Load more ...</span>
                 <span [hidden]="isLoaded">Loading ...</span>
             </button>
             <span *ngIf="!hasNextPage">No more versions to load</span>
         </div>
     </div> <!-- content box -->
 </div><!-- row-->
</div> <!-- /container -->
