import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[syncStopPropagation]',
})
export class StopPropagationDirective {
    constructor() {}

    @HostListener('click', ['$event']) click(event) {
        event.stopPropagation();
    }
}
