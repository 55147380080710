import { BaseApiOutput, BaseApiInput } from './base-api.model';
import { Session } from '../session.model';

export class UserSessionListApiOutput extends BaseApiOutput {
    sessions: Session[];

}

export class UserSessionListApiInput extends BaseApiInput {
}


