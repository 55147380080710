import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { BrowserSupportService } from '../../core/browser-support.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { LoggerService } from '../../core/logger.service';
import { NotificationsService } from '../../core/notifications.service';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { UrlService } from '../../core/url.service';
import { UserService } from '../../core/user.service';
import { ValidateService } from '../../core/validate.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { compare } from '../../shared/func';
import { BaseApiInput, BlendEvent } from '../../shared/models';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { TransferQueueType, TransferStatus, ValidUploadStatus, ValidDownloadStatus } from '../transfer.model';
import { DownloadService } from './download.service';
import { TransferItemService } from './transfer-item.service';
import { streamItem } from 'sync';
import { FileUploader } from '../file-uploader';

@Injectable({
    providedIn: 'root',
})
export class BuildTransferItemService {
    public view: sync.ITransferView;
    public ACT_DOWNLOAD = 1;
    public ACT_PREVIEW = 2;
    public ACT_STREAM_PDF = 3;
    public ACT_STREAM_DOC = 4;

    private cwd: sync.IFile;
    private linkCwd: sync.IFile;

    private MAX_ACTIVE_UPLOADS = 3;
    private MAX_ACTIVE_DOWNLOADS = 1;

    constructor(
        private notificationsService: NotificationsService,
        private downloadService: DownloadService,
        private fileListService: FileListService,
        private browserSupportService: BrowserSupportService,
        private syncCookieService: SyncCookieService,
        private transferItemService: TransferItemService,
        private validateService: ValidateService,
        private base64Service: Base64Service,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private urlService: UrlService,
        private userService: UserService,
        private apiService: ApiService,
        private linkFileListService: LinkFileListService,
        private broadcastService: BroadcastService,
        private injector: Injector
    ) {
        this.view = {
            download: {
                queue: [],
                queueIdx: 0,
                isProcessing: false,
                activeItemCount: 0,
            },
            upload: {
                queue: [],
                queueIdx: 0,
                isProcessing: false,
                activeItemCount: 0
            },
            single: undefined,
            isUpload: false,
            isDownload: false,
            enabled: this.browserSupportService.testXhr2(),
            pending: 0,
            cannotViewSave: false,
            transferLabel: 'Upload',
        };
        this.fileListService.getCwdSubscription().subscribe((data) => {
            if (data) {
                this.cwd = data;
            }
        });

        this.linkFileListService.getCwdSubscription().subscribe((data) => {
            if (data) {
                this.linkCwd = data;
            }
        });

        window.onbeforeunload = (evt) => {
            const message = 'There is unfinished upload/download pending.';
            if (typeof evt == 'undefined') {
                evt = window.event;
            }
            if (this.view.download.isProcessing || this.view.upload.isProcessing) {
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
        };
    }

    public async addToQueue(results: sync.ITransferItem[], queueType: string): Promise<any> {
        const view = this.view[queueType];
        if (results.length) {
            view.queue = view.queue.concat(results);
            view.queue.sort((a, b) =>
                compare(a._initTime, b._initTime, true)
            );
        }
        setTimeout(() => this.runQueue(queueType), 0);
        return true;
    }

    private shouldProcessQueue(queueType: string): boolean {
        const view: sync.ITransferQueueState = this.view[queueType];
        const maxActiveItems = queueType === TransferQueueType.UPLOAD ? this.MAX_ACTIVE_UPLOADS : this.MAX_ACTIVE_DOWNLOADS;
        return view.activeItemCount < maxActiveItems;
    }

    private shouldStopQueue(queueType: string): boolean {
        const view: sync.ITransferQueueState = this.view[queueType];
        return view.activeItemCount === 0;
    }

    public async runQueue(queueType: string) {
        const view: sync.ITransferQueueState = this.view[queueType];
        if (view.queue.length) {
            const next = this.getLatestItem(view.queue);
            this.view.pending = next.pending;
            if (next.idx > -1 && this.shouldProcessQueue(queueType)) {
                this.notificationsService.stopNotificationLoop();
                view.isProcessing = true;
                view.queueIdx = next.idx;
                const tItem = view.queue[next.idx];
                if (tItem) {
                    this.view.transferLabel = 'Transferring';
                    if (tItem.type == this.transferItemService.TYPE_UPLOAD) {
                        this.view.isUpload = true;
                        let fileUploader = new FileUploader(this.injector);

                        fileUploader.uploadItem(<sync.ITransferItemUpload>tItem)
                            .then(() => {
                                fileUploader = null;
                                this.runQueue(queueType);
                            })
                            .catch(error => {
                                this.handleError(error, tItem);
                                fileUploader = null;
                                this.runQueue(queueType);
                            });
                    } else {
                        try {
                            this.view.isDownload = true;
                            await this.downloadService.downloadItem(
                                <sync.ITransferItemDownload>tItem
                            );
                        } catch (error) {
                            this.handleError(error, tItem);
                        }
                    }
                    this.runQueue(queueType);
                }
            } else if (this.shouldStopQueue(queueType)) {
                this.notificationsService.startNotificationLoop();
                view.isProcessing = false;
                if (queueType === TransferQueueType.UPLOAD) {
                    this.view.isUpload = false;
                } else if (queueType === TransferQueueType.DOWNLOAD) {
                    this.view.isDownload = false;
                }
                if (!this.view.download.isProcessing && !this.view.upload.isProcessing) { this.view.transferLabel = 'Upload'; }
                if (this.userService.isAuthenticated()) {
                    this.broadcastService.broadcast('event:file-list.reload');
                }
                this.linkFileListService.reload();
            }
        }
    }

    private handleError(data: any, tItem: sync.ITransferItem) {
        if (
            typeof data === 'object' &&
            data.errors &&
            data.errors.length
        ) {
            tItem.status =
                data.errors[0].error_code ||
                TransferStatus.STATUS_ERROR;
            this.loggerService.error(data.errors[0].error_msg);
        } else if (data.errcode) {
            tItem.status = data.errcode;
        } else if (data.code) {
            tItem.status = data.code;
        } else {
            tItem.status = TransferStatus.STATUS_ERROR;
        }
        this.loggerService.error(
            [
                'Transfer encountered an error',
                ' type: ',
                tItem.type,
                ' status: ',
                tItem.status,
                ' size: ',
                tItem.filesize,
            ].join('')
        );
    }

    public getLatestItem(
        items: sync.ITransferItem[]
    ): sync.ITransferLatestItem {
        let idx = -1,
            pendingBytes = 0,
            pending = 0,
            activeUploadsCount = 0,
            activeDownloadsCount = 0;
        for (let i = 0, len = items.length; i < len; i++) {
            if (items[i].status == TransferStatus.STATUS_WAITING) {
                if (idx === -1) {
                    idx = i;
                }
                pending++;
                pendingBytes += items[i].filesize;
            }
            if (items[i].type === this.transferItemService.TYPE_UPLOAD && ValidUploadStatus.includes(items[i].status)) {
                activeUploadsCount++;
            }
            if (items[i].type === this.transferItemService.TYPE_DOWNLOAD && ValidDownloadStatus.includes(items[i].status)) {
                activeDownloadsCount++;
            }
        }
        this.view.upload.activeItemCount = activeUploadsCount;
        this.view.download.activeItemCount = activeDownloadsCount;
        return {
            pending: pending,
            pendingBytes: pendingBytes,
            idx: idx,
        };
    }

    public async getCompatUrl(
        file: sync.IFile,
        action: number
    ): Promise<string> {
        const keyData = await this.getFileKeys([file]);
        const tItem = await this.mkDownloadItem(file, keyData);
        const url = await this.mkCompatUrl(tItem, action);
        if (action == this.ACT_DOWNLOAD) {
            this.broadcastService.broadcast('sync.track.blend', {
                eventName: BlendEvent.DOWNLOAD_FILE,
                parameters: {
                    isCompat: 'Yes',
                    fileSize : file.filesize,
                    mimeType: file.mime_type
                }
            });
        }
        return url;
    }

    /**
     * Gets the data and share keys for an array of files
     * @param files
     */
     public getFileKeys(files: sync.IFile[]): Promise<sync.IPathData> {
         const needKeys: sync.ITransferNeedKeys[] = [];
         let isLink = false;
        for (let i = 0, len = files.length; i < len; i++) {
            if (files[i].share_id && files[i].sync_id && files[i].blob_id) {
                const ext = files[i].name
                    .substr(files[i].name.lastIndexOf('.') + 1)
                    .toLowerCase();
                needKeys.push({
                    share_id: files[i].share_id,
                    blob_id: files[i].blob_id,
                    sync_id: files[i].sync_id,
                    ext: this.base64Service.encode(ext),
                    link_cachekey: files[i].linkID,
                    size: files[i].size,
                    user_id: this.userService.get('id'),
                });
            }
            if (files[i].linkID && isLink === false) {
                isLink = true;
            }
        }
         const pubkey = !isLink ? this.userService.get('pubkey_id') : undefined;
        return this.apiService.execute<any>('pathdata', {
            pathitems: needKeys,
            pubkey: pubkey
        });
    }

    public async mkDownloadItem(
        file: sync.IFile,
        keyData: any
    ): Promise<sync.ITransferItemDownload> {
        let curDataKey,
            enc_data_key,
            enc_share_key,
            previewtoken;

        // TODO: This is a hack when we don't have the keydata in the Webpanel
        // TODO: Please sign off on this Richard.
        if (keyData) {
            curDataKey = keyData.datakeys[file.sync_id];
            enc_data_key = curDataKey.enc_data_key;
            enc_share_key = keyData.sharekeys[curDataKey.share_key_id];
            previewtoken = keyData.previewtoken || undefined;
        } else {
            curDataKey = {
                share_key_id: undefined,
                share_id: undefined,
                share_sequence: undefined,
            };
        }
        const result = await this.transferItemService.get({
            context: file.context,
            type: this.transferItemService.TYPE_DOWNLOAD,
            blob_id: file.blob_id,
            blobtype: file.blobtype || 'btFILE',
            filename: file.name,
            name: file.name,
            filesize: file.size,
            filetype: file.filetype,
            mimetype: file.filetype.mimetype,
            filedate: file.date,
            cachekey: file.cachekey,
            link_is_pro: file.link_is_pro || 0,
            link_owner_id: file.link_owner_id || 0,
            linkID: file.linkID || undefined,
            linkpasswordlock: file.linkpasswordlock || undefined,
            previewtoken: previewtoken,
            sync_id: file.sync_id,
            enc_data_key: enc_data_key,
            enc_share_key: enc_share_key,
            share_key: file.share_key || undefined,
            share_key_id: curDataKey.share_key_id,
            share_id: curDataKey.share_id,
            share_sequence: curDataKey.share_sequence,
            enc_name: file.enc_name,
            status: TransferStatus.STATUS_WAITING,
        }).catch((err) => {
            this.loggerService.error('An error occurred creating download item');
            throw err;
        });
        return <sync.ITransferItemDownload>result;

    }

    public async mkuploadItem(fileObj: File): Promise<sync.ITransferItemUpload> {
        let itemStatus = 0;
        if (!fileObj) {
            this.loggerService.warn('An empty file object was passed to mkuploadItem');
            itemStatus = TransferStatus.STATUS_ERR_NAME;
        }
        // if (typeof fileObj.webkitGetAsEntry == 'function') {
        //     let item = fileObj.webkitGetAsEntry();
        //     if (item && item.isDirectory) {
        //         this.loggerService.warn(item.name + ' directories are not supported');
        //         itemStatus = 7200; // dir not supported
        //     }
        // }
        const cwd = this.cwd ? this.cwd.sync_id : 0;
        // safari does not use 'lastModifiedDate' so if undefined, set the
        // date to now.
        const filedate = fileObj.lastModified || Date.now();

        const tItem = await this.transferItemService.get({
            type: this.transferItemService.TYPE_UPLOAD,
            sync_pid: cwd,
            filename: fileObj.name.trim() || '',
            filesize: fileObj.size || 0,
            fileobj: fileObj,
            status: itemStatus,
            filedate: new Date(filedate).getTime(),
        }).catch((err) => { throw err; });
        if (!this.validateService.isNameValid(tItem.filename)) {
            this.loggerService.warn(
                'File name ' +
                    tItem.filename +
                    ' is not allowed to be uploaded'
            );
            tItem.status = TransferStatus.STATUS_ERR_NAME;
        }
        return <sync.ITransferItemUpload>tItem;
    }

    public async mkPublicUploadItem(
        fileObj: File
    ): Promise<sync.ITransferItemUpload> {
        const tItem = await this.mkuploadItem(fileObj).catch((err) => { throw err; });
        const cwd = this.linkCwd;
        tItem.filename = this.linkFileListService.getValidFileName(
            tItem.filename
        );
        tItem.share_id = cwd.share_id;
        tItem.share_key = cwd.share_key;
        tItem.share_sequence = cwd.share_sequence;
        tItem.share_key_id = cwd.share_key_id;
        tItem.sync_pid = cwd.sync_id;
        tItem.linkID = cwd.linkID;

        const encShareName = await this.linkFileListService.encName(tItem.filename).catch((err) => { throw err; });
        tItem.enc_share_name = encShareName;
        return tItem;

    }


    public async mkCompatUrl(
        tItem: sync.ITransferItemDownload,
        action: number
    ): Promise<string> {
        const disp = action == this.ACT_DOWNLOAD ? 'attachment' : 'inline';
        let mfsUrl: string,
            host = window.location.href;
        if (window.location.search) {
            host = host.replace(window.location.search, '');
        }

        const rsaDatakey = await this.syncCryptService.compatDatakeyEncrypt(
            this.syncCryptService.bytesToB64(tItem.data_key)
        );
        const encHost = await this.syncCryptService.compatDatakeyEncrypt(
            this.base64Service.encode(host)
        );

        this.syncCookieService.deleteCookie('signature');
        this.syncCookieService.deleteCookie('passwordlock');
        tItem.disposition = disp;

        if (tItem.linkID) {
            let params = this.urlService.getDownloadPubLinkParams(
                tItem,
                rsaDatakey,
                encHost
            );
            if (action == this.ACT_STREAM_DOC) {
                params.preview = 'pdf';
                params.action = 'stream';
            }
            const result = await this.apiService.execute<any>('linksignrequest', {'req': params}).catch((err) => { throw err; });
            params = result.response;
            if (tItem.linkpasswordlock) {
                this.syncCookieService.setDownloadPubLink(
                    tItem.linkpasswordlock
                );
                mfsUrl = this.urlService.mkDownloadPubLinkPassword(
                    tItem,
                    params
                );
            } else {
                mfsUrl = await this.urlService.mkDownloadPubLink(
                    tItem,
                    params
                );
            }
            return mfsUrl;
        } else {
            const ts = Date.now();
            if (action == this.ACT_STREAM_DOC) {
                mfsUrl = this.urlService.mkPreviewPubUser(
                    tItem,
                    rsaDatakey,
                    ts
                );
            } else {
                mfsUrl = this.urlService.mkDownloadPubUser(
                    tItem,
                    rsaDatakey,
                    ts
                );
            }
            const input = new BaseApiInput();
            input.servtime = ts;
            const defaults = await this.syncCryptService.signApiReq(input).catch((err) => { throw err; });
            mfsUrl = mfsUrl + '&access_token=' + defaults.access_token;
            this.syncCookieService.setDownloadPubUser(defaults.signature);
            return mfsUrl;
        }
    }

    public async getStreamingDetails(item: sync.IFile): Promise<streamItem> {
        const url = await this.getCompatUrl(
            item,
            this.ACT_PREVIEW
        );
        let mimeType = '';
        const fileType = [
            'mp4',
            'm4v',
            'm4p',
            'ogg',
            'mov',
            'mkv',
            'webm',
            '3gp',
            'ogv',
            'mpg',
            'mpeg',
            'mp3',
            'wav',
            'aac',
            'wmv',
            'asf',
            'flv',
            'avi'
        ];
        const ext = item.name
            .substr(item.name.lastIndexOf('.') + 1)
            .toLowerCase();
        if (fileType.indexOf(ext) > -1) {
            switch (ext) {
                case 'mp4':
                case 'm4v':
                case 'm4p':
                    mimeType = 'video/mp4';
                    break;
                case 'webm':
                    mimeType = 'video/webm';
                    break;
                case 'mpg':
                case 'mpeg':
                    mimeType = 'video/mpeg';
                    break;
                case '3gp':
                    mimeType = 'video/3gpp';
                    break;
                case 'ogv':
                case 'ogg':
                    mimeType = 'video/ogg';
                    break;
                case 'mov':
                    mimeType = 'video/quicktime';
                    break;
                case 'mp3':
                    mimeType = 'audio/mp3';
                    break;
                case 'wav':
                    mimeType = 'audio/wav';
                    break;
                case 'aac':
                    mimeType = 'audio/aac';
                    break;
                case 'wmv':
                    mimeType = 'video/wmv';
                    break;
                case 'asf':
                    mimeType = 'video/x-ms-asf';
                    break;
                case 'flv':
                    mimeType = 'video/x-flv';
                    break;
                case 'avi':
                    mimeType = 'video/avi';
                    break;
                case 'mkv':
                    mimeType = 'video/x-matroska';
                    break;
                default:
                    mimeType = null;
                    break;
            }
            return {
                url,
                mimeType,
            };
        } else {
            return {
                url: null,
                mimeType: null,
            };
        }
    }
}
