import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlendEvent, ErrCode, ErrorMessages, OrderSessionNewApiOutput, User } from '../../shared/models';
import { ProvisionService } from '../../account/services/provision.service';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { environment } from '../../../environments/environment';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Router } from '@angular/router';
import { ZuoraAccountService } from '../../account/services/zuora-account.service';
import { PasswordService } from '../../auth/services/password.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { SkuService } from 'src/app/shared/services/sku.service';

@Component({
    selector: 'sync-dialog-multiuser-add',
    templateUrl: './dialog-multiuser-add.component.html'
})
export class DialogMultiuserAddComponent implements OnInit, OnDestroy {

    @Input() hasEmptySlots: boolean;
    @Input() openState: number;
    @Input() addAction: 'existing' | 'new' | 'order';

    public errcode: ErrCode;
    public spinner = false;
    private sub: Subscription;

    public email: string;
    public refcode: string;
    public password: string;
    public passreset = '1';
    public purgefiles = '0';
    public showInputError = {
        email: false,
        password: false,
        refCode: false
    };
    public userLimit: number[];
    public user: User;
    public sku = '';
    public addUserAmount = '1';

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private syncCookie: SyncCookieService,
        private log: LoggerService,
        private provision: ProvisionService,
        private store: Store<fromRoot.State>,
        private userService: UserService,
        private skuService: SkuService,
        private router: Router,
        private zuora: ZuoraAccountService,
        private pwdService: PasswordService,
        private blendService: BlendService,
    ) { }

    async ngOnInit() {
        this.spinner = false;
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                if (!data.authenticated) {
                    this.user = new User();
                    return;
                }
                if (data.user) {
                    this.user = data.user;
                    this.userLimit = Array((this.user.is_on_trial ? 10 : 300) - this.user.plan_user_limit).fill(0).map((x, i) => i + 1);
                }
            });
        this.openState = (this.openState) ? this.openState : 1;
        this.sku = await this.skuService.getSku();

    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    /**
     * Bound to add user button click
     */
    public addUser() {
        if (this.addAction == 'existing') {
            this.addExisting();
        } else if (this.addAction == 'new') {
            this.signup();
        } else if (this.addAction == 'order') {
            this.orderAddUser();
        } else {
            this.errcode = new ErrCode(8220);
        }
    }

    /**
     * Return the error messages for input fields
     */
    public getValidationError(): { email: string[], refcode: string[], password: string[] } {
        const errorObj = { email: [], refcode: [], password: [] };

        if (!this.email) { errorObj.email.push('Email is required'); } else if (!this.provision.validEmail(this.email)) { errorObj.email.push('Invalid Email Address'); }

        if (this.addAction == 'existing') {
            if (!this.refcode) { errorObj.email.push('Ref Code is required'); }
        } else if (this.addAction == 'new') {
            if (!this.password) { errorObj.password.push('Password is required'); } else {
                if (!this.pwdService.isPasswordStrong(this.password)) {
                    errorObj.password.push(ErrorMessages[8304]);
                }
            }
        }

        return errorObj;
    }

    /**
     * If the input is valid, the add button will be enabled.
     * @Dependency - getValidationError()
     */
    public isInputValid(): boolean {
        const validationErrors = this.getValidationError();
        return !Object.keys(validationErrors).some(key => validationErrors[key].length > 0);
    }

    public goNext() {
        if (this.addAction == 'existing') {
            this.openState = 2;
        } else if (this.addAction == 'new') {
            this.openState = 2;
        } else if (this.addAction == 'order') {
            this.openState = 3;
        } else {
            return false;
        }
    }

    private async signup() {
        if (!this.email || !this.password) {
            return;
        }
        const email = this.sanitizeEmail(this.email);
        if (!this.provision.validEmail(email)) {
            this.errcode = new ErrCode(8107, `The email ${email} is not valid.`);
            return;
        }
        this.spinner = true;
        try {
            const data = await this.provision.provision(
                'multiuser',
                email,
                this.password,
                '',
                parseInt(this.passreset, 10),
                0
            );
            this.blendService.track(BlendEvent.SUB_USER_ADDED, { type: 'new user', num_users: 1, sku: this.sku, method: 'Manual' });
            this.spinner = false;

            const result = await this.api.execute('multiuserupdate', {
                action: 'addnew',
                email: email,
                code: data.referral_code
            });

            if (this.purgefiles == '1') {
                await this.api.execute('multiuserpermissions', {
                    child_user_id: data.user_id,
                    disablepurge: 1
                });
            }
            this.activeModal.close(true);
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
        }
    }

    private async orderAddUser() {
        const additionalUsers: number = parseInt(this.addUserAmount, 10);

        if (this.userService.get('zuora_acct_key')) {

            const p = this.userService.get('plan_product_id');
            const rp = this.userService.get('plan_rate_plan_id');
            const rpc = this.userService.get('plan_rate_plan_charge_id');
            const qty = this.userService.get('plan_user_limit');
            const zprofile = await this.zuora.getProfile();

            if (this.userService.get('is_multi_admin') && zprofile && zprofile.balance <= 0 && zprofile.unpaidInvoiceCount >= 0) {
                try {
                    const result = await this.api.execute<OrderSessionNewApiOutput>('ordersessionnew', {});
                    this.syncCookie.setOrderSessionId(result.sessionId);
                    const url = environment.ordershost
                        + '/upgrade/'
                        + p
                        + '?rp=' + rp
                        + '&rpc=' + rpc
                        + '&qty=' + (qty + additionalUsers);
                    this.log.info(`Sending user to upgrade ${url}`);
                    window.location.href = url;
                } catch (ex) {
                    this.errcode = new ErrCode(8550);
                    this.log.error(`Error creating order session to add user ${JSON.stringify(ex)}`);
                }
            } else {
                this.router.navigate(['/account/upgrade']);
                this.activeModal.close();
            }
        } else {
            this.errcode = new ErrCode(8505);
        }
    }

    private async addExisting() {
        if (!this.email || !this.refcode) {
            return;
        }
        this.spinner = true;
        try {
            await this.api.execute('multiuserupdate', {
                action: 'add',
                email: this.sanitizeEmail(this.email),
                code: this.refcode
            });
            this.blendService.track(BlendEvent.SUB_USER_ADDED, {type: 'existing user', num_users: 1, sku: this.sku, method: 'Manual' });
            this.spinner = false;
            this.activeModal.close(true);
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
        }
    }

    private sanitizeEmail(email: string) {
        return email.toLowerCase().trim();
    }
}
