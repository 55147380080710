

<sync-link-create
    *ngIf="openState == 0 && initialized"
    [item]="item"
    [linkPrecreateOptions]="linkPrecreateOptions"
    [openState]="openState"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-create>

<sync-link-manage
    *ngIf="openState == 1 && initialized"
    [link]="link"
    [item]="item"
    [linkPrecreateOptions]="linkPrecreateOptions"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-manage>

<sync-link-settings
    *ngIf="openState == 2 && initialized"
    [link]="link"
    [item]="item"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-settings>

<sync-link-delete
    *ngIf="openState == 3 && initialized"
    [link]="link"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-delete>

<sync-link-precreate-settings
    *ngIf="openState == 4"
    [item]="item"
    [linkPrecreateOptions]="linkPrecreateOptions"
    [openState]="openState"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
    (linkPrecreateOptionsState)="onLinkPrecreateOptionsStateChange($event)"
></sync-link-precreate-settings>

<sync-link-email
    *ngIf="openState == 5 && initialized"
    [link]="link"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-email>

<sync-link-suspended
    *ngIf="openState == 10 && initialized"
    [link]="link"
    (state)="onStateChange($event)"
    (close)="onClose($event)"
></sync-link-suspended>

