<div class="container-fluid container-fluid-wrap tab-wrapper">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Account settings
            </div>

            <div class="right-menu-button right-menu-button-push affix">
            </div>

            <ul class="nav nav-tabs">
                <li role="presentation">
                    <a routerLink="/account/info">Account</a>
                </li>
                <li role="presentation"><a routerLink="/account/billing">Billing</a></li>
                <li role="presentation"><a routerLink="/account/devices">Devices</a></li>
                <li role="presentation" *ngIf="user.new_feature"><a routerLink="/account/apps">Apps</a></li>
                <li class="active" role="presentation"><a routerLink="/account/security">Security</a></li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 content-box-withmenu content-account">
            <div class="row" style="padding-top:32px;">
                <div class="col-sm-4">
                    Two-factor Authentication:
                    <div class="subtextfull">Secure web-based access and all account level changes with 2FA.</div>
                </div>
                <div class="col-sm-4 text-right">
                    {{(user.twofa_type != '0') ? 'Enabled' : 'Disabled'}}
                </div>
                <div *ngIf="is2FaEditable" class="col-sm-4 text-right">
                    <a (click)="openTwoFa()" class="syncblue showhand">Edit</a>
                </div>
            </div>
            <hr />
            <div class="row" *ngIf="!isPassResetHidden">
                <div class="col-sm-4">
                    Email-based password recovery:
                    <div class="subtextfull">Make your Sync account recoverable via email authentication.</div>
                </div>
                <div class="col-sm-4 text-right">
                    {{user.is_resetpassword ? 'Enabled' : 'Disabled'}}
                </div>
                <div class="col-sm-4 text-right">
                    <a (click)="openPasswordReset()"  class="syncblue showhand">Edit</a>
                </div>
            </div>

            <hr *ngIf="!isPassResetHidden" />

            <div class="row">
                <div class="col-sm-4">
                    Password hint:
                    <div class="subtextfull">Set a word or phrase that can help you remember a forgotten password.</div>
                </div>
                <div class="col-sm-4 text-right">
                    {{user.has_password_hint ? 'Enabled' : 'Disabled'}}
                </div>
                <div class="col-sm-4 text-right">
                    <a (click)="openPasswordHint()" class="syncblue showhand">Edit</a>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-4">
                    Automatic logout:
                    <div class="subtextfull">Manage web browser session time.</div>
                </div>
                <div class="col-sm-4 text-right">
                    <span *ngIf="user.sessionttl == 0">Disabled</span>
                    <span *ngIf="user.sessionttl == 3600">1 hour</span>
                    <span *ngIf="user.sessionttl == 86400">24 hours</span>
                </div>
                <div class="col-sm-4 text-right">
                    <a  (click)="openAutoLogout()" class="syncblue showhand">Edit</a>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-4">
                    Single sign-on:
                    <div class="subtextfull">Manage SSO settings from your authenticated desktop apps.</div>
                </div>
                <div class="col-sm-4 text-right">
                    {{user.is_disablesso == 1 ? 'Disabled' : 'Enabled'}}
                </div>
                <div class="col-sm-4 text-right">
                    <a (click)="openDisableSso()" class="syncblue showhand">Edit</a>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-sm-4">
                    Analytics:
                    <div class="subtextfull">Help us improve your experience with Sync.</div>
                </div>
                <div class="col-sm-4 text-right">
                    {{user.is_disableanalytics == 1 ? 'Disabled' : 'Enabled'}}
            </div>
                <div class="col-sm-4 text-right">
                    <a (click)="openDisableAnalytics()" class="syncblue showhand">Edit</a>
                </div>
            </div>

            <hr />
        </div>
    </div>
</div>