import { Pipe, PipeTransform } from '@angular/core';
import { TransferStatus } from '../../transfer/transfer.model';
import { ErrorMessages } from '../models';

@Pipe({
    name: 'translateTransferItem',
})
export class TranslateTransferItemPipe implements PipeTransform {

    transform(status: string, shouldReturnAsHtml: boolean): string {
        let str = '';
        if (ErrorMessages[status]) {
            return ErrorMessages[status];
        }
        switch (parseInt(status, 10)) {
            case TransferStatus.STATUS_WAITING:
                str = 'Waiting';
                break;
            case TransferStatus.STATUS_WORKING:
                str = 'Working ...';
                break;
            case TransferStatus.STATUS_ENC_UPLOAD:
                str = 'Encrypting file';
                break;
            case TransferStatus.STATUS_UPLOAD:
                str = 'Uploading encrypted file';
                break;
            case TransferStatus.STATUS_DECRYPT_DOWNLOAD:
                str = 'Decrypting';
                break;
            case TransferStatus.STATUS_DOWNLOAD:
                str = 'Downloading encrypted file';
                break;
            case TransferStatus.STATUS_PREPAREDOWNLOAD:
                str = 'Preparing file for download';
                break;

            case TransferStatus.STATUS_ERR_SIZE:
                str = 'File is too large for this browser';
                break;
            case TransferStatus.STATUS_ABORT:
                str = 'Stopped by user';
                break;
            case TransferStatus.STATUS_ERROR:
                str = 'An error occurred.';
                break;
            case TransferStatus.STATUS_ERR_OPEN:
                str = 'The browser cannot save/view this file';
                break;
            case TransferStatus.STATUS_SUCCESS:
                str = 'Complete';
                break;
            case TransferStatus.STATUS_UPLOAD_CANCELLED:
                str = 'Upload canceled';
                break;
            default:
                str = 'Unknown status.';
                break;
        }
        return str;
    }
}
