import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MemoizationService {
  constructor() { }
  private memoizedValues: {
    [key: string]: { value: Observable<any>; timestamp: number };
  } = {};
  private cacheExpiration = 30 * 60 * 1000; // 30 minute in milliseconds

  memoize(key: string, func: () => Observable<any>): Observable<any> {
    const cachedValue = this.memoizedValues[key];

    if (!cachedValue || this.isCacheExpired(cachedValue.timestamp)) {
      const result = func();

      this.memoizedValues[key] = {
        value: result,
        timestamp: Date.now(),
      };

      timer(this.cacheExpiration)
        .pipe(
          take(1),
          switchMap(() => this.memoize(key, func))
        )
        .subscribe();

      return result;
    }

    return cachedValue.value;
  }

  private isCacheExpired(timestamp: number): boolean {
    const currentTime = Date.now();
    return currentTime - timestamp > this.cacheExpiration;
  }
}
