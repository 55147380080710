<!-- COMMENT NOTIFER -->
<div
    *ngIf="allowComment && hasNewComments && showNotification"
    class="text-center comment-notifier"
>
    <table style="width: 100%">
        <tr>
            <td>
                <i class="fas fa-bell fa-lg" aria-hidden="true"></i> There's a
                new comment.
            </td>
            <td>
                <a
                    class="btn btn-default"
                    (click)="getComments(); scrollToBottom()"
                    >Show</a
                >
            </td>
            <td>
                <a class="btn btn-default" (click)="hideNotification()"
                    >Dismiss</a
                >
            </td>
        </tr>
    </table>
</div>
<!-- /COMMENT NOTIFIER -->

<i *ngIf="spinner" class="fas fa-sync fa-spin"></i>
<span *ngIf="allowComment && !spinner">
    <div style="margin-top: auto">
        <div
            #commentThread
            class="
                comment-box
                dropdown-menu
                right-menu-dropdown-menu
                dropdown-regular
            "
        >
            <!-- COMMENT HEADER -->
            <div class="comment-header">
                <div
                    [ngClass]="
                        link.image_cachekey ? 'syncblue-custom' : 'syncblue'
                    "
                    style="padding-bottom: 8px"
                    (click)="toggleComment()"
                >
                    <i class="zmdi zmdi-comments zmdi-hc-fw zmdi-hc-lg"></i>
                    Comments
                    <i
                        class="fas pull-right visible-xs"
                        [ngClass]="isCommentExpanded ? 'fa-window-minimize' : 'fa-window-restore'"
                        style="padding-left: 8px"
                        aria-hidden="true"
                    ></i>

                    <i
                        class="fas fa-redo pull-right showhand"
                        aria-hidden="true"
                        (click)="getComments(); scrollToBottom($event);"
                    ></i>
                    <i
                        *ngIf="hasNewComments"
                        class="fas fa-bell pull-right"
                        style="color: #f9bc16; padding-right: 8px"
                        aria-hidden="true"
                    ></i>
                </div>
            </div>
            <sync-error [errcode]="errCode"></sync-error>
            <!-- / COMMENT HEADER -->

            <!-- COMMENT INPUT BOX -->
            <sync-comment
                *ngIf="!errCode"
                [user]="user"
                [tempSession]="tempSession"
                [locId]="locId"
                [item]="item"
                [isInputBox]="true"
                [link]="link"
                (commentCreationEvent)="createComment($event)"
                (anonymousCreationEvent)="createAnonymousSession($event)"
            >
            </sync-comment>
            <!-- / COMMENT INPUT BOX -->

            <!-- COMMENT THREADS -->
            <span *ngFor="let comment of comments">
                <sync-comment
                    [comment]="comment"
                    [user]="user"
                    [tempSession]="tempSession"
                    [cdk]="cdk"
                    [ownerId]="ownerId"
                    [item]="item"
                    [locId]="locId"
                    [link]="link"
                    [publinkId]="publinkId"
                    (commentEditionEvent)="updateComment($event)"
                    (commentDeletionEvent)="deleteComment($event)"
                    (commentGetEvent)="getComments()"
                    (anonymousCreationEvent)="createAnonymousSession($event)"
                >
                </sync-comment>
                <br />
            </span>
            <!-- / COMMENT THREADS -->
        </div>
    </div>
</span>
<!-- COMMENT IFRAME -->
<iframe
    id="sync-comment-cp-iframe"
    class="comment-cp-iframe"
    [src]="commentCPServiceHost"
></iframe>
<!-- /COMMENT IFRAME -->
