import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoSignupLayoutComponent } from '../layout/no-signup-layout/no-signup-layout.component';
import { ShareInviteLayoutComponent } from '../layout/share-invite-layout/share-invite-layout.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ShareInviteComponent } from './share-invite/share-invite.component';
import { ShareListComponent } from './share-list/share-list.component';
import { ShareProvisionComponent } from './share-provision/share-provision.component';
import { FooterLayoutComponent } from '../layout/footer-layout/footer-layout.component';
import { ExternalShareListComponent } from './external-share-list/external-share-list.component';


const routes: Routes = [
  {
    path: '', component: FooterLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ShareListComponent },
      { path: 'externalshares', component: ExternalShareListComponent },
      { path: 'invite', component: ShareInviteComponent },
      { path: 'rejoin', component: ShareInviteComponent },
    ]
  },
  {
    path: 'invite/:invite_id', component: ShareInviteLayoutComponent,
    children: [
      { path: '', component: ShareInviteComponent }
    ]
  },
  {
    path: ':cachekey', component: NoSignupLayoutComponent,
    children: [
      { path: '', component: ShareProvisionComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharesRoutingModule { }
