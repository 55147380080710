
<div class="container-fluid container-fluid-wrap">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Renew your account
            </div>
        </div>
        <div class="right-menu-button right-menu-button-push affix"></div>
        <div class="col-sm-12 header-box-withmenu" *ngIf="!spinner">
            <sync-error [errcode]="errcode"></sync-error>
            <div *ngIf="cancelInFuture">
                <div class="header-title" style="font-size: 30px;font-weight:600;">
                    Your account is pending cancellation
                </div>
                <p>Please contact support if you wish to make changes to your plan.</p>
            </div>
            <div *ngIf="unableToRenew">
                <div class="header-title" style="font-size: 30px;font-weight:600;">
                    Unable to renew
                </div>
                <p>Your account is not able to be renewed currently.  Please contact support for further assistance.</p>
            </div>
            <div *ngIf="url && !unableToRenew && !cancelInFuture">
                <p>
                    If the page does not reload automatically, click the renew now button.
                </p>
                <a [href]="url" class="btn btn-primary">Renew Now</a>
            </div>
        </div>
        <div class="col-sm-12 content-box-withmenu" *ngIf="spinner">
            <div class="row">
                <div class="col-sm-12">
                    <i class="fa fa-spin fa-spinner"></i>
                    Processing user information...
                    <hr />
                </div>
            </div>
        </div>
    </div>
</div>

