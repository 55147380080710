import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../core/api.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { BatchCopyService } from './batch-copy.service';
import { WebpathActionType } from '../shared/models';
import { JoblockService } from '../../../src/app/core/joblock.service';
import { ShareAcceptApiOutput } from '../shared/models/api/shareaccept-api.model';

@Injectable({
    providedIn: 'root',
})
export class BatchMoveService extends BatchCopyService {
    constructor(
        public broadcastService: BroadcastService,
        public loggerService: LoggerService,
        public syncCryptService: SyncCryptService,
        public notificationsService: NotificationsService,
        public apiService: ApiService,
        private JobLockService: JoblockService,
    ) {
        super(
            broadcastService,
            loggerService,
            syncCryptService,
            notificationsService,
            apiService
        );
    }

    public async execute(syncIds: number[], destSyncId: number): Promise<any> {
        this.loggerService.info(`batchMove(${syncIds.toString()})`);
        this.ACTION = this.ACT_MOVE;
        this.view.globalCancel = false;
        this.view.completedBatch = [];
        this.view.total = syncIds.length;

        // get copy to prevent modifying reference
        const syncArray: number[] = syncIds.slice();
        return await this._moveFolder(syncArray, destSyncId);
    }

    public async _moveFolder(
        syncIds: number[],
        syncPid: number,
        nameData?: any
    ) {
        let shareData: any,
            dumpDirsList: any;
        const syncId = syncIds.pop();
        this.view.spinner = syncId;
        this.loggerService.info(`sync ids ${syncIds.toString()} to ${syncPid}`);
        try {
            const dumpList = await this.dumpDirs(syncId);
            const retryAmt = 0,
                syncIDList: any[] = [],
                syncHash = dumpList.sync_ids;

            dumpDirsList = dumpList;
            shareData = dumpList.share;

            if (!syncHash[syncId]) {
                this.loggerService.error(
                    'Could not find the sync id in the batchdumpdirs result? ' +
                        syncId
                );
                throw { errcode: 9000 };
            }

            syncHash[syncId].new_sync_id = syncPid;
            this.view.total = dumpList.cnt * 2;

            // convert to array first so we can recursively run a function and
            // not worry about a loop outracing our API call.
            for (const key in syncHash) {
                if (syncHash[key].sync_id == syncId) {
                    syncHash[key].nameData = nameData;
                }
                syncIDList.push(syncHash[key]);
            }

            const syncArray = await this._copyFolderContents(syncIDList, 0, WebpathActionType.MOVE);
            let shareAcceptData: any;
            if (shareData && shareData.share_id && shareData.sharemember_id) {
                this.loggerService.info('share data exists');
                for (let i = 0, len = syncArray.length; i < len; i++) {
                    if (
                        syncArray[i].new_metaname &&
                        syncArray[i].new_sync_pid
                    ) {
                        shareAcceptData = syncArray[i];
                    }
                }

                if (
                    shareAcceptData &&
                    shareAcceptData.new_sync_pid &&
                    shareAcceptData.new_metaname
                ) {
                    this.loggerService.info(
                        'found share on original sync id, leaving'
                    );
                    this.loggerService.info('share id: ' + shareData.share_id);
                    this.loggerService.info(
                        'share member: ' + shareData.sharemember_id
                    );
                    await this.apiService.execute('sharememberleave', {
                        share_id: shareData.share_id,
                        sharemember_id: shareData.sharemember_id,
                        bg: 0, //use background job 0 = false, i.e. move share
                    });
                    this.loggerService.info(
                        'Checking if new sync id is a share ...'
                    );
                    const pathdata: any = await this.apiService.execute(
                        'pathget',
                        {
                            sync_id: shareAcceptData.new_sync_pid,
                        }
                    );
                    this.loggerService.info(
                        pathdata.is_shared
                            ? 'New sync is shared'
                            : 'New sync is not shared, re-attach'
                    );
                    if (pathdata.is_shared) {
                        return; // resolve immediately
                    } else {
                        try {
                            const shareAccept = await this.apiService.execute<ShareAcceptApiOutput>('shareaccept', {
                                uniqid: shareData.sharemember_id,
                                enc_name: shareAcceptData.new_metaname,
                                accept: 1,
                                sync_pid: shareAcceptData.new_sync_pid,
                                sharedata: {},
                                displayname: shareData.display_name,
                                bg: 1, // use background job 1=true,
                            });
                            const bg = shareAccept['bg'];
                            if (bg) {
                                const response = await this.JobLockService.pollJobStatus();
                                this.loggerService.info('ShareAccept_JobComplete');
                            }
                        } catch (err) {
                            this.loggerService.handleError(
                                '_moveFolder.shareacceptdata',
                                err
                            );
                        }
                    }
                } else {
                    this.loggerService.error(
                        'Failed to find share accept data to re-attach'
                    );
                }
            } else {
                this.loggerService.info('No share data found');
            }
            if (this.view.globalCancel) {
                return;
            }
            const syncIdArray = this._dumpDirsToSyncIds(dumpDirsList);
            await this.executeMove(syncIdArray).toPromise();
            await this.batchSuccess();
            if (syncIds.length) {
                await this._moveFolder(syncIds, syncPid, nameData);
            } else {
                this.view.spinner = 0;
                return;
            }
        } catch (errData) {
            this.loggerService.handleError('_moveRecursive', errData);
        }
    }

    private _dumpDirsToSyncIds(data: any): number[] {
        const list: any[] = [];
        for (const key in data.sync_ids) {
            list.push(data.sync_ids[key].sync_id);
        }

        return list;
    }

    protected dumpDirs(
        syncId: number,
        activeLevel?: number,
        completed?: number
    ): Promise<any> {
        const string = activeLevel || 1;
        this.view.completed = completed || 0;
        return this.apiService.execute('batchdumpdirs', {
            sync_id: syncId,
            active_level: string.toString(),
        });
    }

    public executeMove(syncIds: number[]): Subject<any> {
        const subject = new Subject();
        this.loggerService.info(`batchDeleteMove(${syncIds.toString()})`);
        this.ACTION = this.ACT_MOVE;
        console.log('executeMove running for ' + this.view.total);
        this.runBatchItems(syncIds, subject);
        return subject;
    }

    protected async prepareItemForAction(syncId: number): Promise<any> {
        if (this.view.globalCancel) {
            return this.handleCancel();
        } else {
            this.resetProgress();
            this.view.spinner = syncId;
            try {
                const dumpList = await this.dumpDirs(
                    syncId,
                    1,
                    this.view.completed
                );
                const dumpItems = await this.dropShare(dumpList);
                const syncIds = await this.convertToArray(dumpItems);
                if (this.view.globalCancel) {
                    return this.handleCancel();
                }
                syncIds.reverse();
                return await this.runProcessTask(syncIds, 0);
            } catch (err) {
                console.log('batch-move prepareItemForAction err: ', err);
                return this.loggerService.handleError(
                    'batch-move prepareItemForAction',
                    err
                );
            }
        }
    }

    protected apiExecuteTask(syncId: number): Promise<any> {
        return this.apiService.execute('batchdelete', { sync_id: syncId });
    }
}
