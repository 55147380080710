import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkItem } from '../../shared/models';

@Component({
    selector: 'sync-link-suspended',
    templateUrl: './link-suspended.component.html'
})
export class LinkSuspendedComponent implements OnInit {

    @Input() link: LinkItem;
    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter();

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
    }

    public closeDialog() {
        this.close.emit();
    }
}
