import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as CoreActions from '../../actions/core.actions';
import * as AuthActions from '../../actions/auth.actions';
import * as FileListActions from '../../actions/file-list.actions';

import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { SyncDigestService } from '../../core/crypt/sync-digest.service';
import { AuthSSO, User, AuthUserPassword, ErrCode } from '../../shared/models';
import {
    BaseApiOutput,
    GetloginsaltApiOutput,
    GetUserKeysApiOutput,
    SessionNewApiInput,
    SessionNewApiOutput,
    UserSsoApiOutput,
    UserSsoApiInput,
} from '../../shared/models/api';
import { LoggerService } from '../../core/logger.service';
import { UrlService } from '../../core/url.service';
import { UserService } from '../../core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferService } from '../../transfer/transfer.service';
import { NotificationsService } from '../../core/notifications.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { WebSocketService } from '../../core/websocket.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authenticated = false;
    private bCrypt: bCrypt;
    constructor(
        private api: ApiService,
        private base64: Base64Service,
        private crypt: SyncCryptService,
        private digest: SyncDigestService,
        private log: LoggerService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private userService: UserService,
        private url: UrlService,
        private transferService: TransferService,
        private notificationsService: NotificationsService,
        private broadcastService: BroadcastService,
        private webSocketService: WebSocketService,
    ) {
        this.bCrypt = new bCrypt();
    }

    // helper for ng1 to get store data.
    public authState() {
        return this.store.select(fromRoot.getAuthState);
    }

    // helper for ng1 to get store data. share-provision-controller
    public login(username, password, twofacode?) {
        const item = new AuthUserPassword(username, password, twofacode);
        this.store.dispatch(new AuthActions.LoginAction(item));
    }

    public async loginUserPass(
        username: string,
        password: string,
        twofaCode?: string
    ) {
        username = username.toLowerCase();
        return await this.authenticate(username, password, twofaCode);
    }

    public async authenticate(
        username: string,
        password: string,
        twofaCode?: string
    ): Promise<User> {
        username = username.toLowerCase();
        const hashPass = await this.getHashedPassword(username, password);
        try {
            const accessData = await this.api.send<SessionNewApiOutput>(
                'sessionnew',
                {
                    username: username,
                    password: hashPass,
                    twofacode: twofaCode,
                }
            );
            if (accessData.success == 1 && accessData.errors) {
                throw ErrCode.fromApiLegacy(accessData.errors);
            }
            const keys = await this.api.send<GetUserKeysApiOutput>(
                'getuserkeys',
                {
                    username: username,
                    password: hashPass,
                }
            );
            // console.log(' ---- storekeys');
            await this.storeKeys(keys, password);
            // console.log(' ---- storeaccessdata');
            await this.storeAccessData(accessData);
            // console.log(' ---- return User');
            this.broadcastService.broadcast('sync.sessionnew.user');
            return new User(accessData);
        } catch (ex) {
            this.log.error('authenticate() failed');
            this.log.error(ex);
            throw ErrCode.fromException(ex);
        }
    }

    public async authenticateAndGetUserKeys(
        username: string,
        hashPass: string,
        twofaCode?: string,
        isLegacyToCNC?: boolean
    ): Promise<GetUserKeysApiOutput> {
        username = username.toLowerCase();
        try {
            let accessData;
            if (isLegacyToCNC !== undefined && isLegacyToCNC === true) {
                accessData = await this.api.send<SessionNewApiOutput>(
                    'userverifypassword',
                    {
                        username: username,
                        password: hashPass,
                        isLegacyToCNC: isLegacyToCNC ? true : false,
                    }
                );
            } else {
                accessData = await this.api.send<SessionNewApiOutput>(
                    'sessionnew',
                    {
                        username: username,
                        password: hashPass,
                        twofacode: twofaCode,
                    }
                );
            }

            if (accessData.success == 1 && accessData.errors) {
                throw ErrCode.fromApiLegacy(accessData.errors);
            }
            const keys = await this.api.send<GetUserKeysApiOutput>(
                'getuserkeys',
                {
                    username: username,
                    password: hashPass,
                    isLegacyToCNC: isLegacyToCNC ? true : false,
                }
            );
            return keys;
        } catch (ex) {
            throw ErrCode.fromException(ex);
        }
    }

    public async loginSSO(loginData: AuthSSO) {
        await this.logout();

        const resp = await this.api.send<UserSsoApiOutput>('usersso', <
            UserSsoApiInput
            >{
                key: loginData.lookupkey,
                twofacode: loginData.twofacode,
            });
        if (resp && resp.success == 1) {
            await this.storeKeys(resp, loginData.password);
            await this.storeAccessData(resp.userattr);
        }
        // startNotificationLoop
        this.notificationsService.startNotificationLoop();
        return new User(resp.userattr);
    }

    private async storeAccessData(accessData: AccessTokens) {
        const secret = await this.crypt.storeEncrypt(accessData.access_secret);
        this.store.dispatch(
            new CoreActions.SetValueAction({
                key: 'access_token',
                value: accessData.access_token,
            })
        );
        this.store.dispatch(
            new CoreActions.SetValueAction({
                key: 'access_secret',
                value: secret,
            })
        );
        return true;
    }

    private async storeKeys(keys: UserKeys, password: string) {
        try {
            const meta = await this.crypt.userkeyDecrypt(
                keys.enc_meta_key,
                password
            );
            const priv = await this.crypt.userkeyDecrypt(
                keys.enc_priv_key,
                password
            );
            const encMeta = await this.crypt.storeEncrypt(meta);
            const encPriv = await this.crypt.storeEncrypt(priv);
            this.store.dispatch(
                new CoreActions.SetValueAction({ key: 'meta_key', value: encMeta })
            );
            this.store.dispatch(
                new CoreActions.SetValueAction({
                    key: 'private_key',
                    value: encPriv,
                })
            );
            return true;
        } catch (ex) {
            this.log.e('Error storing keys on user, key decryption failed', ex);
            throw new ErrCode(6002);
        }
    }

    public storeJwtToken(token: string) {
        this.store.dispatch(
            new CoreActions.SetValueAction({
                key: 'multiadmin_jwt_token',
                value: token,
            })
        );
    }

    public isAuthenticated(): Observable<boolean> {
        return of(this.authenticated);
    }

    public async getHashedPassword(username: string, password: string, noUpdate?: boolean) {
        try {
            const data = await this.api.send<GetloginsaltApiOutput>(
                'getloginsalt',
                {
                    username_b64: this.base64.encode(username),
                }
            );
            return await this.hashPassword(
                (<GetloginsaltApiOutput>data).salt,
                password,
                noUpdate
            );
        } catch (ex) {
            this.log.e(
                'getHashedPassword username or password does not exist',
                ex
            );
            throw new ErrCode(6001);
        }
    }

    public hashPassword(salt: string, password: string, noUpdate?: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            // let sha1pass
            const sha1pass = this.digest.hash(password),
                mangle = this.digest.hash256(
                    sha1pass + sha1pass.substring(4, 24)
                );
            let number = 0;
            this.bCrypt.hashpw(
                mangle,
                salt,
                (hashedPass: string) => {
                    // console.log('hashed password = ' + hashedPass);
                    resolve(hashedPass);
                },
                () => {
                    if (noUpdate) { return; }
                    number += 1;
                    this.store.dispatch(
                        new AuthActions.LoginProgressAction(number)
                    );
                    // console.log('Progress is running' , number);
                }
            );
        });
    }

    /**
     * @ngdoc method
     * @name  logout
     * @methodOf sync.service:AuthService
     * @description
     * Logs a user out and resets the state of the cp.
     *
     */
    public async logout() {
        this.log.info('Logging out user');
        this.notificationsService.handleLogout();
        if (this.userService.isAuthenticated()) {
            try {
                await this.api.execute('sessiondelete', {});
            } catch (ex) {
                this.log.warn('Error logging out');
                this.log.warn(ex);
            }
        }
        this.store.dispatch(new CoreActions.StoreClearAction());
        this.store.dispatch(new AuthActions.LogoutAction());
        this.store.dispatch(new FileListActions.FileListResetAction());
        this.transferService.reset();
        this.modalService.dismissAll();
        this.broadcastService.broadcast('sync.reset.user');
        this.webSocketService.close();
    }
}
