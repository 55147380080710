import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ViewEncapsulation,
} from '@angular/core';
import { User, ErrCode } from '../../shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Subscription, Subject } from 'rxjs';

@Component({
    selector: 'sync-dialog-display-avatar',
    templateUrl: './dialog-display-avatar.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DialogDisplayAvatarComponent implements OnInit {
    @Input() user: User;
    public logoInput: File; //user input

    public errcode: ErrCode;
    public avatarForm: FormGroup;
    public spinner: boolean;
    public avatarEndpoint: string;
    //avatar attributes
    public logo: string; //either default image path or base64 image

    private defaultLogo: string;
    private sub: Subscription;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private userService: UserService,
        private httpClient: HttpClient,
        private logger: LoggerService,
        private api: ApiService,
        private store: Store<fromRoot.State>
    ) {}

    ngOnInit() {
        this.defaultLogo = this.user.avatar.avatar_data;
        this.avatarEndpoint = `${environment.logohost}avatar/`;

        if (!this.defaultLogo) {
            this.defaultLogo = '';
        }

        this.errcode = null;
        this.logoInput = null;
        //set things to saved settings or default
        this.logo = this.defaultLogo;
        this.spinner = false;
        this.createForm();
    }

    private createForm() {
        //logo input is not required if a custom logo exists
        if (this.logo) {
            this.avatarForm = this.fb.group({
                logo: [this.logoInput],
            });
        } else {
            this.avatarForm = this.fb.group({
                logo: [this.logoInput, Validators.required],
            });
        }
    }

    async onSubmit() {
        //api call to store the image
        // this.logger.info('upload info: ' + this.logo + " " + this.primaryColor + " " + this.textColor);
        try {
            await this.api.execute('uploadavatar', { imgBase64: this.logo });
            this.userService.refresh();
            this.activeModal.close(this.logo);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    async uploadFile(files: FileList) {
        if (!files || files.length == 0) {
            return;
        }
        this.errcode = null;
        this.setSpinner();
        const logoFile = files.item(0);
        const path = '/upload/whitelabel';
        const formData: FormData = new FormData();
        formData.append('file', logoFile, logoFile.name);
        //api call for image preprocessing
        await this.httpClient
            .post(environment.imageconversionhost + path, formData)
            .subscribe(
                (response) => {
                    if (response['success']) {
                        this.logo = response['imgdata'];
                        this.setSpinner();
                    }
                },
                (err) => {
                    this.errcode = new ErrCode(
                        err.error.error_code,
                        'The file format is unsupported. Please try again'
                    );
                    this.setSpinner();
                }
            );
    }

    async clearAvatar() {
        try {
            await this.api.execute('uploadavatar', { imgBase64: '' });
            this.userService.refresh();
            this.activeModal.close('');
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    setSpinner() {
        this.spinner = !this.spinner;
    }
}
