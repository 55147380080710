<div class="avatar-container" [ngClass]="'avatar-' + avatarIndex">
    <div *ngIf="overflowCount" class="avatar-overflow">
        {{'+' + overflowCount}}
    </div>

    <div *ngIf="initial && !avatarUrl" class="avatar-initial">
        {{initial}}
    </div>
    <img *ngIf="avatarUrl" class="img-circle display-avatar-setting" [src]="avatarUrl" alt="avatar"
        (error)="handleMissingImage()" />
    <div class="hover-effect"></div>
    <div *ngIf="disabled" class="disabled-effect"></div>
</div>