import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../core/user.service';
import { ErrCode, User } from '../../shared/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sync-wopi-upgrade',
    templateUrl: './wopi-upgrade.component.html',
})
export class WopiUpgradeComponent implements OnInit {
    @Input() id: string;
    public user: User;
    public status: string;

    public spinner = false;
    public errcode: ErrCode;

    constructor(
        private userService: UserService,
        public activeModal: NgbActiveModal,
        private router: Router,
        private route: ActivatedRoute
        ) {}

        ngOnInit() {
        this.user = this.userService.getUser();
    }

    public async open() {
        this.spinner = true;
        this.activeModal.close();
        this.router.navigate(['/file', this.id, 'view', 'officefse'],
            {
                queryParams:
                {
                    view: this.route.snapshot.queryParams['view'],
                    page: this.route.snapshot.queryParams['page']
                }
            });
    }

    public async upgrade() {
        this.spinner = true;
        this.activeModal.close();
        this.router.navigate(['/account/upgrade']);
    }
}
