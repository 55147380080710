<sync-header-previews [item]="item">
  <div class="hamburger-menu-item visible-xs" hamburgerMenu>
    <sync-preview-menu [item]="item" [url]="url"></sync-preview-menu>
  </div>
</sync-header-previews>
<div class="col-md-8 col-lg-9 video-container">
  <video syncPreventRightClick id="my-video" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" data-setup='{}'>
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a web browser that
      <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
    </p>
  </video>
</div>
<div class="col-md-4 col-lg-3">
  <sync-preview-menu [item]="item" [url]="url"></sync-preview-menu>
  <sync-comment-block *ngIf="allowComment" [item]="item" [ontoggle]="onCommentToggle"></sync-comment-block>
</div>
<br>