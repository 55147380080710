<ul class="sync-table" style="list-style: none; margin: 0px; padding: 0px">
    <li>
        <table class="table list-table list-table-modal">
            <thead *ngIf="headerTitle">
                <tr>
                    <th colspan="4">
                        <span [innerHtml]="headerTitle"></span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- choose sync folder -->
                <tr *ngIf="!pickerView?.cwd?.sync_id">
                    <td class="table-modalchoosercheck">
                        <i
                            class="showhand"
                            (click)="selectByName('sync')"
                            [ngClass]="{
                                'fas fa-check-square ':
                                    isSelectedByName('sync'),
                                'far fa-square': !isSelectedByName('sync')
                            }"
                        ></i>
                    </td>
                    <td
                        (click)="openRoot('sync')"
                        class="table-modalchoosericon"
                    >
                        <img
                            src="images/icons/dir.svg"
                            width="24"
                            height="24"
                        />
                    </td>
                    <td
                        class="table-modalchoosername"
                    >
                        <div class="tdmore">
                            <a
                                class="syncblue showhand"
                                id="sync-folder-option"
                                (click)="openRoot('sync')"
                            >
                                Sync Folder</a
                            >
                        </div>
                    </td>
                    <td
                        (click)="openRoot('sync')"
                        class="table-modalchooseroptions"
                    >
                        <i class="fas fa-angle-right syncblue showhand"></i>
                    </td>
                </tr>

                <!-- choose vault -->
                <tr *ngIf="!pickerView?.cwd?.sync_id">
                    <td class="table-modalchoosercheck">
                        <i
                            class="showhand"
                            (click)="selectByName('vault')"
                            [ngClass]="{
                                'fas fa-check-square':
                                    isSelectedByName('vault'),
                                'far fa-square': !isSelectedByName('vault')
                            }"
                        ></i>
                    </td>
                    <td
                        (click)="openRoot('vault')"
                        class="table-modalchoosericon"
                    >
                        <img
                            src="images/icons/icon-vault.svg"
                            width="24"
                            height="24"
                        />
                    </td>
                    <td
                        class="table-modalchoosername"
                    >
                        <div class="tdmore">
                            <a
                                class="syncblue showhand"
                                (click)="openRoot('vault')"
                                >Vault</a
                            >
                        </div>
                    </td>
                    <td
                        (click)="openRoot('vault')"
                        class="table-modalchooseroptions"
                    >
                        <i class="fas fa-angle-right syncblue showhand"></i>
                    </td>
                </tr>

                <!-- choose other folder -->

                <tr [class.hide]="pickerView?.cwd?.pid == undefined">
                    <td colspan="4" (click)="cdUp()">
                        <span class="syncblue showhand"
                            ><i class="fas fa-angle-left"></i> Back</span
                        >
                    </td>
                </tr>
                <ng-container *ngIf="pickerView?.cwd?.sync_id">
                <tr
                    *ngFor="
                        let item of pickerView.dirlist | orderBy: 'search_name'
                    "
                    [ngClass]="{ active: isSelected(item) }"
                >
                    <td class="table-modalchoosercheck">
                        <i
                            class="showhand"
                            (click)="select(item)"
                            [ngClass]="{
                                'fa fa-spin fa-spinner': item.spinner,
                                'fas fa-check-square':
                                    !item.spinner && isSelected(item),
                                'far fa-square':
                                    !item.spinner && !isSelected(item)
                            }"
                        ></i>
                    </td>
                    <td (click)="cd(item)" class="table-modalchoosericon">
                        <img
                            [src]="'images/icons/' + item.cssclass + '.svg'"
                            width="24"
                            height="24"
                        />
                    </td>
                    <td class="table-modalchoosername">
                        <div class="tdmore">
                            <a
                                (click)="cd(item)"
                                data-sync-stop-propagation=""
                                class="syncblue showhand"
                                [innerHtml]="item.name"
                                >Loading ...</a
                            >
                        </div>
                    </td>
                    <td (click)="cd(item)" class="table-modalchooseroptions">
                        <i class="fas fa-angle-right syncblue showhand"></i>
                    </td>
                </tr>
              </ng-container>
            </tbody>
        </table>
    </li>

    <!-- choose other folder -->
</ul>
