import { Injectable } from '@angular/core';


import { PermissionActions, User } from '../shared/models';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as AuthActions from '../actions/auth.actions';
import { ApiService } from './api.service';
import { LoggerService } from './logger.service';

@Injectable()
export class UserService {

    public user: User;
    public MAX_USERS = 299;
    public MIN_USERS = 3;
    private lastCheckTime: number;
    private initialized = false;
    private authenticated = false;
    public DEFAULT_ROLE = 'Default User';
    constructor(
        private api: ApiService,
        private store: Store<fromRoot.State>,
        private log: LoggerService
    ) {
        this.init();
    }

    public init() {
        this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                this.authenticated = data.authenticated;
                if (!data.authenticated) {
                    this.user = new User();
                    this.initialized = false;
                    return;
                }
                if (data.user) {
                    this.user = data.user;
                    this.initialized = true;
                }
            });

    }

    // ----- ng1 functions
    public get(key) {
        return this.user[key];
    }

    public set(key, val) {
        console.error('UserService.set() ERROR SETTING ' + key + ' = ' + val);
    }

    public isAuthenticated() {
        return this.authenticated;
    }

    public checkPermission(permName: PermissionActions): boolean {
        if (this.user.is_multi_new) {
            // CNC users
            const val: boolean | number = this.user.permissions[permName];
            if (typeof val === 'number') {
                return true;
                // return default true because the value is of type number
                // which means the prop is enforced
                // if boolean, it is explicit
            }
            return this.user.permissions[permName] as boolean;
        } else if ((this.user.zuora_acct_key === '' || this.user.zuora_acct_key === null) && !this.user.is_multi_child && !this.user.is_multi_admin) {
            // free users
            if (permName === 'viewBilling' || permName === 'viewUsers' || permName === 'viewRoles' || permName === 'shareLinkExpiry' || permName === 'shareLinkPassword') { return false; }
            return true;
        } else if (!this.user.is_multi_child) {
            // paid users other than teams+ will have these false as default
            if (permName === 'shareLinkExpiry' || permName === 'shareLinkPassword') { return false; }
            if (!this.user.is_multi_admin) {
                // solo or personal paid users
                // if (permName === 'viewUsers') return false;
                return this.user.zuora_acct_key !== '' && this.user.zuora_acct_key !== null;
            }
            // legacy business teams users
            // return (this.user.zuora_acct_key !== "" && this.user.zuora_acct_key !== null) && this.user.is_multi_admin;
            // for trial users no need to check for zuora accnt key
            if (permName === 'viewUsers') {
                return this.user.is_multi_admin;
            }
            if (permName === 'viewBilling' || permName === 'manageBilling') {
                return (this.user.zuora_acct_key !== '' && this.user.zuora_acct_key !== null) && this.user.is_multi_admin;
            }
        }
        return false;
    }

    public getSubPermissionValues(permName: PermissionActions): number {
        return this.user.permissions[permName] as number;
    }

    public getPermssionByKey(permissionKey: PermissionActions) {
        return this.user.is_multi_new == true ? this.user['permissions'][permissionKey] : true;
    }

    public clear(): void {
        console.error('UserService.clear() not implemented');
    }
    // -- end ng1 functions

    public subscribeUser() {

    }
    public getUser() {
        if (!this.initialized) {
            console.log('Not initialized yet');
            this.init();

        }
        return this.user;
    }

    public getStarted() {
        const getstarted = {
            hasVerified: this.user.is_verified,
            hasPasswordHint: this.user.has_password_hint,
            hasMobile: this.user.install_mobile,
            hasDeviceOne: this.user.install_desktop,
            hasDeviceMany: this.user.install_desktop > 1,
            hasFiles: this.user.has_files,
            hasShares: this.user.has_shares,
            hasLinks: this.user.has_links,
            getstarted_completed: 0
        };
        return getstarted;
    }


    /**
     * Initiates a refresh request for the user.  This will trigger the
     * subscription to trigger.
     */
    public refresh() {
        this.lastCheckTime = Date.now();
        this.store.dispatch(new AuthActions.RefreshAction());
    }

    /**
     * Updates every 5 minutes, used for any polling.
     */
    public update() {
        const timeDiff = Date.now() - this.lastCheckTime;
        if (timeDiff > 60 * 5 * 1000) {
            this.reload();
        }
    }

    /**
     * Initiates the network call to get the actual user information.  The
     * auth.effects and reducer will take care of updating the state of the
     * user object.
     */
    public async reload() {
        // check if user is authenticated
        if (this.user.exists === 1) {
            this.lastCheckTime = Date.now();
            return await this.api.execute('userget', {});
        }
    }

    public dateConversionForTrials(termEndDate: string, trimOneDay?: boolean) {
        const dateArr = termEndDate.split('-');
        if (dateArr.length !== 3) {
            this.log.error(`An invalid term end date received ${termEndDate}`);
        }
        const d = parseInt(dateArr[2], 10) - (trimOneDay ? 1 : 0),
            // because the data that we have here is related to subscription and not the actual plan term
            // as per zuora, if auto renew is enabled, the subscription current term end and new renewal
            // happens on same day, even though the plan term ends a day before
            m = parseInt(dateArr[1], 10) - 1,
            // no need to trim month as we are using date string
            y = parseInt(dateArr[0], 10);
        return new Date(y, m, d);
        // zuora date format changed from yyyy-mm-dd to mm-dd-yyyy so that when converted to user's local time zone,
        // the utc time will be considered and time zone issue will be resolved.
        // This time zone fix is being applied only for trial users
    }
}
