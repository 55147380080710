import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreviewLayoutComponent } from '../layout/preview-layout/preview-layout.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { HistoryComponent } from './history/history.component';
import { PreviewComponent } from './preview/preview.component';
import { FooterLayoutComponent } from '../layout/footer-layout/footer-layout.component';

const routes: Routes = [
  {
    path: ':id/view/:type', component: PreviewLayoutComponent,
    children: [
      { path: '', component: PreviewComponent }
    ]
  },
  {
    path: ':id/history',
    component: FooterLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HistoryComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileRoutingModule { }
