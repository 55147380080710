<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="modalInstance.close()"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Leave {{ shareData.name }}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            If you continue, you won't be able to see future changes to this
            shared folder.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" id="leave-share-dialog-btn" class="btn btn-primary" (click)="leaveShare()">
        Leave share
    </button>
    <button
        type="button"
        class="btn btn-default"
        (click)="modalInstance.close()"
    >
        Cancel
    </button>
</div>
