<div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        <h4 class="modal-title">Activate device</h4>
    </div>
    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
            <div class="col-sm-12">
                    <p>Because two-factor authentication is enabled, you will need to
                        authenticate this device <strong>({{device.name|b64}})</strong>
                        before it can be used. Click the Activate button below to complete
                        this step.
                    </p>
            </div>
        </div>
    
        <hr/>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="activate()">Activate</button>
        <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
    </div>

