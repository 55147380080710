<form (submit)="cancelForm.valid && onSubmit()" [formGroup]="cancelForm" method="post" ngNativeValidate>
    <div class="modal-header">
        <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Billing profile</h4>
    </div>
    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
            <div class="col-sm-4">Reason for leaving:</div>
            <div class="col-sm-8">
                <select
                    class="form-control"
                    id="dialog-cancel-subject"
                    formControlName="subject"
                >
                    <option value=""> -- Please choose one --</option>
                    <option value="Found Something Better">I found something better</option>
                    <option value="Technical Issues">Technical Issues</option>
                    <option value="Ease of Use Issues">Ease of use issues</option>
                    <option value="Too expensive">Too expensive</option>
                    <option value="Other">Other</option>
                </select>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-sm-4">Brief description of why you are cancelling (so we can do better next time):</div>
            <div class="col-sm-8">
                <textarea
                    required
                    class="form-control"
                    id="dialog-cancel-description"
                    formControlName="description"
                    rows="4"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <label for="confirm">
                    <input type="checkbox" name="confirm" required formControlName="confirm" />
                    I understand that closing my account will permanently delete all account data, including all encrypted file data stored in the cloud. I confirm that I have a local copy of my data.
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!cancelForm.valid"
        >Submit</button>
        <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
    </div>

</form>
