import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrCode, LinkPathListApiInput } from '../../shared/models';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { LoggerService } from '../../core/logger.service';
import { FileItemService } from '../file-item.service';
import { DialogReportLinkComponent } from '../../link-consume/dialog-report-link/dialog-report-link.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildTransferItemService } from 'src/app/transfer/services/build-transfer-item.service';

@Component({
    selector: 'sync-preview-link',
    templateUrl: './preview-link.component.html',
})
export class PreviewLinkComponent implements OnInit, OnDestroy {
    @Input() public type: string; // binding from component.
    public item: sync.IFile;
    public list: sync.IFile[] = [];
    public locId: number;
    public initialized = false;
    public ctx: string;
    public notice = 0;
    public errcode: ErrCode;
    public upsell = false;
    public isPro = 0;
    public isBusiness = 1; // set default 1 so that it's not shown immediately

    //white label attributes
    public isWhiteLabel: boolean;
    public linkTextColor: string;
    public buttonTextColor: string;
    public buttonPrimaryColor: string;
    public url: string;
    public mimeType: string;

    private subscription: Subscription;
    private prevRouterState: any;
    public compat = 0;

    constructor(
        private linkPathListService: LinkFileListService,
        private loggerService: LoggerService,
        private fileItemService: FileItemService,
        private modalService: NgbModal,
        private syncCookieService: SyncCookieService,
        private route: ActivatedRoute,
        private router: Router,
        private buildTransferItemService: BuildTransferItemService,
    ) {}

    ngOnInit() {
        this.prevRouterState = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.locId = this.route.snapshot.params['id'];
        // this.ctx = this.$stateParams.ctx;
        let dispatched = false;

        const rawKey = this.linkPathListService.sanitizeKey(
            this.route.snapshot.params['key']
                ?  this.route.snapshot.params['key']
                : this.route.snapshot.fragment
        );

        if (!this.type) {
            this.type = this.route.snapshot.params['type'];
        }

        const linkPass = this.syncCookieService.getLinkPassword(
            this.route.snapshot.params['cachekey']
        );
        this.initialized = false;

        if (this.type == 'doc' || this.type == 'office' || this.type == 'officefse' || this.type == 'officefsv') {
            // fetch the latest linkpathlisting for the current file
            // the doc/wopi component should have the latest copy of pathitem for preview
            // note: this will only fetch the pathlisting for a single file, not the entire parent folder
            dispatched = true;
            const input = new LinkPathListApiInput();
            input.sync_id = this.route.snapshot.params['id']; // fetch pathlist for current file only
            input.publink_id = this.route.snapshot.params['cachekey'];
            input.key = rawKey;
            input.passwordlock = linkPass;
            this.linkPathListService.dispatch(input);
        }

        this.subscription = this.linkPathListService
            .getSubscription()
            .subscribe(async (data) => {
                try {
                    if (data.error) {
                        if (data.error instanceof ErrCode) {
                            const { params, queryParams, fragment } = this.route.snapshot;
                            const errorCode = data.error.code;

                            // route to link-consume component, i.e., parent of preview-link
                            const navigationParams = ['/dl', params['cachekey']];
                            if (params['key']) { navigationParams.splice(2, 0, params['key']); }

                            let warningMessage = '';

                            switch (errorCode) {
                                case 8018:
                                    warningMessage = 'Password is missing or expired';
                                    break;
                                case 8026:
                                    warningMessage = 'File has been deleted';
                                    break;
                                case 400:
                                    warningMessage = 'Link has been removed';
                                    break;
                                default:
                                    this.loggerService.e('An error was received loading link preview', data.error);
                                    throw new ErrCode(errorCode, data.error.msg);
                            }

                            if (warningMessage) {
                                this.loggerService.warn(warningMessage);
                                this.router.navigate(navigationParams, {
                                    ...this.linkPathListService.decorateQueryParams(queryParams),
                                    ...this.linkPathListService.decorateFragment(true, fragment)
                                });
                            }
                        }

                    }
                    if (
                        data &&
                        data.pathlist &&
                        data.pathlist.length &&
                        data.loaded &&
                        data.sorted
                    ) {
                        this.compat = data.compat;
                        if (!this.compat &&
                            (this.type == 'video' ||
                                this.type == 'doc' ||
                                this.type == 'pdf' ||
                                this.type == 'office' ||
                                this.type == 'officefse' ||
                                this.type == 'officefsv'
                            )
                        ) {
                            this.type = 'default';
                        }

                        this.list = data.pathlist.filter((val) => {
                            return this.fileItemService.isImage(val);
                        });

                        const item = data.pathlist.filter((value) => {
                            if (value.sync_id == this.route.snapshot.params['id']) {
                                return true;
                            }
                        });
                        if (item.length == 0) {
                            throw new ErrCode(1804);
                        } else if (item.length == 1) {
                            this.item = item[0];
                        } else {
                            throw new ErrCode(
                                1000,
                                'This file listing has an error'
                            );
                        }

                        if (this.compat) {
                            const streamItem =
                                await this.buildTransferItemService.getStreamingDetails(this.item);

                            if (streamItem.url && streamItem.mimeType) {
                                this.type = 'video';
                                this.url = streamItem.url;
                                this.mimeType = streamItem.mimeType;
                            }
                        }

                        if (
                            (this.type != 'default' && this.type != 'office' && this.type != 'officefse' && this.type != 'officefsv') &&
                            !this.fileItemService.isPreviewable(this.item) &&
                            !this.mimeType
                        ) {
                            this.type = 'default';
                            this.notice = 1;
                        }
                        this.isPro = data.is_pro;
                        this.isBusiness = data.is_business;
                        this.initialized = true;
                    } else if (!dispatched) {
                        dispatched = true;
                        const input = new LinkPathListApiInput();
                        input.sync_id = this.route.snapshot.queryParams['sync_id'];
                        input.publink_id = this.route.snapshot.params['cachekey'];
                        input.key = rawKey;
                        input.passwordlock = linkPass;
                        this.linkPathListService.dispatch(input);
                        this.loggerService.info('Dispatched');
                    }
                    //get white label attributes
                    if (data.loaded && data.sorted) {
                        if (data.image_cachekey) {
                            this.isWhiteLabel = true;
                            this.linkTextColor = data.link_text_color;
                            this.buttonPrimaryColor = data.button_primary_color;
                            this.buttonTextColor = data.button_text_color;
                        }
                    }
                } catch (ex) {
                    this.loggerService.e('Cannot process link', ex);
                    this.type = 'error';
                    this.errcode = ErrCode.fromException(ex);
                    this.initialized = true;
                }
            });
    }

    public onStateChange(event: any) {
        this.type = event.type;
        this.upsell = event.upsell;
    }

    public showReportDialog() {
        const ref = this.modalService.open(DialogReportLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.linkID = this.route.snapshot.params['cachekey'];
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.router.routeReuseStrategy.shouldReuseRoute = this.prevRouterState;
    }
}
