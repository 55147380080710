<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Auto renewal</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            Disabling automatic renewal can result in your Sync account expiring.
        </div>
    </div>

    <hr/>

    <div class="row">
        <div class="col-sm-4">Status:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="disableautorenew"
                        id="autorenew-enabled"
                        value="0"
                        [(ngModel)]="autoRenew"
                    /> Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="disableautorenew"
                        id="autorenew-disabled"
                        value="1"
                        [(ngModel)]="autoRenew"
                    /> Disabled
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        (click)="save()"
        [disabled]="spinner"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Save settings
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>