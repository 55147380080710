
import { FileListModule } from '../file-list/file-list.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCopyComponent } from './dialog-copy/dialog-copy.component';
import { DialogPurgeComponent } from './dialog-purge/dialog-purge.component';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ErrorModule } from '../error/error-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogRestoreComponent } from './dialog-restore/dialog-restore.component';
import { UtilModule } from '../util/util-module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgbModalModule,
        UtilModule,
        SharedModule,
        FileListModule,
        ErrorModule,
        UtilModule,
        ReactiveFormsModule,
    ],
    providers: [],
    declarations: [
        DialogCopyComponent,
        DialogPurgeComponent,
        DialogDeleteComponent,
        DialogRestoreComponent
    ],
    entryComponents: [
        DialogPurgeComponent,
        DialogDeleteComponent,
        DialogRestoreComponent,
        DialogCopyComponent
    ],
})

export class FileActionsModule {}


