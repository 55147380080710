import { HttpErrorResponse } from '@angular/common/http';
import { ErrCode } from '../../../models/err-code.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../../../core/api.service';
import { Base64Service } from '../../../../core/base64.service';
import { CommentService } from '../../../../core/comment.service';
import { LoggerService } from '../../../../core/logger.service';
import {
    AnonymousCreationParams,
    Comment,
    CommentCreationParams,
    ReplyCreationParams,
    SuccessRes,
    CommentDeleteData,
    CommentUpdateData,
    CommentUpdateParams,
    DeleteParams,
} from '../../../models/commments.model';
import { Subscription } from 'rxjs';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { LinkFileListService } from '../../../../link-consume/services/link-file-list.service';
import { SyncCryptService } from '../../../../core/crypt/sync-crypt.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { BaseApiOutput, User } from '../../../models';
import { IFile } from 'sync';
import * as fromLinkFileList from '../../../../reducers/link-file-list.reducer';
import { environment } from '../../../../../environments/environment';
import { IFrameMessage } from '../comment-cp-service/comment-cp-iframe.component';
import { UpdateAction } from '../../../../actions/auth.actions';
import { IFrameReadyAction } from '../../../../actions/link-file-list.actions';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

interface CommentDatakeySetApiOutput extends BaseApiOutput {
    enc_comment_key: string;
}

@Component({
    selector: 'sync-comment-block',
    templateUrl: './comment-block.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CommentBlockComponent implements OnInit, OnDestroy {

    private static cpHost = environment.cphost.replace(/\/+$/, '');
    private static resolveCallback: Function;
    private static rejectCallback: Function;
    private static iframe: Window;

    @Input() item: IFile;
    @Input() ontoggle: Function;
    @ViewChild('commentThread') private scrollContainer: ElementRef;

    public linkTextColor: string;
    public buttonPrimaryColor: string;
    public buttonTextColor: string;
    public comments: Comment[];
    public cdk: string;
    public allowComment: number;
    public ownerId: number;
    public user: User | undefined;
    public tempSession: string; //base64 string represents a session token for anonymous users, empty if authenticated
    public locId: string; // [share id]-0-[publink cachekey]
    public publinkId: string;
    public link: fromLinkFileList.State;
    public errCode: ErrCode;
    public hasNewComments: boolean; // true if comments server has new comments (relative to user)
    public showNotification: boolean;
    public commentCPServiceHost;
    public spinner: boolean;
    public isCommentExpanded = false;

    private timer: number; //interval timer ID
    private sub: Subscription;
    private sub1: Subscription;
    private sub2: Subscription;
    private movementEvents = ['keydown', 'click']; //types of user movement that delays reloading the page
    private refreshRate = 60 * 1000; // secs
    private lastReloadTime: number; //time in miliseconds since the last time user reloads comments
    private encCdk: string;

    constructor(
        private linkPathList: LinkFileListService,
        private crypt: SyncCryptService,
        private store: Store<fromRoot.State>,
        private log: LoggerService,
        private commentService: CommentService,
        private base64: Base64Service,
        private api: ApiService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
    ) {}


    private static sendMessage(action: string, data: any) {
        this.iframe.postMessage({ action: action, data: data }, this.cpHost);
    }

    public static async signRequest(input: BaseApiInput) {
        return new Promise<BaseApiInput>((resolve, reject) => {
            this.resolveCallback = resolve;
            this.rejectCallback = reject;
            this.sendMessage('sign', input);
        });
    }

    async ngOnInit() {
        this.spinner = true;
        this.commentCPServiceHost =
            this.sanitizer.bypassSecurityTrustResourceUrl(
                `${CommentBlockComponent.cpHost}/comment-iframe/cm`
            );

        window.addEventListener('message', this.handleIFrameMessage.bind(this));
        this.movementEvents.forEach((e) =>
            document.addEventListener(e, this.resetError.bind(this), false)
        );

        //get comment settings
        this.sub = this.linkPathList
            .getSubscription()
            .subscribe(async (data) => {
                if (data.loaded && data.sorted) {
                    console.log('link data:', data);
                    this.link = data;
                    this.ownerId = data.owner_id;
                    this.allowComment = data.allow_comment;
                    this.publinkId = data.publink_id;
                    const shareId = data.sharekey_id.match(/(.*)-1$/)[1];
                    this.locId = `${shareId}-0-${this.publinkId}`;
                    if (data.image_cachekey) {
                        this.linkTextColor = data.link_text_color;
                        this.buttonPrimaryColor = data.button_primary_color;
                        this.buttonTextColor = data.button_text_color;
                    }
                    this.allowComment = data.allow_comment;
                    // encrypted comment key, once set, may not be unset
                    if (data.enc_comment_key) {
                        this.encCdk = data.enc_comment_key;
                    }

                    const derivedKey = await this.processLinkKey(data);

                    //generate new comment data key (cdk) only when comment allowed AND no cdk exists yet
                    if (this.allowComment) {
                        if (!this.encCdk) {
                            this.log.info('generating comment key');
                            try {
                                this.encCdk = await this.createCommentDataKey(
                                    data,
                                    derivedKey
                                );
                                this.log.info('done generating comment key');
                            } catch (ex) {
                                this.log.error(
                                    `Error while encrypting comment data key ${JSON.stringify(
                                        ex
                                    )}`
                                );
                                this.errCode = new ErrCode(
                                    1100,
                                    'Error while encrypting comment data key'
                                );
                                return;
                            }
                        }
                        console.log('enc cdk:', this.encCdk);

                        try {
                            this.cdk = await this.crypt.commentDatakeyDecrypt(
                                this.encCdk,
                                derivedKey
                            );
                            this.log.info('done decrypting comment key');
                        } catch (ex) {
                            this.log.error(
                                `Error while decrypting comment data key ${JSON.stringify(
                                    ex
                                )}`
                            );
                            this.errCode = new ErrCode(
                                1100,
                                'Error while decrypting comment data key'
                            );
                            return;
                        }
                    }
                }
                if (data.iframeReady) {
                    this.getComments();
                } else {
                    this.spinner = false;
                }
            });
        //get user info
        this.sub1 = this.store
            .pipe(select(fromRoot.getAuthUser))
            .subscribe((data) => {
                if (data) {
                    this.user = data;
                    this.tempSession = '';
                } else {
                    //else the user is null
                    this.user = null;
                }
            });

        CommentBlockComponent.iframe = (<HTMLIFrameElement>(
            document.getElementById('sync-comment-cp-iframe')
        )).contentWindow;
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        window.clearInterval(this.timer);
        // this.movementEvents.forEach((e) =>
        //     document.removeEventListener(
        //         e,
        //         this.resetInterval.bind(this),
        //         false
        //     )
        // );
        if (this.user && !this.user.exists) {
            this.user = null;
        }
    }

    async createAnonymousSession(
        input: AnonymousCreationParams
    ): Promise<void> {
        try {
            //create a temp user for anonymous user
            this.user = new User({
                display_name: this.base64.encode(input.name),
            });
            //create a temp 'session token' for the user
            this.tempSession = `${this.crypt.bytesToB64(
                this.crypt.mkDataKey()
            )}`;
            this.log.info(`tempSession: ${this.tempSession}`);
            if (input.commentID) {
                const newReply: ReplyCreationParams = {
                    parent_id: input.commentID,
                    author_id: this.user.id,
                    author_name: this.user.display_name,
                    content: input.content,
                    temp_session: this.tempSession,
                };
                newReply.content = await this.commentService.encryptContent(
                    this.cdk,
                    newReply.content
                );

                const payload = {
                    servtime: Date.now(),
                    access_token: '',
                    signature: '',
                    ...newReply,
                    content_length: input.content.length.toString(),
                };
                const signedInput = await CommentBlockComponent.signRequest(
                    payload
                );

                //api call to comment server, posting a reply
                const response = await this.commentService.createReply(
                    newReply,
                    signedInput
                );
                this.log.info('Reply has been created');
                this.getComments();
                this.sendNotification('POST');
            } else if (input.content) {
                const newComment: CommentCreationParams = {
                    loc_id: this.route.snapshot.params['key'],
                    author_id: this.user.id,
                    author_name: this.user.display_name,
                    content: input.content,
                    temp_session: this.tempSession,
                };
                this.createComment(newComment);
            }
            //else user create a anonymous session just to view detail
        } catch (ex) {
            this.log.error(
                `Error while creating/posting anonymous comment: ${JSON.stringify(
                    ex
                )}`
            );
        }
    }

    private async checkNewComments() {
        if (!this.allowComment) {
            return;
        }
        const input = {
            servtime: Date.now(),
            access_token: '',
            signature: '',
            locId: this.locId,
            requestTime: this.lastReloadTime.toString(),
        };
        const signedInput = await CommentBlockComponent.signRequest(input);
        //check for new comments
        this.hasNewComments = await this.commentService.checkNewComments(
            signedInput
        );
        this.showNotification = this.hasNewComments;
    }

    async createComment(newComment: CommentCreationParams) {
        newComment.loc_id = this.locId;
        const contentLength = newComment.content.length;
        try {
            //encrypt comment's content
            newComment.content = await this.commentService.encryptContent(
                this.cdk,
                newComment.content
            );
            const input = {
                servtime: Date.now(),
                access_token: '',
                signature: '',
                ...newComment,
                content_length: contentLength.toString(),
            };

            const signedInput = await CommentBlockComponent.signRequest(input);

            // newComment.author_name = await this.commentService.encryptContent(this.cdk, newComment.author_name);
            //api call to comment server, posting a comment
            const response = await this.commentService.createComment(
                signedInput
            );
            if (response) {
                this.log.info('Comment has been created');
                await this.getComments();
                this.scrollToBottom();
                this.sendNotification('POST');
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getComments() {
        try {
            const input: BaseApiInput = {
                servtime: Date.now(),
                access_token: '',
                signature: '',
            };
            // const signedInput = await this.crypt.signApiReq(input);
            const signedInput = await CommentBlockComponent.signRequest(input);
            const response = await this.commentService.getComments(
                this.locId,
                signedInput
            );
            if (response && response['success'] === 1) {
                this.log.info('comments have been fetched');
                const data = (response as SuccessRes)['data'] as Comment[];
                //decrypt comments and replies content
                data.forEach(async (comment) => {
                    comment.content = await this.commentService.decryptContent(
                        this.cdk,
                        comment.content,
                        comment._id as string
                    );
                    if (comment.replies.length > 0) {
                        comment.replies.forEach(async (reply) => {
                            reply.content =
                                await this.commentService.decryptContent(
                                    this.cdk,
                                    reply.content,
                                    reply.reply_id as string
                                );
                        });
                    }
                });

                this.comments = data;
            } else {
                this.comments = [];
            }
            this.lastReloadTime = new Date().getTime();
            this.hasNewComments = false;
            this.showNotification = this.hasNewComments;
            this.resetReloadTimer();
            this.spinner = false;
            // this.scrollToBottom();
        } catch (errResponse) {
            this.spinner = false;
            this.handleError(errResponse);
        }
    }

    async updateComment(data: CommentUpdateData) {
        //encrypt comment's content
        const contentLength = data.content.length;
        data.content = await this.commentService.encryptContent(
            this.cdk,
            data.content
        );
        const commentUpdate: CommentUpdateParams = {
            author_id: data.author_id,
            content: data.content,
            temp_session: data.temp_session,
        };
        const input = {
            servtime: Date.now(),
            access_token: '',
            signature: '',
            ...commentUpdate,
            content_length: contentLength.toString(),
        };
        try {
            const signedInput = await CommentBlockComponent.signRequest(input);
            const response = await this.commentService.updateComment(
                data,
                signedInput
            );
            if (response && response['success'] === 1) {
                this.log.info('Comments has been updated');
                this.getComments();
                this.sendNotification('PATCH');
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async deleteComment(data: CommentDeleteData) {
        const deleteParams: DeleteParams = {
            author_id: data.author_id,
            temp_session: encodeURIComponent(data.temp_session), //encode special character
        };
        const input = {
            servtime: Date.now(),
            access_token: '',
            signature: '',
            ...deleteParams,
        };
        try {
            const signedInput = await CommentBlockComponent.signRequest(input);

            const response = await this.commentService.deleteComment(
                data,
                signedInput
            );
            if (response && response['success'] === 1) {
                this.log.info(`comment id ${data._id} has been deleted`);
                this.sendNotification('DEL', false);
                this.getComments();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    resetError() {
        this.errCode = null;
    }

    private resetReloadTimer() {
        window.clearInterval(this.timer);
        this.timer = window.setInterval(
            () => this.checkNewComments(),
            this.refreshRate
        );
    }

    private handleIFrameMessage(e: MessageEvent) {
        if (!e.origin.startsWith(CommentBlockComponent.cpHost)) {
            return;
        }

        switch (e.data.action) {
            case 'update-user':
                //update User object in the store for ln2.sync.com
                this.store.dispatch(new UpdateAction(e.data.data));
                this.store.dispatch(new IFrameReadyAction(true));
                this.log.info('iframe ready');
                break;
            case 'signed':
                CommentBlockComponent.resolveCallback(e.data.data);
                break;
            default:
                this.log.error('unsupported action');
        }
    }

    /**
     *
     * @param {string} method 'POST' if event is a comment creation, 'PATCH' or 'PUT' if event is a comment update, 'DEL' if delete
     * @param {boolean} sendEmail true if an email is also sent
     */
    private sendNotification(method: string, sendEmail = true) {
        if (!this.link.comment_notification) {
            this.log.info('Comment notification is disabled');
            return;
        }
        //send email only if user is not link owner
        if (this.user && this.user.id === this.ownerId) {
            sendEmail = false;
        }

        //create event for this user
        if (this.user.exists) {
            this.notify(this.user.id, method, false);
        }

        //send notif to link owner if not the same user (to avoid double notifications)
        if (this.user.id !== this.ownerId) {
            this.notify(this.ownerId, method);
        }
    }

    private handleError(errResponse) {
        if (errResponse instanceof HttpErrorResponse) {
            this.errCode = new ErrCode(
                errResponse.status,
                errResponse.error.message || errResponse.message,
                errResponse.error.detail || errResponse.name
            );
        } else {
            this.errCode = ErrCode.fromException(errResponse);
        }
    }

    hideNotification() {
        this.showNotification = false;
    }

    private notify(recipientId: number, method: string, sendEmail = true) {
        this.api.execute('notifycomment', {
            publink_id: this.publinkId,
            link_owner_id: this.ownerId,
            recipient_id: recipientId,
            display_name: this.user.exists
                ? this.user.display_name
                : this.base64.encode('An anonymous user'),
            link_name: this.base64.encode(this.item.name),
            link_url: this.base64.encode(window.location.href),
            method: method,
            send_email: sendEmail,
        });
    }

    scrollToBottom(event?: any) {
        if (event && this.isCommentExpanded) {
            event.stopPropagation();
        }
        try {
            this.scrollContainer.nativeElement.scrollIntoView(false);
            this.scrollContainer.nativeElement.scrollTop =
                this.scrollContainer.nativeElement.scrollHeight;
        } catch (err) {
            this.log.info('scrollContainer not initalized');
        }
    }

    private getLinkKey() {
        const key =
            this.item.context === 'applink'
                ? this.route.snapshot.params['cachekey']
                : this.route.snapshot.params['key'] || this.route.snapshot.fragment;
        return this.linkPathList.sanitizeKey(key);
    }

    private async processLinkKey(data: fromLinkFileList.State) {
        try {
            return await this.linkPathList.getKeyB64(
                data.linkversion,
                this.getLinkKey(),
                data.salt,
                data.iterations
            );
        } catch (ex) {
            this.log.error(
                `Error while processing link key ${this.getLinkKey()} ${JSON.stringify(
                    ex
                )}`
            );
            this.errCode = new ErrCode(1100, 'Error while processing link key');
            return;
        }
    }

    private async createCommentDataKey(
        data: fromLinkFileList.State,
        derivedKey: string
    ) {
        this.cdk = this.crypt.bytesToB64(this.crypt.mkDataKey());
        try {
            if (this.item.context === 'applink') {
                this.encCdk = await this.crypt.appLinkDatakeyEncrypt(
                    this.cdk,
                    derivedKey
                );
            } else {
                this.encCdk = await this.crypt.datakeyEncrypt(
                    this.cdk,
                    derivedKey
                );
            }
        } catch (ex) {
            throw ex;
        }

        // update CDK to the API side, if enc_comment_key is already set, the API returns the old key
        const response = await this.api.execute<CommentDatakeySetApiOutput>(
            'setcommentdatakey',
            {
                publink_id: this.publinkId,
                enc_comment_key: this.encCdk,
                passwordlock: data.passwordlock,
            }
        );

        return response.enc_comment_key;
    }

    /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer
            .bypassSecurityTrustStyle(`--linkTextColor: ${this.linkTextColor};
                                                        --buttonPrimaryColor: ${this.buttonPrimaryColor};
                                                        --buttonTextColor: ${this.buttonTextColor};
                                                    `);
    }

    public toggleComment() {
        this.isCommentExpanded = !this.isCommentExpanded;
        if (this.ontoggle) {
            this.ontoggle(this.isCommentExpanded);
        }
    }
}
