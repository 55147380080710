import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/user.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import {
    canBrowserOpen,
    canLinkBrowserOpen,
    getFileExt,
} from '../../shared/func';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { BlendEvent, User } from '../../shared/models';
import { TransferService } from '../../transfer/transfer.service';
import { FileItemService } from '../file-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WopiUpgradeComponent } from '../wopi-upgrade/wopi-upgrade.component';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-preview-default',
    templateUrl: './preview-default.component.html',
})
export class PreviewDefaultComponent implements OnInit, OnDestroy {
    @Input() public notice: number;
    @Input() public upsell: boolean; // binding
    @Input() public item: sync.IFile; // binding
    public url: string;
    public openSpinner = false;

    //white label attributes
    public isWhiteLabel: boolean;
    public buttonTextColor: string;
    public buttonPrimaryColor: string;
    public linkTextColor: string;

    public allowComment: 0 | 1 | 2;

    private sub: Subscription;
    public isAuth: boolean;
    public user: User;
    public isCommentExpanded: boolean;
    public isUploadAllowed: number;
    public compat = 0;
    public isFileEditAllowed: number;
    public isCwdShared: number;

    constructor(
        private buildTransferItemService: BuildTransferItemService,
        private transferService: TransferService,
        private linkPathListService: LinkFileListService,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private fileItemService: FileItemService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private blendService: BlendService,
    ) {}

    bypassSecurityTrustUrl(url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    }

    async ngOnInit() {
        console.log(this.item);
        // console.log(this.upsell);
        this.isAuth = this.userService.isAuthenticated();
        this.user = this.userService.getUser();

        this.sub = this.linkPathListService
            .getSubscription()
            .subscribe((data) => {
                if (data.loaded && data.sorted) {
                    this.isUploadAllowed = data.upload;
                    this.compat = data.compat;
                    this.isFileEditAllowed = data.file_edit;
                    this.isCwdShared = data.cwd.is_shared;
                    if (data.image_cachekey) {
                        this.isWhiteLabel = true;
                        this.buttonPrimaryColor = data.button_primary_color;
                        this.buttonTextColor = data.button_text_color;
                        this.linkTextColor = data.link_text_color;
                    }
                    this.allowComment = data.allow_comment;
                }
            });
        this.blendService.track(BlendEvent.VIEW_FILE, {
            type: 'default',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    public isOpenable() {
        if (this.item.context == 'link' && (this.item.previewonly == 8 || !this.compat)) {
            return false;
        }
        if (
            this.item.context == 'link' &&
            getFileExt(this.item.name) == 'pdf' &&
            !this.item.link_is_business
        ) {
            return false;
        }
        if (this.item.context == 'link' && this.fileItemService.isMSOffice(this.item, 'edit')) {
            return (
                this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared)
            );
        }
        if (!canBrowserOpen(this.item.name)) {
            return false;
        }
        // return this.item.compat
        //     ? canLinkBrowserOpen(this.item.name)
        //     : canBrowserOpen(this.item.name);
        return true;
    }

    public async openFile() {
        if (this.isOfficeEdit()) {
            this.openOfficeEdit();
        } else {
            if (this.item.context == 'applink') {
                this.url = this.item.compaturl;
            } else {
                this.url = await this.buildTransferItemService.getCompatUrl(
                    this.item,
                    this.buildTransferItemService.ACT_PREVIEW
                );
            }
            window.location.href = this.url;
        }
    }

    public isOfficeEdit() {
        return (
            this.fileItemService.isMSOffice(this.item, 'edit') && this.isAuth
        );
    }

    public openOfficeEdit() {
        const isLinkEdit = this.item.context == 'link'
            ? this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared)
            : false;
        if (isLinkEdit) {
            const navigationParams = ['/dl', this.route.snapshot.params['cachekey'], 'view', 'officefse', this.item.sync_id];

            // add key in navigation params array if it exists in router params, otherwise key after # in fragment will be used
            if (this.route.snapshot.params['key']) { navigationParams.splice(2, 0, this.route.snapshot.params['key']); }
            this.router.navigate(navigationParams, {
                ...this.linkPathListService.decorateQueryParams(this.route.snapshot.queryParams),
                ...this.linkPathListService.decorateFragment(this.compat, this.route.snapshot.fragment)
            });
        } else if (this.item.context == 'files') {
            if (this.user.can_preview) {
                this.router.navigate(['/file', this.item.sync_id, 'view', 'officefse'],
                    {
                        queryParams: {
                            view: this.route.snapshot.queryParams['view'],
                            page: this.route.snapshot.queryParams['page']
                        }
                    });
            } else {
                const ref = this.modalService.open(WopiUpgradeComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.componentInstance.id = this.route.snapshot.params['id'];
            }
        }
    }

    public downloadCompat() {
        if (this.item.context == 'applink') {
            window.location.href = this.item.compaturl_dl;
        } else {
            this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD).
                then((url) => {
                    this.item.compaturl_dl = url;
                    window.location.href = this.item.compaturl_dl;
                });
        }
    }

    public async download() {
        // this.spinner = true;
        this.item.blobtype = 'btFILE';
        // // this ctrl will only ever be run during a compat link
        // // or the user's file download.
        if (this.item.compat && this.item.context == 'link') {
            this.url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_DOWNLOAD
            );
            window.location.href = this.url;
        } else if (this.item.compat && this.item.context == 'applink') {
            this.url = this.item.compaturl_dl;
            window.location.href = this.url;
        } else if (this.item.context == 'link' && !this.item.compat) {
            this.transferService.queueDownload([this.item]);
        } else {
            this.transferService.queueDownload([this.item]);
        }
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }
}
