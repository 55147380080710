import { LinkListItem } from '../link-list-item.model';
import { WebPath } from '../webpath.model';
import { BaseApiOutput } from './base-api.model';

export class PathGetApiOutput extends BaseApiOutput {
    enc_name: string;
    type: string;
    id: number;
    sync_id: number;
    blob_id: number;
    pid: number;
    date: number;
    usertime: number;
    size: number;
    has_thumb1: number;
    has_thumb2: number;
    share_id: number;
    synctype: number;
    is_deleted: number;
    cachekey: number;
    link_cachekey;
}

export class PathGetApiInput {
    sync_id: number;
}


