import { Deserializable } from './deserializable.model';
export class Session implements Deserializable<Session> {

    device_id = 0;
    device_name = '';
    device_type_id = 0;
    id = '';
    ipaddr = '';
    is_current = 0;
    lastaction_servtime = 0;
    login_servtime = 0;
    useragent_browser = '';
    useragent_platform = '';
    useragent_version = '';

    constructor(params?: {[k: string]: any}) {
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}
