import { BaseApiOutput } from './base-api.model';


export class KeysNeedEncBySyncApiOutput extends BaseApiOutput {
    sync_id: number;
    metaname: string;
    servtime: number;
    sharekeys: { [sharekey_id: string]: string };
}

export class KeysNeedEncBySyncApiInput {
    sync_id: number;
}


