import { Directive, HostListener } from '@angular/core';
import { FileSelectionService } from '../file-list/services/file-selection.service';
import { TransferService } from './transfer.service';

/**
 * @description
 * Queues the selected files from the PathList factory.
 *
 * This only works in the main Sync of Vault folders.  Link downloads happen
 * inline, so are not queued via this directive
 */

@Directive({
    selector: '[syncQueuePathDownload]'
})
export class QueuePathDownloadDirective {
    constructor(
        private fileSelectionService: FileSelectionService,
        private transferService: TransferService
    ) {}

    @HostListener('click', ['$event']) click(event) {
        let clicks = 0;
        clicks++;
        if (clicks === 1) {
            this.transferService
                .queueDownload(this.fileSelectionService.getSelected())
                .then(() => {
                    clicks = 0;
                })
                .catch((error) => {
                    clicks = 0;
                    console.error(error);
                });
        }
    }
}
