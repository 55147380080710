import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { getFileExt } from '../../shared/func';
import { BlendEvent, ErrCode } from '../../shared/models';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { TransferService } from '../../transfer/transfer.service';
import { FileItemService } from '../file-item.service';
import { WopiOptinComponent } from '../wopi-optin/wopi-optin.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-preview-doc',
    templateUrl: './preview-doc.component.html',
})
export class PreviewDocComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public item: sync.IFile;
    @Output() public stateChange = new EventEmitter();
    public errcode: ErrCode;
    public spinner: boolean;
    public url: string;
    public transferItem: sync.ITransferItemDownload;
    public sessionId: string;
    public errorMsg: string;
    public viewUrl: any;
    public accusoftErr: number;
    public viewEvent: EventListenerObject;

    // white label attributes
    public buttonTextColor: string;
    public buttonPrimaryColor: string;
    public isWhiteLabel: boolean;

    public docPassword: string;

    public allowComment: 0 | 1 | 2;
    public isCommentExpanded: boolean;

    private host: string;
    private subscription: Subscription;

    constructor(
        private linkFileListService: LinkFileListService,
        private loggerService: LoggerService,
        private transferService: TransferService,
        private buildTransferItemService: BuildTransferItemService,
        private sanitizer: DomSanitizer,
        private apiService: ApiService,
        private base64Service: Base64Service,
        private http: HttpClient,
        private fileItemService: FileItemService,
        private userService: UserService,
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute,
        private blendService: BlendService
    ) {}

    bypassSecurityTrustResourceUrl(value: any) {
        if (value) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }
        return null;
    }
    ngOnInit() {
        this.viewEvent = this.handleViewerReady.bind(this);
        this.init();
    }

    private getHost() {
        const ext = getFileExt(this.item.name);
        if (['xls', 'xlsx'].indexOf(ext) > -1) {
            return environment.previewhost1;
        }
        if (
            ext == 'pdf' &&
            this.item.linkID &&
            this.item.size <= 256000 &&
            !this.item.link_is_pro
        ) {
            return environment.previewhost3;
        }
        return environment.previewhost2;
    }

    public async init(password?: string) {
        let tItem, doc;
        this.spinner = true;
        this.sessionId = null;

        // white label attributes
        this.subscription = this.linkFileListService
            .getSubscription()
            .subscribe((data) => {
                if (data.loaded && data.sorted) {
                    if (data.image_cachekey) {
                        this.isWhiteLabel = true;
                        this.buttonTextColor = data.button_text_color;
                        this.buttonPrimaryColor = data.button_primary_color;
                    }
                    this.allowComment = data.allow_comment;
                }
            });

        try {
            // NOTE: Previewtoken/PathDataApi will fail if link hasn't been backfilled (applink)
            if (this.item.context == 'applink') {
                if (
                    !this.item.previewtoken ||
                    this.item.previewtoken == '' ||
                    this.item.previewtoken == 'error'
                ) {
                    this.loggerService.info(
                        'No preview token provided by extapi ' +
                            this.item.sync_id
                    );
                    this.stateChange.emit({ type: 'default', upsell: true });
                    return;
                }
            } else {
                // run get file keys first so that we don't download the entire file to
                // to learn we can't preview.
                const keys = await this.buildTransferItemService.getFileKeys([
                    this.item,
                ]);
                if (keys && (!keys.previewtoken || keys.previewtoken == '')) {
                    this.loggerService.info(
                        'No preview token available ' + this.item.sync_id
                    );
                    this.stateChange.emit({ type: 'default', upsell: true });
                    return;
                }
            }

            tItem = await this.transferService.getFileDocPreview(this.item);
            if (this.display_office_optin(this.item)) {
                const ref = this.modalService.open(WopiOptinComponent , {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.result.then((shouldDisplayInOffice: boolean) => {
                    if (shouldDisplayInOffice) {
                        const prev = this.router.routeReuseStrategy.shouldReuseRoute;
                        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                        this.router.navigate(['/file', this.route.snapshot.params['id'], 'view', 'office'], {
                            queryParams: {
                                view: this.route.snapshot.queryParams['view'],
                                page: this.route.snapshot.queryParams['page']
                            }
                        }).then(() => {
                            this.router.routeReuseStrategy.shouldReuseRoute = prev;
                        });
                    }
                });
            }
        } catch (ex) {
            this.loggerService.error(ex);
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            // this.errorMsg = 'Unable to download the file';
            this.loggerService.error(
                'Error doing doc preview for ' + this.item.sync_id
            );
        }

        this.blendService.track(BlendEvent.VIEW_FILE, {
            type: this.fileItemService.isPDF(this.item) ? 'pdf' : 'doc',
            preview_preference: 'Sync',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });

        this.host = this.getHost();
        this.loggerService.warn(
            'Environment' +
                environment.viewhost +
                ' - ' +
                this.host +
                ' for ' +
                getFileExt(this.item.name)
        );

        try {
            doc = await this.makePreview(this.host, tItem, password);
        } catch (ex) {
            this.loggerService.error(ex);
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            this.loggerService.error(
                'Error preparing doc preview for ' + this.item.sync_id
            );
        }
        // The API did not return a previewtoken, so we set it to "error" so that
        // the preview pas server can correctly reject the request.
        if (!tItem.previewtoken) {
            this.loggerService.info('No preview token available');
            tItem.previewtoken = 'error';
            this.stateChange.emit({ type: 'default', upsell: true });
            return;
        }

        this.url = tItem.renderFile.url;
        try {
            await this.sendForPreview(
                this.host,
                tItem.renderFile.url,
                doc.viewingSessionId,
                tItem.previewtoken,
                getFileExt(tItem.name)
            );
            this.spinner = false;
            this.errcode = undefined;
            this.accusoftErr = undefined;
            this.sessionId = doc.viewingSessionId;

            if (tItem.linkID) {
                this.loggerService.info('Consume link');
                this.consume([tItem.linkID]);
            }
        } catch (ex) {
            this.loggerService.error(
                `An error occurred putting file for preview`
            );
            switch (ex.status) {
                case -1:
                case 0:
                    this.errcode = new ErrCode(3201);
                    break;
                case 403:
                    const errcode = parseInt(ex.headers('x-preview-error'), 10);
                    if (errcode) {
                        this.errcode = new ErrCode(errcode);
                    } else {
                        this.errcode = new ErrCode(
                            9403,
                            'Error accessing this resource'
                        );
                    }
                    break;
                default:
                    this.errcode = new ErrCode(3202);
                    break;
            }
            this.spinner = false;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        window.removeEventListener('message', this.viewEvent);
    }

    ngAfterViewInit(): void {
        // need to tell sce that the viewhost is a trusted resource.
        this.viewUrl = this.bypassSecurityTrustResourceUrl(
            environment.viewhost
        );
        window.addEventListener('message', this.viewEvent);
    }

    /**
     * @description
     * Consumes a link and increments the download counter
     *
     * This is a non-authenticated call.
     * @param  {Array<string>} publink_id The link cachekey
     * @returns {Promise} The promise from the API call
     */
    public consume(links: Array<string>) {
        const publinks = [];
        for (let i = 0, len = links.length; i < len; i++) {
            publinks.push({ publink_id: links[i] });
        }
        return this.apiService.execute('linkconsume', { links: publinks });
    }

    public submitPassword() {
        this.spinner = true;
        this.init(this.docPassword);
    }

    private handleViewerReady(e: MessageEvent) {
        // if (e.origin !== 'http://the-trusted-iframe-origin.com') return;
        //  || e.message === "myevent"
        const options = {
            encryption: false,
            // Set the `options.documentID` to the session ID provided by PCCIS.
            // This property is *required*.
            documentID: this.sessionId,

            // Set the `options.documentDisplayName` to name of the document we use.
            // This property is *required*.
            documentDisplayName: this.item.name,
            // The `options.template` object specifies all of the templates
            // that layout the UI of the client.
            // This property is *required*.

            // The `options.icons` specifies the svg icons to be used in the viewer
            // This property is *required*.
            // icons : exampleHelper.icons,

            // The `options.imageHandlerUrl` specifies the path to the web tier services, which the
            // viewer uses.
            // The default value is 'pcc.ashx', so it must be set if using non-.NET web tiers.
            imageHandlerUrl: this.host, // 'https://preview1.sync.com',

            // The `options.resourcePath` specifies the path to images used by the viewer control.
            // The default value is 'img'.
            viewerAssetsPath: '/',

            // immediateActionMenuActionsFilter: {
            //     copy: true,
            //     hyperlink: true,
            //     cancel: true,
            //     redact: false,
            //     highlight: false,
            //     comment: false
            // },

            // The `options.redactionReasons` specifies the path to images used by the viewer control.
            // The parameter is required
            // redactionReasons: exampleHelper.redactionReasons
            uiElements: {
                fullScreenOnInit: true,
                // Use `options.uiElements.viewTab` to hide or show the View Tab.
                // Default is `true`.
                viewTab: false,
                // Hide or show the E-Signature Tab. Default is true.
                esignTab: false,

                // Use `options.uiElements.searchTab` to hide or show the Search Tab.
                // Default is `true`.
                searchTab: false,

                // Use `options.uiElements.annotateTab` to hide or show the Annotate tab.
                // Default is `true`.
                annotateTab: false,

                // Use `options.uiElements.redactTab` to hide or show the Redact tab.
                // Default is `true`.
                redactTab: false,

                // Use `options.uiElements.copyPaste` to hide or show the Text Select Tool.
                // Default is `true`.
                copyPaste: !this.item.previewonly,

                // Use `options.uiElements.download` to hide or show the Download Button.
                // Default is `true`.
                download: false,

                // Use `options.uiElements.printing` to hide or show the Print Button.
                // Default is `true`.
                printing: false, //!(this.item.previewonly)
            },
        };
        // devtools with ngrx-store causes errors and breaks the handler.
        if (e.data && e.data.source && e.data.source.indexOf('devtools') > -1) {
            return;
        }
        if (e.origin !== environment.viewhost) {
            return;
        }
        if (
            e.data === 'sync-viewer-ready' &&
            e.origin == environment.viewhost
        ) {
            const iframe = (<HTMLIFrameElement>(
                document.getElementById('sync-viewer')
            )).contentWindow;
            iframe.postMessage(options, environment.viewhost);
        } else if (
            e.data === 'sync-viewer-error' &&
            e.origin == environment.viewhost
        ) {
            this.loggerService.error('An error occurred loading the link');
            this.loggerService.error('Session ID: ' + this.sessionId);
            this.loggerService.error('SyncId: ' + this.item.sync_id);
            this.loggerService.error('Link: ' + this.item.linkID);
            this.loggerService.error('Blob: ' + this.item.blob_id);
        } else if (
            e &&
            e.data &&
            typeof e.data === 'string' &&
            e.data.indexOf('sync-viewer-error-number') > -1 &&
            e.origin == environment.viewhost
        ) {
            const num = parseInt(
                e.data.replace('sync-viewer-error-number-', ''),
                10
            );

            switch (num) {
                case 4001:
                    this.loggerService.warn('The document requires a password');
                    this.sessionId = undefined;
                    if (this.docPassword) {
                        // user submitted a bad password
                        this.errcode = new ErrCode(3213);
                    }
                    break;
                case 5001:
                    //accusoft error occurred : Unable to generate page with code 5001
                    this.stateChange.emit({ type: 'default', upsell: false });
                    break;
                default:
                    this.loggerService.error('Error was ' + num);
                    this.errcode = new ErrCode(
                        3200,
                        'An error was received ' + num
                    );
            }
            this.accusoftErr = num;
        }
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }

    public makePreview(
        host,
        tItem: sync.ITransferItemDownload,
        password?: string
    ): Promise<any> {
        const ext = getFileExt(tItem.filename);
        const markupId = this.base64Service.encode(tItem.blob_id);
        const opts = {
            source: {
                type: 'upload',
                displayName: tItem.filename,
                fileExtension: ext,
                markupId: markupId,
            },
            serverSideSearch: 'disabled',
            origin: {
                blob_id: tItem.blob_id,
                name: this.base64Service.encode(tItem.filename),
                size: tItem.filesize,
            },
            render: {
                html5: {
                    alwaysUseRaster: false,
                    vectorTolerance: 1.0,
                },
            },
        };
        if (password) {
            opts['password'] = password;
        }
        if (
            getFileExt(tItem.filename) == 'xls' ||
            getFileExt(tItem.filename) == 'xlsx'
        ) {
            opts['serverCaching'] = 'none';
        }

        return new Promise<any>(async (resolve, reject) => {
            this.http
                .post(host + '/ViewingSession', opts, { withCredentials: true })
                .subscribe(
                    (result: any) => {
                        resolve(result);
                    },
                    (err: any) => {
                        this.loggerService.error(
                            'An error occurred making preview viewing session'
                        );
                        this.loggerService.error(
                            'INPUT: ' +
                                JSON.stringify({
                                    type: 'upload',
                                    displayName: tItem.filename,
                                    fileExtension: ext,
                                    markupId: markupId,
                                })
                        );
                        this.loggerService.error(
                            'OUTPUT: ' + JSON.stringify(err)
                        );
                        reject({ errcode: 3200 });
                    }
                );
        });
    }

    public async sendForPreview(
        host: string,
        url: string,
        sessionId: string,
        token: string,
        ext: string
    ) {
        this.http
            .get(url, { responseType: 'arraybuffer', withCredentials: true })
            .subscribe((resp) => {
                if (resp) {
                    console.log('previewhost = ' + environment.previewhost2);
                    // send the data to the preview server

                    return new Promise<any>(async (resolve, reject) => {
                        await this.http.put(
                            [
                                host,
                                '/ViewingSession/u',
                                sessionId,
                                '/SourceFile?FileExtension=',
                                ext,
                            ].join(''),
                            new Blob([resp]),
                            {
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/octet-stream',
                                    'X-Preview-Token': token,
                                },
                            }
                        ).subscribe();
                    });
                }
            });
    }

    private display_office_optin(item: sync.IFile) {
        return (
            this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.userService.get('is_displayedofficeoptin') === 0 &&
            this.userService.get('can_preview') === 1
        );
    }
}
