import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User, UserInfoSetApiOutput, UserInfoSetApiInput, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { UserService } from '../../core/user.service';
import { ValidateService } from '../../core/validate.service';

@Component({
    selector: 'sync-dialog-displayname',
    templateUrl: './dialog-displayname.component.html'
})
export class DialogDisplaynameComponent implements OnInit {

    @Input() user: User;
    public displayName: string;
    public errcode: ErrCode;
    public spinner: boolean;
    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private base64: Base64Service,
        private userService: UserService,
        private validateService: ValidateService
    ) {}

    ngOnInit() {
    }

    public async save() {
        this.spinner = true;
        try {
            if (!this.validateService.isDisplayNameValid(this.displayName)) {
                throw { code: 1659 };
            }

            await this.api.execute<UserInfoSetApiOutput>('userinfoset', <UserInfoSetApiInput>{
                display_name_b64: this.base64.encode(this.displayName)
            });
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            this.spinner = false;
            console.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
    }
}
