import { DialogDisplayAvatarComponent } from './../dialog-display-avatar/dialog-display-avatar.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

import { User, UserRewards, UserDiskUsageApiOutput, PermissionActions } from '../../shared/models';
import { RewardsService } from '../services/rewards.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogDisplaynameComponent } from '../dialog-displayname/dialog-displayname.component';
import { DialogUsernameChangeComponent } from '../dialog-username-change/dialog-username-change.component';
import { DialogRewardsComponent } from '../dialog-rewards/dialog-rewards.component';
import { DialogEmailPreferenceComponent } from '../dialog-email-preference/dialog-email-preference.component';
import { DialogPreviewPreferenceComponent } from '../dialog-preview-preference/dialog-preview-preference.component';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { DialogCancelComponent } from '../dialog-cancel/dialog-cancel.component';
import { Subscription } from 'rxjs';
import { DialogCustomBrandComponent } from '../dialog-custom-brand/dialog-custom-brand.component';
import { environment } from '../../../environments/environment';
import { UserService } from '../../core/user.service';
import { DialogConfirmPasswordComponent } from '../dialog-confirm-password/dialog-confirm-password.component';

@Component({
    selector: 'sync-dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
    public user: User;
    public userRewards: UserRewards;

    public DU_STATUS_INIT = 0;
    public DU_STATUS_SUCCESS = 1;
    public DU_STATUS_INVALID = 2;
    public avatarEndpoint = `${environment.logohost}avatar/`;
    public avatarUrl: string;
    public errorImageUrl = `${environment.cphost}/images/anonymous.png`;
    public showCopiedText = false;
    public timeStamp: number; //used to force angular reload avatar url

    public du = {
        status: 0,
        spinner: false,
        vaultbytes: 0,
        vaultpercent: 0,
        syncbytes: 0,
        syncpercent: 0,
        unusedbytes: 0,
    };

    private sub: Subscription;
    public permissionActions = PermissionActions;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private store: Store<fromRoot.State>,
        private rewards: RewardsService,
        private modalService: NgbModal,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                if (!data.authenticated) {
                    this.user = new User();
                    // this.initialized = false;
                    return;
                }
                if (data.user) {
                    this.user = data.user;
                    // this.du.syncbytes = this.user.disk_usage_bytes;
                    // this.du.syncpercent = this.user.disk_usage_bytes / this.user.disk_limit_bytes * 100;
                    // this.du.unusedbytes = this.user.disk_limit_bytes - this.user.disk_usage_bytes;

                    if (this.du.status == this.DU_STATUS_INIT) {
                        this.getDiskUsage();
                    }
                    // this.initialized = true;
                }
            });
        this.rewards.getData().then((result) => (this.userRewards = result));
        this.avatarUrl = `${this.avatarEndpoint}${this.user.id}`;
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    // public invite() {
    //     window.open('http://www.sync-rewards.com/?_sync_refer=' +
    //                       this.user.referral_code,
    //                      'Invite',
    //                      'height=680,width=560');
    // }

    copyReferral() {
        const copyInput = <string>(
            document.getElementById('referral-link').innerText
        );
        const selBox = document.createElement('input');
        selBox.style.position = 'fixed';
        selBox.value = copyInput;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.log.d('copied link url');
        this.showCopiedText = true;
    }

    private async getDiskUsage() {
        // this.du.syncbytes = this.user.disk_usage_bytes;
        // this.du.syncpercent = this.user.disk_usage_bytes / this.user.disk_limit_bytes * 100;
        this.du.unusedbytes =
            this.user.disk_limit_bytes - this.user.disk_usage_bytes;
        let vaultBytes = 0;
        // console.log(this.user);
        // console.log('web sync id = ' + this.user.web_sync_id);
        try {
            const result = await this.api.execute<UserDiskUsageApiOutput>(
                'userdiskusage',
                {
                    sync_pid: this.user.web_sync_id,
                }
            );
            vaultBytes = result.bytes;
        } catch (ex) {
            vaultBytes = 0;
            this.log.error(ex);
        }

        // some legacy
        if (vaultBytes > this.user.disk_usage_bytes) {
            this.log.warn(
                `Vault bytes ${vaultBytes} < usage bytes ${this.user.disk_usage_bytes}`
            );
            this.log.warn('User needs disk usage/vault bytes recalculated');
            this.du.syncbytes = this.user.disk_usage_bytes;
            this.du.syncpercent =
                (this.user.disk_usage_bytes / this.user.disk_limit_bytes) * 100;
            this.du.unusedbytes =
                this.user.disk_limit_bytes - this.user.disk_usage_bytes;
            this.du.status = this.DU_STATUS_INVALID;
            return;
        }
        this.du.syncbytes = this.user.disk_usage_bytes - vaultBytes;
        this.du.vaultbytes = vaultBytes;
        this.du.syncpercent = Math.ceil(
            (this.du.syncbytes / this.user.disk_limit_bytes) * 100
        );
        this.du.vaultpercent = Math.ceil(
            (this.du.vaultbytes / this.user.disk_limit_bytes) * 100
        );
        this.du.status = this.DU_STATUS_SUCCESS;
    }

    openChangeUsernameDialog() {
        const ref = this.modalService.open(DialogUsernameChangeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }
    openRewardsDialog() {
        const ref = this.modalService.open(DialogRewardsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.userRewards = this.userRewards;
    }

    openDisplaynameDialog() {
        const ref = this.modalService.open(DialogDisplaynameComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openDisplayAvatarDialog() {
        const ref = this.modalService.open(DialogDisplayAvatarComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.result
            .then(() => {
                this.timeStamp = new Date().getTime();
                this.avatarUrl = this.avatarUrl.concat(`?${this.timeStamp}`);
            })
            .catch(() => this.log.info('Error closing avatar dialog'));
    }

    openEmailPrefDialog() {
        const ref = this.modalService.open(DialogEmailPreferenceComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openPreviewPreferences() {
        const ref = this.modalService.open(DialogPreviewPreferenceComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    private async confirmPassword() {
        const ref = this.modalService.open(DialogConfirmPasswordComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
            // size: 'lg'
        });
        return ref.result;
    }

    async openCancelDialog() {
        const result: boolean | string = await this.confirmPassword();
        if (result) {
            const ref = this.modalService.open(DialogCancelComponent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static',
            });
            ref.componentInstance.user = this.user;
        }
    }

    openCustomBrandDialog() {
        const ref = this.modalService.open(DialogCustomBrandComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    checkIfUnlimited() {
        return this.user.is_unlimited_plan;
    }

    handleMissingImage(event) {
        event.target.src = this.errorImageUrl;
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    allowed() {
        if (this.user.is_multi_new) {
            if (this.checkPermission(this.permissionActions.MANAGE_BILLING)) {
                return true;
            }
            return false;
        }
        return true;
    }
}
