
<!-- footer -->
<div class="container" >
    <div class="row">
        <div class="col-xs-12 text-right">
            &copy;
            {{curDate | date:'yyyy'}} Sync.com Inc.
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>
</div>
    <!-- end footer -->
