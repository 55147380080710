import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  public view: sync.IFeedbackView = {
    globalTasks: {
        uniqid: '',
        msg: '',
        type: ''
    },
    globalError: [],
    feedbackType: '',
    showFeedback: false,
    feedbackMsg: ''
};

  constructor() { }

  /**
     * @ngdoc method
     * @name  setFeedback
     * @methodOf sync.service:Feedback
     * @description
     * Sets a feedback message
     * @param {String} type     The type of feedback corresponding to
     *                          bootstrap's 'success'|info|'danger'
     * @param {String} msg      The message to display
     * @param {Integer=} displayTime If defined, will auto-hide after X ms
     */
   public setFeedback(type: string, msg: string, displayTime?: number) {
    this.view.feedbackType = type;
    this.view.showFeedback = true;
    this.view.feedbackMsg  = msg;
    if (displayTime && displayTime > 0) {
        setTimeout(() => {
          this.hideFeedback();
        }, displayTime);
    }
}


/**
 * @ngdoc method
 * @name  hideFeedback
 * @methodOf sync.service:Feedback
 * @description
 * Hides the feedback box
 */
  public hideFeedback() {
    this.view.feedbackType = '';
    this.view.showFeedback = false;
    this.view.feedbackMsg  = '';
  }
}
