import { Directive, ElementRef, HostListener, Optional, Renderer2 } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: `
    input[type="email"],
    [syncTrim]
  `
})
export class TrimDirective {

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional()
    private ngModel: NgModel,
    @Optional()
    private readonly control: NgControl
    ) { }


  @HostListener('blur')
  onBlur() {
    this.trimValue();
  }

  @HostListener('change')
  onChange() {
    this.trimValue();
  }

  trimValue () {
    let value = this.ngModel ? this.ngModel.model : (this.control ? this.control.value : this.elementRef.nativeElement.value);
    if (value) {
      value = value.trim();
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
      this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value);
      if (this.ngModel) {
        this.ngModel.update.emit(value);
      }
      if (typeof this.control.value === 'string') {
        this.control.control.setValue(value);
      }
    }
  }

}
