import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiSelectItem } from '../../models';

@Component({
    selector: 'sync-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.css']
})
export class MultiSelectDropdownComponent {

    /**
    * @property {string} placeholder
    * placeholder to be displayed on dropdown if no item is selected
    */
    @Input() placeholder: string;
    /**
    * @property {string} selectedText
    * Text to be displayed on dropdown after selection
    */
    @Input() selectedText: string;

    /**
    * @property {boolean} showSearch
    * Flag to show search box inside items dropdown or not.
    * Default value is true
    */
    @Input() showSearch = true;

    /**
    * @property {boolean} showAll
    * Flag to show Select All option or not.
    * Default value is true
    */
    @Input() showAll = true;

    /**
    * @property {boolean} showStatus
    * Flag to show Selected/unselected count at the bottom of the list.
    * Default value is false
    */
    @Input() showStatus = false;

    /**
    * @property {boolean} showError
    * Flag to set error state to the dropdown
    * Default value is false
    */
    @Input() showError = false;

    /**
    * @property {function} itemChange
    * Callback function on itemChange.
    * Callback is called with all selected Items
    */
    @Output() itemChange = new EventEmitter(null);

    /**
    * @property {array} items
    * Array of items to display on the dropdown.
    */
    @Input('items')
    set items(items: MultiSelectItem[]) {
        this.multiSelectItems = items || [];
        this.multiSelectItems.map(item => {
            item.id = item.id || this.generateID();
            item.checked = item.checked || false;
            item.visible = item.visible || true;
            item.disabled = item.disabled || false;
        });
        this.filtered = Object.assign(this.multiSelectItems);

        if (!this.filtered.length) {
            this.all.visible = false;
        } else {
            this.all.visible = true;
        }
    }

    /**
     * @property {boolean} isLoading
     * When isLoading is true display loading... in dropdown options after it gets false show the options
     */
    @Input() isLoading: boolean;


    multiSelectItems: MultiSelectItem[] = [];
    filtered: MultiSelectItem[] = [];
    all: MultiSelectItem = {
        id: this.generateID(),
        name: 'All',
        checked: false,
        visible: true
    };

    private searchText = '';

    get search(): string {
        return this.searchText;
    }

    set search(searchText: string) {
        this.searchText = searchText;

        const search = this.searchText.toLowerCase();
        if (!search) {
            this.filtered = Object.assign(this.multiSelectItems);
            this.all.visible = true;
            return;
        }
        this.filtered = this.multiSelectItems.filter(i => i.name.toLowerCase().indexOf(search) !== -1);
        if (this.all.name.toLowerCase().indexOf(search) !== -1) {
            this.all.visible = true;
        } else {
            this.all.visible = false;
        }
    }

    get selected(): string {
        if (this.selectedText) {
            return this.selectedText;
        }
        return this.all && this.all.checked ? this.all.name :
            this.multiSelectItems.filter(i => i.checked && i.visible).map(i => i.name).join(', ');
    }

    get isEmpty(): boolean {
        return this.filtered.filter(i => i.visible).length === 0;
    }

    get checked(): number {
        return this.multiSelectItems.filter(i => i.checked).length;
    }

    trackByID(index: number, item: MultiSelectItem): number | string {
        return item.id;
    }

    onChange($event: any, item: MultiSelectItem): void {
        const checked = $event.target.checked;
        const index = this.multiSelectItems.findIndex(i => i.id === item.id);

        if (item.name === 'All') {
            this.all.checked = checked;
            for (const iterator of this.multiSelectItems) {
                iterator.checked = checked;
            }
        } else {
            this.multiSelectItems[index].checked = checked;

            if (this.all) {
                if (this.all.checked) {
                    this.all.checked = false;
                }
                const allChecked = this.multiSelectItems.every(i => i.checked);
                this.all.checked = allChecked;
            }
        }

        this.itemChange.emit(this.multiSelectItems.filter(i => i.checked && i.visible));
    }

    generateID() {
        return Math.floor(Math.random() * 10000000);
    }

}
