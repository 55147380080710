import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { FeatureService } from '../services/feature.service';

@Directive({
  selector: '[syncIsFeatureAllowed]'
})
export class IsFeatureAllowedDirective implements OnInit {

  @Input('syncIsFeatureAllowed') featureName: string;

  constructor(
    private elRef: ElementRef,
    private featureService: FeatureService,
    private renderer2: Renderer2
  ) { }

  async ngOnInit() {
    const isFeatureAllowed = await this.featureService.isAllowed(this.featureName);
    if (!isFeatureAllowed) {
      this.renderer2.setStyle(this.elRef.nativeElement, 'display', 'none');
    } else {
      this.renderer2.removeStyle(this.elRef.nativeElement, 'display');
    }
  }

}
