import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'sync-content-length-display',
    templateUrl: './content-length-display.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ContentLengthDisplayComponent implements OnInit {
    @Input() value: string;
    @Input() maxLength: number;

    constructor() {}

    ngOnInit() {}
}
