import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DirPickerService } from '../../file-list/dir-picker.service';

@Component({
    selector: 'sync-dialog-dir-picker',
    templateUrl: './dialog-dir-picker.component.html',
})
export class DialogDirPickerComponent implements OnInit {

    constructor(
        private dirPickerService: DirPickerService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
        this.dirPickerService.init(this.dirPickerService.INIT_ALL);
        this.dirPickerService.selectByName('sync');
    }

    public getDestination() {
        return this.dirPickerService.getDestination();
    }

    public closeDialog() {
        this.activeModal.close(this.dirPickerService.view.selected);
    }
}
