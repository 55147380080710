import { BaseApiOutput } from './base-api.model';

export class JobLockApiOutput extends BaseApiOutput {
    has_joblock: number;
    joblock: {
        event_device_id: number;
        job_status: string;
        job_action: string;
        sync_id: number;
        name: string;
        resque_id: number;
        path_count: number;
        servtime: number;
    };
}

export class JobLockApiInput {
    command = 'getjoblock';
}
