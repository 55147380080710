import { DirtyOutNeedEncDataKeys } from '.';

export class DirtyOutEncResultShare {
    sync_id: number;
    share_id: number;
    sharekey_id: string;
    servtime: number;
    enc_share_name: string;
    completed?: number;
    data_keys?: DirtyOutNeedEncDataKeys[];
}


export class DirtyOutEncResultSync {
    enc_data_key: string;
    sync_id: number;
    sharekey_id: string;
    enc_share_name: string;
    servtime: number;
    action?: string;
}
