import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    HostBinding,
} from '@angular/core';
import { User, WebPath } from '../../../models';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { LoggerService } from '../../../../core/logger.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sync-header-previews',
    templateUrl: './header-previews.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderPreviewsComponent implements OnInit, OnDestroy {
    @Input() item: WebPath;
    private sub: Subscription;
    private sub1: Subscription;

    public imageCachekey: string; //32-char random string
    // @HostBinding("style.--primaryColor") only works from Angular 9+
    public primaryColor: string;
    public textColor: string;
    public linkColor: string;
    public logoHost: string;
    public whiteLabelLoaded: boolean;
    public isReady: boolean;
    public user: User;
    public avatarEndpoint = `${environment.logohost}avatar/`;
    public cpHost = environment.cphost;
    public userLoaded: boolean;
    private PAGE_TITLE = 'Sync.com | Control Panel';
    public compat = 0;

    constructor(
        private store: Store<fromRoot.State>,
        private log: LoggerService,
        private sanitizer: DomSanitizer,
        private pageTitleService: Title,
        private modalService: NgbModal,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {

        this.sub = this.store
            .pipe(select(fromRoot.getAuthUser))
            .subscribe((data) => {
                if (data) {
                    this.user = data;
                } else {
                    this.user = null;
                }
                this.userLoaded = true;
            });
        //only fetch white label when it's a shared link
        if (this.item.context === 'link' || this.item.context === 'applink') {
            this.sub1 = this.store
                .pipe(select(fromRoot.getLinkFileListState))
                .subscribe((data) => {
                    if (data && data.loaded && data.sorted) {
                        if (data.whitelabel_loaded) {
                            this.imageCachekey = data.image_cachekey;
                            this.primaryColor = data.header_primary_color;
                            this.textColor = data.header_text_color;
                            this.linkColor = data.link_text_color;
                            this.whiteLabelLoaded = data.whitelabel_loaded;
                            this.logoHost = environment.logohost;
                            this.isReady = true;
                            this.compat = data.compat;
                        }
                    }
                    //observe when iframe in comment-block completely loaded
                    if (data.iframeReady) {
                        this.userLoaded = true;
                    }
                });
        } else {
            this.isReady = true;
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        this.pageTitleService.setTitle(
            `${this.PAGE_TITLE}`
        );
    }

    public goBack() {
        if (this.router.url.includes('/file')) {
            this.router.navigate(['/files', this.item.pid], {
                queryParams: {
                    view: this.route.snapshot.queryParams.view,
                    page: this.route.snapshot.queryParams.page,
                }
            }
         );
        } else {
            const { cachekey, key } = this.route.snapshot.params;
            const navigationParams = {
                relativeTo: this.route.parent,
                ...this.decorateFragment(),
                queryParams:
                {
                    sync_id: this.item.pid,
                    view: this.route.snapshot.params['view'],
                    page: this.route.snapshot.queryParams['page']
                }
            };
            key ? this.router.navigate(['.', cachekey, key], navigationParams)
                : this.router.navigate(['.', cachekey], navigationParams);
        }
        // window.location.href = '/files/' + this.item.pid;
    }

    private decorateFragment() {
        if (!this.compat || this.route.snapshot.fragment) {
            return { fragment: this.route.snapshot.fragment };
        }
    }

    public openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }

    /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer
            .bypassSecurityTrustStyle(`--headerPrimaryColor: ${this.primaryColor};
                                                        --headerTextColor: ${this.textColor};
                                                        --linkTextColor: ${this.linkColor};
                                                    `);
    }
}
