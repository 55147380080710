import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchDeleteService } from '../batch-delete.service';
import { FeedbackService } from '../../notifications/feedback.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { BlendEvent } from 'src/app/shared/models';

@Component({
    selector: 'sync-dialog-delete',
    templateUrl: './dialog-delete.component.html',
})
export class DialogDeleteComponent implements OnInit {
    public dlgView: sync.IBatchDialogView;
    public batchView: sync.IBatchActionView;
    @Input() selected: sync.IFile[] = [];

    constructor(
        private batchDeleteService: BatchDeleteService,
        public activeModal: NgbActiveModal,
        private feedbackService: FeedbackService,
        private blendService: BlendService,
    ) {}

    ngOnInit() {
        this.dlgView = {
            itemlist: this.selected,
            action: 'delete',
            title: 'Delete items',
            btnExecuteText: 'Delete',
            btnExecuteClass: 'btn btn-warning',
            completedClass: '',
            confirm: {
                check: false,
                show: false,
                notices: [],
                txt: ' Deleting files free up your account\'s disk usage',
            },
        };
        this.batchView = this.batchDeleteService.view;
        this.batchDeleteService.init();
    }

    public isCompleted(syncId: number) {
        this.batchDeleteService.isCompleted(syncId);
    }
    public getClass(syncID: number) {
        return this.batchDeleteService.isCompleted(syncID) ? 'deleted' : '';
    }

    public closeDialog() {
        if (this.batchDeleteService.view.spinner !== 0) {
            const c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchDeleteService.cancel();
                this.activeModal.close();
            }
        } else {
            this.activeModal.close();
        }
    }

    public removeFromList(listitem: sync.IFile) {
        for (let i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    }

    public sortList(a, b) {
        if (a.type < b.type) {
            return -1;
        } else if (a.type > b.type) {
            return 1;
        } else {
            if (a.search_name < b.search_name) {
                return -1;
            } else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    }

    public async execute() {
        const sync_id_array: number[] = [],
            list = this.dlgView.itemlist
                ? this.dlgView.itemlist.sort((a, b) => this.sortList(a, b))
                : [];

        let files = 0,
            directories = 0;
        for (let i = list.length - 1; i >= 0; i--) {
            sync_id_array.push(list[i].sync_id);
            if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                directories++;
            } else {
                files++;
            }
            this.blendService.track(BlendEvent.DELETE_ITEM, {
                size: list[i].size,
                type: list[i].type && list[i].type.toUpperCase() === 'DIR' ? 'folder' : 'file',
                mime_type: list[i].mime_type
            });
        }

        try {
            await this.batchDeleteService.execute(sync_id_array).toPromise();
        } catch (error) {
            let errorMsg = `${list.length} file${files > 1 ? 's' : ''} failed to delete`;
            if (directories && files) {
                errorMsg = `${list.length} items failed to delete`;
            } else if (directories) {
                errorMsg = `${list.length} folder${directories > 1 ? 's' : ''} failed to delete`;
            }
            this.feedbackService.setFeedback('danger', errorMsg, 10000);
        } finally {
            this.activeModal.close();
        }
    }
}
