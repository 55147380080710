import { Injectable } from '@angular/core';
import * as storejs from 'store';
import { LoggerService } from '../logger.service';

@Injectable({
    providedIn: 'root'
})
export class StorageBrowserService {

    public storageType: string;
    public _data: any = {};
    public _userId = 0;
    public _nameSpace = 'sync';
    public _storage: any;
    private store: any;
    constructor(private log: LoggerService) {
        this.store = storejs;
        if (this.store.enabled) {
            this.log.info('store is enabled');
            // console.log('This store is enabled');
            this.storageType = 'store';
            this._storage = this.store;
        } else {
            this.log.info('store is using memory, store test failed probably private browsing');
            this.storageType = 'memory';
            this._storage = {
                get: (key: string) => {
                    return this._data[key];
                },
                set: <T>(key: string, value: T) => {
                    this._data[key] = value;
                },
                clear: () => { this._data = {}; },
                getAll() { return this._data; }
            };
        }
    }

    public setMemoryStorage(): void {
        this.storageType = 'memory';
        this._storage = {
                get: (key: string) => {
                    return this._data[key];
                },
                set: <T>(key: string, value: T) => {
                    this._data[key] = value;
                },
                clear: () => { this._data = {}; },
                getAll() { return this._data; }
            };
    }

    public getStorageType() {
        return this.storageType;
    }



    /**
     * @ngdoc method
     * @name  setUserId
     * @methodOf sync.service:BrowserStorage
     * @description
     * Sets the user id for the browser storage
     * @param {Integer} userid The user's id
     */
    setUserId(userid: number) {
        this._userId = userid;
    }

    /**
     * @ngdoc method
     * @name  set
     * @methodOf sync.service:BrowserStorage
     * @description
     * Sets a value in local storage
     * @param {String} key   The key name
     * @param {*} value      Mixed value, array, object, str, int, etc.
     */
    set(key: string, value: any) {
        return this._storage.set(this.mkKey(key), value);
    }

    /**
     * @ngdoc method
     * @name  get
     * @methodOf sync.service:BrowserStorage
     * @description
     * Gets a value based on the key
     * @param  {String} key The key to retrieve
     * @return {*}     The value, array, obj, str, int, etc.
     */
    get(key: string) {
        return this._storage.get(this.mkKey(key));
    }

    /**
     * @ngdoc method
     * @name  getAll
     * @methodOf sync.service:BrowserStorage
     * @description
     * Gets all key=>value stored in local storage
     * @return {[type]} [description]
     */
    getAll() {
        return this._storage.getAll();
    }

    /**
     * @ngdoc method
     * @name  clear
     * @methodOf sync.service:BrowserStorage
     * @description
     * Clears the local storage.
     */
    clear() {
        return this._storage.clear();
    }


    mkKey(key: string) {
        return [this._nameSpace, this._userId, key].join('.');
    }
}
