
<div class="modal-header">
  <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Connect apps - {{item.name}}</h4>
</div>

<div class="modal-body modal-body-overflow">

  <sync-error-display-hash [errors]="errors" ></sync-error-display-hash>

  <div class="row">

      <div class="col-sm-12" [class.hide]="!spinners.loading" >
          <i class="fa fa-spinner fa-spin" > </i>
          Loading Apps
      </div>

      <div [class.hide]="spinners.loading">

          <div [class.hide]="apps && apps.length > 0" class="col-sm-12">
              You must first add applications to your Sync account before connecting apps to a directory.
          </div>

          <div [class.hide]="apps.length === 0" class="col-sm-12">

              <table class="table list-table list-table-modal ">
                  <thead>
                      <tr>
                      <th class="table-checkbox"></th>
                      <th class="table-modalsharename">Name </th>
                      <th class="table-modalsharestatus hidden-xs">Status</th>
                      <th class="table-modalshareactions"></th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr
                      [class.hide]="apps.length === 0"
                      [ngClass]="{'active': app.enabled}"
                      *ngFor="let app of apps | orderBy:false">

                          <td class="table-checkbox">
                              <i
                                  (click)="app.selected = !app.selected"
                                  [ngClass]="{
                                      'far fa-square': !app.selected,
                                      'fas fa-check-square': app.selected
                                  }"></i>
                          </td>

                          <td class="table-modalsharename">
                              <div class="tdmore">
                                  <span [innerHtml]="app.name"></span>
                              </div>
                              <div class="tdmore  subtext" [innerHtml]="app.id"></div>
                          </td>

                          <td class="table-modalsharestatus hidden-xs">
                              <div class="tdmore" *ngIf="app.enabled">Connected</div>
                              <div class="tdmore" *ngIf="!app.enabled">Disconnected</div>
                          </td>

                          <td class="table-modalsharestatus hidden-xs">
                              <div class="tdmore" ></div>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <div class="table-info">
                <span [ngPlural]="getEnabledAmtApps(apps)">
                    <ng-template ngPluralCase="=1">
                        1 app connected
                    </ng-template>
                    <ng-template ngPluralCase="other">
                        {{ getEnabledAmtApps(apps) }} apps connected
                    </ng-template>
                </span>
              </div>

          </div>

      </div>
  </div>
</div>

<div class="modal-footer">

  <button
  (click)="enableApps()"
      type="button"
      class="btn btn-default pull-left"
      [disabled]="getSelectedAmtApps(apps) == 0 || spinners.enabling || spinners.disabling"
  >
      <i class="fa fa-spinner fa-spin" [class.hide]="!spinners.enabling"> </i>
      Connect<span class="hidden-xs"> apps</span>
  </button>
  <button
  (click)="disableApps()"
      type="button"
      class="btn btn-default pull-left"
      [disabled]="getSelectedAmtApps(apps) == 0 || spinners.enabling || spinners.disabling"
  >

      <i class="fa fa-spinner fa-spin" [class.hide]="!spinners.disabling"> </i>
      Disconnect<span class="hidden-xs"> apps</span>
  </button>

  <button type="button" class="btn btn-default" (click)="closeModal()">
      Close
  </button>

</div>
