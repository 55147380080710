
<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-cog"></i> Single Sign-on</h4>
</div>

<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p>Single sign-on (SS0) allows you to log in directly to the web panel from an installed and authenticated desktop app attached to your account. </p>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">Single sign-on</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="status"
                        id="disablesso-enabled"
                        value="0"
                        [(ngModel)]="status"
                    /> Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="status"
                        id="disablesso-disabled"
                        value="1"
                        [(ngModel)]="status"
                    /> Disabled
                </label>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        [disabled]="status == user.is_disablesso.toString()"
        id="disablesso-save"
        type="button"
        class="btn btn-primary"
        (click)="save()"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        {{(user.is_disablesso) ? 'Enable' : 'Disable'}}
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>
