
import * as Actions from '../actions/link-list.actions';
import { compare } from '../shared/func';

import { ErrCode, LinkListItem } from '../shared/models';
export interface State {
    items: LinkListItem[];
    error: ErrCode;
    loaded: boolean;
    sorted: boolean;
    predicate: string;
    direction: 'asc' | 'desc' | '';
}

export const initialState: State = {
    items: [],
    error: undefined,
    loaded: false,
    sorted: false,
    predicate: 'default',
    direction: ''
};


export function reducer(state = initialState, action: Actions.All) {
    switch (action.type) {
        case Actions.SUCCESS:
            return Object.assign({}, state, {
                loaded: true,
                items: action.payload
            });
        case Actions.RESET:
            return initialState;

        case Actions.ERROR:
            return Object.assign({}, state, { error: action.payload });

        case Actions.REFRESH:
            return Object.assign({}, state, {
                loaded: false,
                sorted: false
            });

        case Actions.SORT:
            const isAsc = action.payload.direction === 'asc';
            const newlist = state.items.slice(0);
            if (action.payload.direction === '') {
                newlist.sort((a, b) => a.name.toString().localeCompare(b.name));

            } else {
                switch (action.payload.active) {
                    case 'name':
                        // newlist.sort((a, b) => compare(a.sortName, b.sortName, isAsc));
                        newlist.sort((a, b) => {
                            if (isAsc) {
                                return a.name.toString().localeCompare(b.name);
                            } else {
                                return b.name.toString().localeCompare(a.name);
                            }
                        });
                        break;
                    case 'date':
                        newlist.sort((a, b) => compare(a.date, b.date, isAsc));
                        break;
                    case 'downloads':
                        newlist.sort((a, b) => compare(a.download_count, b.download_count, isAsc));
                        break;
                    case 'default':
                        newlist.sort((a, b) => a.name.toString().localeCompare(b.name));
                        break;
                    default:
                        newlist.sort((a, b) => a.name.toString().localeCompare(b.name));
                        break;
                }
            }
            return Object.assign({}, state, {
                items: newlist,
                predicate: action.payload.active,
                direction: action.payload.direction,
                sorted: true
            });
        default:
            return state;
    }
}

// export const getAuthenticated = (state: State) => state.authenticated;

// export const getUser = (state: State) => state.user;

// export const getLoginProgress = (state: State) => state.progress;
// export const getErrCode = (state: State) => state.error;
