import { Component, OnInit, Injector, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-multiadminuser-delete',
    templateUrl: './dialog-multiadminuser-delete.component.html'
})
export class DialogMultiAdminuserDeleteComponent implements OnInit {
    @Input('shareData') shareData: sync.IShareData;
    constructor(
        public activeModal: NgbActiveModal,
        // @Inject('$state') private $state: StateService,
    ) {}
    ngOnInit(): void {}
    onConfirm() {
        this.activeModal.close(true);
    }
    onCancel() {
        this.activeModal.close(false);
    }
}
