import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbDropdownModule, NgbModalModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BillingComponent } from './billing/billing.component';
import { BillingProfileComponent } from './billing-profile/billing-profile.component';
import { BillingInvoiceComponent } from './billing-invoice/billing-invoice.component';
import { BillingStatusComponent } from './billing-status/billing-status.component';
import { DevicesComponent } from './devices/devices.component';
import { GetstartedComponent } from './getstarted/getstarted.component';
import { SecurityComponent } from './security/security.component';
import { MigrateComponent } from './migrate/migrate.component';
import { ProvisionService } from './services/provision.service';
import { ProvisionWorkerService } from './services/provision-worker.service';
import { RewardsService } from './services/rewards.service';
import { SupportTicketService } from './services/support-ticket.service';
import { ZuoraAccountService } from './services/zuora-account.service';
import { DialogAutorenewComponent } from './dialog-autorenew/dialog-autorenew.component';
import { DialogAutoLogoutComponent } from './dialog-auto-logout/dialog-auto-logout.component';
import { DialogCancelComponent } from './dialog-cancel/dialog-cancel.component';
import { DialogDeviceActivateComponent } from './dialog-device-activate/dialog-device-activate.component';
import { DialogDeviceDeleteComponent } from './dialog-device-delete/dialog-device-delete.component';
import { DialogDevicePermissionComponent } from './dialog-device-permission/dialog-device-permission.component';
import { DialogDeviceRenameComponent } from './dialog-device-rename/dialog-device-rename.component';
import { DialogDisplaynameComponent } from './dialog-displayname/dialog-displayname.component';
import { DialogEmailBillingDocComponent } from './dialog-email-billing-doc/dialog-email-billing-doc.component';
import { DialogEmailPreferenceComponent } from './dialog-email-preference/dialog-email-preference.component';
import { DialogPreviewPreferenceComponent } from './dialog-preview-preference/dialog-preview-preference.component';
import { DialogPasswordHintComponent } from './dialog-password-hint/dialog-password-hint.component';
import { DialogPasswordResetComponent } from './dialog-password-reset/dialog-password-reset.component';
import { DialogRewardsComponent } from './dialog-rewards/dialog-rewards.component';
import { DialogSsoComponent } from './dialog-sso/dialog-sso.component';
import { DialogTwofactorComponent } from './dialog-twofactor/dialog-twofactor.component';
import { DialogUsernameChangeComponent } from './dialog-username-change/dialog-username-change.component';
import { DialogBillingProfileDeleteComponent } from './dialog-billing-profile-delete/dialog-billing-profile-delete.component';
import { DialogAcctholdCancelComponent } from './dialog-accthold-cancel/dialog-accthold-cancel.component';
import { DialogCustomBrandComponent } from './dialog-custom-brand/dialog-custom-brand.component';
import { DialogDisplayAvatarComponent } from './dialog-display-avatar/dialog-display-avatar.component';
import { RenewComponent } from './renew/renew.component';
import { AppsComponent } from './apps/apps.component';
import { DialogContactSupportComponent } from './dialog-contact-support/dialog-contact-support.component';
import { Dialog1UpComponent } from './dialog1-up/dialog1-up.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { AccountRoutingModule } from './account-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { DialogConfirmPasswordComponent } from './dialog-confirm-password/dialog-confirm-password.component';
import { MultiUserModule } from '../multi-user/multi-user.module';
import { SyncLibraryModule } from 'sync-library';
import { DialogAnalyticsComponent } from './dialog-analytics/dialog-analytics.component';
import { BlendService } from '../shared/services/blend.service';
import { DialogAccountUpgradeComponent } from './dialog-account-upgrade/dialog-account-upgrade.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModalModule,
        NgbDropdownModule,
        NgbProgressbarModule,
        ReactiveFormsModule,
        SharedModule,
        QRCodeModule,
        LayoutModule,
        AccountRoutingModule,
        MultiUserModule,
        SyncLibraryModule
    ],
    providers: [
        ProvisionService,
        ProvisionWorkerService,
        RewardsService,
        SupportTicketService,
        ZuoraAccountService,
        BlendService
    ],
    declarations: [
        DashboardComponent,
        DialogDisplaynameComponent,
        DialogUsernameChangeComponent,
        DialogRewardsComponent,
        DialogEmailPreferenceComponent,
        DialogPreviewPreferenceComponent,
        BillingComponent,
        DialogAutorenewComponent,
        BillingInvoiceComponent,
        DevicesComponent,
        SecurityComponent,
        DialogTwofactorComponent,
        DialogPasswordResetComponent,
        DialogPasswordHintComponent,
        DialogAutoLogoutComponent,
        DialogSsoComponent,
        DialogDeviceRenameComponent,
        DialogDeviceDeleteComponent,
        DialogDevicePermissionComponent,
        DialogDeviceActivateComponent,
        DialogCustomBrandComponent,
        DialogCancelComponent,
        GetstartedComponent,
        MigrateComponent,
        BillingStatusComponent,
        BillingProfileComponent,
        DialogEmailBillingDocComponent,
        DialogBillingProfileDeleteComponent,
        DialogAcctholdCancelComponent,
        DialogDisplayAvatarComponent,
        RenewComponent,
        AppsComponent,
        DialogContactSupportComponent,
        Dialog1UpComponent,
        UpgradeComponent,
        PlanDetailsComponent,
        DialogConfirmPasswordComponent,
        DialogAnalyticsComponent,
        DialogAccountUpgradeComponent
    ],
    entryComponents: [
        BillingComponent,
        BillingInvoiceComponent,
        BillingProfileComponent,
        DashboardComponent,
        DevicesComponent,
        DialogAutorenewComponent,
        DialogBillingProfileDeleteComponent,
        DialogConfirmPasswordComponent,
        DialogEmailBillingDocComponent,
        DialogDisplaynameComponent,
        DialogDisplayAvatarComponent,
        DialogEmailPreferenceComponent,
        DialogPreviewPreferenceComponent,
        DialogRewardsComponent,
        DialogUsernameChangeComponent,
        SecurityComponent,
        DialogTwofactorComponent,
        DialogPasswordResetComponent,
        DialogPasswordHintComponent,
        DialogAutoLogoutComponent,
        DialogSsoComponent,
        DialogDeviceRenameComponent,
        DialogDeviceDeleteComponent,
        DialogDevicePermissionComponent,
        DialogDeviceActivateComponent,
        DialogCustomBrandComponent,
        GetstartedComponent,
        DialogCancelComponent,
        MigrateComponent,
        DialogAcctholdCancelComponent,
        RenewComponent,
        AppsComponent,
        DialogContactSupportComponent,
        Dialog1UpComponent,
        UpgradeComponent,
        PlanDetailsComponent,
        DialogAnalyticsComponent,
        DialogAccountUpgradeComponent
    ]
})
export class AccountModule { }
