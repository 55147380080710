import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-account-upgrade',
    templateUrl: './dialog-account-upgrade.component.html',
})
export class DialogAccountUpgradeComponent implements OnInit {
    @Input('featureName') featureName: string;
    @Input('message') message: string;
    @Input('title') title: string;
    @Input('bodyHeader') bodyHeader: string;
    @Input('bodyItems') bodyItems: [];
    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {}
}
