import { Deserializable } from './deserializable.model';
import { ShareMemberApiOutput } from '.';

export class SharememberItem implements Deserializable<SharememberItem> {
    sharememberId: number;
    userId: number;
    email: string;
    displayname: string;
    status: string;
    pubkey: string;
    pubkeyId: number;
    shareId: number;
    permissions: {[permission: string]: string};
    num_apps: number;
    isMe: boolean;


    public deserialize(input: ShareMemberApiOutput) {
        this.sharememberId = input.uniqid;
        this.userId = input.userid;
        this.email = input.email;
        this.displayname = input.displayname;
        this.status = input.status;
        this.pubkeyId = input.pubkey_id;
        this.pubkey = input.pubkey;
        this.shareId = input.share_id;
        this.permissions = input.permissions;
        this.num_apps = input.num_apps;
        return this;
    }

}
