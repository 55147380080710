import { Action } from '@ngrx/store';

import { AuthSSO, AuthUserPassword, ErrCode, User } from '../shared/models';

export const AUTH_LOGIN = '[Auth] Login';
export const AUTH_LOGIN_FAILURE = '[Auth] Login failure';
export const AUTH_LOGIN_SUCCESS = '[Auth] Login success';

export const AUTH_LOGIN_SSO = '[Auth] Login SSO';

export const AUTH_REFRESH = '[Auth] refresh';
export const AUTH_UPDATE_USER = '[Auth] Update user';
export const AUTH_LOGOUT = '[Auth] Logout';
export const AUTH_LOGIN_PROGRESS = '[Auth] Login progress';
export const AUTH_LOGIN_TWOFACTOR = '[Auth] Two-factor required';

export class LoginSuccessAction implements Action {
    readonly type = AUTH_LOGIN_SUCCESS;
    constructor(public payload: User) {}
}

export class LoginFailureAction implements Action {
    readonly type = AUTH_LOGIN_FAILURE;
    constructor(public payload: ErrCode) {}
}

export class LoginAction implements Action {
    readonly type = AUTH_LOGIN;
    constructor(public payload: AuthUserPassword) {}
}
export class LoginSSOAction implements Action {
    readonly type = AUTH_LOGIN_SSO;
    constructor(public payload: AuthSSO) {}
}

export class LogoutAction implements Action {
    readonly type = AUTH_LOGOUT;
    constructor() {}
}

export class RefreshAction implements Action {
    readonly type = AUTH_REFRESH;
    constructor() {}
}
export class UpdateAction implements Action {
    readonly type = AUTH_UPDATE_USER;
    constructor(public payload: User) {}
}

export class LoginProgressAction implements Action {
    readonly type = AUTH_LOGIN_PROGRESS;
    constructor(public payload: number) {}
}

export class LoginTwoFactorRequiredAction implements Action {
    readonly type = AUTH_LOGIN_TWOFACTOR;
    constructor() {}
}

export type All =
    | LoginAction
    | LoginSSOAction
    | LogoutAction
    | LoginFailureAction
    | LoginSuccessAction
    | LoginProgressAction
    | LoginTwoFactorRequiredAction
    | RefreshAction
    | UpdateAction;
