<nav class="navbar navbar-default navbar-fixed-top navbar-white">
    <div class="navbar-header navbar-white-header">
        <a style="width:100%" class="navbar-brand" routerLink="/login">
            <img id="navbar-logo" src="images/sync_logo.svg" style="height: 46px;width:172px;" />
        </a>
    </div>
    <div id="navbar" class="n">
        
        <ul class="nav navbar-nav navbar-right navbar-white-right">
            <li class="hidden-xs"><a href="https://www.sync.com/signup?from=cpsignout">Create account</a></li>
            <li><a (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>
