<span [class.hide]="paginate.total == 0">
    Displaying
    <span
        [class.hide]="paginate.total > paginate.itemsPerPage"
        [innerHtml]="paginate.total"
    ></span>
    <span [class.hide]="paginate.total <= paginate.itemsPerPage">
        <span>{{
            (paginate.currentPage * paginate.itemsPerPage - paginate.itemsPerPage) == 0 ? 1 : 
            (paginate.currentPage * paginate.itemsPerPage - paginate.itemsPerPage) + 1
        }}</span>
        to
        <span>{{
            paginate.itemsPerPage * paginate.currentPage > paginate.total
                ? paginate.total
                : paginate.itemsPerPage * paginate.currentPage
        }}</span>
        of
        <span [innerHtml]="paginate.total"></span>
    </span>
    <span [ngPlural]="paginate.total">
        <ng-template ngPluralCase="1"> item</ng-template>
        <ng-template ngPluralCase="other"> items</ng-template>
    </span>
</span>
