<div class="modal-header">

  <button type="button" class="close" (click)="activeModal.close()"><i class="ion-android-close fa-lg"></i></button>
  <h4 class="modal-title"><i class="fa fa-heart fa-lg"></i> Contra-gratulations!</h4>

</div>
<div class="modal-body" style="background-color:#77d1ed!important">


<!--start animation -->
<div style="margin:0 auto;width:550px;height:500px;background-color:#77d1ed!important;
  -webkit-border-radius: 60px!important;
  -moz-border-radius: 60px!important;
  border-radius: 60px!important;
  animation-delay: 10s;
  -moz-animation-delay: 10s;
  -webkit-animation-delay: 10s;"
   class="animated shake">

<div style="position:absolute;color:#fff;font-size:20px;font-family:arial;font-weight:bold;margin:30px 0px 0px 100px;
  animation-delay: 3s;
  -moz-animation-delay: 3s;
  -webkit-animation-delay: 3s;"
   class="animated bounceIn">Yeeehaaaah!</div>


<div style="width:313px;height:351px; position:absolute; margin:0px 0px 0px 200px;" class="animated bounceInDown">
<img src="https://www.sync.com/images/1upunicorn.png"
   style="
  animation-delay: 8s;
  -moz-animation-delay: 8s;
  -webkit-animation-delay: 8s;"
   class="animated shake">
</div>

<div style="width:252px;height:252px; position:absolute; margin:100px 0px 0px 25px;" class="animated RotateIn">
<img src="https://www.sync.com/images/1upsun.png"
   style="animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;"
   class="animated pulse">
</div>

<div style="width:62px;height:63px; position:absolute; margin:197px 0px 0px 129px;
  animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;" class="animated RotateIn spin">
<img src="https://www.sync.com/images/1upsunface.png">
</div>


<div style="width:447px;height:226px; position:absolute; margin:250px 0px 0px 75px;" class="animated LightSpeedIn">
<img src="https://www.sync.com/images/1upclouds.png"
   style="
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-delay: 6s;
  -moz-animation-delay: 6s;
  -webkit-animation-delay: 6s;"
   class="animated bounce">
</div>


<img src="https://www.sync.com/images/1up.png" width="420" height="210"
   style="position:absolute;margin:275px 0px 0px 70px;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-delay: 2s;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;"
   class="animated bounceIn">

<!--end animation -->

</div>

<div class="modal-footer text-right" style="background-color:#77d1ed!important">
  <span *ngIf="returnCode == 0" class="pull-left"><h4><strong>You just earned a FREE GB :-)</strong></h4></span>
  <span *ngIf="returnCode > 0" class="pull-left"><h4><strong>You've already received your bonus :-P</strong></h4></span>


  <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
  <button type="submit" [disabled]="returnCode > 0" class="btn btn-primary" (click)="submit()">
  <i class="fa fa-spinner fa-spin" [class.hide]="!spinner"></i>
      Redeem
  </button>
</div>