import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JobLockApiOutput, BaseApiOutput } from '../shared/models';
import { LoggerService } from './logger.service';
import { NotificationsService } from './notifications.service';

@Injectable({
    providedIn: 'root',
})
export class JoblockService {
    private pollingInterval = 2000; // 2 seconds

    constructor(
        private api: ApiService,
        private loggerService: LoggerService,
        private notificationsService: NotificationsService
    ) { }

    public async getJobLock() {
        const resp = await this.api.execute<JobLockApiOutput>('getjoblock', {});
        return resp;
    }

    public pollJobStatus(): Promise<boolean> {
        this.notificationsService.stopNotificationLoop();
        return new Promise(async (resolve, reject) => {
            try {
                while (true) {
                    try {
                        const response = await this.api.execute<JobLockApiOutput>('getjoblock', {});
                        if (response.has_joblock == 0) {
                            resolve(true);
                            break;
                        }
                    } catch (apiError) {
                        this.loggerService.error(`API call failed in pollJobStatus: ${apiError}`);
                    }
                    await this.delay(this.pollingInterval);
                }
            } catch (error) {
                this.loggerService.error(`Error in pollJobStatus:: ${error}`);
            } finally {
                this.notificationsService.startNotificationLoop();
            }
        });
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
