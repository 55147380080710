import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BuildTransferItemService } from '../services/build-transfer-item.service';

@Component({
    selector: 'sync-download-compat',
    templateUrl: './download-compat.component.html'
})
export class DownloadCompatComponent implements OnInit {
    public currentItem: sync.IFile;
    private urlDownload: string;
    private urlPreview: string;
    @Input() public item: sync.IFile;

    constructor(
        public activeModal: NgbActiveModal,
        private buildTransferItemService: BuildTransferItemService
    ) {}

    ngOnInit() {
        this.urlDownload = undefined;
        this.urlPreview = undefined;
        this.currentItem = this.item;
        this.currentItem.compat = true;
        this.currentItem.linkID = undefined;
    }

    public async downloadFile() {
        if (this.urlDownload) {
            window.location.href = this.urlDownload;
        }

        const url = await this.buildTransferItemService.getCompatUrl(
            this.currentItem,
            this.buildTransferItemService.ACT_DOWNLOAD
        );
        this.urlDownload = url;
        window.location.href = this.urlDownload;
    }

    public async openFile() {
        if (this.urlPreview) {
            window.location.href = this.urlPreview;
        }

        const url = await this.buildTransferItemService.getCompatUrl(
            this.currentItem,
            this.buildTransferItemService.ACT_PREVIEW
        );
        this.urlPreview = url;
        window.location.href = this.urlPreview;
    }
}
