export enum AlertMessageType {
    INFO = 'info',
    ERROR = 'error'
}
export class AlertMessage {
    constructor(
        public message: string,
        public type: AlertMessageType = AlertMessageType.INFO
    ) {}
}
