import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { UserRewards, RewardsListApiOutput } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class RewardsService {

  constructor(
      private api: ApiService,
      private log: LoggerService
  ) { }

    /**
     * @ngdoc method
     * @name  getData
     * @methodOf sync.service:RewardsService
     * @description
     * Gets a list of rewards
     * @returns {Promise} The newly created promise
     */
    public async getData() {
        const data = await this.api.execute<RewardsListApiOutput>('rewardlist', {});
        const rewards = new UserRewards();
        rewards.list = [];
        rewards.from_sync = [];
        rewards.referral_code = data.referral_code;
        rewards.is_referred = data.signup_referred;
        rewards.total = 0;

        for (let i = 0, len = data.list.length; i < len; i++) {
            if (data.list[i].status == 1) {
                rewards.total += data.list[i].bonus_bytes;
                if (data.list[i].bonus_type == 'admin') {
                    data.list[i].bonus_text = data.list[i].comment;
                    rewards.from_sync.push(data.list[i]);
                } else if (data.list[i].bonus_type.indexOf('sync-') === 0) {
                    data.list[i].bonus_text = data.list[i].bonus_type_text;
                    rewards.from_sync.push(data.list[i]);
                } else {
                    data.list[i].bonus_text = data.list[i].bonus_type_text;
                    rewards.list.push(data.list[i]);
                }
            }
        }

        rewards.count = rewards.from_sync.length + rewards.list.length;
        return rewards;
    }

    /*
     * Some rewards can only be claimed once, this will attempt to claim
     * them and wait for the API to reject or allow.
     * @param {STring} rewardCode The code to attempt to claim
     * @returns {Promise} The newly created promise
     */
    public async claimRewardOnce(code: string) {
        return await this.api.execute('rewardclaimonce', { reward_code: code });
    }

    public async claimReward(code: string) {
        this.log.info('Claiming reward ' + code);
        await this.api.execute('rewardclaim', { referrer_code: code });
        return this.getData();
    }

    /**
     * Returns user id of user who referred for sign up.
     */
    public async getSignupReferralUserId() {
        const rewards = await this.getData();
        if (rewards.is_referred) {
            const signupReferred = rewards.list.filter(reward => reward.bonus_type === 'signup-referred')[0];
            return signupReferred && signupReferred.bonus_from_id;
        }
        return null;
    }

}
