import { Component, OnInit, Input } from '@angular/core';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { ErrCode } from '../../shared/models';
import { ValidateService } from '../../core/validate.service';
import { LoggerService } from '../../core/logger.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-email-billing-doc',
    templateUrl: './dialog-email-billing-doc.component.html'
})
export class DialogEmailBillingDocComponent implements OnInit {
    @Input() doc: any;
    public errcode: ErrCode;

    public email: string;

    public emailQueue: string[] = [];

    public spinner: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private log: LoggerService,
        private zuora: ZuoraAccountService,
        private validate: ValidateService
    ) { }

    ngOnInit() {
    }

    public addToQueue() {
        const email = this.email.toLowerCase();
        if (this.emailQueue.indexOf(email) > -1) {
            return;
        }
        if (this.emailQueue.length > 20) {
            this.errcode = new ErrCode(1655);
            return;
        }

        if (!this.validate.isEmail(email)) {
            this.errcode = new ErrCode(8107);
            return;
        }
        this.errcode = undefined;
        this.emailQueue.push(email);
        this.email = '';
    }

    public removeQueue(email: string) {
        this.emailQueue.splice(this.emailQueue.indexOf(email.toLowerCase()), 1);
    }

    public async sendEmail() {
        if (this.email) {
            this.addToQueue();
        }
        if (!this.emailQueue) {
            this.errcode = new ErrCode(1656, 'No email addresses added to queue');
        }

        try {
            this.spinner = true;
            await this.zuora.emailBillingDocument(this.emailQueue, this.doc.type, this.doc.id);
            this.activeModal.close(true);
        } catch (ex) {
            this.log.error(`An error occurred sending an email document ${JSON.stringify(this.doc)} ${JSON.stringify(ex)}`);
            this.errcode = new ErrCode(1657, 'An error occurred attempting to send the document.  Please try again later');
        } finally {
            this.spinner = false;
        }
    }
}
