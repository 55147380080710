import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ShareService } from '../share.service';
import { DialogShareChooseOwnerComponent } from '../dialog-choose-share-owner/dialog-choose-share-owner.component';
import { DialogConfirmOwnershipChangeComponent } from '../dialog-confirm-ownership-change/dialog-confirm-ownership-change.component';
import { FeedbackService } from '../../notifications/feedback.service';
import { ShareNewService } from '../share-new.service';

@Component({
    selector: 'sync-sharemember-list',
    templateUrl: './sharemember-list.component.html',
})
export class SharememberListComponent implements OnInit {
    @Input() public shareData: sync.IShareData;
    @Input() public openState: number;
    @Input() public modalInstance: NgbModalRef;

    @Output() openStateChange: EventEmitter<number> = new EventEmitter();

    public errors: Array<sync.IErrorCode> = [];
    public spinner: boolean;
    public selectedPurge = true;
    public canChangeOwner = false;

    public predicate: string[] = ['isMe', 'displayname'];
    public isPro = false;
    public hasAppsNewFeature = false;

    public spinners = {
        member: 0,
    };
    public memberResent = 0;

    constructor(
        private loggerService: LoggerService,
        private userService: UserService,
        private shareService: ShareService,
        private modalService: NgbModal,
        private Feedback: FeedbackService,
        private ShareNewService: ShareNewService,
    ) {}

    async ngOnInit() {
        this.hasAppsNewFeature = this.userService.get('new_feature');
        this.isPro = this.userService.get('is_pro');
        if (!this.shareData) {
            this.loggerService.error(
                'Unable to find shareData for sharemember-list'
            );
            this.loggerService.error(
                JSON.parse(JSON.stringify(this.shareData))
            );
            this.errors.push({
                code: 9000,
                msg: 'No share member information provided',
            });
        }

        this.canChangeOwner = (this.shareData.people.filter(member => !(
            member.isMe ||
            ['Waiting Signup', 'Waiting'].includes(member.status)
        )).length > 0) && !!this.shareData.isOwner;

        const pending_users = this.shareData.people.filter((member) => {
            if (member.status === 'Confirm') {
                return member;
            }
        });

        if (pending_users.length > 0) {
            this.loggerService.info(`enc pending user found : ${pending_users.length}`);
            await this.ShareNewService.postshareDataProcess(this.shareData.syncId);
            await this.updateUserStatus();
        }
    }

    public async updateUserStatus() {
        const share = await this.shareService.getShare(this.shareData.shareId);
        this.shareData = share;
    }
    public async resendInvite(member: sync.ISharememberData) {
        try {
            this.errors = [];
            this.spinners.member = member.sharememberId;
            await this.shareService.resendInvite(this.shareData, member.email);
            this.spinners.member = 0;
            this.memberResent = member.sharememberId;
            // resent the invite, show success!
        } catch (err) {
            this.errors.push(err);
        }
    }

    public async setPermission(
        permission: 'perUPLOAD' | 'perSEEOTHERS' | 'perADDAPP' | 'perOWNER' | 'perINVITE',
        sharemember: sync.ISharememberData,
    ): Promise<string | null> {

        this.spinners.member = sharemember.sharememberId;
        this.spinner = true;
        this.errors = [];

        try {
            const newVal = !sharemember.permissions[permission] ? 1 : 0;
            await this.shareService.setPermission(
                this.shareData,
                permission,
                sharemember
            );
            sharemember.permissions[permission] = newVal;
            this.spinners.member = 0;
            this.spinner = false;

            return null;
        } catch (err) {
            this.errors.push(err);
            this.spinner = false;

            if (typeof err === 'string') {
                return err;
            } else {
                return err.message || 'Failed to change ownership!';
            }
        }
    }

    public async chooseNewOwner() {
        this.modalInstance.close();


        const ref = this.modalService.open(DialogShareChooseOwnerComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
            size: 'lg'
        });
        ref.componentInstance.openState = 3;
        ref.componentInstance.shareData = this.shareData;

        const newOwnerEmail: string = await ref.result;
        if (!newOwnerEmail) { return; }

        const newOwner: sync.ISharememberData = this.shareData.people.find((member) => newOwnerEmail === member.email);
        if (!newOwner) { return; }

        await this.changeOwnership(newOwner);
    }

    public async changeOwnership(sharemember: sync.ISharememberData) {
        this.modalInstance.close();

        const ref = this.modalService.open(DialogConfirmOwnershipChangeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
            size: 'lg'
        });

        ref.componentInstance.openState = 3;
        ref.componentInstance.shareData = this.shareData;

        const isConfirm: boolean = await ref.result;
        if (!isConfirm) { return; }

        const error = await this.setPermission('perOWNER', sharemember);

        if (!error) {
            return this.Feedback.setFeedback('success', 'Ownership has been transferred successfully', 3000);
        } else {
            return this.Feedback.setFeedback('error', `Failed to transfer the ownership. ${error}`, 3000);
        }
    }

    public getSelectedAmt(): number {
        let amt = 0;
        this.shareData.people.map((cur) => {
            if (cur.selected) {
                amt++;
            }
        });
        return amt;
    }

    public openPopup(state: number) {
        this.openStateChange.next(state);
    }
}
