<nav
    class="navbar navbar-default navbar-fixed-top-link navbar-white"
    *ngIf="whiteLabelLoaded && !imageCachekey"
>
    <div class="navbar-header navbar-white-header">
        <a class="navbar-brand" href="https://www.sync.com/?_m=h73">
            <img
                id="navbar-logo"
                class="navbar-white-q"
                src="/images/sync-q-lightbg.svg"
            />
            <!-- <img src="/images/sync-w-lightbg.svg"  /> -->
        </a>
    </div>
    <div id="navbar" class="n">
        <ul class="nav navbar-nav navbar-right navbar-white-right">
            <li *ngIf="(!user || !user.exists) && userLoaded" class="hidden-xs">
                <a href="https://www.sync.com/?_m=h73">Create account</a>
            </li>
            <!-- DROP-DOWN MENU  -->
            <li *ngIf="user && user.exists && userLoaded" class="hidden-xs">
                <a [href]="cpHost + '/account/info'">
                    <div class="navbar-avatar-bigbox">
                        <img
                            class="img-circle navbar-avatar-header-bar"
                            [src]="avatarEndpoint + user.id"
                            onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                        />
                        <div class="navbar-avatar-smalltext hidden-xs">
                            Signed in as:
                        </div>
                        <div class="navbar-avatar-bigtext hidden-xs">
                            {{ user.display_name | b64 }}
                        </div>
                    </div>
                </a>
            </li>

            <!-- avatar for small displays -->
            <li
                *ngIf="user && user.exists && userLoaded"
                class="visible-xs hidden-sm hidden-md hidden-lg hidden-xl"
            >
                <a href="/account/info">
                    <img
                        class="img-circle navbar-avatar-header-bar"
                        [src]="avatarEndpoint + user.id"
                        onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                    />
                </a>
            </li>
            <!-- / DROP-DOWN MENU -->
            <li><a (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>

<!-- ----------------------------------------------------------------------- -->
<!--                        CUSTOM WHITE LABEL NAVBAR                        -->
<!-- ----------------------------------------------------------------------- -->

<nav
    class="navbar navbar-fixed-top-link navbar-white-custom"
    *ngIf="whiteLabelLoaded && imageCachekey"
>
    <div class="navbar-header navbar-white-header">
        <a class="navbar-brand" href="https://www.sync.com/?_m=h73">
            <img
                id="navbar-logo"
                class="navbar-white-q logo-custom"
                [src]="logoHost + imageCachekey"
            />
            <!-- <img src="/images/sync-w-lightbg.svg"  /> -->
        </a>
    </div>
    <div id="navbar" class="n">
        <ul class="nav navbar-nav navbar-right navbar-white-right-custom">
            <li *ngIf="(!user || !user.exists) && userLoaded" class="hidden-xs">
                <a href="https://www.sync.com/?_m=h73">Create account</a>
            </li>
            <!-- DROP-DOWN MENU -->
            <li *ngIf="user && user.exists && userLoaded" class="hidden-xs">
                <a [href]="cpHost + '/account/info'">
                    <div class="navbar-avatar-bigbox">
                        <img
                            class="img-circle navbar-avatar-header-bar"
                            [src]="avatarEndpoint + user.id"
                            onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                        />
                        <div class="navbar-avatar-smalltext hidden-xs">
                            Signed in as:
                        </div>
                        <div class="navbar-avatar-bigtext hidden-xs">
                            {{ user.display_name | b64 }}
                        </div>
                    </div>
                </a>
            </li>
            <!-- DROP-DOWN MENU -->

            <!-- avatar for small displays -->
            <li
                *ngIf="user && user.exists"
                class="visible-xs hidden-sm hidden-md hidden-lg hidden-xl"
            >
                <a href="/account/info">
                    <img
                        class="img-circle navbar-avatar-header-bar"
                        [src]="avatarEndpoint + user.id"
                        onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                    />
                </a>
            </li>

            <li><a (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>
