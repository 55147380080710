import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserMultiAdminComponent } from '../multi-admin/user-multi-admin/user-multi-admin.component';
import { UserAdminComponent } from './user-admin/user-admin.component';

const routes: Routes = [
      { path: '', component: UserAdminComponent },
      { path: 'users', component: UserMultiAdminComponent },
      { path: 'roles', component: UserMultiAdminComponent, data: { rolesActive: true } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MultiUserRoutingModule { }
