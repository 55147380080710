import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as FileListActions from '../../actions/file-list.actions';
import { ErrCode, PathListApiOutput, ShareKeyList, WebPath, PathAddApiOutput, PathListApiInput, EncFile } from '../../shared/models';
import { UrlService } from '../../core/url.service';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../core/logger.service';
import { FileItemService } from '../../file/file-item.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from '../../core/user.service';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FileListService {
    private is_starred: boolean;
    private totalFavoriteSubject = new BehaviorSubject<number>(0);
    total_favorite$: Observable<number> = this.totalFavoriteSubject.asObservable();

   constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private dirtyOut: DirtyOutService,
        private store: Store<fromRoot.State>,
        private url: UrlService,
        private log: LoggerService,
        private fileItemService: FileItemService,
        private modalService: NgbModal,
        private router: Router,
        private userService: UserService,
    ) {

    }

    public reload() {
        this.store.dispatch(new FileListActions.FileListReloadAction());
    }
    public async getData(syncId: number, histId: number, showdeleted: number, is_vault: boolean) {
        try {
            const pathlist = await this.load(syncId, histId, showdeleted, '0', [], is_vault);
            return pathlist;
        } catch (e) {
            throw ErrCode.fromException(e);
        }
    }

    public async getPathList() {
        const pathlist = await this.store.pipe(select(fromRoot.fileSelector),
            take(1)).toPromise();
        return pathlist;
    }

    // legacy helper
    public getListSubscription() {
        return this.store.select(fromRoot.getFileListState);
    }
    // legacy helper
    public getCwdSubscription() {
        return this.store.select(fromRoot.getFileListCwd);
    }

    public sortList(predicate: string, reverse: boolean) {
        const sortData = {
            direction: (reverse) ? 'desc' : 'asc',
            active: predicate
        };
        this.store.dispatch(new FileListActions.FileListSortAction(sortData));
    }
    public getListTypeSubscription() {

    }
    // legacy helper
    public dispatch(input: PathListApiInput) {
        this.store.dispatch(new FileListActions.FileListRefreshAction(input));
    }
    public reset() {
        this.store.dispatch(new FileListActions.FileListResetAction());
    }

    public async getDataNoState(syncId: number, offset: string, pathlist: sync.IFile[] = []): Promise<PathListApiOutput> {
        const d = await this.api.execute<PathListApiOutput>('pathlist', {
            showdeleted: 0,
            hist_id: 0,
            sync_id: syncId,
            offset_metaname_digest: offset
        });
        if (d.cwd) {
            d.cwd = await this.fileItemService.format(d.cwd);
        }
        if (d.pathlist && d.pathlist.length) {
            for (let i = 0, len = d.pathlist.length; i < len; i++) {
                pathlist.push(await this.fileItemService.format(d.pathlist[i]));
            }
            return await this.getDataNoState(syncId, d.last_metaname_digest, pathlist);
        } else {
            d.pathlist = pathlist;
            return d;
        }
    }

    private async load(syncId: number, histId: number, showdeleted: number, offset: string, pathlist: sync.IFile[] = [], is_vault: boolean): Promise<sync.IFile[]> {
        const d = await this.api.execute<PathListApiOutput>('pathlist', {
            showdeleted: showdeleted,
            hist_id: histId,
            sync_id: syncId,
            offset_metaname_digest: offset
        });
        this.store.dispatch(new FileListActions.FileListTotalFavoriteAction(d.total_favorite));
        this.url.setHostMulti(d);

        this.is_starred = this.router.url.includes('/starred') ? true : false;

        if (offset === '0') {
            if (!d.cwd) {
                throw new ErrCode(1803);
            }
            const cwd = await this.fileItemService.format(d.cwd);
            this.store.dispatch(new FileListActions.FileListSetCwdAction(cwd));
            if (d.parents) {
                const parents: EncFile[] = [];
                for (let i = 0, len = d.parents.length; i < len; i++) {
                    const name =
                        d.parents[i].metaname == 'web' ||
                        d.parents[i].metaname == 'Web Uploads'
                            ? 'Vault'
                            : this.is_starred &&
                              d.parents[i].loc_id ==
                                  this.userService.get('syncus_sync_id')
                            ? 'Starred'
                            : await this.crypt.filenameDecrypt(
                                  d.parents[i].metaname
                              );
                    parents.push(
                        new EncFile({
                            loc_id: d.parents[i].loc_id,
                            metaname: d.parents[i].metaname,
                            name: name,
                        })
                    );
                }
                this.store.dispatch(new FileListActions.FileListSetParentsAction(parents));
            }
        }
        this.store.dispatch(new FileListActions.FileListSetProgressAction(d.progress));
        if (d.hist_id && d.hist_id > 0) {
            this.store.dispatch(new FileListActions.FileListSetHistIdAction(d.hist_id));
        }

        if (d.pathlist && d.pathlist.length) {
            for (let i = 0, len = d.pathlist.length; i < len; i++) {
                if (pathlist.some((file: sync.IFile) => {
                    return (d.pathlist[i].sync_id === file.sync_id);
                })) {
                    this.log.error(`Duplicate sync ids '${d.pathlist[i].sync_id}' in pid '${syncId}'`);
                    throw new ErrCode(9000, 'Multiple files with the same Sync_id');
                }
                const file = d.pathlist[i];
                file.is_vault = is_vault;
                pathlist.push(await this.fileItemService.format(file));
            }
            return await this.load(syncId, histId, showdeleted, d.last_metaname_digest, pathlist, is_vault);
        } else {
            return pathlist;
        }
    }

    public async mkdir(syncPid: number, name: string): Promise<PathAddApiOutput> {
        const keyArray = await this.dirtyOut.executeForSync(syncPid, name);
        const enc_name = await this.crypt.filenameEncrypt(name);
        return await this.api.execute<PathAddApiOutput>('pathadd', {
            sync_pid: syncPid,
            new_name: enc_name,
            share_names: keyArray
        });

    }
}
