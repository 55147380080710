
import { Injectable } from '@angular/core';
import { of, from, Observable, throwError } from 'rxjs';
import { withLatestFrom, map, mergeMap, catchError } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as LinkListActions from '../actions/link-list.actions';
import { LinkListService } from '../links/services/link-list.service';

import { State, getLinkListState } from '../reducers';

@Injectable()
export class LinkListEffects {

    constructor(
        private links: LinkListService,
        private actions$: Actions,
        private store$: Store<State>
    ) {
    }
    @Effect()
    refresh$: Observable<Action> = this.actions$.pipe(
        ofType(LinkListActions.REFRESH),
        mergeMap((action: LinkListActions.LinkListRefreshAction) => {
            return from(this.links.getData()).pipe(
                map(data => new LinkListActions.LinkListSuccessAction(data)),
                catchError((err) => {
                    return of(new LinkListActions.LinkListErrorAction(err));
                })
            );
        })
    );

    @Effect()
    success$: Observable<Action> = this.actions$.pipe(
        ofType(LinkListActions.SUCCESS),
        withLatestFrom(this.store$.select(getLinkListState)),
        map(([action, storeState]) => {
            return new LinkListActions.LinkListSortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        })
    );

}

