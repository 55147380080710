<div class="modal-header">
    <button type="button" class="close"  (click)="close.emit(false)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
        <span>Create link on {{item.name}}</span>
    </h4>
</div>
<div class="modal-body" >
    <ng-container *ngIf="isLinkLimit">
        <div class="error-general-overlay error-general-overlay-inline error-push-bottom" role="alert">
            <i class="fa fa-exclamation-triangle"></i>
            The free Sync Starter plan allows you to create a maximum of {{linkLimitAmt}} links. 
            <br /> You'll need to remove an existing link to create a new link, or upgrade to get unlimited links. 
        </div>
        <div  class="panel panel-default bgwhite">
            <div class="panel-body bgwhite">
                
                <h4>
                <i class="zmdi zmdi-star-circle zmdi-hc-lg"></i>
                <b>Upgrade to get these great link features:</b>
                </h4>
                
                <i class="fa fa-check"></i> Unlimited links<br />
                <i class="fa fa-check"></i> Set expiry dates on links<br />
                <i class="fa fa-check"></i> Set download limits on links<br />
                <i class="fa fa-check"></i> Restrict downloads on links (view only links)<br />
                <i class="fa fa-check"></i> Request files with links (upload enabled links)<br />
                <i class="fa fa-check"></i> 30-day money back guarantee<br />
    
                <br />
                <a class="syncblue" routerLink="/account/upgrade" (click)="close.emit(false)">View upgrade options now <i class="fas fa-arrow-right"></i></a>
                <br />
            </div>
        </div>
    </ng-container>

    <sync-error [errcode]="errcode"></sync-error>
    <sync-verify-email-required *ngIf="!verified"></sync-verify-email-required>
    <div *ngIf="verified && spinner">
        <div class="error-general-overlay error-general-overlay-inline error-push-bottom">
            <i class="fa fa-spin fa-spinner"></i>
            {{ this.modalText }}
        </div>
    </div>
    <br />
</div>
<div *ngIf="isLinkLimit" class="modal-footer">

    <button id="btn-link-limit-cancel" type="button" class="btn btn-default" (click)="close.emit(false)">Cancel</button>
    <a routerLink="/account/upgrade" id="" type="button" class="btn btn-primary pull-right" style="color:white;cursor: pointer;" (click)="close.emit(false)">Upgrade account now</a>
</div>
