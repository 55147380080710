<div class="modal-header">
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">Email billing document</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            <sync-error [errcode]="errcode"></sync-error>

            Email a copy of the document <strong>{{doc.number}}</strong> {{doc.date}} for {{doc.amount | currency}}
            <hr />
            <div class="input-group">
                <input
                    id="text-sharemember-add-sharewith"
                    autocomplete="off"
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    [(ngModel)]="email"
                    class="form-control"
                />
                <div class="input-group-btn">
                    <button (click)="addToQueue()" class="btn btn-primary">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="shareuserbadges">
                <span class="badge" *ngFor="let p of emailQueue">
                    {{p}}
                    <i (click)="removeQueue(p)" class="fas fa-times"></i>
                </span>
            </div>
      </div>
  </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="sendEmail()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Email
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>