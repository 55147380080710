import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { ShareService } from '../../shares/share.service';

@Component({
    selector: 'sync-inline-share-info',
    templateUrl: './inline-share-info.component.html'
})
export class InlineShareInfoComponent implements OnInit {
    public share: sync.IShareData;
    @Input('syncInlineShareInfo') public shareId: number;
    public initialized = false;

    constructor(
        private loggerService: LoggerService,
        private shareService: ShareService
    ) {}

    ngOnInit() {
        this.initialized = false;
        this.getData(this.shareId);
    }

    public async getData(shareId: number) {
        try {
            const share = await this.shareService.getShare(shareId);
            this.share = share;
            this.initialized = true;
        } catch (err) {
            this.loggerService.error(
                'Error retrieving share inline for ' + this.shareId
            );
            this.loggerService.error(err);
        }
    }

    public openDetails() {
        this.shareService.openDetails(this.shareId, 0);
    }
}
