import { Component, OnInit, Input, Injector } from '@angular/core';
import { User, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { PasswordService } from '../../auth/services/password.service';


@Component({
    selector: 'sync-dialog-password-reset',
    templateUrl: './dialog-password-reset.component.html'
})
export class DialogPasswordResetComponent implements OnInit {

    @Input() user: User;

    public status: string;
    public curPass: string;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private injector: Injector,
        private password: PasswordService,
        private userService: UserService
    ) { }


    ngOnInit() {
        this.status = this.user.is_resetpassword ? '1' : '0';
    }

    public async save() {

        this.spinner = true;

        try {
            const keyData = await this.password.passwordValid(
                this.user.email,
                this.curPass
            );
            if (this.status == '1') {
                await this.password.enablePassReset(this.curPass, keyData);
            } else {
                await this.password.disablePassReset();
            }
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            console.error('Error with ', ex);
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
        console.log('save');
    }
}
