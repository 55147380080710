
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ShareListComponent } from './share-list/share-list.component';
import { UtilModule } from '../util/util-module';
import { NgbActiveModal, NgbDropdownModule, NgbModalModule, NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogShareNewComponent } from './dialog-share-new/dialog-share-new.component';
import { ErrorModule } from '../error/error-module';
import { SharememberAddComponent } from './sharemember-add/sharemember-add.component';
import { DialogShareChooseOwnerComponent } from './dialog-choose-share-owner/dialog-choose-share-owner.component';
import { DialogConfirmOwnershipChangeComponent } from './dialog-confirm-ownership-change/dialog-confirm-ownership-change.component';
import { DialogShareAppListComponent } from './dialog-share-app-list/dialog-share-app-list.component';
import { ShareProvisionComponent } from './share-provision/share-provision.component';
import { DialogShareDetailsComponent } from './dialog-share-details/dialog-share-details.component';
import { SharememberListComponent } from './sharemember-list/sharemember-list.component';
import { ShareOptionsComponent } from './share-options/share-options.component';
import { SharememberInviteComponent } from './sharemember-invite/sharemember-invite.component';
import { SharememberRemoveComponent } from './sharemember-remove/sharemember-remove.component';
import { ShareLeaveComponent } from './share-leave/share-leave.component';
import { ShareDeleteComponent } from './share-delete/share-delete.component';
import { ShareInviteComponent } from './share-invite/share-invite.component';
import { MultiAdminService } from '../core/multiadmin.service';
import { SharesRoutingModule } from './shares-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { ExternalShareListComponent } from './external-share-list/external-share-list.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharesRoutingModule,
        SharedModule,
        UtilModule,
        NgbModule,
        NgbDropdownModule,
        NgbModalModule,
        NgbTypeaheadModule,
        ErrorModule,
        LayoutModule,
    ],
    providers: [
        NgbActiveModal, MultiAdminService
    ],
    exports: [],
    declarations: [
        ShareListComponent,
        DialogShareNewComponent,
        SharememberAddComponent,
        DialogShareChooseOwnerComponent,
        DialogConfirmOwnershipChangeComponent,
        DialogShareAppListComponent,
        ShareProvisionComponent,
        DialogShareDetailsComponent,
        SharememberListComponent,
        ShareOptionsComponent,
        SharememberInviteComponent,
        SharememberRemoveComponent,
        ShareLeaveComponent,
        ShareDeleteComponent,
        ShareInviteComponent,
        ExternalShareListComponent
    ],
    entryComponents: [
        ShareListComponent,
        DialogShareNewComponent,
        SharememberAddComponent,
        DialogShareChooseOwnerComponent,
        DialogConfirmOwnershipChangeComponent,
        DialogShareAppListComponent,
        ShareProvisionComponent,
        SharememberListComponent,
        ShareOptionsComponent,
        SharememberInviteComponent,
        SharememberRemoveComponent,
        ShareLeaveComponent,
        ShareDeleteComponent,
        DialogShareDetailsComponent,
        ShareInviteComponent,
        ExternalShareListComponent
    ]
})
export class SharesModule { }


