<div>

    <sync-sharemember-list
        *ngIf="openState == 1"
        [shareData]="shareData"
        [(openState)]="openState"
        [modalInstance]="activeModal"
    ></sync-sharemember-list>
    
    
    <sync-share-options
        *ngIf="openState == 2"
        [shareData]="shareData"
        [(openState)]="openState"
        [modalInstance]="activeModal"
    ></sync-share-options>
    
    
    <sync-sharemember-invite
        *ngIf="openState == 3"
        [shareData]="shareData"
        [openState]="openState"
        [modalInstance]="activeModal"
    ></sync-sharemember-invite>
    
    <sync-sharemember-remove
        *ngIf="openState == 4"
        [shareData]="shareData"
        [openState]="openState"
        [modalInstance]="activeModal"
        (stateChangeEvent)="onStateChange($event)"
    ></sync-sharemember-remove>
    
    <sync-share-delete
        *ngIf="openState == 5"
        [shareData]="shareData"
        [openState]="openState"
        [modalInstance]="activeModal"
    ></sync-share-delete>
    
    <sync-share-leave
        *ngIf="openState == 6"
        [shareData]="shareData"
        [openState]="openState"
        [modalInstance]="activeModal"
    ></sync-share-leave>
    
    </div>
    