import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as LinkFileListActions from '../../actions/link-file-list.actions';
import * as fromRoot from '../../reducers';

import { LoggerService } from '../../core/logger.service';
import { SyncDigestService } from '../../core/crypt/sync-digest.service';
import { ApiService } from '../../core/api.service';
import {
    ErrCode,
    LinkPathListApiOutput,
    LinkPathListApiInput,
} from '../../shared/models';
import { UrlService } from '../../core/url.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { LinkPasswordService } from '../../links/services/link-password.service';
import { FileItemService } from '../../file/file-item.service';

@Injectable({
    providedIn: 'root',
})
export class LinkFileListService {
    public key: string;
    /**
     * The Enc Key stored as b64.  Due to version 2, we need to ensure we're
     * not decrypting the key twice.
     */
    private LINKKEY: string;

    /**
     * The htaccess style password for a link
     */
    private PASSWORDLOCK: string;
    private syncID: number;
    private shareId: number;
    private rootSyncID: number;
    private is_pro: boolean;
    private thumbs: Array<any>;


    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private digest: SyncDigestService,
        private linkPassword: LinkPasswordService,
        private log: LoggerService,
        private store: Store<fromRoot.State>,
        private url: UrlService,
        private fileItemService: FileItemService
    ) {}

    // ng1 support
    public getSubscription() {
        return this.store.select(fromRoot.getLinkFileListState);
    }

    public getCwdSubscription() {
        return this.store.select(fromRoot.getLinkFileListCwd);
    }

    public dispatch(input: LinkPathListApiInput) {
        this.store.dispatch(
            new LinkFileListActions.LoadWhiteLabelAction(input.publink_id)
        );
        this.store.dispatch(new LinkFileListActions.LoadAction(input));
    }

    public dispatchApp(input: LinkPathListApiInput) {
        this.store.dispatch(
            new LinkFileListActions.LoadWhiteLabelAction(input.publink_id)
        );
        this.store.dispatch(new LinkFileListActions.LoadAppAction(input));
    }

    public reload() {
        return this.store.dispatch(new LinkFileListActions.RefreshAction());
    }

    public success(payload: LinkPathListApiOutput) {
        return this.store.dispatch(new LinkFileListActions.SuccessAction(payload));
    }
    /**
     * Returns a processed link file list.
     */
    public async getData(
        key: string,
        linkId: string,
        locid: number,
        passlock: string
    ) {
        if (!key || !linkId) {
            return;
        }
        this.PASSWORDLOCK = passlock;
        try {
            const data = await this.api.send<LinkPathListApiOutput>(
                'linkpathlist',
                {
                    publink_id: linkId,
                    sync_id: locid,
                    passwordlock: passlock,
                }
            );
            // if (data.servers) {
            //     this.url.setHosts(data.servers);
            // }

            if (!data.pathitems.length && !Object.keys(data.cwd).length) {
                this.log.error('The link is empty');
                throw new ErrCode(1700);
            }

            this.url.setHostMulti(data);

            if (!this.LINKKEY) {
                this.LINKKEY = await this.getKeyB64(
                    data.linkversion,
                    key,
                    data.salt,
                    data.iterations
                );
            }

            data.cwd = await this.processItem(
                data.cwd,
                data,
                this.LINKKEY,
                linkId
            );
            const list = [];
            for (let i = 0, len = data.pathitems.length; i < len; i++) {
                data.pathitems[i].compat = data.compat == 1;
                list.push(
                    await this.processItem(
                        data.pathitems[i],
                        data,
                        this.LINKKEY,
                        linkId
                    )
                );
            }
            data.pathitems = list;

            // Once the user has downgraded, don't allow the links to
            // utilize the pro features.
            if (!data.is_pro) {
                data.upload = 0;
            }
            // done
            return data;
        } catch (ex) {
            const errcode = ErrCode.fromException(ex);
            this.log.e('Error retrieving link list from cachekey: ' + linkId, errcode);
            throw errcode;
        }
    }

    /**
     * Returns a processed link file list. Pre-decrypted External App Link.
     */
    public async getDataExternal(
        linkId: string,
        locid: number,
        passlock: string
    ) {
        if (!linkId) {
            return;
        }
        let data;
        this.PASSWORDLOCK = passlock;
        try {
            this.log.info(
                `getDataExt for link:${linkId}, locid:${locid}, passlock:${passlock}`
            );
            const params = passlock ? { passlock: passlock } : {};

            data = await (<Promise<LinkPathListApiOutput>>(
                this.api.sendExt(`link/${linkId}`, 'GET', params)
            ));

            if (!data.pathitems.length && !Object.keys(data.cwd).length) {
                this.log.error('The link is empty');
                throw new ErrCode(1700);
            }

            const pwEnabled = passlock ? true : false;
            // this.url.setHostMulti(data);

            // if (!this.LINKKEY) {
            //     this.LINKKEY = await this.getKeyB64(data.linkversion, key, data.salt, data.iterations);
            // }

            // data.cwd = await this.processItem(data.cwd, data, this.LINKKEY, linkId);
            data.cwd = await this.processItemExternal(
                data.cwd,
                data,
                linkId,
                pwEnabled
            );
            const list = [];
            for (let i = 0, len = data.pathitems.length; i < len; i++) {
                data.pathitems[i].compat = data.compat == 1;
                data.pathitems[i].previewtoken = data.previewtoken;
                list.push(
                    await this.processItemExternal(
                        data.pathitems[i],
                        data,
                        linkId,
                        pwEnabled
                    )
                );
            }
            data.pathitems = list;

            // Once the user has downgraded, don't allow the links to
            // utilize the pro features.
            if (!data.is_pro) {
                data.upload = 0;
            }
            // done
            return data;
        } catch (ex) {
            this.log.error(ex);
            const errcode = ErrCode.fromException(ex);
            this.log.e(
                'Error retrieving data from external ' + linkId,
                errcode
            );
            throw errcode;
        }
    }

    public isRoot() {
        return this.syncID == this.rootSyncID;
    }

    public sanitizeKey(key: string) {
        // strip out any n
        const safekey = key.replace(/[^0-9a-zA-Z-]/gi, '');
        if (safekey !== key) {
            this.log.warn('Key was different, stripped out characters');
            key = safekey;
        }
        const encoded = encodeURIComponent(key);
        if (key !== encoded) {
            this.log.warn(
                'Key url was encoded by another process, attempting to decode into ascii'
            );
            key = encoded.replace(/%E2%80%90/g, '-');
        }
        return key;
    }

    public setPasswordLock(password) {
        this.PASSWORDLOCK = this.digest.hash(password);
        // getData
    }

    public getPassword() {
        return this.PASSWORDLOCK;
    }
    public async encName(filename: string) {
        return await this.crypt.filenameEncrypt(filename, this.LINKKEY);
    }

    public getValidFileName(filename: string) {
        // console.log(filename);
        const dt: Date = new Date();
        const parts = filename.split('.');
        const ext = parts[parts.length - 1];
        const newFileName: (string | number)[] = [
            filename,
            '-',
            dt.getFullYear(),
            '-',
            dt.getMonth() + 1,
            '-',
            dt.getDate(),
            ' ',
            dt.getHours(),
            '.',
            dt.getMinutes(),
            '.',
            dt.getSeconds(),
        ];
        if (ext != filename) {
            newFileName.push('.', ext);
        }
        return newFileName.join('');
    }

    /**
     * Gets the plain text password as a valid share key in base64
     */
    public async getKeyB64(
        version: number,
        key: string,
        salt: string,
        iterations: number
    ) {
        if (version == 2) {
            const skey = await this.linkPassword.convertToShareKey(
                key,
                salt,
                iterations
            );
            return this.crypt.bytesToB64(skey);
        } else {
            return key;
        }
    }

    private async processItem(
        item: sync.IFile,
        settings: LinkPathListApiOutput,
        linkkey: string,
        linkId: string
    ) {
        // set name first so that fileitem.format doesn't try to
        // decrypt the name.
        if (!item) {
            this.log.warn('Cannot find pathitem');
        } else if (!item.enc_share_name) {
            item.name = `${item.sync_id} failed decryption 1`;
            this.log.error(`syncid: ${item.sync_id} failed decryption,
                missing enc_share_name on
                cachekey: ${linkId}
                shareId: ${item.share_id}`);
        } else if (!linkkey) {
            item.name = `${item.sync_id} failed decryption 2`;
            this.log.error(`syncid: ${item.sync_id} failed decryption,
                missing linkkey on
                cachekey: ${linkId}
                shareId: ${item.share_id}`);
        } else {
            item.name = await this.crypt.filenameDecrypt(
                item.enc_share_name,
                linkkey
            );
        }

        if (item.name == item.enc_share_name) {
            throw new ErrCode(2110);
        }

        if (item.name == '') {
            item.name = `Error ${item.sync_id} - file has a blank name`;
        }

        item = await this.fileItemService.format(item);

        item.context = 'link';
        item.compat = settings.compat == 1;
        item.share_key = linkkey;
        item.linkID = linkId;
        item.link_owner_id = settings.oid;
        item.link_is_business = settings.is_business > 0;
        item.link_is_pro = settings.is_pro > 0;
        item.is_publink = 1;
        item.linkpasswordlock = this.PASSWORDLOCK;
        item.previewonly = settings.previewonly;
        if (item.size > 0 && item.has_thumb1 == 1) {
            item.thumbData = {
                processed: false,
                thumbType: 1,
                cachekey: item.cachekey,
                blobtype: 'btTHUMB1',
            };
        } else if (
            item.kind == 'image' &&
            item.size > 0 &&
            item.size < 1024 * 100 &&
            !item.has_thumb1 &&
            !item.has_thumb2
        ) {
            // Logger.info('No thumbnail, use original');
            item.thumbData = {
                processed: false,
                thumbType: 1,
                cachekey: item.cachekey,
                blobtype: 'btFILE',
            };
        }
        return item;
    }

    private async processItemExternal(
        item: sync.IFile,
        settings: LinkPathListApiOutput,
        linkId: string,
        pwProtected: boolean
    ) {
        // item.name = item.share_name;

        // if (item.name == item.enc_share_name) {
        //     throw new ErrCode(2110);
        // }

        if (pwProtected && window.location.host.indexOf('sync.us') > -1) {
            const replaceDomain = '';
            item.compaturl = settings.url.replace(
                /https?\:\/\/[a-zA-Z0-9\-]*\.(sync|Sync|SYNC)\.(com|us|COM|US)/g,
                replaceDomain
            );
            item.compaturl_dl = settings.url_dl.replace(
                /https?\:\/\/[a-zA-Z0-9\-]*\.(sync|Sync|SYNC)\.(com|us|COM|US)/g,
                replaceDomain
            );
            this.log.info(
                'Removed domain from compaturl so that cookie is passed along - New URL: ' +
                    item.compaturl +
                    ' Old URL: ' +
                    settings.url
            );
        } else {
            item.compaturl = settings.url;
            item.compaturl_dl = settings.url_dl;
        }

        item = await this.fileItemService.format(item);
        if (!item) {
            this.log.info('Cannot find pathitem');
        }
        item.context = 'applink';
        item.compat = settings.compat == 1;
        item.linkID = linkId;
        item.link_owner_id = settings.oid;
        item.link_is_business = settings.is_business > 0;
        item.link_is_pro = settings.is_pro > 0;
        item.is_publink = 1;
        item.linkpasswordlock = this.PASSWORDLOCK;
        item.previewonly = settings.previewonly;
        if (item.size > 0 && item.has_thumb1 == 1) {
            item.thumbData = {
                processed: false,
                thumbType: 1,
                cachekey: item.cachekey,
                blobtype: 'btTHUMB1',
            };
        } else if (
            item.kind == 'image' &&
            item.size > 0 &&
            item.size < 1024 * 100 &&
            !item.has_thumb1 &&
            !item.has_thumb2
        ) {
            // Logger.info('No thumbnail, use original');
            item.thumbData = {
                processed: false,
                thumbType: 1,
                cachekey: item.cachekey,
                blobtype: 'btFILE',
            };
        }
        return item;
    }
    public async getWhiteLabel(pubLinkID: string) {
        try {
            return await this.api.send<LinkPathListApiOutput>('getwhitelabel', {
                publink_id: pubLinkID,
            });
        } catch (ex) {
            const errcode = ErrCode.fromException(ex);
            this.log.e(
                'Error retrieving whitelabel from ' + pubLinkID,
                errcode
            );
            throw errcode;
        }
    }

    public decorateQueryParams(queryParams) {
        if (queryParams['sync_id']) {
            return { queryParams: { sync_id: queryParams['sync_id'] } };
        }
    }

    public decorateFragment(isLinkCompat, fragment) {
        if (!isLinkCompat || fragment) {
            return { fragment: fragment };
        }
    }
}
