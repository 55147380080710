<!-- modalcontent -->
<div class="modal-header">
  <button (click)="activeModal.close()" type="button" class="close" data-dismiss="modal" aria-label="Close"
    title="Close"><span aria-hidden="true">&times;</span></button>
  <button (click)="activeModal.close()" type="button" class="close" style="margin-top: -8px; margin-right: 15px;"
    title="Minimize" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i
        class="fa fa-xs fa-window-minimize" style="font-weight: 500;"></i></span></button>
  <h4 class="modal-title" style="padding-left: 0px;">Folder transfer</h4>
</div>

<div class="modal-body modal-body-overflow">

  <div *ngIf="runUploadFolderService.folderUploadInProgress" class="alert alert-info">
    There is already a folder upload in progress. Please wait for it to complete before proceeding.
  </div>

  <!-- error -->
  <sync-error-display *ngIf="view?.error" class="alert alert-info" [errorItem]="view?.error"></sync-error-display>

  <!-- finished -->
  <div [hidden]="!isFinished()">
    <div class="alert alert-info" *ngIf="view?.fileErrorCount > 0">
      There were <span [innerHtml]="view?.fileErrorCount"></span> files that failed transfer. Please click restart to
      attempt to finish them.
    </div>
    <!-- used to be ng-show="view.current.status == 1" -->
    <i *ngIf="view?.fileErrorCount < 1" class="zmdi zmdi-check-circle"></i> Upload completed

  </div>

  <!-- processing -->
  <div [hidden]="!view?.isScanning">
    <p>
      Found {{view?.fileCount}} files and {{view?.folderCount}} folders ({{view?.totalBytes|bytesToSize}})
    </p>
    <div *ngIf="view?.isMakingFolders">
      <!-- {{view.cwdName}} --><!-- <p>Making folder: {{ view.currentFolder}}</p> -->
      <i class="fa fa-circle-o-notch fa-spin"></i> Processing folders ({{getMakeDirPercent()|number:0}}%)
    </div>
    

    <div *ngIf="view?.isMakingTransfers">
      <i class="fa fa-circle-o-notch fa-spin"></i>
      Processing upload
    </div>
  </div>

  <!-- total transfer status summary -->
  <div [hidden]="!(!view?.isScanning && (view?.isProcessing || !isFinished()))">
    <p>
      Completed {{view?.fileCount - view?.filePendingCount}}/{{view?.fileCount}}
      (Remaining: <!-- {{view.eta|timeRemaining }} -->
      {{view?.pendingBytes|bytesToSize}})
    </p>

    <table class="table list-table list-table-modal ">

      <tbody>
        <tr>
          <td class="table-transferpreview"><img class="hidden-xs" *ngIf="view?.current?.filedata?.cssclass"
            [src]="'images/icons/' + view?.current?.filedata?.cssclass + '.svg'" width="24" height="24" /></td>
          <td class="table-transfername">
            <div class="tdmore"><span [innerHtml]="view?.current?.filename"></span></div>
          </td>
          <td class="table-transfersize hidden-xs">
            <div class="tdmore">
              <span *ngIf="view?.current?.status < 3000 || view?.current?.status > 3200">
                <!--{{view.current.bytes_sent | bytesToSize}} -->
                <span [hidden]="view?.current?.status == 1">{{view?.current?.filesize | bytesToSize}}</span>
              </span>
            </div>
          </td>
          <td class="table-transferstatus">

            <!-- show spinner when transferring -->
            <i [class.hide]="view?.current?.status <= 10 && view?.current?.status >= 20" class="fas fa-sync fa-spin"></i>

            <!-- show check when complete -->
            <i [class.hide]="view?.current?.status !== 1" class="fas fa-check"></i>

            <!-- complete status -->
            <span *ngIf="view?.current?.status == 1">
              {{view?.current.status | translateTransferItem}}
            </span>

          </td>
        </tr>

        <tr [hidden]="view?.current?.renderFile?.dl_ready">
          <td class="table-transferprogress" colspan="4">

            <ngb-progressbar *ngIf="view?.current?.status >= 10 && view?.current?.status <= 20" animate="false"
              [value]="view?.current?.progress_percent" class="progress modal-transfer-progress"
              style="margin:0px;padding:0px;background-color:white;margin-top:-3px;box-shadow: none;width:100%;">
              {{view?.current?.progress_percent}}%</ngb-progressbar>
          </td>
        </tr>
      </tbody>

    </table>
  </div> <!-- END total transfer status summary -->

</div>
<div class="modal-footer">

  <div *ngIf="view?.fileCount > 500" class="col-md-8 text-left small">
    <i class="fa fa-exclamation-triangle"></i>
    <strong>For large folder uploads we suggest you upload via the Sync desktop app for improved performance</strong>
  </div>



  <button *ngIf="view?.fileErrorCount > 0" (click)="restart()" type="button" class="btn btn-primary">Restart
  </button>

  <button *ngIf="!isFinished() || view?.isProcessing" (click)="cancel()" type="button" class="btn btn-danger">Cancel
  </button>

  <button (click)="activeModal.close()" type="button" class="btn btn-default">Close
  </button>

</div>