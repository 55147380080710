import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ValidateService } from '../../core/validate.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { ShareNewService } from '../share-new.service';
import { ShareService } from '../share.service';
import { BaseApiOutput, BlendEvent } from '../../shared/models';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';

import { JoblockService } from '../../core/joblock.service';
import { BlendService } from 'src/app/shared/services/blend.service';

@Component({
  selector: 'sync-dialog-share-new',
  templateUrl: './dialog-share-new.component.html'
})
export class DialogShareNewComponent implements OnInit {

    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public isChecking = false;
    public isVerified: boolean;
    public isShareLimit = false;
    public spinner: boolean;
    public syncId: number;
    public completed: number;
    public count: number;
    public errors: Array<sync.IErrorCode> = [];
    public filename: string;
    public validate: any;
    public shareId: number;
    public cachekey: string;
    public inviteKey: string;
    public sharekey: string;
    @Input('selected') selected: sync.IFile[] = [];
    @Input('pid') pid: number;
    public mkshareJob: any;
    public sync_id: number;

    private shareJobSubscription: Subscription;
    public shareAddJob: BaseApiOutput;
    public bg: number;

    private pathlist: sync.IFile[];
    private cwd: sync.IFile;

    public sharememberInvite: sync.ISharememberInviteData = {
            permissions: {
                'perDOWNLOAD': 1,
                'perUPLOAD': 1,
                'perINVITE': 0,
                'perSEEOTHERS': 1,
                'perADDAPP': 0,
            },
            displayName: this.base64Service.decode(this.userService.get('display_name')),
            queue: [],
            roleQueue: [],
            roleName: '',
            privateMessage: ''
        };

        newshareform = new FormGroup({
            filename: new FormControl('', [Validators.required, this.leadingSpace.bind(this), this.trailingChar.bind(this), this.reservedNames.bind(this),
            this.illegalCharacters.bind(this), this.exist.bind(this)])
        });

    constructor(
        private base64Service: Base64Service,
        private userService: UserService,
        private validateService: ValidateService,
        private fileListService: FileListService,
        private loggerService: LoggerService,
        public activeModal: NgbActiveModal,
        private shareService: ShareService,
        private shareNewService: ShareNewService,
        private JobLockService: JoblockService,
        private store: Store<State>,
        private blendService: BlendService
    ) {
        this.isVerified = this.userService.get('is_verified');
        this.isChecking = true;
        this.completed = 0;
        this.validate = this.validateService;
        this.pid = 0;
        if (this.selected.length && this.selected[0]) {
            this.fileListService.getListSubscription()
                .subscribe((data) => {
                    if (data && data.cwd) {
                        this.cwd = data.cwd;
                    }
                    if (this.selected[0].sync_id == data.cwd.sync_id) {
                        if (data && data.pathlist.length) {
                            this.pathlist = data.pathlist;
                        }
                    }
                });
        }
    }

     onChange(event: any) {
        this.filename = event.target.value;
     }
    leadingSpace(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return (fileName && fileName.charAt(0) !== ' ') ? null : { 'leadingSpace': true};
      }

    trailingChar(control: FormControl) {
        const fileName = control.value;
        const lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) { return true; }
        return !(lastChar === '.' || lastChar === ' ') ? null : { 'trailingChar': true};
    }
     reservedNames(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return !(/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i).test(fileName) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !(/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i).test(fileName) ? null : { 'reserved': true};
    }

    illegalCharacters(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return (/^[^\/:\*\?"<>\|]+$/).test(fileName) ? null : { 'illegal': true};
    }

    exist(control: FormControl) {
        const fileName = control.value;
        if (!this.pathlist) { return true; }
        if (this.validateService.isEmpty(fileName)) { return true; }
        const found = this.pathlist.filter((val) => {
            return (val.name.toLowerCase() == fileName.toLowerCase());
        });
        return !(found && found.length > 0) ? null : { 'exist': true};
    }

    ngOnInit() {
        this.bg = 1;  //use background job 1 = true; change this variable to switch between background jobs for 'shareadd'
        this.init();
    }

  public exists(value: string , isPristine: boolean) {
    if (isPristine || this.validateService.isEmpty(value)) { return true; }
    if (!this.pathlist) { return true; }
    const found = this.pathlist.filter((val) => {
        return (val.name.toLowerCase() == value.toLowerCase());
    });
    return !(found && found.length > 0);
  }

    private async init() {
        this.syncId = null;
        this.isChecking = true;

        try {
            let data;
            if (this.selected && this.selected.length && this.selected[0]) {
                const item = this.selected[0];
                this.syncId = item.sync_id;
                this.filename = item.name;
                this.newshareform.patchValue({ filename: this.filename });
            } else if (this.pid && this.pid != 0) {
                data = await this.fileListService.getDataNoState(this.pid, '0');
                this.pathlist = data.pathlist;
                // } else if (this.cwd && this.User.get('syncus_sync_id') != this.cwd.sync_id) {
                //     this.pid = this.User.get('syncus_sync_id');
                //     data = this.FileList.getDataNoState(this.User.get('syncus_sync_id'), '0');
            } else {
                this.pid = this.userService.get('syncus_sync_id');
                data = await this.fileListService.getDataNoState(this.userService.get('syncus_sync_id'), '0');
                this.pathlist = data.pathlist;
            }
            await this.shareNewService.checkShare(this.syncId);
            this.isShareLimit = false;
        } catch (ex) {
            if (ex && ex.error_code == 8027) {
                this.isShareLimit = true;
            } else {
                this.handleErr(ex);
            }
        }
        this.isChecking = false;
    }

  shareMemberInviteChange(value: any) {
    this.sharememberInvite = value;
  }

    public async postmkShareSteps() {
        if (this.bg == 1) {
            const response = await this.JobLockService.pollJobStatus();
            this.loggerService.info({ func: 'postShareJobCompleteAction', inviteKey: this.inviteKey });
            const data = await this.shareNewService.postshareDataProcess(this.syncId);
            const share_id = data.shareId;
            await this.shareNewService.shareMailInvite(share_id, this.inviteKey, this.sharememberInvite);
        }
        this.spinner = false;
        this.fileListService.reload();
        this.activeModal.close();
    }

    public makeShare() {
        if (this.newshareform.valid) {
            this.spinner = true;
            this.completed = 0;
            // this.startTimeInterval();

            if (!this.sharememberInvite.displayName) {
                this.errors.push({ code: 1654 });
                return;
            }

            if (this.selected && this.selected[0] && this.selected[0].is_hide_share && this.selected[0].is_shared) {
                // This effectively just "Un-hides" the share
                this.loggerService.info('Share New: Adding share to hidden share.');
                return this.unhideShare();
            } else if (this.syncId) {
                this.loggerService.info('Share New: Converting existing directory to share');
                return this.convertShare();
            } else {
                this.loggerService.info('Share New: Creating new directory for new share');
                return this.newShare();
            }
        }
    }

private async unhideShare() {

    if (!this.selected[0].share_id) {
        // TODO Errors
        this.loggerService.error('Directory is a hidden share but doesn\'t have a share_id');
        this.errors.push({code: 11201});
        return;
    }

    const shareData = await this.shareService.getShare(this.selected[0].share_id)
    .catch((err) => {
        this.handleErr(err);
        return;
    });

    if (!shareData) {
        this.loggerService.error(`No sharedata for share_id=${this.selected[0].share_id}`);
        this.errors.push({code: 11201});

    } else {
        this.shareNewService.addMember(shareData, this.sharememberInvite).then(() => {
            this.spinner = false;
            this.activeModal.close();
        }).catch((err) => {
            this.spinner = false;
            this.handleErr(err);
            return;
        });
    }

}

private async convertShare() {
    try {
        if (
            !this.validateService.isDisplayNameValid(
                this.sharememberInvite.displayName
            )
        ) {
            this.handleErr({ code: 1659 });
            return;
        }
            this.shareJobSubscription = this.shareNewService
                .mkShare(
                    this.syncId,
                    this.newshareform.value.filename,
                    this.newshareform.value.filename,
                    this.sharememberInvite,
                    null,
                    this.bg
                )
                .subscribe(
                    (next) => {
                        if (next.count) {
                            this.updateProgress(next);
                        } else {
                            this.shareId = next.shareId;
                            this.inviteKey = next.inviteKey;
                            this.cachekey = next.cachekey;
                            this.bg = next.bg;
                            this.sharekey = next.sharekey;
                            this.postmkShareSteps();
                        }
                    },
                    (error) => {
                        this.handleErr(error);
                    }
                );
    } catch (err) {
        this.loggerService.error(`error in convertShare: ,${err}`);
        this.handleErr(err);
    }
}

private newShare() {
    try {
        if (
            !this.validateService.isDisplayNameValid(
                this.sharememberInvite.displayName
            )
        ) {
            this.handleErr({ code: 1659 });
            return;
        }
        this.blendService.track(BlendEvent.BUTTON_CLICKED, {
            button_text: 'Create Share'
        });
            this.fileListService
                .mkdir(this.pid, this.newshareform.value.filename)
                .then((data) => {
                    this.syncId = data.pathitem.sync_id;
                    return this.shareNewService
                        .mkShare(
                            this.syncId,
                            this.newshareform.value.filename,
                            this.newshareform.value.filename,
                            this.sharememberInvite,
                            null,
                            this.bg
                        )
                        .toPromise()
                        .then((next) => {
                            this.shareId = next.shareId;
                            this.inviteKey = next.inviteKey;
                            this.cachekey = next.cachekey;
                            this.bg = next.bg;
                            this.sharekey = next.sharekey;
                            this.postmkShareSteps();
                        });
                })
                .catch((err) => this.handleErr(err));
    } catch (err) {
        this.loggerService.error(`error in newShare: ,${err}`);
        this.handleErr(err);
    }
}

public isValid() {
    return  !this.spinner && this.newshareform.valid && !this.isChecking && !this.isShareLimit;
}

private handleErr(err: any) {
    this.spinner = false;
    if (typeof err === 'object') {
        if (err.error_code) {
            this.errors.push({code: err.error_code, msg: err.error_msg});
        } else if (err.code) {
            this.errors.push(err);
        } else {
            this.errors.push({code: 1000, msg: 'An unknown error occurred'});
        }
    }
}

private updateProgress(prog: any) {
    this.count = prog.count;
    this.completed += prog.completed;
    if (prog.completed >= prog.count) {
        // we're done
    }
}
}
