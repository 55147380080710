<div class="row content-account-summary">

  <ng-container *ngIf="zbillingqueuepending.length > 0 && !user.is_on_trial">
    <!-- Note: If we have multiple things in the billing queue other than delayed upgrade/renew this will need to change -->
    <div class="alert alert-warning">
      You have an upcoming plan change on {{zbillingqueuepending[0].contract_effective_date | date: 'longDate'}}
      (scheduled on {{zbillingqueuepending[0].cre_servtime | date: 'longDate'}})
      <br />
      If you wish to modify it please contact support at <a href="https://www.sync.com/support/">https://www.sync.com/support/</a>
    </div>
  </ng-container>

  <div class="col-xs-8">
    <img class="hidden-xs content-account-sync-logo" src="images/sync-q-lightbg.svg">
    <div class="content-account-summary-details">
      <span class="content-account-planname">
        {{planName}}
      </span><br />
      <ng-container *ngIf="user.is_on_trial">
        <span> {{currentTerm | titlecase}}</span>
        <br />
      </ng-container>
      <span *ngIf="checkPermission(permissionActions.MANAGE_BILLING)"><a class="subtext syncblue"
        style="cursor: pointer;" (click)="onUpgradeClick()">
          {{user.is_on_trial ? "Change plan" : "View upgrade options"}}
        </a></span><br />
      <span *ngIf="checkPermission(permissionActions.VIEW_BILLING) && planExpiry">
        <i *ngIf="planExpiryClose && !user.is_on_trial" class="fas fa-exclamation-circle"></i>
        <span *ngIf="planExpiryClose && !cancelInFuture && !user.is_on_trial">
          Next renewal date: {{planExpiry | date: 'longDate'}}
          <a *ngIf="checkPermission(permissionActions.MANAGE_BILLING) && planExpiryClose" routerLink="/account/renew"
            class="showhand syncblue">Renew now</a>
        </span>
        <br *ngIf="planExpiryClose && !cancelInFuture && !user.is_on_trial" />
        <span>
          <ng-container *ngIf="cancelInFuture">
            Account scheduled to be cancelled on {{planExpiry | date: 'mediumDate'}}
          </ng-container>
          <ng-container *ngIf="!cancelInFuture">
            <ng-container *ngIf="!user.is_on_trial">
              Term:  {{ ( zbillingqueuepending[0]?.contract_effective_date || planStart) | date: 'mediumDate' }}  -  {{ planExpiry | date: 'mediumDate' }}
            </ng-container>
            <ng-container *ngIf="user.is_on_trial">
              Trial ends on  {{planExpiry | date: 'MMMM d, yyyy'}}
            </ng-container>

            <span class="badge badge-warning" *ngIf="zprofile.balance > 0 && zprofile.unpaidInvoiceCount > 0">
              Unpaid invoices. Paid until {{paidThru | date: 'mediumDate'}}
            </span>
          </ng-container>
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="checkPermission(permissionActions.MANAGE_BILLING)"
    class="col-xs-4 content-account-summary-buttons text-right">
    <button *ngIf="canRenew()" class="btn btn-default" type="button" routerLink="/account/renew">Renew</button>
    <button class="btn btn-primary" type="button" (click)="onUpgradeClick()">
      {{user.is_on_trial && zprofile ? (!zprofile.defaultPaymentMethod ? "Add billing profile" : "Change plan") : "Upgrade" }}
    </button>
  </div>
</div>
