import { OnInit, AfterViewInit, Component } from '@angular/core';
import * as fromRoot from './reducers';
import { Store, select } from '@ngrx/store';
import { environment } from '../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VersionService } from './shared/services/version.service';
import { SentryService } from './core/sentry.service';

@Component({
    selector: 'sync-app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'syncCp';
    public versionChanged: boolean;

    constructor(private store: Store<fromRoot.State>, private sanitizer: DomSanitizer, public versionService: VersionService,
        private sentryService: SentryService
    ) {
    }

    ngOnInit(): void {
        // This needs to be uncommented when we fix the refresh prompt
        // setInterval(async () => {
        //     // Check for version change recursively
        //     this.versionChanged = await this.versionService.loadVersion();
        // }, 150000); // 150000 milliseconds (adjust as needed)
    }

    ngAfterViewInit(): void {
        this.store
        .pipe(select(fromRoot.getAuthUser))
        .subscribe((data) => {
            const synccp = document.getElementById('synccpIframe') as HTMLIFrameElement;
            if (synccp) {
                const storageItems = { localstorage: { ...localStorage }, cookie: document.cookie };
                synccp.contentWindow.postMessage(storageItems, environment.syncCpHost);
            }
        });
    }
}
