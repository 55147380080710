<div class="modal-header">
    <button type="button" class="close" (click)="closeDialog()">
        <i class="ion-android-close fa-lg"></i>
    </button>
    <h4 class="modal-title">
        <i class="fa fa-history"></i>
        Choose a directory
    </h4>
</div>

<div class="modal-body">
    <p>Destination: <strong [innerHtml]="getDestination()"></strong></p>
    <sync-dir-picker [skipShareFolders]="1"></sync-dir-picker>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="closeDialog()">
        Done
    </button>
</div>
