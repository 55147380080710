
import { Action } from '@ngrx/store';

import { AuthUserPassword, EncFile, ErrCode, WebPath, User } from '../shared/models';
import { PathListApiInput, PathListApiOutput } from '../shared/models/api';
// import { Sort } from '@angular/material';
export const FILE_LIST_RESET = '[FileList] Reset';
export const FILE_LIST_REFRESH = '[FileList] Refresh';
export const FILE_LIST_RELOAD = '[FileList] Reload';
export const FILE_LIST_ERROR = '[FileList] Error';
export const FILE_LIST_SUCCESS = '[FileList] Success';
export const FILE_LIST_SORT = '[FileList] Sort';
export const FILE_LIST_SELECT = '[FileList] Select';
export const FILE_LIST_SELECT_ALL = '[FileList] Select all';
export const FILE_LIST_SELECT_TOGGLE = '[FileList] Select toggle';
export const FILE_LIST_SELECT_TYPE = '[FileList] Select by type';
export const FILE_LIST_SET_CWD = '[FileList] Set cwd';
export const FILE_LIST_SET_PARENTS = '[FileList] Set parents';
export const FILE_LIST_SET_HIST_ID = '[FileList] Set hist_id';
export const FILE_LIST_SET_PROGRESS = '[FileList] Set progress';
export const FILE_LIST_SHOW_DELETED = '[FileList] Show deleted';
export const FILE_LIST_SHOW_FAVORITES = '[FileList] Show favorites';
export const FILE_LIST_TOTAL_FAVORITE = '[FileList] Total favorite';
export class FileListResetAction implements Action {
    readonly type = FILE_LIST_RESET;
    constructor() {}
}
export class FileListSuccessAction implements Action {
    readonly type = FILE_LIST_SUCCESS;
    constructor(public payload: sync.IFile[]) {}
}
export class FileListSetCwdAction implements Action {
    readonly type = FILE_LIST_SET_CWD;
    constructor(public payload: sync.IFile) {}
}
export class FileListSetParentsAction implements Action {
    readonly type = FILE_LIST_SET_PARENTS;
    constructor(public payload: EncFile[]) {}
}
export class FileListSetProgressAction implements Action {
    readonly type = FILE_LIST_SET_PROGRESS;
    constructor(public payload: number) {}
}
export class FileListSetHistIdAction implements Action {
    readonly type = FILE_LIST_SET_HIST_ID;
    constructor(public payload: number) {}
}
export class FileListShowDeletedAction implements Action {
    readonly type = FILE_LIST_SHOW_DELETED;
    constructor() {}
}
export class FileListShowFavoritesAction implements Action {
    readonly type = FILE_LIST_SHOW_FAVORITES;
    constructor(public payload: {syncId: number}) {}
}
export class FileListRefreshAction implements Action {
    readonly type = FILE_LIST_REFRESH;
    constructor(public payload: PathListApiInput) {}
}
export class FileListReloadAction implements Action {
    readonly type = FILE_LIST_RELOAD;
    constructor() {}
}

export class FileListErrorAction implements Action {
    readonly type = FILE_LIST_ERROR;
    constructor(public payload: ErrCode) {}
}

export class FileListSortAction implements Action {
    readonly type = FILE_LIST_SORT;
    constructor(public payload: any) {}
}

export class FileListSelectAction implements Action {
    readonly type = FILE_LIST_SELECT;
    constructor(public payload: sync.IFile) {}
}

export class FileListSelectAllAction implements Action {
    readonly type = FILE_LIST_SELECT_ALL;
    constructor(public payload: any) {}
}
export class FileListSelectToggleAction implements Action {
    readonly type = FILE_LIST_SELECT_TOGGLE;
    constructor(public payload: sync.IFile) {}
}
export class FileListSelectTypeAction implements Action {
    readonly type = FILE_LIST_SELECT_TYPE;
    constructor(public payload: any) {}
}
export class FileListTotalFavoriteAction implements Action {
    readonly type = FILE_LIST_TOTAL_FAVORITE;
    constructor(public payload: any) {}
}

export type All = FileListResetAction
    | FileListRefreshAction
    | FileListReloadAction
    | FileListSetProgressAction
    | FileListShowDeletedAction
    | FileListShowFavoritesAction
    | FileListSetCwdAction
    | FileListSetHistIdAction
    | FileListSetParentsAction
    | FileListSuccessAction
    | FileListErrorAction
    | FileListSortAction
    | FileListSelectAction
    | FileListSelectAllAction
    | FileListSelectToggleAction
    | FileListSelectTypeAction
    | FileListTotalFavoriteAction;


