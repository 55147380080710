import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { MultiAdminService } from '../../core/multiadmin.service';
import { UserService } from '../../core/user.service';
import { ErrCode, MultiAdminUserData, MultiSelectItem, PermissionActions, Role } from '../../shared/models';
import { ValidateService } from '../../core/validate.service';

@Component({
  selector: 'sync-sharemember-add',
  templateUrl: './sharemember-add.component.html'
})
export class SharememberAddComponent implements OnInit {

    @Input('sharememberInvite') public sharememberInvite: sync.ISharememberInviteData;
    @Input('shareData') public shareData: sync.IShareData;
    public errors: Array<sync.IErrorCode> = [];
    public spinner: boolean;

    public isPro = false;
    public isVerified = false;

    public emailfield: any;
    // must be initialized as an array or email validation fails.
    public contacts: Array<string> = [];
    public invitedUserIds: Array<number> = [];
    public permission = '1';
    public disableValidation = false;
    public isOnTrial = false;
    public permissionActions = PermissionActions;
    public isRolesAllowed: Boolean = false;
    public rolesList: MultiSelectItem[] = [];
    public selectedRoleNames: string[] = [];
    public teamUsers: MultiAdminUserData[] = [];

    public isLoading = true;
  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private loggerService: LoggerService,
    public activeModal: NgbActiveModal,
    private multiadminService: MultiAdminService,
    private validateService: ValidateService
  ) {}

   async ngOnInit() {
    this.isPro = (this.userService.get('is_pro'));
        this.isVerified = (this.userService.get('is_verified'));
        this.isOnTrial = (this.userService.get('is_on_trial'));
      this.apiService.execute<any>('contactlist', {}).then((data) => {
            this.contacts = data.contacts.map((val: any) => val.value);
        });
    this.isRolesAllowed = this.userService.get('is_multi_new') && this.checkPermission(this.permissionActions.VIEW_ROLES) && this.userService.get('is_unlimited_plan');
    this.invitedUserIds = this.shareData && this.shareData.people ? this.shareData.people.filter(value => value.user_status_id === 1).map((val: any) => val.userId) : [];
    if (this.isRolesAllowed) {
        await this.getUsersList();
        await this.getRolesList();
    }
    this.isLoading = false;
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term === '' ? []
      : this.contacts.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

    permissionChanged(newVal: number) {
    if (newVal == 1) {
        this.sharememberInvite.permissions.perDOWNLOAD = 1;
        this.sharememberInvite.permissions.perUPLOAD = 1;
        this.sharememberInvite.permissions.perSEEOTHERS = 1;
        this.sharememberInvite.permissions.perINVITE = 0;
        this.sharememberInvite.permissions.perADDAPP = 0;
        console.log('set permissions to readwrite');
    } else if (newVal == 2) {
        this.sharememberInvite.permissions.perDOWNLOAD = 1;
        this.sharememberInvite.permissions.perUPLOAD = 0;
        this.sharememberInvite.permissions.perSEEOTHERS = 1;
        this.sharememberInvite.permissions.perINVITE = 0;
        this.sharememberInvite.permissions.perADDAPP = 0;
        console.log('set permissions to readonly');
    } else {
        this.loggerService.warn('unknown permission set for share');
    }
  }

  public togglePerm(perm: string) {

    if (perm == 'perUPLOAD' && !this.isPro) {
        this.loggerService.info('PER UPLOAD BUT user is not pro');
        return false;
    }

    this.sharememberInvite.permissions[perm] = (this.sharememberInvite.permissions[perm]) ? 0 : 1;
}

public getContactName(email: string) {
    for (let i = 0, len = this.contacts.length; i < len; i++) {
        if (this.contacts[i].toLowerCase() == email.toLowerCase()) {
            return this.contacts[i];
        }
    }
    return email;
}
public removePerson(email: string) {
    this.sharememberInvite.queue.splice(this.sharememberInvite.queue.indexOf(email.toLowerCase()), 1);

}
    onFocus() {
    this.disableValidation = false;
}

public queueInvite(f: NgForm) {
    this.disableValidation = true;
    if (!this.emailfield || !f.valid) { return false; }
    this.errors = [];
    let email: string;
    email = this.emailfield.toLowerCase();
    if (!this.validateService.isEmail(email)) {
        f.controls['email'].setErrors({ 'email': true });
        return false;
    }
    if (email == this.userService.get('email').toLowerCase()) {
        this.errors.push({
            code: 1650
        });
        this.loggerService.error('cannot queue yourself');
        return;
    }
    if (this.shareData && this.shareData.people && this.shareData.people.length) {
        for (let i = 0, len = this.shareData.people.length; i < len; i++) {
            const person = this.shareData.people[i];
            if (person.email.toLowerCase() == email &&
                person.status == 'Active') {
                    this.errors.push({
                        code: 1651,
                        msg: `The user ${email} is already on this share`
                    });
                    this.loggerService.warn('${email}  is already on the share');
                    return false;
                }
        }
    }
    if (this.sharememberInvite.queue.length) {
        this.sharememberInvite.queue.map((val) => {
            if (val == email) {
                this.errors.push({
                    code: 1653,
                    msg: `The user ${email} is in your invite queue already`
                });
                this.loggerService.error('this person is already queued');
                return false;
            }
        });
    }
    if (this.errors.length) { return false; }
    if (this.sharememberInvite.queue.length >= 20) {
        this.errors.push({
            code: 1652
        });
        return;
    }
    this.sharememberInvite.queue.push(email);
    this.emailfield = '';
    this.disableValidation = false;
    return true;
}

public onRoleSelect(selectedRoles: MultiSelectItem[]) {
    this.sharememberInvite.roleQueue = [];
    this.sharememberInvite.roleName = '';
    this.selectedRoleNames = selectedRoles.map(role => role.name);
    this.sharememberInvite.roleName = this.selectedRoleNames.join(', ');

    this.teamUsers.forEach(user => {
        if (this.selectedRoleNames.includes(user.roles.toString()) && this.userService.get('email').toLowerCase() != user.email.toLowerCase() && !this.invitedUserIds.includes(user.user_id) && user.user_status_id === 1) {
            this.sharememberInvite.roleQueue.push(user.email);
        }
    });
}

public async getRolesList() {
    try {
        const response = await this.multiadminService.getRoles();
        const rolesList = (response as Role[])['roles'] as Role[];
        const multiSelectItems: MultiSelectItem[] = [];
        rolesList.forEach(role => {
            let multiSelectItem: MultiSelectItem;
            // Excluding current logged in user
            role.users = role.users.filter(user => user.user_id != this.userService.get('id') && !this.invitedUserIds.includes(user.user_id));
            multiSelectItem = role;
            multiSelectItem.displayName = `${role.name} (${role.users.length}${!role.users.length ? ' Users' :
                (role.users.length > 1 ? ' Users will be invited' : ' User will be invited')})`;
            multiSelectItem.disabled = role.users.length === 0;

            multiSelectItems.push(multiSelectItem);
        });
        this.rolesList = multiSelectItems;
    } catch (ex) {
        this.loggerService.error(ex);
        const error = ErrCode.fromException(ex);
        this.errors.push({
            code: error.code,
            msg: error.msg.toString()
        });
    }
}

public async getUsersList() {
    try {
        const result = await this.multiadminService.getUserList();
        this.teamUsers = result.users;
    } catch (ex) {
        this.loggerService.error(ex);
        const error = ErrCode.fromException(ex);
        this.errors.push({
            code: error.code,
            msg: error.msg.toString()
        });
    }
}


public checkPermission(permName: PermissionActions): boolean {
    return this.userService.checkPermission(permName);
}

get roleSelectedText(): string {
    if (!this.selectedRoleNames.length) {
        return '';
    }
    return `${this.selectedRoleNames.length}${this.selectedRoleNames.length == 1 ?  ' role selected' : ' roles selected'}`;
}

}
