<!-- COMMENT REPLY -->
<div *ngIf="reply">
    <td class="comment-reply-marker">
        <img
            class="comment-reply-marker-hline"
            src="../../../images/line-v.png"
        />
    </td>

    <!-- AVATAR FOR EXISTING REPLY -->
    <td class="comment-reply-avatar-box" *ngIf="!isInputBox">
        <img
            class="img-circle comment-avatar"
            [src]="authorAvatar"
            onerror="this.onerror=null;this.src='../../../images/sync-construction.png';"
        />
    </td>
    <!-- / AVATAR FOR EXISTING REPLY -->

    <td class="comment-reply-text-box">
        <span *ngIf="!editMode" class="comment-text-display">
            {{ reply.content }}</span
        >
        <!-- REPLY TEXT BOX AND BUTTONS -->
        <div id="edit-reply-form">
            <textarea
                class="comment-text-input-edit"
                [maxlength]="contentLength"
                minlength="1"
                type="text"
                [formControl]="replyEditionForm"
                *ngIf="editMode"
            >
            </textarea>
            <sync-content-length-display
                *ngIf="editMode"
                [value]="replyEditionForm.value"
                [maxLength]="contentLength"
            ></sync-content-length-display>

            <!-- REPLY EDIT MODE BUTTONS -->
            <div *ngIf="editMode" class="comment-reply-input-buttons">
                <button
                    [ngClass]="
                        link.image_cachekey
                            ? 'btn-primary-custom'
                            : 'btn-primary'
                    "
                    class="btn btn-default btn-sm"
                    (click)="updateReply()"
                    [disabled]="
                        replyEditionForm.invalid ||
                        !validContent(replyEditionForm.value)
                    "
                >
                    Edit
                </button>
                <button
                    class="btn btn-default btn-sm"
                    style="margin-left: 10px"
                    (click)="toggleReplyInputBox(); cancelEditMode()"
                >
                    Cancel
                </button>
            </div>
            <!-- / REPLY EDIT MODE BUTTONS -->

            <!-- REPLY EDIT DETAIL AND BUTTONS -->
            <div class="comment-text-box-details" *ngIf="detailEnabled && user">
                <span>Posted by: {{ reply.author_name | b64 }}</span
                ><br />
                <span>{{
                    reply.updated_at | date: "MMM dd, yyyy, h:mm a"
                }}</span>

                <span
                    *ngIf="
                        !editMode &&
                        !confirmedDelete &&
                        user.id == reply.author_id &&
                        tempSession == reply.temp_session
                    "
                >
                    -
                    <button
                        [ngClass]="
                            link.image_cachekey
                                ? 'link-primary-custom'
                                : 'link-primary'
                        "
                        class="text-only-btn"
                        (click)="editReply()"
                    >
                        Edit
                    </button>
                </span>

                <span
                    *ngIf="
                        !confirmedDelete &&
                        (user.id == ownerId ||
                            (user.id == reply.author_id &&
                                tempSession == reply.temp_session))
                    "
                >
                    -
                    <button
                        [ngClass]="
                            link.image_cachekey
                                ? 'link-primary-custom'
                                : 'link-primary'
                        "
                        class="text-only-btn"
                        (click)="toggleConfirm()"
                    >
                        Delete
                    </button>
                </span>

                <span
                    *ngIf="
                        confirmedDelete &&
                        (user.id == ownerId ||
                            (user.id == reply.author_id &&
                                tempSession == reply.temp_session))
                    "
                >
                    -
                    <button
                        [ngClass]="
                            link.image_cachekey
                                ? 'link-primary-custom'
                                : 'link-primary'
                        "
                        class="text-only-btn"
                        (click)="deleteReply()"
                        (blur)="toggleConfirm()"
                    >
                        Confirm
                    </button>
                </span>

                <span
                    *ngIf="
                        confirmedDelete &&
                        (user.id == ownerId ||
                            (user.id == reply.author_id &&
                                tempSession == reply.temp_session))
                    "
                >
                    -
                    <button
                        [ngClass]="
                            link.image_cachekey
                                ? 'link-primary-custom'
                                : 'link-primary'
                        "
                        class="text-only-btn"
                        (click)="cancelConfirm()"
                    >
                        Cancel
                    </button>
                </span>
                <br />
            </div>
            <!-- / REPLY EDIT DETAIL BUTTONS -->
        </div>
        <!-- / REPLY TEXT BOX AND BUTTONS -->
    </td>
</div>
<!-- / COMMENT REPLY -->

<!-- NEW REPLY INPUT BOX -->
<div id="replyBox" class="comment-reply-text-input-box" *ngIf="!reply">
    <textarea
        class="comment-text-input"
        [maxlength]="contentLength"
        type="text"
        placeholder="Enter a reply ..."
        [formControl]="replyCreationForm"
    >
    </textarea>
    <sync-content-length-display
        [value]="replyCreationForm.value"
        [maxLength]="contentLength"
    ></sync-content-length-display>

    <div class="comment-reply-input-buttons">
        <button
            [ngClass]="
                link.image_cachekey ? 'btn-primary-custom' : 'btn-primary'
            "
            class="btn btn-default btn-sm"
            (click)="createReply()"
            [disabled]="
                replyCreationForm.invalid ||
                !validContent(replyCreationForm.value)
            "
            *ngIf="isInputBox"
        >
            Post Reply
        </button>
        <button
            class="btn btn-default btn-sm"
            style="margin-left: 10px"
            (click)="toggleReplyInputBox(); cancelReplyCreation()"
        >
            Cancel
        </button>
    </div>
</div>
<!-- / NEW REPLY INPUT BOX -->
