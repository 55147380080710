



import { of, from, Observable, throwError } from 'rxjs';

import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';


import { ErrCode, User } from '../shared/models';
import * as AuthActions from '../actions/auth.actions';
import { AuthService } from '../auth/services/auth.service';
// import { Router } from '@angular/router';
import { UserService } from '../core/user.service';

@Injectable()
export class AuthEffects {

    constructor(
        private authService: AuthService,
        private userService: UserService,
        // private auth: AuthService,
        private actions$: Actions,
        // private user: UserService
    ) {
    }

    // Listen for the 'LOGIN' action
    @Effect()
    login$: Observable<Action> = this.actions$.pipe(
        ofType(AuthActions.AUTH_LOGIN),
        mergeMap((action: AuthActions.LoginAction) => {
            // console.log(action.payload);
            return from(this.authService.authenticate(
                action.payload.username,
                action.payload.password,
                action.payload.twofacode
            )).pipe(
                map(data => new AuthActions.LoginSuccessAction(data)),
                catchError((err) => of(new AuthActions.LoginFailureAction(err)))
            );
        })
    );
    @Effect()
    loginSSO$: Observable<Action> = this.actions$.pipe(
        ofType(AuthActions.AUTH_LOGIN_SSO),
        mergeMap((action: AuthActions.LoginSSOAction) => {
            // console.log(action.payload);
            return from(this.authService.loginSSO(action.payload)).pipe(
                map(user => new AuthActions.LoginSuccessAction(user)),
                catchError((err) => of(new AuthActions.LoginFailureAction(err)))
            );
        })
    );


    @Effect()
    refresh$: Observable<Action> = this.actions$.pipe(
        ofType(AuthActions.AUTH_REFRESH),
        mergeMap((action: AuthActions.RefreshAction) => {
            return from(this.userService.reload()).pipe(
                map(data => {
                    const user = new User().deserialize(data);
                    return new AuthActions.UpdateAction(user);
                }),
                catchError((err) => {
                    return of(new AuthActions.LoginFailureAction(err));
                })
            );
        })
    );
}
