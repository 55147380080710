export const Constants = {
    PPT_BLOB_TYPE: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    EXCEL_BLOB_TYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    DOCX_BLOB_TYPE: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8',
    TEXT_BLOB_TYPE: 'text/plain',
    DOC_FILE_TYPE: '.docx',
    PPT_FILE_TYPE: '.pptx',
    EXCEL_FILE_TYPE: '.xlsx',
    TEXT_FILE_TYPE: '.txt',
    DOCUMENT_TYPE_LIST: ['Word Document', 'Powerpoint Presentation', 'Excel Worksheet', 'Text File'],
    FILE_TYPE_LIST: ['.docx', '.pptx', '.xlsx', '.txt'],
    DOCUMENT_SVG_IMAGE_LIST: ['mime-msoffice-docx.svg', 'mime-msoffice-pptx.svg',
        'mime-msoffice-xlsx.svg', 'mime-txt.svg'],
    DUMMY_TEMPLATES_PATH: '/assets/dummyTemplates/',
    DUMMY_MS_WORD_FILE_NAME: 'template.docx',
    DUMMY_MS_EXCEL_FILE_NAME: 'template.xlsx',
    DUMMY_MS_PPT_FILE_NAME: 'template.pptx',
    DUMMY_TEXT_FILE_NAME: 'template.txt',

    MAX_FILE_NAME_LEN: 248
};
