import { BaseApiOutput } from './base-api.model';
import { DirtyOutNeedEncItem } from '..';


export class KeysNeedEncByShareApiOutput extends BaseApiOutput {
    items: DirtyOutNeedEncItem[];
    sharekeys: { [sharekeyid: string]: string };
}
export class KeysNeedEncByShareApiInput {
    share_id: number;
}


