import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../core/logger.service';
import { ValidateService } from '../../core/validate.service';
import { FileListService } from '../services/file-list.service';
import { DocType } from '../../shared/types';
import { Constants } from '../../transfer/constants';
import { TransferService } from '../../transfer/transfer.service';
import { BlendService } from '../../shared/services/blend.service';
import { BlendEvent } from 'src/app/shared/models';
import { FileItemService } from '../../file/file-item.service';

@Component({
    selector: 'sync-dialog-create-blank-document',
    templateUrl: './dialog-create-blank-document.component.html'
})
export class DialogCreateBlankDocumentComponent implements OnInit {
    fileName = 'untitled.docx';
    documents = [];
    selectedDocType: DocType;
    public maxFileNameLength = Constants.MAX_FILE_NAME_LEN;
    public errors: Array<sync.IErrorCode>;

    private pathlist: sync.IFile[];

    constructor(
        private fileListService: FileListService,
        private loggerService: LoggerService,
        private http: HttpClient,
        private transferService: TransferService,
        private validateService: ValidateService,
        public activeModal: NgbActiveModal,
        private blendService: BlendService,
        private fileItemService: FileItemService
    ) {}

    ngOnInit() {
        this.errors = [];
        this.prepareDocumentList();
        this.onSelectDocType('.docx');
        this.fileListService.getListSubscription().subscribe((data) => {
            if (
                data &&
                data.pathlist.length &&
                !(this.pathlist !== undefined && this.pathlist.length)
            ) {
                this.pathlist = data.pathlist;
                this.onSelectDocType('.docx');
            }
        });
    }

    /**
     * set doc type based on file selecetion
     * @param docType
     */
    public onSelectDocType(docType: DocType): void {
        if (docType) {
            this.errors = [];
            this.selectedDocType = docType;
            if (this.fileName.includes('.')) {
                this.fileName = this.fileName.substring(
                    0,
                    this.fileName.lastIndexOf('.')
                );
            }
            this.fileName = this.createFileName(this.selectedDocType);
        } else {
            this.selectedDocType = Constants.TEXT_FILE_TYPE as DocType;
        }
    }

    /**
     * create document
     */
    public createDocument(): void {
        this.errors = [];
        try {
            if (this.isValid()) {
                const docType = this.selectedDocType;
                this.blendService.track(BlendEvent.CREATE_FILE, {
                    fileType: docType,
                    mimeType: this.fileItemService.getmimeType(docType)
                });
                switch (docType) {
                    case Constants.DOC_FILE_TYPE:
                        this.createDocxDocument(this.createFileName(docType));
                        break;
                    case Constants.PPT_FILE_TYPE:
                        this.createPptDocument(this.createFileName(docType));
                        break;
                    case Constants.EXCEL_FILE_TYPE:
                        this.createExcelDocument(this.createFileName(docType));
                        break;
                    case Constants.TEXT_FILE_TYPE:
                        this.createTextDocument(this.fileName.trim());
                        break;
                }
            } else {
                this.loggerService.warn(
                    'create document method document type is not available'
                );
            }
        } catch (err) {
            this.errors.push({ code: err.code });
            this.loggerService.error('Error in create Document ' + err);
        }
    }

    /**
     * prepare document list based on constants
     */
    private prepareDocumentList(): void {
        try {
            Constants.DOCUMENT_TYPE_LIST.map((document, index) => {
                const documentObject = {
                    id: index,
                    name: document,
                    extension: Constants.FILE_TYPE_LIST[index],
                    svg: Constants.DOCUMENT_SVG_IMAGE_LIST[index],
                };
                this.documents.push(documentObject);
            });
        } catch (err) {
            this.loggerService.error('Error on prepare document list ' + err);
        }
    }

    /**
     * create excel file object
     * @param fileName
     */
    private createExcelDocument(fileName: string): void {
        try {
            const url =
                Constants.DUMMY_TEMPLATES_PATH +
                Constants.DUMMY_MS_EXCEL_FILE_NAME;
            this.http.get(url, { responseType : 'arraybuffer'}).subscribe(
                (response: any) => {
                    this.createFile(
                        response,
                        Constants.EXCEL_BLOB_TYPE,
                        fileName
                    );
                },
                (err) => {
                    this.loggerService.error(
                        'Error while get request, reading file ' + err
                    );
                    throw err;
                }
            );
        } catch (err) {
            this.loggerService.error('Error in create Excel document ' + err);
            throw err;
        }
    }

    /**
     * create ppt file blank document
     * @param fileName
     */
    private createPptDocument(fileName: string): void {
        try {
            const url =
                Constants.DUMMY_TEMPLATES_PATH +
                Constants.DUMMY_MS_PPT_FILE_NAME;
            this.http.get(url, { responseType : 'arraybuffer'}).subscribe(
                (response: any) => {
                    this.createFile(
                        response,
                        Constants.PPT_BLOB_TYPE,
                        fileName
                    );
                },
                (err) => {
                    this.loggerService.error(
                        'Error while get request, reading file ' + err
                    );
                    throw err;
                }
            );
        } catch (err) {
            this.loggerService.error('Error in create pptx document ' + err);
            throw err;
        }
    }

    /**
     * create text file object
     * @param fileName
     */
    private createTextDocument(fileName: string): void {
        try {
            const url =
                Constants.DUMMY_TEMPLATES_PATH + Constants.DUMMY_TEXT_FILE_NAME;
            this.http.get(url, { responseType : 'arraybuffer'}).subscribe(
                (response: any) => {
                    this.createFile(
                        response,
                        Constants.TEXT_BLOB_TYPE,
                        fileName
                    );
                },
                (err) => {
                    this.loggerService.error(
                        'Error while get request, reading file ' + err
                    );
                    throw err;
                }
            );
        } catch (err) {
            this.loggerService.warn('error in create Text doucment ' + err);
            throw err;
        }
    }

    /**
     * create docx file object
     * @param fileExtension
     */
    private createDocxDocument(fileName: string): void {
        try {
            const url =
                Constants.DUMMY_TEMPLATES_PATH +
                Constants.DUMMY_MS_WORD_FILE_NAME;
            this.http.get(url, { responseType : 'arraybuffer'}).subscribe(
                (response: any) => {
                    this.createFile(
                        response,
                        Constants.DOCX_BLOB_TYPE,
                        fileName
                    );
                },
                (err) => {
                    this.loggerService.error(
                        'Error while get request, reading file ' + err
                    );
                    throw err;
                }
            );
        } catch (err) {
            this.loggerService.error('Error in Create docx document ' + err);
            throw err;
        }
    }

    /**
     * convert blob object into file.
     * @param theBlob
     * @param fileName
     * @returns
     */
    private blobToFile(theBlob: Blob, fileName: string): File {
        const blob: any = theBlob;
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        return <File>theBlob;
    }

    /**
     * upload blank document.
     * @param file
     */
    private uploadFile(file: File): void {
        this.transferService
            .queuePublicBlankDocUpload(file)
            .then((success) => {
                this.loggerService.info(
                    'file upload successfully' + JSON.stringify(success)
                );
                this.activeModal.close();
            })
            .catch((err) => {
                this.loggerService.error('Error in upload file ' + err);
                throw { code: 7244 };
            });
    }

    /**
     * check if file type selected & filename is given
     * won't allow to put .(dot) in filename
     * @returns boolean true/false
     */
    public isValid(): boolean {
        if (!this.selectedDocType) {
            throw { code: 7242 };
        }

        if (!this.fileName.length) {
            throw { code: 7241 };
        }

        if (!this.validateService.isNameValid(this.fileName)) {
            this.loggerService.warn(
                'File name ' + this.fileName + ' is not allowed'
            );
            throw { code: 8213 };
        }

        if (this.fileExists(this.fileName)) {
            throw { code: 8211 };
        }

        return true;
    }

    /**
     * create file Name with extension
     * @param fileExtension
     * @returns
     */
    private createFileName(fileExtension: string): string {
        const fileName = this.fileName;
        if (fileName.includes('.')) {
            const extension = fileName.substring(
                fileName.lastIndexOf('.'),
                fileName.length
            );
            if (extension !== this.selectedDocType) {
                return (this.fileName + fileExtension).trim();
            }
            return this.fileName.trim();
        }
        return (this.fileName + fileExtension).trim();
    }

    /**
     * create File object
     * @param data
     * @param blobType
     * @param fileName
     */
    private createFile(
        data: ArrayBuffer,
        blobType: string,
        fileName: string
    ): void {
        try {
            const file = new File([data], fileName);
            this.uploadFile(file);
        } catch (err) {
            this.loggerService.error('Error in createFile method, ' + err);
            throw err;
        }
    }

    /**
     * check if file with same name exists
     * @param value
     */
    public fileExists(fileName: string): boolean {
        if (fileName === undefined || fileName === '' || fileName === null) {
            return false;
        }

        if (!this.pathlist) {
            return false;
        }

        if (!fileName.endsWith(this.selectedDocType)) {
            fileName = fileName + this.selectedDocType;
        }

        const matchingFileName = this.pathlist.find(
            (f) => f.name.toLowerCase() === fileName.toLowerCase()
        );

        return matchingFileName !== undefined;
    }

    /**
     * on text change event
     * @param value
     */
    public change(): void {
        this.errors = [];
    }
}
