import { ErrCode } from '..';
import { BaseApiOutput } from './base-api.model';

export class LinkApiOutput extends BaseApiOutput {
    public type: 'file' | 'dir';
    public linkversion: number;
    public sync_id: number;
    public share_id: number;
    public share_sequence: number;
    public enc_share_key: string;
    public enc_key: string;
    public password: string;
    public salt: string;
    public interations: number;
    public is_suspended: 0 | 1;
    public exp_servtime: number;
    public download_limit: number;
    public download_count: number;
    public email_notification: 0 | 1;
    public compat: 0 | 1;
    public previewonly: 0 | 1;
    public file_edit: 0 | 1;
    public upload: 0 | 1 | 2;
    public cnt: number;
    public cachekey: string;
    public label: string;
    public enc_comment_key: string;
    public allow_comment: 0 | 1 | 2;
    public comment_notification: 0 | 1;
}

export class LinkApiInput {
    command = 'linkget';

    sync_id: number;
}


