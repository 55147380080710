
import { BaseApiOutput } from './base-api.model';

export class UserDiskUsageApiInput extends BaseApiOutput {
    sync_pid: number;
}


export class UserDiskUsageApiOutput extends BaseApiOutput {
    sync_pid: number;
    file_count: number;
    bytes: number;
}
