
<form syncStopPropagation [formGroup]="pathitemform" (ngSubmit)="submit()" autocomplete="off">
  <div class="input-group">
      <input 
         id="new-folder-text"
          name="pathname"
          type="text"
          class="form-control input-lg"
          [syncShowFocus]="showNewFolder"
          placeholder="Enter a folder name..."
          formControlName="pathname"
          autocomplete="off"
          (keyup.escape)="reset()"
      />
      <span class="input-group-btn ">
          <button 
              id="new-folder-add-btn"
              class="btn btn-default btn-lg actions-global-btn"
              type="submit"
              [disabled]="spinner"
          ><i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>Add</button>
      </span>
  </div>
  <div class="sync-tool-tip" 
  *ngIf="!pathitemform.controls.pathname.valid && pathitemform.controls.pathname.dirty">
    <i class="fas fa-exclamation-triangle"></i>
       <span *ngIf="pathitemform.controls.pathname.errors?.leadingSpace">
          Files cannot begin with a space
      </span>
      <span *ngIf="pathitemform.controls.pathname.errors?.trailingChar">
          Files cannot end in a . or a space.
      </span>
      <span *ngIf="pathitemform.controls.pathname.errors?.reserved">
          This name is a reserved and cannot be used.
      </span>
      <span *ngIf="pathitemform.controls.pathname.errors?.illegal">
          The following characters are not allowed: \ / : ? * &lt; &gt; &quot; |
      </span>
      <span *ngIf="pathitemform.controls.pathname.errors?.exist">
        A folder exists with this name already.
      </span>
      <span *ngIf="pathitemform.controls.pathname.errors?.required">
        The folder name cannot be blank.
    </span>
    <span *ngIf="pathitemform.controls.pathname.errors?.maxlength">
        The length of the folder name should not be greater than {{MAX_FOLDER_NAME_LEN}}
    </span>
  </div>

</form>
