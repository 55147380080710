<div class="error-general-overlay{{ user.email ? modalOpen ? ' error-general-overlay-login' : '' : ' error-general-overlay-login' }}" *ngIf="errcode && errcode.code">
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-exclamation-triangle"></i>
            {{errcode.code}}
            <span *ngIf="errcode.msg" class="error-msg" [innerHTML]="errcode.msg"></span>
            <span *ngIf="!errcode.msg">An error occurred.</span>
        </div>
    </div>
    <p *ngIf="errcode.code == 1610 && errcode.data">
        The existing nested share (id: {{errcode.data}}
        <a href="/files/{{errcode.data}}" target="_blank">
            can be viewed here
        </a>
    </p>
    <ul *ngIf="errcode.code == 8122 && errcode.data" style="list-style: none; padding: 0; margin-top: 5px;">
        <li *ngFor="let email of errcode.data">{{email.usernameB64}}</li>
    </ul>

    <p *ngIf="errcode.code == 1101">
        This link is invalid. Please contact the owner of the link and ensure
        that you are using the correct address.
    </p>
    <div *ngIf="errcode.code == 1600">
        There are three reasons this would happen:
        <ol>
            <li>You've already accepted or declined this share and the
                link is old.</li>
            <li>Your invite was removed by the share owner before you had
                a chance to act on it. In this case, please contact
                the share owner to re-invite you. You will receive
                a new link to join this share afterwards.</li>
            <li>The address you were provided contains an error or was
                modified by your email client. Ensure the link
                was sent correctly and when you clicked it in your email, the
                entire link was clickable. You can always copy/paste the
                address into your browser.</li>
        </ol>
    </div>
    <div *ngIf="errcode.code == 1601">
        It is possible you're logged in as a different user than this
        invitation was sent to. Please double check your current log
        in session.
    </div>

    <p *ngIf="errcode.code == 2110">
        The key provided for this link was incorrect. Please double
        check and ensure the key was entered correctly. The key
        is case sensitive. If you need further assistance, please contact
        the person who sent you the link, or feel free to
        <a class="showhand" (click)="openContactSupport()">contact us</a>.
    </p>

    <p *ngIf="errcode.code == 3010">
        Safari and a few older web browsers are not capable of downloading encrypted files. Please try this link again
        using Google Chrome or Firefox. Just looking out for your security.
    </p>

    <p *ngIf="errcode.code >= 2000 && errcode.code <= 2100">
        It appears that the encryption keys for this file are corrupt. Please
        <a class="showhand" (click)="openContactSupport()">contact us</a>.
    </p>

    <p *ngIf="errcode.code == 3050">
        Private browsing prevents us from storing and decrypting the file
        in your browser. Please disable private browsing and try to download
        the file again.
    </p>

    <i class="zmdi zmdi-close overlay-closer" (click)="closeAlert()"></i>

    <!-- <strong class="visible-xs-inline"><br />If you're on a mobile device you may want to try this link on a desktop using one of the web browsers mentioned.</strong> -->
</div>