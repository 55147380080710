import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[syncPreventRightClick]'
})
export class PreventRightClickDirective {

  constructor() { }

  @HostListener('contextmenu', ['$event']) click(event) {
    event.preventDefault();
}

}
