
import { BaseApiOutput } from './base-api.model';

export class UserInfoSetApiInput extends BaseApiOutput {
    billing = undefined;
    passreset = undefined;
    sessionttl: number = undefined;
    display_name_b64: string = undefined;
    is_emailmarketing: number = undefined;
    is_emailproduct: number = undefined;
    is_emailrewards: number = undefined;
    is_disableautorenew: number = undefined;
    rm_billingprofile: number = undefined;
    is_office: number = undefined;
    is_displayedofficeoptin: number = undefined;
    enc_password: string = undefined;
}


export class UserInfoSetApiOutput extends BaseApiOutput {
}
