import { environment } from './../../../../../environments/environment';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../../core/user.service';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../../reducers';
import { Store, select } from '@ngrx/store';
import { BlendEvent, PermissionActions, User, UserInfoSetApiOutput } from '../../../models';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../../../core/logger.service';
import { ZuoraAccountService } from '../../../../account/services/zuora-account.service';
import { zAccountInfo } from '../../../models/api/orderprofile-api.model';
import { Router } from '@angular/router';
import { BlendService } from '../../../services/blend.service';
import { FeatureService } from '../../../services/feature.service';
import { ApiService } from '../../../../core/api.service';


@Component({
    selector: 'sync-header-user',
    templateUrl: './header-user.component.html',
    styleUrls: ['./header-user.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderUserComponent implements OnInit, OnDestroy {
    public pendingShares: number; // user
    public eventCount: number; // user
    public avatarEndpoint = `${environment.logohost}avatar/`;
    public user: User;
    private subscription: Subscription;
    public permissionActions = PermissionActions;
    public zprofile: zAccountInfo;
    public syncCpHost = environment.syncCpHost;
    public isRecentsAllowed: boolean;
    public isFavouriteAllowed: boolean;
    private redirectToNewUI = false;

    constructor(
        private store: Store<fromRoot.State>,
        private userService: UserService,
        private modalService: NgbModal,
        private log: LoggerService,
        private zuora: ZuoraAccountService,
        private router: Router,
        private BlendService: BlendService,
        private api: ApiService,
        public featureService: FeatureService,
    ) { }

    ngOnInit() {
        this.subscription = this.store.pipe(select(fromRoot.getAuthUser))
            .subscribe((data) => {
                this.user = data;
                if (this.redirectToNewUI && this.user.is_newui_enabled) {
                    window.location.href = environment.syncCpHost;
                }
                this.loadProfile();
            });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    public openContactSupport(flag) {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        if (flag == 'get_help') {
            this.BlendService.track(BlendEvent.GET_HELP_EVENT);
        } else {
            this.BlendService.track(BlendEvent.HELP_EVENT);
        }
    }

    public onUsersClick() {
        if ((this.user.is_multi_admin || !this.user.is_multi_admin) &&
            !this.user.is_multi_child &&
            !this.user.is_multi_new
        ) {
            this.log.info('Using old multiuser');
            this.router.navigate(['/multi-user']);
        } else if (
            !this.user.is_multi_admin &&
            this.user.is_multi_child &&
            this.user.is_multi_new
        ) {
            this.log.info('Using new multiadmin');
            if (!this.checkPermission(this.permissionActions.VIEW_USERS) && this.checkPermission(this.permissionActions.VIEW_ROLES)) {
                this.router.navigate(['/multi-user/roles']);
            } else {
                this.router.navigate(['/multi-user/users']);
            }
        }
    }

    public onUpgradeClick() {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        } else {
            this.BlendService.addEventsForUpgradeButton();
            this.router.navigate(['/account/upgrade']);
        }
    }

    public async toggleNewUi() {
        const newui = this.user.is_newui_enabled ? 0 : 1;
        const result = await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
            newui
        });
        if (result.success) {
            const event_name = newui ? BlendEvent.NEW_UI_OPT_IN : BlendEvent.NEW_UI_OPT_OUT;
            this.BlendService.track(event_name, {});
            this.userService.refresh();
            this.redirectToNewUI = true;
        }
    }

    private async loadProfile() {
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.zprofile = await this.zuora.getProfile();
        }
        this.isRecentsAllowed = await this.featureService.isAllowed('recents');
        this.isFavouriteAllowed = await this.featureService.isAllowed('favourite');
    }
}
