import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserService } from '../../core/user.service';

@Injectable({
    providedIn: 'root'
})
export class ResolveIsAdminResolver implements Resolve<any> {

    constructor(
        private userService: UserService
    ) { }

    resolve() {
        const user = this.userService.getUser();
        return user.is_multi_admin;
    }
}
