import { CommentService } from './../../core/comment.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrCode, LinkListItem, User } from '../../shared/models';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as LinkListActions from '../../actions/link-list.actions';
import { LoggerService } from '../../core/logger.service';
import { DispatchService } from '../../core/dispatch.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogLinkComponent } from '../dialog-link/dialog-link.component';
import { LinkListService } from '../services/link-list.service';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/user.service';
@Component({
    selector: 'sync-link-list',
    templateUrl: './link-list.component.html',
    providers: [NgbDropdownConfig]
})
export class LinkListComponent implements OnInit, OnDestroy {

    public errcode: ErrCode;
    public linkData: any;
    public list: LinkListItem[] = [];
    public showInfo: boolean;
    public loading: boolean;
    public user: User;
    public isTeamsPlusUser = false;

    public paginate: sync.IPaginate = {
        total: 0,
        currentPage: 1,
        itemsPerPage: 50,
        numPages: 0,
        viewOffset: 0,
        viewEnd: 0
    };
    private userSub: Subscription;
    private sub: Subscription;
    private predicate: string;
    private predicateReverse: boolean;

    constructor(
        private dispatch: DispatchService,
        private linkList: LinkListService,
        private userService: UserService,
        private log: LoggerService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private commentService: CommentService,
        config: NgbDropdownConfig,
        private router: Router,
        private route: ActivatedRoute
    ) {
        config.placement = 'left-top';
    }

    ngOnInit() {
        this.loading = true;
        this.store.dispatch(new LinkListActions.LinkListRefreshAction());

        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
            this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.userSub = this.store.pipe(select(fromRoot.getAuthUser)).subscribe(data => {
            this.user = data;
        });
        this.sub = this.store.select(fromRoot.getLinkListState).subscribe(async (data) => {
            // console.log('======================');
            // console.log('link list subscription');
            // console.log(data);
            if (data.error) {
                this.log.e('An error occurred loading links', data.error);
                this.errcode = data.error;
            }
            if (data.sorted && data.loaded) {
                this.loading = false;
                this.list = data.items;
                this.paginate.total = this.list.length;
                this.paginate.numPages = Math.ceil(this.list.length / this.paginate.itemsPerPage);
                if (
                    this.route.snapshot.queryParams['page'] &&
                    parseInt(this.route.snapshot.queryParams['page'], 10) <=
                    this.paginate.numPages
                ) {
                    this.paginate.currentPage =
                        parseInt(this.route.snapshot.queryParams['page'], 10) || 1;
                } else {
                    this.paginate.currentPage = 1;
                }

                this.paginate.viewOffset = this.paginate.itemsPerPage * (this.paginate.currentPage - 1);
                this.paginate.viewEnd = this.paginate.itemsPerPage + this.paginate.viewOffset;
                this.getCommentsCount();

                const locData = this.dispatch.getDipatchData();
                if (locData && locData.sync_id) {
                    const item = this.list.filter((val) => {
                        if (val.sync_id == locData.sync_id) {
                            return true;
                        }
                    });
                    if (item.length) {
                        this.showLinkDialog(item[0], 1);
                    }
                    this.dispatch.removeDispatch();
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    public onPageChange(page) {
        this.paginate.currentPage = page;
        this.paginate.viewOffset = this.paginate.itemsPerPage * (page - 1);
        this.paginate.viewEnd = this.paginate.itemsPerPage + this.paginate.viewOffset;
        const pageParam = (this.paginate.currentPage > 1)
            ? this.paginate.currentPage
            : undefined;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: pageParam },
            queryParamsHandling: 'merge'
        });
        this.getCommentsCount();
    }

    private async getCommentsCount() {
        const { viewOffset, itemsPerPage, total } = this.paginate;
        const start = viewOffset;
        const end = Math.min(start + itemsPerPage, total);
        const pageList = this.list.slice(start, end);

        const locIds = pageList.map(item => item.loc_id);

        const countResult = await this.commentService.getCommentCount(locIds);
        for (const item of pageList) {
            item.comment_count = countResult ? (countResult[item.loc_id] || 0) : 0;
        }
    }

    public getPredicateClass(field: string): string {

        let cssClass = 'fa fa-sortx text-muted';
        if (this.predicate && this.predicate.indexOf(field) > -1 && this.predicateReverse) {
            cssClass = 'fa fa-sort-down';
        } else if (this.predicate && this.predicate.indexOf(field) > -1 && !this.predicateReverse) {
            cssClass = 'fa fa-sort-up';
        }
        return cssClass;
    }

    public setPredicate(field: string): void {
        if (this.predicate == 'name' && this.predicateReverse === true) {
            this.predicate = 'default';
            this.predicateReverse = true;
        } else {
            this.predicate = field;
            this.predicateReverse = !this.predicateReverse;
        }
        const sortData = {
            direction: (this.predicateReverse) ? 'desc' : 'asc',
            active: this.predicate
        };
        this.store.dispatch(new LinkListActions.LinkListSortAction(sortData));
    }

    public async loadLink(link: LinkListItem) {
        link.spinner = true;
        const item = await this.linkList.getLink(link.sync_id, link.name);
        link.spinner = false;
        window.location.href = item.linkUrl;
    }

    public showLinkDialog(item: LinkListItem, openState: number) {
        const ref = this.modalService.open(DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;

        ref.result.then((result) => {
            if (result === true) {
                this.store.dispatch(new LinkListActions.LinkListRefreshAction());
            }
        });
    }
}
