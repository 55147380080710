import { Component, Inject, Input, OnInit } from '@angular/core';
import { DirPickerService } from '../../file-list/dir-picker.service';

@Component({
    selector: 'sync-dir-picker',
    templateUrl: './dir-picker.component.html',
})
export class DirPickerComponent implements OnInit {
    public pickerView: sync.IDirPickerView;
    @Input() public skipShareFolders: any;
    @Input() public headerTitle: string;

    constructor(
        private dirPickerService: DirPickerService
    ) {}

    ngOnInit() {
        this.pickerView = this.dirPickerService.view;
        const mode = this.skipShareFolders
            ? this.dirPickerService.INIT_SKIPSHARE
            : this.dirPickerService.INIT_ALL;
        this.dirPickerService.init(mode);
    }

    public cd(item: sync.IFile) {
        return this.dirPickerService.cd(item);
    }
    public select(item: sync.IFile) {
        return this.dirPickerService.select(item);
    }
    public selectByName(name: string) {
        return this.dirPickerService.selectByName(name);
    }
    public isSelectedByName(name: string) {
        return this.dirPickerService.isSelectedByName(name);
    }
    public isSelected(item: sync.IFile) {
        return this.dirPickerService.isSelected(item);
    }
    public getDestination() {
        return this.dirPickerService.getDestination();
    }

    public openRoot(name: string) {
        console.log(name);
        this.dirPickerService.openRoot(name);
    }
    public cdUp() {
        if (this.pickerView.cwd.pid) {
            this.cd(<sync.IFile>{ sync_id: this.pickerView.cwd.pid });
        } else {
            const mode = this.skipShareFolders
                ? this.dirPickerService.INIT_SKIPSHARE
                : this.dirPickerService.INIT_ALL;
            this.dirPickerService.init(mode);
        }
    }
}
