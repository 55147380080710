import { Component, OnInit, Input } from '@angular/core';
import { Device } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-device-delete',
    templateUrl: './dialog-device-delete.component.html'
})
export class DialogDeviceDeleteComponent implements OnInit {

    @Input() device: Device;

    public confirm;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService
    ) { }

    ngOnInit() {
    }

    public async delete() {
        if (!this.confirm) {
            return;
        }
        await this.api.execute('devicedelete', {
            device_id: this.device.id
        });
        this.activeModal.close(true);
    }
}
