<sync-error [errcode]="errcode"></sync-error>
<div class="alert alert-info" *ngIf="!success">
    Please verify your account.
    <br />
    <button
        class="btn btn-primary"
        [disabled]="spinner"
        (click)="resendVerification()">
        <i class="fa fa-spinner fa-spin" *ngIf="spinner"></i>
        Re-send verification
    </button>
</div>
<div class="alert alert-success" *ngIf="success">
    You should receive an email at {{userEmail}} soon.
</div>