import { BaseApiOutput, BaseApiInput } from './base-api.model';
import { Device } from '../device.model';

export class DeviceListApiOutput extends BaseApiOutput {
    devices: Device[];

}

export class DeviceListApiInput extends BaseApiInput {
}


