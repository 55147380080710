import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportTicketService } from '../../account/services/support-ticket.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
    selector: 'sync-dialog-report-link',
    templateUrl: './dialog-report-link.component.html'
})
export class DialogReportLinkComponent implements OnInit {

    @Input() linkID: string;

    public copyrightForm: FormGroup;
    public reportForm: FormGroup;
    public frmValues: any = {
        requester: '',
        name: '',
        subject: null,
        description: null
    };
    public subject: string;
    isCopyright(): boolean {
        return (this.val('subject').indexOf('Infringes') > -1);
    }
    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private ticket: SupportTicketService

    ) { }

    ngOnInit() {
        this.reportForm = this.fb.group({
            name: ['', Validators.required],
            subject: ['', Validators.required],
            email: ['', Validators.required],
            digitalsignature: ['', Validators.required],
            description: ['', Validators.required],
            copyright: this.fb.group({
                jobtitle: [''],
                behalfof: [''],
                address: [''],
                city: [''],
                province: [''],
                postalcode: [''],
                country: [''],
                phone: [''],
                fax: [''],
                email: [''],
                name: [''],
                dmca1: [''],
                dmca2: [''],
                dmca3: [''],
            })
        });
    }
    private val(field) {
        console.log('val = ' + field);
        return this.reportForm.get(field).value;
    }
    onSubmit() {
        let fullDetails = '';
        if (this.isCopyright()) {
            fullDetails = [
                'Job title: ' + this.val('copyright.jobtitle'),
                'Reporting on behalf of: ' + this.val('copyright.behalfof'),
                '----',
                this.val('copyright.address'),
                this.val('copyright.city')
                    + ' ' + this.val('copyright.province')
                    + ' ' + this.val('copyright.postalcode'),
                this.val('copyright.country'),
                '----',
                'Phone: ' + this.val('copyright.phone'),
                'Fax: ' + this.val('copyright.fax'),
                'Email: ' + this.val('copyright.email'),
                '----',
                'Checked: I hereby state that I have a good faith belief that the sharing of copyrighted material at the location above is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).',
                'Checked: I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.',
                'Checked: I acknowledge that under Section 512(f) any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability for damages.',
            ].join('\n\n');
        }
        const description = [
            'Link: ' + window.location.href,
            'Subject: ' + this.val('subject'),
            '',
            'Full name: ' + this.val('name'),
            'Email: ' + this.val('email'),
            fullDetails,
            '',
            this.val('description'),
            '',
            'Digital Signature included: ' + this.val('digitalsignature')
        ].join('\n\n');
        const postData = {
            z_requester: 'abuse@sync.com',
            z_name: 'Sync Abuse',
            z_subject: 'ABUSE for public link' + this.val('subject'),
            z_description: description
        };
        this.ticket.sendSupportTicket(postData);
    }
}
