import { Directive, HostListener } from '@angular/core';
import { BroadcastService } from '../services/broadcast.service';

@Directive({
  selector: '[syncHideTools]'
})
export class HideToolsDirective {

  constructor(
    private broadcastService: BroadcastService
  ) { }

  @HostListener('click', ['$event']) click(event) {
    this.broadcastService.broadcast('sync.hide.tools');
    this.broadcastService.broadcast('event:hide.tools');
  }
}
