import { Injectable } from '@angular/core';
import { LoggerService } from '../core/logger.service';
import { FileListService } from './services/file-list.service';
import { UserService } from '../core/user.service';
import { PathListApiOutput, ErrCode } from '../shared/models';

@Injectable({
    providedIn: 'root',
})
export class DirPickerService {
    private rootSyncId: number;
    private rootVaultId: number;
    private destinationId: number;
    private skipShareFolders: boolean;

    public view: sync.IDirPickerView = {
        dirlist: [],
        skipSyncIds: [],
        selected: undefined,
        cwd: undefined,
        root: 'sync',
    };
    public INIT_SKIPSHARE = 1;
    public INIT_ALL = 0;

    constructor(
        private loggerService: LoggerService,
        private FileList: FileListService,
        private User: UserService
    ) {
        this.rootSyncId = User.get('syncus_sync_id');
        this.rootVaultId = User.get('web_sync_id');
    }

    public init(mode: number) {
        this.skipShareFolders = mode == this.INIT_SKIPSHARE;
        this.view.dirlist = [];
        this.view.cwd = undefined;
        this.view.selected = undefined;
        this.view.root = 'sync';
        this.destinationId = 0;
    }

    public async cd(
        item: sync.IFile | sync.IDirSelectFile
    ): Promise<sync.IFile | sync.IDirSelectFile> {
        try {
            item.spinner = true;
            await this.getData(item.sync_id);
            if (!this.isSelected(item)) {
                this.select(item);
            }
            item.spinner = false;
            return item;
        } catch (err) {
            this.loggerService.error('Error changing directory for cd');
            throw new Error(err);
        }
    }
    public inRoot(name: string): boolean {
        return this.view.root == name;
    }
    public isSelected(item: sync.IFile | sync.IDirSelectFile) {
        if (!this.view.selected) {
            return false;
        }
        return this.view.selected && item.sync_id == this.view.selected.sync_id;
    }
    public isSelectedByName(name: string): boolean {
        const item = this.translateRootToItem(name);
        return this.isSelected(item);
    }
    public select(item: sync.IFile | sync.IDirSelectFile) {
        this.view.selected = item;
    }
    public selectByName(name: string) {
        const item = this.translateRootToItem(name);
        return this.select(item);
    }

    public getDestination(): string {
        let dest: string;
        if (this.view.selected && this.view.selected.name) {
            dest = this.view.selected.name;
            this.destinationId = this.view.selected.sync_id;
        } else if (this.view.cwd && this.view.cwd.sync_id == this.rootSyncId) {
            dest = 'Sync Folder';
            this.destinationId = this.rootSyncId;
        } else if (this.view.cwd && this.view.cwd.sync_id == this.rootVaultId) {
            dest = 'Vault';
            this.destinationId = this.rootVaultId;
        } else {
            this.destinationId = this.rootSyncId;
            dest = 'Sync Folder';
        }
        return dest;
    }

    public nameExists(name: string) {
        for (let i = 0, len = this.view.dirlist.length; i < len; i++) {
            if (
                this.view.dirlist[i].name &&
                this.view.dirlist[i].name.toLowerCase() == name.toLowerCase()
            ) {
                return true;
            }
        }
        return false;
    }

    public async getData(syncId?: number) {
        if (!syncId) {
            syncId = this.rootSyncId;
        }
        try {
            const data = await this.FileList.getDataNoState(syncId, '0');
            const pathlist = this.processList(data);
            this.view.dirlist = <sync.IFile[]>pathlist;
            return;
        } catch (ex) {
            throw ErrCode.fromException(ex);
        }
    }

    public exists<T>(key: string, value: T): boolean {
        for (let i = 0, len = this.view.dirlist.length; i < len; i++) {
            const item = this.view.dirlist[i];
            if (
                key == 'name' &&
                typeof value == 'string' &&
                item[key].toLowerCase() == value.toLowerCase() &&
                !item.is_deleted
            ) {
                return true;
            }
            if (item[key] === value && !item.is_deleted) {
                return true;
            }
        }
        return false;
    }

    public openRoot(name: string) {
        this.view.root = name;
        this.cd(this.translateRootToItem(name));
    }

    private processList(data: PathListApiOutput): sync.IFile[] {
        const dirs: Array<sync.IFile> = data.pathlist.filter(
            (item: sync.IFile, idx: number) => {
                return (
                    (!this.skipShareFolders ||
                        (this.skipShareFolders && !item.is_shared)) &&
                    item.type == 'dir' &&
                    this.view.skipSyncIds.indexOf(item.sync_id) === -1
                );
            }
        );
        if (data.cwd.sync_id == this.rootSyncId) {
            data.cwd.name = 'Sync Folder';
        } else if (data.cwd.sync_id == this.rootVaultId) {
            data.cwd.name = 'Vault';
        }
        this.view.cwd = data.cwd;
        return dirs;
    }
    private translateRootToItem(name: string): sync.IDirSelectFile {
        let item: sync.IDirSelectFile;
        switch (name) {
            case 'sync':
                item = { name: 'Sync Folder', sync_id: this.rootSyncId };
                break;
            case 'vault':
                item = { name: 'Vault', sync_id: this.rootVaultId };
                break;
            default:
                this.loggerService.error(
                    'An unknown root was provided to DirPicker to translate ' +
                        name
                );
        }
        return item;
    }
}
