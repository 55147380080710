import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogContactSupportComponent } from '../../account/dialog-contact-support/dialog-contact-support.component';
import { LoggerService } from '../../core/logger.service';
import { ErrCode } from '../../shared/models';

@Component({
  selector: 'sync-preview-error',
  templateUrl: './preview-error.component.html'
})
export class PreviewErrorComponent implements OnInit {

  @Input() public errcode: ErrCode; // binding from component
  @Input() public syncId: number;
  @Input() public item: sync.IFile;

  constructor(
    private modalService: NgbModal,
    private loggerService: LoggerService
  ) { }

  public openContactSupport() {
    const ref = this.modalService.open(DialogContactSupportComponent, {
        backdropClass: 'in',
        windowClass: 'in',
        backdrop: 'static',
    });
}
  ngOnInit() {
    this.loggerService.error([
      'Preview encountered an error, location:',
      this.syncId,
      this.errcode.code,
      this.errcode.msg
  ].join(' '));
  }

}
