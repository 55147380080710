import { DirtyOutKeyItemDataKey } from './dirty-out-key-item.model';



export class DirtyOutTask {
    action: string;
    sync_id: number;
    sharetype: number;
    share_id: number;
    share_sequence: number;

    share_key?: string;
    enc_share_name?: string;

    blob_id: number;
    blob_ids: number[];

    keys: DirtyOutKeyItemDataKey[];
}

export class DirtyOutTaskResult {
    action: 'encdatakey' | 'encnameshare';
    sync_id: number;
    share_id: number;
    share_sequence: number;
}

export class DirtyOutTaskDataKeyResult extends DirtyOutTaskResult {
    public blob_id;
    public enc_data_key: string;
}

export class DirtyOutTaskShareNameResult extends DirtyOutTaskResult {
    public enc_share_name;
}


