import { BaseApiOutput } from './base-api.model';
import { DirtyOutEncResultShare, DirtyOutEncResultSync } from '../dirty-out-enc-result.model';
export class KeysSetEncApiOutput extends BaseApiOutput {
    completed: number;
}

export class KeysSetEncApiInput {
    items: DirtyOutEncResultShare[] | DirtyOutEncResultSync[];
}




