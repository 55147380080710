import {
    Directive,
    ElementRef,
    HostListener,
    Inject,
    OnInit,
    Renderer2,
} from '@angular/core';
import { first } from 'rxjs/operators';
import { BrowserSupportService } from '../../core/browser-support.service';
import { LoggerService } from '../../core/logger.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { TransferService } from '../../transfer/transfer.service';
import { UploadFolderService } from '../../transfer/upload-folder.service';
import { BlendService } from '../services/blend.service';
import { BlendEvent } from '../models';
import { FileItemService } from '../../file/file-item.service';

/**
 * @description
 * Binds the drag and drop events for a given element and when a file is
 * dragged on to this element, it will trigger a file upload.
 *
 * The drop event filters out directories.
 */

@Directive({
    selector: '[syncUploadDropBox]',
})
export class UploadDropBoxDirective implements OnInit {
    constructor(
        private browserSupportService: BrowserSupportService,
        private loggerService: LoggerService,
        private fileListService: FileListService,
        private transferService: TransferService,
        private renderer: Renderer2,
        private element: ElementRef,
        private uploadFolderService: UploadFolderService,
        private blendService: BlendService,
        private fileItemService: FileItemService
    ) {}

    ngOnInit() {
        if (!this.browserSupportService.testFileUpload()) {
            this.loggerService.info('Browser is unable to upload files');
            return;
        }
    }

    @HostListener('dragstart', ['$event']) onDragStart(evt: DragEvent) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'all';
    }

    @HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'all';
        this.renderer.addClass(this.element.nativeElement, 'fileupload-drop-over');
    }

    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
        evt.dataTransfer.effectAllowed = 'all';
        this.renderer.addClass(this.element.nativeElement, 'fileupload-drop-over');
        return false;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'none';
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
    }

    @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
        evt.preventDefault();
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
        const list = evt.dataTransfer.items;
        if (list && list.length > 0) {
            if (this.browserSupportService.testDirUpload()) {
                let hasDirs = false;
                const items: Entry[] = [];
                for (let i = 0, len = list.length; i < len; i++) {
                    const item: Entry = list[i].webkitGetAsEntry();
                    if (item) {
                        items.push(item);
                        if (!hasDirs && item.isDirectory) {
                            hasDirs = true;
                        }
                    }
                }
                if (hasDirs) {
                    this.loggerService.info('Found directories for upload');
                    this.fileListService
                        .getCwdSubscription()
                        .pipe(first())
                        .subscribe((data: any) => {
                            if (data) {
                                this.uploadFolderService.run(data, items);
                            } else {
                                this.loggerService.warn(
                                    'No cwd found in upload'
                                );
                                this.loggerService.warn(data);
                            }
                        });
                } else {
                    this.transferService.queueUpload(evt.dataTransfer.files, true);
                }
            } else {
                this.transferService.queueUpload(evt.dataTransfer.files, true);
            }
        } else if (evt.dataTransfer.files.length) {
            this.transferService.queueUpload(evt.dataTransfer.files, true);
        } else {
            this.loggerService.error('No files were dropped for upload');
        }
    }

    @HostListener('dragend', ['$event']) onDragEnd(evt: DragEvent) {
        evt.preventDefault();
        evt.dataTransfer.effectAllowed = 'none';
        evt.dataTransfer.dropEffect = 'none';
        this.renderer.removeClass(this.element.nativeElement, 'fileupload-drop-over');
    }
}
