import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ShareNewService } from '../share-new.service';
import { ShareService } from '../share.service';

@Component({
  selector: 'sync-sharemember-invite',
  templateUrl: './sharemember-invite.component.html'
})
export class SharememberInviteComponent implements OnInit {

  @Input() public shareData: sync.IShareData;
  @Input() public openState: number;
  @Input() public modalInstance: NgbModalRef;
  public errors: Array<sync.IErrorCode> = [];
  public spinner: boolean;
  public invitedEmails: string;
  public sharememberInvite: sync.ISharememberInviteData = {
      permissions: {
          perDOWNLOAD: 1,
          perUPLOAD: 1,
          perINVITE: 0,
          perSEEOTHERS: 1,
      },
      displayName: '',
      queue: [],
      roleQueue: [],
      roleName: '',
      privateMessage: '',
  };

  public isPro = false;
  public isVerified = false;

  constructor(
      private userService: UserService,
      private base64Service: Base64Service,
      private loggerService: LoggerService,
      private shareService: ShareService,
      private shareNewService: ShareNewService
  ) {}

  ngOnInit() {
      this.isVerified = this.userService.get('is_verified');
      this.isPro = this.userService.get('is_pro');
      this.sharememberInvite.displayName = this.base64Service.decode(
          this.userService.get('display_name')
      );

      if (!this.shareData) {
          this.loggerService.error(
              'Unable to find shareData for sharemember-list'
          );
          this.loggerService.error(JSON.stringify(this.shareData));
          this.errors.push({
              code: 1000,
              msg: 'No share member information provided',
          });
      }
  }

  public isValidInvite(): boolean {
      return (
          this.sharememberInvite.displayName &&
          (this.sharememberInvite.queue.length > 0 || this.sharememberInvite.roleQueue.length > 0)
      );
  }

  public async sendInvites() {
      try {
          this.spinner = true;
          this.errors = [];
          this.invitedEmails = '';
          await this.shareNewService.addMember(
              this.shareData,
              this.sharememberInvite
          );
          this.spinner = false;
          this.loggerService.info('Finished adding share member');
          this.invitedEmails = this.sharememberInvite.queue.join(', ');
          if (this.invitedEmails && this.sharememberInvite.roleName) {
            this.invitedEmails += `, ${this.sharememberInvite.roleName}`;
          } else if (!this.invitedEmails && this.sharememberInvite.roleName) {
            this.invitedEmails = this.sharememberInvite.roleName;
          }
          this.invitedEmails = this.invitedEmails.replace(/,(?=[^,]+$)/, ' and');
          this.sharememberInvite.queue = [];
          this.sharememberInvite.roleQueue = [];
          this.sharememberInvite.roleName = '';
          const shareData = await this.shareService.getShare(
              this.shareData.shareId
          );
          this.shareData = shareData;
      } catch (err) {
          this.errors.push(err);
      }
  }
}
