
import { Deserializable } from './deserializable.model';

export class ShareListMember implements Deserializable<ShareListMember> {
    uniqid: number;
    userid: number;
    email: string;
    displayname: string;
    status: string;
    pubkey_id: number;
    pubkey: string;
    share_id: number;
    permissions: string[];


    public deserialize(input): ShareListMember {
        Object.assign(this, input);
        return this;
    }

}
