export const TransferStatus = {
    STATUS_WAITING: 0,
    STATUS_WORKING: 10,
    STATUS_ABORT: 5,
    STATUS_SUCCESS: 1,

    STATUS_UPLOAD: 11,         // Data is being uploaded
    STATUS_ENC_UPLOAD: 15,     // encrypting data pre-upload
    STATUS_UPLOAD_CANCELLED: 1001,

    STATUS_DOWNLOAD: 12,         // Data is being downloaded.
    STATUS_DECRYPT_DOWNLOAD: 16, // Data downloaded is decrypting
    STATUS_PREPAREDOWNLOAD: 18,  // indexeddb is prearing dl

    STATUS_ERROR: 3000,          // an error occurred
    STATUS_ERR_SIZE: 3020,       // the file is too big
    STATUS_ERR_OPEN: 3010,       // the file cannot be opened
    STATUS_ERR_NAME: 3500,       // illegal file name
    STATUS_ERR_SHARE_KEY: 2000,  // no share key
    STATUS_ERR_DATA_KEY: 2010,   // missing data key
};

export enum TransferQueueType {
    UPLOAD = 'upload',
    DOWNLOAD = 'download'
}

export const ValidUploadStatus = [
    TransferStatus.STATUS_WORKING,
    TransferStatus.STATUS_UPLOAD,
    TransferStatus.STATUS_ENC_UPLOAD
];

export const ValidDownloadStatus = [
    TransferStatus.STATUS_WORKING,
    TransferStatus.STATUS_DOWNLOAD,
    TransferStatus.STATUS_DECRYPT_DOWNLOAD,
    TransferStatus.STATUS_PREPAREDOWNLOAD
];
