import { Deserializable } from './deserializable.model';

export class User implements Deserializable<User> {


    // every variable must be initialized with a default value
    // or the constructor will not assign the value
    exists = 0;
    id = 0;
    uid = 0;
    team_id = 0;
    plan_id = 0;
    plan_name = 'Sync.com Free';
    account_type = 'Sync Starter';
    account_duedate = 0;
    renewal_plan_id = 0;
    getstarted_completed = 0;
    display_name = '';
    email = '';
    first_name = '';
    last_name = '';
    password_hint = '';
    join_date = 0;

    is_office = 0; // Uses MS Office Integration (wopi)
    is_displayedofficeoptin = 0; // Uses MS Office Integration (wopi)

    has_apps = 0; // Has installed an app at some point
    app_count = 0; // Currently active apps

    is_office_personal = 0;
    can_preview = 0;

    new_feature = 0;

    is_verified = 0;
    is_pro = 0;
    is_multi_admin = false;
    is_multi_child = false;
    is_multi_new = false;
    is_on_trial = false;
    is_trial_used = false;
    permissions: Record<string, boolean | number> = {};
    is_resetpassword = 0;
    is_disablesso = 0;

    is_disablepurge = 0;
    is_disableautorenew = 0;
    is_emailmarketing = 1;
    is_emailproduct = 1;
    is_emailrewards = 1;

    is_unlimited_plan = 0;
    is_disableanalytics = 0;
    is_newui_enabled = 0;

    disk_limit_bytes = 0;
    disk_usage_bytes = 0;
    disk_usage_percent = 0;

    web_device_id = 0;

    syncus_sync_id = 0;
    web_sync_id = 0;

    install_mobile = 0;
    install_desktop = 0;

    pubkey_id = 0;
    pubkey = '';
    sessionttl = 0;
    login_servtime = 0;

    referral_code = '';
    has_2fa = 0;
    is_google_app_2fa_disabled = 0;
    has_files = 0;
    has_gettingstarted = 0;
    has_links = 0;
    has_password_hint = 0;
    has_shares = 0;
    has_whitelabel = 0;
    has_beta_access = 0;
    has_comment = 0;

    twofa_type = '0';

    backup_code_count = 0;

    zuora_acct_key = '';
    sku = '';
    plan_sku = '';
    plan_features: string[] = [];
    plan_product_id = '';
    plan_rate_plan_id = '';
    plan_rate_plan_charge_id = '';
    plantype_id = 1; // default plan type 1 = free
    plan_user_limit = 1; // default of 1 user limit means non-multi admin

    //whitelabel attributes
    public whitelabel = {
        image_data: '',
        image_cachekey: '',
        header_primary_color: '',
        header_text_color: '',
        button_primary_color: '',
        button_text_color: '',
        link_text_color: '',
    };

    public avatar = {
        avatar_data: '',
        avatar_cachekey: '',
    };


    public getstarted = {
        hasVerified: 0,
        hasPasswordHint: 0,
        hasMobile: 0,
        hasDeviceOne: 0,
        hasDeviceMany: false,
        hasFiles: 0,
        hasShares: 0,
        hasLinks: 0,
    };

    constructor(params?: { [k: string]: any }) {
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        this.account_type = (params.plan_id > 1 || params.plan_product_id) ? 'Sync Pro' : 'Sync Starter';

        // handle when 2fa type is set to null, change it to 0 string.
        if (params.twofa_type === undefined || params.twofa_type == null) {
            this.twofa_type = '0';
        }

        if (params.zuora_acct_key) {
            this.is_multi_child = (params.is_multi_child === 1);
            this.is_multi_admin = (params.is_multi_admin === 1);
        } else {
            if (params.plan_id === 1001
                || params.plan_id === 1101
                || params.plan_id === 1201
                || params.plan_id === 1301
                || params.plan_id === 1401
                || params.plan_id === 1501
            ) {
                this.is_multi_child = true;
            } else if (params.plan_id > 1000) {
                this.is_multi_admin = true;
            }
        }

        //build the whitelabel data
        this.whitelabel.image_data = params.image_data;
        this.whitelabel.image_cachekey = params.image_cachekey;
        this.whitelabel.header_primary_color = params.header_primary_color;
        this.whitelabel.header_text_color = params.header_text_color;
        this.whitelabel.button_primary_color = params.button_primary_color;
        this.whitelabel.button_text_color = params.button_text_color;
        this.whitelabel.link_text_color = params.link_text_color;

        //build avatar data
        this.avatar.avatar_data = params.avatar_data;
        this.avatar.avatar_cachekey = params.avatar_cachekey;


        // build the getstarted data
        this.getstarted.hasVerified = params.is_verified;
        this.getstarted.hasPasswordHint = params.has_password_hint;
        this.getstarted.hasMobile = params.install_mobile;
        this.getstarted.hasDeviceOne = params.install_desktop;
        this.getstarted.hasDeviceMany = (params.install_desktop > 1);
        this.getstarted.hasFiles = params.has_files;
        this.getstarted.hasShares = params.has_shares;
        this.getstarted.hasLinks = params.has_links;
        let cnt = 0;
        for (const key in this.getstarted) {
            if (this.getstarted.hasOwnProperty(key) && this.getstarted[key]) {
                cnt++;
            }
        }

        this.disk_usage_percent = Math.ceil(this.disk_usage_bytes / this.disk_limit_bytes * 100);
        this.getstarted_completed = cnt;

        this.new_feature = this.newFeature();

        return this;
    }

    private newFeature(): number {
        // console.log('New Feature Estimation:');
        // console.log(`UserID: ${this.id}`);

        // adding null check to prevent browser caching errors.
        if (window.SYNCCFG && window.SYNCCFG.newfeature) {


            // NOTE: This is an optional setting that will seed different number to offset predictable behavior.
            // Though for Office/Wopi we want predictable behavior.
            const use_random_seed = false;
            // NOTE: This is an optional setting that means all new users automatically have the feature.
            const new_users_get_feature = true;

            const date_release = new Date(window.SYNCCFG.newfeature.date);
            // console.log(`Release Date: ${date_release.getTime()}`);
            // console.log(`User joined on: ${this.join_date}`);


            const date_now = Date.now();
            const days_since_release = Math.floor((date_now.valueOf() - date_release.valueOf()) / (1000 * 60 * 60 * 24));
            // console.log(`Release Date Human: ${date_release}`);
            // console.log(`Release Date Epoch: ${date_release.valueOf() / 1000}`);
            // console.log(`Days since release: ${days_since_release}`);


            // User_id aren't distributed linearly due to sharding. Ids greater than 10 million should be truncated.
            const unique_id = this.id > 10000000 ? Math.floor(this.id / 1000) : this.id;
            // This ensure that user_id xxx0 doesn't always get the new feature first.
            // Every release will have a pseudo random seed from 0 to 100,
            // i.e. A release on Feb 1 2020 00:00:00 = 33
            // This means that user id's ending with 33 get the feature first.
            const random_seed = use_random_seed ? Math.floor(date_release.valueOf() / 1000 / 1000 % 100) : 0;
            // console.log(`Random seed: ${random_seed}`);
            const seed_id = unique_id - random_seed;
            // console.log(`Seed Id: ${seed_id}`);

            // Percentage of users that should have the feature, per day
            const release_rate = window.SYNCCFG.newfeature.rate;
            const percentage_with_feature =
                days_since_release < 0
                    ? 0
                    : days_since_release >= release_rate.length
                        ? 100
                        : release_rate[days_since_release];
            // console.log(`Feature Penetration: ${percentage_with_feature}`);


            // console.log(`User Email: ${this.email}`);
            const domains_dev = ['syncus.com', 'sync.us', 'sync.com']; // Allows the feature indefiniteily before release
            const domains_early = []; // Allows the feature 2 weeks before release.


            if (domains_dev.some((allowed_domain) => this.email.includes('@' + allowed_domain))) {
                // console.log(`Email matches allow list. Feature enabled for DEV.`);
                return (1);
            } else if (
                domains_early.some((allowed_domain) => this.email.includes('@' + allowed_domain))
                &&
                days_since_release > -14
            ) {
                // console.log(`Email matches allow list. Feature enabled early.`);
                return (1);
            } else if (
                new_users_get_feature
                &&
                this.join_date > date_release.getTime()
            ) {
                // console.log(`This user has joined after the release date. Feature automatically enabled.`);
                return (1);
            } else {
                // console.log(`Email doesn't match allow list. They're a part of the staggered release.`);
                const new_feature = seed_id % (100 / percentage_with_feature) === 0 ? 1 : 0;
                // console.log(`User has feature?: ${new_feature}`);
                return (new_feature);
            }
        } else {
            return 0;
        }
    }

}

