import { ActionReducer, Action } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

import { ErrCode, User } from '../shared/models';
export interface State {
    user: User;
    authenticated: boolean;
    error: ErrCode;
    progress: number;
    twofarequired: boolean;
}

export const initialState: State = {
    user: undefined,
    authenticated: false,
    error: undefined,
    progress: 0,
    twofarequired: false,
};

export function reducer(state = initialState, action: AuthActions.All) {
    switch (action.type) {
        case AuthActions.AUTH_LOGIN_SSO:
        case AuthActions.AUTH_LOGIN:
            return Object.assign({}, state, { error: undefined });
        case AuthActions.AUTH_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                user: action.payload,
                authenticated: true,
                error: undefined,
                progress: 0,
            });
        case AuthActions.AUTH_LOGIN_FAILURE:
            if (action.payload && action.payload.code === 8022) {
                return Object.assign({}, state, {
                    twofarequired: true,
                    progress: 0,
                });
            } else {
                return Object.assign({}, state, {
                    error: action.payload,
                    progress: 0,
                });
            }

        // case AuthActions.AUTH_REFRESH:
        //     console.log('Action type refresh');
        //     console.log(action.payload);
        //     break;
        case AuthActions.AUTH_UPDATE_USER:
            return Object.assign({}, state, {
                user: action.payload,
            });
        case AuthActions.AUTH_LOGIN_TWOFACTOR:
            return Object.assign({}, state, { twofarequired: true });
        case AuthActions.AUTH_LOGOUT:
            return initialState;
        case AuthActions.AUTH_LOGIN_PROGRESS:
            return Object.assign({}, state, { progress: action.payload });
        default:
            return state;
    }
}

export const getAuthenticated = (state: State) => state.authenticated;

export const getUser = (state: State) => state.user;

export const getLoginProgress = (state: State) => state.progress;
export const getErrCode = (state: State) => state.error;
