import { Injectable } from '@angular/core';
import * as asmCrypto from 'asmcrypto.js';
import { LoggerService } from '../logger.service';
import { ErrCode } from '../../shared/models';
import { CryptMethodService } from '../../shared/models/crypt-method-service.model';

// seed the crypto as soon as it's imported
seedCrypto();
function seedCrypto() {
  let seed = false;
    function randString() {
        const text = [];
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 128; i++) {
          text.push(possible.charAt(Math.floor(Math.random() * possible.length)));
      }
      return text.join('');
  }
  //start up the random number seeding
    const crypto = window.crypto || window.msCrypto;
    let buffer, ArrayView;
  try {
      if (Uint8Array) {
          asmCrypto.random.skipSystemRNGWarning = true;
          while (!seed) {
              buffer = new Int32Array(2);
              buffer[0] = Date.now();
              buffer[1] = Math.random();
              // buffer[2] = $window.performance.now();
              seed = asmCrypto.random.seed(buffer);
              const ab = new Uint8Array(96);
              seed = asmCrypto.random.seed(window.crypto.getRandomValues(ab));
              seed = asmCrypto.random.seed(asmCrypto.string_to_bytes(randString()));
              seed = asmCrypto.random.seed(asmCrypto.string_to_bytes(randString()));
          }
          asmCrypto.random.skipSystemRNGWarning = false;
          ArrayView = new Uint32Array(32);
          crypto.getRandomValues(ArrayView);
      }
  // asmCrypto.random.getValues.skipSystemRNGWarning = true;
  } catch (ex) {
  }
}

/* tslint:disable:no-bitwise */
@Injectable({
    providedIn: 'root'
})
export class CryptBufferService implements CryptMethodService {

    constructor(
        private Logger: LoggerService
    ) {
        let seed = false;
        asmCrypto.random.skipSystemRNGWarning = true;
        while (!seed) {
            if (window.crypto && window.crypto.getRandomValues) {
                const ab = new Uint8Array(96);
                seed = asmCrypto.random.seed(window.crypto.getRandomValues(ab));
            } else {
                const buffer = new Int32Array(2);
                buffer[0] = Date.now();
                buffer[1] = Math.random();
                seed = asmCrypto.random.seed(buffer);
            }
        }
        asmCrypto.random.skipSystemRNGWarning = false;
    }

    public bytesToB64(bits: Uint8Array): string {
        return asmCrypto.bytes_to_base64(bits);
    }
    public bytesToString(bits: Uint8Array): string {
        return asmCrypto.bytes_to_string(bits);
    }
    public bytesToHex(bits: Uint8Array): string {
        return asmCrypto.bytes_to_hex(bits);
    }

    public hexToBytes(hex: string): Uint8Array {
        return asmCrypto.hex_to_bytes(hex);
    }
    public stringToBytes(str: string): Uint8Array {
        return asmCrypto.string_to_bytes(str);
    }
    public b64ToBytes(b64: string): Uint8Array {
        return asmCrypto.base64_to_bytes(b64);
    }

    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} plain  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    public symmetricEncrypt(key: Uint8Array, plain: Uint8Array, iv: Uint8Array, header?: Uint8Array): Promise<Uint8Array> {
         try {
            if (!header) {
                header = new Uint8Array(0);
            }
            const data = asmCrypto.AES_GCM.encrypt(plain, key, iv, header, 12);
            const len = header.length + iv.length + data.length;
            const result = new Uint8Array(len);
            result.set(header, 0);
            result.set(iv, header.length);
            result.set(data, header.length + iv.length);
            return Promise.resolve(result);
        } catch (ex) {
            this.Logger.error(ex.toString());
            this.Logger.error('SyncCryptBuffer.symmetricEncrypt() failed');
            throw new ErrCode(2501);
        }
    }

    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} crypted  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    public symmetricDecrypt(key: Uint8Array, crypted: Uint8Array, iv: Uint8Array, header?: Uint8Array): Promise<Uint8Array> {
         try {
            if (!header) {
                header = new Uint8Array(0);
            }
            return Promise.resolve(asmCrypto.AES_GCM.decrypt(crypted, key, iv, header, 12));
        } catch (ex) {
            this.Logger.error('SyncCryptBuffer.symmetricDecrypt() failed');
            this.Logger.error(ex.toString());
            throw new ErrCode(2502);
        }
    }


    /**
     * Decrypts data using a string password that is keystretched
     * @param  {String} b64_crypted A num-prefixed b64 string
     * @param  {String|Uint8Array} password    The AES key
     * @param  {Integer} iterations  iterations for keystretch
     * @return {Promise}        [description]
     */
    public async passwordDecrypt(b64_crypted: string, password: string, iterations: number): Promise<string> {
        if (b64_crypted.substring(0, 3) === '30:') {
            const raw = asmCrypto.base64_to_bytes(b64_crypted.substring(3)),
                salt = this.getPartialBytes(raw, 0, 96),
                iv = this.getPartialBytes(raw, 96, 192),
                rawbytes = this.getPartialBytes(raw, 192);

            const key = await this.keyStretch(password, salt, iterations, 256);
            const decBytes = await this.symmetricDecrypt(key, rawbytes, iv);
            return <string>asmCrypto.bytes_to_string(decBytes);
        } else {
            this.Logger.error('SyncCryptBuffer.passwordDecrypt() failed');
            this.Logger.error('Badly formatted password');
            throw new ErrCode(9000, 'badly formatted passwd encrypted string ' + b64_crypted);
        }
    }


    /**
     * Encrypts data using a string password that is keystretched
     * @param  {String} plain A num-prefixed b64 string
     * @param  {String|Uint8Array} password    The AES key
     * @param  {Integer} iterations  iterations for keystretch
     * @return {Promise}
     */
    public async passwordEncrypt(plain: string, password: string, iterations: number) {
        const salt = this.getRandom(96);
        const key = await this.keyStretch(password, salt, iterations, 256);
        const iv = this.getRandom(3 * 32);
        const encData = await this.symmetricEncrypt(key, this.stringToBytes(plain), iv);
        const result = new Uint8Array(salt.length + encData.length);
        result.set(salt);
        result.set(encData, salt.length);
        return '30:' + asmCrypto.bytes_to_base64(result);
    }


    /**
     * Stretches data, uses higher iteration count and runs it in a worker
     * to prevent browser lockups
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    public keyStretchSlow(password: string, salt: Uint8Array, iterations: number, length: number): Promise<Uint8Array> {
        // if (window.Worker) {
        //     this.KeystretchWorker.init();
        //     let passBytes = this.stringToBytes(password);

        //     return this.KeystretchWorker.run(
        //                         passBytes,
        //                         salt,
        //                         iterations,
        //                         length / 8).
        //                 then((result) => {
        //                     this.KeystretchWorker.completed();
        //                     return this.$q.when(result);
        //                 });
        // } else {
            return this.keyStretch(password, salt, iterations, length);
        // }
    }


    /**
     * Stretches data quickly
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    public keyStretch(password: string, salt: Uint8Array, iterations: number, length: number): Promise<Uint8Array> {
        const passBytes = this.stringToBytes(password);
        return Promise.resolve(asmCrypto.PBKDF2_HMAC_SHA256.bytes(passBytes,
                                        salt,
                                        iterations || 60000,
                                        length / 8
            ));
    }


    /**
     * Gets a SHA1 HMAC of the data using key as secret
     * @param  {Uint8Array} key  [description]
     * @param  {Uint8Array} data [description]
     * @return {Promise}      [description]
     */
    public getApiHmac(key: ArrayLike<number>, data: ArrayLike<number>): Promise<number[]> {
        return Promise.resolve(asmCrypto.HMAC_SHA1.bytes(data, key));
    }


    /**
     * Gets random bits.
     * @param  {Integer} bits The bit amount
     * @return {Uint8Array}      [description]
     */
    public getRandom(bits: number): Uint8Array {
        if (bits % 32 !== 0) {
            this.Logger.error('Bytes is not modulus 32');
            throw new Error('getRandom(bits) not a modules of 32');
        }
        const buf = new Int8Array(bits / 8);
        const rand = (window && window.crypto && window.crypto.getRandomValues && Int8Array) ?
            window.crypto.getRandomValues(buf) :
            asmCrypto.random.getValues(buf);
        return new Uint8Array(rand);
    }

    /**
     * Gets partial bits from the given data source.
     * @param  {Uint8Array} array     [description]
     * @param  {Integer} byteStart [description]
     * @param  {Integer} byteEnd   [description]
     * @return {Uint8Array}           [description]
     */
    public getPartialBytes(data: Uint8Array, byteStart: number, byteEnd?: number): Uint8Array {
        byteStart = byteStart / 8;
        byteEnd = (byteEnd) ? byteEnd = byteEnd / 8 : data.length;
        //     console.log(typeof data.slice)
        //                 console.log(typeof data);
        //     console.log(data.toString());
        // if (!data) {
        //     console.log(typeof data);
        //     console.log(data.toString());
        //     throw "data is null!";
        // }
        return data.subarray(byteStart, byteEnd);
    }


    /**
     * @ngdoc method
     * @name  unpackHeader
     * @methodOf sync.service:SyncCryptBuffer
     * @description
     * Unpacks the header array and returns the encrypted offset stored within
     * @param  {Uint8Array} header     [description]
     * @return {Integer}           [description]
     */
    public unpackHeader(header: Uint8Array): number {
        const a = new Uint8Array(header);
        let hi = (a[2] << 8) | (a[3] << 0);
        let lo = (a[4] << 24) | (a[5] << 16) | (a[6] << 8) | (a[7] << 0);

        // force to floating point
        hi = hi * 1.0;
        lo = lo * 1.0;
        return hi * (0xFFFFFFFF + 1) + lo;
    }

    /**
     * Packs a header for AES and embeds the offset in the first 8 bytes of the
     * header.  The number is encoded as an 8 byte array with the most significant
     * digit at index 0 and least significant at index 7.
     *
     * @param {Integer} offset the offsset.
     * @return {Uint8Array} a typed array
    */
    public packHeader(offset: number): Uint8Array {
        const hi = Math.floor(offset / 0xFFFFFFFF),
            lo = offset | 0x0;

        if (hi > 0xFF) {
            throw new Error('offset is too big (max = 2^40)');
        }
        const a = 0 |
            (hi & 0xFF000000) |
            (hi & 0x00FF0000) |
            (hi & 0x0000FF00) |
            (hi & 0x000000FF);

        const b = 0 |
            (lo & 0xFF000000) |
            (lo & 0x00FF0000) |
            (lo & 0x0000FF00) |
            (lo & 0x000000FF);
        const dv = new DataView(new ArrayBuffer(12));
        dv.setInt32(0, a);
        dv.setInt32(4, b);
        const result = new Uint8Array(new ArrayBuffer(12));
        for (let i = 0; i < 12; i++) {
            result[i] = dv.getUint8(i);
        }
        return result;
    }
    /**
     * Initializes the SHA1 object
     * @return {asmCrypto.SHA1} [description]
     */
    public sha1init() {
        return new asmCrypto.SHA1();
    }

    /**
     * Updates a sha1 with more data
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @return {asmCrypto.SHA1}         [description]
     */
    public sha1reset(sha1Obj: any) {
        return sha1Obj.reset();
    }

    /**
     * Updates a sha1 with more data
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @param  {Uint8Array} data    [description]
     * @return {asmCrypto.SHA1}         [description]
     */
    public sha1update(sha1Obj: any, data: ArrayLike<number>) {
        return sha1Obj.process(data);
    }

    /**
     * Finishes and gets the sha1 result
     * @param  {asmCrypto.SHA1} sha1Obj [description]
     * @return {Uint8Array}         [description]
     */
    public sha1finish(sha1Obj: any): Uint8Array {
        sha1Obj.finish();
        return sha1Obj.result;
    }



    /**
     * This function doesn't do anything.  It's needed because legacy (sjcl)
     * requires it's own "bitArray" instead of array buffers.
     * @param  {ArrayBuffer} buffer [description]
     * @return {Uint8Array}        [description]
     */
    public arraybufferToBytes(buffer: ArrayBuffer): Uint8Array {
        return new Uint8Array(buffer);
    }

    /**
     * Prepares data to be sent.  Since this encryption method uses 8bit
     * integers, it doesn't do anything.  This function is needed for
     * compatibility for Legacy.
     * @param  {Uint8Array} data [description]
     * @return {Uint8Array}      [description]
     */
    public prepareDataSend(data: Uint8Array): Uint8Array {
        return data;
    }

    /**
     * Appends file data when uploading/downloading creating a new
     * result.  This will return a new typed array with the old data
     * appended at the end.
     * @param  {Uint8Array} payload         [description]
     * @param  {Uint8Array} appendedPayload [description]
     * @return {Uint8Array}                 [description]
     */
    public filedataAppend(appendedPayload: Uint8Array[], maxLength: number): Uint8Array {
        const result = new Uint8Array(maxLength);
        let offset = 0;
        for (let i = 0; i < appendedPayload.length; i++) {
            result.set(appendedPayload[i], offset);
          offset += appendedPayload[i].length;
        }

        return result;
    }

    /**
     * Verifies that the data is correct
     * @param  {Uint8Array} payload         [description]
     * @param  {number} bits [description]
     * @return {boolean}                 [description]
     */
    public checkBitLength(data: Uint8Array, bits: number): boolean {
        return (data.byteLength * 8 === bits);
    }


}
