import { Injectable } from '@angular/core';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { LoggerService } from '../../core/logger.service';
import { ErrCode } from '../../shared/models';
import { HttpEventType } from '@angular/common/http';
import { ApiService } from '../../core/api.service';
import { environment } from '../../../environments/environment';
import { UrlService } from '../../core/url.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadWorkerService implements sync.IDownloadMethod {

    private WorkerObj: Worker;
    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private log: LoggerService,
        private url: UrlService
    ) { }

    public abort(): void {}


    public async getChunk(tItem: sync.ITransferItemDownload, offset: number, reqLength: number, retry = 0) {

        return new Promise<sync.IStoreData>((resolve, reject) => {
            this.WorkerObj = new Worker(environment.jslibpath + 'filedecrypt-worker.js?d=' + Date.now());
            this.WorkerObj.addEventListener('message', (ev: MessageEvent) => {
                const agentCmd = ev.data.agentcmd,
                    cmdData = ev.data.data;
                switch (agentCmd) {
                    case 'error':
                        this.log.error(`Received error ${agentCmd} with data: ${JSON.stringify(cmdData)}`);
                        const errcode = parseInt(ev.data.errcode, 10) || 1000;
                        this.log.error(errcode + ' error code download-chunk-worker');
                        reject(new ErrCode(errcode));
                        break;
                    case 'log':
                        this.log.info(cmdData);
                        break;
                    case 'runFinished':
                    // reject(new ErrCode(1000, 'blah'));
                        resolve(<sync.IStoreData>cmdData);
                        break;
                    default:
                        this.log.error(`${agentCmd} does not exist`);
                        reject(new ErrCode(8221));
                        break;
                }
            }, false);

            this.api.fetchFile(tItem, offset, reqLength, retry)
                .subscribe( resp => {
                    if (resp.type === HttpEventType.DownloadProgress) {
                        // console.log('download preogress');
                        const bytesSent = resp.loaded + offset;
                        const gcmTags = Math.ceil(bytesSent / 131072) * 36;
                        tItem.bytes_sent = bytesSent - gcmTags;
                        let percent = Math.round(tItem.bytes_sent / tItem.filesize * 100 * 100) / 100;
                        if (percent > 100) { percent = 100; }
                        tItem.progress_percent = percent;
                        // console.log('Download progress: ' + percent);
                    } else if (resp.type === HttpEventType.Response) {
                        // console.log('HTTP RESPONSE EVENT');
                        this.WorkerObj.postMessage({
                            workercmd: 'run',
                            data: {
                                response: resp.body,
                                tItem: tItem,
                                jsliburl: environment.jslibpath ,
                                datakey: this.crypt.bytesToB64(tItem.data_key),
                                offset: offset,
                                reqLength: reqLength,
                            }}
                        );
                    }
                }, err => {
                    // reject(err);
                    this.log.e('Error subscribing to fetch file in download-worker', err);
                    if (this.url.downloadhosts[retry + 1]) {
                        this.log.warn(`Retrying download on host ${this.url.downloadhosts[retry + 1]}`);
                        this.WorkerObj.terminate();
                        this.WorkerObj = null;
                        resolve(this.getChunk(tItem, offset, reqLength, retry + 1));
                    } else {
                        this.log.error(`Attempted ${retry} retries and failed, rejecting file`);
                        reject(err);
                    }
                });
        });
    }
    public completed(): void {
        this.log.info('calling terminate on workerobj');
        this.WorkerObj.terminate();
        this.WorkerObj = null;
        // this.dfd = null;
    }
}
