<div class="modal-header">
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">Confirm your password</h4>
</div>
<div class="modal-body">
  <sync-error [errcode]="errcode"></sync-error>
  <div class="row">
      <div class="col-sm-12">
        For security, confirm your account password to proceed with this account level change
      </div>
  </div>

  <hr/>

  <div class="row">
      <div class="col-sm-4">Password:</div>
      <div class="col-sm-8">
          <input
              type="password"
              class="form-control"
              id="txt-dialog-confirm-password"
              placeholder="Your password"
              [(ngModel)]="password"
              syncShowHidePassword
              identity="confirmpass"
          />
      </div>
  </div>
</div>
<div class="modal-footer">
  <button
      type="button"
      id="btn-dialog-confirm-password"
      class="btn btn-primary"
      (click)="check()"
      [disabled]="spinner"
  >
      <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
      Confirm
  </button>
  <button type="button" class="btn btn-default" (click)="cancel()">Close</button>
</div>
