import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class OrderUpgradesApiOutput extends BaseApiOutput {
    sessionId: string;
    products: {
        [k: string]: {
                        id: string;
                        name: string;
                        sku: string;
                        yearlyRatePlans: {
                            name: string;
                            disk_limit_bytes: string;
                            id: string;
                            sku: string;
                            label_annual: string;
                            label_monthly: string;
                        }[];
                        monthlyRatePlans: {
                            name: string;
                            disk_limit_bytes: string;
                            id: string;
                            sku: string;
                            label_annual: string;
                            label_monthly: string;
                        }[];
                    }
    };
    encHash?: string;
}

export class OrderUpgradesApiInput extends BaseApiInput {
}


