import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as FileListActions from '../../actions/file-list.actions';
import { UserService } from '../../core/user.service';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class FileSelectionService {
    private selected: sync.IFile[];

    constructor(
        private store: Store<fromRoot.State>,
        private userService: UserService
    ) {
        this.init();
    }

    private init() {
        this.watchSelected()
            .subscribe((result) => {
                this.selected = result;
            });
    }
    public watchSelected() {
        return this.store.select(fromRoot.getFileListSelected);
    }

    public getSelected() {
        return this.selected;
    }
    public select(item: sync.IFile, key = 'sync_id', value?: any) {
        return this.store.dispatch(new FileListActions.FileListSelectAction(item));
    }

    public selectType(key: string, value: any, filteredIds?: Array<number>) {
        return this.store.dispatch(new FileListActions.FileListSelectTypeAction({
            key: key,
            value: value,
            filteredIds
        }));
    }

    public toggle(item: sync.IFile) {
        return this.store.dispatch(new FileListActions.FileListSelectToggleAction(item));
    }
    public selectAll(active = false, filteredIds?: Array<number>) {
        return this.store.dispatch(new FileListActions.FileListSelectAllAction({
            active,
            filteredIds
        }));
    }
}
