import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../../core/logger.service';
import { UserService } from '../../../core/user.service';
import { AvatarUser } from '../../models/avatar-user.model';

@Component({
  selector: 'sync-avatar-stack',
  templateUrl: './avatar-stack.component.html',
  styleUrls: ['./avatar-stack.component.css']
})
export class AvatarStackComponent implements OnInit, OnChanges {

  /**
  * @property {AvatarUser[]} users
  * Users to display in Avatar stack
  */
  @Input() users: AvatarUser[];

  /**
  * @property {number} maxDisplayAvatar
  * Accepts a number of avatars that should be displayed in the stack.
  * Default value is 5.
  * e.g. Setting this value as 5 will show max 5 avatars. If users passed are more than 5,
  * then it will display 4 avatars and 5th avatar will show as overflowing avatar with remaining count.
  */
  @Input() maxDisplayAvatar = 5;

  /**
  * @property {number} maxOverflowAvatar
  * Accepts a number of avatars that should be displayed on overflowing popover.
  * Default value is 5.
  * e.g. Setting this value as 5 will show max 5 users on click of overflowing avatar.
  */
  @Input() maxOverflowAvatar = 5;
  public avatarUsers: AvatarUser[];
  public overflowUsers: AvatarUser[];
  public overflowUsersCount: number;

  /**
  * @property {Function} onViewAll
  * callback function on click of view all on overfow popover
  */
  @Input() onViewAll: Function = () => { };

  constructor(
    private userService: UserService,
    private log: LoggerService
  ) { }

  ngOnInit() {
  }

  initUsers() {
    this.users = this.users.filter(user => {
      try {
        user.decodedDisplayName = atob(user.displayname);
      } catch {
        // displayname already decoded
      }

      user.disabled = user.status != 'Active';
      return user.userid != this.userService.get('id');
    }).sort(user => user.disabled ? 1 : -1);

    if (this.users.length > this.maxDisplayAvatar) {
      this.avatarUsers = this.users.slice(0, this.maxDisplayAvatar - 1);
      this.overflowUsersCount = this.users.length - this.avatarUsers.length;
      this.overflowUsers = this.users.slice(this.maxDisplayAvatar - 1, this.maxDisplayAvatar + this.maxOverflowAvatar);
    } else {
      this.avatarUsers = this.users;
      this.overflowUsers = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.users && changes.users.currentValue) {
      this.users = changes.users.currentValue;
      this.initUsers();
    }
  }

}
