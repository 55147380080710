import { Deserializable } from './deserializable.model';
import { FileType } from './file-type.model';
/* tslint:disable:no-bitwise */
export class WebPath implements Deserializable<WebPath> {

    static CTX_FILES = 'files';
    static CTX_LINKS = 'links';

    blob_id = 0;
    sync_id = 0;
    pid = 0;
    share_id = 0;

    cachekey = '';
    size = 0;
    is_deleted = 0;
    has_thumb1 = 0;
    has_thumb2 = 0;
    synctype = 0;

    date = 0;
    usertime = 0;

    enc_name = '';
    enc_share_name = '';

    link_cachekey = '';
    share_key_id = ''; // [100001-1] or [shareid-sharesequence];

    context = '';
    cssclass = '';
    // derived values
    search_name = '';

    name = '';
    imgSrc = '';
    type = '';
    sortName = '';
    is_dir = 0;
    kind = '';
    imgsrc = '';
    filetype: sync.IFileTypeMime;

    is_readonly = 0;
    is_shared = 0;
    is_share_root = 0;
    is_publink = 0;

    constructor(params?: {[k: string]: any}) {
        if (params) {
            return this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        this.type = ((this.synctype & 1) === 1) ? 'dir' : 'file';
        this.is_dir = ((this.synctype & 1) === 1) ? 1 : 0;
        this.is_shared = ((this.synctype & 16) === 16) ? 1 : 0;
        this.is_readonly = !((this.synctype & 2) === 2) ? 1 : 0;
        this.is_share_root = (((this.synctype & 128) === 128) && this.is_shared) ? 1 : 0;
        this.is_publink = ((this.synctype & 32) === 32)  ? 1 : 0;

        if (this.type == 'dir') {
            this.cssclass = (this.is_deleted)
                ? 'dir-deleted'
                : (this.is_share_root) ? 'dir-sync' : 'dir';
            this.kind = (this.is_deleted)
                ? 'deleted folder'
                : (this.is_share_root) ? 'shared folder' : 'folder';
        } else if (this.type == 'file') {
            this.kind = (this.is_deleted) ? 'deleted file' : this.filetype.kind;
            this.cssclass = this.filetype.cssclass;
        }
        this.imgsrc = 'images/icons/' + this.cssclass + '.svg';
        return this;


    }
    // synctypes defined in API repo lib/SyncType
    // public isDir() {
    //     return ((this.synctype & 1) === 1);
    // }
    // public isReadOnly() {
    //     return ((this.synctype & 2) === 2);
    // }

    // public isPublink() {
    //     return ((this.synctype & 32) === 32);
    // }

    // public isShared() {
    //     return ((this.synctype & 16) === 16);
    // }

    // public isShareRoot() {
    //     return ((this.synctype & 128) === 128) && this.isShared();
    // }
}
