import { Component, OnInit } from '@angular/core';
import { RewardsService } from '../services/rewards.service';
import { UserService } from '../../core/user.service';
import { ErrCode } from '../../shared/models';
import { Router } from '@angular/router';

@Component({
    selector: 'sync-getstarted',
    templateUrl: './getstarted.component.html'
})
export class GetstartedComponent implements OnInit {

    public readonly required = 5;
    public returnCode = -1;
    public bonusData: sync.IUserGetStarted;
    public errcode: ErrCode;

    constructor(
        private rewards: RewardsService,
        private userService: UserService,
        private router: Router
    ) { }

    ngOnInit() {
        this.bonusData = this.userService.getStarted();
        if (this.userService.get('has_gettingstarted')) {
            this.router.navigate(['/files']);
        }
    }

    public isValid() {
        return (this.userService.get('getstarted_completed') < this.required);
    }

    public async submit() {
        this.errcode = undefined;
        try {
            await this.rewards.claimRewardOnce('sync-getting-started');
            this.userService.refresh();
            this.returnCode = 0;
        } catch (err) {
            this.errcode = ErrCode.fromException(err);
        }

    }
}
