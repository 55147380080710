<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-heart"></i> Sync rewards</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Rewards:</div>
        <div class="col-sm-8">
            <div *ngIf="!userRewards.from_sync.length && !userRewards.list.length">
                <h4>No rewards</h4>
                <p>You have not earned any rewards yet.</p>
            </div>
            <table class="table table-condensed" *ngIf="userRewards.list.length">
                <thead>
                    <th colspan="3">Your rewards</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of userRewards.list">
                        <td>{{item.bonus_bytes | bytesToSize}}</td>
                        <td>{{item.bonus_servtime | date:'mediumDate'}}</td>
                        <td>{{item.bonus_text}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-condensed" *ngIf="userRewards.from_sync.length">
                <thead>
                    <th colspan="3">Sync rewards</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of userRewards.from_sync">
                        <td>{{item.bonus_bytes | bytesToSize}}</td>
                        <td>{{item.bonus_servtime | date:'mediumDate'}}</td>
                        <td>{{item.bonus_text}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <hr/>

    <div class="row" *ngIf="!userRewards.is_referred">
        <div class="col-sm-4">Manual redeem:</div>
        <div class="col-sm-8">
            <input
                type="text"
                class="form-control"
                placeholder="Enter ref code"
                [(ngModel)]="refcode"
            />
            <div class="subtext">Use this to manually enter a referral code if the link you clicked didn't work but you know it's valid.</div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <sync-button *ngIf="!userRewards.is_referred" [disabled]="spinner" text="Redeem" type="primary" (click)="redeem()"></sync-button>
    <sync-button text="Close" type="secondary" (click)="activeModal.close()"></sync-button>
</div>