'use strict';
import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UtilModule } from '../util/util-module';
import { ErrorModule } from '../error/error-module';
import { HttpClientModule } from '@angular/common/http';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LinkConsumeModule } from '../link-consume/link-consume.module';
import { HistoryComponent } from './history/history.component';
import { PreviewMenuComponent } from './preview-menu/preview-menu.component';
import { PreviewImageComponent } from './preview-image/preview-image.component';
import { PreviewTextComponent } from './preview-text/preview-text.component';
import { PreviewWopiComponent } from './preview-wopi/preview-wopi.component';
import { PreviewDocComponent } from './preview-doc/preview-doc.component';
import { PreviewDefaultComponent } from './preview-default/preview-default.component';
import { PreviewPdfComponent } from './preview-pdf/preview-pdf.component';
import { PreviewErrorComponent } from './preview-error/preview-error.component';
import { PreviewComponent } from './preview/preview.component';
import { PreviewLinkComponent } from './preview-link/preview-link.component';
import { PreviewApplinkComponent } from './preview-applink/preview-applink.component';
import { WopiUpgradeComponent } from './wopi-upgrade/wopi-upgrade.component';
import { WopiOptinComponent } from './wopi-optin/wopi-optin.component';
import { DatePipe } from '@angular/common';
import { FileRoutingModule } from './file-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { PreviewVideoComponent } from './preview-video/preview-video.component';
import { DialogEditLinkAuthComponent } from './dialog-edit-link-auth/dialog-edit-link-auth.component';

@NgModule({
    imports: [
        FileRoutingModule,
        CommonModule,
        FormsModule,
        SharedModule,
        UtilModule,
        ErrorModule,
        NgbTooltipModule,
        HttpClientModule,
        NgbModalModule,
        LayoutModule,
    ],
    exports: [
        WopiOptinComponent,
        PreviewLinkComponent,
        PreviewApplinkComponent
    ],
    declarations: [
        PreviewMenuComponent,
        HistoryComponent,
        PreviewTextComponent,
        PreviewImageComponent,
        PreviewDefaultComponent,
        PreviewDocComponent,
        PreviewWopiComponent,
        PreviewLinkComponent,
        PreviewPdfComponent,
        PreviewErrorComponent,
        PreviewApplinkComponent,
        PreviewComponent,
        PreviewVideoComponent,
        WopiUpgradeComponent,
        WopiOptinComponent,
        DialogEditLinkAuthComponent,
    ],
    entryComponents: [
        PreviewMenuComponent,
        HistoryComponent,
        PreviewTextComponent,
        PreviewImageComponent,
        PreviewDefaultComponent,
        PreviewDocComponent,
        PreviewWopiComponent,
        PreviewLinkComponent,
        PreviewPdfComponent,
        PreviewErrorComponent,
        PreviewApplinkComponent,
        PreviewComponent,
        PreviewVideoComponent,
        WopiUpgradeComponent,
        WopiOptinComponent,
        DialogEditLinkAuthComponent
    ],
    providers: [
        Title,
        DatePipe,
        DecimalPipe
    ],
})
export class FileModule {}
