import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
    LinkListItem,
    WebPath,
    ErrCode,
    LinkApiOutput,
    LinkNewApiOutput,
    LinkPreCreateOptions,
} from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { LinkPasswordService } from '../services/link-password.service';
import { Base64Service } from '../../core/base64.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { Store } from '@ngrx/store';
import * as FileListActions from '../../actions/file-list.actions';

import { CensorSensor } from 'censor-sensor';
import { JoblockService } from '../../core/joblock.service';
import { State } from '../../reducers';
const censor = new CensorSensor();
import { WebSocketService } from '../../core/websocket.service';
import { MessageType } from '../../shared/models/websocket/websocket-message';
import { ProgressPayload } from '../../shared/models/websocket/action-payload';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../core/notifications.service';
import { FeatureService } from '../../shared/services/feature.service';

@Component({
    selector: 'sync-link-create',
    templateUrl: './link-create.component.html',
})
export class LinkCreateComponent implements OnInit, OnDestroy {
    @Input() openState: number;
    @Input() item: LinkListItem | WebPath;

    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();
    @Input() linkPrecreateOptions: LinkPreCreateOptions;

    public total = 0;
    public finished = 0;
    public errcode: ErrCode;
    public verified: boolean;
    public isLinkLimit: boolean;
    public spinner: boolean;
    public linkLimitAmt = 0;

    public newlinkjob: LinkApiOutput;
    public newLink: LinkNewApiOutput;
    public bg = 1;
    public cachekey: string;
    public modalText: string;

    private progressSubscription: Subscription;
    public createProgressPayload: ProgressPayload;
    private isComponentActive = true;
    private isWsProgressAllowed = false;

    constructor(
        private api: ApiService,
        private joblockService: JoblockService,
        private base64: Base64Service,
        private dirtyOut: DirtyOutService,
        private linkPassword: LinkPasswordService,
        private log: LoggerService,
        private store: Store<State>,
        private userService: UserService,
        private webSocketService: WebSocketService,
        private notificationsService: NotificationsService,
        private featureService: FeatureService,
    ) { }

    async ngOnInit() {
        if (this.item.is_publink) {
            this.state.emit(1);
            return;
        }

        this.isWsProgressAllowed = await this.featureService.isAllowed('websocketProgress');

        this.verified = this.userService.getUser().is_verified == 1;
        this.bg = 1;
        if (this.verified) {
            await this.makeLink();
        }
    }

    ngOnDestroy(): void {
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;

        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }

    async postLinkCreateAction() {
        this.notificationsService.stopNotificationLoop();
        this.modalText = 'Encrypting link, Please wait...';

        const updateModalText = (progress: number) => {
            this.modalText = this.isWsProgressAllowed
                ? `Encrypting link ${progress}%, Please wait...`
                : 'Encrypting link, Please wait...';
        };

        this.cachekey = this.bg === 1 ? this.newlinkjob.cachekey : this.newLink.cachekey;
        this.log.info('cachekey filter status: ' + censor.isProfaneIsh(this.cachekey));

        this.total = this.bg === 1 ? this.newlinkjob.cnt : this.newLink.cnt;
        this.finished = 0;

        // run executeForShare until the component and all items are processed
        // once the component is closed, let dirtyout execute the remaining items
        // This is to ensure that a new loop is not started if the modal is reopened
        // while the previous processing is still ongoing.
        while (this.finished < this.total && this.isComponentActive) {
            const data = await this.dirtyOut.executeForShare(
                this.bg === 1 ? this.newlinkjob.share_id : this.newLink.share_id
            );
            if (!data || (Array.isArray(data) && data.length === 0)) {
                this.finished = this.total;
                updateModalText(100);
            }
            if (data && data.completed) {
                this.finished += data.completed;
                const percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                updateModalText(percentage);

                if (this.finished >= this.total) {
                    updateModalText(100);
                    break;
                }
            }
        }
        this.modalText = 'Finalizing Link, Please wait...';
        this.store.dispatch(new FileListActions.FileListReloadAction());

        this.state.emit(1);

        this.spinner = false;
        this.notificationsService.startNotificationLoop();
        this.log.info('post link action completed');
    }

    public async getLinkPassword() {
        let pass;
        do {
            pass = this.linkPassword.makeLinkPassword(32);
        } while (censor.isProfaneIsh(pass));

        return this.linkPassword.makePasswordData(pass);
    }

    public async makeLink() {
        this.spinner = true;

        this.progressSubscription = this.webSocketService
            .getMessageHandler<ProgressPayload>(MessageType.LINK_NEW_PROGRESS)
            .subscribe((progress) => {
                this.log.info('Received link creation progress update:');
                this.log.info(progress);
                if (progress.sync_id === this.item.sync_id) {
                    this.createProgressPayload = progress;
                    this.modalText = `Creating link ${this.createProgressPayload.progressPercentage}%, Please wait...`;
                }
            });

        this.modalText = 'Creating link, Please wait...';

        try {
            const passdata = await this.getLinkPassword();
            let linkParams = {
                sync_id: this.item.sync_id,
                label: this.base64.encode(this.item.name),
                enc_share_key: passdata.enc_share_key,
                enc_password: passdata.enc_password,
                salt: passdata.salt,
                iterations: passdata.iterations,
                bg: this.bg,
            };

            if (this.linkPrecreateOptions !== undefined) {
                linkParams = { ...linkParams, ...this.linkPrecreateOptions };
            }

            do {
                this.newLink = await this.api.execute<LinkNewApiOutput>('linknew', linkParams);
                this.bg = this.newLink.bg;
                this.modalText = this.isWsProgressAllowed && this.bg === 1
                    ? `Creating link 0%, Please wait...`
                    : 'Creating link, Please wait...';
                if (this.bg == 1) {
                    const response = await this.joblockService.pollJobStatus();
                    this.newlinkjob = await this.api.execute<LinkApiOutput>(
                        'linkget',
                        {
                            sync_id: this.item.sync_id,
                        }
                    );
                }
            } while (censor.isProfaneIsh(this.bg == 1 ? this.newlinkjob.cachekey : this.newLink.cachekey));

            await this.postLinkCreateAction();
        } catch (ex) {
            if (ex instanceof ErrCode) {
                if (ex.code == 8028) {
                    if (ex.msg) {
                        this.log.warn('Link limit is reached ' + ex.msg);
                        const limit = ex.msg.toString().match(/\(([0-9]+)\)/);
                        this.log.info(limit);
                        if (limit !== null && parseInt(limit[1], 10) > 0) {
                            this.linkLimitAmt = parseInt(limit[1], 10);
                        } else {
                            this.log.error(
                                'Could not determine the link limit from the API, hardcoding to a limit of 3.  Received : "' +
                                ex.msg +
                                '"'
                            );
                            this.linkLimitAmt = 3;
                        }
                    }
                    this.isLinkLimit = true;
                } else {
                    this.errcode = ex;
                }
            } else {
                this.errcode = new ErrCode(
                    1000,
                    'An error occurred creating the link'
                );
                this.log.error('An error occurred creating the link');
                this.log.error(ex);
            }

            this.spinner = false;
        }
    }
}
