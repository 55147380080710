import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { OrderProfileApiOutput, zAccountInfo } from '../../shared/models/api/orderprofile-api.model';
import { LoggerService } from '../../core/logger.service';

@Injectable({
    providedIn: 'root'
})
export class ZuoraAccountService {

    constructor(
        private api: ApiService,
        private log: LoggerService
    ) { }

    public async getProfileAndQueue(): Promise<{zprofile: zAccountInfo, zbillingqueue: any} | null> {
        const retval = await this.api.execute<OrderProfileApiOutput>('orderprofile', {}, true);
        return (retval.exists) ? {
            zprofile: retval.zprofile,
            zbillingqueue: retval.zbillingqueue,
         } : null;
    }

    public async getProfile(): Promise<zAccountInfo | null> {
        const retval = await this.api.execute<OrderProfileApiOutput>('orderprofile', {}, true);
        return (retval.exists) ? retval.zprofile : null;
    }

    public async getInvoices(): Promise<zAccountInfo | null> {
        const retval = await this.api.execute<OrderProfileApiOutput>('orderprofile', {}, true);
        return (retval.exists) ? retval.zprofile : null;
    }

    public async emailBillingDocument(emails: string[], docType: string, id: string) {
        await this.api.execute('orderemail', {
            type: docType,
            id: id,
            emails: emails.join(',')
        });
    }
}
