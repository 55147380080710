import { Injectable } from '@angular/core';
import * as blend from 'mixpanel-browser';
import { DefaultProperties } from '../models';
import { environment } from '../../../environments/environment';
import { LoggerService } from 'src/app/core/logger.service';

@Injectable({
    providedIn: 'root'
})
export class UnauthBlendService {

    private blend: any;

    constructor(
        private log: LoggerService,
    ) {
        this.blend = blend.init(
            environment.unauthBlendToken,
            {
                'persistence': 'localStorage', //This prevents getting a "Cookie Too Large" error and in general is a more reliable way to persist state on the browser.
                'api_host': environment.blendHost,
                ...(!environment.production && { 'debug': true })
            },
            'Sync — unauthenticated'
        );
    }

    linkURLSanitizer(url) {
        try {
            // Parse the URL
            const parsedUrl = new URL(url);

            // Split the pathname into parts
            const pathParts = parsedUrl.pathname.split('/').filter(part => part !== '');
            const rootPath = (pathParts.length) ? pathParts[0] : '';

            switch (rootPath) {
                case 'dl': // e.g., /dl/aaaabbbb0/xxx-xxx-xx-xxx
                    parsedUrl.pathname = '/' + pathParts.slice(0, 2).join('/');
                    break;
            }

            return parsedUrl.href;
        } catch (error) {
            this.log.error('Error processing URL: ' + error.message);
            return url; // Return the original URL if there's an error
        }
    }

    isUrlHistoryClean() {
        // Parse the referrer and current URL
        const referrer = new URL(document.referrer, window.location.origin);
        const currentUrl = new URL(window.location.href);

        // Check if the referrer path starts with '/dl/'
        const referrerHasDl = referrer.pathname.startsWith('/dl/');

        // Check if the current URL path starts with '/dl/'
        const currentHasDl = currentUrl.pathname.startsWith('/dl/');

        return !referrerHasDl && !currentHasDl;
    }

    getDefaultProperties(): DefaultProperties {
        if (!this.isUrlHistoryClean()) {
            const defaultProperties: DefaultProperties = {
                'current_page_title': 'Sync.com — link',
                'current_url_path': '-',
                '$current_url': this.linkURLSanitizer(window.location.href), // Sanitize the URL if it's a link
                'platform': 'CP',
                '$referrer': this.linkURLSanitizer(document.referrer),
            };
            return defaultProperties;
        }
        const defaultProperties: DefaultProperties = {
            'current_url_path': window.location.pathname,
            'platform': 'CP'
        };
        return defaultProperties;
    }


    async trackPageview(): Promise<void> {
        this.blend.track_pageview(this.getDefaultProperties());
    }
}
