import { Component, OnInit, Input, Injector } from '@angular/core';
import { User, ErrCode, UserInfoSetApiOutput } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'sync-dialog-sso',
    templateUrl: './dialog-sso.component.html'
})
export class DialogSsoComponent implements OnInit {

    @Input() user: User;

    public status: string;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService
    ) { }


    ngOnInit() {
        this.status = this.user.is_disablesso == 1 ? '1' : '0';
        console.log('dialog sso ', this.status, ' - disablesso ', this.user.is_disablesso);
    }

    public async save() {

        this.spinner = true;
        console.log('dialog sso ', this.status, ' - disablesso ', this.user.is_disablesso);

        try {
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
                disablesso: this.status
            });
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }
}
