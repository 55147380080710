<input
    syncStopPropagation
    class="form-control"
    type="text"
    [syncShowFocus]="filterToggle"
    [(ngModel)]="filterText"
    (keyup.escape)="resetFilter()"
    id="show-filter-text"
    placeholder="Filter files in this folder"
    (input)="filterTextData($event)"
/>
