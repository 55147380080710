import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../core/user.service';
import { User, ErrCode, OrderSessionNewApiOutput, PermissionActions } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogBillingProfileDeleteComponent } from '../dialog-billing-profile-delete/dialog-billing-profile-delete.component';
import { DialogContactSupportComponent } from '../dialog-contact-support/dialog-contact-support.component';
import { Router } from '@angular/router';
import { zAccountInfo } from '../../shared/models/api/orderprofile-api.model';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { SkuService } from '../../shared/services/sku.service';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-billing-profile',
    templateUrl: './billing-profile.component.html'
})
export class BillingProfileComponent implements OnInit {

    @ViewChild('iframe') iframe: ElementRef<HTMLIFrameElement>;

    public error: ErrCode;
    public user: User;

    public profileSafeUrl: SafeUrl;
    public spinner: boolean;

    private sessionId: string;

    public framedError: string;

    public permissionActions = PermissionActions;
    public zprofile: zAccountInfo;
    public planName: string;
    public currentTerm: string;
    public planSku: string;
    public planExpiry: number;
    public cancelInFuture: boolean;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private modalService: NgbModal,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private router: Router,
        private zuora: ZuoraAccountService,
        private skuService: SkuService,
        private blendService: BlendService,
    ) { }

    ngOnInit() {
        this.spinner = true;
        this.user = this.userService.getUser();
        this.planName = this.user.plan_name;
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.loadBillingProfile();
        } else {
            this.planExpiry = this.user.account_duedate;
        }
        this.init();
    }

    private async loadBillingProfile() {
        this.zprofile = await this.zuora.getProfile();
        if (this.zprofile.subscription) {
            this.planName = this.zprofile.subscription.productFullName;
            this.planSku = this.zprofile.subscription.sku;
            this.cancelInFuture = this.zprofile.subscription.status === 'Cancelled';
            this.planExpiry = this.userService.dateConversionForTrials(this.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
            this.currentTerm = this.skuService.getPlanTerm(this.planSku);
        }
    }


    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    private async init() {
        this.log.info('Running init');
        try {
            const sess = await this.api.execute<OrderSessionNewApiOutput>('ordersessionnew', {});
            if (!sess.sessionId) {
                this.error = new ErrCode(8550);
                return;
            }
            this.log.info('got session ' + sess.sessionId);
            this.sessionId = sess.sessionId;

            const baseUrl = `${environment.ordershost}/profile`;
            // if (this.user.zuora_acct_key) {
            //     baseUrl = baseUrl + `?acctkey=${this.user.zuora_acct_key}`;
            // }
            this.profileSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(baseUrl);
        } catch (ex) {
            this.log.error('Error initializing billing profile');
            this.error = ErrCode.fromException(ex);
            this.log.e('An error occurred loading session', ex);
        }
    }

    @HostListener('window:message', ['$event']) onPostMessage(e: MessageEvent) {
        if (!e.data) {
            this.log.warn('Received a message without any data');
            return;
        }
        if (e.origin.indexOf('.paypal.com') > -1) {
            return;
        }
        if (e.origin !== environment.ordershost) {
            this.log.warn(`Billing profile e.origin does not match, do nothing origin: "${e.origin}" env: "${environment.ordershost}"`);
            return;
        }
        const regex = /^[a-z-]+::[\w -:,.{}'"\[\]]+$/;
        if (!regex.test(e.data)) {
            this.log.info(`A message was received from ${e.origin}, but did not pass regex ${e.data}`);
            // set a default error message
            // this.log.warn(`An invalid message was received from ${e.origin} "${e.data}"`);
            return;
        }
        // split on first occurence of "::"
        const data: string = e.data.split(/::(.*)/);
        const cmd = data[0];
        const payload = data[1];
        switch (cmd) {
            case 'sync-profile-dimensions':
                const num = parseInt(payload, 10);
                const newHeight = num + 32;
                // console.log('data =  ', e.data, 'num = ' , num, ' new height = ' , newHeight);
                this.iframe.nativeElement.height = `${newHeight}px`;
                break;
            case 'sync-profile-session':
                this.postToIframe('sync-profile-session', this.sessionId);
                break;
            case 'sync-profile-ready':
                if (payload === 'error') {
                    this.log.error('Error occurred loading the profile');
                    this.spinner = false;
                    this.error = new ErrCode(8500);
                } else {
                    this.spinner = false;
                    this.log.info('profile page has loaded');
                }
                break;
            case 'sync-profile-error':
                this.log.error(`Error with sync-profile-error ${payload}`);
                this.framedError = payload;
                break;
            case 'sync-profile-saved':
                // clear the cache
                if (payload === 'error') {
                    this.log.error('Error saving the profile');
                } else {
                    this.api.execute('userinfoset', { cc_billingprofile: 1 })
                        .then(() => {
                            this.userService.refresh();
                            this.log.info('Cleared cache back to billing');
                            if (this.user.is_on_trial && !this.zprofile.defaultPaymentMethod) {
                                const baseUrl = `${environment.webhost}/welcome-trial-billing/?sku=${this.zprofile.subscription.sku}&uid=${this.user.id}`;
                                window.location.href = baseUrl;
                            } else {
                                this.router.navigate(['/account/billing'], { queryParams: { newprofile: 1 }});
                            }
                        }).catch(() => {
                            this.log.error('Error clearing the billing profile');
                            this.router.navigate(['/account/billing']);
                        });
                }

                break;
            case 'sync-profile-delete':
                this.log.info('Delete billing profile started');
                this.deleteProfile();
                break;
            default:
                this.log.warn('Command not found');

        }
    }
    public clearFramedError() {
        this.framedError = undefined;
    }

    private postToIframe(cmd: string, payload: string) {
        this.iframe.nativeElement.contentWindow.postMessage(`${cmd}::${payload}`, '*');
    }
    public openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }
    public deleteProfile() {
        const ref = this.modalService.open(DialogBillingProfileDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then((result) => {
            if (result === true) {
                this.userService.refresh();
                this.router.navigate(['/account/billing'], { queryParams: { newprofile: 0 }});
            }
        });
    }

    public onUpgradeClick() {
        this.blendService.addEventsForUpgradeButton();
        this.router.navigate(['/account/upgrade']);
    }
}
