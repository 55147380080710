import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';

@Component({
    selector: 'sync-header-default',
    templateUrl: './header-default.component.html',
    styleUrls: ['./header-default.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderDefaultComponent implements OnInit {

    constructor(
        private modalService: NgbModal
    ) { }
    ngOnInit() {
    }
    openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }
}
