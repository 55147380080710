import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { LinkListApiOutput, LinkListItem, LinkItem, LinkApiOutput, BlendEvent, PermissionActions } from '../../shared/models';
import { Base64Service } from '../../core/base64.service';
import { LinkPasswordService } from './link-password.service';
import { environment } from '../../../environments/environment';
import { FileItemService } from '../../file/file-item.service';
import { BlendService } from '../../shared/services/blend.service';
import { UserService } from '../../core/user.service';


@Injectable({
    providedIn: 'root'
})
export class LinkListService {

   constructor(
        private api: ApiService,
        private base64: Base64Service,
        private linkPassword: LinkPasswordService,
        private fileItemService: FileItemService,
        private blendService: BlendService,
        private userService: UserService
    ) { }



    /**
     * Gets the link list data from the backend
     *
     * This will toggle the spinner property in the view.
     *
     * Formats the list for display in the CP
     * @returns {Promise} Resolves on completion.
     */
    public async getData() {
        const data = await this.api.execute<LinkListApiOutput>('linklist', {});

        const list: LinkListItem[] = [];
        for (let i = 0, len = data.linklist.length; i < len; i++) {
            data.linklist[i].download_count = data.linklist[i].download_count;
            data.linklist[i].download_limit = data.linklist[i].download_limit;
            data.linklist[i].is_expired = this.isExpired(data.linklist[i]);
            data.linklist[i].is_download_limit = this.isDownloadLimit(data.linklist[i]);
            data.linklist[i].is_download_close = this.isDownloadClose(data.linklist[i]);
            data.linklist[i].loc_id = `${data.linklist[i].share_id}-0-${data.linklist[i].publink_id}`;
            if (data.linklist[i].label) {
                data.linklist[i].label = this.base64.decode(data.linklist[i].label);
            }
            data.linklist[i].parent_id = (data.linklist[i].type == 'dir')
                ? data.linklist[i].sync_id
                : data.linklist[i].pid;

            list.push(await this.fileItemService.format(data.linklist[i]) as unknown as LinkListItem);
        }
        return list;
    }

    /**
     * Checks if the link has expired
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if the link is expired
     */
    // public getItemByKey<T>(key: string, value: T): sync.IFile {
    //     let obj;
    //     for (let i = 0, len = this.view.linklist.length; i < len; i++) {
    //         var curItem = this.view.linklist[i];
    //         if (curItem[key] == value) {
    //             obj = curItem;
    //             break;
    //         }
    //     }
    //     return obj;
    // }


    /**
     * Checks if the link has expired
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if the link is expired
     */
    public isExpired(item: LinkListItem | LinkItem): boolean {
        return (item.exp_servtime && item.exp_servtime < Date.now());
    }

    /**
     * Checks if the link has reached the download limit
     * @param  {Object}  item The link list item.
     * @returns {Boolean}      True if the limit has been reached
     */
    public isDownloadLimit(item: LinkListItem | LinkItem): boolean {
        return (item.download_limit > 0 &&
                item.download_count >= item.download_limit);
    }

    /**
     * Checks if the link download count is 75% of the way to the limit
     * @param  {Object}  item The link list item.
     * @return {Boolean}      True if >=75%
     */
    public isDownloadClose(item: LinkListItem | LinkItem): boolean {
        return (item.download_count / item.download_limit * 100 > 75 &&
            !this.isDownloadLimit(item) &&
            item.download_limit > 0);
    }

    public async getLink(syncId: number, name: string): Promise<LinkItem> {

        const link = await this.api.execute<LinkApiOutput>('linkget', {
            sync_id: syncId
        });

        const linkItem = new LinkItem(link);
        if (link.password) {
            linkItem.passwordprotect = this.base64.decode(link.password);
            linkItem.has_passwordprotect = true;
        }
        linkItem.is_expired = this.isExpired(linkItem);
        linkItem.is_download_close = this.isDownloadClose(linkItem);
        linkItem.is_download_limit = this.isDownloadLimit(linkItem);

        if (name) {
            linkItem.name = name;
        }


        linkItem.key = (linkItem.linkversion == 2)
            ? await this.linkPassword.getPassword(link.enc_key)
            : await this.linkPassword.getLegacyPassword(link.share_id + '-1', link.enc_share_key);

        linkItem.togglePassword = true;

        if (linkItem.linkversion == 2) {
            linkItem.togglePassword = (linkItem.key.length === 35 && linkItem.key.indexOf('-') === 8);
        }
        linkItem.publinkOutput = '';


        if (linkItem.is_app_link) {

            linkItem.baseUrl = [environment.linkhost, 'al'].join('');

            linkItem.linkUrl = [
                linkItem.baseUrl, '/', linkItem.cachekey,
            ].join('');
            linkItem.separatedUrlBase = [linkItem.baseUrl, '/', linkItem].join('');

        } else {
            linkItem.baseUrl = [environment.linkhost, 'dl'].join('');

            // Defaulting to # due to security issue.
            // const joinStr = (linkItem.compat) ? '/' : '#';
            const joinStr = '#';
            linkItem.linkUrl = [
                linkItem.baseUrl, '/', linkItem.cachekey,
                joinStr, linkItem.key
            ].join('');
            linkItem.separatedUrlBase = [linkItem.baseUrl, '/', linkItem].join('');

        }
        this.blendService.track(BlendEvent.SHARE_LINK, {});

        return linkItem;
    }

    public getLinkEnforcedSettings() {
        let isLinkExpiryDateRequired = false;
        let linkExpiryDateDefault: number = null;
        let isLinkPasswordRequired = false;
        let linkPasswordDefault: number = null;
        isLinkExpiryDateRequired = this.userService.checkPermission(PermissionActions.SHARE_LINK_EXPIRY);

        linkExpiryDateDefault = isLinkExpiryDateRequired ? this.userService.getSubPermissionValues(PermissionActions.DEFAULT_SHARE_LINK_EXPIRY_TIME) : null;

        isLinkPasswordRequired = this.userService.checkPermission(PermissionActions.SHARE_LINK_PASSWORD);

        linkPasswordDefault = isLinkPasswordRequired ? this.userService.getSubPermissionValues(PermissionActions.DEFAULT_SHARE_LINK_PASSWORD) : null;
        return {
            isLinkExpiryDateRequired: isLinkExpiryDateRequired,
            linkExpiryDateDefault: linkExpiryDateDefault,
            isLinkPasswordRequired: isLinkPasswordRequired,
            linkPasswordDefault: linkPasswordDefault
        };
    }
}
