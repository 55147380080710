import { Injectable } from '@angular/core';
import { LoggerService } from '../logger.service';
import { ErrCode } from '../../shared/models';
import { CryptBufferService } from './crypt-buffer.service';
import { CryptMethodService } from '../../shared/models/crypt-method-service.model';

/* tslint:disable:no-bitwise */
@Injectable({
    providedIn: 'root'
})
export class CryptNativeService extends CryptBufferService implements CryptMethodService {

    private mCryptoSubtle: SubtleCrypto;
    private mCrypto: Crypto;
    /**
     * caches CryptoKey instances since they take 5-10ms to import.
     *
     * The key is a base64 representation of the raw encryption key.  It is
     * only memoized so we don't pay the cost repeatedly.
     */
    private mCache: {[k: string]: CryptoKey};
    constructor(
        private logService: LoggerService
    ) {
        super(logService);
        this.mCrypto = window.crypto || window.msCrypto;
        this.mCryptoSubtle = (this.mCrypto && this.mCrypto.subtle) ? this.mCrypto.subtle : null;
        this.mCache = {};
    }

    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} plain  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    public async symmetricEncrypt(key: Uint8Array, plain: Uint8Array, iv: Uint8Array, header?: Uint8Array): Promise<Uint8Array> {
         try {
            // let startMs = window.performance.now();
            // console.warn('Using crypt-native encrypt');
            if (!header) {
                header = new Uint8Array(0);
            }
            const aesKey = await this.getSymmetricKey(key);
            // console.warn(' - make key = ' + (window.performance.now() - startMs));
            // startMs = window.performance.now();
            const encBuffer = await this.mCryptoSubtle.encrypt({
                    name: 'AES-GCM',
                    iv: iv,
                    additionalData: header,
                    tagLength: 96
                },
                aesKey,
                plain
            );
            // console.warn(' - encrypt = ' + ( window.performance.now()  - startMs));
            // startMs = window.performance.now();

            const data = new Uint8Array(encBuffer);
            const len = header.length + iv.length + data.length;
            const result = new Uint8Array(len);
            result.set(header, 0);
            result.set(iv, header.length);
            result.set(data, header.length + iv.length);
//            console.warn(' - encrypt done = ' + (window.performance.now()  - startMs));

            return result;
        } catch (ex) {
            this.logService.error(`CryptNative.symmetricEncrypt() failed ${ex.toString()}`);
            throw new ErrCode(2503);
        }
    }

    /**
     * Encrypts data using symmetric encryption via asmCrypto
     * @param  {Uint8Array} key    The AES key
     * @param  {Uint8Array} crypted  [description]
     * @param  {Uint8Array} iv     [description]
     * @param  {Uint8Array|null} header [description]
     * @return {Promise}        [description]
     */
    public async symmetricDecrypt(key: Uint8Array, crypted: Uint8Array, iv: Uint8Array, header?: Uint8Array): Promise<Uint8Array> {
        // console.warn('Using crypt-native decrypt');
         try {
            if (!header) {
                header = new Uint8Array(0);
            }
            const aesKey = await this.getSymmetricKey(key);
            const decBuffer = await this.mCryptoSubtle.decrypt({
                    name: 'AES-GCM',
                    iv: iv,
                    additionalData: header,
                    tagLength: 96
                },
                aesKey,
                crypted
            );
            return new Uint8Array(decBuffer);
        } catch (ex) {
            this.logService.error(`CryptNative.symmetricDecrypt() failed ${ex.toString()}`);
            throw new ErrCode(2504);
        }
    }

    /**
     * Stretches data quickly
     * @todo Combine this with keystretch and use the iteration size
     *       to determine which method to use.
     * @param  {String|Uint8Array} password   [description]
     * @param  {Uint8Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} length     [description]
     * @return {Promise}            [description]
     */
    public async keyStretch(password: string, salt: Uint8Array|string, iterations: number, length: number): Promise<Uint8Array> {
        try {
            const saltBuff = (typeof salt === 'string')
                ? this.hexToBytes(salt)
                : salt;
            const passBytes = this.stringToBytes(password);
            const key = await this.mCryptoSubtle.importKey(
                'raw',
                passBytes,
                'PBKDF2',
                false,
                [ 'deriveBits' ]
            );
            const buffer = await this.mCryptoSubtle.deriveBits({
                    name: 'PBKDF2',
                    salt: saltBuff,
                    iterations: iterations,
                    hash: { name: 'SHA-256' }
                },
                key,
                length
            );
            return new Uint8Array(buffer);
        } catch (ex) {
            this.logService.e('Failed PBKDF2', ex);
            throw new ErrCode(2505);
        }
    }

    /**
     * Converts the key into a CryptoKey usable for webcrypto functions.
     *
     * It will cache the result of importKey() since each call takes 5-10 ms.
     *
     * @param key the encryption key
     * @returns CryptoKey
     */
    private async getSymmetricKey(key: Uint8Array): Promise<CryptoKey> {
        const aesKeyId = this.bytesToB64(key);
        if (!this.mCache[aesKeyId]) {
            this.mCache[aesKeyId] = await this.mCryptoSubtle.importKey(
                'raw',
                key,
                'AES-GCM',
                false, // extractable
                ['encrypt', 'decrypt']
            );
        }
        return this.mCache[aesKeyId];
    }


}
