import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { BatchActionService } from './batch-action.service';
import { WebpathActionType } from '../shared/models';

@Injectable({
    providedIn: 'root',
})
export class BatchCopyService extends BatchActionService {
    constructor(
        public broadcastService: BroadcastService,
        public loggerService: LoggerService,
        public syncCryptService: SyncCryptService,
        public notificationsService: NotificationsService,
        public apiService: ApiService
    ) {
        super(
            broadcastService,
            loggerService,
            syncCryptService,
            notificationsService,
            apiService
        );
    }

    public async execute(
        syncIds: number[],
        destSyncId: number,
        delOld?: boolean,
        duplicateNameMap?: { [key: number]: sync.INameData }
    ): Promise<any> {
        this.ACTION = this.ACT_COPY;
        this.loggerService.info(
            `batchCopy(${syncIds.toString()}, ${destSyncId}`
        );
        this.loggerService.info(delOld ? 'Move' : 'Copy');
        this.view.globalCancel = false;
        this.view.completedBatch = [];
        if (!syncIds.length) {
            return;
        }
        // get copy of syncIds to prevent modifying ref
        const syncArray: number[] = syncIds.slice();
        return await this._copyFolder(
            syncArray,
            destSyncId,
            delOld ? WebpathActionType.MOVE : WebpathActionType.COPY,
            duplicateNameMap
        );
    }

    public async _copyFolder(
        syncIds: number[],
        destSyncId: number,
        action: string,
        duplicateNameMap?: { [key: number]: sync.INameData }
    ) {
        let syncHash: { [syncId: number]: any } = {};
        const syncIdList: sync.ICopyData[] = [],
            syncArrayResolve = syncIds.slice(),
            syncId = syncIds.pop();
        this.resetProgress();
        this.view.spinner = syncId;

        try {
            const data = await this.dumpDirs(syncId);
            syncHash = data.sync_ids;
            if (!syncHash[syncId]) {
                this.loggerService.error(
                    'Could not find the sync id in the batchdumpdiers result'
                );
                throw { errcode: 9000 };
            }
            syncHash[syncId].new_sync_id = destSyncId;
            this.view.total = data.cnt;

            for (const key in syncHash) {
                syncIdList.push(syncHash[key]);
            }
            await this._copyFolderContents(
                syncIdList,
                0,
                action,
                duplicateNameMap
            );
            if (syncIds.length) {
                await this._copyFolder(
                    syncIds,
                    destSyncId,
                    action,
                    duplicateNameMap
                );
            } else {
                this.view.spinner = 0;
                return syncArrayResolve;
            }
        } catch (errData) {
            this.loggerService.handleError('_copyFolder', errData);
        }
    }

    public async _copyFolderContents(
        syncIds: Array<sync.ICopyData>,
        idx: number,
        action: string,
        duplicateNameMap?: { [key: number]: sync.INameData }
    ): Promise<any> {
        if (!syncIds[idx]) {
            this.loggerService.error('idx not found, resolving');
            return syncIds;
        }
        if (this.view.globalCancel) {
            this.handleCancel();
            return;
        }
        const item = syncIds[idx];
        // this value may be a string from the api.
        const syncId = parseInt(item.sync_id, 10);
        if (!item.new_sync_id) {
            this.loggerService.warn(
                `ICopyData is incorrect ${JSON.stringify(item)}`
            );
        }
        if (!item.nameData && duplicateNameMap) {
            item.nameData = duplicateNameMap[syncId];
        }
        try {
            const data: any = item.nameData
                ? await this.apiService.execute('batchcopy', {
                      sync_id: syncId,
                      sync_pid: item.new_sync_id,
                      new_name: item.nameData.new_name,
                      share_names: item.nameData.share_names,
                      is_duplicate_copy:
                          duplicateNameMap &&
                          Object.keys(duplicateNameMap).length > 0,
                      action: action,
                  })
                : await this.apiService.execute('batchcopy', {
                      sync_id: syncId,
                      sync_pid: item.new_sync_id,
                      action: action,
                  });

            this.updateProgress(syncId);
            this.copyRetryAmt = 0;
            if (item.synctype & 128 && item.synctype & 16) {
                this.loggerService.info('batchCopy found a share root folder');
                item.new_metaname = data.new_metaname;
                item.new_sync_pid = data.new_sync_pid;
            }

            if (data['continue']) {
                for (let i = 0, len = syncIds.length; i < len; i++) {
                    if (syncIds[i].sync_pid == syncId) {
                        syncIds[i].new_sync_id = data.new_sync_id;
                    }
                }
                idx++;
                if (!syncIds[idx]) {
                    return syncIds;
                } else {
                    return await this._copyFolderContents(syncIds, idx, action);
                }
            } else {
                return syncIds;
            }
        } catch (errData) {
            if (
                this.copyRetryAmt < 2 &&
                errData &&
                typeof errData == 'object'
            ) {
                if (errData.error_code >= 8000 && errData.error_code <= 8200) {
                    return this.loggerService.handleError(
                        '_copyFolderContents',
                        errData
                    );
                } else {
                    this.copyRetryAmt++;
                    this.loggerService.warn('Retrying ' + errData.error_msg);
                    await new Promise((resolve) =>
                        setTimeout(() => {
                            resolve(
                                this._copyFolderContents(syncIds, idx, action)
                            );
                        }, 10000)
                    );
                }
            } else {
                this.copyRetryAmt = 0;
                this.loggerService.error(JSON.stringify(errData));
                this.loggerService.handleError(
                    '_copyFolderContents',
                    'Copy has encountered an error,  stopping operation'
                );
            }
        }
    }
}
