<div class="container panel-push">
    <div class="row">
        <div class="col-sm-2 col-md-3"></div>
        <div class="col-xs-12 col-sm-8 col-md-6">
            <sync-error [errcode]="errcode"></sync-error>

            <div class="alert alert-info" *ngIf="returnCode == 0">
                <i class="fa fa-check-circle fa-lg"></i>
                Successfully claimed your free 1 GB!
            </div>

            <div class="panel panel-default" >
                <div class="panel-heading">
                    <h3 class="panel-title">
                    You're just a few steps away from a 1 GB bonus!
                    </h3>
                </div>
                <div class="panel-body">
                    <p>Complete at least {{required}} of the achievements below and get yourself free space.</p>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a [ngClass]="{'syncblue': !bonusData.hasVerified,
                            'syncgreen': bonusData.hasVerified}" routerLink="/account/info">
                                <i class="fa-lg" [ngClass]="{'far fa-circle': !bonusData.hasVerified,
                                'fas fa-check-circle': bonusData.hasVerified}"></i>
                                Verify your email
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a  [ngClass]="{'syncblue': !bonusData.hasDeviceOne,
                                        'syncgreen': bonusData.hasDeviceOne}"
                                href="https://www.sync.com/install">
                                <i  class="fa-lg"
                                    [ngClass]="{'far fa-circle': !bonusData.hasDeviceOne,
                                            'fas fa-check-circle': bonusData.hasDeviceOne}"
                                ></i>
                                Install Sync on your computer
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a [ngClass]="{'syncblue': !bonusData.hasFiles,
                                        'syncgreen': bonusData.hasFiles}"
                                routerLink="/files">
                                <i  class="fa-lg"
                                    [ngClass]="{'far fa-circle': !bonusData.hasFiles,
                                            'fas fa-check-circle': bonusData.hasFiles}"></i>
                                Put files in your Sync folder
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a  [ngClass]="{'syncblue': !bonusData.hasDeviceMany,
                                        'syncgreen': bonusData.hasDeviceMany}"
                                href="https://www.sync.com/install"
                            >
                                <i class="fa-lg" [ngClass]="{
                                    'far fa-circle': !bonusData.hasDeviceMany,
                                    'fas fa-check-circle': bonusData.hasDeviceMany
                                }"></i>
                                Install Sync on other computers you use
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a  [ngClass]="{'syncblue': !bonusData.hasShares,
                                        'syncgreen': bonusData.hasShares}"
                                        routerLink="/shares"
                            >
                                <i class="fa-lg" [ngClass]="{
                                    'far fa-circle': !bonusData.hasShares,
                                    'fas fa-check-circle': bonusData.hasShares
                                }"></i>
                                Share a folder with friends or colleagues
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a  [ngClass]="{'syncblue': !bonusData.hasLinks,
                                        'syncgreen': bonusData.hasLinks}"
                                routerLink="/links"
                            >
                                <i class="fa-lg" [ngClass]="{
                                    'far fa-circle': !bonusData.hasLinks,
                                    'fas fa-check-circle': bonusData.hasLinks
                                }"></i>
                                Create a secure link
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a [ngClass]="{'syncblue': !bonusData.hasPasswordHint,
                                        'syncgreen': bonusData.hasPasswordHint}"
                                        routerLink="/account/security">
                                <i class="fa-lg"
                                    [ngClass]="{'far fa-circle': !bonusData.hasPasswordHint,
                                            'fas fa-check-circle': bonusData.hasPasswordHint}"
                                ></i>
                                Set up a password hint
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a  [ngClass]="{'syncblue': !bonusData.hasMobile,
                                        'syncgreen': bonusData.hasMobile}"
                                href="https://www.sync.com/install"
                            >
                                <i class=" fa-lg" [ngClass]="{
                                    'far fa-circle': !bonusData.hasMobile,
                                    'fas fa-check-circle': bonusData.hasMobile
                                }"></i>
                                Install Sync on your mobile device
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="panel-footer">
                    <button
                        class="btn btn-default"
                        routerLink="/files"
                    >Skip</button>
                    <button
                        [disabled]="isValid()"
                        class="btn btn-primary pull-right"
                        (click)="submit()"
                    >Claim reward</button>
                </div>
            </div>
            <div class="col-sm-2 col-md-3"></div> <!-- spacer column -->
        </div>
    </div>
</div>
