<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
    <h4 class="modal-title" *ngIf="openState == 1">User settings for {{child.email}}</h4>
    <h4 class="modal-title" *ngIf="openState == 2 || openState == 3">Remove user {{child.email}}?</h4>
    <h4 class="modal-title" *ngIf="openState == 4">Remove invite for user {{child.email}}?</h4>
    <h4 class="modal-title" *ngIf="openState == 5">Change role for user {{child.email}}?</h4>
</div>
<div class="modal-body" *ngIf="openState == 1">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Change password:</div>
        <div class="col-sm-8">
            <div>
                Enter new password for {{child.email}}:
                <input [(ngModel)]="pass1" type="password" class="form-control" placeholder="Enter new password"
                    id="text-mu-passreset-newpass1"
                    syncShowHidePassword
                    identity="subuserpasschange">
            </div>
            <br>
            <div>
                Confirm new password for {{child.email}}:
                <input [(ngModel)]="pass2" type="password" class="form-control" placeholder="Confirm new password"
                    id="text-mu-passreset-newpass2"
                    syncShowHidePassword
                    identity="subuserpasschange">
            </div>
            <br>
            <div>
                Enter your password ({{user.email}}):
                <input [(ngModel)]="parentPass" type="password" class="form-control" placeholder="Enter your password"
                    id="text-mu-passreset-parent-password"
                    syncShowHidePassword
                    identity="subuserpasschange">
            </div>

            <div class="subtext">(this is used for security verification only)</div>

        </div>
    </div>
</div>
<div class="modal-body" *ngIf="openState == 2 || openState == 3 || openState == 4">
    <sync-error [errcode]="errcode"></sync-error>

    <div class="row">
        <div class="col-sm-12" *ngIf="openState == 2 && child.user_status_str === 'email sent'">
            <p>The user invite will be cancelled and the account will be removed.</p>
        </div>
        <div class="col-sm-12" *ngIf="openState == 2 && child.user_status_str === 'Active'">
            <p>
                By default the Sync account {{child.email}} will remain active
                and usable, however, upon removal the account will be
                downgraded to a free plan.
            </p>

            <p>Note: you will also need to remove this user from any
                shares you've created. We recommend you do this step
                first, prior to removal, from the
                <a routerLink="/shares" (click)="activeModal.close()">shares tab</a>.
            </p>

        </div>
        <div class="col-sm-12" *ngIf="openState == 3">
            <p>Are you sure you want to remove this user from provisioning?</p>
        </div>
        <div class="col-sm-12" *ngIf="openState == 4">
            <p>Are you sure you want to remove this user invite?</p>
        </div>
    </div>

    <hr *ngIf="openState == 2 && child.user_status_str === 'Active'">
    <div class="row" *ngIf="openState == 2 && child.user_status_str === 'Active'">
        <div class="col-sm-4">Purge account?</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input type="radio" name="rmPurge" id="opt-multiuser-rm-purge-false" value="0"
                        [(ngModel)]="rmPurge">
                    No, account will remain active as a free account.
                </label>
            </div>

            <div class="radio radio-bottom">
                <label>
                    <input type="radio" name="rmPurge" id="opt-multiuser-rm-purge-true" value="1" [(ngModel)]="rmPurge">
                    Yes, account will be destroyed, and all user data will be purged. This cannot be undone.
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-body" *ngIf="openState == 5">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Change role:</div>
        <div class="col-sm-8">
            <select class="form-control" [(ngModel)]="role">
                <option value="{{role.name}}" *ngFor="let role of rolesList">{{role.name}}</option>
            </select>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button *ngIf="openState == 1" type="button" class="btn btn-primary" [disabled]="spinner"
        (click)="changePassword()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Change Password
    </button>
    <button *ngIf="openState == 2" type="button" class="btn btn-primary" [disabled]="spinner"
        (click)="removeActiveUser()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Remove User
    </button>
    <button *ngIf="openState == 3" type="button" class="btn btn-primary" [disabled]="spinner"
        (click)="removeProvisioningUser()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Remove User
    </button>
    <button *ngIf="openState == 4" type="button" class="btn btn-primary" [disabled]="spinner"
        (click)="removeUserTeamInvite()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Remove User Invite
    </button>
    <button *ngIf="openState == 5" type="button" class="btn btn-primary" [disabled]="spinner"
        (click)="updateUserRole()">
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Update User Role
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>