import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';

@Injectable({
  providedIn: 'root'
})
export class BaseGuard implements CanActivate {

  constructor(
    private apiService: ApiService,
    private loggerService: LoggerService,
    private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    try {
      if (window.location.pathname == '/') {
        await this.apiService.execute<any>('sessionvalid', {});
        this.router.navigate(['/files']);
        return false;
      }
    } catch (err) {
      this.loggerService.error('Session is not valid');
      this.loggerService.error(err);
    }
    return true;
  }
}
