import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../core/logger.service';
import { Base64Service } from '../../core/base64.service';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../auth/services/auth.service';
import { ProvisionService } from '../../account/services/provision.service';
import { NgForm } from '@angular/forms';
import { ShareInviteService } from '../share-invite.service';
import { NotificationsService } from '../../core/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '../../auth/services/password.service';
import { JoblockService } from '../../core/joblock.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { BlendEvent } from 'src/app/shared/models';
import { SyncCookieService } from 'src/app/core/sync-cookie.service';

@Component({
    selector: 'sync-share-provision',
    templateUrl: './share-provision.component.html',
})
export class ShareProvisionComponent implements OnInit, OnDestroy {
    public errors: sync.IErrorCode[] = [];
    public terms = false;
    public reset = true;
    public username: string;
    public password: string;
    public password2: string;

    public wrapKey: string;
    public uWrapKey: string;
    public cachekey: string;
    private syncReferCode: string;

    // public isUserExist: boolean = false;
    public userExistEmail: string;

    public loginUsername: string;
    public loginPassword: string;

    public spinners = {
        invite: false,
        login: false,
        provision: false,
    };
    public foldername: string;
    public label: string;
    private shareReady: boolean;
    private sub: Subscription;
    @ViewChild('provisionForm') public provisionForm: NgForm;
    public submitted = false;

    constructor(
        private notificationsService: NotificationsService,
        private loggerService: LoggerService,
        private base64Service: Base64Service,
        private userService: UserService,
        private authService: AuthService,
        private provisionService: ProvisionService,
        private shareInviteService: ShareInviteService,
        private router: Router,
        private route: ActivatedRoute,
        private pwdService: PasswordService,
        private JoblockService: JoblockService,
        private syncCookieService: SyncCookieService,
        private blendService: BlendService
    ) {}

    async ngOnInit() {
        this.shareReady = false;

        this.cachekey = this.route.snapshot.params['cachekey'];
        this.syncReferCode = this.route.snapshot.queryParams['_sync_refer'];
        this.loggerService.info(
            `Share invite non user cachekey: ${this.cachekey}`
        );
        if (this.route.snapshot.fragment) {
            this.wrapKey = this.route.snapshot.fragment;
        } else {
            this.loggerService.warn('No share password provided');
        }
        if (this.route.snapshot.queryParams['eid']) {
            this.username = this.base64Service.decode(this.route.snapshot.queryParams['eid']);
            this.loginUsername = this.username;
            this.loggerService.info(`Suggested email to use: ${this.username}`);
            if (this.userService.isAuthenticated()) {
                this.notificationsService.stopNotificationLoop();
                if (
                    this.wrapKey &&
                    this.userService.get('email') == this.username
                ) {
                    this.loggerService.info(
                        'We have all we need, redirect to invites page'
                    );
                    this.loggerService.info(
                        `Redirect /shares/invite/${this.cachekey}`
                    );
                    window.location.replace(`/shares/invite/${this.cachekey}#${this.wrapKey}`);
                    return;
                } else {
                    this.loggerService.warn(
                        'LOGGED IN AS SOMEONE ELSE, LOGOUT'
                    );
                    await this.authService.logout();
                }
            } else {
                this.loggerService.error('USER IS NOT LOGGED IN');
            }
        } else {
            this.loggerService.info('No email was passed to this share invite');
        }
        if (this.wrapKey) {
            await this.getInvite();
        }
        this.sub = this.authService.authState().subscribe((data) => {
            if (data.error) {
                this.loggerService.e(
                    'authState().subscribe - share provision - An error occurred ',
                    data.error
                );
            }
            if (
                data.authenticated &&
                data.user &&
                this.username == data.user.email &&
                this.shareReady
            ) {
                this.loggerService.info(
                    `authenticated and share ready ${this.username} - call success`
                );
                this.shareSuccess();
            }
        });
    }

    public setWrapKey() {
        this.spinners.invite = true;
        this.wrapKey = this.uWrapKey;
        this.getInvite();
    }

    private async getInvite() {
        const exists = await this.provisionService.emailExists(this.username);
        if (exists) {
            this.loggerService.info(
                'Email exists, user already provisioned, redirect to login'
            );
            this.redirectToLogin();
        } else {
            try {
                this.loggerService.info(
                    'Email does not exist in system, continue'
                );
                const data = await this.shareInviteService.getInvitePublic(
                    this.route.snapshot.params['cachekey'],
                    this.wrapKey
                );
                this.foldername = data.name;
                this.label = data.label;
                this.spinners.invite = false;
            } catch (err) {
                if (typeof err === 'object' && err.code && err.code == 1620) {
                    this.wrapKey = '';
                }
                this.errors.push(err);
            }
        }
    }

    public async provision() {
        this.submitted = true;
        if (!this.provisionForm.valid) {
            return;
        }
        this.errors = [];
        if (!this.pwdService.isPasswordStrong(this.password)) {
            this.errors.push({ code : 8304});
            return;
        }
        if (!this.terms) {
            this.errors.push({ code: 8300 });
            return;
        }
        if (this.password !== this.password2) {
            this.errors.push({ code: 8121 });
            return;
        }
        this.spinners.provision = true;
        try {
            const provuser = await this.provisionService.provision(
                'share', //source of provision
                this.username,
                this.password,
                this.syncReferCode,
                this.reset ? 1 : 0, // passreset
                1 // sso enable
            );
            // Awaiting this prevents the connection from being severed due to reroute
            await this.blendService.trackSignUp(provuser.user_id, {
                Signup_type: 'Free',
                product_type: 'Free',
                Signup_from: 'shareinvite',
                Attribution_Mtags: this.syncCookieService.getMarketingRef(),
                password_reset: this.reset,
            });
            this.authService.login(this.username, this.password);
            console.log(' ---- authenticate successful');
            this.shareReady = true;

            console.log(' ---- shareready true');
        } catch (err) {
            this.loggerService.error(
                'An error occurred provisioning or authenticating'
            );
            this.loggerService.error(err);
            this.spinners.provision = false;
            // errcode 8106 is that this email already exists
            if (typeof err == 'object' && err.code && err.code == 8106) {
                this.loggerService.info(
                    'This user already exists, showing login'
                );
                window.location.replace(
                    `/login?ctx=share&username=
                    ${this.base64Service.encode(this.username)}
                    &cachekey=${this.cachekey}#${this.wrapKey}`);

                this.errors.push({ code: 8302 });
            } else {
                this.errors.push(err);
            }
        }
    }

    public async login() {
        this.errors = [];
        this.spinners.login = true;
        try {
            await this.authService.authenticate(
                this.loginUsername,
                this.loginPassword
            );
            this.shareReady = true;
        } catch (err) {
            this.errors.push(err);
        }
    }

    private redirectToLogin() {
        window.location.replace(
            `/login?ctx=share&username=
            ${this.base64Service.encode(this.username)}
            &cachekey=${this.cachekey}#${this.wrapKey}`);
    }

    private async getInviteAccept() {
        this.loggerService.info(`Retrieve invite for ${this.cachekey}`);
        const inviteData = await this.shareInviteService.getInvite(
            this.cachekey,
            this.wrapKey
        );
        this.loggerService.info('Accepting the invite');
        inviteData.displayName = this.base64Service.decode(
            inviteData.displayName
        );
        return await this.shareInviteService.accept(this.wrapKey, 0, inviteData);
    }

    private async shareSuccess() {
        await this.getInviteAccept();
        this.spinners.login = false;
        this.spinners.provision = false;
        this.loggerService.info('Share has been joined successfully');
        this.router.navigate(['/shares'], { queryParams : undefined });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
