import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { SupportTicketService } from '../services/support-ticket.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { BlendEvent } from 'src/app/shared/models';

@Component({
    selector: 'sync-dialog-contact-support',
    templateUrl: './dialog-contact-support.component.html',
})
export class DialogContactSupportComponent implements OnInit {

    @ViewChild('contactForm') public contactForm: NgForm;
    public frmValues: { [k: string]: any };

    constructor(
        private userService: UserService,
        private base64Service: Base64Service,
        private loggerService: LoggerService,
        private supportTicketService: SupportTicketService,
        public activeModal: NgbActiveModal,
        public BlendService: BlendService
    ) { }

    ngOnInit() {
        this.frmValues = {
            requester: this.userService.get('email'),
            name: this.base64Service.decode(
                this.userService.get('display_name')
            ),
            subject: null,
            description: null,
            attachLogs: true
        };
    }

    public sendSupportTicket(): void {
        if (!this.contactForm.valid) { return; }
        const logs = this.frmValues.attachLogs
            ? this.loggerService.dumpLogsText('all')
            : 'Not provided\n';

        // This formatting is very important
        // support-submit.php splits the message
        // into sections delimited by '--------' for zendesk.
        // don't touch unless change support-submit
        const description = [
            'USER ID: ',
            this.userService.get('uid'),
            '--------',
            'Customer wrote:',
            this.frmValues.description,
            '--------',
            'Javascript logging:',
            logs,
            '--------',
        ].join('\n\n');
        const postData = {
            z_requester: this.frmValues.requester,
            z_name: this.frmValues.name,
            z_subject: this.frmValues.subject,
            z_description: description,
        };
        this.supportTicketService.sendSupportTicket(postData);
        this.BlendService.track(BlendEvent.CONTACT_SUPPORT, { 'logs': this.frmValues.attachLogs });
    }

    cancel() {
        this.activeModal.close();
        this.BlendService.track(BlendEvent.CANCEL_HELP);
    }
    syncKnowledgebase() {
        this.BlendService.track(BlendEvent.SYNC_KNOWLEDGEBASE);
    }
}
