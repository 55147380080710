<div class="modal-header">
    <button type="button" class="close" (click)="closeDialog()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Delete link {{link.name}}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            <sync-error [errcode]="errcode"></sync-error>
            This link will stop working.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        id="delete-link-dialog-btn"
        (click)="delete()"
        [disabled]="spinner"
    ><i class="fa fa-spin fa-spinner" *ngIf="spinner"></i> Delete link</button>
    <button type="button" class="btn btn-default" (click)="closeDialog()">Cancel</button>
</div>