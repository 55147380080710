<sync-header-previews [item]="item">
    <div class="hamburger-menu-item visible-landscape visible-xs" hamburgerMenu>
        <sync-preview-menu
            [item]="item"
            [url]="url"
        ></sync-preview-menu>
    </div>
</sync-header-previews>

<!-- CP TEMPLATE -->
<div
    *ngIf="item.context != 'link' && item.context != 'applink'"
    class="container-fluid container-fluid-wrap container-fluid-wrap-preview"
>
    <sync-preview-menu [item]="item" [url]="url"></sync-preview-menu>
    <div class="preview-wrap">
        <sync-error [errcode]="errcode"></sync-error>
        <div *ngIf="spinner">
            <i class="fa fa-spin fa-spinner"></i> Preparing for preview
        </div>
        <div *ngIf="sessionId && !accusoftErr">
            <iframe
                class="preview-iframe"
                frameborder="0"
                id="sync-viewer"
                [src]="viewUrl"
            ></iframe>
        </div>
        <div class="row" *ngIf="accusoftErr == 4001">
            <div
                class="col-sm-offset-2 col-md-offset-3 col-xs-12 col-sm-8 col-md-6"
            >
                <br /><br />
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">
                            <i class="fas fa-lock"></i> Enter password
                        </h3>
                    </div>
                    <div class="panel-body">
                        <p>
                            This document is password protected. If you do not
                            have a password you'll need to contact the person
                            who sent you the document.
                        </p>
                        <div class="form-group">
                            <input
                                autocomplete="off"
                                type="password"
                                [(ngModel)]="docPassword"
                                class="form-control"
                                syncShowHidePassword
                                identity="docpass"
                            />
                        </div>
                    </div>
                    <div class="panel-footer text-right">
                        <button
                            (click)="init(docPassword)"
                            [disabled]="!docPassword || spinner"
                            type="submit"
                            class="btn btn-primary"
                            *ngIf="!isWhiteLabel"
                        >
                            <i
                                class="fa fa-spin fa-spinner"
                                *ngIf="spinner"
                            ></i>
                            Submit
                        </button>
                        <!-- white label color -->
                        <button
                            (click)="init(docPassword)"
                            [disabled]="!docPassword || spinner"
                            type="submit"
                            class="btn"
                            *ngIf="isWhiteLabel"
                            [ngStyle]="{'background-color': buttonPrimaryColor,
                                        'border-color': buttonPrimaryColor,
                                        'color': buttonTextColor}"
                        >
                            <i
                                class="fa fa-spin fa-spinner"
                                *ngIf="spinner"
                            ></i>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /container -->
<!-- / CP TEMPLATE -->

<!-- LINK CONSUME TEMPLATE -->
<div
    *ngIf="item.context == 'link' || item.context == 'applink'"
    class="container-fluid preview-container"
>
    <div class="row">
        <!-- MAIN CONTENT COL -->
        <div class="col-md-8 col-lg-9 preview-file-margin">
            <div class="preview-wrap-no-margin">
                <sync-error [errcode]="errcode"></sync-error>

                <div *ngIf="spinner">
                    <i class="fa fa-spin fa-spinner"></i> Preparing for preview
                </div>
                <div *ngIf="sessionId && !accusoftErr">
                    <iframe
                        class="preview-iframe"
                        frameborder="0"
                        id="sync-viewer"
                        [src]="viewUrl"
                    ></iframe>
                </div>
                <div class="row" *ngIf="accusoftErr == 4001">
                    <div
                        class="col-sm-offset-2 col-md-offset-3 col-xs-12 col-sm-8 col-md-6"
                    >
                        <br /><br />
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title">
                                    <i class="fas fa-lock"></i> Enter password
                                </h3>
                            </div>
                            <div class="panel-body">
                                <p>
                                    This document is password protected. If you
                                    do not have a password you'll need to
                                    contact the person who sent you the
                                    document.
                                </p>
                                <div class="form-group">
                                    <input
                                        autocomplete="off"
                                        type="password"
                                        [(ngModel)]="docPassword"
                                        class="form-control"
                                        syncShowHidePassword
                                        identity="docpass"
                                    />
                                </div>
                            </div>
                            <div class="panel-footer text-right">
                                <button
                                    (click)="init(docPassword)"
                                    [disabled]="!docPassword || spinner"
                                    type="submit"
                                    class="btn btn-primary"
                                    *ngIf="!isWhiteLabel"
                                >
                                    <i
                                        class="fa fa-spin fa-spinner"
                                        *ngIf="spinner"
                                    ></i>
                                    Submit
                                </button>
                                <!-- white label color -->
                                <button
                                    (click)="init(docPassword)"
                                    [disabled]="!docPassword || spinner"
                                    type="submit"
                                    class="btn"
                                    *ngIf="isWhiteLabel"
                                    [ngStyle]="{'background-color': buttonPrimaryColor,
                                        'border-color': buttonPrimaryColor,
                                        'color': buttonTextColor}"
                                >
                                    <i
                                        class="fa fa-spin fa-spinner"
                                        *ngIf="spinner"
                                    ></i>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /MAIN CONTENT COL -->

        <div class="col-md-4 col-lg-3 xs-fixed-comment"
            [ngClass]="isCommentExpanded ? 'expanded' : ''">
            <sync-preview-menu
                [item]="item"
                [url]="url"
                class=" hidden-landscape hidden-xs"
            ></sync-preview-menu>
            <!-- comment feature -->
            <sync-comment-block
                *ngIf="allowComment"
                [item]="item"
                [ontoggle]="onCommentToggle"
            ></sync-comment-block>
        </div>
    </div>
</div>
<!-- /container -->
<!-- / LINK CONSUME TEMPLATE -->
