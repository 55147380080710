import { Component, OnInit, OnDestroy, ViewEncapsulation, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../../reducers';
import { Store } from '@ngrx/store';
import { User, ErrCode, PermissionActions } from '../../../models';
import { LoggerService } from '../../../../core/logger.service';
import { ValidateService } from '../../../../core/validate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../core/user.service';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZuoraAccountService } from '../../../../account/services/zuora-account.service';
import { zAccountInfo } from '../../../models/api/orderprofile-api.model';
import { ShareInviteService } from '../../../../shares/share-invite.service';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
    selector: 'sync-header-shares-accept',
    templateUrl: './header-shares-accept.component.html',
    styleUrls: ['./header-shares-accept.component.css'],
    encapsulation: ViewEncapsulation.None

})
export class HeaderSharesAcceptComponent implements OnInit, OnDestroy {

    public pendingShares: number; // user
    public eventCount: number; // user

    public user: User;
    private subscription: Subscription;

    public validate: sync.IValidateService;
    public errcode: ErrCode;

    private cachekey: string;
    private wrapKey: string;
    private shareId: number;

    //white label attributes
    public isWhiteLabel: boolean;
    public imageCachekey: string;
    public headerPrimaryColor: string;
    public headerTextColor: string;
    public whitelabelLoaded: boolean;
    public logoHost: string;
    public permissionActions = PermissionActions;
    public zprofile: zAccountInfo;

    constructor(
        private store: Store<fromRoot.State>,
        private Validate: ValidateService,
        private logger: LoggerService,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private modalService: NgbModal,
        private zuora: ZuoraAccountService,
        private shareInviteService: ShareInviteService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.subscription = this.store.select(fromRoot.getAuthUser)
            .subscribe((data) => {
                this.user = data;
            });
        this.validate = this.Validate;
        this.cachekey = this.route.snapshot.params['invite_id'];
        this.shareId = parseInt(this.route.snapshot.queryParams['share_id'], 10);
        this.wrapKey = this.route.snapshot.fragment;
        if (!this.cachekey) {
            this.logger.error('The url is missing the invite id');
            this.errcode = new ErrCode(1702);
        } else {
            this.shareInviteService.getInvite(this.cachekey, this.wrapKey, this.shareId)
                .then((result) => {
                    //load white label attributes
                    if (result.image_cachekey) {
                        this.imageCachekey = result.image_cachekey;
                        this.headerPrimaryColor = result.header_primary_color;
                        this.headerTextColor = result.header_text_color;
                        this.logoHost = environment.logohost;
                        this.whitelabelLoaded = true;
                    }
                });
        }
        this.loadProfile();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer.bypassSecurityTrustStyle(`--headerPrimaryColor: ${this.headerPrimaryColor};
                                                        --headerTextColor: ${this.headerTextColor}`);
    }

    public onUsersClick() {
        if ((this.user.is_multi_admin || !this.user.is_multi_admin) &&
            !this.user.is_multi_child &&
            !this.user.is_multi_new
        ) {
            this.logger.info('Using old multiuser');
            this.router.navigate(['/multi-user']);
        } else if (
            !this.user.is_multi_admin &&
            this.user.is_multi_child &&
            this.user.is_multi_new
        ) {
            this.logger.info('Using new multiadmin');
            if (!this.checkPermission(this.permissionActions.VIEW_USERS) && this.checkPermission(this.permissionActions.VIEW_ROLES)) {
                this.router.navigate(['/multi-user/roles']);
            } else {
                this.router.navigate(['/multi-user/users']);
            }
        }
    }

    public onUpgradeClick() {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        } else {
            this.router.navigate(['/account/upgrade']);
        }
    }

    private async loadProfile() {
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.zprofile = await this.zuora.getProfile();
        }
    }

}
