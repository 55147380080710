import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoggerService } from 'src/app/core/logger.service';
import { FileItemService } from '../file-item.service';
import videojs from 'video.js';
import { Subscription } from 'rxjs';
import 'video.js/dist/video-js.css';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { BlendEvent } from '../../shared/models';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-preview-video',
    templateUrl: './preview-video.component.html',
    styles: ['./preview-video.component.css'],
})
export class PreviewVideoComponent implements OnInit, OnDestroy {
    public type: string; // binding from component.
    public transferItem: sync.ITransferItemDownload;
    public content: string;
    @Input() public item: sync.IFile;
    public spinner = false;
    public errorMsg: string;
    public url: string;
    public id: any;
    public allowComment: 0 | 1 | 2;
    public isCommentExpanded: boolean;
    @Input() videoUrl: string;
    @Input() mimeType: string;
    private player: any;
    private sub: Subscription;

    // private sub: Subscription;
    constructor(
        private loggerService: LoggerService,
        private fileItemService: FileItemService,
        private linkPathListService: LinkFileListService,
        private blendService: BlendService
    ) {}

    ngOnInit() {
        try {
            this.sub = this.linkPathListService
                .getSubscription()
                .subscribe((data) => {
                    if (data.loaded && data.sorted) {
                        this.allowComment = data.allow_comment;
                    }
                });

            this.url = this.videoUrl;

            this.loggerService.info(`url : ${this.url}`);
            this.loggerService.info(`mimeType : ${this.mimeType}`);

            const options = {
                maxWidth: 700,
                maxHeight: 500,
                aspectRatio: '16:8',
                fluid: true,
                responsive: true,
                preload: 'auto',
                controls: true,
                autoplay: true,
                overrideNative: true,
                techOrder: ['html5', 'flash'],
                html5: {
                    nativeVideoTracks: false,
                    nativeAudioTracks: false,
                    nativeTextTracks: false,
                    hls: {
                        withCredentials: false,
                        overrideNative: true,
                        debug: true,
                    },
                },
                sources: [
                    {
                        src: this.url,
                        type: this.mimeType,
                    },
                ],
                playbackRates: [0.5, 1, 1.5, 2, 3],
                controlBar: {
                    skipButtons: {
                        forward: 10,
                    },
                },
            };

            this.player = videojs('my-video', options);
            this.blendService.track(BlendEvent.VIEW_FILE, {
                type: 'video',
                preview_preference: 'N/A',
                fileSize: this.item.filesize,
                mimeType: this.item.mime_type
            });
        } catch (err) {
            this.loggerService.error(`video.js error ${err}`);
        }
    }
    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
