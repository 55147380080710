import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any, sortBy: string, reverse?: boolean): any[] {
    const sortOrder = reverse ? 'des' : 'asc'; // setting default ascending order
    if (array && Array.isArray(array)) {
      if (sortOrder == 'asc') {
        return array.sort((a, b) => a[sortBy] < b[sortBy] ? -1 : 1);
      } else {
        return array.sort((a, b) => a[sortBy] < b[sortBy] ? 1 : -1);
      }
    } else {
      return array;
    }
  }

}
