import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SyncCookieService {


    constructor(
        private cookies: CookieService,
    ) { }


    public setViewedLink() {
        const expDate = new Date();
        expDate.setMonth(expDate.getMonth() + 3);
        this.cookies.set(
            'sync_link',
            '1',
            expDate,
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }

    public setLinkPassword(linkId: string, linkPassword: string) {
        const expDate = Date.now() + 600000; // 10 minutes
        this.cookies.set(
            `linkpassword-${linkId}`,
            linkPassword,
            new Date(expDate),
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }
    public getLinkPassword(linkId: string) {
        return this.cookies.get(`linkpassword-${linkId}`);
    }
    public deleteLinkPassword(linkId: string) {
        return this.cookies.delete(`linkpassword-${linkId}`, '/');
    }
    public setOrderSessionId(sessionId: string) {
        const expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set(
            `sync-order-session-id`,
            sessionId,
            new Date(expDate),
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }
    public setOrderSessionHashData(saltData: string) {
        const expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set(
            `sync-order-session-hash-data`,
            saltData,
            new Date(expDate),
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }
    public setOrderSessionSaltData(saltData: string) {
        const expDate = Date.now() + 10800 * 1000; // 3 hours
        this.cookies.set(
            `sync-order-session-salt-data`,
            saltData,
            new Date(expDate),
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }
    public setDownloadPubLink(passwordlock: string) {
        this.cookies.set(
            'passwordlock',
            encodeURIComponent(passwordlock),
            undefined,
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }

    public setDownloadPubUser(signature: string) {
        this.cookies.set(
            'signature',
            signature,
            undefined,
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }

    public setThumbnailSignature(signature: string) {
        this.cookies.set(
            'thumbnail_sign',
            signature,
            undefined,
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }

    public setDisableAnalytics(disabled: string) {
        this.cookies.set(
            'is_disableanalytics',
            disabled,
            undefined,
            '/',
            environment.cookiedomain,
            environment.cookiesecure,
            'Lax'
        );
    }

    public deleteCookie(name: string) {
        this.cookies.delete(name, '/', environment.cookiedomain);
    }

    public getMarketingRef() {
        return this.cookies.get('_sync_mktg');
    }
}
