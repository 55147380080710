export const DELETE_REASONS = {
    'I\'m unhappy with my plan or pricing': [
        'My plan is too expensive',
        'My plan doesn\'t offer enough storage',
        'Sync doesn\'t provide enough value'
    ],
    'I\'m having problems with the Sync\'s Desktop App': [
        'Uploading is too slow',
        'Downloading is too slow',
        'Syncing files between my devices is not reliable or not working',
        'Sharing files with others is not reliable or not working',
        'The desktop app crashes',
        'Other'
    ],
    'I\'m having problems with the Sync\'s Web Panel': [
        'Uploading is too slow',
        'Downloading is too slow',
        'Syncing files between my devices is not reliable or not working',
        'Sharing files with others is not reliable or not working',
        'Sync doesn\'t work on my browser',
        'Other'
    ],
    'I\'m having problems with the Sync\'s Mobile App': [
        'Uploading is too slow',
        'Downloading is too slow',
        'Syncing files between my devices is not reliable or not working',
        'Sharing files with others is not reliable or not working',
        'Sync doesn\'t work on my mobile device',
        'Other'
    ],
    'I find Sync confusing to use or set up': [
        'I have a hard time finding features I need on Sync',
        'I have a hard time completing tasks on Sync',
        'Sync\'s interface is not user friendly',
        'Sync is not compatible with my devices'
    ],
    'I need features that Sync doesn\'t have': [
        'File storage/organization features',
        'Security features',
        'Sharing & collaboration features',
        'User administration features',
        'Other'
    ],
    'I can\'t get the help I need': [
        'I need more ways of getting help',
        'The Help Centre articles are not useful',
        'Getting help is too slow',
        'I\'m not satisfied with the quality of help'
    ],
    'I have an issue with how Sync handles security': [
        'I have concerns with Sync\'s security and privacy policies',
        'Sync doesn\'t comply with regulations I need',
        'Other'
    ],
    'I don\'t use Sync anymore': [
        'I\'ve moved to a different product',
        'I\'m using local or offline file storage'
    ],
    // "Other": []
};
