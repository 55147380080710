import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'sync-dialog-confirm-ownership-change',
    templateUrl: './dialog-confirm-ownership-change.component.html'
  })
export class DialogConfirmOwnershipChangeComponent implements OnInit {

    @Input('shareData') shareData: sync.IShareData;
    constructor(
        public activeModal: NgbActiveModal,
        // @Inject('$state') private $state: StateService,
    ) {}
    ngOnInit(): void {}
    onConfirm() {
        this.activeModal.close(true);
    }
    onCancel() {
        this.activeModal.close(false);
    }
}
