import { Action } from '@ngrx/store';

import { ErrCode } from '../shared/models';
import {
    LinkPathListApiInput,
    LinkPathListApiOutput,
} from '../shared/models/api';

export const LINK_FILE_LIST_RESET = '[LinkFileList] Reset';
export const LINK_FILE_LIST_REFRESH = '[LinkFileList] Refresh';
export const LINK_FILE_LIST_LOAD = '[LinkFileList] Load';
export const LINK_FILE_LIST_LOAD_APP = '[LinkFileList] Load App';
export const LINK_FILE_LIST_CD = '[LinkFileList] cd';
export const LINK_FILE_LIST_ERROR = '[LinkFileList] Error';
export const LINK_FILE_LIST_SUCCESS = '[LinkFileList] Success';
export const LINK_FILE_LIST_SORT = '[LinkFileList] Sort';
export const LINK_FILE_LIST_SET_PASSWORD = '[LinkFileList] Set Password';
export const LINK_FILE_LIST_LOAD_WHITELABEL = '[LinkFileList] Load WhiteLabel';
export const LINK_FILE_LIST_SUCCESS_WHITELABEL =
    '[LinkFileList] WhiteLabel Success';
export const IFRAME_READY = '[LinkFileList] IFrameReady';

export class ResetAction implements Action {
    readonly type = LINK_FILE_LIST_RESET;
    constructor() {}
}
export class SuccessAction implements Action {
    readonly type = LINK_FILE_LIST_SUCCESS;
    constructor(public payload: LinkPathListApiOutput) {}
}

export class RefreshAction implements Action {
    readonly type = LINK_FILE_LIST_REFRESH;
    constructor() {}
}
export class LoadAction implements Action {
    readonly type = LINK_FILE_LIST_LOAD;
    constructor(public payload: LinkPathListApiInput) {}
}
export class LoadAppAction implements Action {
    readonly type = LINK_FILE_LIST_LOAD_APP;
    constructor(
        public payload: {
            sync_id: number;
            publink_id: string;
            passwordlock?: string;
        }
    ) {}
}
export class LoadWhiteLabelAction implements Action {
    readonly type = LINK_FILE_LIST_LOAD_WHITELABEL;
    constructor(public payload: string) {}
}
export class WhiteLabelSuccessAction implements Action {
    readonly type = LINK_FILE_LIST_SUCCESS_WHITELABEL;
    constructor(public payload: LinkPathListApiOutput) {}
}
export class ChangeDirAction implements Action {
    readonly type = LINK_FILE_LIST_CD;
    constructor(public payload: number) {}
}
export class ErrorAction implements Action {
    readonly type = LINK_FILE_LIST_ERROR;
    constructor(public payload: ErrCode) {}
}

export class IFrameReadyAction implements Action {
    readonly type = IFRAME_READY;
    constructor(public ready: boolean) {}
}

export class SortAction implements Action {
    readonly type = LINK_FILE_LIST_SORT;
    constructor(public payload: any) {}
}

export class SetPasswordAction implements Action {
    readonly type = LINK_FILE_LIST_SET_PASSWORD;
    constructor(public payload: string) {}
}

export type All =
    | ResetAction
    | LoadAction
    | ChangeDirAction
    | RefreshAction
    | SuccessAction
    | ErrorAction
    | SortAction
    | SetPasswordAction
    | LoadWhiteLabelAction
    | WhiteLabelSuccessAction
    | IFrameReadyAction
    | LoadAppAction;
