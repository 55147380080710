import { Injectable } from '@angular/core';

import { CryptBufferService } from './crypt-buffer.service';
import * as sjcl from 'sjcl';

@Injectable()
export class SyncDigestService {

    constructor(private crypt: CryptBufferService) { }

    public hash(str: string) {
        // console.log(sjcl);
        return sjcl.codec.hex.fromBits(sjcl.hash.sha1.hash(str));
    }

    public hash256(str: string): string {
        return sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(str));
    }

    public init() {
        return this.crypt.sha1init();
    }
    public reset(shaObj: any) {
        return this.crypt.sha1reset(shaObj);
    }
    public update(shaObj: any, data: ArrayLike<number>) {
        return this.crypt.sha1update(shaObj, data);
    }
    public finish(shaObj: any) {
        return this.crypt.sha1finish(shaObj);
    }
}
