import { Directive, HostListener, Input } from '@angular/core';
import { UserService } from '../core/user.service';
import { User } from '../shared/models';
import { FileItemService } from '../file/file-item.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WopiOptinComponent } from '../file/wopi-optin/wopi-optin.component';
import { BroadcastService } from '../shared/services/broadcast.service';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * @description
 * Binds a click event to the element and when clicked, will activate
 * the item.
 *
 * Depending on the path item object passed in, the activation will result in
 * different default behaviors.  Directories will be entered, files will
 * be downloaded and images will be previewed.
 */

@Directive({
    selector: '[syncPathActivateAction]'
})
export class PathActivateActionDirective {
    @Input('syncPathActivateAction') item: sync.IFile; // set from directive;
    public user: User;
    public is_starred: boolean;

    constructor(
        private userService: UserService,
        private fileItemService: FileItemService,
        private modalService: NgbModal,
        private broadcastService: BroadcastService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    @HostListener('click', ['$event']) click(event) {
        this.execute(this.item);
    }

    public execute(item: sync.IFile) {
        const prev = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.broadcastService.broadcast('event:hide.tools');
        this.user = this.userService.getUser();
        this.is_starred = this.router.url.includes('/starred') ? true : false;
        const ctx =
        item.context == 'link' || item.context == 'applink' ? 'p' : 'u';
        let type = 'default';
        if (item.type == 'dir') {
            if (this.is_starred) {
                if (item.rootpath == 'vault') {
                    this.router.navigate([`/vault/${item.sync_id}`]);
                } else {
                    this.router.navigate([`/files/${item.sync_id}`]);
                }
                return;
            }
            this.router.navigate([[], item.sync_id], { queryParams: { page: undefined }, queryParamsHandling: 'merge' }).then(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = prev;
            });
            return;
        } else if (this.fileItemService.isImage(item)) {
            type = 'image';
        } else if (this.fileItemService.isText(item)) {
            type = 'text';
        } else if (
            this.fileItemService.isPDF(item) &&
            (item.context == 'link' || item.context == 'applink') &&
            item.previewonly
        ) {
            type = 'doc';
        } else if (
            this.fileItemService.isPDF(item) &&
            item.context == 'files'
        ) {
            type = 'doc';
        } else if (this.display_office_optin(item)) {
            const ref = this.modalService.open(WopiOptinComponent , {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static',
            });

          return;
        } else if (this.display_office(item)) {
            type = 'office';
        } else if (this.display_doc(item)) {
            type = 'doc';
        } else {
            type = 'default';
        }
        this.router.navigate(['/file', item.sync_id, 'view', type],
            {
                queryParams: {
                    view: this.route.snapshot.queryParams['view'],
                    page: this.route.snapshot.queryParams['page']
                }
            }).then(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = prev;
            });
        return;
    }

    private display_doc(item: sync.IFile) {
        return (
            this.fileItemService.isDocument(item) &&
            // only compat links
            ((item.compat &&
                (item.context == 'link' || item.context == 'applink')) ||
                item.context == 'files')
        );
    }

    private display_office(item: sync.IFile) {
        return (
            this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_office === 1
        );
    }

    private display_office_optin(item: sync.IFile) {
        return (
            this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_displayedofficeoptin === 0 &&
            this.user.can_preview === 1
        );
    }
}
