import { Injectable } from '@angular/core';
import { LoggerService } from '../logger.service';
import { ErrCode } from '../../shared/models';

@Injectable({
    providedIn: 'root'
})
export class StorageFilesystemService implements sync.IStorageMethod {
    public maxsize = 0; // no maximum size

    private dirname = 'syncpreview';
    private options: {[key: string]: sync.IStorageOpts} = {};

    constructor(
        private log: LoggerService
    ) { }



    public async init(initOpts: sync.IStorageOpts) {
        const key = `${initOpts.blobtype}-${initOpts.cachekey}`;
        this.dirname = initOpts.dirname;
        this.options[key] = {
            dirname: initOpts.dirname,
            filesize: initOpts.filesize,
            filename: initOpts.filename,
            cachekey: initOpts.cachekey,
            blobtype: initOpts.blobtype,
            mimetype: initOpts.mimetype,
            path: `${initOpts.dirname}/${key}`,
            storetype: await this.getSpace(initOpts.filesize)
        };
    }

    public async test() {
        const key = 'btFILE-000test000';
        this.dirname = 'syncpreview';
        this.options[key] = {
            dirname: 'syncpreview',
            filesize: 1,
            filename: 'filename.jpg',
            cachekey: '000test000',
            blobtype: 'btFILE',
            mimetype: '',
            path: 'syncpreview/' + key,
            storetype: 0
        };

        const storeType = await this.getSpace(this.options[key].filesize);
        this.options[key].storetype = storeType;
        await this.getFS(storeType, this.options[key]);
        const bytearray = [0];
        await this.writeChunk(this.options[key], bytearray, 0);
    }

    public async clearSpace(type: number) {
        const list: Entry[] = [];
        return await new Promise<void>((resolve, reject) => {
            this.queryUsageAndQuota(type, (used, remain) => {
                if (used + remain > 0) {
                    this.getFileSystem(type, 1024, (fs) => {
                        fs.root.getDirectory(
                            this.dirname,
                            {create: true},
                            (dirEntry) => {
                                this.log.info('Clear space found root dir');
                                const dirReader = dirEntry.createReader();
                                dirReader.readEntries(async (entries) => {
                                    const len = entries.length;
                                    if (!len || len == 0) {
                                        this.log.info('The dir is empty');
                                        resolve();
                                    }
                                    for (let i = 0; i < len; i++) {
                                        const entry = entries[i];
                                        if (entry.isFile) {
                                            list.push(entry);
                                        }
                                    }
                                    await this.deleteFiles(list)
                                        .then(() => resolve(), (err) => reject(err));
                                }, (err) => reject(err));
                            }, (err) => reject(err));
                    }, (err) => reject(err));
                } else {
                    this.log.info('Cleared all possible');
                    resolve();
                }
            }, (err) => reject(err));
        });
    }

    public exists(tItem: sync.ITransferItemDownload) {
        return Promise.reject();
    }

    public async getRenderData(tItem: sync.ITransferItemDownload) {
        const key = `${tItem.blobtype}-${tItem.cachekey}`,
            opts = this.options[key];
        return await new Promise<sync.IRenderData>((resolve, reject) => {
            this.getFileSystem(opts.storetype, tItem.filesize, (fs) => {
                fs.root.getFile(opts.path, {}, (fileEntry) => {
                    resolve({
                        type: 'url',
                        url: fileEntry.toURL(),
                        blob: null
                    });
                }, (err) => reject(err));
            }, (err) => reject(err));
        });
    }

    public async writeChunk(tItem: (sync.ITransferItemDownload|sync.IStorageOpts), bytearray: number[]|Uint8Array[], offset: number, hasCleaned?: boolean) {
        const time = Date.now(),
            key = `${tItem.blobtype}-${tItem.cachekey}`,
            opts = this.options[key];
        hasCleaned = !!hasCleaned;
        return await new Promise((resolve, reject) => {
            this.getFileSystem(opts.storetype, tItem.filesize, (fs) => {
                fs.root.getFile(opts.path, {create: true}, (fileEntry) => {
                    fileEntry.createWriter((writer) => {
                        let truncated = false;
                        let blob: Blob;
                        writer.onwriteend = () => {
                            if (!truncated) {
                                const truncsize = (tItem.blobtype != 'btFILE')
                                    ? tItem[tItem.blobtype + 'size']
                                    : opts.filesize;
                                this.log.info(' - truncate - ' + truncsize);
                                truncated = true;
                                writer.truncate(truncsize);
                            }
                            this.log.info(`WRITE OP ${(Date.now() - time)} ms write data`);
                            blob = null;
                            resolve();
                        };
                        writer.onerror = (e) => {
                            this.log.info(writer.error.toString());
                            this.log.error(`Write failed ${writer.error.name}`);
                            if (!hasCleaned && writer.error.name == 'QuotaExceededError') {
                                this.log.info('Clearing space');
                                return this.clearSpace(opts.storetype)
                                    .then(() => this.writeChunk(tItem, bytearray, offset, hasCleaned));
                            } else {
                                reject(new ErrCode(3101));
                            }
                        };
                        if (typeof bytearray == 'object' && bytearray instanceof Uint8Array) {
                            blob = new Blob([bytearray], {type: 'application/octet-binary'});
                        } else {
                            blob = new Blob(<any>bytearray, {type: 'application/octet-binary'});
                        }
                        if (tItem.blobtype != 'btFILE') {
                            tItem[tItem.blobtype + 'size'] = blob.size;
                        }
                        writer.seek(offset);
                        writer.write(blob);

                    }, (err) => {
                        this.log.error('Error creating a filewriter');
                        reject(new ErrCode(3112));
                    });
                }, (err) => {
                    this.log.error('Error getting a file entry for ' + opts.path);
                    this.log.error(err.toString());
                    reject(new ErrCode(3111));
                });

            }, (err) => reject(err));
        });
    }

    /**
     * Recursively delete a file from the list.
     *
     * This will take an array of objects from the list and then shift off
     * items to delete them.  It will call itself again passing the deferred
     * object, which will only be resolved once completed.
     */
    private async deleteFiles(list: Entry[]) {
        return await new Promise<void>((resolve, reject) => {
            if (!list || !list.length) {
                resolve();
                return;
            }

            const entry = list.shift();
            if (!entry) {
                resolve();
                return;
            }
            entry.getMetadata((meta) => {
                // account for file's just downloaded or in process of moving from
                // FileSystem storage to the user's disk + 60sec
                const deltime = meta.modificationTime.getTime() +  + meta.size / 1024 + 60000;
                if (deltime < Date.now()) {
                    this.log.warn(`${entry.fullPath} attempt deletion`);
                    entry.remove(() => {
                        this.log.warn(`Deleted ${entry.fullPath}, size: ${meta.size}`);
                        return this.deleteFiles(list);
                    }, (err) => reject(err));
                } else {
                    this.log.warn(`Skipping ${entry.fullPath} because it's too new`);
                    return this.deleteFiles(list);
                }
            }, (err) => reject(err));
        });
    }

    private async getSpace(size: number, hasCleaned?: boolean) {
        const reqBytes = size,
            tempReqBytes = size * 6;
        // first pass hasCleaned is false
        hasCleaned = !!hasCleaned;
        // debugging clearSpace() function
        // return this.clearSpace().then(() => {
        //     this.getUsage().then((quotas) => {
        //         Logger.info('PERSISTENT storage exists');
        //         Logger.info(' - Persistent used: ' + quotas.pUsed);
        //         Logger.info(' - Persistent remain: ' + quotas.pRemain);
        //         Logger.info(' - Persistent total: ' + quotas.pTotal);
        //         Logger.info(' - Temporary used: ' + quotas.tUsed);
        //         Logger.info(' - Temporary remain: ' + quotas.tRemain);
        //         Logger.info(' - Temporary total: ' + quotas.tTotal);
        //         Logger.info(' - ' + quotas.tTotal + ' > ' + tempReqBytes);
        //     });
        // });
        this.log.info(' Requesting disk space storage');
        this.log.info('   size: ' + size);
        this.log.info('   temp storage needed: ' + tempReqBytes);

        const quotas = await this.getUsage();
        if (quotas.pTotal > 0) {
            this.log.info('PERSISTENT storage exists');
            this.log.info(' - Persistent used: ' + quotas.pUsed);
            this.log.info(' - Persistent remain: ' + quotas.pRemain);
            this.log.info(' - Persistent total: ' + quotas.pTotal);
            if (quotas.pRemain < reqBytes && !hasCleaned) {
                await this.clearSpace(window.PERSISTENT);
                return await this.getSpace(size, !hasCleaned);
            } else {
                if (quotas.pRemain > reqBytes) {
                    this.log.info('Persistent storage should accomodate this');
                    return window.PERSISTENT;
                } else if (quotas.tTotal > tempReqBytes && !hasCleaned) {
                    this.log.info('Temporary space should accomodate, clearing space');
                    await this.clearSpace(window.TEMPORARY);
                    return await this.getSpace(size, !hasCleaned);
                } else {
                    this.log.info('Asking for 2^40 bytes persistent');
                    return await this.requestQuota(reqBytes);
                }
            }
        } else {
            this.log.info('PERSISTENT does not exist, check if temp can hold');
            this.log.info(' - Temporary used: ' + quotas.tUsed);
            this.log.info(' - Temporary remain: ' + quotas.tRemain);
            this.log.info(' - Temporary total: ' + quotas.tTotal);
            this.log.info(' - ' + quotas.tTotal + ' > ' + tempReqBytes);

            if (quotas.tRemain > tempReqBytes) {
                this.log.info('Temporary storage should accomodate this');
                return window.TEMPORARY;
            } else if (quotas.tTotal > tempReqBytes && !hasCleaned) {
                await this.clearSpace(window.TEMPORARY);
                return await this.getSpace(size, !hasCleaned);
            } else {
                this.log.info('Failed getting temporary space, asking for persistent');
                return await this.requestQuota(reqBytes);
            }

        }
    }


    private async getUsage() {
        return await new Promise<FileSystemUsageData>((resolve, reject) => {
            this.queryUsageAndQuota(window.TEMPORARY, (tUsed, tRemain) => {
                this.queryUsageAndQuota(window.PERSISTENT, (pUsed, pRemain) => {
                    resolve({
                        pUsed: pUsed,
                        pRemain: pRemain,
                        pTotal: (pUsed + pRemain),
                        tUsed: tUsed,
                        tRemain: tRemain,
                        tTotal: (tUsed + tRemain)
                    });
                }, (err) => reject(err));
            }, (err) => reject(err));
        });
    }

    private async getFS(type: number, initOpts: sync.IStorageOpts) {
        return await new Promise<FileEntry>((resolve, reject) => {
            this.log.d('attempting getFS for type = ' + type);
            this.log.d(initOpts);
            this.getFileSystem(type, initOpts.filesize, (fs) => {
                this.log.d('getFileSystem returned success');
                fs.root.getDirectory(
                    initOpts.dirname,
                    {create: true},
                    () => {
                        fs.root.getFile(
                            initOpts.path,
                            {create: true},
                            (res) => {
                                resolve(res);
                            },
                            (err) => {
                                this.log.e('reject getFS', err);
                                reject(err);
                            }
                        );
                    }, (err) => {
                        this.log.e('reject getDirectory', err);
                        reject(err);
                    }
                );
            }, (err) => {
                this.log.e('Error getting filesystem', err);
                reject(err);
            });

        });
    }

    private getFileSystem(type: number, size: number, cb: FileSystemCallback, errCb?: ErrorCallback): void {
        if (window.webkitRequestFileSystem) {
            window.webkitRequestFileSystem(type, size, cb, errCb);
        } else if (window.requestFileSystem) {
            window.requestFileSystem(type, size, cb, errCb);
        } else {
            this.log.error('requestFileSystem does not exist on window');
        }
    }

    private queryUsageAndQuota(
            type: number,
            cb: StorageUsageCallback,
            errCb: ErrorCallback
    ) {
        const str = (type == window.TEMPORARY) ? 'Temporary' : 'Persistent';
        const navKey = `webkit${str}Storage`;
        if (navigator[navKey]) {
            return navigator[navKey].queryUsageAndQuota(cb, errCb);
        } else if (window.webkitStorageInfo) {
            return window.webkitStorageInfo.queryUsageAndQuota(type, cb, errCb);
        } else {
            this.log.error('queryUsageAndQuota: no methods found');
        }
    }

    private async requestQuota(reqBytes: number) {
        const size = 1099511627776;
        return await new Promise<number>((resolve, reject) => {
            const cb = (grantedBytes: number) => {
                if (grantedBytes === 0) {
                    this.log.error('We were granted 0 bytes');
                    reject(new ErrCode(3100));
                } else if (reqBytes > grantedBytes) {
                    this.log.error('The requested byets are greater than the granted byets');
                    this.log.error(`Asked for ${reqBytes}, granted ${grantedBytes} `);
                    reject(new ErrCode(3100));
                } else {
                    this.log.info('PERSISTENT storage chosen');
                    resolve(window.PERSISTENT);
                }
            };
            const errCb = (err: any) => {
                reject(err);
            };
            if (navigator['webkitPersistentStorage']) {
                navigator['webkitPersistentStorage'].requestQuota(size, cb, errCb);
            } else if (window.webkitStorageInfo) {
                window.webkitStorageInfo.requestQuota(window.PERSISTENT, size, cb, errCb);
            } else {
                this.log.error('requestQuota: no methods found');
            }
        });
    }

    // private getOpt(initOpts: sync.IStorageOpts) {
    //     if (!initOpts.blobtype || !initOpts.cachekey) {
    //         this.log.error(`Missing blobtype ${initOpts.blobtype}-${initOpts.cachekey}`);
    //     }
    //     let key = `${initOpts.blobtype}-${initOpts.cachekey}`;
    //     if (!this.options[key]) {
    //         this.log.error('getOpt called without running init() first');
    //         alert('A fatal error occurred, please reload the page');
    //     }
    //     return this.options[key];
    // }

}

type StorageUsageCallback = (used: number, remain: number) => void;


interface FileSystemUsageData {
    pUsed: number;
    pRemain: number;
    pTotal: number;
    tUsed: number;
    tRemain: number;
    tTotal: number;
}
