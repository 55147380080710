import { Component, OnInit, Input } from '@angular/core';
import { User, ErrCode, BlendEvent } from '../../shared/models';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SupportTicketService } from '../services/support-ticket.service';
import { Base64Service } from '../../core/base64.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';
import { environment } from '../../../environments/environment';
import { BlendService } from '../../shared/services/blend.service';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { DELETE_REASONS } from 'src/app/shared/models/delete-reasons.model';
import { SkuService } from 'src/app/shared/services/sku.service';
@Component({
    selector: 'sync-dialog-cancel',
    templateUrl: './dialog-cancel.component.html',
})
export class DialogCancelComponent implements OnInit {
    @Input() user: User;
    @Input() userId: number;
    @Input() plan: number;
    @Input() sig: string;
    @Input() isAccthold: boolean;

    public cancelForm: FormGroup;
    public errcode: ErrCode;
    public spinner: boolean;
    private paidUser: boolean;
    private sub: Subscription;
    private store: Store<fromRoot.State>;
    public deleteReasons = DELETE_REASONS;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private base64: Base64Service,
        private fb: FormBuilder,
        private ticket: SupportTicketService,
        private blendService: BlendService,
        private skuService: SkuService
    ) {}

    ngOnInit() {
        if (this.isAccthold) {
            this.createAcctHoldForm();
            return;
        }
        if (this.user) {
            this.paidUser = this.user.is_pro === 1;
            if (this.sub) {
                this.sub.unsubscribe();
            }
        } else {
            this.sub = this.store
                .pipe(select(fromRoot.getAuthUser))
                .subscribe((data) => {
                    this.user = data;
                    this.paidUser = this.user.is_pro === 1;
                    if (this.sub) {
                        this.sub.unsubscribe();
                    }
                });
        }
        this.createForm();
    }

    private createForm() {
        this.cancelForm = this.fb.group({
            name: [
                this.base64.decode(this.user.display_name),
                Validators.required,
            ],
            email: [this.user.email, Validators.required],
            mainReason: ['', Validators.required],
            specificReason: [''],
            description: [''],
            confirm: ['', Validators.required],
        });
        this.setupValidators();
    }

    private createAcctHoldForm() {
        this.cancelForm = this.fb.group({
            mainReason: ['', Validators.required],
            specificReason: [''],
            description: [''],
            confirm: ['', Validators.required],
        });
        this.setupValidators();
    }

    private setupValidators() {
        this.cancelForm.get('mainReason').valueChanges.subscribe(() => {
            if (this.cancelForm.get('mainReason').value == 'Other') {
                this.cancelForm.controls['description'].setValidators([Validators.required]);
                this.cancelForm.controls['specificReason'].clearValidators();
            } else {
              this.cancelForm.controls['description'].clearValidators();
              this.cancelForm.controls['specificReason'].setValidators(Validators.required);
              this.cancelForm.controls['specificReason'].setValue('');
            }
            this.cancelForm.controls['description'].updateValueAndValidity();
            this.cancelForm.controls['specificReason'].updateValueAndValidity();
          });

        this.cancelForm.get('specificReason').valueChanges.subscribe(val => {
            if (this.cancelForm.get('mainReason').value == 'Other' || this.cancelForm.get('specificReason').value == 'Other') {
                this.cancelForm.controls['description'].setValidators([Validators.required]);
            } else {
              this.cancelForm.controls['description'].clearValidators();
            }
            this.cancelForm.controls['description'].updateValueAndValidity();
          });
    }

    public async onSubmit() {
        this.spinner = true;
        const reason = this.cancelForm.get('mainReason').value;
        const specificReason = this.cancelForm.get('specificReason').value || 'N/A';
        const seats = await this.skuService.getPanLimit();
        const plan = await this.skuService.getSku();
        await new Promise((resolve) => {
            this.blendService.track(BlendEvent.DELETE_ACCOUNT, {
                reason,
                specific_reason: specificReason,
                selections: '2024-update',
                plan,
                seats
            });
            setTimeout(() => {
                resolve(true);
            }, 3000); //wait for 3 sec
        });
        if (this.paidUser) {
            const isTialUser = this.user.is_on_trial;
            //Auto-delete Account is currently only available for trial use, but it may be used for paid accounts in the future.
            if (isTialUser) {
                try {
                    await this.api.execute('cancelzuorasubscription', {});
                } catch (ex) {
                    this.spinner = false;
                    this.errcode = ErrCode.fromException(ex);
                }
            }

            //Call the Zproxy API and disable the auto-renewal when user fills out the cancellation form.
            await this.api.execute('userinfoset', { is_disableautorenew: 1 });

            if (environment.production) {
                const isTrialUserText = isTialUser ? 'Is Trial User: Yes' : '';
                const description = [
                    'USER ID: ',
                    this.user.uid,
                    isTrialUserText,
                    `Confirm cancellation: ${
                        this.cancelForm.get('confirm').value ? 'true' : 'false'
                    }`,
                    '--------',
                    `Specific reason: ${specificReason}`,
                    '--------',
                    'Cancellation request:',
                    this.cancelForm.get('description').value,
                ].join('\n\n');

                const postData = {
                    z_requester: this.cancelForm.get('email').value,
                    z_name: this.cancelForm.get('name').value,
                    z_subject: isTialUser
                        ? 'Trial User - Cancellation - ' + reason
                        : 'Cancellation - ' + reason,
                    z_description: description,
                };
                this.ticket.sendSupportTicket(postData);
            } else {
                window.location.href =
                    'https://www.sync.com/support/?thank=you';
            }
        } else if (this.isAccthold) {
            try {
                await this.api.send('acctcancel', {
                    source: 'accthold',
                    user: this.userId,
                    p: this.plan,
                    sig: this.sig,
                    subject: this.base64.encode('Cancellation - ' + reason),
                    message: this.base64.encode(
                        'Specific reason - ' + specificReason + '. message - ' +
                        this.cancelForm.get('description').value
                    ),
                });
                window.location.href = 'https://www.sync.com/support/?thank=you';
            } catch (ex) {
                this.spinner = false;
                this.errcode = ErrCode.fromException(ex);
            }
        } else {
            // this is a free acct
            try {
                await this.api.execute('acctcancel', {
                    source: 'free',
                    subject: this.base64.encode('Cancellation - ' + reason),
                    message: this.base64.encode(
                        'Specific reason - ' + specificReason + '. message - ' +
                        this.cancelForm.get('description').value
                    ),
                });
                window.location.href =
                    'https://www.sync.com/support/?thank=you';
            } catch (ex) {
                this.spinner = false;
                this.errcode = ErrCode.fromException(ex);
            }
        }
    }
}
