<form (submit)="cancelForm.valid && onSubmit()" id="dialog-cancel-modal" [formGroup]="cancelForm" method="post" ngNativeValidate>
    <div class="modal-header">
        <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Delete account</h4>
    </div>
    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
            <div class="col-sm-12 bottom-margin-40">
                Thanks for trying Sync. Before leaving, we'd like to learn how we can make the product better.
            </div>
        </div>
        <ng-container *ngIf="!isAccthold">
            <div class="row">
                <div class="col-sm-4">Your name:</div>
                <div class="col-sm-8">
                    <input
                        type="text"
                        class="form-control"
                        id="dialog-cancel-name"
                        formControlName="name"
                        placeholder=""
                    />
                </div>
            </div>
    
            <hr>
    
            <div class="row">
                <div class="col-sm-4">Your email address:</div>
                <div class="col-sm-8">
                    <input
                        type="email"
                        class="form-control"
                        id="dialog-cancel-email"
                        formControlName="email"
                        required
                        placeholder=""
                    />
                </div>
            </div>
    
            <hr>
        </ng-container>
        
        <div class="row">
            <div class="col-sm-12">
                <label>Why are you leaving?</label>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">Main reason:</div>
            <div class="col-sm-8">
                <select
                    class="form-control"
                    id="dialog-cancel-subject"
                    formControlName="mainReason"
                >
                    <option value=""> -- Please choose one --</option>
                    <option value="{{item.key}}" *ngFor="let item of deleteReasons | keyvalue: null">{{item.key}}</option>
                </select>
            </div>
        </div>

        <hr *ngIf="cancelForm.get('mainReason').value && cancelForm.get('mainReason').value != 'Other'">
        <div class="row" *ngIf="cancelForm.get('mainReason').value && cancelForm.get('mainReason').value != 'Other'">
            <div class="col-sm-4">Specific reason:</div>
            <div class="col-sm-8">
                <select
                    class="form-control"
                    id="dialog-cancel-subject"
                    formControlName="specificReason"
                >
                    <option value=""> -- Please choose one --</option>
                    <option value="{{item}}" *ngFor="let item of deleteReasons[cancelForm.get('mainReason').value]">{{item}}</option>
                </select>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-sm-4">Additional feedback:</div>
            <div class="col-sm-8">
                <textarea
                    class="form-control"
                    id="dialog-cancel-description"
                    formControlName="description"
                    rows="4"></textarea>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <label for="confirm">
                    <input type="checkbox" id="dialog-confirm-checkbox" name="confirm" required formControlName="confirm" />
                    I understand that closing my account will permanently delete all account data, including all encrypted file data stored in the cloud. I confirm that I have a local copy of my data.
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            class="btn btn-primary"
            id="dialog-submit-btn"
            [disabled]="!cancelForm.valid || spinner"
        >
            <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
            Submit
        </button>
        <button type="button" class="btn btn-default" id="dialog-close-btn" (click)="activeModal.close()">Close</button>
    </div>

</form>
