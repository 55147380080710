import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../core/api.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { BroadcastService } from '../shared/services/broadcast.service';

@Injectable({
    providedIn: 'root',
})
export class BatchActionService {
    public view: sync.IBatchActionView;
    protected retryAmt = 0;
    public ACT_MOVE = 1;
    public ACT_COPY = 2;
    public ACT_DELETE = 3;
    public ACT_PURGE = 4;
    public ACT_RESTORE = 5;
    public ACT_RENAME_RECURSIVE = 6;
    public ACT_RENAME_SINGLE = 7;
    public ACTION = 0;

    protected copyRetryAmt = 0;
    constructor(
        public broadcastService: BroadcastService,
        public loggerService: LoggerService,
        public syncCryptService: SyncCryptService,
        public notificationsService: NotificationsService,
        public apiService: ApiService
    ) {
        this.view = {
            spinner: 0,
            total: 0,
            completed: 0,
            status: {},
            globalCancel: false,
            percent: 0,
            completedBatch: [],
            errors: [],
            action: 0,
        };
    }
    /**
     * @ngdoc method
     * @name  init
     * @methodOf sync.service:BatchActionFactory
     * @description
     * Initializes and resets the batch action state
     */
    public init(): void {
        this.resetProgress();
        this.ACTION = 0;
        this.view.globalCancel = false;
        this.view.spinner = 0;
        this.view.completedBatch = [];
        this.view.errors = [];
    }
    /**
     * @ngdoc method
     * @name  cancel
     * @methodOf sync.service:BatchAction
     * @description
     * Allows cancelling the current batch action.  It will not stop in the
     * middle of processing but when it's time to move on to the next batch
     * the cancel value will be set and stopped
     */
    public cancel(): void {
        this.loggerService.info('Cancelling the batch action task');
        this.view.globalCancel = true;
    }
    /**
     * @ngdoc method
     * @name  isCompleted
     * @methodOf sync.service:BatchAction
     * @description
     * Check if the given sync id has been completed
     * @param  {Integer}  syncID The path sync id
     * @return {Boolean}        True if completed
     */
    public isCompleted(syncId: number): boolean {
        return this.view.completedBatch.indexOf(syncId) > -1;
    }
    /**
     * @ngdoc method
     * @name  setStatus
     * @methodOf sync.service:BatchActionFactory
     * @description
     * Set the status message of the current batch action
     * @param {Integer} syncID    The affected sync id
     * @param {String} statusMsg The message
     */
    public setStatus(syncId: number, statusMsg: string) {
        this.view.status[syncId] = { msg: statusMsg };
    }
    protected dumpDirs(syncId: number, activeLevel?: number): Promise<any> {
        this.view.completed = 0;
        const string = activeLevel || 1;
        return this.apiService.execute('batchdumpdirs', {
            sync_id: syncId,
            active_level: string.toString(),
        });
    }

    protected async runBatchItems(
        syncIds: number[],
        subject: Subject<any>
    ) {
        const syncId = syncIds.pop();
        if (this.view.globalCancel) {
            return this.handleCancel();
        } else {
            this.prepareItemForAction(syncId)
                .then( () => {
                    subject.next({ sync_id: syncId });
                    if (syncIds.length) {
                        this.runBatchItems(syncIds, subject);
                    } else {
                        this.batchSuccess();
                        subject.complete();
                    }
                }).catch((errData) => {
                    subject.error(errData);
                    subject.complete();
                });
        }
    }

    protected batchSuccess() {
        // reload the file list
        this.broadcastService.broadcast('event:file-list.reload', {hist_id: 0} );
        this.init();
        this.resetProgress();
    }

    protected async runProcessTask(
        syncIds: any[],
        idx: number
    ) {
        if (this.view.globalCancel) {
            return this.handleCancel();
        }
        const syncId = syncIds[idx];

        try {
            await this.apiExecuteTask(syncId.sync_id);
            this.loggerService.info('Run Process Task api successful');
            this.updateProgress(syncId);
            idx++;
            if (syncIds[idx]) {
                await this.runProcessTask(syncIds, idx);
            } else {
                return;
            }
        } catch (errData) {
            if (
                errData &&
                errData.error_code > 8000 &&
                errData.error_code < 8200
            ) {
                this.notificationsService.startNotificationLoop();
                this.retryAmt = 0;
                return this.loggerService.handleError(
                    'runProcessTask.notauthorized',
                    errData
                );
            }
            if (this.retryAmt > 2) {
                this.notificationsService.startNotificationLoop();
                this.retryAmt = 0;
                return this.loggerService.handleError(
                    'runProcessTask',
                    errData
                );
            } else {
                this.retryAmt += 1;
                this.loggerService.error(
                    `run process api execute failed for ${syncId}`
                );

                await new Promise(resolve => setTimeout(() => {
                    resolve(this.runProcessTask(syncIds, idx));
                }, 10000));
            }
        }
    }

    protected async prepareItemForAction(syncId: number): Promise<any> {
        this.loggerService.error('Must be overwritten');
        return await new Promise<any>(async (resolve, reject) => {
            reject();
        });
    }
    protected async apiExecuteTask(syncId: number): Promise<any> {
        this.loggerService.error('Must be overwritten');
        return await new Promise<any>(async (resolve, reject) => {
            reject();
        });
    }
    protected updateProgress(syncId: number): void {
        this.view.completed += 1;
        let percent = Math.floor((this.view.completed / this.view.total) * 100);
        // percent = (
        //     this.ACTION == this.ACT_MOVE
        //     || this.ACTION == this.ACT_RENAME_RECURSIVE
        // ) ? percent / 2 : percent;
        if (percent >= 100) {
            percent = 100;
            this.view.completedBatch.push(syncId);
        }
        this.view.percent = percent;
    }
    protected async convertToArray(data: any): Promise<Array<number>> {
        const list: number[] = [];
        for (const key in data.sync_ids) {
            list.push(data.sync_ids[key]);
        }
        return Promise.resolve(list);
    }
    protected async dropShare(data: any): Promise<any> {
        if (data.share && data.share.share_id && data.share.sharemember_id) {
            try {
                await this.apiService.execute('sharememberleave', {
                    share_id: data.share.share_id,
                    sharemember_id: data.share.sharemember_id,
                    bg: 0, //use background job 0 = false, i.e. move share
                });
                return data;
            } catch (errData) {
                this.loggerService.handleError('dropShare', errData);
            }
        } else {
            return data;
        }
    }
    protected async handleCancel(): Promise<any> {
        this.loggerService.warn('Cancelled action');
        this.notificationsService.startNotificationLoop();
        this.view.spinner = 0;
        throw new Error();
    }
    protected resetProgress() {
        // publicApi.view.spinner = false;
        this.view.completed = 0;
        this.view.percent = 0;
        this.view.total = 0;
    }
}
