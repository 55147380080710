
import { BaseApiOutput } from './base-api.model';
import { User } from '../user.model';

export class SessionNewApiInput {
    username: string;
    password: string;
    twoFa: string;
}



export class SessionNewApiOutput extends BaseApiOutput implements AccessTokens, UserKeys, UserSession {
    errors?: any;
    access_token: string;
    access_secret: string;
    enc_meta_key: string;
    enc_priv_key: string;
    exists: number;
    id: number;
    uid: number;
    email: string;
    web_sync_id: number;
    syncus_sync_id: number;
    web_device_id: number;
    pubkey_id: number;
    pubkey: string;
    disk_usage_bytes: number;
    disk_limit_bytes: number;
    event_count: number;
    first_name: string;
    last_name: string;
    display_name: string; // b64
    join_date: number;
    plan_id: number;
    referral_code: string;
    account_type: string;
    renewal_plan_id: number;
    account_duedate: number;
    sessionttl: number;
    is_verified: number;
    is_pro: number;
    is_resetpassword: number;
    is_disablesso: number;
    is_disablepurge: number;
    is_disableautorenew: number;
    is_emailmarketing: number;
    is_emailproduct: number;
    is_emailrewards: number;
    install_mobile: number;
    install_desktop: number;
    has_password_hint: number;
    has_shares: number;
    has_links: number;
    has_files: number;
    has_gettingstarted: number;
    has_2fa: number;
    twofa_type: number;
    team_id: number;
}
