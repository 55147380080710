
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DownloadService } from './services/download.service';
import { DownloadLegacyService } from './services/download-legacy.service';
import { DownloadWorkerService } from './services/download-worker.service';
import { TransferItemService } from './services/transfer-item.service';
import { HttpClientModule } from '@angular/common/http';
import { ErrorModule } from '../error/error-module';
import { FormsModule } from '@angular/forms';
import { DownloadCompatComponent } from './download-compat/download-compat.component';
import { NgbModalModule, NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferComponent } from './transfer/transfer.component';
import { UploadFileSelectDirective } from './upload-file-select.directive';
import { FileModule } from '../file/file.module';
import { DialogFolderUploadComponent } from './dialog-folder-upload/dialog-folder-upload.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        HttpClientModule,
        ErrorModule,
        FormsModule,
        NgbModalModule,
        NgbProgressbarModule,
        NgbPopoverModule,
        FileModule,
    ],
    exports: [
        TransferComponent
    ],
    providers: [
        DownloadService,
        DownloadLegacyService,
        DownloadWorkerService,
        TransferItemService,
    ],
    declarations: [DownloadCompatComponent, TransferComponent, UploadFileSelectDirective, DialogFolderUploadComponent],
    entryComponents: [DownloadCompatComponent, TransferComponent, DialogFolderUploadComponent]
})
export class TransferModule { }




