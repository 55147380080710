import { Injectable, Injector } from '@angular/core';
import { TransferService } from '../../transfer/transfer.service';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';

@Injectable({
    providedIn: 'root'
})
export class ThumbnailsService {

    private isRunning: boolean;

    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private injector: Injector,
        private log: LoggerService,
        private sanitizer: DomSanitizer,
        private transferService: TransferService,
        private buildTransferItemService: BuildTransferItemService
    ) { }

    public async getThumbs(displayed: Array<sync.IFile>, paginate: sync.IPaginate) {
        const start = Date.now();
        const list = this.getThumbList(displayed, paginate);
        this.log.d((Date.now() - start) + ' ms to getThumbList');
        // if this list is empty, there's no thumbs to get, return
        if (!list || !list.length) {
            this.log.d('No items to get');
            return;
        }

        this.isRunning = true;
        const items = list.map((curFile) => {
            return {
                cachekey: curFile.cachekey,
                blobtype: 'btTHUMB1',
                maxsize: 262144
            };
        });
        const keyData = await this.buildTransferItemService.getFileKeys(list);
        const response = await this.api.fetchThumbs(items);
        const byteLen = response.byteLength,
            manifestStart = response.byteLength - 4096,
            manifest = response.slice(manifestStart),
            thumbs = response.slice(0, manifestStart),
            m = JSON.parse(this.crypt.bytesToString(new Uint8Array(manifest)));
        this.processThumb(list, m, thumbs, displayed, keyData);
    }


    public async processThumb(list: sync.IFile[],
        manifest: number[][],
        thumbs: ArrayBuffer,
        displayed: Array<sync.IFile>, keyData: sync.IPathData) {
        const curItem: sync.IFile = list.shift();
        let man: Array<number> = manifest.shift(),
            start: number = man[0],
            end: number = start + man[1],
            thumb = thumbs.slice(start, end);
        if (!curItem || !man || end === 0 ) { return; }
        const startTime = Date.now();
        const tItem = await this.transferService.getThumb(curItem, keyData, thumb);
        this.log.d((Date.now() - startTime) + ' ms to ');
        for (let j = 0, jlen = displayed.length; j < jlen; j++) {
            if (tItem.cachekey == displayed[j].cachekey && tItem.status == 1) {
                // ignore this for now, it's non fatal - required due to angular requiring a trusted url
                // and angular 1 whitelisting it via thumbsrc
                displayed[j].imgsrc = this.sanitizer.bypassSecurityTrustUrl(tItem.renderFile.url);
                displayed[j].thumbsrc = tItem.renderFile.url;
                if (displayed[j].thumbData) {
                    displayed[j].thumbData.processed = true;
                }
            }
        }
        if (list.length) {
            man = [];
            start = 0;
            end = 0;
            thumb = null;
            this.processThumb(list, manifest, thumbs, displayed, keyData);
        } else {
            this.log.info('Thumbnails finished ' + (Date.now() - startTime) + ' ms');
            this.isRunning = false;
            return;
        }
    }


    public getThumbList(list: Array<sync.IFile>, paginate: sync.IPaginate): Array<sync.IFile> {

        const thumbs: Array<sync.IFile> = [];

        const start = (paginate.currentPage - 1) * paginate.itemsPerPage;
        const end = (start + paginate.itemsPerPage > paginate.total)
            ? paginate.total
            : start + paginate.itemsPerPage;

        for (let i = end - 1; i >= start; i--) {
            const cur = list[i];
            if (!cur || (cur.thumbData && cur.thumbData.processed == true)) { continue; }
            if (cur.size > 0 && cur.has_thumb1 == 1) {
                cur.thumbData = {
                    processed: false,
                    thumbType: 1,
                    cachekey: cur.cachekey,
                    blobtype: 'btTHUMB1'
                };
                thumbs.push(cur);
            } else if (cur.kind == 'image' && cur.size > 0 &&
                cur.size < 1024 * 100 && cur.type == 'file' &&
                !cur.has_thumb1 && (cur.filetype && cur.filetype.viewable)
            ) {
                cur.thumbData = {
                    processed: false,
                    thumbType: 1,
                    cachekey: cur.cachekey,
                    blobtype: 'btFILE'
                };
                thumbs.push(cur);
            }
        }
        return thumbs;
    }

}
