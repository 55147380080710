import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AppsComponent } from './apps/apps.component';
import { BillingInvoiceComponent } from './billing-invoice/billing-invoice.component';
import { BillingProfileComponent } from './billing-profile/billing-profile.component';
import { BillingComponent } from './billing/billing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { GetstartedComponent } from './getstarted/getstarted.component';
import { MigrateComponent } from './migrate/migrate.component';
import { RenewComponent } from './renew/renew.component';
import { SecurityComponent } from './security/security.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { FooterLayoutComponent } from '../layout/footer-layout/footer-layout.component';

const routes: Routes = [
  {
    path: '', component: FooterLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/account/info', pathMatch: 'full' },
      { path: 'info', component: DashboardComponent },
      { path: 'apps', component: AppsComponent },
      { path: 'billing', component: BillingComponent },
      { path: 'billing/profile', component: BillingProfileComponent },
      { path: 'billing/invoice/:id', component: BillingInvoiceComponent },
      { path: 'devices', component: DevicesComponent },
      { path: 'security', component: SecurityComponent },
      { path: 'migrate', component: MigrateComponent },
      { path: 'upgrade', component: UpgradeComponent },
      { path: 'getspace', redirectTo : 'upgrade' },
      { path: 'renew', component: RenewComponent },
      { path: 'getstarted', component: GetstartedComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
