<div class="modal-header" *ngIf="syncId">

  <button type="button" class="close" (click)="activeModal.close()"><i class="ion-android-close fa-lg"></i></button>
  <h4 class="modal-title">
      <i class="ion-android-people fa-lg"></i>
      Make share:
      <span [innerHtml]="filename"></span>
  </h4>
</div>
<div class="modal-header" *ngIf="!syncId">
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">
      Create new share folder
  </h4>
</div>
  
<div
  *ngIf="!isVerified"
  class="modal-body"
>
  <sync-verify-email-required></sync-verify-email-required>
</div>
  


<div class="modal-body" *ngIf="isVerified">
  <sync-error-display-hash [errors]="errors" ></sync-error-display-hash>
 

  
   <div class="error-general-overlay error-general-overlay-inline error-push-bottom"
          role="alert"
          [class.hide]="!(count > 0 && spinner)"
          >
  <i class="fa fa-spin fa-spinner"></i> Encrypting share ({{ ((completed/count)*100) | number:0 }}%). Please wait.
  </div>

  <!-- Share limit reached for user -->

  <div class="error-general-overlay error-general-overlay-inline error-push-bottom" role="alert" *ngIf="isShareLimit">
      <i class="fa fa-exclamation-triangle"></i>
      The free Sync Starter plan allows you to create a maximum of 3 shared folders. <br />Upgrade to get unlimited shared folders. 
  </div>
  
  
  <div *ngIf="isShareLimit" class="panel panel-default bgwhite">
      <div class="panel-body bgwhite">
          
          <h4>
          <i class="zmdi zmdi-star-circle zmdi-hc-lg"></i>
          <b>Upgrade to get these great share features:</b>
          </h4>
          
          <i class="fa fa-check"></i> Unlimited shared folders<br />
          <i class="fa fa-check"></i> Set read only permissions<br />
          <i class="fa fa-check"></i> Give other users invite permissions<br />
          <i class="fa fa-check"></i> Unlimited revision history<br />
          <i class="fa fa-check"></i> Unlimited deleted file recovery<br />
          <i class="fa fa-check"></i> 30-day money back guarantee<br />

          <br />
          <a class="syncblue" routerLink="/account/upgrade" (click)="activeModal.close()">View upgrade options now <i class="fas fa-arrow-right"></i></a>
          <br />
      </div>
  </div>


  <!-- Checking if user can create share here -->
  <div *ngIf="isChecking">
          <i class="fa fa-spin fa-spinner"></i> Loading ...
  </div>
  <form [formGroup]="newshareform" *ngIf="!isChecking && !isShareLimit" ngNativeValidate>
      <div class="row" [hidden]="syncId">
          <div class="col-sm-4">Folder name:</div>
          <div class="col-sm-8">
              <input
                  id="text-share-new-folder"
                  class="form-control"
                  name="sharename"
                  type="text"
                  placeholder="Name your shared folder"
                  formControlName="filename"
                  required 
                  (change)="onChange($event)"           
              />

              <div class="sync-tool-tip" *ngIf="!newshareform.controls.filename.valid && (newshareform.controls.filename.touched || newshareform.controls.filename.dirty)">
                <i class="fas fa-exclamation-triangle"></i>
                   <span *ngIf="newshareform.controls.filename.errors?.leadingSpace">
                      Files cannot begin with a space
                  </span>
                  <span *ngIf="newshareform.controls.filename.errors?.trailingChar">
                      Files cannot end in a . or a space.
                  </span>
                  <span *ngIf="newshareform.controls.filename.errors?.reserved">
                      This name is a reserved and cannot be used.
                  </span>
                  <span *ngIf="newshareform.controls.filename.errors?.illegal">
                      The following characters are not allowed: \ / : ? * &lt; &gt; &quot; |
                  </span>
                  <span *ngIf="newshareform.controls.filename.errors?.exist">
                      A file or folder already exists with this name.
                  </span>
                  <span *ngIf="newshareform.controls.filename.errors?.required">
                     Can't rename without a name.
                </span>
              </div>
          </div>
      </div>
      <hr />
      <sync-sharemember-add [sharememberInvite]="sharememberInvite"></sync-sharemember-add>
  </form>

</div>

<div class="modal-footer">

  <button id="btn-share-new-cancel" type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>

  
  
  <a routerLink="/account/upgrade" *ngIf="isShareLimit" id="" type="button" class="btn btn-primary pull-right" style="color:white;cursor: pointer;" (click)="activeModal.close()">Upgrade account now</a>
  
  <button
      id="btn-share-new-create-share"
      *ngIf="isVerified && !isShareLimit"
      [disabled]="!isValid()"
      (click)="makeShare()"
      class="btn btn-primary pull-right"
  >
      <i class="fa fa-spin fa-spinner" [class.hide]="!spinner"></i>
      Create Share
  </button>
</div>
