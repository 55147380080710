<div class="container-fluid container-fluid-wrap">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Account settings
            </div>
            <!-- general error handler -->
            <sync-error [errcode]="error"></sync-error>

            <!-- Profile specific error handler w/ dismiss -->
            <div class="error-general-overlay" *ngIf="framedError" style="z-index: 10000;">
                <button type="button" (click)="clearFramedError()" aria-label="Close" class="close"><span
                        aria-hidden="true">&times;</span></button>
                {{framedError}}
            </div>

            <div class="right-menu-button right-menu-button-push affix">
            </div>


            <ul class="nav nav-tabs">
                <li role="presentation">
                    <a routerLink="/account/info">Account</a>
                </li>
                <li role="presentation" class="active"><a routerLink="/account/billing">Billing</a></li>
                <li role="presentation"><a routerLink="/account/devices">Devices</a></li>
                <li role="presentation" *ngIf="user.new_feature"><a routerLink="/account/apps">Apps</a></li>
                <li role="presentation"><a routerLink="/account/security">Security</a></li>
            </ul>
        </div>
    </div>
    <div class="row content-account-summary" *ngIf="!checkPermission(permissionActions.VIEW_BILLING)">
        <div class="col-sm-12 content-box-withmenu">
            Contact your Administrator for billing inquiries.
        </div>
    </div>

    <div class="row" *ngIf="checkPermission(permissionActions.VIEW_BILLING)">
        <div class="col-sm-8 content-box-withmenu content-account">
            <div class="row iframe-container">
                <div class="col-sm-6">
                    <span *ngIf="spinner && !error">
                        <i class="fa fa-spin fa-spinner"></i> 
                        Loading ...
                    </span>
                    <iframe #iframe scrolling="no" [style.visibility]="(!spinner && !error) ? 'visible' : 'hidden'"
                        class="billing-profile" [src]="profileSafeUrl"
                        sandbox="allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
                </div>
                <div class="col-sm-2"></div>

                <div class="col-sm-4" *ngIf="user.is_on_trial">
                    <div class="content-account-summary-details">
                        <span class="content-account-planname">
                            {{planName}}
                        </span><br />
                        <ng-container>
                            <span> {{currentTerm | titlecase}}</span>
                            <br />
                        </ng-container>
                        <span *ngIf="checkPermission(permissionActions.MANAGE_BILLING)"><a class="subtext syncblue"
                            (click)="onUpgradeClick()" style="cursor: pointer;">
                                Change plan
                            </a>
                        </span> <br />
                        <span *ngIf="checkPermission(permissionActions.VIEW_BILLING) && planExpiry">
                            <ng-container *ngIf="!cancelInFuture">
                                Trial ends on {{planExpiry | date: 'MMMM d, yyyy'}}
                            </ng-container>
                        </span>
                    </div>
                    <hr />
                    <div>
                        <span style="font-size: 14px;">
                            <ng-container>
                                <b>Reminder:</b> Add your credit card details before your trial ends to avoid service disruption.
                                You won't be charged until your trial ends and you can cancel anytime.
                            </ng-container>
                        </span>
                        <br />
                    </div>
                    <hr />
                    <div style="font-size:14px;">
                        <b>Questions?</b>
                        <a class="subtext syncblue" href="https://www.sync.com/support"> Contact us </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>