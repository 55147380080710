<form class="form" #contactForm="ngForm" id="dialog-support-modal" name="contact-us" (ngSubmit)="sendSupportTicket()" ngNativeValidate>
  <div class="modal-header">
  
      <button type="button" class="close" (click)="activeModal.close()"><i class="ion-android-close fa-lg"></i></button>
      <h4 class="modal-title"><i class="ion-help-circled fa-lg"></i> Contact support</h4>
  
  </div>
  <div class="modal-body">
      <p>
      Check out the <a rel="noopener" (click)="syncKnowledgebase()" href="https://help.sync.com"  target="_blank" class="syncblue">Sync knowledgebase</a> for setup guides and tutorials.
      </p>
      <div class="form-group">
          <label for="name">Your Name<span class="required">*</span></label>
          <input type="text" required class="form-control required" id="input-name-dialog-support" [(ngModel)]="frmValues.name" name="name" #name="ngModel">
      </div>
      
      <div class="form-group">
          <label  for="email">Your email address<span class="required">*</span></label>
          <input type="email" required class="form-control required email" id="email" [(ngModel)]="frmValues.requester" name="requester" #requester="ngModel">
        </div>
      <div class="form-group">
          <label class="control-label" for="subject">Subject<span class="required">*</span></label>
          <input type="text" id="input-subject-dialog-support" required class="form-control required subject" [(ngModel)]="frmValues.subject" name="subject" #subject="ngModel">
      </div>
      <div class="form-group">
          <label class="control-label" for="comments">Comments, questions or suggestions</label>
          <textarea class="form-control" id="comments" [(ngModel)]="frmValues.description" rows="8" name="description"></textarea>
      </div>
  
      <div class="checkbox">
          <label>
              <input type="checkbox" [(ngModel)]="frmValues.attachLogs" name="attachLogs"/>
              Attach javascript error logs?
          </label>
      </div>
  
  </div>
  
  <div class="modal-footer text-right">
      <button type="button" class="btn btn-default" id="cancel-dialog-support-btn" (click)="cancel()">Cancel</button>
      <button type="submit" class="btn btn-primary" id="submit-dialog-support-btn">Submit</button>
  </div>
  </form>
  