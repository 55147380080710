import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User, UserRewards, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { RewardsService } from '../services/rewards.service';

@Component({
    selector: 'sync-dialog-rewards',
    templateUrl: './dialog-rewards.component.html',
    styleUrls: ['./dialog-rewards.component.css']
})
export class DialogRewardsComponent implements OnInit {

    @Input() user: User;
    @Input() userRewards: UserRewards;

    public refcode: string;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private rewards: RewardsService
    ) { }

    ngOnInit() {
        this.spinner = false;
    }

    public async redeem() {
        this.spinner = true;
        try {
            await this.api.execute('rewardclaim', { referrer_code: this.refcode });
            this.userRewards = await this.rewards.getData();
        } catch (err) {
            console.warn('error redeem', err);
            if (err instanceof ErrCode) {
                this.errcode = err;
            } else {
                this.errcode = ErrCode.fromException(err);
            }
        }
        this.spinner = false;
    }
}
