<div class="container-fluid container-fluid-wrap" style="min-height: 400px;">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="response-alert alert alert-{{alert.type}} alert-dismissible show" role="alert"
                *ngFor="let alert of alerts">
                <strong>{{alert.msg}}</strong>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="header-title">{{ roleEditorOpen ? 'Role editor' + (roleObject.name !== '' ? ' - ' +
                roleObject.name : '') : 'User Multi admin'
                }}
                <div style="position: absolute; top: 30px; right: 15px; width: 140px;"
                    class="hidden-md hidden-lg hidden-xl" *ngIf="!rolesActive && user.is_unlimited_plan">
                    <select [(ngModel)]="roleFilter" class="form-control" (change)="getList()">
                        <option value="">All</option>
                        <option *ngFor="let role of rolesList" [ngValue]="role.name">
                            {{role.name}}
                        </option>
                    </select>
                </div>
            </div>

            <ul class="nav nav-tabs">
                <li *ngIf="checkPermission(permissionActions.VIEW_USERS)" role="presentation"
                    class="{{rolesActive ? '' : 'active'}}" routerLink="/multi-user/users"><a>Users</a>
                </li>
                <li *ngIf="checkPermission(permissionActions.VIEW_ROLES) && user.is_unlimited_plan" role="presentation"
                    class="{{rolesActive ? 'active' : ''}}" routerLink="/multi-user/roles"><a>Roles</a>
                </li>
                <li role="presentation"><a (click)="addUser('INVITE_USERS')"
                        *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS) && !rolesActive"
                        class="hidden-md hidden-lg hidden-xl">+
                        Invite user</a></li>
                <li role="presentation"><a (click)="addUser('PURCHASE_USERS')"
                        *ngIf="checkPermission(permissionActions.MANAGE_BILLING) && limit < usersLimit && !rolesActive"
                        class="hidden-md hidden-lg hidden-xl">+
                        Purchase users</a></li>
                <li role="presentation"><a [ngClass]="{'disableMe': spinner}" (click)="addRole()"
                        *ngIf="checkPermission(permissionActions.ADD_EDIT_ROLES) && rolesActive"
                        class="hidden-md hidden-lg hidden-xl">+
                        Add role</a></li>
            </ul>

            <div class="right-menu affix hidden-xs hidden-sm" style="margin-top: 0; top: 180px;">
                <ng-container *ngIf="!rolesActive">
                    <ng-container *ngIf="!isUserSelected">
                        <ul class="dropdown-menu right-menu-dropdown-menu dropdown-regular" style="z-index:0;">
                            <li *ngIf="limit > 0">
                                <a style="color: black !important">
                                    Users available:{{limit - totalUsers}}/{{limit}}
                                </a>
                            </li>
                            <li class="showhand" *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS)"
                                (click)="addUser('INVITE_USERS')">
                                <a title="invite">
                                    <i class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-account-add"></i>
                                    <span> Invite user</span>
                                </a>
                            </li>
                            <li class="showhand"
                                *ngIf="checkPermission(permissionActions.MANAGE_BILLING) && limit < usersLimit"
                                (click)="addUser('PURCHASE_USERS')">
                                <a>
                                    <i class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-shopping-cart-plus"></i>
                                    <span> Purchase users</span>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="isUserSelected && checkPermission(permissionActions.ADD_EDIT_USERS)">
                        <ul class="dropdown-menu right-menu-dropdown-menu dropdown-regular" style="z-index:0;"
                            syncStopPropagation>
                            <li role="presentation" class="dropdown-header status">
                                {{selectedUsers.length}} selected.
                            </li>
                            <li class="showhand" (click)="openRemoveUsersModal()">
                                <a title="invite">
                                    <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                    <span *ngIf="selectedType === UserSelect.selectTypes.active"> Remove
                                        user{{selectedUsers.length > 1 ? "s" : ""}}</span>
                                    <span *ngIf="selectedType === UserSelect.selectTypes.invited"> Remove
                                        invite{{selectedUsers.length > 1 ? "s" : ""}}</span>
                                </a>
                            </li>
                            <li class="showhand"
                                *ngIf="selectedType === UserSelect.selectTypes.active && checkPermission(permissionActions.ADD_EDIT_ROLES)"
                                (click)="openChangeRolesModal()">
                                <a title="change role">
                                    <i class="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-lg"></i>
                                    <span> Change Role </span>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <div class="role-filter" *ngIf="user.is_unlimited_plan">
                        <label>Filter by role:</label>
                        <select [(ngModel)]="roleFilter" class="form-control" (change)="getList()">
                            <option value="">All</option>
                            <option *ngFor="let role of rolesList" [ngValue]="role.name">
                                {{role.name}}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <ng-container *ngIf="checkPermission(permissionActions.ADD_EDIT_ROLES) && rolesActive">
                    <ul class="dropdown-menu right-menu-dropdown-menu dropdown-regular" style="z-index:0;">
                        <li class="showhand" (click)="!spinner && addRole()">
                            <a>
                                <i class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-account-add"></i>
                                <span> Add role</span>
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>
    <sync-error [errcode]="errcode"></sync-error>
    <div *ngIf="usersInProvision" class="error-general-overlay error-general-overlay-message">
        <p>
            <i class="fa fa-spin fa-sync" style="margin-right: 5px;"></i>
            Provisioning users
        </p>
    </div>
    <div *ngIf="showUsersLoader" class="error-general-overlay error-general-overlay-message">
        <p>
            <i class="fa fa-spin fa-sync" style="margin-right: 5px;"></i>
            Loading users...
        </p>
    </div>
    <div class="row" *ngIf="rolesActive && !roleEditorOpen">
        <div class="col-sm-12 content-box-withmenu">
            <table class="table list-table list-table-push">
                <thead>
                    <tr>
                        <th class="table-rolename">Name</th>
                        <th class="table-roleusers">Users</th>
                        <th class="table-actions"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let role of rolesList">
                        <!-- <tr>
                            <td colspan="3">{{role | json}}>/td>
                        </tr> -->
                        <tr *ngIf="!role.hidden">
                            <td class="table-rolename">
                                <div class="tdmore">
                                    <strong>{{role.name}}</strong>
                                </div>
                            </td>
                            <td class="table-roleusers">
                                <div class="tdmore">
                                    <strong><a (click)="redirectToUsers(role.name)"
                                            style="text-decoration: none;">{{role.users.length}}</a></strong>
                                </div>
                            </td>
                            <td class="table-actions">
                                <div class="tdmore"
                                    *ngIf="checkPermission(permissionActions.ADD_EDIT_ROLES) && role.editable">
                                    <a (click)="editRole(role)">
                                        {{ role.name === userService.DEFAULT_ROLE ? 'View': 'Edit'}}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="rolesActive && roleEditorOpen">
        <div class="col-sm-12 content-box-withmenu">
            <form id="rolesForm" (ngSubmit)="addUpdateRole()" [formGroup]="form" ngNativeValidate>
                <table class="table list-table list-table-push">
                    <thead>
                        <tr>
                            <th colspan="2">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let field of fields">
                            <!-- <tr>
                                <td colspan="2">{{field | json}}</td>
                            </tr> -->
                            <tr *ngIf="!field.hidden">
                                <td>
                                    <b>{{field.label}}</b>
                                </td>
                                <td [ngSwitch]="field.controlType">
                                    <input *ngSwitchCase="'textbox'" [id]="field.key" class="form-control"
                                        [type]="field.type" [formControlName]="field.key" />
                                    <div class="sync-tool-tip" *ngIf="!!inputValidationErr[field.key]">
                                        <i class="fas fa-exclamation-triangle"></i>
                                        <span>{{inputValidationErr[field.key]}}</span>
                                    </div>

                                    <select *ngSwitchCase="'dropdown'" [id]="field.key" [formControlName]="field.key"
                                        class="form-control" (change)="parentFieldPropChange(field.key, field.subFields)">
                                        <option *ngFor="let opt of field.options" [ngValue]="opt.value">
                                            {{opt.key}}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <ng-container *ngIf="checkSubFieldHidden(field.key)">
                                <tr *ngIf="field.subFields.length && field.subFieldContext" syncIsFeatureAllowed="linkGranularSettings">
                                    <td colspan="2" style="padding-left: 30px; padding-right: 30px; border: 0">
                                        {{field.subFieldContext}}</td>
                                </tr>
                                <ng-container *ngFor="let subField of field.subFields">
                                    <!-- <tr>
                                        <td colspan="2">{{subField | json}}</td>
                                    </tr> -->
                                    <ng-container *ngIf="checkSubFieldHidden(subField.subOf)">
                                        <tr *ngIf="!subField.hidden" syncIsFeatureAllowed="linkGranularSettings">
                                            <td style="padding-left: 30px; border: 0">
                                                <b>{{subField.label}}</b>
                                            </td>
                                            <td style="padding-left: 30px; padding-right: 30px; border: 0"
                                                [ngSwitch]="subField.controlType">
                                                <input *ngSwitchCase="'textbox'" [id]="subField.key"
                                                    class="form-control" [type]="subField.type"
                                                    [formControlName]="subField.key" />
                                                <div class="sync-tool-tip" *ngIf="!!inputValidationErr[subField.key]">
                                                    <i class="fas fa-exclamation-triangle"></i>
                                                    <span>{{inputValidationErr[subField.key]}}</span>
                                                </div>

                                                <ng-container>
                                                    <select *ngSwitchCase="'dropdown'" [id]="subField.key"
                                                        [formControlName]="subField.key" class="form-control">
                                                        <option *ngFor="let opt of subField.options"
                                                            [ngValue]="opt.value">
                                                            {{opt.key}}
                                                        </option>
                                                    </select>
                                                </ng-container>

                                                <ng-container *ngSwitchCase="'radiobutton'">
                                                    <label *ngFor="let option of subField.options; let i = index"
                                                        style="margin-right: 30px; cursor: pointer; font-weight: 400;">
                                                        <input [id]="subField.key + i" [type]="subField.type"
                                                            [value]="option.value" 
                                                            (click)="parentFieldPropChange(subField.key)"
                                                            [formControlName]="subField.key"
                                                            [name]="subField.key"
                                                            [checked]="option.value === subField.value">
                                                        <span style="display: inline-block; margin-left: 10px;">
                                                            {{option.key}}</span>
                                                    </label>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
                <div class="text-right">
                    <button type="submit" class="btn btn-primary theme-btn"
                        [disabled]="!form.dirty || !form.valid || spinner">
                        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
                        Save Role
                    </button>

                    <button type="button" [disabled]="spinner || deleteSpinner || unEditedRole.name === 'Default User'"
                        (click)="deleteRole()" class="btn btn-danger ml-3">
                        <i *ngIf="deleteSpinner" class="fa fa-spin fa-spinner"></i>
                        Delete role
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div class="row" *ngIf="!rolesActive">
        <div class="col-sm-12 content-box-withmenu">
            <table class="table list-table list-table-push multi-user-table" syncStopPropagation>
                <thead>
                    <tr>
                        <th *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS)"
                            class="table-checkbox hidden-xs hidden-sm" style="font-size: 17px;">
                            <div [autoClose]="true" ngbDropdown class="dropdown" #selectDropdown="ngbDropdown">
                                <a ngbDropdownToggle toggle="dropdown" class="showhand">
                                    <span class="caret"></span>
                                </a>
                                <ul ngbDropdownMenu class="dropdown-menu showhand">
                                    <li>
                                        <a (click)="selectAllActive(); selectDropdown.close()">Active</a>
                                    </li>
                                    <li>
                                        <a (click)="selectAllInvited(); selectDropdown.close()">Invited</a>
                                    </li>
                                </ul>
                            </div>
                        </th>
                        <th class="name-column">Name</th>
                        <th class="hidden-xs hidden-sm">Role</th>
                        <th class="hidden-xs hidden-sm">Last active</th>
                        <th class="hidden-xs hidden-sm">Usage</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="currentUser">
                        <td *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS)"
                            class="table-checkbox hidden-xs hidden-sm">
                        </td>
                        <td>
                            <div class="tdmore">
                                <strong>You</strong>
                            </div>
                            <div class="tdmore hidden-md hidden-lg hidden-xl">{{currentUser.roles}}</div>
                            <div class="tdmore hidden-md hidden-lg hidden-xl">{{currentUser.last_filesync_time |
                                date:'medium'}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore">{{currentUser.roles}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore">{{currentUser.last_filesync_time | date:'medium'}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore"
                                title="{{currentUser.disk_usage_bytes | bytesToSize }} / {{currentUser.disk_limit_bytes | bytesToSize}}">
                                {{currentUser.disk_usage_bytes | bytesToSize }}
                            </div>
                        </td>
                        <td>
                            <strong>{{currentUser.user_status_str}}</strong>
                        </td>
                        <td></td>
                    </tr>

                    <tr *ngFor="let person of currentList">
                        <td *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS)"
                            class="table-checkbox hidden-xs hidden-sm">
                            <i sync-stop-propagation (click)="selectToggle(person)" [ngClass]="{
                                'fas fa-check-square' : person.active,
                                'far fa-square': !person.active
                            }" [class.disabled]="isSelectionDisabled(person.user_status_str)"></i>
                        </td>
                        <td>
                            <div class="tdmore">
                                <strong>{{person.email}}</strong>
                            </div>
                            <div class="tdmore hidden-md hidden-lg hidden-xl">{{person.roles}}</div>
                            <div class="tdmore hidden-md hidden-lg hidden-xl">{{person.last_filesync_time |
                                date:'medium'}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore">{{person.roles}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore">{{person.last_filesync_time | date:'medium'}}</div>
                        </td>
                        <td class="hidden-xs hidden-sm">
                            <div class="tdmore"
                                title="{{person.disk_usage_bytes | bytesToSize }} / {{person.disk_limit_bytes | bytesToSize}}">
                                {{person.disk_usage_bytes | bytesToSize }}
                            </div>
                        </td>
                        <td>
                            <div class="tdmore">
                                <i *ngIf="person.user_status_str === 'pending' || person.user_status_str === 'working'"
                                    class="fa fa-spin fa-sync" style="margin-right: 5px;"></i>
                                <strong style="text-transform: capitalize;">{{ (person.user_status_str === 'email sent'
                                    || person.user_status_str === 'invited') ? (person.isExpired ? 'Invitation Expired'
                                    : (person.isLegacyToCNC ? 'Upgrade pending' : 'Invitation Sent')) :
                                    person.user_status_str}}</strong>
                            </div>
                        </td>
                        <td class="table-actions">

                            <div *ngIf="checkPermission(permissionActions.ADD_EDIT_USERS) && person.user_status_str !== 'migrating' && !person.roles.includes('Administrator')"
                                ngbDropdown placement="bottom-right" class="dropdown actions-dropdown"
                                #rowActionDropdown="ngbDropdown">
                                <i ngbDropdownToggle class="fas fa-ellipsis-h pull-right dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
                                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-regular"
                                    style="width:230px;" aria-labelledby="dropdownMenu1">
                                    <li
                                        *ngIf="person.user_status_str === 'email sent' || (person.isExpired && person.user_status_str !== 'invited')">
                                        <a (click)="resendInviteEmail(person); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-repeat zmdi-hc-fw zmdi-hc-lg"></i>
                                            Resend Email
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="person.user_status_str === 'invited' || (person.isExpired && person.user_status_str !== 'email sent')">
                                        <a (click)="resenduserTeamInviteEmail(person); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-repeat zmdi-hc-fw zmdi-hc-lg"></i>
                                            Resend User Invite
                                        </a>
                                    </li>
                                    <li *ngIf="person.user_status_str === 'Active'">
                                        <a (click)="openSettings(person, 1); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-refresh-alt zmdi-hc-fw zmdi-hc-lg"></i>
                                            Password Reset
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="!person.roles.includes('Administrator') && person.user_status_str === 'Active' && user.is_unlimited_plan && checkPermission(permissionActions.ADD_EDIT_ROLES)">
                                        <a (click)="openSettings(person, 5); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-lg"></i>
                                            Change Role
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="person.user_status_str === 'email sent' || person.user_status_str === 'Active' || (person.isExpired && person.user_status_str !== 'invited')">
                                        <a (click)="openSettings(person, 2); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                            Remove User
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="person.user_status_str === 'pending' || person.user_status_str === 'working'">
                                        <a (click)="openSettings(person, 3); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                            Remove User
                                        </a>
                                    </li>
                                    <li *ngIf="removeUserInvite(person)">
                                        <a (click)="openSettings(person, 4); rowActionDropdown.close()">
                                            <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                            Remove User Invite
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-pagination pull-right">
            </div>

            <div class="table-info">
            </div>
        </div>
    </div>
</div>
<sync-modal [open]="showRemoveUsersModal" [onClose]="closeRemoveUsersModal"
    [title]="selectedType === UserSelect.selectTypes.active ? 'Remove Users' : 'Remove Invites'"
    [showCancelButton]="false">
    <div class="row">
        <div class="col-sm-12" *ngIf="removeUsersAlert?.message">
            <div class="alert" [ngClass]="removeUsersAlert.type == 'error' ? 'alert-danger' : 'alert-info'" role="alert"
                style="margin: 24px 0 0;">
                {{removeUsersAlert.message}}
            </div>
        </div>

        <div class="col-sm-12" *ngIf="!removeUsersAlert">
            <ng-container *ngIf="selectedType === UserSelect.selectTypes.active">
                <p>
                    By default the Sync {{selectedUsers.length > 1 ? 'accounts' : 'account'}}
                    <ng-container *ngIf="selectedUsers.length > 1">
                        {{getSelectedUserEmails()}}
                    </ng-container>
                    <ng-container *ngIf="selectedUsers.length == 1">
                        {{selectedUsers[0].email}}
                    </ng-container>
                    will remain active
                    and usable, however, upon removal the account will be
                    downgraded to a free plan.
                </p>

                <p>Note: you will also need to remove this user from any
                    shares you've created. We recommend you do this step
                    first, prior to removal, from the
                    <a routerLink="/shares" (click)="closeRemoveUsersModal()">shares tab</a>.
                </p>
                <hr>
                <div class="row">
                    <div class="col-sm-4">Purge account?</div>
                    <div class="col-sm-8">
                        <div class="radio radio-top">
                            <label>
                                <input type="radio" name="rmPurge" id="opt-multiuser-rm-purge-false" value="0"
                                    [(ngModel)]="rmPurge">
                                No, account will remain active as a free account.
                            </label>
                        </div>

                        <div class="radio radio-bottom">
                            <label>
                                <input type="radio" name="rmPurge" id="opt-multiuser-rm-purge-true" value="1"
                                    [(ngModel)]="rmPurge">
                                Yes, account will be destroyed, and all user data will be purged. This cannot be undone.
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedType === UserSelect.selectTypes.invited">
                <p>The user {{selectedUsers.length > 1 ? 'invites' : 'invite'}}
                    <ng-container *ngIf="selectedUsers.length > 1">
                        {{getSelectedUserEmails()}}
                    </ng-container>
                    <ng-container *ngIf="selectedUsers.length == 1">
                        {{selectedUsers[0].email}}
                    </ng-container>
                    will be cancelled and the account will be removed.
                </p>
            </ng-container>

        </div>
    </div>
    <ng-container buttons>
        <button type="button" *ngIf="!removeUsersAlert" class="btn btn-primary" (click)="removeUsers()"
            [disabled]="spinner">
            <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
            Yes
        </button>
        <button type="button" class="btn btn-default" (click)="closeRemoveUsersModal()">{{!removeUsersAlert ? 'No' :
            'Ok'}}</button>
    </ng-container>
</sync-modal>

<sync-modal [open]="showChangeRolesModal" [onClose]="closeChangeRolesModal" title="Change Role"
    [showCancelButton]="false">
    <div class="row">
        <div class="col-sm-12" *ngIf="updateUsersAlert?.message">
            <div class="alert" [ngClass]="updateUsersAlert.type == 'error' ? 'alert-danger' : 'alert-info'" role="alert"
                style="margin: 24px 0 0;">
                {{updateUsersAlert.message}}
            </div>
        </div>
        <div class="col-sm-12" *ngIf="!updateUsersAlert">
            <sync-error [errcode]="errcode"></sync-error>
            <div class="row">
                <div class="col-sm-4">Change role:</div>
                <div class="col-sm-8">
                    <select class="form-control" [(ngModel)]="role">
                        <option value="{{role.name}}" *ngFor="let role of filteredRoleList">{{role.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <ng-container buttons>
        <button type="button" *ngIf="!updateUsersAlert" class="btn btn-primary" (click)="updateUserRole()"
            [disabled]="spinner || !role">
            <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
            Yes
        </button>
        <button type="button" class="btn btn-default" (click)="closeChangeRolesModal()">{{!updateUsersAlert ? 'No' :
            'Ok'}}</button>
    </ng-container>
</sync-modal>