import { Injectable } from '@angular/core';
import { Base64Service } from '../core/base64.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { EventItemService } from './event-item.service';
import { EventsApiService } from './events-api.service';

@Injectable({
    providedIn: 'root',
})
export class EventsListService {
    constructor(
        private eventsApiService: EventsApiService,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private base64Service: Base64Service,
        private eventItemService: EventItemService
    ) {}

    public isFile(synctype: string[]): boolean {
        return synctype.indexOf('stDIR') == -1;
    }

    public async getList(opts?: sync.IEventOptions) {
        try {
            let apiResult: any;
            const data = await this.eventsApiService.getList(opts);
            apiResult = data;
            const eventList = await this.processEventList(data.events);
            apiResult.events = eventList;
            const shareList = await this.processShareList(apiResult.shares);
            apiResult.shares = shareList;
            return apiResult;
        } catch (errData) {
            this.loggerService.apiError(errData);
        }
    }

    private async processShareList(list: any[]) {
        const shareList: Promise<any>[] = [];
        for (let i = 0, len = list.length; i < len; i++) {
            shareList.push(this._shareItem(list[i]));
        }
        return await Promise.all(shareList);
    }

    private async _shareItem(shareitem: any) {
        try {
            const sname = await this.syncCryptService.filenameDecrypt(
                shareitem.enc_meta_name
            );
            shareitem.name = sname;
            shareitem.label = this.base64Service.decode(shareitem.label);
            shareitem.display_name = shareitem.label
                ? `${sname} [ ${shareitem.label} ]`
                : sname;
            return shareitem;
        } catch (error) {
            this.loggerService.error(
                'Error decrypting share name for ' + shareitem.share_id
            );
            shareitem.display_name =
                this.base64Service.decode(shareitem.label) ||
                shareitem.share_id;
            return shareitem;
        }
    }

    private shouldExclude(event: any) {
        return false;
    }

    private async processEventList(events: any[]) {
        const eventItems: Promise<any>[] = [];
        for (let i = 0, len = events.length; i < len; i++) {
            if (!this.shouldExclude(events[i])) {
                eventItems.push(this.eventItemService.format(events[i]));
            }
        }
        return await Promise.all(eventItems);
    }
}
