<div class="container-fluid container-fluid-wrap">
    <!-- header row top bar contains title, menu, options -->
    <div class="row">
        <!-- affixed header box sets padding for right menu -->
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">Links</div>
            <div class="right-menu-button right-menu-button-push affix"></div>
            <ul class="nav nav-tabs">
                <li role="presentation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <a routerLink="/shares">Team shares</a>
                </li>
                <li role="presentation" routerLinkActive="active">
                    <a routerLink="/links">Links</a>
                </li>
                <li *ngIf="isTeamsPlusUser" role="presentation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                    <a routerLink="/shares/externalshares">Shared externally</a>
                </li>
            </ul>
        </div>
        <!-- col-sm-12 affix -->
    </div>
    <!-- row -->

    <sync-error [errcode]="errcode"></sync-error>

    <div class="row">
        <div class="col-sm-12 content-box-withmenu">
            <table class="table list-table list-table-push">
                <thead>
                    <tr>
                        <th class="table-linkname" colspan="2">
                            <span
                                (click)="setPredicate('name')"
                                class="showhand"
                            >
                                Name <i [class]="getPredicateClass('name')"></i>
                            </span>
                        </th>

                        <th class="table-linkdate hidden-sm hidden-xs">
                            <span
                                (click)="setPredicate('date')"
                                class="showhand"
                            >
                                Shared on
                                <i [class]="getPredicateClass('date')"></i>
                            </span>
                        </th>
                        <th class="table-linkdownload">
                            <span
                                (click)="setPredicate('downloads')"
                                class="showhand"
                            >
                                Views
                                <i [class]="getPredicateClass('downloads')"></i>
                            </span>
                        </th>
                        <!-- comment count header -->
                        <th class="table-linkdownload hidden-sm hidden-xs">
                            <span> Comments </span>
                        </th>
                        <!-- / comment count header -->
                        <th class="table-actions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!loading && !list.length">
                        <td colspan="5" class="text-center linkshare-empty">
                            <h4>
                                <i class="fas fa-link dropdown-shim-link"></i>
                                <b
                                    >Send or request files and folders securely
                                    with links.</b
                                >
                            </h4>
                            <div class="file-empty-text">
                                The people you share with get instant access, no
                                Sync account required, and you can control
                                access with passwords, expiry dates, email
                                notifications and more.
                                <br /><br />
                                Create a link to any file or folder from the
                                <a routerLink="/files" class="syncblue"
                                    >files tab</a
                                >.
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="loading && !list.length">
                        <td colspan="5" class="text-center linkshare-empty">
                            <p>
                                <i class="fa fa-spin fa-spinner"></i>
                                Loading ...
                            </p>
                        </td>
                    </tr>
                    <tr
                        *ngFor="
                            let link of list
                                | slice: paginate.viewOffset:paginate.viewEnd
                        "
                        id="link-list-item-{{ link.publink_id }}"
                    >
                        <td class="table-preview">
                            <img
                                (click)="loadLink(link)"
                                [src]="'images/icons/' + link.cssclass + '.svg'"
                                width="32"
                                height="32"
                            />
                        </td>
                        <td class="table-linkname">
                            <div class="tdmore">
                                <a
                                    class="syncblacklink"
                                    (click)="loadLink(link)"
                                >
                                    <i
                                        *ngIf="link.password"
                                        title="Password protected"
                                        class="zmdi zmdi-lock"
                                    ></i>
                                    <i
                                        *ngIf="link.upload > 0"
                                        class="zmdi zmdi-cloud-upload zmdi-hc-fw zmdi-hc-lg"
                                    ></i>
                                    {{ link.name }}
                                </a>
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="link.is_suspended"
                            >
                                <span class="label label-danger"
                                    >Suspended</span
                                >
                            </div>
                            <div class="tdmore subtext" *ngIf="showInfo">
                                ID: {{ link.publink_id }}
                            </div>
                        </td>
                        <td class="table-linkdate hidden-sm hidden-xs">
                            <div class="tdmore">
                                {{ link.date | date: "mediumDate" }}
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="
                                    link.exp_servtime > 0 && !link.is_expired
                                "
                            >
                                (Expires:
                                {{ link.exp_servtime | date: "mediumDate" }})
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="link.exp_servtime > 0 && link.is_expired"
                            >
                                <span class="label label-danger">Expired</span>
                            </div>
                        </td>
                        <td class="table-linkdownload">
                            <div class="tdmore">
                                {{ link.download_count }}
                                <span *ngIf="link.download_limit > 0">
                                    / {{ link.download_limit }}
                                </span>
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="link.is_download_limit"
                            >
                                <span class="label label-danger"
                                    >Download Limit</span
                                >
                            </div>
                        </td>
                        <!-- comment count content -->
                        <td class="table-linkdownload hidden-sm hidden-xs">
                            <div class="tdmore" *ngIf="link.allow_comment">
                                {{ link.comment_count }}
                            </div>
                        </td>
                        <!-- / comment count content -->
                        <td class="table-actions">
                            <div ngbDropdown class="dropdown actions-dropdown">
                                <i
                                    ngbDropdownToggle
                                    class="fas fa-ellipsis-h pull-right dropdown-toggle"
                                    id="dropdownMenu1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                ></i>
                                <ul
                                    ngbDropdownMenu
                                    class="dropdown-menu dropdown-regular"
                                    aria-labelledby="dropdownMenu1"
                                >
                                    <li>
                                        <a (click)="showLinkDialog(link, 1)">
                                            <i
                                                class="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg"
                                            ></i>
                                            Link settings
                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="showLinkDialog(link, 3)">
                                            <i
                                                class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"
                                            ></i>
                                            Delete link</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-pagination pull-right">
                <!-- *ngIf="paginate.total > paginate.itemsPerPage" -->
                <ngb-pagination
                    *ngIf="list.length > 0"
                    [maxSize]="10"
                    [collectionSize]="paginate.total"
                    [(page)]="paginate.currentPage"
                    (pageChange)="onPageChange($event)"
                    [pageSize]="paginate.itemsPerPage"
                    [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
            <div class="table-info">
                <span *ngIf="list.length == 0">
                    No links created yet.
                </span>
                <span *ngIf="list.length > 0">
                    <i (click)="showInfo = !showInfo" class="fa fa-info-circle fa-lg showhand"></i>
                    <sync-display-pagination [paginate]="paginate"></sync-display-pagination>
                </span>
            </div>
        </div>
    </div>
</div>
