import { Injectable } from '@angular/core';
import { UrlService } from '../../core/url.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { HttpEventType } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DownloadLegacyService implements sync.IDownloadMethod {
    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private log: LoggerService,
        private url: UrlService
    ) { }


    public abort(): void {}


    public getChunk(tItem: sync.ITransferItemDownload, offset: number, reqLength: number, retry = 0) {
        return new Promise<sync.IStoreData>(async (resolve, reject) => {

            this.api.fetchFile(tItem, offset, reqLength, retry)
                .subscribe( async resp => {
                    if (resp.type === HttpEventType.DownloadProgress) {
                        // console.log('download preogress');
                        const bytesSent = resp.loaded + offset;
                        const gcmTags = Math.ceil(bytesSent / 131072) * 36;
                        tItem.bytes_sent = bytesSent - gcmTags;
                        let percent = Math.round(tItem.bytes_sent / tItem.filesize * 100 * 100) / 100;
                        if (percent > 100) { percent = 100; }
                        tItem.progress_percent = percent;
                        // console.log('Download progress: ' + percent);
                    } else if (resp.type === HttpEventType.Response) {

                        const respBuffer = <ArrayBuffer>resp.body,
                            encStart = Date.now(),
                            data = [],
                            processData: ChunkProcessData = {
                                buffer: respBuffer,
                                byteLen: respBuffer.byteLength,
                            bytearray: [],
                            datakey: tItem.data_key,
                            packetAmt: Math.ceil(respBuffer.byteLength / this.crypt.GCM_PACKET_SIZE),
                            sliceStart: 0,
                            sliceEnd: (respBuffer.byteLength > this.crypt.GCM_PACKET_SIZE) ?
                                        this.crypt.GCM_PACKET_SIZE :
                                        respBuffer.byteLength,
                            cryptOffset: offset,
                            iterations: 0
                        };
                        const result = await this.processPackets(processData);
                        const res: sync.IStoreData = {
                            tItem: tItem,
                            bytearray: result.bytearray,
                            offset: offset,
                            reqlength: reqLength,
                            chunkLength: result.byteLen
                        };
                        resolve(res);

                    }
                }, err => {
                    // reject(err);
                    this.log.e('Error subscribing to fetch file in download-worker', err);
                    if (this.url.downloadhosts[retry + 1]) {
                        this.log.warn(`Retrying download on host ${this.url.downloadhosts[retry + 1]}`);
                        resolve(this.getChunk(tItem, offset, reqLength, retry + 1));
                    } else {
                        this.log.error(`Attempted ${retry} retries and failed, rejecting file`);
                        reject(err);
                    }
                });
            });
        // }).then((resp) => {
        //     let dataDefer: ng.IDeferred<ChunkProcessData> = this.$q.defer(),
        //         respBuffer: ArrayBuffer = <ArrayBuffer>resp.data,
        //         encStart = Date.now(),
        //         data = [],
        //         processData: ChunkProcessData = {
        //             buffer: respBuffer,
        //             byteLen: respBuffer.byteLength,
        //             bytearray: [],
        //             datakey: tItem.data_key,
        //             packetAmt: Math.ceil(respBuffer.byteLength/ this.SyncCrypt.GCM_PACKET_SIZE),
        //             sliceStart: 0,
        //             sliceEnd: (respBuffer.byteLength > this.SyncCrypt.GCM_PACKET_SIZE) ?
        //                         this.SyncCrypt.GCM_PACKET_SIZE :
        //                         respBuffer.byteLength,
        //             cryptOffset: offset,
        //             iterations: 0
        //         }
        //     this.processPackets(dataDefer, processData);
        //     return dataDefer.promise;
        // }).then((result) => {
        //     let res: sync.IStoreData = {
        //         tItem: tItem,
        //         bytearray: result.bytearray,
        //         offset: offset,
        //         reqlength: reqLength,
        //         chunkLength: result.byteLen
        //     }
        //     dfd.resolve(res)
        // })
        // .catch((err) => {
        //     this.Logger.error('Error occurred downloading the file');
        //     this.Logger.error(err);
        //     if (err.status == -1) {
        //         dfd.reject({code: 7101 });
        //     }
        //     else if (err.status == 404) {
        //         dfd.reject({code: 7102 });
        //     }
        //     else {
        //         dfd.reject({code: 7100});
        //     }
        // })

        // return dfd.promise;
    }
    public completed(): void {
        // this.dfd = null;
    }

    private async processPackets(data: ChunkProcessData) {

        while (data.iterations < data.packetAmt) {
            data.iterations++;
            const crypted = data.buffer.slice(data.sliceStart, data.sliceEnd),
                chunkStart = Date.now();
            const decryptedBuffer = await this.crypt.filedataDecrypt(
                new Uint8Array(crypted),
                data.datakey,
                data.cryptOffset);

            data.bytearray = data.bytearray.concat(<number[]>decryptedBuffer);
            data.sliceStart += this.crypt.GCM_PACKET_SIZE;
            data.sliceEnd = (data.byteLen - data.sliceStart > this.crypt.GCM_PACKET_SIZE)
                ? data.sliceEnd + this.crypt.GCM_PACKET_SIZE
                : data.sliceEnd + data.byteLen - data.sliceStart;
            data.cryptOffset += this.crypt.GCM_PACKET_SIZE;
        }
        return data;
    }
}

export interface ChunkProcessData {
    buffer: ArrayBuffer;
    datakey: ArrayLike<number>;
    byteLen: number;
    packetAmt: number;
    sliceStart: number;
    sliceEnd: number;
    cryptOffset: number;
    bytearray: number[];
    iterations: 0;
}
