<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Change your display name?</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            Your current display name is <strong>{{user.display_name | b64 }}</strong> <br>The display name is your visible name when sharing.
                
        </div>
    </div>

    <hr/>
        
    <div class="row">
        <div class="col-sm-4">New display name:</div>
        <div class="col-sm-8">
            <input
                type="text"
                class="form-control"
                id="txt-displayname-new"
                placeholder="Enter new display name"
                [(ngModel)]="displayName"
            />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        id="save-settings-display-dialog-btn"
        (click)="save()"
        [disabled]="spinner"
    >
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Save settings
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>