import { Component, OnInit, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

import { PermissionActions, User } from '../../shared/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogTwofactorComponent } from '../dialog-twofactor/dialog-twofactor.component';
import { DialogPasswordHintComponent } from '../dialog-password-hint/dialog-password-hint.component';
import { DialogPasswordResetComponent } from '../dialog-password-reset/dialog-password-reset.component';
import { DialogAutoLogoutComponent } from '../dialog-auto-logout/dialog-auto-logout.component';
import { DialogSsoComponent } from '../dialog-sso/dialog-sso.component';
import { UserService } from '../../core/user.service';
import { DialogAnalyticsComponent } from '../dialog-analytics/dialog-analytics.component';

@Component({
    selector: 'sync-security',
    templateUrl: './security.component.html'
})
export class SecurityComponent implements OnInit {

    public user: User;
    public isPassResetHidden: boolean;
    public permissionActions = PermissionActions;
    public is2FaEditable = true;

    constructor(
        private injector: Injector,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                if (!data.authenticated) {
                    console.warn('User is not authenicated');
                    this.user = new User();
                    // this.initialized = false;
                    return;
                }
                if (data.user) {
                    console.log('Setting user');
                    this.user = data.user;
                    this.isPassResetHidden = this.user.is_multi_new ? this.user.is_multi_new : (this.user.is_multi_child && this.user.is_resetpassword == 1);
                }
                if (this.user.is_multi_new) {
                    this.is2FaEditable = !this.userService.getPermssionByKey(PermissionActions.FORCE_2FA_ON_FIRST_LOGIN);
                }
            });
    }

    public openTwoFa() {
        const ref = this.modalService.open(DialogTwofactorComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.result.then((result) => {
            if (result === true) {
                console.log('received a true response from result');
            }
        });
    }

    openPasswordHint() {
        const ref = this.modalService.open(DialogPasswordHintComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openPasswordReset() {
        const ref = this.modalService.open(DialogPasswordResetComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openAutoLogout() {
        const ref = this.modalService.open(DialogAutoLogoutComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openDisableSso() {
        const ref = this.modalService.open(DialogSsoComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }

    openDisableAnalytics() {
        const ref = this.modalService.open(DialogAnalyticsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
    }
}
