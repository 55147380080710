<div class="container-fluid container-fluid-wrap">

    <div class="row">

        <div class="col-xs-7">
            <br />
            <img  width="220" height="50" src="images/logoinvoice.png" />
        </div>
        <div class="col-xs-5 text-right" style="font-size:12px;">

                Sync.com Inc.<br />
                105-155 Gordon Baker Road<br />
                Toronto, ON M2H 3N5<br />
                Canada<br />

        </div>

    </div>

    <br />

    <div class="row" style="background-color:#eeeeee;">

        <div class="col-xs-8">
            <h4>
            <span *ngIf="invoice.company_name">
                {{invoice.company_name | b64}}<br />
            </span>
            {{invoice.first_name | b64 }} {{invoice.last_name | b64 }}<br />
            {{invoice.address | b64 }}<br />
            {{invoice.city | b64 }}, {{invoice.province | b64 }}, {{invoice.postal_code | b64 }}<br />
            {{invoice.country | b64 }}
            <span *ngIf="invoice.tax_code">
                <br />VAT: {{invoice.tax_code| b64}}
            </span>
            </h4>
        </div>
        <div class="col-xs-4">
            <br />
            Invoice ID: <span class="subcolor">{{invoice.id}}</span><br />
            Date: <span class="subcolor">{{invoice.start_servtime | date:'mediumDate'}}</span><br />
            <span *ngIf="invoice.status != 10">
                Expiry: <span class="subcolor">{{invoice.end_servtime | date:'mediumDate'}}</span><br />
            </span>
            <span *ngIf="invoice.status == 10">
                Refunded: <span class="subcolor">{{invoice.refund_servtime | date:'mediumDate'}}</span><br />
            </span>
            <br />
        </div>

    </div>

    <br />

    <div class="row">

        <table class="table col-xs-12">

            <tr>
                <th class="col-xs-8 subcolor">Description</th>
                <th class="col-xs-4 subcolor">
                    <div class="invoice-col2">Amount ({{ invoice.currency_id ==1 ? 'CAD' : 'USD' }}$)</div>
                </th>
            </tr>

            <!-- prorated -->
            <tr *ngIf="invoice.prorated_amount">

                <td class="col-xs-8">
                    Pro-rated Amount
                </td>

                <td class="col-xs-2">
                    <div class="invoice-col2">({{invoice.prorated_amount | currency}})</div>
                </td>

            </tr>

            <!-- plan -->
            <tr>

                <td class="col-xs-8">
                    {{invoice.plan_name}} (Annual)
                </td>

                <td class="col-xs-4">
                    <div class="invoice-col2">{{invoice.total_amount - invoice.tax_amount | currency}}</div>
                </td>

            </tr>

            <!-- discount -->
            <tr *ngIf="invoice.coupon_discount_amount">

                <td class="col-xs-8">
                    Discount
                </td>

                <td class="col-xs-4">
                    <div class="invoice-col2">({{invoice.coupon_discount_amount | currency}})</div>
                </td>

            </tr>

            <!-- tax -->
            <tr *ngIf="invoice.currency_id==1">

                <td class="col-xs-8">
                    HST/GST
                    <br />848248506RT0001
                </td>

                <td class="col-xs-4">
                    <div class="invoice-col2">{{invoice.tax_amount | currency}}</div>
                </td>

            </tr>
            <tr *ngIf="invoice.currency_id == 1 && invoice.province == 'QC'">
                <td class="col-xs-8">
                    QST
                    <br />NR00003484
                </td>
                <td class="col-xs-4">
                    <div class="invoice-col2">{{invoice.qst_tax_amount | currency }}</div>
                </td>
            </tr>

            <!-- total -->
            <tr>
                <td class="col-xs-8">
                    <strong>Total</strong>
                </td>
                <td class="col-xs-4">
                    <div class="invoice-col2"><strong>{{invoice.total_amount | currency}}</strong></div>
                </td>
            </tr>

            <!-- line -->
            <tr>
                <td class="col-xs-8">
                </td>
                <td class="col-xs-4">
                </td>
            </tr>

        </table>
    </div>

    <div class="row">

        <div class="col-xs-8">
                Payment status:
                <span *ngIf="invoice.status == 0">Pending</span>
                <span *ngIf="invoice.status == 1">Okay</span>
                <span *ngIf="invoice.status == 2">Failed</span>
                <span *ngIf="invoice.status == 10">Refunded</span>
            <br />
            Transaction ID #: {{invoice.paypal_trans_id}}

        </div>
        <div class="col-xs-4">

        <span *ngIf="invoice.currency_id == 1">GST/HST: 848248506RT0001</span><br/>

        </div>


    </div>

    <div class="row">
        <hr />
        </div>

    </div> <!-- container -->
