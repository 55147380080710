import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { ErrCode, User } from '../../shared/models';

@Component({
    selector: 'sync-wopi-optin',
    templateUrl: './wopi-optin.component.html',
})
export class WopiOptinComponent implements OnInit {
    public user: User;
    public status: string;

    public spinner = false;
    public errcode: ErrCode;

    constructor(
        private userService: UserService,
        public activeModal: NgbActiveModal,
        private apiService: ApiService
    ) {}

    ngOnInit() {
        this.user = this.userService.getUser();
        this.status = this.user.is_office === 1 ? '1' : '0';
    }

    public async optin() {
        this.spinner = true;
        try {
            await this.apiService.execute('userinfoset', {
                is_office: 1,
            });
            await this.apiService.execute('userinfoset', {
                is_displayedofficeoptin: 1,
            });
            this.userService.refresh();

            this.spinner = false;
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async optout() {
        this.spinner = true;
        try {
            await this.apiService.execute('userinfoset', {
                is_office: 0,
            });
            await this.apiService.execute('userinfoset', {
                is_displayedofficeoptin: 1,
            });
            this.userService.refresh();

            this.spinner = false;
            this.activeModal.close(false);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }
}
