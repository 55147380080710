import { Injectable, Inject } from '@angular/core';
import { LoggerService } from './logger.service';
import { CryptEngine } from '../shared/models/crypt-engine.model';

@Injectable({
  providedIn: 'root'
})
export class BrowserSupportService {

    private ENCENGINE: CryptEngine;
    constructor(
        private log: LoggerService,
        @Inject('window') private window: Window
    ) {
        this.getEncryptionEngine();
    }

    /**
     * @ngdoc method
     * @name  testblob
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if Blob exists and we are able to make blob urls
     * @return boolean
     */
    public testBlob(): boolean {
        if (!window.Blob || typeof window.Blob.prototype.slice !== 'function') {
            return false;
        }
        const urlObj = window.URL || window.webkitURL;
        if (!urlObj) { return false; }
        try {
            return !! new Blob();
        } catch (e) {
            return false;
        }
    }

    /**
     * @ngdoc method
     * @name  testXhr2
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests whether the newer XHR2 is available.
     * @return {bool} true if feature passes
     */
    public testXhr2() {
        return (new XMLHttpRequest().upload) ? true : false;
    }

    // test save Methods
    /**
     * @ngdoc method
     * @name  testDownloadAttr
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if the download attribute is available on <a> tags.
     * @return {bool} true if feature passes
     */
    public testDownloadAttr(): boolean {
        return ('download' in document.createElement('a'));
    }
    /**
     * @ngdoc method
     * @name  testMsSaveBlob
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests if Microsoft 10+ save blob is available.
     * @return {bool} true if feature passes
     */
    public testMsSaveBlob(): boolean {
        return (navigator.msSaveOrOpenBlob) ? true : false;
    }

    /**
     * @ngdoc method
     * @name testDirUpload
     * @methodOf sync.service:BrowserSupport
     * @description tests if the browser supports dir uploads
     * @return {bool}
     */
    public testDirUpload(): boolean {

// var input = document.createElement("input");
// input.type = "file";
// return !!("webkitdirectory" in (input || document.querySelectorAll("input[type=file]")[0] ))
        return (typeof DataTransferItem.prototype.webkitGetAsEntry === 'function');
    }


    /**
     * @ngdoc method
     * @name  getFileSaveMethod
     * @methodOf sync.service:BrowserSupport
     * @description
     * Gets the file save method as string.  The strings returned here are
     * handled in other directives/factories
     * @return {String} The name of the save method
     */
    public getFileSaveMethod(): string {
        if (this.testBlob() && this.testMsSaveBlob()) {
            return 'msblob';
        } else if (this.testDownloadAttr()) {
            return 'dlattr';
        } else {
            return 'none';
        }
    }

    /**
     * @ngdoc method
     * @name  getFileStorageMethod
     * @methodOf sync.service:BrowserSupport
     * @description
     * Gets the file storage method available to this browser cascading
     * to in-memory as the worst storage method.
     * @return {String} A string name for the storage method.
     */
    public getFileStorageMethod(): string {
        return (this.testFileSystemWriter()) ? 'filesystem' : 'memory';
    }

    public getEncryptionEngine(): CryptEngine {
        if (!this.ENCENGINE || this.ENCENGINE === undefined) {
            if (this.getCrypto() && this.getCryptoSubtle()) {
                this.ENCENGINE = CryptEngine.NATIVE;
            } else if (this.testWorker()) {
                this.ENCENGINE = CryptEngine.BUFFER;
            } else {
                this.ENCENGINE = CryptEngine.LEGACY;
            }
        }
        return this.ENCENGINE;
    }

    public getCrypto(): Crypto {
        return window.crypto || window.msCrypto;
    }

    public getCryptoSubtle(): SubtleCrypto {
        const crypto = this.getCrypto();
        if (crypto && crypto.subtle) {
            return crypto.subtle;
        } else if (crypto && crypto.webkitSubtle) {
            return crypto.webkitSubtle;
        } else {
            return null;
        }
    }


    /**
     * @ngdoc method
     * @name  testFileUpload
     * @methodOf sync.service:BrowserSupport
     * @description
     * tests if the browser can upload files
     * @return {bool} true if feature passes
     */
    public testFileUpload(): boolean  {
        if (!this.testBlob()) {
            this.log.info('Blob constructor fails');
        }
        return (this.testBlob() && this.testXhr2() && !!window.FileReader);
    }

    /**
     * @ngdoc method
     * @name  testFileSystemWriter
     * @methodOf sync.service:BrowserSupport
     * @description
     * tests if the FileSystem writer API is available
     * @return {bool} true if feature passes
     */
    public testFileSystemWriter(): boolean {
        window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        return (!!window.File && !!window.FileReader && !!window.FileList &&
            !!window.Blob && !!window.ArrayBuffer &&
            !!window.DataView && !!window.requestFileSystem && !!window.Worker);
    }

    /**
     * @ngdoc method
     * @name  testWorker
     * @methodOf sync.service:BrowserSupport
     * @description
     * Tests for Web Worker support
     * @return {bool} true if feature passes
     */
    public testWorker(): boolean {
        return (window.Worker !== undefined);
    }





    private async testCryptoSubtleImportKey() {
        const cryptoObj = window.crypto || window.msCrypto;
        if (!cryptoObj || !cryptoObj.subtle) {
            return false;
        }
        try {
            const key = await cryptoObj.subtle.importKey('raw', new Uint8Array([
                122, 94, 39, 230, 46, 23, 151, 80, 131, 230, 3, 101, 80, 234, 143, 9, 251,
                152, 229, 228, 89, 222, 31, 135, 214, 104, 55, 68, 67, 59, 5, 51
            ]), 'AES-GCM', false, ['encrypt', 'decrypt']);
            return (key != undefined);
        } catch (ex) {
            this.log.e('Crypto subtle failed import key', ex);
            return false;
        }
    }


    private async testCryptoSubtleDecrypt() {
        const cryptoObj = window.crypto || window.msCrypto,
            TESTBYTES = new Uint8Array([1, 2, 3, 4]);
        if (!cryptoObj || !cryptoObj.subtle) {
            return false;
        }
        try {
            const key = await cryptoObj.subtle.generateKey('AES-GCM', false, ['encrypt']);
            const data = await cryptoObj.subtle.encrypt({
                        name: 'AES-GCM',
                        iv: cryptoObj.getRandomValues(new Uint8Array(12)),
                        additionalData: cryptoObj.getRandomValues(new Uint8Array(256)),
                        tagLength: 128,
                    }, <CryptoKey>key, TESTBYTES);
            return (key != undefined);
        } catch (ex) {
            this.log.e('testCryptoSubtleDecrypt failure', ex);
            return false;
        }
    }
}
