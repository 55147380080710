import { ShareNewCryptData } from './share-new-crypt-data.model';

export class ShareNewData extends ShareNewCryptData {
    enc_name: string;
    sync_id: number;
    label: string;
    emaillist: string[];
    pm_b64: string;
    permissions: string[];
    display_name: string;
}
