<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Rename device?</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            Current device name is <strong>{{device.name | b64}}</strong>
        </div>
    </div>

    <hr/>

    <div class="row">
        <div class="col-sm-4">New device name:</div>
        <div class="col-sm-8">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="newName"
                placeholder="Enter new display name"
            />
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()">Save settings</button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>