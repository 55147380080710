import { environment } from './../../environments/environment';
import { Base64Service } from './base64.service';
import { SyncCryptService } from './crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpErrorResponse,
} from '@angular/common/http';
import {
    CommentUpdateData,
    CommentDeleteData,
    ReplyUpdateData,
    ReplyDeleteData,
    CommentCreationParams,
    CommentUpdateParams,
    DeleteParams,
    LocIdCountPair,
    ReplyCreationParams,
    ReplyUpdateParams,
    IResponse,
    SuccessRes,
} from '../shared/models/commments.model';
import { ApiService } from './api.service';
import { LinkListService } from '../links/services/link-list.service';
import { IFrameMessage } from '../shared/components/comment/comment-cp-service/comment-cp-iframe.component';

@Injectable({
    providedIn: 'root'
})
export class CommentService {
    private path = environment.commenthost;

    constructor(
        private httpClient: HttpClient,
        private log: LoggerService,
        private crypt: SyncCryptService,
        private api: ApiService,
        private linkList: LinkListService,
        private base64: Base64Service
        ) {
        // window.addEventListener('message', this.handleIFrameMessage.bind(this));
    }

    async createComment(signedInput: BaseApiInput): Promise<IResponse | never> {
        try {
            // const signedInput = await this.crypt.signApiReq(input);
            //api call to comment server, posting a comment
            const result = await this.httpClient
                .post<IResponse>(this.path, signedInput)
                .toPromise();
            this.log.info(`${result.status} ${this.path}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err);
        }
    }

    async getComments(
        key: string,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/link/${key}`;

        try {
            let params = new HttpParams();

            for (const [key, val] of Object.entries(signedInput)) {
                params = params.set(key, val); //since HttpParams is immutable, reasingment needed
            }

            //api call to comment server, getting a comment
            const result = await this.httpClient
                .get<IResponse>(url, { params: params })
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async updateComment(
        data: CommentUpdateData,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/${data._id}`;
        try {
            //api call to comment server, posting a comment
            const result = await this.httpClient
                .patch<IResponse>(url, signedInput)
                .toPromise();
            this.log.info(`${result.status} ${this.path}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err);
        }
    }

    async deleteComment(
        data: CommentDeleteData,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/${data._id}`;
        let params = new HttpParams();

        try {
            for (const [key, val] of Object.entries(signedInput)) {
                params = params.set(key, val as string); //since HttpParams is immutable, reassingment needed
            }
            const result = await this.httpClient
                .delete<IResponse>(url, { params: params })
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err);
        }
    }

    private handleError(response: HttpErrorResponse) {
        if (response.status) {
            switch (response.status) {
                case 422:
                    this.log.error(
                        `error status ${response.status}: ${
                            response.error.message
                        }, detail: ${JSON.stringify(
                            response.error.detail,
                            null,
                            4
                        )}`
                    );
                    break;
                case 429:
                    this.log.error(
                        `error status ${response.status}: ${response.error.message}`
                    );
                    break;
                default:
                    this.log.error(
                        `error status ${response.status}: ${
                            response.error.message
                        }\n detail:${JSON.stringify(
                            response.error.detail,
                            null,
                            4
                        )}`
                    );
            }
        } else {
            this.log.error(JSON.stringify(response));
        }
        throw response;
    }

    async createReply(
        newReply: ReplyCreationParams,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/${newReply.parent_id}`;

        try {
            //api call to comment server, posting a reply
            const result = await this.httpClient
                .post<IResponse>(url, signedInput)
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err);
        }
    }

    async updateReply(
        data: ReplyUpdateData,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/${data.parent_id}/${data.reply_id}`;
        try {
            //api call to comment server, updating a reply
            const result = await this.httpClient
                .patch<IResponse>(url, signedInput)
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err);
        }
    }

    async deleteReply(
        data: ReplyDeleteData,
        signedInput: BaseApiInput
    ): Promise<IResponse | never> {
        const url = `${this.path}/${data.parent_id}/${data.reply_id}`;

        try {
            let params = new HttpParams();

            for (const [key, val] of Object.entries(signedInput)) {
                params = params.set(key, val as string); //since HttpParams is immutable, reasingment needed
            }

            //api call to comment server, deleting a comment
            const result = await this.httpClient
                .delete<IResponse>(url, { params: params })
                .toPromise();
            this.log.info(
                `${result.status} ${url}: successfully deleted reply`
            );
            return result;
        } catch (err) {
            this.handleError(err);
        }
    }

    /**
     * encrypt a comment/reply content using a naked comment key
     * @param key naked/decrypted comment key
     * @param content a comment/reply content
     * @return content String based64 encrypted string content
     */
    public async encryptContent(key: string, content: string): Promise<string> {
        const encryptedData = await this.crypt.filedataEncrypt(
            this.crypt.stringToBytes(content),
            this.crypt.b64ToBytes(key),
            0
        );
        return this.crypt.bytesToB64(encryptedData);
    }

    /**
     * decrypt comment/reply content using a naked comment key
     * @param key decrypted/naked comment key
     * @param content comment/reply content
     * @param id comment or reply ID
     * @return content String the naked content
     */
    public async decryptContent(
        key: string,
        content: string,
        id: string
    ): Promise<string> {
        try {
            const decryptedData = await this.crypt.filedataDecrypt(
                this.crypt.b64ToBytes(content),
                this.crypt.b64ToBytes(key),
                0
            );
            return this.crypt.bytesToString(decryptedData);
        } catch (err) {
            // console.log('CDK: ' + key);
            // console.log('comment content: ' + content);
            this.log.warn(
                `failed to decrypt content for comment or reply id: ${id}. Ignoring...`
            );
        }
    }

    public async getCommentCount(
        locIds: string[]
    ): Promise<LocIdCountPair | null> {
        const url = `${this.path}/count`;
        const input: BaseApiInput = {
            servtime: Date.now(),
            access_token: '',
            signature: '',
        };
        try {
            const signedInput = await this.crypt.signApiReq(input);

            let params = new HttpParams();

            for (const [key, val] of Object.entries(signedInput)) {
                params = params.set(key, val); //since HttpParams is immutable, reasingment needed
            }

            params = params.append('locIds', locIds.join(','));

            //api call to comment server
            const result = await this.httpClient
                .get<IResponse>(url, { params: params })
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            const response: SuccessRes = result;
            if (response.data) {
                return Promise.resolve(response.data as LocIdCountPair);
            } else { return Promise.resolve(null); }
        } catch (err) {
            this.handleError(err);
        }
    }

    public async checkNewComments(signedInput: BaseApiInput) {
        const url = `${this.path}/peek`;
        try {
            let params = new HttpParams();

            for (const [key, val] of Object.entries(signedInput)) {
                params = params.set(key, val as string); //since HttpParams is immutable, reasingment needed
            }
            //api call to comment server
            const result = await this.httpClient
                .get<IResponse>(url, { params: params })
                .toPromise();
            this.log.info(`${result.status} ${url}: successful`);
            const response: SuccessRes = result;
            return response.data as boolean;
        } catch (err) {
            this.handleError(err);
        }
    }
}
