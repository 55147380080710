import { Injectable, Injector } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { NotificationsService } from '../../core/notifications.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { TransferStatus } from '../transfer.model';
import { BuildTransferItemService } from './build-transfer-item.service';
import { TransferItemService } from './transfer-item.service';
import { FileUploader } from '../file-uploader';

interface IWorkItem {
  pid?: number;
  fullPath: string;
  pidPath: string;
  file: File;
}
interface IDirMappingList {
  [fullPath: string]: IDirItem;
}
interface IDirItem {
  id?: number;
  fullPath: string;
  name: string;
}


@Injectable({
  providedIn: 'root'
})
export class RunUploadFolderService {

  public view: sync.IUploadFolderView = {
    fileCount: 0,
    filePendingCount: 0,
    cwdName: '',
    currentFolder: '',
    folderCount: 0,
    folderMadeCount: 0,
    totalBytes: 0,
    pendingBytes: 0,
    fileErrorCount: 0,
    current: undefined,
    isProcessing: false,
    isMakingFolders: false,
    isMakingTransfers: false,
    isScanning: false,
    eta: 0,
    error: undefined
  };

  public workDirs: IWorkItem[] = [];

  public queueFiles: IWorkItem[] = [];

  public shouldCancel = false;

  public cwd: sync.IFile;

  public dirMap: IDirMappingList = {};

  public workFiles: sync.ITransferItemUpload[] = [];

  public folderUploadInProgress = false;

  private fileUploader: FileUploader;

  constructor(
    private transferItemService: TransferItemService,
    private loggerService: LoggerService,
    private buildTransferItemService: BuildTransferItemService,
    private notificationsService: NotificationsService,
    private broadcastService: BroadcastService,
    private injector: Injector
  ) {
    this.fileUploader = new FileUploader(this.injector);
  }

  public restart(): void {
    const result: sync.ITransferItemUpload[] = [];
    let bytes = 0;
    for (let i = 0, len = this.workFiles.length; i < len; i++) {
      const tItem = this.workFiles[i];
      if (tItem.status !== TransferStatus.STATUS_SUCCESS) {
        tItem.status = TransferStatus.STATUS_WAITING;
        tItem.chunkqueue = this.transferItemService.getChunkQueue(tItem);
        bytes += tItem.filesize;
        result.push(tItem);
      }
    }
    this.reset();
    this.workFiles = result;
    this.view.fileCount = result.length;
    // this.view.filePendingCount = result.length;
    // this.view.pendingBytes = bytes;
    this.view.totalBytes = bytes;
    this.view.error = undefined;
    this.runUploadQueue();
  }

  public async runUploadQueue(): Promise<any> {
    if (this.shouldCancel) {
      this.loggerService.error('Cancel requested');
      throw { code: 7210 };
    }

    if (this.workFiles.length) {
      const next = this.buildTransferItemService.getLatestItem(this.workFiles);
      this.view.filePendingCount = next.pending;
      this.view.pendingBytes = next.pendingBytes;

      if (next.idx !== -1) {

        this.view.isProcessing = true;
        const tItem = this.workFiles[next.idx];
        if (tItem) {
          this.view.current = tItem;
          try {
            const stats = await this.fileUploader.uploadItem(tItem);
            const timePreUpload = this.view.filePendingCount * stats.elapsedPreUpload;
            const timeFinishUpload = this.view.filePendingCount * stats.elapsedFinishUpload;
            const bps = stats.size / stats.elapsedUpload;
            const estimated = this.view.pendingBytes / bps;
            const total = (timePreUpload + timeFinishUpload + estimated) / 1000;
            this.view.eta = total;
            this.loggerService.info('Estimated time remaining ' + total + ' seconds');
            return await this.runUploadQueue();
          } catch (ex) {
            this.view.fileErrorCount += 1;
            this.loggerService.error('Uploading failed for ' + tItem.backup_id);
            this.loggerService.info('runUploadQueue()');
            return await this.runUploadQueue();
          }
        } else {
          this.loggerService.error('Could not locate transfer item');
          return await this.runUploadQueue();
        }
      } else {
        // could not find any more, I think we're done.
        this.loggerService.info('Completed upload: ' + this.view.fileCount);
        this.notificationsService.startNotificationLoop();
        this.broadcastService.broadcast('event:link-file-list.reload');

        this.view.isProcessing = false;
        return true;
      }
    } else {
      this.view.isProcessing = false;
      this.notificationsService.startNotificationLoop();
      return false;
    }
  }

  public reset(): void {
    this.view.fileCount = 0;
    this.view.filePendingCount = 0;
    this.view.fileErrorCount = 0;
    this.view.cwdName = '';
    this.view.currentFolder = '';
    this.view.folderCount = 0;
    this.view.folderMadeCount = 0;
    this.view.totalBytes = 0;
    this.view.pendingBytes = 0;
    this.view.error = undefined;
    this.view.current = undefined;
    this.view.isScanning = false;
    this.view.isProcessing = false;
    this.view.isMakingFolders = false;
    this.view.isMakingTransfers = false;
    this.view.error = undefined;

    this.dirMap = {};
    this.workFiles = [];
    this.workDirs = [];
    this.queueFiles = [];
    this.shouldCancel = false;
    this.cwd = undefined;
    this.folderUploadInProgress = false;
  }

  public cancel(): void {
    this.shouldCancel = true;
    this.reset();
  }

}
