import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class OrderSessionNewApiOutput extends BaseApiOutput {
    sessionId: string;
}

export class OrderSessionNewApiInput extends BaseApiInput {
}


