import { Component, OnInit, Input } from '@angular/core';
import { Device, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-device-permission',
    templateUrl: './dialog-device-permission.component.html'
})
export class DialogDevicePermissionComponent implements OnInit {

    @Input() device: Device;
    public errcode: ErrCode;

    public changepass: string;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService
    ) { }

    ngOnInit() {
        this.changepass = (this.device.permission.perCHANGEPASS) ? '1' : '0';
    }

    public async save() {
        console.log(this.changepass);
        try {
            await this.api.execute('devicepermission', {
                permission: 'perCHANGEPASS',
                device_id: this.device.id,
                value: parseInt(this.changepass, 10)
            });
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }
}

