import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { RootLayoutComponent } from './root-layout/root-layout.component';
import { PreviewLayoutComponent } from './preview-layout/preview-layout.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '../notifications/notifications-module';
import { NoSignupLayoutComponent } from './no-signup-layout/no-signup-layout.component';
import { ShareInviteLayoutComponent } from './share-invite-layout/share-invite-layout.component';
import { SlackLayoutComponent } from './slack-layout/slack-layout.component';
import { FooterLayoutComponent } from './footer-layout/footer-layout.component';
import { PreviewLinkLayoutComponent } from './preview-link-layout/preview-link-layout.component';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    RootLayoutComponent,
    PreviewLayoutComponent,
    NoSignupLayoutComponent,
    ShareInviteLayoutComponent,
    SlackLayoutComponent,
    FooterLayoutComponent,
    PreviewLinkLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NotificationsModule
  ],
  exports: [
    AuthLayoutComponent,
    RootLayoutComponent,
    PreviewLayoutComponent,
    NoSignupLayoutComponent,
    ShareInviteLayoutComponent,
    SlackLayoutComponent,
    FooterLayoutComponent
  ]
})
export class LayoutModule { }
