import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
@Directive({
    selector: '[syncShowHidePassword]'
})
export class SyncShowHidePasswordDirective implements AfterViewInit {
    @Input() private identity: string;
    private _shown = false;
    private eye = `<i alt="show" class="fa fa-eye"></i>`;
    private eyeSlash = `<i alt="hide" class="fa fa-eye-slash"></i>`;
    constructor(private el: ElementRef) { }
    ngAfterViewInit(): void {
        this.setup();
    }
    toggle(span: HTMLElement) {
        this._shown = !this._shown;
        this.el.nativeElement.setAttribute('type', this._shown ? 'text' : 'password');
        span.innerHTML = this._shown ? this.eye : this.eyeSlash;
    }
    setup() {
        const parent = this.el.nativeElement.parentNode;
        const span = document.createElement('span');
        span.classList.add('showhideIcon');
        span.innerHTML = this.eyeSlash;
        span.addEventListener('click', () => {
            this.toggle(span);
        });
        parent.classList.add('passcover');
        parent.classList.add(this.identity);
        parent.appendChild(span);
    }
}
