<div class="modal-header">
    <button type="button" class="close" (click)="close()">
        <i class="ion-android-close fa-lg"></i>
    </button>
    <h4 class="modal-title">
        <i class="ion-android-people fa-lg"></i>
        Manage Share {{ shareData.name }}
    </h4>
</div>
<div class="modal-body">
    <div style="display: flex">
        <div class="table-modalsharename" style="width: 30%">
            Select new owner:
        </div>
        <div style="border-radius: 10px; width: 80%; align-items: center; margin-left: 40px; position: relative;">
            <!-- <div style="align-items: right; position: absolute; top: 3px; right: 14px; font-size: 20px;">
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </div> -->
            <select id="autologout-ttl" class="form-control" (change)="onSelectOwner($event.target.value)" name="owner selector" style="width: 100%; padding: 7px; border-radius: 5px;">
                <option value="">Select new owner:</option>
                <option
                    *ngFor="let member of members"
                    style="cursor: pointer; padding: 4px 9px; border-radius: 4px;"
                    [value]="member.email"
                >
                    {{ member.email }}
                </option>
            </select>
        </div>
    </div>
    <div style="display: flex; margin-top: 40px;">
        <div class="table-modalsharename" style="width: 30%">
            Warning:
        </div>
        <div style="width: 70%; margin-left: 12px">
            You are about to change the ownership of your shared folder <b>{{ shareData.name }}</b> to the share member selected above. After this change you will still have access to the share folder, but permissions will no longer be managed by you. This action cannot be undone.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        id="btn-share-new-cancel"
        type="button"
        class="btn btn-default"
        (click)="close()"
    >
        Cancel
    </button>
    <button (click)="onChangeOwnership()" [disabled]="!selectedMember" class="btn btn-primary pull-right">Continue</button>
</div>
