import { BaseApiOutput } from './base-api.model';
export class PlanDetailsApiOutput extends BaseApiOutput {
    zuora_acct_key: string;
    plan_user_limit: string;
    plan_product_id: string;
    plan_rate_plan_id: string;
    plan_rate_plan_charge_id: string;
}

export enum PlanTerm {
    MONTHLY = 'MONTHLY',
    ANNUAL = 'ANNUAL',
}
