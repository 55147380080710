import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'sync-dialog-share-choose-owner',
    templateUrl: './dialog-choose-share-owner.component.html'
  })
export class DialogShareChooseOwnerComponent implements OnInit {

    @Input('selected') shareData: sync.IShareData;
    public selectedMember;
    public members = [];

    constructor(
        public activeModal: NgbActiveModal,
    ) {}
    ngOnInit(): void {
        this.members = this.shareData.people.filter(member => !(
                member.isMe ||
                ['Waiting Signup', 'Waiting'].includes(member.status)
            )
        );
        if ( this.members.length === 0 ) { this.activeModal.close(); }
    }
    public onSelectOwner(selectedEmail: string) {
        this.selectedMember = this.members.find(member => member.email === selectedEmail);
    }
    public onChangeOwnership() {
        if (!this.selectedMember) { return; }
        this.close(this.selectedMember.email);
    }
    public close(newOwnerEmail?: string) {
        this.activeModal.close(newOwnerEmail);
    }
}
