import { SharememberItem } from './sharemember-item.model';
import { Deserializable } from './deserializable.model';
import { ShareGetApiOutput } from './api/shareget-api.model';
export class ShareData implements Deserializable<ShareData> {
    people: SharememberItem[];
    syncId: number;
    encName: string;
    label: string;
    inviteStatus: string;
    isReadOnly: 1 | 0;
    isOwner: 1 | 0;
    isSeeOthers: 1 | 0;
    isInvite: 1 | 0;
    shareId: number;
    shareSequence: number;
    sharememberId: number;
    encPassword: string;
    salt: string;
    iterations: number;
    name: string;

    public deserialize(input: ShareGetApiOutput) {
        const data = input.share;
        this.syncId = data.sync_id;
        this.encName = data.enc_name;
        this.label = data.label;
        this.inviteStatus = data.invite_status;
        this.isReadOnly = data.is_readonly;
        this.isOwner = data.is_owner;
        this.isSeeOthers = data.is_seeothers;
        this.isInvite = data.is_invite;
        this.shareId = data.share_id;
        this.shareSequence = data.share_sequence;
        this.encPassword = data.enc_password;
        this.salt = data.salt;
        this.iterations = data.iterations;
        // this.people = input.people;
        // for (let i = 0, len = data.people.length; i < len; i++) {
        //     this.people.push(new SharememberItem().deserialize(data.people[i]));
        // }
        return this;
    }
}
