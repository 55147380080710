import { Component, OnInit, Input } from '@angular/core';
import { Device } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-dialog-device-rename',
    templateUrl: './dialog-device-rename.component.html',
})
export class DialogDeviceRenameComponent implements OnInit {

    @Input() device: Device;

    public newName: string;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService
    ) { }

    ngOnInit() {
    }

    public async save() {
        await this.api.execute('devicerename', {
            device_id: this.device.id,
            devicename: this.newName
        });
        this.activeModal.close(true);
    }
}
