
<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-cog fa-lg"></i> Two-Factor Authentication</h4>
</div>

<div class="modal-body">
    <sync-error *ngIf="errcode || !showBackupCodeDialog" [errcode]="errcode"></sync-error>
    <p *ngIf="twofaType == TWOFATYPE_EMAIL">
        
        2-FA enabled (sends to '{{ user.email }}').
        <a (click)="disable2FA()" class="syncblue showhand">Disable</a>
    </p>

    <!-- if enabled google auth -->
    <p *ngIf="twofaType == TWOFATYPE_GOOGLE">
        2-FA enabled (Google Authenticator).
        <a (click)="disable2FA()" class="syncblue showhand">Disable</a>
    </p>

    <div *ngIf="(twofaType == TWOFATYPE_GOOGLE || twofaType == TWOFATYPE_EMAIL) && !showBackupCodeDialog">
        <div *ngIf="user.backup_code_count > 1"> You have {{user.backup_code_count}} two-factor back-up codes remaining</div>
        <div *ngIf="user.backup_code_count == 1">You have {{user.backup_code_count}} two-factor back-up code remaining</div>
        <!-- if user.backup_code_count returns -1, no message is displayed -->
        <hr style="height:2px;border-width:0;color:gray;background-color:gray"/>
        To generate new backup codes, please enter your password: <br/>
        <b class="text-warning">Warning: All of your old back-up codes will be deleted from the system</b> <br/>
        <!--
        fake fields are a workaround for chrome autofill getting the wrong fields.
        Browsers stopped honouring autocomplete=off, which means anywhere they
        see an input type of password, the visitor's password (if stored) will
        be plastered.  They've removed the ability for the HTML to dictate
        whether it should be there or not.

        These two fields must be above the real fields
    -->
        <input style="visibility:hidden; width: 1px; height: 1px;" type="password" name="fakepasswordremembered"/>
        <div class="row">
            <div class="col-sm-2">Password:</div>
            <div class="col-sm-5">
                <input
                    type="password"
                    required
                    autocomplete="off"
                    class="form-control"
                    id="change-username-acct-password"
                    placeholder="Enter your account password"
                    [(ngModel)]="password"
                    syncShowHidePassword
                    identity="twofactorpass"
                />
            </div>
            <div class="col-sm-2">
                <button type="button" 
                    class="btn btn-primary" 
                    (click)="generateBackupCodes()" 
                    [disabled]="spinner || !password">
                    <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
                    Generate New Backup Codes
                </button>
            </div>
        </div>
    </div>
        
    <!-- if disabled -->
    <div *ngIf="twofaType == TWOFATYPE_NONE">
        <a (click)="update2FA()" class="syncblue showhand">Enable 2-FA via email</a> (easiest)
        <p>You will be prompted for a 2-FA code during web login, which will be sent to '{{ user.email }}'.</p>

        <hr />

        <a *ngIf="!isGoogleAuth2faDisabled" (click)="showGoogleAuth()" class="syncblue showhand">Enable 2-FA via Google Authenticator</a>
        <p *ngIf="!isGoogleAuth2faDisabled">You will be prompted for a 2-FA code during web login, which will be provided by your Google Authenticator app. You will need to install Google Authenticator first.</p>
    </div>
    
    <div *ngIf="showGoogleAuthForm">

        <label>Connect Sync to Google Authenticator:</label>
        <ol>
            <li>Install Google Authenticator for iOS or Android.</li>
            <li>In Google Authenticator, tap &quot;+&quot;, and then &quot;Manual Entry.&quot;</li>
            <li>
                Under Account enter sync.com, and scan this QR code:<br />
                <qrcode [qrdata]="qrdata" [size]="200" [level]="'M'"></qrcode>
                <br/>
                Or enter manually<br/>
                <pre>
                    {{twofakey}}
                </pre>
            </li>
            <li>
                Google Authenticator will give you a 6-digit verification code. Enter it here and press Save:<br />
                <div class="input-group">
                    <input
                        type="text"
                        required
                        id="verification_code"
                        [(ngModel)]="verificationCode"
                        class="form-control"
                    />
                    <span class="input-group-btn">
                        <button 
                            class="btn btn-primary"
                            type="button"
                            (click)="updateGoogleAuth()"
                            [disabled]="spinner || !verificationCode || (twofaType == TWOFATYPE_GOOGLE)"
                        >Save</button>
                    </span>
                </div><!-- /input-group -->
            </li>
        </ol>
       
    </div> 
    <div *ngIf="showBackupCodeDialog">
        <hr style="height:2px;border-width:0;color:gray;background-color:gray"/>
            <!-- Please <a (click)="downloadBackupCodes();" class="syncblue showhand">
                click here</a> to download a file containing 10 backup codes for 2-step authentication
            and keep it secret; or copy and paste the following comma-separated codes to a file on your device -->
            Please download or copy the codes below and keep them secret
            <pre style="white-space: pre-line;border-style: none;">
                {{backupCodesString}}
            </pre>
            <div class="col-sm-2">
                <button type="button" id="downloadCodes" class="btn btn-primary" (click)="downloadBackupCodes();">Download</button>
            </div>
            <div class="col-sm-2">
                <button type="button" id="clipboard" class="btn btn-primary" (click)="copyCodesToClipboard()">Copy to Clipboard</button>
            </div>
    </div>
</div>
    
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>
    
