import { Component, OnInit, Input } from '@angular/core';
import { ErrCode } from '../../shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Base64Service } from '../../core/base64.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';

@Component({
    selector: 'sync-dialog-accthold-cancel',
    templateUrl: './dialog-accthold-cancel.component.html'
})
// @Deprecated (Please use DialogCancelComponent)
export class DialogAcctholdCancelComponent implements OnInit {

    @Input() userId: number;
    @Input() plan: number;
    @Input() sig: string;

    public cancelForm: FormGroup;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private base64: Base64Service,
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.cancelForm = this.fb.group({
            subject: ['', Validators.required],
            description: ['', Validators.required],
            confirm: ['', Validators.required]
        });
    }

    public async onSubmit() {
        try {
            this.spinner = true;
            await this.api.send('acctcancel', {
                source: 'accthold',
                user: this.userId,
                p: this.plan,
                sig: this.sig,
                subject: this.base64.encode(this.cancelForm.get('subject').value),
                message: this.base64.encode(this.cancelForm.get('description').value)
            });
            window.location.href = 'https://www.sync.com/support/?thank=you';
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
        }
    }

}
