'use strict';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ErrorDisplayHashComponent } from './error-display-hash/error-display-hash.component';
import { ErrorDisplayComponent } from './error-display/error-display.component';
// import Logger                           from './logger-service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    providers: [],
    declarations: [ErrorDisplayComponent, ErrorDisplayHashComponent],
    entryComponents: [ErrorDisplayComponent, ErrorDisplayHashComponent],
    exports: [ErrorDisplayComponent, ErrorDisplayHashComponent]
})
export class ErrorModule {}
