<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="modalInstance.close()"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Manage share {{ shareData.name }}</h4>
</div>
<div class="modal-body modal-body-overflow">
    <div class="row" id="manage-share-data-modal">
        <div class="col-sm-12">
            <sync-error-display-hash
                [errors]="errors"
            ></sync-error-display-hash>

            <table class="table list-table list-table-modal">
                <thead>
                    <tr>
                        <th class="table-checkbox"></th>
                        <th class="table-modalsharename">Name</th>
                        <th class="table-modalsharestatus hidden-xs">Status</th>
                        <th
                            class="table-modalshareapps hidden-xs"
                            *ngIf="
                                shareData.app_integrated > 0 &&
                                hasAppsNewFeature
                            "
                        >
                            Apps
                        </th>
                        <th class="table-modalshareactions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr [hidden]="shareData.people.length">
                        <td colspan="4">
                            <p>No member names are viewable for this share</p>
                        </td>
                    </tr>
                    <tr
                        [ngClass]="{ active: member.selected }"
                        *ngFor="
                            let member of shareData.people
                                | orderBy: predicate:false
                        "
                    >
                        <td class="table-checkbox">
                            <i
                                (click)="member.selected = !member.selected"
                                *ngIf="shareData.isOwner && !member.isMe"
                                [ngClass]="{
                                    'far fa-square': !member.selected,
                                    'fas fa-check-square': member.selected
                                }"
                            ></i>
                        </td>
                        <td class="table-modalsharename">
                            <div class="tdmore">
                                <span *ngIf="member.isMe">You</span>
                                <span
                                    *ngIf="!member.isMe"
                                    [innerHtml]="member.displayname"
                                ></span>
                            </div>
                            <div
                                class="tdmore subtext"
                                [innerHtml]="member.email"
                            ></div>
                        </td>
                        <td
                            class="table-modalsharestatus hidden-xs"
                            [ngClass]="{
                                'table-modalsharestatus-inactive':
                                    member.user_status_id < -1
                            }"
                        >
                            <div
                                class="tdmore"
                                *ngIf="member.permissions.perOWNER"
                            >
                                Owner
                            </div>
                            <a
                                *ngIf="member.permissions.perOWNER && member.isMe && canChangeOwner"
                                (click)="chooseNewOwner()"
                                class="syncblue tdmore subtext"
                            >
                                Change owner
                            </a>
                            <div
                                class="tdmore"
                                *ngIf="!member.permissions.perOWNER"
                            >
                                <span [ngSwitch]="member.user_status_id">
                                    <span *ngSwitchCase="-2"
                                        ><i
                                            class="fa fa-exclamation-triangle"
                                        ></i>
                                        Account closed</span
                                    >
                                    <span *ngSwitchCase="-5"
                                        ><i
                                            class="fa fa-exclamation-triangle"
                                        ></i>
                                        Account suspended</span
                                    >
                                    <span *ngSwitchDefault>
                                        {{ member.status }}
                                    </span>
                                </span>
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="
                                    (member.status == 'Waiting' ||
                                        member.status == 'Waiting Signup') &&
                                    shareData.isOwner
                                "
                            >
                                <a
                                    (click)="resendInvite(member)"
                                    class="syncblue"
                                    *ngIf="memberResent != member.sharememberId"
                                >
                                    <i class="fas fa-envelope"></i>
                                    Resend invitation
                                </a>
                                <span
                                    *ngIf="memberResent == member.sharememberId"
                                    >Invitation sent.</span
                                >
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="
                                    member.status == 'Active' &&
                                    !member.permissions.perOWNER
                                "
                            >
                                <span [ngSwitch]="member.user_status_id">
                                    <span *ngSwitchCase="-2"
                                        >You may safely remove this user.</span
                                    >
                                    <span *ngSwitchCase="-5"></span>
                                    <span *ngSwitchDefault>
                                        View
                                        <span
                                            *ngIf="member.permissions.perUPLOAD"
                                            >, Edit</span
                                        >
                                        <span
                                            *ngIf="
                                                member.permissions.perSEEOTHERS
                                            "
                                            >, See</span
                                        >
                                        <span
                                            *ngIf="member.permissions.perINVITE"
                                            >, Invite</span
                                        >
                                        <span
                                            *ngIf="
                                                member.permissions.perADDAPP &&
                                                hasAppsNewFeature
                                            "
                                            >, Add Apps</span
                                        >
                                    </span>
                                </span>
                            </div>
                            <div
                                class="tdmore subtext"
                                *ngIf="
                                    member.status == 'Active' &&
                                    member.permissions.perOWNER
                                "
                            >
                                <span [ngSwitch]="member.user_status_id">
                                    <span *ngSwitchCase="-2"
                                        ><i
                                            class="fa fa-exclamation-triangle"
                                        ></i>
                                        Account closed</span
                                    >
                                    <span *ngSwitchCase="-5"
                                        ><i
                                            class="fa fa-exclamation-triangle"
                                        ></i>
                                        Account suspended</span
                                    >
                                    <span *ngSwitchDefault></span>
                                </span>
                            </div>
                        </td>
                        <td
                            class="table-modalshareapps hidden-xs"
                            *ngIf="
                                shareData.app_integrated > 0 &&
                                hasAppsNewFeature
                            "
                            [ngClass]="{
                                'table-modalshareapps-inactive':
                                    member.user_status_id < -1
                            }"
                        >
                            <div class="tdmore">
                                {{ member.num_apps }}
                            </div>
                        </td>
                        <td class="table-modalshareactions">
                            <div
                                ngbDropdown placement="bottom-right"
                                class="dropdown"
                                *ngIf="
                                    !member.permissions.perOWNER &&
                                    shareData.isOwner &&
                                    (member.user_status_id == 1 ||
                                        member.user_status_id == -1)
                                "
                            >
                                <button
                                    ngbDropdownToggle
                                    type="button"
                                    class="btn btn-default btn-xs"
                                    id="permission-dropdown-btn"
                                >
                                    Permissions <span class="caret"></span>
                                </button>

                                <ul
                                    ngbDropdownMenu 
                                    class="dropdown-menu dropdown-menu-right dropdown-small dropdown-inline"
                                    aria-labelledby="dLabel"                                 >
                                    <i
                                        class="fa fa-spin fa-spinner"
                                        *ngIf="spinner"
                                    ></i>
                                    <li [ngClass]="{ disabled: spinner }">
                                        <a [attr.disabled]="1" (click)="$event.stopPropagation();"
                                            ><i class="fas fa-check-square"></i>
                                            Can view</a
                                        >
                                    </li>

                                    <li
                                    [attr.disabled]="isPro == 0"
                                    id="canedit-check-box"
                                        [ngClass]="{
                                            disabled: !isPro,
                                            disabled: spinner
                                        }"
                                    >
                                        <a
                                        [attr.disabled]="isPro == 0"
                                            title="Disable user to Edit/Update files for this share"
                                            (click)="$event.stopPropagation();
                                                !isPro ||
                                                    setPermission(
                                                        'perUPLOAD',
                                                        member
                                                    )
                                            "
                                        >
                                            <i
                                                [ngClass]="{
                                                    'fas fa-check-square':
                                                        member.permissions
                                                            .perUPLOAD,
                                                    'far fa-square':
                                                        !member.permissions
                                                            .perUPLOAD,
                                                    showhand: !spinner
                                                }"
                                            ></i>
                                            Can edit
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="member.status == 'Active'"
                                        [ngClass]="{ disabled: spinner }"
                                    >
                                        <a
                                            tooltip-placement="left"
                                            title="Allows this person to invite more people to this share"
                                            (click)="$event.stopPropagation();
                                                setPermission(
                                                    'perINVITE',
                                                    member,
                                                    $event
                                                )
                                            "
                                        >
                                            <i
                                                [ngClass]="{
                                                    'fas fa-check-square':
                                                        member.permissions
                                                            .perINVITE,
                                                    'far fa-square':
                                                        !member.permissions
                                                            .perINVITE,
                                                    showhand: !spinner
                                                }"
                                            ></i>
                                            Can Invite
                                        </a>
                                    </li>
                                    <li [ngClass]="{ disabled: spinner }">
                                        <a
                                            tooltip-placement="left"
                                            title="Grants the ability to see the names of who is on this share"
                                            (click)="$event.stopPropagation();
                                                setPermission(
                                                    'perSEEOTHERS',
                                                    member
                                                )
                                            "
                                        >
                                            <i
                                                [ngClass]="{
                                                    'fas fa-check-square':
                                                        member.permissions
                                                            .perSEEOTHERS,
                                                    'far fa-square':
                                                        !member.permissions
                                                            .perSEEOTHERS,
                                                    showhand: !spinner
                                                }"
                                            ></i>
                                            See others
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            tooltip-placement="left"
                                            title="Grants the ability to add personal apps to this share"
                                            (click)="$event.stopPropagation();
                                                setPermission(
                                                    'perADDAPP',
                                                    member
                                                )
                                            "
                                            *ngIf="hasAppsNewFeature"
                                        >
                                            <i
                                                class="showhand"
                                                [ngClass]="{
                                                    'fas fa-check-square':
                                                        member.permissions
                                                            .perADDAPP,
                                                    'far fa-square':
                                                        !member.permissions
                                                            .perADDAPP
                                                }"
                                            ></i>
                                            Add apps
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-info">
                <span [ngPlural]="shareData.people.length">
                    <ng-template ngPluralCase="=1"> 1 user </ng-template>
                    <ng-template ngPluralCase="other"> {{ shareData.people.length }} users </ng-template>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        *ngIf="shareData.isOwner"
        (click)="openPopup(4)"
        type="button"
        class="btn btn-default pull-left"
        [disabled]="getSelectedAmt() == 0"
    >
        Remove<span class="hidden-xs"> selected users</span>
    </button>

    <button
        *ngIf="!shareData.isOwner"
        (click)="openPopup(6)"
        type="button"
        class="btn btn-default pull-left"
        id="leave-share-btn"
    >
        Leave share
    </button>

    <button
        type="button"
        *ngIf="shareData.isOwner || shareData.isInvite"
        class="btn btn-default"
        id="add-users-btn"
        (click)="openPopup(3)"
    >
        + Add<span class="hidden-xs"> users</span>
    </button>
    <button
        type="button"
        *ngIf="shareData.isOwner"
        class="btn btn-primary"
        (click)="openPopup(2)"
    >
        <i class="fas fa-cog hidden-xs"></i>
        Folder settings
    </button>
    <button
        type="button"
        *ngIf="!shareData.isOwner"
        class="btn btn-default"
        (click)="modalInstance.close()"
    >
        Close
    </button>
</div>
