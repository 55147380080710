<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal" (click)="activeModal.close();">&times;</button>
  <h4 class="modal-title"> Edit with Microsoft Office </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <b> Sign into your Sync account to edit this document online. </b>
      <br> <br>
      Online collaborative editing is available for Microsoft Word, Excel, and PowerPoint documents authorized by the file owner.
    </div>
  </div>
</div>
<div class="modal-footer">
  <a class="btn btn-default pull-left" href="https://www.sync.com/?_m=h73">Create Account</a>
  <a class="btn btn-primary pull-right" [href]="loginUrl + '?return_to_link=' + currentPath">Sign in now</a>
</div>