import { Component, OnInit, Input } from '@angular/core';
import { User, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'sync-dialog-password-hint',
    templateUrl: './dialog-password-hint.component.html'
})
export class DialogPasswordHintComponent implements OnInit {


    @Input() user: User;

    public hint: string;
    public spinner: boolean;
    public errcode: ErrCode;
    public edit: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.spinner = false;
        this.errcode = undefined;
    }

    public async save() {
        this.spinner = true;
        this.errcode = undefined;
        try {
            await this.api.execute('passwordhintupdate', {
                password_hint: this.hint
            });
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
        console.log('save');
    }
}
