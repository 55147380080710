<!-- global out of space message -->
<div class="error-general-overlay" [class.hide]="!notifications?.maintenance">
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-exclamation-triangle"></i> Your account is temporarily in maintenance mode. Syncing will be read-only at this time.
            Please <a href="https://sync.statuspage.io/" style="color: #00ADEF !important;">visit our status page</a> for further information and updates.
        </div>
    </div>
</div>

<div
    class="error-general-overlay"
    [hidden]="notifications?.disk_usage_bytes <= notifications?.disk_limit_bytes"
>
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-exclamation-triangle"></i>

            Your account is full.
            <a routerLink="/account/upgrade" style="color: #00adef !important"
                >Get more space</a
            >
            or delete files.
            <!-- {{(notifications.disk_usage_bytes - notifications.disk_limit_bytes) | bytesToSize}} -->
        </div>
    </div>
</div>

<!-- global errors -->
<sync-error-display-hash
    [errors]="feedbackView?.globalErrors"
></sync-error-display-hash>

<!-- global feedback -->
<div class="error-general-overlay" [hidden]="!feedbackView.showFeedback">
    <div class="row">
        <div class="col-xs-9">
            <i  
                class="fas"
                [ngClass]="{
                    'fa-info-circle': feedbackView.feedbackType == 'info',
                    'fa-check-circle':
                        feedbackView.feedbackType == 'success',
                    'fa-exclamation-triangle':
                        feedbackView.feedbackType == 'danger'
                }"
            ></i>

            {{ feedbackView.feedbackMsg }}
        </div>
        <div class="col-xs-3 text-right">
            <i (click)="closeFeedback()" class="fas fa-times showhand"></i>
        </div>
    </div>
</div>

<!-- task notifications -->
<div
    class="error-general-overlay"
    [hidden]="notifications?.globalTaskStatus <= 0"
>
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-info-circle"></i>
            <span *ngIf="notifications?.globalTaskStatus == 1">
                The rewind task will start soon.
            </span>
            <span *ngIf="notifications?.globalTaskStatus == 2">
                The rewind is running.
            </span>
            <span *ngIf="notifications?.globalTaskStatus == 3">
                Rewind has failed, please contact us.
            </span>
            <span *ngIf="notifications?.globalTaskStatus == 4">
                Rewind has completed.
            </span>
        </div>
    </div>
</div>

<!-- share and encryption notifications -->
<div class="error-general-overlay" [hidden]="!notifications?.is_taskrunning">
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-sync fa-spin"></i>
            <span [hidden]="!notifications?.is_taskrunningShared">
                A shared folder is loading. Please leave your browser open.
            </span>
            <span [hidden]="notifications?.is_taskrunningShared">
                Your account is processing changes. Please leave your browser open.
            </span>
        </div>
    </div>
</div>

<!-- error message processing dirty in or out. -->
<div
    class="error-general-overlay"
    [hidden]="notifications?.errorCount <= notifications?.errorCountLimit"
>
    <div class="row">
        <div class="col-xs-12">
            <i class="fas fa-exclamation-triangle"></i>
            Something went wrong. If the issue persists,
            <span class="showhand" syncOpenDialog>please contact us</span>.
        </div>
    </div>
</div>

<!-- error message more flag going crazy. -->
<div
    class="error-general-overlay"
    [hidden]="notifications?.runCount <= notifications?.runCountLimit"
>
    <div class="row">
        <div class="col-xs-9">
            <i class="fas fa-exclamation-triangle"></i>
            Something went wrong. If the issue persists,
            <span class="showhand" syncOpenDialog>please contact us</span>.
        </div>
        <div class="col-xs-3 text-right">
            <i
                (click)="restartNotifications()"
                class="fas fa-times showhand"
            ></i>
        </div>
    </div>
</div>

<!-- New feature bar -->
<div class="error-general-overlay" [hidden]="!featureBanner">
    <div class="row">
        <div class="col-xs-9">
            Experience seamless collaboration with Live Editing on Links.
            <a href="https://www.sync.com/help/how-to-manage-shared-link-permissions/#linkedit" style="color: #337ab7;"
                onmouseover="this.style.color='#337ab7'" (click)="closeFeedback('collaborativeLinks')">Click here to learn</a>
            more about our latest feature!
        </div>
        <div class="col-xs-3 text-right">
            <i (click)="closeFeedback('collaborativeLinks')" class="fas fa-times showhand"></i>
        </div>
    </div>
</div>