import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User, UserInfoSetApiOutput, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'sync-dialog-email-preference',
    templateUrl: './dialog-email-preference.component.html'
})
export class DialogEmailPreferenceComponent implements OnInit {

    @Input() user: User;

    public product: string;
    public marketing: string;
    public rewards: string;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.spinner = false;
        this.errcode = undefined;
        this.product = this.user.is_emailproduct.toString();
        this.marketing = this.user.is_emailmarketing.toString();
        this.rewards = this.user.is_emailrewards.toString();
    }

    public async save() {
        this.spinner = true;
        try {
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
                is_emailmarketing: parseInt(this.marketing, 10),
                is_emailproduct: parseInt(this.product, 10),
                is_emailrewards: parseInt(this.rewards, 10)
            });
            this.userService.refresh();
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }
}
