import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../core/api.service';
import { TransferService } from '../../transfer/transfer.service';
import { FileItemService } from '../file-item.service';

@Component({
    selector: 'sync-history',
    templateUrl: './history.component.html',
})
export class HistoryComponent implements OnInit {
    // public active: sync.IHistoryItem;
    public events: sync.IHistoryItem[] = [];
    public alerts: sync.IErrorCode[] = [];
    public success = false;
    public predicate = 'hist_id';
    public reverse = true;
    public currentItem: sync.IFile;
    public isReadOnly = false;

    private syncId: number;
    private histId = 0;
    public initialized = false;
    public hasNextPage: boolean;
    public isLoaded: boolean;

    constructor(
        private apiService: ApiService,
        private fileItemService: FileItemService,
        private transferService: TransferService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.syncId = this.route.snapshot.params['id'];
        this.events = [];
        this.hasNextPage = true;

        const data = await this.pathGet(this.syncId);
        this.currentItem = await this.fileItemService.format(data);
        this.isReadOnly = this.currentItem.is_readonly > 0;
        this.initialized = true;
        this.getHistory();
    }

    public pathGet(sync_id: number) {
        return this.apiService.execute('pathget', {
            sync_id: sync_id,
        });
    }

    public restoreVersionApi(syncId: number, blobId: number) {
        return this.apiService.execute('restorewebversion', {
            sync_id: syncId,
            version_id: blobId,
        });
    }

    restoreVersion(blobId: number) {
        this.success = false;
        this.restoreVersionApi(this.syncId, blobId).then(
            (data) => {
                this.alerts = [];
                this.histId = 0;
                this.events = [];
                this.getHistory();
                this.success = true;
            },
            (err) => {
                if (typeof err === 'object' && err.error_code) {
                    this.alerts.push({
                        code: err.error_code,
                        msg: err.error_msg,
                    });
                } else {
                    this.alerts.push({
                        code: 1000,
                        msg: 'Error restoring the version history',
                    });
                }
            }
        );
    }

    dismiss() {
        this.success = false;
    }

    download(eventRow: sync.IHistoryItem) {
        eventRow.spinner = true;
        const dlVersion = Object.assign({}, this.currentItem);
        dlVersion.cachekey = eventRow.cachekey;
        dlVersion.blob_id = eventRow.blob_id;
        dlVersion.size = eventRow.size;
        dlVersion.blobtype = 'btFILE';

        for (const key in eventRow) {
            if (eventRow.hasOwnProperty(key)) {
                dlVersion[key] = eventRow[key];
            }
        }
        this.transferService.queueDownload([dlVersion]);
    }

    async getHistory() {
        this.isLoaded = false;
        const result = await this.getHistoryApi(this.syncId, this.histId);
        if (result.events.length) {
            this.events = this.events.concat(result.events);
            this.histId = result.hist_id;
        } else {
            this.hasNextPage = false;
        }
        this.isLoaded = true;
    }

    public async getHistoryApi(syncId: number, histId: number = 0): Promise<any> {
        const response = await this.historyApi(syncId, histId);
        response.events = this.decorate(response.events);
        return response;
    }

    public historyApi(sync_id: number, hist_id: number): Promise<any> {
        return this.apiService.execute('pathhistory', {
            sync_id: sync_id,
            hist_id: hist_id
        });
    }

    private decorate(events: sync.IHistoryItem[]): sync.IHistoryItem[] {
        let i,
            len,
            action,
            curEvent: sync.IHistoryItem,
            lastEvent;
        const retEvents = [],
            prevEvents: { [key: number]: sync.IHistoryItem } = {};
        console.log(events);
        for (i = 0, len = events.length; i < len; i++) {
            action = undefined;
            curEvent = events[i];
            lastEvent = prevEvents[curEvent.sync_id];

            if (!curEvent) {
                continue;
            }

            // don't duplicate rows in the path history
            if (
                lastEvent &&
                lastEvent.blob_id == curEvent.blob_id &&
                lastEvent.event_type == curEvent.event_type &&
                lastEvent.size == curEvent.size
            ) {
                continue;
            }
            switch (parseInt(curEvent.event_type, 10)) {
                case -2:
                    action = 'Purged';
                    break;
                case -1:
                    action = 'Deleted';
                    break;
                case 0:
                    action = 'Pending';
                    break;
                case 1:
                    action = 'Added';
                    break;
                case 2:
                    action = 'Restored';
                    break;
                case 3:
                    action = 'Edited';
                    break;
                default:
                    action = '';
            }
            curEvent.action_text = action;
            prevEvents[curEvent.sync_id] = curEvent;
            retEvents.push(curEvent);
        }
        return retEvents;
    }
}
