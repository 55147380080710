import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class RequestPassResetApiOutput extends BaseApiOutput {
    code: string;
}

export class RequestPassResetApiInput extends BaseApiInput {
    email: string;
}


