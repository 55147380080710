import { Component, OnInit, Input, Injector } from '@angular/core';
import { User, ErrCode, UserInfoSetApiOutput } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'sync-dialog-auto-logout',
    templateUrl: './dialog-auto-logout.component.html'
})
export class DialogAutoLogoutComponent implements OnInit {

    @Input() user: User;

    public ttl: string;
    public spinner: boolean;
    public errcode: ErrCode;
    public edit: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService
    ) { }


    ngOnInit() {
        this.ttl = this.user.sessionttl.toString();
    }

    public async save() {

        this.spinner = true;

        try {
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
                sessionttl: parseInt(this.ttl, 10)
            });
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }
}
