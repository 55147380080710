export class LinkOptions {
    sync_id: number;
    share_id: number;
    cachekey: number;
    label: string;

    exp_servtime?: number;
    download_limit?: number;
    upload?: number;
    password?: string;
    email_notification?: number;
}
