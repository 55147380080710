
import { Action } from '@ngrx/store';

import { ErrCode, User } from '../shared/models';

export const CORE_CLEAR = '[Core] Store clear';
export const CORE_SAVE_VALUE = '[Core] Save value';

export class StoreClearAction implements Action {
    readonly type = CORE_CLEAR;
}
export class SetValueAction implements Action {
    readonly type = CORE_SAVE_VALUE;
    constructor(public payload: StoreValue) {}
}
// export class StoreDecryptAction implements Action {
//     readonly type = ActionTypes.STORE_DECRYPT;
//     constructor(public payload: string) {}
// }

export interface StoreValue {
    key: string;
    value: string;
}


export type All = StoreClearAction | SetValueAction;
