<nav class="navbar navbar-default navbar-fixed-top navbar-white">
        <div class="navbar-header navbar-white-header">
            <a class="navbar-brand" href="/">
            <img id="navbar-logo" class="navbar-white-q" src="images/sync-q-lightbg.svg" /><img src="/images/sync-w-lightbg.svg"  />
            </a>
        </div>
        <div id="navbar" class="n">
            <ul class="nav navbar-nav navbar-right navbar-white-right">
                <li><a (click)="openContactSupport()">Help</a></li>
            </ul>
        </div>
    </nav>
    