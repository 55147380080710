import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { User, ErrCode, UserInfoSetApiOutput, UserInfoSetApiInput, PermissionActions, BlendEvent } from '../../shared/models';
import { zAccountInfo } from '../../shared/models/api/orderprofile-api.model';
import { LoggerService } from '../../core/logger.service';
import { BlendService } from 'src/app/shared/services/blend.service';


@Component({
    selector: 'sync-dialog-autorenew',
    templateUrl: './dialog-autorenew.component.html'
})
export class DialogAutorenewComponent implements OnInit {
    @Input() user: User;
    @Input() zprofile: zAccountInfo;

    public errcode: ErrCode;
    public autoRenew: string;

    public spinner: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private log: LoggerService,
        private userService: UserService,
        private blendService: BlendService
    ) { }

    ngOnInit() {
        if (this.userService.checkPermission(PermissionActions.MANAGE_BILLING)) {
            if (!this.zprofile) {
                this.log.error(`User in zuora ${this.user.zuora_acct_key} but no accountInfo provided from zproxy`);
                this.errcode = new ErrCode(8501);
            }
            this.autoRenew = (this.zprofile.autoPay) ? '0' : '1';
        } else {
            this.autoRenew = this.user.is_disableautorenew.toString();
        }
    }

    public async save() {
        try {
            this.spinner = true;
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', <UserInfoSetApiInput>{
                is_disableautorenew: parseInt(this.autoRenew, 10)
            });
            this.spinner = false;
            this.userService.refresh();
            this.activeModal.close();
            this.blendService.track(BlendEvent.CHANGE_ACCOUNT_SETTING, { autoRenew: !this.autoRenew, termEndDate: this.zprofile.subscription.termEndDate });
            this.blendService.setUserProperty({ is_auto_renew_enabled: !this.autoRenew, term_end_date: this.zprofile.subscription.termEndDate });
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
            this.log.error('An error occurred saving auto renew settings');
        }
    }
}
