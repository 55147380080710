import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../core/api.service';
import { DirtyOutService } from '../core/crypt/dirty-out.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { LinkFileListService } from '../link-consume/services/link-file-list.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { DialogFolderUploadComponent } from './dialog-folder-upload/dialog-folder-upload.component';
import { BuildTransferItemService } from './services/build-transfer-item.service';
import { RunUploadFolderService } from './services/run-upload-folder.service';
import { TransferService } from './transfer.service';
import { FileItemService } from '../file/file-item.service';
import { BlendService } from '../shared/services/blend.service';
import { BlendEvent } from '../shared/models';
import { SentryService } from '../core/sentry.service';

type ScanData = sync.IResultPathPreUploadPublic | sync.IDirtyOutShareKeyItem[];

interface IWorkItem {
    pid?: number;
    fullPath: string;
    pidPath: string;
    file: File;
}

interface MkDirResult {
    pid: number;
    id: number;
    is_publink: number;
}

@Injectable({
    providedIn: 'root'
})

export class UploadFolderService {
   public file_ext = [];
   public mime_type = [];
   public file_size = 0;

    // set a delay between mkdir commands to prevent causing too high of a load
    // on the live servers.  The number is in milliseconds.
    private MKDIR_DELAY = 300;

    // once we encounter > maxFiles we will stop the folder upload.
    private maxFiles = 20000;

    constructor(
        private transferService: TransferService,
        private modalService: NgbModal,
        private loggerService: LoggerService,
        private notificationsService: NotificationsService,
        private LinkPathList: LinkFileListService,
        private apiService: ApiService,
        private dirtyOutService: DirtyOutService,
        private buildTransferItemService: BuildTransferItemService,
        private syncCryptService: SyncCryptService,
        private runUploadFolderService: RunUploadFolderService,
        private broadcastService: BroadcastService,
        private fileItemService: FileItemService,
        private blendService: BlendService,
        private sentryService: SentryService,
    ) { }

    public showFolderUpload(isUploadInProgress?: boolean) {
        isUploadInProgress ? this.runUploadFolderService.folderUploadInProgress = true : this.runUploadFolderService.folderUploadInProgress = false;
        this.transferService.hideTransfer();
        const instance = this.modalService.open(DialogFolderUploadComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        instance.result.then((result) => {
            console.warn('showFolderUpload closed');
        }, () => {
            console.log('showFolderUpload cancelled');
        });
        return instance;
    }

    public async run(cwd: sync.IFile, items: Entry[], linkId?: string) {
        if (this.runUploadFolderService.view.isScanning || this.runUploadFolderService.view.isProcessing) {
            this.showFolderUpload(true);
            return;
        }
        const stTime = Date.now();
        if (!cwd || !cwd.sync_id) {
            this.loggerService.error('Unable to determine root folder');
            this.handleErr({ code: 7213, msg: 'Unable to determine root folder' });
            return;
        }
        this.notificationsService.stopNotificationLoop();
        this.runUploadFolderService.reset();
        this.showFolderUpload();
        this.runUploadFolderService.view.isScanning = true;
        this.runUploadFolderService.cwd = cwd;



        try {
            const scanData = await this.getScanData(cwd.sync_id, linkId);
            await this.processEntries(scanData, items);

            const scanTime = Date.now() - stTime;
            this.loggerService.warn(`Scanning took ${scanTime}ms for ${this.runUploadFolderService.queueFiles.length} files and ${this.runUploadFolderService.view.folderCount}`);

            const mkDirTime = Date.now();
            await this.makeDirs(cwd.sync_id, scanData);
            const mkDirTimeTotal = Date.now() - mkDirTime;
            this.loggerService.warn(`Creating directories took ${mkDirTimeTotal}ms for ${this.runUploadFolderService.view.folderCount} folders`);

            const makeTime = Date.now();
            await this.makeTransferItems(cwd.sync_id, scanData);
            const makeTimeTotal = Date.now() - makeTime;
            this.loggerService.warn(`Creating transfer items took ${makeTimeTotal}ms for ${this.runUploadFolderService.workFiles.length} folders`);

            const prepTime = Date.now() - stTime;
            this.loggerService.warn(`Preparation took ${prepTime}ms for ${this.runUploadFolderService.workFiles.length} files and ${this.runUploadFolderService.view.folderCount} `);

            this.runUploadFolderService.view.isScanning = false;

            const upTime = Date.now();
            await this.runUploadFolderService.runUploadQueue();
            const upTimeTotal = Date.now() - upTime;
            this.runUploadFolderService.folderUploadInProgress = false;

            this.loggerService.info(['Upload completed: scanTime:',
                scanTime, 'ms',
                ' , mkdir: ', mkDirTimeTotal, 'ms',
                ' , tItem: ', makeTimeTotal, 'ms',
                ' , prep total: ', prepTime, 'ms',
                ' , upload: ', upTimeTotal, 'ms',
                ' for a total of ', this.runUploadFolderService.workFiles.length, ' files and ',
                this.runUploadFolderService.view.folderCount, ' folders'
            ].join(''));

            if (this.file_ext && this.file_ext.length > 0) {
                this.file_ext.forEach((element) => {
                    const type = this.fileItemService.getmimeType(element);
                    if (this.file_ext.indexOf(type) === -1) {
                        this.mime_type.push(type);
                    }
                });
            }

            this.blendService.track(BlendEvent.UPLOAD_FILE, {
                isDragDrop: 'Yes',
                mimeType: this.mime_type,
                fileSize: this.fileItemService.bytesToSize(this.file_size)
            });

        } catch (ex) {
            this.loggerService.error('An error occurred ');
            this.handleErr(ex);
            this.runUploadFolderService.view.error = ex;
        }
        this.loggerService.info('Starting services');
        this.notificationsService.startNotificationLoop();
        this.LinkPathList.reload();
        this.broadcastService.broadcast('event:file-list.reload');
    }

    private async makeDirs(pid: number, scanData: ScanData) {
        this.runUploadFolderService.view.isMakingFolders = true;
        const sortedDirKeys = Object.keys(this.runUploadFolderService.dirMap).sort((a: any, b: any) => {
            return a.split('/').length - b.split('/').length;
        });
        for (let i = 0, len = sortedDirKeys.length; i < len; i++) {
            if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                throw { code: 7240 };
            }
            if (this.runUploadFolderService.shouldCancel) {
                this.loggerService.error('Cancel requested');
                throw { code: 7210 };
            }

            const fullPath = sortedDirKeys[i],
                item = this.runUploadFolderService.dirMap[fullPath],
                newPid = this.translatePidFromPath(pid, fullPath);

            this.runUploadFolderService.view.currentFolder = fullPath;

            const result = await this.mkDir(newPid, item.name, scanData);
            item.id = result.id;
            this.runUploadFolderService.view.folderMadeCount += 1;
            // console.log('make dir ' + fullPath + ' pid: ' + newPid + ' result id ' + result.id);
        }
        this.runUploadFolderService.view.isMakingFolders = false;
        return true;
    }

    private async makeTransferItems(rootPid: number, scanData: ScanData) {
        this.runUploadFolderService.view.isMakingTransfers = true;
        for (let i = 0, len = this.runUploadFolderService.queueFiles.length; i < len; i++) {
            if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                throw { code: 7240 };
            }
            if (this.runUploadFolderService.shouldCancel) {
                this.loggerService.error('Cancel requested');
                throw { code: 7210 };
            }

            const dest = this.runUploadFolderService.dirMap[this.runUploadFolderService.queueFiles[i].pidPath];
            if (!this.runUploadFolderService.queueFiles[i].file) {
                this.loggerService.error('Could not find entry, or returned null');
            }
            const pid = (dest && dest.id) ? dest.id : rootPid;
            this.runUploadFolderService.workFiles.push(await this.getTransferItem(
                scanData,
                pid,
                this.runUploadFolderService.queueFiles[i]));
        }
        this.runUploadFolderService.view.isMakingTransfers = false;
        this.loggerService.info('Made all transfer items');
        return true;
    }

    private async processEntries(scanData: ScanData, items: Entry[]) {
        const process = async () => {
            // try to break out here, but we may have a hard time getting out
            // since this function needs to run quickly so that MS Edge will
            // complete it.
            if (this.runUploadFolderService.queueFiles.length > this.maxFiles) {
                throw { code: 7240 };
            }
            if (items.length == 0) {
                return;
            }
            if (this.runUploadFolderService.shouldCancel) {
                this.loggerService.error('Cancel requested');
                throw { code: 7210 };
            }

            const item = items.shift();
            if (item.isDirectory) {
                // this.Logger.info('adding dir  ' + item.name);
                // this.mkDir(pid, item.name, scanData)
                //     .then((parent) => {

                this.runUploadFolderService.dirMap[item.fullPath] = {
                    fullPath: item.fullPath,
                    name: item.name
                };

                this.runUploadFolderService.view.folderCount += 1;
                try {
                    const entries = await this.getListOfEntries(<DirectoryEntry>item);
                    await this.processEntries(scanData, entries);
                    await process();
                } catch (ex) {
                    this.loggerService.error(ex);
                    this.loggerService.error('Trying to re-throw');
                    throw ex;
                }
            } else if (item.isFile) {
                this.runUploadFolderService.view.filePendingCount += 1;
                (<FileEntry>item).file((file) => {
                    this.runUploadFolderService.view.totalBytes += file.size;
                    this.runUploadFolderService.view.fileCount += 1;
                    this.runUploadFolderService.queueFiles.push({
                        fullPath: item.fullPath,
                        pidPath: item.fullPath.substr(0, item.fullPath.lastIndexOf('/')),
                        file: file
                    });
                    this.file_ext.push(this.fileItemService.getFileExt(file.name));
                    this.file_size += file.size;

                }, (err) => {
                    this.loggerService.error('Error getting file');
                    // Logging actual error to get more context
                    this.sentryService.logError(err, 'Error getting file from entry.file()');
                    throw new Error('Error getting file from entry.file()');
                });
                try {
                    await process();
                } catch (ex) {
                    this.loggerService.error(ex);
                    this.loggerService.error('Trying to re-throw');
                    throw ex;
                    // alert('ex');
                }
            } else {
                this.loggerService.error('Received an unknown type of entry while processing');
            }
        };

        await process();
        // try {
        //     await process();
        // } catch(ex) {
        //     this.Logger.error(ex);
        //     this.Logger.error('Trying to re-throw');
        //     throw ex;
        //     // alert('ex');
        // }
        // return dfd.promise;
    }

    private translatePidFromPath(rootPid: number, pidPath: string): number {
        const pathComp = pidPath.split('/');
        let newPid = rootPid;
        // path Comp length will contain 2 components
        // ["", "Dirname"] - since a path of "/Dirname" splits to two
        if (pathComp.length == 2) {
            newPid = rootPid;
        } else if (pathComp.length > 2) {
            const parentPath = pidPath.substr(0, pidPath.lastIndexOf('/'));
            // console.log('Path comp is > 2 ', parentPath, pathComp, fullPath);
            newPid = this.runUploadFolderService.dirMap[parentPath].id;
        }
        return newPid;
    }

    private async getListOfEntries(item: DirectoryEntry): Promise<Entry[]> {
        return new Promise<Entry[]>((resolve, reject) => {
            let reader = item.createReader();
            let items: Entry[] = [];
            const getEntries = () => {
                reader.readEntries((entries) => {
                    if (entries.length == 0) {
                        resolve(items);
                        reader = undefined;
                        return;
                    } else {
                        items = items.concat(entries);
                        getEntries();
                    }
                },
                    (err) => {
                        this.loggerService.error('Read entries error');
                    });
            };

            getEntries();

        });

    }



    private async getScanData(syncId: number, linkId: string) {
        return (linkId)
            ? this.apiService.execute<any>('pathpreuploadpublic', {
                sync_pid: syncId,
                enc_share_name: '1:RGVhZEJlZWY=',
                publink_id: linkId
            })
            : this.dirtyOutService.getShareKeyDataForSyncId(syncId);
    }


    private async getTransferItem(scanData: ScanData, pid: number, item: IWorkItem): Promise<sync.ITransferItemUpload> {

        try {
            const tItem = (Array.isArray(scanData))
                ? await this.buildTransferItemService.mkuploadItem(item.file)
                : await this.buildTransferItemService.mkPublicUploadItem(item.file);
            tItem.filename = item.file.name.slice(0);
            tItem.fullPath = item.fullPath.slice(0);
            tItem.sync_pid = pid;
            if (!Array.isArray(scanData)) {
                this.runUploadFolderService.view.totalBytes += tItem.filesize;
                try {
                    const encShareName = await this.LinkPathList.encName(item.file.name);
                    tItem.enc_share_name = encShareName;
                    return tItem;
                } catch (error) {
                    this.loggerService.error('Unable to encrypt the file name for the link path list');
                }
            } else {
                return tItem;
            }
        } catch (err) {
            this.loggerService.error(`unable to make upload item for ${item.file.name.slice(0)}`);
            throw { code: 7215 };
        }
    }


    /**
     * We use this mkDir since we get the sharedata required initially and
     * skip making the API call since we can re-use it for the entire upload
     * process
     * @param pid {number} the sync pid to make the dir in
     * @param name {string} the plain text name of the file.
     * @param sharedata {sync.IDirtyOutShareKeyItem[]} an array of sharekey items
     */
    private async mkDir(pid: number, name: string, scanData: ScanData): Promise<MkDirResult> {

        if (Array.isArray(scanData)) {
            try {
                const keyArray = await this.dirtyOutService.getKeyArrayForSyncId(scanData, pid, name);
                try {
                    const enc_name = await this.syncCryptService.filenameEncrypt(name);
                    try {
                        const result = await this.apiService.execute<any>('pathadd', {
                            sync_pid: pid,
                            new_name: enc_name,
                            share_names: keyArray
                        });
                        return new Promise<MkDirResult>(resolve => {
                            setTimeout(() => {
                                resolve({
                                    id: result.pathitem.sync_id,
                                    pid: result.pathitem.pid,
                                    is_publink: result.pathitem.is_publink
                                });
                            }, this.MKDIR_DELAY);
                        });
                    } catch (err) {
                        this.handleErr(err);
                    }
                } catch (err) {
                    this.handleErr(err);
                }
            } catch (err) {
                this.handleErr(err);
            }
        } else {
            if (pid == this.runUploadFolderService.cwd.sync_id) {
                name = this.LinkPathList.getValidFileName(name);
            }
            const encShareName = await this.LinkPathList.encName(name);
            const data = {
                pid: pid,
                sharekey_id: this.runUploadFolderService.cwd.share_key_id,
                enc_share_name: encShareName,

                device_sig_b64: scanData.device_sig_b64,
                backup_id: scanData.backup_id,
                user_id: scanData.user_id,
                device_id: scanData.device_id,
                servtime: scanData.servtime
            };

            try {
                const res = await this.apiService.execute<any>('pathaddshare', data);
                return new Promise<MkDirResult>(resolve => {
                    setTimeout(() => {
                        resolve({
                            id: res.pathitem.id,
                            pid: res.pathitem.pid,
                            is_publink: res.pathitem.is_publink
                        });
                    }, this.MKDIR_DELAY);
                });
            } catch (err) {
                this.handleErr(err);
            }
        }
        return;

    }

    private handleErr(err: any, promise?: Promise<any>) {
        this.loggerService.error(JSON.stringify(err));
        if (typeof err == 'object') {
            this.loggerService.error(JSON.stringify(err));
            if (err.code) {
                this.runUploadFolderService.view.error = err;
            } else {
                this.runUploadFolderService.view.error = { code: 7214 };
            }
        } else if (typeof err == 'string') {
            this.runUploadFolderService.view.error = { code: 7214, msg: err };
        } else {
            this.runUploadFolderService.view.error = { code: 7214 };
        }
        // this.Logger.error(angular.toJson(this.view, true));
        if (promise) {
            Promise.reject(this.runUploadFolderService.view.error);
        }
    }
}

