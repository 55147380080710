<div class="modal-header">
  <button type="button" class="close"  (click)="this.activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">
      <span>{{title}}</span>
  </h4>
</div>
<div class="modal-body" >
  <ng-container>
      <div class="error-general-overlay error-general-overlay-inline error-push-bottom" role="alert">
          <i class="fa fa-exclamation-triangle"></i>
          {{message}} 
      </div>
      <div  class="panel panel-default bgwhite">
          <div class="panel-body bgwhite">
              
              <h4>
              <i class="zmdi zmdi-star-circle zmdi-hc-lg"></i>
              <b>{{ bodyHeader }}:</b>
              </h4>
              <ng-container *ngFor="let item of bodyItems">
                <i class="fa fa-check"></i> {{item}}<br />
              </ng-container>
  
              <br />
              <a class="syncblue" routerLink="/account/upgrade" (click)="this.activeModal.close()">View upgrade options now <i class="fas fa-arrow-right"></i></a>
              <br />
          </div>
      </div>
  </ng-container>

  <br />
</div>
<div class="modal-footer">

  <button id="btn-link-limit-cancel" type="button" class="btn btn-default" (click)="this.activeModal.close()">Cancel</button>
  <a routerLink="/account/upgrade" id="" type="button" class="btn btn-primary pull-right" style="color:white;cursor: pointer;" (click)="this.activeModal.close()">Upgrade now</a>
</div>
