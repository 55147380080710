import { RecentsApiResponse, WebPath } from './models';

/**
 * Compare function used for sort() functions.
 * @param a Object 1
 * @param b Object 2
 * @param isAsc is ascending
 */
export function compare(a, b, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

/**
 * Sorts web paths.
 * @param a Webpath 1
 * @param b WebPath 2
 * @param isAsc boolean is ascending sort
 * @param dirsFirst  boolean should sort dirs first
 */
export function compareWebPath(a: sync.IFile, b: sync.IFile, isAsc: boolean, dirsFirst: boolean) {
    let result;
    if (isAsc) {
        result = b.name.toString().localeCompare(a.name,
            undefined,
            {numeric: true, sensitivity: 'base'});
    } else {
        result = a.name.toString().localeCompare(b.name,
            undefined,
            {numeric: true, sensitivity: 'base'});
    }
    if (dirsFirst) {
        return (b.is_dir - a.is_dir) || result;
    } else {
        return result;
    }
}

export function getFileExt(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
}


export function canBrowserOpen(fileName: string): boolean {
    let openable = true;
    switch (getFileExt(fileName)) {
        case 'pdf':
        case 'txt':
        case 'md':
        case 'jpg':
        case 'gif':
        case 'png':
            openable = true;
            break;
        case '7z':
        case 'zip':
        case 'rar':
        case 'gz':
        case 'gzip':
        case 'tar':
        case 'bz2':
        case 'tgz':
        case 'tbz2':
        case 'dmg':
        case 'exe':
        case 'bin':
        case 'bat':
        case 'iso':
        case 'heic':
        case 'heif':
        case 'hevc':
        case 'wmv':
        case 'avi':
        case 'flv':
        case 'mpg':
            openable = false;
            break;
        default:
            openable = true;
    }
    return openable;
}
export function canLinkBrowserOpen(fileName: string): boolean {
    let openable = false;
    switch (getFileExt(fileName)) {
        case 'pdf':
        case 'txt':
        case 'md':
        case 'jpg':
        case 'gif':
        case 'png':
        case 'mp3':
        case 'mp4':
        case 'avi':
        case 'mov':
            openable = true;
            break;
        default:
            openable = false;
    }
    return openable;
}

export function comparePathItemsName(a: RecentsApiResponse, b: RecentsApiResponse, isAsc: boolean ) {
    const nameA = a.items.pathitems[0].name;
    const nameB = b.items.pathitems[0].name;

    if (isAsc) {
        return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
    } else {
        return nameB.localeCompare(nameA, undefined, { numeric: true, sensitivity: 'base' });
    }
}
