import { Component, OnInit } from '@angular/core';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { User, MigrateStatusApiOutput } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { EventsListService } from '../../events/events-list.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'sync-migrate',
    templateUrl: './migrate.component.html'
})
export class MigrateComponent implements OnInit {


    public password: string;
    public code: string;

    public polldata: any;

    public bytesprocessed: number;
    public totalbytes: number;
    public bytesdonepercent: number;
    public filesprocessed: number;
    public totalfiles: number;
    public filesdonepercent: number;
    public loading: boolean;

    public migratespinner: boolean;
    public migrationstarted: boolean;
    public migrationreqstatus: number;

    public jobspinner: boolean;
    public jobprogresspercent: number;

    private polltimer: number;

    public migrateeventlist: Array<any>;
    public migrateeventsloading: boolean;

    public user: User;

    public cancelerrcode: number;
    public showDetails: boolean;

    public errMsg: string;

    constructor(
        // private $timeout: ng.ITimeoutService,
        private api: ApiService,
        private crypt: SyncCryptService,
        private userService: UserService,
        private log: LoggerService,
        private eventsListService: EventsListService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.code = this.route.snapshot.queryParams['code'];
        this.loading = true;
        this.poll(true);
        this.getMigrateEvents();
        this.filesprocessed = 0;
        this.bytesprocessed = 0;
        this.user = this.userService.getUser();
    }

    public async doMigrate() {
        this.migratespinner = true;

        try {
            const pubkey = await this.api.fetchText('/key/migration/pubkey');
            const migrationkeys = await this.crypt.prepareMigrationKeys(pubkey);
            await this.api.execute('queueformigration', {
                type: 'dropbox',
                code: this.route.snapshot.queryParams['code'],
                migrate_pass: migrationkeys.encPass,
                migrate_priv_key: migrationkeys.encPriv,
                migrate_meta_key: migrationkeys.encMeta
            });

            this.migratespinner = false;
            this.migrationstarted = true;
            window.clearTimeout(this.polltimer);
            // this.$timeout.cancel(this.polltimer);
            this.poll(false);
        } catch (err) {
            if (typeof err === 'object') {
                this.migrationreqstatus = err.error_code;

            } else {
                this.migrationreqstatus = 0;
            }
            this.migratespinner = false;
            this.migrationstarted = false;
        }
    }

    public async stopMigrate() {
        this.jobspinner = true;
        try {
            await this.api.execute('stopmigration', {
                type: 'dropbox'
            });
            this.jobspinner = false;
                //this.Feedback.setFeedback('success', 'Migration successfully cancelled.', 10000);
                this.errMsg = 'Migration successfully cancelled.';
            this.cancelerrcode = 0;
        } catch (err) {
            if (this.cancelerrcode != 0) {
                if (typeof err === 'object') {
                    switch (err.code) {
                        case 1:
                            this.cancelerrcode = 1;
                            this.log.warn('No migration job is running');
                            break;
                        case 2:
                            this.cancelerrcode = 2;
                            this.log.warn('Migration is already cancelled');
                                //this.Feedback.setFeedback('danger', 'You already cancelled this migration job, please wait...', 10000);
                                this.errMsg = 'You already cancelled this migration job, please wait...';
                            break;
                        default:
                            this.log.warn(JSON.stringify(err));
                            this.cancelerrcode = -1;
                            this.log.warn('Unknown error occurred when cancelling the migration');
                    }
                } else {
                    this.cancelerrcode = -2;
                    this.log.warn('An error occurred when stopping the migration');
                }
            }

            this.jobspinner = false;
        }
    }

    private async poll(checkmongo: boolean) {
        try {
            const result = await this.api.execute<MigrateStatusApiOutput>('migratestatus', {
                type: 'dropbox',
                checkmongo: checkmongo ? 1 : 0
            });
            let repoll = true;
            this.polldata = result;

            switch (parseInt(result.jobstatus, 10)) {
                case 0: {
                    this.jobprogresspercent = 0;
                    break;
                }
                case 1: {
                    this.jobprogresspercent = 2.5;
                    break;
                }
                case 2: {
                    this.jobprogresspercent = 5;
                    break;
                }
                case 3: {
                    const totalfolders = this.polldata.totalfolders;
                    const donefolders = (this.polldata.foldersdone +
                                        this.polldata.foldersfailed +
                                        this.polldata.foldersexcluded);
                    const folder_upload_prog = donefolders / totalfolders;
                    if (totalfolders > 0) {
                        this.jobprogresspercent = 15 + folder_upload_prog * 15;
                    } else {
                        this.jobprogresspercent = 15;
                    }
                    break;
                }
                case 4: {
                    let cur_dl_bytes = 0;

                    if (this.polldata.curfiles.length > 0) {
                        cur_dl_bytes = this.polldata.curfiles
                            .map((f: any) => f.ul_bytes == f.size ? 0 : f.dl_bytes + f.ul_bytes)
                            .reduce((sum: number, current: number) =>
                                        sum + current);
                    }

                    this.bytesprocessed = (result.bytesdone +
                        result.bytesexcluded +
                        result.bytesfailed +
                        (cur_dl_bytes / 2));
                    this.totalbytes = result.totalbytes;
                    this.bytesdonepercent = 100 * this.bytesprocessed / result.totalbytes;

                    this.filesprocessed = (result.filesdone +
                        result.filesexcluded +
                        result.filesfailed);
                    this.totalfiles = result.totalfiles;
                    this.filesdonepercent = 100 * this.filesprocessed / result.totalfiles;

                    this.jobprogresspercent = 30 + this.filesdonepercent * 0.25 + this.bytesdonepercent * 0.45;
                    break;
                }
                case 5:
                case 6:
                case 7:
                case 8:
                case 9: {
                    this.jobprogresspercent = 100;
                    repoll = false;
                    break;
                }
                default: {
                    this.jobprogresspercent = 0;
                }
            }

                this.code = undefined;
                this.loading = false;
                if (repoll) {
                    this.polltimer = window.setTimeout(() => this.poll(false), 5000);
                } else {
                    window.clearTimeout(this.polltimer);
                }
            } catch (err) {
                this.polldata = undefined;
                this.loading = false;
                this.polltimer = window.setTimeout(() => this.poll(false), 30000);
            }
    }

    getMigrateEvents() {
        this.migrateeventsloading = true;
        const opts: sync.IEventOptions = {limit: 10, eventTypes: [300, 301]};
        return this.eventsListService.getList(opts)
        .then( (res) => this.setEventList(res) )
        .catch( (err) =>  this.handleEventErrors(err) );
    }

    setEventList(migrateeventresult: any) {
        this.migrateeventlist = migrateeventresult.events;
        this.migrateeventsloading = false;
    }

    handleEventErrors(err: any) {
        //this.Feedback.setFeedback('danger', 'An error occurred retrieving migration history', 10000);
        this.errMsg = 'An error occurred retrieving migration history';
        this.log.error(JSON.stringify(err));
        this.log.error('An error occurred retrieving migration events');
        this.migrateeventsloading = false;
    }

    migrateEventIsOverQuota(event: any) {
        return event.items && (event.items.hasfailedfiles & 4) == 4;
    }
}
