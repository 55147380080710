import { BaseApiOutput } from './base-api.model';
import { DirtyOutEncResultSync } from '../dirty-out-enc-result.model';

export class PathAddApiOutput extends BaseApiOutput {
    pathitem: sync.IFile;
}

export class PathAddApiInput {
    sync_pid: number;
    new_name: string;
    share_names: DirtyOutEncResultSync[];
}


