<div class="container-fluid container-fluid-wrap tab-wrapper">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Account settings
            </div>

            <div class="right-menu-button right-menu-button-push affix">
            </div>

            <ul class="nav nav-tabs">
                <li role="presentation">
                    <a routerLink="/account/info">Account</a>
                </li>
                <li role="presentation"><a routerLink="/account/billing">Billing</a></li>
                <li  class="active" role="presentation"><a routerLink="/account/devices">Devices</a></li>
                <li role="presentation" *ngIf="user.new_feature"><a routerLink="/account/apps">Apps</a></li>
                <li role="presentation"><a routerLink="/account/security">Security</a></li>
            </ul>
        </div>
    </div>


    <div class="row">
        <div class="col-sm-12 content-box-withmenu">

            <table class="table list-table list-table-push">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th class="hidden-xs">Added</th>
                        <th>Details</th>
                        <th class="hidden-xs">IP</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of devices">
                        <tr *ngIf="!item.is_deleted || (showDeleted && item.is_deleted)">
                            <td>
                                <span *ngIf="!item.is_deleted && item.is_suspended" class="badge">Suspended</span>
                                <span *ngIf="item.is_deleted" class="badge">Deleted</span>
                                <span *ngIf="item.needs_activate" class="badge">2FA Suspended</span>
                                {{item.is_mobile ? 'Mobile' : 'Desktop'}}
                            </td>
                            <td class="hidden-xs">{{item.cre_servtime | date:'mediumDate'}}</td>
                            <td>{{item.name | b64}}<div class="subtext">Version {{item.version}} {{item.devicetype_name}}</div></td>
                            <td class="hidden-xs">
                                --
                            </td>
                            <td class="table-actions" ngbDropdown>
                                <button
                                    *ngIf="item.needs_activate"
                                    class="btn btn-success btn-xs"
                                    (click)="activateDevice(item)"
                                >Activate</button>
                                <div *ngIf="!item.needs_activate" ngbDropdown class="dropdown actions-dropdown">
                                    <i ngbDropdownToggle class="fas fa-ellipsis-h pull-right"  aria-haspopup="true" aria-expanded="true"></i>
                                    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-regular">
                                        <li>
                                            <a (click)="rename(item)">Rename</a>
                                        </li>
                                        <li *ngIf="!item.is_deleted">
                                            <a (click)="delete(item)">Delete</a>
                                        </li>
                                        <li *ngIf="item.is_deleted && item.devicetype_name != 'Migration Robot'">
                                            <a (click)="restore(item)">Restore</a>
                                        </li>
                                        <li>
                                            <a (click)="setPermission(item)">Set permission</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </ng-container>

                    <tr *ngFor="let item of sessions">
                        <td>Web</td>
                        <td class="hidden-xs">{{item.login_servtime | date:'mediumDate'}}</td>
                        <td>{{item.useragent_browser}}<div class="subtext">Version {{item.useragent_version}} {{item.useragent_platform}}</div></td>
                        <td class="hidden-xs">
                            {{item.ipaddr}}
                            <!-- <div class="subtext">Canada</div> -->
                        </td>
                        <td class="table-actions">
                            <div *ngIf="!item.is_current" ngbDropdown class="dropdown actions-dropdown">
                                <i ngbDropdownToggle class="fas fa-ellipsis-h pull-right dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
                                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-regular">
                                    <li><a (click)="logout(item)">Logout</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button
                class="btn-default btn"
                (click)="showDeleted = !showDeleted"
            >{{ (!showDeleted) ? 'Show deleted' : 'Hide deleted'}}</button>
        </div>
    </div>
</div>