import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class LinkSetOptionsApiOutput extends BaseApiOutput {
}

interface LinkSetOptionsApiInput extends BaseApiInput {
    sync_id: number;
    share_id: number;
    publink_id: string;
    label: string;
    exp_servtime: number;
    email_notification: number;
    upload: number;
}
