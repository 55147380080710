<div *ngIf="type == 'office'">
    <sync-header-previews [item]="item">
        <div class="hamburger-menu-item visible-xs" hamburgerMenu>
            <sync-preview-menu
                [item]="item"
                [url]="url"
            ></sync-preview-menu>
        </div>
    </sync-header-previews>

  <!-- CP TEMPLATE -->
  <div
      *ngIf="item.context != 'link' && item.context != 'applink'"
      class="container-fluid container-fluid-wrap container-fluid-wrap-preview"
  >
      <sync-preview-menu [item]="item">
      </sync-preview-menu>
      <div class="preview-wrap">
          <sync-error [errcode]="errcode"></sync-error>

          <div *ngIf="spinner && !errcode">
              <i class="fa fa-spin fa-spinner"></i> Preparing for preview
          </div>

          <div
              class="preview-iframe-wopi"
              *ngIf="!errcode && initialized"
          >
              <iframe
                  class="preview-iframe-wopi"
                  frameborder="0"
                  name="office_frame"
                  id="office_frame"
                  title="Office Online Frame"
                  allowfullscreen="true"
                  sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
              >
              </iframe>
          </div>
      </div>
  </div>
  <!-- /container -->
  <!-- /CP TEMPLATE -->

  <!-- LINK CONSUME TEMPLATE -->
  <div
      *ngIf="item.context == 'link' || item.context == 'applink'"
      class="container-fluid preview-container"
  >
      <div class="row">
          <!-- MAIN CONTENT COL -->
          <div class="col-md-8 col-lg-9 preview-file-margin">
              <div class="preview-wrap-no-margin">
                  <sync-error [errcode]="errcode"></sync-error>

                  <div *ngIf="spinner && !errcode">
                      <i class="fa fa-spin fa-spinner"></i> Preparing for
                      preview
                  </div>

                  <div
                      class="preview-iframe-wopi"
                      *ngIf="!errcode && initialized"
                  >
                      <iframe
                          class="preview-iframe-wopi"
                          frameborder="0"
                          name="office_frame"
                          id="office_frame"
                          title="Office Online Frame"
                          allowfullscreen="true"
                          sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
                      >
                      </iframe>
                  </div>
              </div>
          </div>
          <!-- / MAIN CONTENT COL -->
          <div class="col-md-4 col-lg-3 xs-fixed-comment"
                [ngClass]="isCommentExpanded ? 'expanded' : ''">
                <sync-preview-menu
                    [item]="item"
                    [url]="url"
                    class="hidden-xs"
                ></sync-preview-menu>
                <!-- comment feature -->
                <sync-comment-block
                    *ngIf="allowComment"
                    [item]="item"
                    [ontoggle]="onCommentToggle"
                ></sync-comment-block>
            </div>
      </div>
  </div>
  <!-- /container -->
  <!-- /LINK CONSUME TEMPLATE -->
</div>

<div *ngIf="type == 'officefsv' || type == 'officefse'">
  <div class="preview-wrap-wopi-fullscreen">
      <sync-error [errcode]="errcode"></sync-error>

      <div *ngIf="spinner && !errcode">
          <i class="fa fa-spin fa-spinner"></i> Preparing for Office Online
      </div>

      <div *ngIf="errcode">
          <span
              *ngIf="errcode"
              class="showhand"
              syncOpenDialog
              >Contact support
          </span>
      </div>

      <div
          class="preview-iframe-wopi-fullscreen-wrap"
          *ngIf="!errcode && initialized"
      >
          <iframe
              class="preview-iframe-wopi-fullscreen"
              frameborder="0"
              name="office_frame"
              id="office_frame"
              title="Office Online Frame"
              allowfullscreen="true"
              sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox"
          >
          </iframe>
      </div>
  </div>
  <!-- /container -->
</div>

