<!-- modalcontent -->
<div class="modal-header">
    <button
        (click)="activeModal.close()"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" [innerHtml]="currentItem.name"></h4>
</div>

<div class="modal-body">
    <div class="error-general-overlay error-general-overlay-inline">
        <i class="fa fa-info-circle"></i>
        You are about to download this file using compatibility mode.
        <b
            ><a
                rel="noopener"
                class="syncblue"
                target="_blank"
                href="https://www.sync.com/help/compatibility-mode"
                >More info ...</a
            ></b
        >
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-compat-download btn-primary pull-left"
        (click)="downloadFile()"
    >
        <i class="ion-arrow-down-c fa-lg"></i> Download file now
    </button>
    <button
        class="btn btn-compat-open btn-default pull-left"
        (click)="openFile()"
    >
        Open file
    </button>

    <button (click)="activeModal.close()" type="button" class="btn btn-default">
        Close
    </button>
</div>
<!-- modalcontent -->
