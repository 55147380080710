import { Deserializable } from './deserializable.model';
export class MultiUserData implements Deserializable<MultiUserData> {
    user_id = 0;
    email = '';
    plan_id = 1;
    plan_name = '';
    verified = 0;
    cre_servtime = 0;

    disk_usage_bytes = 0;
    disk_limit_bytes = 0;
    last_filesync_time = 0;
    display_name = '';
    has_password_reset = 0;
    has_disable_purge = 0;

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

