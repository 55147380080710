<div class="container-fluid container-fluid-wrap">
  <!-- header row top bar contains title, menu, options -->
  <div class="row">
      <!-- affixed header box sets padding for right menu -->
      <div class="col-sm-12 affix header-box-withmenu">
          <div class="header-title">
              Team share folders
          </div>
          <div *ngIf="hasShareFolderPermission()" class="right-menu-button right-menu-button-push affix">
              <button id="btn-shares-create" class="btn btn-default" type="button" syncDialogShareNew [selected]="[]"
                  pid="0">Create share folder</button>
          </div>
        <ul class="nav nav-tabs">
            <li role="presentation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/shares">Team shares</a>
            </li>
            <li role="presentation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/links">Links</a>
            </li>
            <li *ngIf="isTeamsPlusUser" role="presentation" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/shares/externalshares">Shared externally</a>
            </li>
        </ul>
      </div> <!-- col-sm-12 affix -->
  </div> <!-- row -->

  <!-- end invite and confirmation messages -->
  <div class="row">
      <div class="col-sm-12 content-box-withmenu">
          <table class="table list-table list-table-push">
              <thead>
                  <tr>
                      <th class="table-sharename showhand" colspan="2"
                          (click)="setPredicate('name', !predicateReverse)">Name
                          <span class="fa" [ngClass]="{
                              'fa-sort-down': predicate === 'name' && !predicateReverse,
                              'fa-sort-up': predicate === 'name' && predicateReverse
                          }"></span>
                      </th>
                      <th class="table-shareadmin hidden-sm hidden-xs">Admin</th>
                      <th class="table-shareusers">Users</th>
                      <th class="table-actions"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngIf="!isLoaded && !externalShares?.length">
                      <td colspan="5" class="text-center linkshare-empty">
                          <p>
                              <i class="fa fa-spin fa-spinner"></i>
                              Loading ...
                          </p>
                      </td>
                  </tr>
                  <tr *ngIf="isLoaded && externalShareCount == 0">
                      <td colspan="5" class="text-center linkshare-empty">
                          <p>
                              No External shares found!
                          </p>
                      </td>
                  </tr>
                  <tr id="share-list-item-{{share.share_id}}" *ngFor="let share of externalShares trackBy:trackByFn">
                      <ng-container *ngIf="share.is_owner">
                          <td class="table-preview">
                              <img src="images/icons/dir-user.svg" />
                          </td>
                          <td class="table-sharename">
                              <div class="tdmore">
                                  <a class="syncblacklink" (click)="onToFiles(share.sync_id)">{{share.name}}</a>
                              </div>
                              <div class="tdmore subtext" *ngIf="showInfo">Share ID: {{share.share_id}}</div>
                          </td>
                          <td class="table-shareadmin hidden-sm hidden-xs">
                              <div class="tdmore">
                                  <span *ngIf="share.is_owner">You</span>
                                  <span *ngIf="!share.is_owner" [innerHtml]="share.owner_name|b64"></span>
                              </div>
                          </td>
                          <td class="table-shareusers">
                              <div [class.hide]="!(share && (share.is_seeothers || share.is_owner) && share.external_user_list)">
                                  <sync-avatar-stack class="visible-xs" [users]="share.external_user_list"
                                      [onViewAll]="openShareDetailsDailog.bind(this,share, '0')"
                                      [maxDisplayAvatar]="3"></sync-avatar-stack>
                                  <sync-avatar-stack class="hidden-xs" [users]="share.external_user_list"
                                      [onViewAll]="openShareDetailsDailog.bind(this,share, '0')"
                                      [maxDisplayAvatar]="5"></sync-avatar-stack>
                              </div>
                              <span [class.hide]="!(!share.is_seeothers && !share.is_owner)">
                                  Shared privately.
                              </span>

                          </td>
                          <td class="table-actions">
                              <div ngbDropdown class="dropdown actions-dropdown">
                                  <i ngbDropdownToggle class="fas fa-ellipsis-h pull-right dropdown-toggle"
                                      id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true"
                                      aria-expanded="true"></i>
                                  <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-regular"
                                      aria-labelledby="dropdownMenu1">
                                      <li>
                                          <a style="padding: 3px 10px;"
                                              (click)="openShareDetailsDailog(share, '0')">
                                              <i class="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg"></i>
                                              Manage share
                                          </a>
                                      </li>
                                      <li *ngIf="share.is_owner">
                                          <a style="padding: 3px 10px;" (click)="openShareDetailsDailog(share, '5')">
                                              <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                              Disable share
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                          </td>
                      </ng-container>

                  </tr>
              </tbody>
          </table>
          <div *ngIf="externalShareCount > 0" class="table-info">
              <i (click)="showInfo = !showInfo" class="fa fa-info-circle fa-lg showhand"></i>
              {{externalShareCount}} team shares
          </div>
      </div> <!-- col-sm-12 affix -->
  </div> <!-- row -->
</div>