export class DirtyInTasks {
    items: DirtyInTaskItem[];
    sharekeys: { [sharekey_id: string]: string };
}
export class DirtyInTaskItem {
    hist_id: number;
    id: number;
    pid: number;
    sharename_digest: string;
    enc_share_name: string;
    sharekey_id: string;
    share_id: number;
    share_sequence: number;
}

export class DirtyInTaskResult {
    id: number;
    pid: number;
    enc_name: string;
    hist_id: number;
    share_id: number;
    sharename_digest: string;
}

