
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications/notifications.component';
import { ErrorModule } from '../error/error-module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        ErrorModule,
        RouterModule
    ],
    providers: [],
    exports: [
        NotificationsComponent
    ],
    declarations: [
        NotificationsComponent
    ],
    entryComponents: [
        NotificationsComponent
    ]
})
export class NotificationsModule { }

