<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Permissions</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Change password:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="changepass"
                        id="device-changepass-enable"
                        value="1"
                        [(ngModel)]="changepass"
                    />
                    Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="changepass"
                        id="device-changepass-disable"
                        value="0"
                        [(ngModel)]="changepass"
                    />
                    Disabled
                </label>
            </div>
            <div class="subtext">
                Allow this device to change your account's password.
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()">Save settings</button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>