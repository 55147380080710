import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ShareService } from '../share.service';

@Component({
    selector: 'sync-share-options',
    templateUrl: './share-options.component.html'
})
export class ShareOptionsComponent implements OnInit {
    @Input() public shareData: sync.IShareData;
    @Input() public modalInstance: NgbModalRef;
    @Input() public openState: number;
    @Output() openStateChange: EventEmitter<number> = new EventEmitter();

    public errors: Array<sync.IErrorCode> = [];

    public isPro = false;
    public spinners = {
        label: false,
    };

    constructor(
        private userService: UserService,
        private loggerService: LoggerService,
        private shareService: ShareService,
        private broadcastService: BroadcastService
    ) {}

    ngOnInit() {
        this.isPro = this.userService.get('is_pro');
        if (!this.shareData) {
            this.loggerService.error(
                'Unable to find shareData for sharemember-list'
            );
            this.loggerService.error(JSON.stringify(this.shareData));
            this.errors.push({
                code: 9000,
                msg: 'No share member information provided',
            });
        }
    }

    public async setLabel() {
        try {
            this.spinners.label = true;
            this.errors = [];
            await this.shareService.setLabel(
                this.shareData.shareId,
                this.shareData.label
            );
            this.broadcastService.broadcast('event:file-list.reload');
            this.broadcastService.broadcast('event:share-list.reload');
            this.spinners.label = false;
            this.modalInstance.close();
        } catch (err) {
            this.spinners.label = false;
            this.errors.push(err);
        }
    }
    public openPopup(state: number) {
        this.openStateChange.next(state);
    }
}
