<sync-header-previews [item]="item">
    <div class="hamburger-menu-item visible-xs" hamburgerMenu>
        <sync-preview-menu
            [item]="item"
            [url]="url"
        ></sync-preview-menu>
    </div>
</sync-header-previews>

<!-- CP TEMPLATE -->
<div
    *ngIf="item.context != 'link' && item.context != 'applink'"
    class="container-fluid container-fluid-wrap container-fluid-wrap-preview"
>
    <sync-preview-menu [item]="item" [url]="url"></sync-preview-menu>
    <div class="preview-wrap">
        <div class="preview-loading-wrap">
            <div *ngIf="errorMsg">
                <i class="fa fa-exclamation-triangle fa-lg"></i>
                {{ errorMsg }}
            </div>
            <div *ngIf="spinner">
                <i class="fa fa-refresh fa-spin"></i> Generating preview ...
            </div>
        </div>
        <div id="viewerContainer" tabindex="0">
            <div id="viewer" class="pdfViewer"></div>
        </div>
    </div>
</div>
<!-- /container -->
<!-- /CP TEMPLATE -->

<!-- LINK CONSUME TEMPLATE -->
<div
    *ngIf="item.context == 'link' || item.context == 'applink'"
    class="container-fluid preview-container"
>
    <div class="row">
        <!-- MAIN CONTENT COL -->
        <div class="col-md-8 col-lg-9 preview-file-margin">
            <div class="preview-wrap-no-margin">
                <div class="preview-loading-wrap">
                    <div *ngIf="errorMsg">
                        <i class="fa fa-exclamation-triangle fa-lg"></i>
                        {{ errorMsg }}
                    </div>
                    <div *ngIf="spinner">
                        <i class="fa fa-refresh fa-spin"></i> Generating preview
                        ...
                    </div>
                </div>
                <div id="viewerContainer" tabindex="0">
                    <div id="viewer" class="pdfViewer"></div>
                </div>
            </div>
        </div>
        <!-- / MAIN CONTENT COL -->

        <div class="col-md-4 col-lg-3 xs-fixed-comment"
            [ngClass]="isCommentExpanded ? 'expanded' : ''">
            <sync-preview-menu
                [item]="item"
                [url]="url"
                class="hidden-xs"
            ></sync-preview-menu>
            <!-- comment feature -->
            <sync-comment-block
                *ngIf="allowComment"
                [item]="item"
                [ontoggle]="onCommentToggle"
            ></sync-comment-block>
        </div>
    </div>
</div>
<!-- /container -->
<!-- /LINK CONSUME TEMPLATE -->
