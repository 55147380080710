
<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-cog"></i> Automatic logout</h4>
</div>

<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            Automatic logout allows you to configure session expiry time for all web browser sessions.
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">Auto logout:</div>
        <div class="col-sm-8">
            <div class="form-group">
                <select class="form-control" id="autologout-ttl" [(ngModel)]="ttl">
                    <option value="0">Don't log me out</option>
                    <option value="3600">Auto-logout after 1 hour</option>
                    <option value="86400">Auto-logout after 24 hours</option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
        <button
            [disabled]="spinner"
            id="autologout-save"
            type="button"
            class="btn btn-primary"
            (click)="save()"
        >
            <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
            Save
        </button>
        <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
    </div>
    