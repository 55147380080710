import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { UserMultiAdminComponent } from '../multi-admin/user-multi-admin/user-multi-admin.component';

import { DialogMultiuserAddComponent } from './dialog-multiuser-add/dialog-multiuser-add.component';
import { DialogMultiAdminuserAddComponent } from '../multi-admin/dialog-multiadminuser-add/dialog-multiadminuser-add.component';
import { DialogMultiAdminuserDeleteComponent } from '../multi-admin/dialog-multiadminuser-delete/dialog-multiadminuser-delete.component';
import { DialogMultiuserSettingsComponent } from './dialog-multiuser-settings/dialog-multiuser-settings.component';
import { DialogMultiAdminuserSettingsComponent } from '../multi-admin/dialog-multiadminuser-settings/dialog-multiadminuser-settings.component';
import { MultiUserRoutingModule } from './multi-user-routing.module';
import { LayoutModule } from '../layout/layout.module';


@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      NgbModalModule,
      NgbDropdownModule,
      ReactiveFormsModule,
      SharedModule,
      LayoutModule,
      MultiUserRoutingModule,
      QRCodeModule
    ],
    providers: [],
    declarations: [
        UserAdminComponent,
        UserMultiAdminComponent,
        DialogMultiuserAddComponent,
        DialogMultiAdminuserAddComponent,
        DialogMultiAdminuserDeleteComponent,
        DialogMultiuserSettingsComponent,
        DialogMultiAdminuserSettingsComponent
    ],
    exports: [
        DialogMultiuserAddComponent,
        DialogMultiAdminuserAddComponent,
        DialogMultiAdminuserDeleteComponent,
        DialogMultiuserSettingsComponent,
        DialogMultiAdminuserSettingsComponent
    ],
    entryComponents: [
        UserAdminComponent,
        UserMultiAdminComponent,
        DialogMultiuserAddComponent,
        DialogMultiAdminuserAddComponent,
        DialogMultiAdminuserDeleteComponent,
        DialogMultiuserSettingsComponent,
        DialogMultiAdminuserSettingsComponent
    ]
})
export class MultiUserModule { }


