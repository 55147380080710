import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class GetUserKeysApiOutput extends BaseApiOutput implements UserKeys {
    enc_meta_key: string;
    enc_priv_key: string;
    pub_key: string;
}

export class GetUserKeysApiInput extends BaseApiInput {
    username: string;
    password: string;
}


