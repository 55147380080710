import { BaseApiOutput } from './base-api.model';

export class LinkSendUrlApiOutput extends BaseApiOutput {
}

export class LinkSendUrlApiInput {
    sync_id: number;
    publink_id: string;
    displayname: string;
    linkname: string;
    linkurl: string;
    emailaddress: string;
    message: string;
}


