import { Injectable } from '@angular/core';

import { TransferStatus } from '../transfer.model';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { FileItemService } from '../../file/file-item.service';


@Injectable({
    providedIn: 'root'
})
export class TransferItemService {
    public TYPE_DOWNLOAD = 10;
    public TYPE_UPLOAD = 20;
    public TYPE_PREVIEW = 50;

    private transferItemUpload: sync.ITransferItemUpload = {
        _initTime: 0, // timestamp of this transferitem
        type: this.TYPE_UPLOAD,
        status: TransferStatus.STATUS_WAITING,
        filename: '', // decrypted file name
        fileobjdata: null, // the arraybuffer holding the file.
        filedata: null,
        filesize: 0,  // file size in bytes
        filedate: 0,  // timestamp
        mimetype: '',
        uniqid: '', // string used to identify this transfer item.
        sync_id: 0,
        data_key: [], // Array like version of the data_key
        bytes_sent: 0,

        share_id: 0,       //
        share_sequence: 0, //  for links only
        share_key_id: '',  //

        chunkamt: 0,
        progress_percent: 0,
        progress_network: 0,
        progress_crypt: 0,
        stats: '',

        share_key: '',      // The decrypted version of the share key
        enc_share_key: '',  // optional if share_key is provided
        enc_data_key: '',

        sha1_digest: null, // new sjcl.hash.sha1()
        enc_name: '', // meta encrypted file name
        sync_pid: 0, /// pid for the sync id
        fileobj: null,
        blob_id: 0,
        servtime: 0,
        backup_id: '',
        device_id: 0,
        device_sig_b64: '',
        chunkqueue: []
    };

    private transferItemDownload: sync.ITransferItemDownload = {
        _initTime: 0, // timestamp of this transferitem
        type: this.TYPE_DOWNLOAD,
        status: TransferStatus.STATUS_WAITING,
        filename: '', // decrypted file name
        filesize: 0,  // file size in bytes
        filedate: 0,  // timestamp
        mimetype: '',
        uniqid: '', // string used to identify this transfer item.

        sync_id: 0,

        // keys and encryption.
        data_key: [], // Array like version of the data_key
        enc_data_key: '',
        share_key: '',
        enc_share_key: '',
        bytes_sent: 0,
        progress_percent: 0,
        progress_network: 0,
        progress_crypt: 0,

        blobtype: '',
        cachekey: '', // download only.
        dl_ready: false,
        dl_href: '',
        dl_method: '',
        renderFile: undefined,
        enc_filesize: 0,
        filedata: undefined, // hash of file attributes derived from the file name.
    };
    constructor(
        private crypt: SyncCryptService,
        private log: LoggerService,
        private userService: UserService,
        private fileItemService: FileItemService
    ) { }


    public getChunkQueue(tItem: sync.ITransferItemUpload): sync.ITransferItemChunkQueue[] {
        // this is a file upload.
        const chunkamt = Math.ceil(tItem.filesize / this.crypt.GCM_PAYLOAD_SIZE);
        const queue = [];
        for (let i = 0; i < chunkamt; i++) {
            const offset = i * this.crypt.GCM_PAYLOAD_SIZE,
                len = (offset + this.crypt.GCM_PAYLOAD_SIZE > tItem.filesize)
                    ? tItem.filesize - offset
                    : this.crypt.GCM_PAYLOAD_SIZE;
            queue.push({
                offset: offset,
                enc_offset: offset + 36 * i,
                chunklen: len
            });
        }
        return queue;
    }


    private async getUploadItem(settings: sync.ITransferItemUpload) {
        let tItem: sync.ITransferItemUpload;
        settings.retryCount = 0;
        settings.chunkqueue = this.getChunkQueue(settings);
        settings.chunkamt = settings.chunkqueue.length;
        settings.data_key = this.crypt.mkDataKey(); // mk new datakey
        if (this.userService.isAuthenticated() && !settings.linkID && !settings.enc_name) {
            try {
                const enc_name = await this.crypt.filenameEncrypt(settings.filename);
                settings.enc_name = enc_name;
                tItem = Object.assign({}, this.transferItemUpload, settings);
            } catch (err) {
                settings.status = 2022;
                this.log.error('TransferItem enc file name failed');
                this.log.error(err);
                tItem = Object.assign({}, this.transferItemUpload, settings);
                tItem.status = TransferStatus.STATUS_ERROR;
            }
        } else {
            // Logger.info('q all promises success');
            tItem = Object.assign({}, this.transferItemUpload, settings);
        }
        return tItem;
    }


    private async getDownloadItem(settings: sync.ITransferItemDownload): Promise<sync.ITransferItemDownload> {
        settings.enc_filesize = Math.ceil(settings.filesize / this.crypt.GCM_PAYLOAD_SIZE) * 36 + settings.filesize;
        settings.uniqid = this.crypt.getRandomHex(32);

        if (settings.context == 'applink') {
            const tItem = Object.assign({}, this.transferItemDownload, settings);
            return tItem;
        }

        if (!settings.share_key  && settings.enc_share_key) {
            try {
                settings.share_key = await this.crypt.sharekeyDecrypt(
                    settings.enc_share_key,
                    settings.share_key_id
                );
            } catch (ex) {
                const tItem: sync.ITransferItemDownload = Object.assign({}, this.transferItemDownload, settings);
                tItem.status = TransferStatus.STATUS_ERROR;
                return tItem;
            }
        }
        let tItem: sync.ITransferItemDownload;
        try {
            const datakey = await this.crypt.datakeyDecrypt(
                settings.enc_data_key,
                settings.share_key
            );
            settings.data_key = this.crypt.b64ToBytes(datakey);
            tItem = Object.assign({}, this.transferItemDownload, settings);
        } catch (err) {
            this.log.error(err);
            this.log.error('datakey decrypt failed for TransferItem');
            tItem = Object.assign({}, this.transferItemDownload, settings);
            tItem.status = 2010;
        }
        return tItem;
    }


    /**
     * @ngdoc method
     * @name  get
     * @methodOf sync.service:TransferItem
     * @description
     * Creates a TransferItem object using the default template and merging
     * in the settings object
     * @param  {Object} settings Overrides default template for transferItem.
     * @return {Promise}         Resolved promise with a transfer item
     */
    public async get(settings: sync.ITransferItemDownload | sync.ITransferItemUpload): Promise<sync.ITransferItemDownload | sync.ITransferItemUpload> {
        if (!settings.filename) {
            this.log.error('TransferItem.get() passed empty file name.');
            settings.status = 7201;
        }
        if (!settings.filesize) {
            this.log.error('TransferItem.get() passed 0 byte file size.');
            settings.status = 7202;
        }
        settings._initTime = Date.now();
        settings.filedata = this.fileItemService.getFileTypeData(settings.filename);
        // settings.status = this.TransferStatus.STATUS_WAITING;
        if (settings.type == this.TYPE_UPLOAD) {
            return await this.getUploadItem(<sync.ITransferItemUpload>settings);
        } else {
            return await this.getDownloadItem(<sync.ITransferItemDownload>settings);
        }
    }



}
