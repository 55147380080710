import { FieldIdentities, FieldTypes } from './enums';

export interface ValidateTokenOutput {
    acctkey?: string;
    success: number;
    user_id?: number;
    team_id?: number;
    email?: string;
    error_code?: number;
    currentUserId?: number;
    currentUserEmail?: string;
    isPro?: boolean;
    migrated?: boolean;
    isLegacyToCNC?: boolean;
    upgradProductName?: string;
}

export interface MultiAdminSeedField {
    type: FieldTypes;
    identity: FieldIdentities;
    subFieldsContext?: string;
    subFields?: MultiAdminSeedField[];
    label: string;
    required: boolean;
    subOf?: FieldIdentities;
    options: {
        value: string | boolean | number;
        key: string;
    }[];
    defaultValue: string | boolean | number | undefined;
    order: number;
}

export const linkExpiryDateOptions = [{ 'value': 1, 'key': '1 day (24 hours)' }, { 'value': 2, 'key': '2 days (48 hours)' }, { 'value': 3, 'key': '3 days (72 hours)' }, { 'value': 7, 'key': '7 days' }, { 'value': 14, 'key': '14 days' }, { 'value': 30, 'key': '30 days' }];
