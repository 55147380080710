import { BaseApiOutput } from './base-api.model';

export class ShareGetApiOutput extends BaseApiOutput {
    share: ShareDataApiOutput;
}
export class ShareDataApiOutput {
    people_list: ShareMemberApiOutput[];
    people: number;
    owner_name: string;
    is_readonly: 1 | 0;
    is_owner: 1 | 0;
    is_seeothers: 1 | 0;
    is_invite: 1 | 0;
    enc_name: string;
    label: string;
    share_id: number;
    share_sequence: number;
    invite_status: string;
    uniqid: number;
    sync_id: number;
    salt: string;
    enc_password: string;
    iterations: number;
}

export class ShareGetApiInput {
    share_id: number;
}

export class ShareMemberApiOutput {
    uniqid: number;
    userid: number;
    email: string;
    displayname: string;
    status: string;
    pubkey_id: number;
    pubkey: string;
    share_id: number;
    permissions: {[permission: string]: string};
    num_apps: number;

}

