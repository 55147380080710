import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LinkItem, ErrCode, LinkPreCreateOptions, BlendEvent, LinkListItem } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { NotificationsService } from '../../core/notifications.service';
import { Subscription } from 'rxjs';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LinkListService } from '../services/link-list.service';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { BlendService } from '../../shared/services/blend.service';
import { renameLinkOptions } from '../../util/blend-property-names';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as LinkListActions from '../../actions/link-list.actions';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { FeatureService } from '../../shared/services/feature.service';

@Component({
    selector: 'sync-link-manage',
    templateUrl: './link-manage.component.html'
})
export class LinkManageComponent implements OnInit, OnDestroy {

    @Input() item: LinkListItem | sync.IFile;
    @Input() link: LinkItem;
    @Input() linkPrecreateOptions: LinkPreCreateOptions;

    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public isPro = 0;
    public linkSendResult = 0;
    public isOnTrial = 0;
    public isEnced = true;
    public linkEnforcedSettings = {
        isLinkExpiryDateRequired: false,
        linkExpiryDateDefault: null,
        isLinkPasswordRequired: false,
        linkPasswordDefault: null
    };
    public spinner = false;
    public expDate: NgbDateStruct;
    public expTime: NgbTimeStruct;
    public expDateMin: NgbDateStruct;
    public errcode: ErrCode;

    linkSettingsForm: FormGroup;

    public copyText = 'Copy link';
    private subscription: Subscription;

    public total = 0;
    public finished = 0;
    public modalText: string;
    private isComponentActive = true;
    private isWsProgressAllowed = false;

    constructor(
        private log: LoggerService,
        private base64: Base64Service,
        private userService: UserService,
        private notificationsService: NotificationsService,
        private linklistService: LinkListService,
        private formBuilder: FormBuilder,
        private blendService: BlendService,
        private store: Store<fromRoot.State>,
        private api: ApiService,
        private dirtyOut: DirtyOutService,
        public featureService: FeatureService
    ) { }

    async ngOnInit() {
        const dt = new Date();
        this.expDateMin = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
        };
        if (this.link.exp_servtime) {
            const exp = new Date(this.link.exp_servtime);
            this.expDate = {
                year: exp.getFullYear(),
                month: exp.getMonth() + 1,
                day: exp.getDate(),
            };
            this.expTime = {
                hour: exp.getHours(),
                minute: exp.getMinutes(),
                second: 0,
            };
        }
        this.isPro = this.userService.get('is_pro');
        this.isOnTrial = this.userService.get('is_on_trial');
        this.isEnced = this.link.cnt > 0 ? false : true;
        this.isWsProgressAllowed = await this.featureService.isAllowed('websocketProgress');
        this.linkEnforcedSettings = this.linklistService.getLinkEnforcedSettings();

        this.linkSettingsForm = this.formBuilder.group({
            password: [this.link.passwordprotect],
            expiryDate: [this.expDate],
            expiryTime: [this.expTime],
        });

        if (this.linkEnforcedSettings.isLinkExpiryDateRequired) {
            this.linkSettingsForm.get('expiryDate').setValidators(Validators.required);
        }

        if (this.linkEnforcedSettings.isLinkPasswordRequired) {
            this.linkSettingsForm.get('password').setValidators(Validators.required);
        }

        if (!this.isEnced) {
            this.encLink();
        }
    }

    public async encLink() {
        this.notificationsService.stopNotificationLoop();

        const updateModalText = (progress: number) => {
            this.modalText = this.isWsProgressAllowed
                ? `Encrypting link ${progress}%, Please wait...`
                : 'Encrypting link, Please wait...';
        };

        this.total = this.link.cnt + this.link.enc_cnt;
        this.finished = this.link.enc_cnt;
        updateModalText(Math.min(Math.round((this.finished / this.total) * 100), 100));

        while (this.finished < this.total && this.isComponentActive) {
            const data = await this.dirtyOut.executeForShare(this.link.share_id);

            if (!data || (Array.isArray(data) && data.length === 0)) {
                this.finished = this.total;
                updateModalText(100);
            }
            if (data && data.completed) {
                this.finished += data.completed;
                const percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                updateModalText(percentage);

                if (this.finished >= this.total) {
                    updateModalText(100);
                    break;
                }
            }
        }
        this.modalText = 'Finalizing Link, Please wait...';
        this.isEnced = true;
        this.notificationsService.startNotificationLoop();
        this.log.info('post link action completed');
    }

    public copy(elemId) {
        const copyInput = <HTMLInputElement>document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        } else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(() => {
                this.copyText = 'Copy link';
            }, 2000);
        }
    }

    public setState(state: number) {
        this.state.emit(state);
    }

    public closeDialog() {
        this.close.emit(false);
    }

    public onDateSelection() {
        const dt = new Date();
        this.linkSettingsForm.get('expiryTime').setValue({
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: 0,
        });
    }

    public clearExp() {
        this.linkSettingsForm.get('expiryDate').setValue(null);
        this.linkSettingsForm.get('expiryTime').setValue(null);
    }

    public async onSubmit() {
        try {
            this.errcode = null;
            this.spinner = true;

            const expDate = this.linkSettingsForm.get('expiryDate').value;
            let expTime = this.linkSettingsForm.get('expiryTime').value;
            let expServtime;
            if (expDate) {
                if (!expTime) {
                    const exp = new Date();
                    expTime = {
                        hour: exp.getHours(),
                        minute: exp.getMinutes(),
                        second: 0,
                    };
                }
                const dt = new Date(
                    expDate.year,
                    expDate.month - 1,
                    expDate.day,
                    expTime.hour,
                    expTime.minute,
                    expTime.second
                );
                expServtime = dt.getTime();
            }
            const linkSetOptions = {
                sync_id: this.link.sync_id,
                share_id: this.link.share_id,
                publink_id: this.link.cachekey,
                exp_servtime: parseInt(expServtime, 10),
                label: this.link.label,
                download_limit: this.link.download_limit,
                email_notification: this.link.email_notification,
                upload: this.link.upload,
                previewonly: this.link.previewonly,
                file_edit: this.link.file_edit,
                compat: this.link.compat,
                allow_comment: this.link.allow_comment,
                comment_notification: this.link.comment_notification
            };
            await this.api.execute('linksetoptions', linkSetOptions);

            const password = this.base64.encode(
                this.linkSettingsForm.get('password').value
            );
            await this.api.execute('linksetpassword', {
                sync_id: this.link.sync_id,
                share_id: this.link.share_id,
                publink_id: this.link.cachekey,
                password: password,
            });
            // link properties are required to be updated as per new values
            // so that same and true data is shared between components
            this.link.exp_servtime = parseInt(expServtime, 10);
            this.link.password = password;
            this.link.passwordprotect = this.linkSettingsForm.get('password').value;

            this.blendService.track(BlendEvent.SAVE_LINK_SETTINGS, renameLinkOptions({ ...linkSetOptions, type: this.link.type, file_extension: this.item.file_extension }));

            this.log.info('Saved link for ' + this.link.cachekey);
            this.store.dispatch(new LinkListActions.LinkListRefreshAction());
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;
    }
}
