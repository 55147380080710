import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackService } from '../../notifications/feedback.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ShareService } from '../share.service';

@Component({
    selector: 'sync-share-leave',
    templateUrl: './share-leave.component.html',
})
export class ShareLeaveComponent implements OnInit {
    @Input() public openState: number;
    @Input() public modalInstance: NgbModalRef;
    @Input() public shareData: sync.IShareData;

    constructor(
        private shareService: ShareService,
        private feedbackService: FeedbackService,
        private broadcastService: BroadcastService
    ) {}

    ngOnInit() {}

    public async leaveShare() {
        await this.shareService.leaveShare(
            this.shareData.shareId,
            this.shareData.sharememberId
        );
        this.broadcastService.broadcast('event:file-list.reload');
        this.broadcastService.broadcast('event:share-list.reload');
        this.feedbackService.setFeedback(
            'success',
            `You have left The share ${this.shareData.name}`,
            2000
        );
        this.modalInstance.close();
    }
}
