import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../../core/api.service';

@Component({
    selector: 'sync-save-file',
    templateUrl: './save-file.component.html',
})
export class SaveFileComponent implements OnInit, OnChanges {

    @Input('syncSaveFile') public saveFile:
        | sync.ITransferItemDownload
        | sync.ITransferItemUpload;
    @Input() public autodownload: string;

    constructor(
        private apiService: ApiService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['saveFile']
            && changes['saveFile'].currentValue
            && parseInt(this.autodownload, 10)
            && !this.saveFile.renderFile.downloadSuccess
        ) {
            setTimeout(() => {
                const elem = document.getElementById(this.saveFile.uniqid);
                if (elem) {
                    elem.click();
                    // Set 'downloadSuccess' to 1 to indicate that the download has been triggered successfully
                    this.saveFile.renderFile.downloadSuccess = 1;
                }
            }, 1000);
        }
    }

    bypassSecurityTrustUrl(url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    }

    ngOnInit() {}

    public msBlobSave(blob: Blob, filename: string) {
        if (this.saveFile.linkID) {
            this.consume([this.saveFile.linkID]);
        }
            window.navigator.msSaveOrOpenBlob(blob, filename);
    }

    public saveDlAttr() {
        if (this.saveFile.linkID) {
            this.consume([this.saveFile.linkID]);
        }
    }

    public consume(links: Array<string>) {
        const publinks = [];
        for (let i = 0, len = links.length; i < len; i++) {
            publinks.push({ publink_id: links[i] });
        }
        return this.apiService.execute('linkconsume', { links: publinks });
    }
}
