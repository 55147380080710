import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { RewardsService } from '../services/rewards.service';

@Component({
    selector: 'sync-dialog1-up',
    templateUrl: './dialog1-up.component.html',
})
export class Dialog1UpComponent implements OnInit {
    public spinner = false;
    public returnCode: number;

    constructor(
        public activeModal: NgbActiveModal,
        private rewardsService: RewardsService,
        private userService: UserService
    ) {}

    ngOnInit() {}

    closeModal() {

    }

    public async submit() {
        this.returnCode = -1;
        this.spinner = true;
        try {
            await this.rewardsService.claimRewardOnce('sync-konami-code');
            await this.userService.refresh();
            this.returnCode = 0;
            this.spinner = false;
        } catch (error) {
            this.returnCode = 1;
            this.spinner = false;
        }
    }
}
