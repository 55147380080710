import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class UserSignRequestApiOutput extends BaseApiOutput {
    signature: string;
}

export class UserSignRequestApiInput extends BaseApiInput {
    sign: {
        [k: string]: string;
    };
}


