export class DirtyOutNeedEncItem {
    sync_id: number;
    servtime: number;
    share_id: number;
    is_enced: number;
    metaname: string;
    blob_id: number;
    new_key_id: string;
    data_keys: DirtyOutNeedEncDataKeys[];
}

export class DirtyOutNeedEncDataKeys {
    sync_id?: number;
    blob_id: number;
    sharekey_id: string;
    enc_data_key: string;
}
