// version.service.ts
import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private localStorageKey = 'sync-appVersion';
  private versionUrl = '../../../assets/version.txt';
  private storedOldVersion: any;
  private isReloading = false; // Add a flag to track reloading state
  public isPromptOpen = false;
  public isVersionChanged = false;
  private newVersionData!: string;
  constructor(private api: ApiService) {
  }
  isLocalStorageAllowed(): boolean {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (error) {
      console.error('Error checking localStorage permission:', error);
      return false;
    }
  }
  async loadVersion() {
    if (!this.isLocalStorageAllowed()) {
      return;
    }
    if (this.isReloading) {
      return;
    }
    this.isReloading = true;
    // Ensure storedOldVersion is initialized
    this.getVersionFromLocalStorage();
    try {
      const data: any = await this.api.fetchText(this.versionUrl);
      this.newVersionData = JSON.parse(data).version;
      if (this.storedOldVersion !== this.newVersionData) {
        this.isVersionChanged = true;
        this.isPromptOpen = true;
      }
    } catch (error) {
      console.error('Error loading version:', error);
    } finally {
      this.isReloading = false;
    }
    return this.isVersionChanged;
  }
  setVersionInLocalStorage() {
    const jsonString = this.newVersionData || '';
    localStorage.setItem(this.localStorageKey, jsonString);
  }
  getVersionFromLocalStorage() {
    this.storedOldVersion = localStorage.getItem(this.localStorageKey);
  }
  reloadApp() {
    this.setVersionInLocalStorage();
    // Reload the application
    window.location.reload();
  }
}
