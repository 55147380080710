import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/api.service';
import { UserService } from '../../../core/user.service';
import { ErrCode } from '../../models';

@Component({
    selector: 'sync-verify-email-required',
    templateUrl: './verify-email-required.component.html'
})
export class VerifyEmailRequiredComponent implements OnInit {

    public errcode: ErrCode;
    public spinner: boolean;
    public success: boolean;
    public userEmail: string;

    constructor(
        private api: ApiService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.success = false;
        this.spinner = false;
        this.errcode = undefined;
        this.userEmail = this.userService.get('email');
    }
    public async resendVerification() {
        this.spinner = true;
        try {
            await this.api.execute('userverifyemailresend', {});
            this.success = true;
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

}
