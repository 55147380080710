<div [ngClass]="{'plan-details-desabled' : isDisabled}">
  <div *ngIf="isTrial" style="background-color:#00adef;color:#ffffff;padding: 8px; padding-left:24px;font-size:16px;">
    Your current plan:
  </div>

  <!-- start trial block -->
  <div *ngIf="isTrial" style="border-style:solid;border-width:1px;border-color:#c2c2c2;padding:24px;">
    <div style="font-weight:700;font-size:22px;">
      <img src="images/pro-black.svg" style="margin-bottom:6px;" width="46" *ngIf="plan.isPro"> {{plan.name}} trial
    </div>
    <hr style="border-color:#c2c2c2;" />
    <div class="row">
      <div class="col-xs-9" style="font-size:18px;">
        <div style="font-weight: 700;"> {{plan.isPro && "Pro"}} {{plan.name}} plan</div>
        <div> {{currentTerm | titlecase}}</div>
        <div *ngIf="teamsQty>0"> {{teamsQty}} users</div>
        <div> Trial ends on {{planExpiry | date: 'MMMM d, yyyy'}}</div>
        <div style="color: #f79256; font-weight: 700;" *ngIf="isTrialUserWithoutCC"> Important: add a billing profile before the trial expires</div>
      </div>
      <div class="col-xs-3">
        <button *ngIf="isTrialUserWithoutCC" class="btn btn-primary" routerLink="/account/billing/profile"
          style="font-size:18px; background-color:#1d4e88; border-color:#1d4e88; padding: 15px 30px; border-radius: 10px; float: right;">
          Add billing profile
        </button>
      </div>
    </div>
  </div>
  <!-- end trial block-->


  <!-- start product -->
  <div *ngIf="!isTrial" style="border-style:solid;border-width:1px;border-color:#c2c2c2;padding:24px;">
    <p style="font-weight:700;font-size:22px;">
      <img src="images/pro-black.svg" style="margin-bottom:6px;" width="46" *ngIf="plan.isPro"> {{plan.name}}
      <ng-container *ngIf="isCurrentPlan">
        <span class="badge badge-secondary" style="font-size:16px;background-color:#00adef;">Current plan</span>
      </ng-container>
    </p>

    <hr style="border-color:#c2c2c2;" />

    <p style="color:#8f9188;font-size:28px;font-weight:700;margin-bottom:0px;padding-bottom:16px;">
      $<span *ngIf="viewTerm == 'annual'">{{plan.annual.price}} </span>
      <span *ngIf="viewTerm == 'monthly'">{{plan.monthly.price}} </span>
      <span style="color:#8f9188;font-size: 16px;margin-top:0px;font-weight:400;">
        <span *ngIf="teamsQty>0">per user, </span>per month
      </span>
    </p>


    <label><span *ngIf="plan.monthly.price">Select</span> Term:</label>

    <div class="row">
      <div class="col-xs-12" style="margin-bottom:12px;">

        <label class="radio-inline" *ngIf="plan.monthly.price">
          <input type="radio" [(ngModel)]="viewTerm" name="{{isTrial ? 'trialplan' : plan.name}}" value="monthly"
            (click)="onTermChange('monthly');">
          Monthly
        </label>
        <label class="radio-inline">
          <input type="radio" [(ngModel)]="viewTerm" name="{{isTrial ? 'trialplan' : plan.name}}" value="annual"
            (click)="onTermChange('annual');">
          Annual
        </label>

      </div>
    </div>

    <label *ngIf="teamsQty>0">Select users:</label>
    <div class="row">

      <div class="col-xs-12">

        <div style="width:100px;float:left;margin-right:4px;margin-bottom:4px;" *ngIf="teamsQty>0">

          <select class="form-control" id="bpt_users" [(ngModel)]="teamsQty" (click)="onQtyChange($event);">
            <option [value]="i" *ngFor="let i of userLimit">{{i}}</option>
          </select>

        </div>

        <div style="width:150px;float:left;margin-bottom:4px;">

          <button class="btn btn-primary" [disabled]="isButtonDisabled" (click)="onUpgrade(plan.family, plan[viewTerm].id, teamsQty)"
            style="font-size:15px; width:150px; background-color:#1d4e88;border-color:#1d4e88;">
            {{ user.is_on_trial ? "Change plan" : "Buy now" }}
          </button>

        </div>
        <div class="row" *ngIf="showPlanUpgradedNote">
          <div class="col-xs-12">            
              <div class="text-danger"><i class="fa fa-arrow-right"></i>&nbsp; Please contact the billing team at <a href="https://www.sync.com/support/">https://www.sync.com/support/</a> for further assistance.</div>
          </div>
        </div>
      </div> 


    </div>
    <!-- Display this warning message when solo user upgraded on team+ plan and they completed all the strong. -->
    <div class="row" *ngIf="['PS', 'PSP'].includes(planSKU) && plan[viewTerm] && user.disk_usage_bytes > plan[viewTerm].disk_limit_bytes">
      <div class="col-xs-12">
        <br/>
        <div class="text-danger"><i class="fa fa-arrow-right"></i>&nbsp;You are currently using more than {{ user.disk_limit_bytes | bytesToSize }} of Storage. After switching to this plan, you need to delete some data before you can add more.</div>
        <div class="text-danger" *ngIf="['PSP'].includes(planSKU) && plan[viewTerm].product_sku == 'BPTSP'"><i class="fa fa-arrow-right"></i>&nbsp;By switching to this plan, you will lose the Custom Branding feature.</div>
      </div>
    </div>
    <!-- free trial -->
    <div class="row" style="margin-top:6px;height:90px;" *ngIf="!isTrial && showTrial">
      <ng-container>
        <div class="col-xs-12">
          <label>New:</label>
          <p>
            <a style="color:#00adef;font-size:18px;font-weight:700;cursor: pointer;"
              (click)="onUpgrade(plan.family, plan.trialPlan[viewTerm].id, (teamsQty>0 ? teamsQty : null), true, viewTerm)">
              <i class="fas fa-star" style="color:#ffd166;"></i> Try free for 30 days <i class="fas fa-arrow-right"
                style="color:#00adef;"></i>
            </a><br />
            <span style="color:#8f9188;font-size: 14px;">No credit card required trial!</span>
          </p>
        </div>
      </ng-container>
    </div>
    <!-- free trial -->


  </div>
  <!-- end product -->
  <!-- start key features  -->
  <div *ngIf="!isTrial"
    style="border-style:solid;border-width:0px 1px 1px 1px;border-color:#c2c2c2;padding:24px;height:230px;">
    <div class="row">
      <div class="col-xs-12" style="font-size:16px;">
        <p><b><span *ngIf="plan.isPro">Pro</span> {{plan.name}} key features:</b></p>

        <p>
          <ng-container *ngFor="let feature of plan.features">
            <i aria-hidden="true" class="fa fa-check-circle" style="color:#00EFB9"></i> {{feature}}<br>
          </ng-container>
        </p>

      </div>
    </div>
  </div>
  <!-- features -->
</div>