import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchRestoreService } from '../batch-restore.service';
import { FeedbackService } from '../../notifications/feedback.service';

@Component({
    selector: 'sync-dialog-restore',
    templateUrl: './dialog-restore.component.html'
})
export class DialogRestoreComponent implements OnInit {
    public dlgView: sync.IBatchDialogView;
    public batchView: sync.IBatchActionView;
    @Input() selected: sync.IFile[] = [];

    constructor(
        private batchRestoreService: BatchRestoreService,
        public activeModal: NgbActiveModal,
        private feedbackService: FeedbackService,
    ) {}

    ngOnInit() {
        this.batchRestoreService.init();
        this.batchView = this.batchRestoreService.view;

        this.dlgView = {
            itemlist: this.selected,
            action: 'restore',
            title: 'Restore items',
            btnExecuteText: 'Restore',
            btnExecuteClass: 'btn btn-success',
            confirm: {
                check: false,
                show: false,
                notices: [],
                txt: ' Deleting files frees up your account\'s disk usage',
            },
        };
    }
    public getClass(syncID: number) {
        return this.batchRestoreService.isCompleted(syncID) ? '' : 'deleted';
    }

    public closeDialog() {
        if (this.batchRestoreService.view.spinner !== 0) {
            const c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.batchRestoreService.cancel();
                this.activeModal.close();
            }
        } else {
            this.activeModal.close();
        }
    }

    public removeFromList(listitem: sync.IFile) {
        for (let i = this.dlgView.itemlist.length - 1; i >= 0; i--) {
            if (this.dlgView.itemlist[i].sync_id == listitem.sync_id) {
                this.dlgView.itemlist.splice(i, 1);
                break;
            }
        }
    }

    public sortList(a, b) {
        if (a.type < b.type) {
            return -1;
        } else if (a.type > b.type) {
            return 1;
        } else {
            if (a.search_name < b.search_name) {
                return -1;
            } else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    }

    public async execute() {
        const sync_id_array: number[] = [],
            list = this.dlgView.itemlist
                ? this.dlgView.itemlist.sort((a, b) => this.sortList(a, b))
                : [];

        let files = 0,
            directories = 0;

        for (let i = list.length - 1; i >= 0; i--) {
            sync_id_array.push(list[i].sync_id);
            if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                directories++;
            } else {
                files++;
            }
        }

        try {
            await this.batchRestoreService.execute(sync_id_array).toPromise();
            let successMsg = `${list.length} file${files > 1 ? 's' : ''} restored`;
            if (directories && files) {
                successMsg = `${list.length} items restored`;
            } else if (directories) {
                successMsg = `${list.length} folder${directories > 1 ? 's' : ''} restored`;
            }
            this.feedbackService.setFeedback('success', successMsg, 10000);
        } catch (error) {
            let errorMsg = `${list.length} file${files > 1 ? 's' : ''} failed to restore`;
            if (directories && files) {
                errorMsg = `${list.length} items failed to restore`;
            } else if (directories) {
                errorMsg = `${list.length} folder${directories > 1 ? 's' : ''} failed to restore`;
            }
            this.feedbackService.setFeedback('danger', errorMsg, 10000);
        } finally {
            this.activeModal.close();
        }
    }
}
