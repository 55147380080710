import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { TransferService } from './transfer.service';

@Directive({
    selector: '[syncUploadFileSelect]',
})
export class UploadFileSelectDirective implements OnChanges {
    @Input('syncUploadFileSelect') syncUploadPublic: string;
    @Input() public fileUpload: any;

    constructor(private transferService: TransferService) {}

    @HostListener('change', ['$event'])
    ngOnChanges() {
      if (this.fileUpload) {
        if (parseInt(this.syncUploadPublic, 10) !== 0) {
            this.transferService.queuePublicUpload(this.fileUpload);
        } else {
            this.transferService.queueUpload(this.fileUpload, false);
        }
      }

    }
}
