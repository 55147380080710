
import { Injectable } from '@angular/core';


import { of, from, Observable, throwError } from 'rxjs';
import { withLatestFrom, map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as FileListActions from '../actions/file-list.actions';
import { FileListService } from '../file-list/services/file-list.service';
import { StarredListService } from '../file-list/services/starred-list.service';


import { State, getFileListState } from '../reducers';
@Injectable()
export class FileListEffects {

    constructor(
        private files: FileListService,
        private starred: StarredListService,
        private actions$: Actions,
        private store$: Store<State>
    ) {
    }
    @Effect()
    refresh$: Observable<Action> = this.actions$.pipe(
        ofType(FileListActions.FILE_LIST_REFRESH),
        mergeMap((action: FileListActions.FileListRefreshAction) => {
            return from(this.files.getData(
                action.payload.sync_id,
                action.payload.hist_id,
                action.payload.showdeleted,
                action.payload.is_vault
            )).pipe(
                map(data => new FileListActions.FileListSuccessAction(data)),
                catchError((err) => {
                    return of(new FileListActions.FileListErrorAction(err));
                })
            );
        })
    );

    @Effect()
    reload$: Observable<Action> = this.actions$.pipe(
        ofType(FileListActions.FILE_LIST_RELOAD),
        withLatestFrom(this.store$.select(getFileListState)),
        map(([action, storeState]) => {
            return new FileListActions.FileListRefreshAction({
                sync_id: storeState.sync_id,
                hist_id: storeState.hist_id,
                showdeleted: storeState.showdeleted ? 1 : 0,
                offset_metaname_digest: '0',
                is_vault: storeState.is_vault
            });
        })
    );

    @Effect()
    success$: Observable<Action> = this.actions$.pipe(
        ofType(FileListActions.FILE_LIST_SUCCESS),
        withLatestFrom(this.store$.select(getFileListState)),
        map(([action, storeState]) => {
            return new FileListActions.FileListSortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        })
    );

    @Effect()
    showDeleted$: Observable<Action> = this.actions$.pipe(
        ofType(FileListActions.FILE_LIST_SHOW_DELETED),
        withLatestFrom(this.store$.select(getFileListState)),
        map(([action, storeState]) => {
            return new FileListActions.FileListRefreshAction({
                sync_id: storeState.sync_id,
                hist_id: storeState.hist_id,
                showdeleted: storeState.showdeleted ? 1 : 0,
                offset_metaname_digest: '0',
                is_vault: storeState.is_vault
            });
        })
    );

    @Effect()
    showFavorites$: Observable<Action> = this.actions$.pipe(
        ofType(FileListActions.FILE_LIST_SHOW_FAVORITES),
        mergeMap((action: FileListActions.FileListShowFavoritesAction) => {
            return from(this.starred.showFavoritesList(
                action.payload.syncId,
            )).pipe(
                map(data => new FileListActions.FileListSuccessAction(data)),
                catchError((err) => {
                    return of(new FileListActions.FileListErrorAction(err));
                })
            );
        })
    );
}

