const mimeData = [
    {
        name: 'application/vnd.lotus-1-2-3',
        description: '',
        types: ['.123'],
        alternatives: [],
    },
    {
        name: 'text/vnd.in3d.3dml',
        description: '',
        types: ['.3dml'],
        alternatives: [],
    },
    {
        name: 'video/3gpp2',
        description: '',
        types: ['.3g2'],
        alternatives: ['audio/3gpp2'],
    },
    {
        name: 'image/avif',
        description:
            'AVIF (AV1 Image File Format) is an image and animation file format, an extension of <a href="/image/heif">HEIF</a> allowing images encoded with AV1. Most files will only have AV1-encoded images, but a mixture of different encodings is allowed. Early draft versions were named AV1 Still Image File Format.',
        types: ['.avif', '.avifs'],
        alternatives: [],
        appearsAs: ['image/avif-sequence'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/AVIF',
            },
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/image/heif',
            },
            {
                title: 'Wikipedia',
                url: 'https://en.wikipedia.org/wiki/AVIF',
            },
        ],
    },
    {
        name: 'application/x-krita',
        description:
            'KRA is the file format for Krita, a raster graphics editor. It is a ZIP archive containing a number of files, including the image data, the layer structure, and the document settings.<br /><br />It is similar in function to PSD files for photoshop.<br /><br />A .krz file is a compressed version of a .kra file and only missing the mergedimage.png contained within it to save storage. The lack of this file can affect interchange with other applications such as Scribus.',
        types: ['.kra', '.krz'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Krita File Format',
                url: 'https://docs.krita.org/en/general_concepts/file_formats/file_kra.html',
            },
        ],
    },
    {
        name: 'image/heic',
        description:
            'The MIME subtype name may be \'heic\' only if the file conforms to the requirements of the \'heic\', \'heix\', \'heim\', or \'heis\' brand, and contains at least one of those brands as a compatible brand. The MIME subtype name may be \'heif\' only if the file conforms to the requirements of the \'mif1\' brand, and contains that brand as a compatible brand.<br /><br />HEIF (High Efficiency Image File Format) is the lossy image and animation format defined by MPEG-H Part 12 (ISO/IEC 23008-12). <br /><br />It is closely related to the HEVC video format, so in that way it is similar to BPG. It uses boxes/atoms format as a container format, so in that way it is similar to JPEG 2000.',
        types: ['.heif', '.heic'],
        alternatives: [],
        appearsAs: ['image/heif'],
        furtherReading: [
            {
                title: 'The Moving Picture Experts Group',
                url: 'https://mpeg.chiariglione.org/standards/mpeg-h/image-file-format',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/HEIF',
            },
            {
                title: 'Wikipedia',
                url: 'https://en.wikipedia.org/wiki/High_Efficiency_Image_File_Format',
            },
        ],
    },
    {
        name: 'video/3gpp',
        description: '',
        types: ['.3gp'],
        alternatives: ['audio/3gpp'],
    },
    {
        name: 'audio/3gpp2',
        description: '',
        types: ['.3g2'],
        alternatives: ['video/3gpp2'],
    },
    {
        name: 'audio/3gpp',
        description: '',
        types: ['.3gp'],
        alternatives: ['video/3gpp'],
    },
    {
        name: 'application/x-7z-compressed',
        description: '7-zip archive',
        types: ['.7z'],
        alternatives: [],
    },
    {
        name: 'application/octet-stream',
        description:
            'This MIME type is used for binary files, it\'s usually a fallback for unknown/generic MIMEs with no specific designation.',
        types: [
            '.a',
            '.bin',
            '.bpk',
            '.deploy',
            '.dist',
            '.distz',
            '.dmg',
            '.dms',
            '.dump',
            '.elc',
            '.iso',
            '.lha',
            '.lrf',
            '.lzh',
            '.o',
            '.obj',
            '.pkg',
            '.so',
        ],
        alternatives: [],
    },
    {
        name: 'application/x-authorware-bin',
        description: '',
        types: ['.aab', '.u32', '.vox', '.x32'],
        alternatives: [],
    },
    {
        name: 'image/x-icns',
        description:
            'ICNS (often written in lowercase as icns) is the native icon format of Mac OS and OS X.<br /><br />It goes by many other names, including Apple Icon, Macintosh Icon, Mac OS Icon, Macintosh OS X Icon, Mac OS X Icon Resource, Mac OS icns Resource, and IconFamily Resource.<br /><br />There are usually multiple images in a file. Supported image sizes are 16×12, and 16, 32, 48, 128, 256, 512, and 1024 pixels square.',
        types: ['.icns'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/ICNS',
            },
            {
                title: 'Wikipedia',
                url: 'https://en.wikipedia.org/wiki/Apple_Icon_Image_format',
            },
        ],
    },
    {
        name: 'audio/aac',
        description:
            'AAC (Advanced Audio Coding) is a compressed audio format defined in MPEG-2 Part 7 (ISO/IEC 13818-7), and in an updated form in MPEG-4 Part 3 (ISO/IEC 14496-3). It was designed to be the successor to MP3. iTunes distributes (or distributed) files in this format, apparently including various metadata (like the name, and sometimes email of the account which downloaded it, along with the time it was downloaded).<br /><br />An AAC file may contain only the raw AAC format, or it may use a multimedia container format such MP4 or QuickTime. AAC is often used for the audio component of a video file.',
        types: ['.aac', '.mp4', '.m4a'],
        alternatives: ['audio/x-aac'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/AAC',
            },
        ],
    },
    {
        name: 'application/x-authorware-map',
        description: '',
        types: ['.aam'],
        alternatives: [],
    },
    {
        name: 'application/x-authorware-seg',
        description: '',
        types: ['.aas'],
        alternatives: [],
    },
    {
        name: 'application/x-abiword',
        description: '',
        types: ['.abw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.americandynamics.acc',
        description: '',
        types: ['.acc'],
        alternatives: [],
    },
    {
        name: 'application/x-ace-compressed',
        description: '',
        types: ['.ace'],
        alternatives: [],
    },
    {
        name: 'application/vnd.acucobol',
        description: '',
        types: ['.acu'],
        alternatives: [],
    },
    {
        name: 'application/vnd.acucorp',
        description: '',
        types: ['.acutc', '.atc'],
        alternatives: [],
    },
    {
        name: 'audio/adpcm',
        description: '',
        types: ['.adp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.audiograph',
        description: '',
        types: ['.aep'],
        alternatives: [],
    },
    {
        name: 'application/x-font-type1',
        description: '',
        types: ['.afm', '.pfa', '.pfb', '.pfm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ibm.modcap',
        description: '',
        types: ['.afp', '.list3820', '.listafp'],
        alternatives: [],
    },
    {
        name: 'application/postscript',
        description: '',
        types: ['.ai', '.eps', '.ps'],
        alternatives: [],
    },
    {
        name: 'audio/x-aiff',
        description: '',
        types: ['.aif', '.aifc', '.aiff'],
        alternatives: [],
    },
    {
        name: 'application/vnd.adobe.air-application-installer-package+zip',
        description: '',
        types: ['.air'],
        alternatives: [],
    },
    {
        name: 'application/vnd.amiga.ami',
        description: '',
        types: ['.ami'],
        alternatives: [],
    },
    {
        name: 'application/vnd.android.package-archive',
        description:
            'APK is an archive format used for distributing Android apps. It is based on the Jar format (for Java), and like that format, is actually a ZIP archive with a different extension, and with specific files and directories within it.<br /><br />The executable part of the app is usually in either Dalvik Executable (for older Android versions prior to 5.0) or ART format (for newer versions).<br /><br />Metadata about the app is in a META-INF directory within the archive (similarly to Jar files), compiled code is in a lib directory, and resources in a res directory. There are also a few files at the root level including AndroidManifest.xml (which may be regular XML or, often, binary XML).',
        types: ['.apk'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/APK',
            },
        ],
    },
    {
        name: 'application/x-ms-application',
        description: '',
        types: ['.application'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-approach',
        description: '',
        types: ['.apr'],
        alternatives: [],
    },
    {
        name: 'application/pgp-signature',
        description:
            'PGP (Pretty Good Privacy) is an encryption program, and its encryption format which eventually became the OpenPGP standard.<br /><br />Created by Phil Zimmerman in 1991, it got into some legal trouble early on because encryption of this grade was classified as a munition under U.S. law and restricted from export. These laws, while not completely repealed even now, have been considerably liberalized since, and now export of PGP and related software is only restricted when it\'s being exported to countries such as Iran that are under special sanction. Zimmerman\'s PGP company was eventually acquired by Network Associates (now McAfee), which eventually rebranded the original command-line-based PGP as "McAfee E-Business Server" and sold off all other PGP assets to some PGP developers who formed a new PGP company, which was eventually acquired by Symantec. Meanwhile, the specifications of the file format had been released as the OpenPGP spec and published as an RFC document, making it available for use in other products.<br /><br />GNU Privacy Guard (GnuPG, GPG) is a commonly-used encryption tool using PGP format, running on Linux systems.<br /><br />PGP uses a combination of several encryption techniques applied serially, including symmetric-key and public-key cryptography.',
        types: ['.asc', '.sig'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PGP',
            },
        ],
    },
    {
        name: 'video/x-ms-asf',
        description: '',
        types: ['.asf', '.asx'],
        alternatives: [],
    },
    {
        name: 'text/x-asm',
        description: '',
        types: ['.asm', '.s'],
        alternatives: [],
    },
    {
        name: 'application/vnd.accpac.simply.aso',
        description: '',
        types: ['.aso'],
        alternatives: [],
    },
    {
        name: 'application/atom+xml',
        description: '',
        types: ['.atom'],
        alternatives: [],
    },
    {
        name: 'application/atomcat+xml',
        description: '',
        types: ['.atomcat'],
        alternatives: [],
    },
    {
        name: 'application/atomsvc+xml',
        description: '',
        types: ['.atomsvc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.antix.game-component',
        description: '',
        types: ['.atx'],
        alternatives: [],
    },
    {
        name: 'audio/basic',
        description: '',
        types: ['.au', '.snd'],
        alternatives: [],
    },
    {
        name: 'video/x-msvideo',
        description: '',
        types: ['.avi'],
        alternatives: [],
    },
    {
        name: 'application/applixware',
        description: '',
        types: ['.aw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.airzip.filesecure.azf',
        description: '',
        types: ['.azf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.airzip.filesecure.azs',
        description: '',
        types: ['.azs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.amazon.ebook',
        description: '',
        types: ['.azw'],
        alternatives: [],
    },
    {
        name: 'application/x-msdownload',
        description: '',
        types: ['.bat', '.com', '.dll', '.exe', '.msi'],
        alternatives: [],
    },
    {
        name: 'application/x-bcpio',
        description: '',
        types: ['.bcpio'],
        alternatives: [],
    },
    {
        name: 'application/x-font-bdf',
        description: '',
        types: ['.bdf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.syncml.dm+wbxml',
        description: '',
        types: ['.bdm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujitsu.oasysprs',
        description: '',
        types: ['.bh2'],
        alternatives: [],
    },
    {
        name: 'application/vnd.bmi',
        description: '',
        types: ['.bmi'],
        alternatives: [],
    },
    {
        name: 'image/bmp',
        description: '',
        types: ['.bmp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.framemaker',
        description: '',
        types: ['.book', '.fm', '.frame', '.maker'],
        alternatives: [],
    },
    {
        name: 'application/vnd.previewsystems.box',
        description: '',
        types: ['.box'],
        alternatives: [],
    },
    {
        name: 'application/x-bzip2',
        description: '',
        types: ['.boz', '.bz2'],
        alternatives: [],
    },
    {
        name: 'image/prs.btif',
        description: '',
        types: ['.btif'],
        alternatives: [],
    },
    {
        name: 'application/x-bzip',
        description: '',
        types: ['.bz'],
        alternatives: [],
    },
    {
        name: 'text/x-c',
        description: '',
        types: ['.c', '.cc', '.cpp', '.cxx', '.dic', '.h', '.hh'],
        alternatives: [],
    },
    {
        name: 'application/vnd.clonk.c4group',
        description: '',
        types: ['.c4d', '.c4f', '.c4g', '.c4p', '.c4u'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-cab-compressed',
        description: '',
        types: ['.cab'],
        alternatives: [],
    },
    {
        name: 'application/vnd.curl.car',
        description: '',
        types: ['.car'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-pki.seccat',
        description: '',
        types: ['.cat'],
        alternatives: [],
    },
    {
        name: 'application/x-director',
        description: '',
        types: [
            '.cct',
            '.cst',
            '.cxt',
            '.dcr',
            '.dir',
            '.dxr',
            '.fgd',
            '.swa',
            '.w3d',
        ],
        alternatives: [],
    },
    {
        name: 'application/ccxml+xml',
        description: '',
        types: ['.ccxml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.contact.cmsg',
        description: '',
        types: ['.cdbcmsg'],
        alternatives: [],
    },
    {
        name: 'application/x-netcdf',
        description: '',
        types: ['.cdf', '.nc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mediastation.cdkey',
        description: '',
        types: ['.cdkey'],
        alternatives: [],
    },
    {
        name: 'chemical/x-cdx',
        description: '',
        types: ['.cdx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.chemdraw+xml',
        description: '',
        types: ['.cdxml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.cinderella',
        description: '',
        types: ['.cdy'],
        alternatives: [],
    },
    {
        name: 'application/pkix-cert',
        description: '',
        types: ['.cer'],
        alternatives: [],
    },
    {
        name: 'image/cgm',
        description: '',
        types: ['.cgm'],
        alternatives: [],
    },
    {
        name: 'application/x-chat',
        description: '',
        types: ['.chat'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-htmlhelp',
        description: '',
        types: ['.chm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kchart',
        description: '',
        types: ['.chrt'],
        alternatives: [],
    },
    {
        name: 'chemical/x-cif',
        description: '',
        types: ['.cif'],
        alternatives: [],
    },
    {
        name: 'application/vnd.anser-web-certificate-issue-initiation',
        description: '',
        types: ['.cii'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-artgalry',
        description: '',
        types: ['.cil'],
        alternatives: [],
    },
    {
        name: 'application/vnd.claymore',
        description: '',
        types: ['.cla'],
        alternatives: [],
    },
    {
        name: 'application/java-vm',
        description: '',
        types: ['.class'],
        alternatives: [],
    },
    {
        name: 'application/vnd.crick.clicker.keyboard',
        description: '',
        types: ['.clkk'],
        alternatives: [],
    },
    {
        name: 'application/vnd.crick.clicker.palette',
        description: '',
        types: ['.clkp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.crick.clicker.template',
        description: '',
        types: ['.clkt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.crick.clicker.wordbank',
        description: '',
        types: ['.clkw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.crick.clicker',
        description: '',
        types: ['.clkx'],
        alternatives: [],
    },
    {
        name: 'application/x-msclip',
        description: '',
        types: ['.clp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.cosmocaller',
        description: '',
        types: ['.cmc'],
        alternatives: [],
    },
    {
        name: 'chemical/x-cmdf',
        description: '',
        types: ['.cmdf'],
        alternatives: [],
    },
    {
        name: 'chemical/x-cml',
        description: '',
        types: ['.cml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yellowriver-custom-menu',
        description: '',
        types: ['.cmp'],
        alternatives: [],
    },
    {
        name: 'image/x-cmx',
        description: '',
        types: ['.cmx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.rim.cod',
        description: '',
        types: ['.cod'],
        alternatives: [],
    },
    {
        name: 'text/plain',
        description: '',
        types: [
            '.conf',
            '.def',
            '.diff',
            '.in',
            '.ksh',
            '.list',
            '.log',
            '.pl',
            '.text',
            '.txt',
        ],
        appearsAs: ['text/x-log'],
        alternatives: [],
    },
    {
        name: 'application/vnd.debian.binary-package',
        description:
            'deb (Debian package) is a file format used for software distribution in the Debian Linux platform. It is actually an AR archive, with content that includes some tar files. <br /><br />The "udeb" files are in the same basic format as "deb", but are considered "micro deb", used for smaller packages with only the most essential files.',
        types: ['.deb', '.udeb'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Deb',
            },
        ],
    },
    {
        name: 'text/markdown',
        description:
            'Markdown is a lightweight and human readable markup format for text formatting created by John Gruber and Aaron Swartz. It is similar to various forms of wiki markup. There is no formal specification for the original Markdown, and it has ambiguities that are handled inconsistently by different implementations.<br /><br />An attempt to improve on this situation was done (released 2014-09) by a group unrelated to the originators of Markdown, and was originally dubbed Standard Markdown until John Gruber objected to this name, and it was first renamed "Common Markdown" and later CommonMark. Markdeep is an extended Markdown implemented in JavaScript for web use.',
        types: ['.md', '.markdown', '.mdown', '.markdn'],
        alternatives: ['text/x-markdown'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Markdown',
            },
        ],
    },
    {
        name: 'text/x-markdown',
        description:
            'Markdown is a lightweight and human readable markup format for text formatting created by John Gruber and Aaron Swartz. It is similar to various forms of wiki markup. There is no formal specification for the original Markdown, and it has ambiguities that are handled inconsistently by different implementations.<br /><br />An attempt to improve on this situation was done (released 2014-09) by a group unrelated to the originators of Markdown, and was originally dubbed Standard Markdown until John Gruber objected to this name, and it was first renamed "Common Markdown" and later CommonMark. Markdeep is an extended Markdown implemented in JavaScript for web use.',
        types: ['.md', '.markdown', '.mdown', '.markdn'],
        alternatives: ['text/markdown'],
        deprecated: true,
        useInstead: 'text/markdown',
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Markdown',
            },
        ],
    },
    {
        name: 'application/x-cpio',
        description: '',
        types: ['.cpio'],
        alternatives: [],
    },
    {
        name: 'application/mac-compactpro',
        description: '',
        types: ['.cpt'],
        alternatives: [],
    },
    {
        name: 'application/x-mscardfile',
        description: '',
        types: ['.crd'],
        alternatives: [],
    },
    {
        name: 'application/pkix-crl',
        description: '',
        types: ['.crl'],
        alternatives: [],
    },
    {
        name: 'application/x-x509-ca-cert',
        description: '',
        types: ['.crt', '.der'],
        alternatives: [],
    },
    {
        name: 'application/x-csh',
        description: '',
        types: ['.csh'],
        alternatives: [],
    },
    {
        name: 'chemical/x-csml',
        description: '',
        types: ['.csml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.commonspace',
        description: '',
        types: ['.csp'],
        alternatives: [],
    },
    {
        name: 'text/css',
        description: '',
        types: ['.css'],
        alternatives: [],
    },
    {
        name: 'text/csv',
        description:
            'CSV (Comma Separated Values) is a text-based format typically used for the storage or exchange of database-like records. In Microsoft Windows systems, it will commonly open in Excel, but it is not a proprietary format and can be used in many other programs.',
        types: ['.csv'],
        alternatives: [
            'application/csv',
            'text/x-csv',
            'application/x-csv',
            'text/x-comma-separated-values',
            'text/comma-separated-values',
        ],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/CSV',
            },
        ],
    },
    {
        name: 'application/cu-seeme',
        description: '',
        types: ['.cu'],
        alternatives: [],
    },
    {
        name: 'text/vnd.curl',
        description: '',
        types: ['.curl'],
        alternatives: [],
    },
    {
        name: 'application/prs.cww',
        description: '',
        types: ['.cww'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.daf',
        description: '',
        types: ['.daf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fdsn.seed',
        description: '',
        types: ['.dataless', '.seed'],
        alternatives: [],
    },
    {
        name: 'application/davmount+xml',
        description: '',
        types: ['.davmount'],
        alternatives: [],
    },
    {
        name: 'text/vnd.curl.dcurl',
        description: '',
        types: ['.dcurl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oma.dd2+xml',
        description: '',
        types: ['.dd2'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujixerox.ddd',
        description: '',
        types: ['.ddd'],
        alternatives: [],
    },
    {
        name: 'application/x-debian-package',
        description: '',
        types: ['.deb', '.udeb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.dreamfactory',
        description: '',
        types: ['.dfac'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.dis',
        description: '',
        types: ['.dis'],
        alternatives: [],
    },
    {
        name: 'image/vnd.djvu',
        description: '',
        types: ['.djv', '.djvu'],
        alternatives: [],
    },
    {
        name: 'application/vnd.dna',
        description: '',
        types: ['.dna'],
        alternatives: [],
    },
    {
        name: 'application/msword',
        description:
            'Microsoft Word is a popular word processor program which has existed in a number of versions for DOS, Windows, and the Macintosh. It is often distributed as part of the Microsoft Office suite. Various native file formats have been used, and some other non-Word-specific formats can also be opened and saved.<br /><br />Please note the more modern .docx file extension does not use this mime type.',
        types: ['.doc', '.dot', '.wiz'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Microsoft_Word',
            },
        ],
    },
    {
        name: 'application/vnd.ms-word.document.macroenabled.12',
        description: '',
        types: ['.docm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        description: '',
        types: ['.docx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-word.template.macroenabled.12',
        description: '',
        types: ['.dotm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        description: '',
        types: ['.dotx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.osgi.dp',
        description: '',
        types: ['.dp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.dpgraph',
        description: '',
        types: ['.dpg'],
        alternatives: [],
    },
    {
        name: 'text/prs.lines.tag',
        description: '',
        types: ['.dsc'],
        alternatives: [],
    },
    {
        name: 'application/x-dtbook+xml',
        description: '',
        types: ['.dtb'],
        alternatives: [],
    },
    {
        name: 'application/xml-dtd',
        description: '',
        types: ['.dtd'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.dts',
        description: '',
        types: ['.dts'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.dts.hd',
        description: '',
        types: ['.dtshd'],
        alternatives: [],
    },
    {
        name: 'application/x-dvi',
        description: '',
        types: ['.dvi'],
        alternatives: [],
    },
    {
        name: 'model/vnd.dwf',
        description: '',
        types: ['.dwf'],
        alternatives: [],
    },
    {
        name: 'image/vnd.dwg',
        description: '',
        types: ['.dwg'],
        alternatives: [],
    },
    {
        name: 'image/vnd.dxf',
        description: '',
        types: ['.dxf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.spotfire.dxp',
        description: '',
        types: ['.dxp'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.nuera.ecelp4800',
        description: '',
        types: ['.ecelp4800'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.nuera.ecelp7470',
        description: '',
        types: ['.ecelp7470'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.nuera.ecelp9600',
        description: '',
        types: ['.ecelp9600'],
        alternatives: [],
    },
    {
        name: 'application/ecmascript',
        description: '',
        types: ['.ecma'],
        alternatives: [],
    },
    {
        name: 'application/vnd.novadigm.edm',
        description: '',
        types: ['.edm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.novadigm.edx',
        description: '',
        types: ['.edx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.picsel',
        description: '',
        types: ['.efif'],
        alternatives: [],
    },
    {
        name: 'application/vnd.pg.osasli',
        description: '',
        types: ['.ei6'],
        alternatives: [],
    },
    {
        name: 'message/rfc822',
        description:
            'A Content-Type of "message/rfc822" indicates that the body contains an encapsulated message, with the syntax of an RFC 822 message.',
        types: ['.eml', '.mht', '.mhtml', '.mime', '.nws'],
        alternatives: [],
        furtherReading: [
            {
                title: 'W3C',
                url: 'https://www.w3.org/Protocols/rfc1341/7_3_Message.html',
            },
            {
                title: 'Internet Engineering Task Force',
                url: 'https://www.ietf.org/rfc/rfc0822.txt',
            },
        ],
    },
    {
        name: 'application/emma+xml',
        description: '',
        types: ['.emma'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.digital-winds',
        description: '',
        types: ['.eol'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-fontobject',
        description: '',
        types: ['.eot'],
        alternatives: [],
    },
    {
        name: 'application/epub+zip',
        description: '',
        types: ['.epub'],
        alternatives: [],
    },
    {
        name: 'application/vnd.eszigno3+xml',
        description: '',
        types: ['.es3', '.et3'],
        alternatives: [],
    },
    {
        name: 'application/vnd.epson.esf',
        description: '',
        types: ['.esf'],
        alternatives: [],
    },
    {
        name: 'text/x-setext',
        description: '',
        types: ['.etx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.novadigm.ext',
        description: '',
        types: ['.ext'],
        alternatives: [],
    },
    {
        name: 'application/andrew-inset',
        description:
            'Published specification:  "Multimedia Applications Development with the Andrew Toolkit", by Nathaniel S. Borenstein, Prentice Hall, 1990. <br /><br />Editors note: I looked him up, he works at Mimecast now. Reach out!',
        types: ['.ez'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/andrew-inset',
            },
        ],
    },
    {
        name: 'application/vnd.ezpix-album',
        description: '',
        types: ['.ez2'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ezpix-package',
        description: '',
        types: ['.ez3'],
        alternatives: [],
    },
    {
        name: 'text/x-fortran',
        description: '',
        types: ['.f', '.f77', '.f90', '.for'],
        alternatives: [],
    },
    {
        name: 'video/x-f4v',
        description: '',
        types: ['.f4v'],
        alternatives: [],
    },
    {
        name: 'image/vnd.fastbidsheet',
        description: '',
        types: ['.fbs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fdf',
        description: '',
        types: ['.fdf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.denovo.fcselayout-link',
        description: '',
        types: ['.fe_launch'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujitsu.oasysgp',
        description: '',
        types: ['.fg5'],
        alternatives: [],
    },
    {
        name: 'image/x-freehand',
        description: '',
        types: ['.fh', '.fh4', '.fh5', '.fh7', '.fhc'],
        alternatives: [],
    },
    {
        name: 'application/x-xfig',
        description: '',
        types: ['.fig'],
        alternatives: [],
    },
    {
        name: 'video/x-fli',
        description: '',
        types: ['.fli'],
        alternatives: [],
    },
    {
        name: 'application/vnd.micrografx.flo',
        description: '',
        types: ['.flo'],
        alternatives: [],
    },
    {
        name: 'video/x-flv',
        description: '',
        types: ['.flv'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kivio',
        description: '',
        types: ['.flw'],
        alternatives: [],
    },
    {
        name: 'text/vnd.fmi.flexstor',
        description: '',
        types: ['.flx'],
        alternatives: [],
    },
    {
        name: 'text/vnd.fly',
        description: '',
        types: ['.fly'],
        alternatives: [],
    },
    {
        name: 'application/vnd.frogans.fnc',
        description: '',
        types: ['.fnc'],
        alternatives: [],
    },
    {
        name: 'image/vnd.fpx',
        description: '',
        types: ['.fpx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fsc.weblaunch',
        description: '',
        types: ['.fsc'],
        alternatives: [],
    },
    {
        name: 'image/vnd.fst',
        description: '',
        types: ['.fst'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fluxtime.clip',
        description: '',
        types: ['.ftc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.anser-web-funds-transfer-initiation',
        description: '',
        types: ['.fti'],
        alternatives: [],
    },
    {
        name: 'video/vnd.fvt',
        description: '',
        types: ['.fvt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fuzzysheet',
        description: '',
        types: ['.fzs'],
        alternatives: [],
    },
    {
        name: 'image/g3fax',
        description: '',
        types: ['.g3'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-account',
        description: '',
        types: ['.gac'],
        alternatives: [],
    },
    {
        name: 'model/vnd.gdl',
        description: '',
        types: ['.gdl'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/model/vnd.gdl',
            },
        ],
    },
    {
        name: 'application/vnd.dynageo',
        description: '',
        types: ['.geo'],
        alternatives: [],
    },
    {
        name: 'application/vnd.geometry-explorer',
        description: '',
        types: ['.gex', '.gre'],
        alternatives: [],
    },
    {
        name: 'application/vnd.geogebra.file',
        description: '',
        types: ['.ggb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.geogebra.tool',
        description: '',
        types: ['.ggt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-help',
        description: '',
        types: ['.ghf'],
        alternatives: [],
    },
    {
        name: 'image/gif',
        description:
            'Graphics Interchange Format (GIF) was introduced by the CompuServe online service in 1987, intended to provide a consistent and compact format for graphics to be downloaded on that service. Since the specifications were openly released, the format gained wide use in graphics software and on online services and bulletin board systems (BBSs), not just CompuServe; later it became a major Web graphic format. GIF\'s ability to have animation (unlike most still graphic formats) has caused it to gain some Internet notoriety and use in conjunction with "memes".',
        types: ['.gif'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-identity-message',
        description: '',
        types: ['.gim'],
        alternatives: [],
    },
    {
        name: 'application/vnd.gmx',
        description: '',
        types: ['.gmx'],
        alternatives: [],
    },
    {
        name: 'application/x-gnumeric',
        description: '',
        types: ['.gnumeric'],
        alternatives: [],
    },
    {
        name: 'application/vnd.flographit',
        description: '',
        types: ['.gph'],
        alternatives: [],
    },
    {
        name: 'application/vnd.grafeq',
        description: '',
        types: ['.gqf', '.gqs'],
        alternatives: [],
    },
    {
        name: 'application/srgs',
        description: '',
        types: ['.gram'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-injector',
        description: '',
        types: ['.grv'],
        alternatives: [],
    },
    {
        name: 'application/srgs+xml',
        description: '',
        types: ['.grxml'],
        alternatives: [],
    },
    {
        name: 'application/x-font-ghostscript',
        description: '',
        types: ['.gsf'],
        alternatives: [],
    },
    {
        name: 'application/x-gtar',
        description: '',
        types: ['.gtar'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-tool-message',
        description: '',
        types: ['.gtm'],
        alternatives: [],
    },
    {
        name: 'model/vnd.gtw',
        description: '',
        types: ['.gtw'],
        alternatives: [],
    },
    {
        name: 'text/vnd.graphviz',
        description: '',
        types: ['.gv'],
        alternatives: [],
    },
    {
        name: 'application/x-gzip',
        description: '',
        types: ['.gz', '.tgz'],
        alternatives: ['application/gzip'],
    },
    {
        name: 'application/gzip',
        description: '',
        types: ['.gz', '.tgz'],
        alternatives: ['application/x-gzip'],
    },
    {
        name: 'video/h261',
        description: '',
        types: ['.h261'],
        alternatives: [],
    },
    {
        name: 'gcode',
        description:
            'GCODE files are created by slicing programs, such as Simplify3D and Slic3r, that translate CAD drawings into G-Code, which a 3D printer can read.<br /><br />There\'s no official mimetype assigned as far as the author is aware, however these are the ones commonly reported.',
        types: ['.gcode'],
        alternatives: ['text/x.gcode', 'text/x-gcode'],
    },
    {
        name: 'video/h263',
        description: '',
        types: ['.h263'],
        alternatives: [],
    },
    {
        name: 'video/h264',
        description:
            'Advanced Video Coding (AVC), also referred to as H.264 or MPEG-4 Part 10, Advanced Video Coding (MPEG-4 AVC), is a video compression standard based on block-oriented, motion-compensated integer-DCT coding.[1] It is by far the most commonly used format for the recording, compression, and distribution of video content, used by 91% of video industry developers as of September 2019.<br /><br />It supports resolutions up to and including 8K UHD.<br /><br />H.264 is controversial for being patent-encumbered, and hence subject to royalty requirements. In 2013, Cisco is attempting to partially remedy this by producing a freely distributed executable H.264 codec for many platforms for which they have paid the royalty, allowing anybody to download and use it in unmodified form royalty-free. The source code is also openly available, but any altered versions that anybody might create from it would require separate licensing, as would any distribution of the executables other than direct download from Cisco. Thus, the only way to use it as part of a product without additional license fees is to have the product\'s installer download the executable from Cisco during the install process, rather than including it directly in your own product (whether on disk or downloadable from your site).<br /><br />HEVC (H.265) has been developed as a more efficient successor to this format, but it is also patent-encumbered. Attempts to create a royalty-free alternative include AV1, Daala, VP9, and Thor.<br /><br />The H.264 specification does not, in an extremely strict sense, define a codec which fits into a series of bytes; instead, it describes the stream as fitting into a sequence of frames called "network abstraction layer units", which can then be delineated into a raw byte format by the user. The format for putting these into a raw bytestream described by Annex B of the specification apparently is apparently very widely-used.',
        types: ['.h264'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Wikipedia',
                url: 'https://en.wikipedia.org/wiki/Advanced_Video_Coding',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/H.264',
            },
        ],
    },
    {
        name: 'application/vnd.hbci',
        description: '',
        types: ['.hbci'],
        alternatives: [],
    },
    {
        name: 'application/x-hdf',
        description: '',
        types: ['.hdf'],
        alternatives: [],
    },
    {
        name: 'application/winhlp',
        description: '',
        types: ['.hlp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-hpgl',
        description: '',
        types: ['.hpgl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-hpid',
        description: '',
        types: ['.hpid'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-hps',
        description: '',
        types: ['.hps'],
        alternatives: [],
    },
    {
        name: 'application/mac-binhex40',
        description: '',
        types: ['.hqx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kenameaapp',
        description: '',
        types: ['.htke'],
        alternatives: [],
    },
    {
        name: 'text/html',
        description: '',
        types: ['.htm', '.html'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.hv-dic',
        description: '',
        types: ['.hvd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.hv-voice',
        description: '',
        types: ['.hvp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.hv-script',
        description: '',
        types: ['.hvs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.iccprofile',
        description: '',
        types: ['.icc', '.icm'],
        alternatives: [],
    },
    {
        name: 'x-conference/x-cooltalk',
        description: '',
        types: ['.ice'],
        alternatives: [],
    },
    {
        name: 'image/x-icon',
        description: '',
        types: ['.ico'],
        alternatives: [],
    },
    {
        name: 'text/calendar',
        description: '',
        types: ['.ics', '.ifb'],
        alternatives: [],
    },
    {
        name: 'image/ief',
        description: '',
        types: ['.ief'],
        alternatives: [],
    },
    {
        name: 'application/vnd.shana.informed.formdata',
        description: '',
        types: ['.ifm'],
        alternatives: [],
    },
    {
        name: 'model/iges',
        description: '',
        types: ['.iges', '.igs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.igloader',
        description: '',
        types: ['.igl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.micrografx.igx',
        description: '',
        types: ['.igx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.shana.informed.interchange',
        description: '',
        types: ['.iif'],
        alternatives: [],
    },
    {
        name: 'application/vnd.accpac.simply.imp',
        description: '',
        types: ['.imp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-ims',
        description: '',
        types: ['.ims'],
        alternatives: [],
    },
    {
        name: 'application/vnd.shana.informed.package',
        description: '',
        types: ['.ipk'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ibm.rights-management',
        description: '',
        types: ['.irm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.irepository.package+xml',
        description: '',
        types: ['.irp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.shana.informed.formtemplate',
        description: '',
        types: ['.itp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.immervision-ivp',
        description: '',
        types: ['.ivp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.immervision-ivu',
        description: '',
        types: ['.ivu'],
        alternatives: [],
    },
    {
        name: 'text/vnd.sun.j2me.app-descriptor',
        description: '',
        types: ['.jad'],
        alternatives: [],
    },
    {
        name: 'application/vnd.jam',
        description: '',
        types: ['.jam'],
        alternatives: [],
    },
    {
        name: 'application/java-archive',
        description: '',
        types: ['.jar'],
        alternatives: [],
    },
    {
        name: 'text/x-java-source',
        description: '',
        types: ['.java'],
        alternatives: [],
    },
    {
        name: 'application/vnd.jisp',
        description: '',
        types: ['.jisp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-jlyt',
        description: '',
        types: ['.jlt'],
        alternatives: [],
    },
    {
        name: 'application/x-java-jnlp-file',
        description: '',
        types: ['.jnlp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.joost.joda-archive',
        description: '',
        types: ['.joda'],
        alternatives: [],
    },
    {
        name: 'image/jpeg',
        description:
            'JPEG is a popular raster image format well-suited to photographic images. It usually uses lossy DCT compression. It is named after the Joint Photographic Experts Group, the organization which developed the format. It is sometimes called JPEG1, JPEG-1, or JPEG 1992 to help disambiguate it.',
        types: [
            '.jpe',
            '.jpeg',
            '.jpg',
            '.pjpg',
            '.jfif',
            '.jfif-tbnl',
            '.jif',
        ],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/JPEG',
            },
        ],
    },
    {
        name: 'image/pjpeg',
        description:
            'PJPEG files refer to images wherein the Progressive JPEG format (hence JPEG) is used for bitmap images.',
        types: [
            '.jpe',
            '.jpeg',
            '.jpg',
            '.pjpg',
            '.jfi',
            '.jfif',
            '.jfif-tbnl',
            '.jif',
        ],
        alternatives: [],
        furtherReading: [
            {
                title: 'Wikipedia',
                url: 'https://en.wikipedia.org/wiki/JPEG',
            },
        ],
    },
    {
        name: 'video/jpm',
        description: '',
        types: ['.jpgm', '.jpm'],
        alternatives: [],
    },
    {
        name: 'video/jpeg',
        description: '',
        types: ['.jpgv'],
        alternatives: [],
    },
    {
        name: 'application/x-trash',
        description: 'Why even drag this in?',
        types: [],
        alternatives: [],
    },
    {
        name: 'application/x-shellscript',
        description: 'Why even drag this in?',
        types: ['.sh'],
        alternatives: [],
    },
    {
        name: 'text/javascript',
        description:
            'JavaScript (sometimes abbreviated JS) is a scripting language commonly implemented as part of a web browser in order to create enhanced user interfaces and dynamic websites, but also used in other contexts (such as server-side JavaScript).<br /><br />This is not the same as <a href="/text/x-java-source">Java</a>. People constantly get confused about that.<br /><br />JavaScript was originally developed at Netscape by Brendan Eich (who later became Mozilla CEO... very briefly), where it was originally called LiveScript while under development, but became JavaScript (with the name licensed from Sun) in order to capitalize on the popularity of Java, though the languages aren\'t really related (although there are some similarities in syntax). Later, a Microsoft implementation designed to be (more or less) compatible was called JScript, and an attempt at a formally standardized version of the language was published by ECMA as ECMAScript.<br /><br />The node.js runtime environment is (mostly) implemented in JavaScript, as are applications running within it.<br /><br />Due to its status as the only scripting language for browsers, JavaScript skyrocketed in popularity in the 2000\'s and 2010\'s and as of 2017 serves as a lingua franca of Web development. Arguably, it is currently the world\'s most popular programming language (it tops GitHub and StackOverflow currently).',
        types: ['.js'],
        alternatives: [
            'application/ecmascript',
            'application/javascript',
            'application/x-ecmascript',
            'application/x-javascript',
            'text/ecmascript',
            'text/javascript1.0',
            'text/javascript1.1',
            'text/javascript1.2',
            'text/javascript1.3',
            'text/javascript1.4',
            'text/javascript1.5',
            'text/jscript',
            'text/livescript',
            'text/x-ecmascript',
            'text/x-javascript',
        ],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Javascript',
            },
            {
                title: 'WHATWG',
                url: 'https://html.spec.whatwg.org/multipage/scripting.html#scriptingLanguages',
            },
        ],
    },
    {
        name: 'application/json',
        description:
            'JSON (JavaScript Object Notation) is widely used by web applications, mobile apps, and other programs to communicate between different systems (such as between a client and server). While it is named after JavaScript, there are libraries for many other programming and scripting languages to let them use this format as well. The geospatial format GeoJSON is based on JSON, as is the remote-procedure-call protocol JSON-RPC and the JSON-LD linked-data format. The IBM standard of JSONx is an XML implementation of JSON. I-JSON is a restricted profile of JSON for Internet use. JSON Web Tokens, JSON Web Encryption, and JSON Web Signatures are JSON-based formats for security-related functions.',
        types: ['.json'],
        alternatives: ['text/json'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/JSON',
            },
        ],
    },
    {
        name: 'text/json',
        description:
            'JSON (JavaScript Object Notation) is widely used by web applications, mobile apps, and other programs to communicate between different systems (such as between a client and server). While it is named after JavaScript, there are libraries for many other programming and scripting languages to let them use this format as well. The geospatial format GeoJSON is based on JSON, as is the remote-procedure-call protocol JSON-RPC and the JSON-LD linked-data format. The IBM standard of JSONx is an XML implementation of JSON. I-JSON is a restricted profile of JSON for Internet use. JSON Web Tokens, JSON Web Encryption, and JSON Web Signatures are JSON-based formats for security-related functions.',
        types: ['.json'],
        alternatives: ['application/json'],
        deprecated: true,
        useInstead: 'application/json',
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/JSON',
            },
        ],
    },
    {
        name: 'audio/midi',
        description: '',
        types: ['.kar', '.mid', '.midi', '.rmi'],
        alternatives: [],
    },
    {
        name: 'audio/aiff',
        description:
            'AIFF (Audio Interchange File Format) files store uncompressed signed audio samples inside an IFF container. There\'s also a compressed variant, called AIFC with the same mimetype.<br /><br />AIFF chunks provide native metadata. In addition, although it is not in the specification, an ID3 chunk is often used to include ID3V2 metadata.',
        types: ['.aiff', '.aif', '.aff'],
        alternatives: ['audio/x-aiff'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/AIFF',
            },
        ],
    },
    {
        name: 'audio/opus',
        description: 'Opus audio',
        types: ['.opus'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.karbon',
        description: '',
        types: ['.karbon'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kformula',
        description: '',
        types: ['.kfo'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kidspiration',
        description: '',
        types: ['.kia'],
        alternatives: [],
    },
    {
        name: 'application/x-killustrator',
        description: '',
        types: ['.kil'],
        alternatives: [],
    },
    {
        name: 'application/vnd.google-earth.kml+xml',
        description: '',
        types: ['.kml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.google-earth.kmz',
        description: '',
        types: ['.kmz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kinar',
        description: '',
        types: ['.kne', '.knp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kontour',
        description: '',
        types: ['.kon'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kpresenter',
        description: '',
        types: ['.kpr', '.kpt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kspread',
        description: '',
        types: ['.ksp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kahootz',
        description: '',
        types: ['.ktr', '.ktz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kde.kword',
        description: '',
        types: ['.kwd', '.kwt'],
        alternatives: [],
    },
    {
        name: 'application/x-latex',
        description: '',
        types: ['.latex'],
        alternatives: [],
    },
    {
        name: 'application/vnd.llamagraphics.life-balance.desktop',
        description: '',
        types: ['.lbd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.llamagraphics.life-balance.exchange+xml',
        description: '',
        types: ['.lbe'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hhe.lesson-player',
        description: '',
        types: ['.les'],
        alternatives: [],
    },
    {
        name: 'application/vnd.route66.link66+xml',
        description: '',
        types: ['.link66'],
        alternatives: [],
    },
    {
        name: 'application/lost+xml',
        description: '',
        types: ['.lostxml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-lrm',
        description: '',
        types: ['.lrm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.frogans.ltf',
        description: '',
        types: ['.ltf'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.lucent.voice',
        description: '',
        types: ['.lvp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-wordpro',
        description: '',
        types: ['.lwp'],
        alternatives: [],
    },
    {
        name: 'application/x-msmediaview',
        description: '',
        types: ['.m13', '.m14', '.mvb'],
        alternatives: [],
    },
    {
        name: 'video/mpeg',
        description: '',
        types: ['.m1v', '.m2v', '.mpa', '.mpe', '.mpeg', '.mpg'],
        alternatives: [],
    },
    {
        name: 'audio/mpeg',
        description:
            'MP3 is the name commonly given to the audio formats specified by MPEG-1 Layer III and MPEG-2 Layer III, standardized as ISO/IEC 11172-3:1993, with some additions in ISO/IEC 13818-3:1995. It uses lossy compressed data.<br /><br />MP3 is based in part on work by the Fraunhofer Institute, which held patents in the format. Other companies may also have held patents encumbering its implementation. One of the relevant patents was <a href="http://www.google.com/patents/US5812672" target="_blank">U.S. Patent 5,812,672</a>, which expired in September 2015; many other related patents in various countries expired earlier. The final expiration of all relevant patents took until 2017, however, due to some "submarine patents" which were kept in the application process for years, extending their expiration dates. A modified version of DCT compression (Discrete cosine transform) is used, a lossy compression method also used in <a href="/image/jpeg">JPEG images</a>.<br /><br />ID3 tags are often used to provide metadata in MP3 files, though they aren\'t part of the MP3 specification.',
        types: ['.m2a', '.m3a', '.mp2', '.mp2a', '.mp3', '.mpga'],
        alternatives: ['audio/mp3', 'audio/mpeg3', 'audio/x-mpeg-3'],
        furtherReading: [
            {
                title: 'IETF - Internet Engineering Task Force (RFC 3003)',
                url: 'https://tools.ietf.org/html/rfc3003',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/MP3',
            },
        ],
    },
    {
        name: 'audio/x-mpegurl',
        description: '',
        types: ['.m3u'],
        alternatives: [],
    },
    {
        name: 'video/vnd.mpegurl',
        description: '',
        types: ['.m4u', '.mxu'],
        alternatives: [],
    },
    {
        name: 'video/x-m4v',
        description: '',
        types: ['.m4v'],
        alternatives: [],
    },
    {
        name: 'application/mathematica',
        description: '',
        types: ['.ma', '.mb', '.nb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ecowin.chart',
        description: '',
        types: ['.mag'],
        alternatives: [],
    },
    {
        name: 'text/troff',
        description: '',
        types: ['.man', '.me', '.ms', '.roff', '.t', '.tr'],
        alternatives: [],
    },
    {
        name: 'application/mathml+xml',
        description: '',
        types: ['.mathml', '.mml'],
        alternatives: ['text/mathml'],
    },
    {
        name: 'text/mathml',
        description: '',
        types: ['.mathml', '.mml'],
        alternatives: ['application/mathml+xml'],
    },
    {
        name: 'application/vnd.sqlite3',
        description:
            'SQLite is a self-contained, serverless, zero-configuration, transactional SQL database engine. The code for SQLite is in the public domain and is thus free for use for any purpose, commercial or private.',
        types: [
            '.db',
            '.sqlite',
            '.sqlite3',
            '.db-wal',
            '.sqlite-wal',
            '.db-shm',
            '.sqlite-shm',
        ],
        alternatives: ['application/x-sqlite3'],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/vnd.sqlite3',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/SQLite',
            },
        ],
    },
    {
        name: 'application/x-sqlite3',
        description:
            'SQLite is a self-contained, serverless, zero-configuration, transactional SQL database engine. The code for SQLite is in the public domain and is thus free for use for any purpose, commercial or private.',
        types: [
            '.db',
            '.sqlite',
            '.sqlite3',
            '.db-wal',
            '.sqlite-wal',
            '.db-shm',
            '.sqlite-shm',
        ],
        alternatives: ['application/vnd.sqlite3'],
        deprecated: true,
        useInstead: 'application/vnd.sqlite3',
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/vnd.sqlite3',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/SQLite',
            },
        ],
    },
    {
        name: 'application/vnd.mobius.mbk',
        description: '',
        types: ['.mbk'],
        alternatives: [],
    },
    {
        name: 'application/mbox',
        description: '',
        types: ['.mbox'],
        alternatives: [],
    },
    {
        name: 'application/vnd.medcalcdata',
        description: '',
        types: ['.mc1'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mcd',
        description: '',
        types: ['.mcd'],
        alternatives: [],
    },
    {
        name: 'text/vnd.curl.mcurl',
        description: '',
        types: ['.mcurl'],
        alternatives: [],
    },
    {
        name: 'application/x-msaccess',
        description: '',
        types: ['.mdb'],
        alternatives: [],
    },
    {
        name: 'image/vnd.ms-modi',
        description: '',
        types: ['.mdi'],
        alternatives: [],
    },
    {
        name: 'model/mesh',
        description: '',
        types: ['.mesh', '.msh', '.silo'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mfmp',
        description: '',
        types: ['.mfm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.proteus.magazine',
        description: '',
        types: ['.mgz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mif',
        description: '',
        types: ['.mif'],
        alternatives: [],
    },
    {
        name: 'video/mj2',
        description: '',
        types: ['.mj2', '.mjp2'],
        alternatives: [],
    },
    {
        name: 'application/vnd.dolby.mlp',
        description: '',
        types: ['.mlp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.chipnuts.karaoke-mmd',
        description: '',
        types: ['.mmd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.smaf',
        description: '',
        types: ['.mmf'],
        alternatives: [],
    },
    {
        name: 'image/vnd.fujixerox.edmics-mmr',
        description: '',
        types: ['.mmr'],
        alternatives: [],
    },
    {
        name: 'application/x-msmoney',
        description: '',
        types: ['.mny'],
        alternatives: [],
    },
    {
        name: 'application/x-mobipocket-ebook',
        description: '',
        types: ['.mobi', '.prc'],
        alternatives: [],
    },
    {
        name: 'video/quicktime',
        description: '',
        types: ['.mov', '.qt'],
        alternatives: [],
    },
    {
        name: 'video/x-sgi-movie',
        description: '',
        types: ['.movie'],
        alternatives: [],
    },
    {
        name: 'video/mp4',
        description:
            'MP4 usually refers to the multimedia file format defined in Part 14 (and to a lesser extent Part 1) of the MPEG-4 standard. "MP4" could also mean the entire MPEG-4 standard, or some other subset of MPEG-4. MPEG-4 Part 14 is an application of Part 12. The recommended extension for it is ".mp4", though ".m4a" is often used for audio-only files. It is standardized by ISO/IEC 14496-14.',
        types: ['.mp4', '.mp4v', '.mpg4'],
        alternatives: [],
    },
    {
        name: 'audio/mp4',
        description:
            'MP4 usually refers to the multimedia file format defined in Part 14 (and to a lesser extent Part 1) of the MPEG-4 standard. "MP4" could also mean the entire MPEG-4 standard, or some other subset of MPEG-4. MPEG-4 Part 14 is an application of Part 12. The recommended extension for it is ".mp4", though ".m4a" is often used for audio-only files. It is standardized by ISO/IEC 14496-14.',
        types: ['.mp4a'],
        alternatives: [],
    },
    {
        name: 'application/yaml',
        description:
            'YAML (YAML Ain’t Markup Language) is a serialization format. It is a strict superset of JSON, with the addition of syntactically significant newlines and indentation, like Python. Unlike Python, however, YAML doesn’t allow literal tab characters for indentation. It is designed to be human-friendly and work well with modern programming languages for common everyday tasks. It is broadly useful for programming needs ranging from configuration files to Internet messaging to object persistence to data auditing. Together with the Unicode standard for characters, it is also well-suited for internationalization.',
        types: ['.yaml', '.yml'],
        alternatives: ['text/yaml', 'text/x-yaml', 'application/x-yaml'],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/yaml',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/YAML',
            },
        ],
    },
    {
        name: 'application/mp4',
        description: '',
        types: ['.mp4s'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mophun.certificate',
        description: '',
        types: ['.mpc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.apple.installer+xml',
        description: '',
        types: ['.mpkg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.blueice.multipass',
        description: '',
        types: ['.mpm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mophun.application',
        description: '',
        types: ['.mpn'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-project',
        description: '',
        types: ['.mpp', '.mpt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ibm.minipay',
        description: '',
        types: ['.mpy'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.mqy',
        description: '',
        types: ['.mqy'],
        alternatives: [],
    },
    {
        name: 'application/marc',
        description: '',
        types: ['.mrc'],
        alternatives: [],
    },
    {
        name: 'application/mediaservercontrol+xml',
        description: '',
        types: ['.mscml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fdsn.mseed',
        description: '',
        types: ['.mseed'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mseq',
        description: '',
        types: ['.mseq'],
        alternatives: [],
    },
    {
        name: 'application/vnd.epson.msf',
        description: '',
        types: ['.msf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.msl',
        description: '',
        types: ['.msl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.muvee.style',
        description: '',
        types: ['.msty'],
        alternatives: [],
    },
    {
        name: 'model/vnd.mts',
        description: '',
        types: ['.mts'],
        alternatives: [],
    },
    {
        name: 'application/vnd.musician',
        description: '',
        types: ['.mus'],
        alternatives: [],
    },
    {
        name: 'application/vnd.recordare.musicxml+xml',
        description: '',
        types: ['.musicxml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mfer',
        description: '',
        types: ['.mwf'],
        alternatives: [],
    },
    {
        name: 'application/mxf',
        description: '',
        types: ['.mxf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.recordare.musicxml',
        description: '',
        types: ['.mxl'],
        alternatives: [],
    },
    {
        name: 'application/xv+xml',
        description: '',
        types: ['.mxml', '.xhvml', '.xvm', '.xvml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.triscape.mxs',
        description: '',
        types: ['.mxs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.nokia.n-gage.symbian.install',
        description: '',
        types: ['.n-gage'],
        alternatives: [],
    },
    {
        name: 'application/x-dtbncx+xml',
        description: '',
        types: ['.ncx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.nokia.n-gage.data',
        description: '',
        types: ['.ngdat'],
        alternatives: [],
    },
    {
        name: 'application/vnd.neurolanguage.nlu',
        description: '',
        types: ['.nlu'],
        alternatives: [],
    },
    {
        name: 'application/vnd.enliven',
        description: '',
        types: ['.nml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.noblenet-directory',
        description: '',
        types: ['.nnd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.noblenet-sealer',
        description: '',
        types: ['.nns'],
        alternatives: [],
    },
    {
        name: 'application/vnd.noblenet-web',
        description: '',
        types: ['.nnw'],
        alternatives: [],
    },
    {
        name: 'image/vnd.net-fpx',
        description: '',
        types: ['.npx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-notes',
        description: '',
        types: ['.nsf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujitsu.oasys2',
        description: '',
        types: ['.oa2'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujitsu.oasys3',
        description: '',
        types: ['.oa3'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujitsu.oasys',
        description: '',
        types: ['.oas'],
        alternatives: [],
    },
    {
        name: 'application/x-msbinder',
        description: '',
        types: ['.obd'],
        alternatives: [],
    },
    {
        name: 'application/oda',
        description: '',
        types: ['.oda'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.database',
        description: '',
        types: ['.odb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.chart',
        description: '',
        types: ['.odc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.formula',
        description: '',
        types: ['.odf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.formula-template',
        description: '',
        types: ['.odft'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.graphics',
        description: '',
        types: ['.odg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.image',
        description: '',
        types: ['.odi'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.presentation',
        description: '',
        types: ['.odp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.spreadsheet',
        description: '',
        types: ['.ods'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.text',
        description: '',
        types: ['.odt'],
        alternatives: [],
    },
    {
        name: 'audio/ogg',
        description: '',
        types: ['.oga', '.ogg', '.spx'],
        alternatives: [],
    },
    {
        name: 'video/x-matroska',
        description:
            'Matroska (sometimes spelled Matroška) is an open standard multimedia container format. It is based on the EBML metaformat and can be used with a variety of compressed video formats.',
        types: ['.mkv'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/MKV',
            },
        ],
    },
    {
        name: 'audio/x-matroska',
        description:
            'Matroska Audio files consist of a Matroska container containing one or more audio files compressed with one of the supported codecs. As well as the compressed audio, a Matroska Audio file might also contain embedded lyrics or transcriptions, in the form of SRT subtitles. In addition, Matroska Audio files can be separated into tracks or chapters - it is possible, for example, to contain a whole album or audiobook in a single file while retaining clear separation between tracks.',
        types: ['.mka'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Matroska_Audio',
            },
        ],
    },
    {
        name: 'video/ogg',
        description: '',
        types: ['.ogv'],
        alternatives: [],
    },
    {
        name: 'application/ogg',
        description: '',
        types: ['.ogx'],
        alternatives: [],
    },
    {
        name: 'application/onenote',
        description: '',
        types: ['.onepkg', '.onetmp', '.onetoc', '.onetoc2'],
        alternatives: [],
    },
    {
        name: 'application/oebps-package+xml',
        description: '',
        types: ['.opf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.palm',
        description: '',
        types: ['.oprc', '.pdb', '.pqa'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-organizer',
        description: '',
        types: ['.org'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.openscoreformat',
        description: '',
        types: ['.osf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.openscoreformat.osfpvg+xml',
        description: '',
        types: ['.osfpvg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.chart-template',
        description: '',
        types: ['.otc'],
        alternatives: [],
    },
    {
        name: 'font/woff',
        description: 'Web Open Font Format (WOFF)',
        types: ['.woff'],
        alternatives: [],
    },
    {
        name: 'font/woff2',
        description: 'Web Open Font Format (WOFF)',
        types: ['.woff2'],
        alternatives: [],
    },
    {
        name: 'application/x-redhat-package-manager',
        description: '',
        types: ['.rpa'],
        alternatives: [],
    },
    {
        name: 'application/x-perl',
        description: '',
        types: ['.pm', '.pl'],
        alternatives: [],
    },
    {
        name: 'audio/webm',
        description: 'WEBM audio',
        types: ['.weba'],
        alternatives: [],
    },
    {
        name: 'video/webm',
        description:
            'WebM is a video and audio container format released by Google, used with content encoded under the VP8 or VP9 codecs for video, and Vorbis and Opus for audio. It is a variant of Matroska, based on EBML.',
        types: ['.webm'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Webm',
            },
        ],
    },
    {
        name: 'image/webp',
        description: 'WEBP image',
        types: ['.webp'],
        alternatives: [],
    },
    {
        name: 'application/x-font-otf',
        description: '',
        types: ['.otf'],
        alternatives: ['font/otf'],
    },
    {
        name: 'font/otf',
        description: '',
        types: ['.otf'],
        alternatives: ['application/x-font-otf'],
    },
    {
        name: 'application/vnd.oasis.opendocument.graphics-template',
        description: '',
        types: ['.otg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.text-web',
        description: '',
        types: ['.oth'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.image-template',
        description: '',
        types: ['.oti'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.text-master',
        description: '',
        types: ['.otm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.presentation-template',
        description: '',
        types: ['.otp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.spreadsheet-template',
        description: '',
        types: ['.ots'],
        alternatives: [],
    },
    {
        name: 'application/vnd.oasis.opendocument.text-template',
        description: '',
        types: ['.ott'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openofficeorg.extension',
        description: '',
        types: ['.oxt'],
        alternatives: [],
    },
    {
        name: 'text/x-pascal',
        description:
            'Pascal is an influential imperative and procedural programming language, designed in 1968–1969 and published in 1970. Borland\'s Turbo Pascal, and the later Borland Pascal, were popular in the 1980s and early 1990s on the PC/MS-DOS platform (CP/M versions were also released).',
        types: ['.p', '.pas', '.pp', '.inc'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Pascal',
            },
        ],
    },
    {
        name: 'application/pkcs10',
        description: '',
        types: ['.p10'],
        alternatives: [],
    },
    {
        name: 'application/x-pkcs12',
        description: '',
        types: ['.p12', '.pfx'],
        alternatives: [],
    },
    {
        name: 'application/x-pkcs7-certificates',
        description: '',
        types: ['.p7b', '.spc'],
        alternatives: [],
    },
    {
        name: 'application/pkcs7-mime',
        description: '',
        types: ['.p7c', '.p7m'],
        alternatives: [
            'application/x-pkcs7-certreqresp',
            'application/x-pkcs7-certificates',
        ],
        furtherReading: [
            {
                title: 'IETF - Internet Engineering Task Force (RFC 5273)',
                url: 'https://tools.ietf.org/html/rfc5273.html#page-3',
            },
            {
                title: 'PKI Tutorial',
                url: 'https://pki-tutorial.readthedocs.io/en/latest/mime.html',
            },
        ],
    },
    {
        name: 'application/x-pkcs7-certreqresp',
        description:
            'x-pkcs7-certreqresp and the .p7r extension were also introduced by Microsoft. Likely yet another alias for pkcs7-mime.',
        types: ['.p7r'],
        alternatives: ['application/pkcs7-mime'],
        deprecated: true,
        useInstead: 'application/pkcs7-mime',
        furtherReading: [
            {
                title: 'IETF - Internet Engineering Task Force (RFC 5273)',
                url: 'https://tools.ietf.org/html/rfc5273.html#page-3',
            },
            {
                title: 'PKI Tutorial',
                url: 'https://pki-tutorial.readthedocs.io/en/latest/mime.html',
            },
        ],
    },
    {
        name: 'application/pkcs7-signature',
        description: '',
        types: ['.p7s'],
        alternatives: [],
    },
    {
        name: 'application/vnd.powerbuilder6',
        description: '',
        types: ['.pbd'],
        alternatives: [],
    },
    {
        name: 'image/x-portable-bitmap',
        description: '',
        types: ['.pbm'],
        alternatives: [],
    },
    {
        name: 'application/x-font-pcf',
        description: '',
        types: ['.pcf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-pcl',
        description: '',
        types: ['.pcl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hp-pclxl',
        description: '',
        types: ['.pclxl'],
        alternatives: [],
    },
    {
        name: 'image/x-pict',
        description: '',
        types: ['.pct', '.pic'],
        alternatives: [],
    },
    {
        name: 'application/vnd.curl.pcurl',
        description: '',
        types: ['.pcurl'],
        alternatives: [],
    },
    {
        name: 'image/x-pcx',
        description: '',
        types: ['.pcx'],
        alternatives: [],
    },
    {
        name: 'application/pdf',
        description:
            'Portable Document Format (PDF) is a document file format originally from Adobe, based on PostScript. It has many subsets.',
        types: ['.pdf'],
        alternatives: ['application/x-pdf'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PDF',
            },
        ],
    },
    {
        name: 'application/font-tdpfr',
        description: '',
        types: ['.pfr'],
        alternatives: [],
    },
    {
        name: 'image/x-portable-graymap',
        description: '',
        types: ['.pgm'],
        alternatives: [],
    },
    {
        name: 'application/x-chess-pgn',
        description: '',
        types: ['.pgn'],
        alternatives: [],
    },
    {
        name: 'application/pgp-encrypted',
        description: '',
        types: ['.pgp'],
        alternatives: [],
    },
    {
        name: 'application/pkixcmp',
        description: '',
        types: ['.pki'],
        alternatives: [],
    },
    {
        name: 'application/pkix-pkipath',
        description: '',
        types: ['.pkipath'],
        alternatives: [],
    },
    {
        name: 'application/vnd.3gpp.pic-bw-large',
        description: '',
        types: ['.plb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.plc',
        description: '',
        types: ['.plc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.pocketlearn',
        description: '',
        types: ['.plf'],
        alternatives: [],
    },
    {
        name: 'application/pls+xml',
        description: '',
        types: ['.pls'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ctc-posml',
        description: '',
        types: ['.pml'],
        alternatives: [],
    },
    {
        name: 'image/png',
        description:
            'Portable Network Graphics (PNG) was devised starting in a discussion on newsgroup comp.graphics in 1995, with the first version of its specification released in 1996. The motivation for its creation was to create a free and unencumbered image format in the wake of the patent issue with GIF.',
        types: ['.png'],
        alternatives: ['image/x-png', 'image/vnd.mozilla.apng'],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/image/png',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PNG',
            },
        ],
    },
    {
        name: 'image/x-png',
        description:
            'Portable Network Graphics (PNG) was devised starting in a discussion on newsgroup comp.graphics in 1995, with the first version of its specification released in 1996. The motivation for its creation was to create a free and unencumbered image format in the wake of the patent issue with GIF.',
        types: ['.png'],
        alternatives: ['image/png', 'image/vnd.mozilla.apng'],
        deprecated: true,
        useInstead: 'image/png',
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/image/png',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PNG',
            },
        ],
    },
    {
        name: 'image/vnd.mozilla.apng',
        description:
            'Portable Network Graphics (PNG) was devised starting in a discussion on newsgroup comp.graphics in 1995, with the first version of its specification released in 1996. The motivation for its creation was to create a free and unencumbered image format in the wake of the patent issue with GIF.',
        types: ['.png'],
        alternatives: ['image/png', 'image/x-png'],
        deprecated: true,
        useInstead: 'image/png',
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/image/png',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PNG',
            },
        ],
    },
    {
        name: 'image/x-portable-anymap',
        description: '',
        types: ['.pnm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.macports.portpkg',
        description: '',
        types: ['.portpkg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint',
        description: '',
        types: ['.pot', '.ppa', '.pps', '.ppt', '.pwz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint.template.macroenabled.12',
        description: '',
        types: ['.potm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.presentationml.template',
        description: '',
        types: ['.potx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint.addin.macroenabled.12',
        description: '',
        types: ['.ppam'],
        alternatives: [],
    },
    {
        name: 'application/vnd.cups-ppd',
        description: '',
        types: ['.ppd'],
        alternatives: [],
    },
    {
        name: 'image/x-portable-pixmap',
        description: '',
        types: ['.ppm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
        description: '',
        types: ['.ppsm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        description: '',
        types: ['.ppsx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
        description: '',
        types: ['.pptm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        description: '',
        types: ['.pptx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-freelance',
        description: '',
        types: ['.pre'],
        alternatives: [],
    },
    {
        name: 'application/pics-rules',
        description: '',
        types: ['.prf'],
        alternatives: [],
    },
    {
        name: 'application/prql',
        description:
            'PRQL is a modern language for transforming data — a simple, powerful, pipelined SQL replacement.',
        types: ['.prql'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/PRQL',
            },
        ],
    },
    {
        name: 'application/vnd.3gpp.pic-bw-small',
        description: '',
        types: ['.psb'],
        alternatives: [],
    },
    {
        name: 'image/vnd.adobe.photoshop',
        description: '',
        types: ['.psd'],
        alternatives: [],
    },
    {
        name: 'application/x-font-linux-psf',
        description: '',
        types: ['.psf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.pvi.ptid1',
        description: '',
        types: ['.ptid'],
        alternatives: [],
    },
    {
        name: 'application/x-mspublisher',
        description: '',
        types: ['.pub'],
        alternatives: [],
    },
    {
        name: 'application/vnd.3gpp.pic-bw-var',
        description: '',
        types: ['.pvb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.3m.post-it-notes',
        description: '',
        types: ['.pwn'],
        alternatives: [],
    },
    {
        name: 'text/x-python',
        description: '',
        types: ['.py'],
        alternatives: [],
    },
    {
        name: 'audio/vnd.ms-playready.media.pya',
        description: '',
        types: ['.pya'],
        alternatives: [],
    },
    {
        name: 'application/x-python-code',
        description: '',
        types: ['.pyc', '.pyo'],
        alternatives: [],
    },
    {
        name: 'video/vnd.ms-playready.media.pyv',
        description: '',
        types: ['.pyv'],
        alternatives: [],
    },
    {
        name: 'application/vnd.epson.quickanime',
        description: '',
        types: ['.qam'],
        alternatives: [],
    },
    {
        name: 'application/vnd.intu.qbo',
        description: '',
        types: ['.qbo'],
        alternatives: [],
    },
    {
        name: 'application/vnd.intu.qfx',
        description: '',
        types: ['.qfx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.publishare-delta-tree',
        description: '',
        types: ['.qps'],
        alternatives: [],
    },
    {
        name: 'application/vnd.quark.quarkxpress',
        description: '',
        types: ['.qwd', '.qwt', '.qxb', '.qxd', '.qxl', '.qxt'],
        alternatives: [],
    },
    {
        name: 'audio/x-pn-realaudio',
        description: '',
        types: ['.ra', '.ram'],
        alternatives: [],
    },
    {
        name: 'application/vnd.rar',
        description:
            '<strong>R</strong>oshal <strong>AR</strong>chive (RAR) is a proprietary file format used by the compression software WinRAR. They make the decompression code available for use in other programs and allow its distribution, but with a license provision that "You cannot use the unrar source to re-create the RAR compression algorithm, which is proprietary.',
        types: ['.rar'],
        alternatives: ['application/x-rar-compressed'],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/vnd.rar',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/RAR',
            },
        ],
    },
    {
        name: 'application/x-rar-compressed',
        description:
            '<strong>R</strong>oshal <strong>AR</strong>chive (RAR) is a proprietary file format used by the compression software WinRAR. They make the decompression code available for use in other programs and allow its distribution, but with a license provision that "You cannot use the unrar source to re-create the RAR compression algorithm, which is proprietary.',
        types: ['.rar'],
        deprecated: true,
        useInstead: 'application/vnd.rar',
        alternatives: ['application/vnd.rar'],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/vnd.rar',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/RAR',
            },
        ],
    },
    {
        name: 'image/x-cmu-raster',
        description: '',
        types: ['.ras'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ipunplugged.rcprofile',
        description: '',
        types: ['.rcprofile'],
        alternatives: [],
    },
    {
        name: 'application/rdf+xml',
        description: '',
        types: ['.rdf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.data-vision.rdz',
        description: '',
        types: ['.rdz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.businessobjects',
        description: '',
        types: ['.rep'],
        alternatives: [],
    },
    {
        name: 'application/x-dtbresource+xml',
        description: '',
        types: ['.res'],
        alternatives: [],
    },
    {
        name: 'image/x-rgb',
        description: '',
        types: ['.rgb'],
        alternatives: [],
    },
    {
        name: 'application/reginfo+xml',
        description: '',
        types: ['.rif'],
        alternatives: [],
    },
    {
        name: 'application/resource-lists+xml',
        description: '',
        types: ['.rl'],
        alternatives: [],
    },
    {
        name: 'image/vnd.fujixerox.edmics-rlc',
        description: '',
        types: ['.rlc'],
        alternatives: [],
    },
    {
        name: 'application/resource-lists-diff+xml',
        description: '',
        types: ['.rld'],
        alternatives: [],
    },
    {
        name: 'application/vnd.rn-realmedia',
        description: '',
        types: ['.rm'],
        alternatives: [],
    },
    {
        name: 'audio/x-pn-realaudio-plugin',
        description: '',
        types: ['.rmp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.jcp.javame.midlet-rms',
        description: '',
        types: ['.rms'],
        alternatives: [],
    },
    {
        name: 'application/relax-ng-compact-syntax',
        description: '',
        types: ['.rnc'],
        alternatives: [],
    },
    {
        name: 'application/x-rpm',
        description: '',
        types: ['.rpm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.nokia.radio-presets',
        description: '',
        types: ['.rpss'],
        alternatives: [],
    },
    {
        name: 'application/vnd.nokia.radio-preset',
        description: '',
        types: ['.rpst'],
        alternatives: [],
    },
    {
        name: 'application/sparql-query',
        description: '',
        types: ['.rq'],
        alternatives: [],
    },
    {
        name: 'application/rls-services+xml',
        description: '',
        types: ['.rs'],
        alternatives: [],
    },
    {
        name: 'application/rsd+xml',
        description: '',
        types: ['.rsd'],
        alternatives: [],
    },
    {
        name: 'application/rss+xml',
        description:
            'The MIME used for RSS feeds (RSS 0.9x, 2.0, 3.0 and others). Many feeds end up being served as plain text/html however and _most_ parsers seem to be able to handle this discrepancy.    RSS is an XML-based document format for the syndication of web\n   content so that it can be republished on other sites or downloaded\n   periodically and presented to users. RSS is currently used for a number of applications, including news headline syndication, weblog content distribution, and the exchange of other timely information such as software release notes.',
        types: ['.rss', '.xml'],
        alternatives: [],
        furtherReading: [
            {
                title: 'RSS Board Media Type Application',
                url: 'https://www.rssboard.org/rss-mime-type-application.txt',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/RSS',
            },
        ],
    },
    {
        name: 'application/rtf',
        description: '',
        types: ['.rtf'],
        alternatives: [],
    },
    {
        name: 'text/richtext',
        description: '',
        types: ['.rtx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.smaf-audio',
        description: '',
        types: ['.saf'],
        alternatives: [],
    },
    {
        name: 'application/sbml+xml',
        description: '',
        types: ['.sbml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ibm.secure-container',
        description: '',
        types: ['.sc'],
        alternatives: [],
    },
    {
        name: 'application/x-msschedule',
        description: '',
        types: ['.scd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.lotus-screencam',
        description: '',
        types: ['.scm'],
        alternatives: [],
    },
    {
        name: 'application/scvp-cv-request',
        description: '',
        types: ['.scq'],
        alternatives: [],
    },
    {
        name: 'application/scvp-cv-response',
        description: '',
        types: ['.scs'],
        alternatives: [],
    },
    {
        name: 'text/vnd.curl.scurl',
        description: '',
        types: ['.scurl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.draw',
        description: '',
        types: ['.sda'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.calc',
        description: '',
        types: ['.sdc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.impress',
        description: '',
        types: ['.sdd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.solent.sdkm+xml',
        description: '',
        types: ['.sdkd', '.sdkm'],
        alternatives: [],
    },
    {
        name: 'application/sdp',
        description: '',
        types: ['.sdp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.writer',
        description: '',
        types: ['.sdw', '.vor'],
        alternatives: [],
    },
    {
        name: 'application/vnd.seemail',
        description: '',
        types: ['.see'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sema',
        description: '',
        types: ['.sema'],
        alternatives: [],
    },
    {
        name: 'application/vnd.semd',
        description: '',
        types: ['.semd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.semf',
        description: '',
        types: ['.semf'],
        alternatives: [],
    },
    {
        name: 'application/java-serialized-object',
        description: '',
        types: ['.ser'],
        alternatives: [],
    },
    {
        name: 'application/set-payment-initiation',
        description: '',
        types: ['.setpay'],
        alternatives: [],
    },
    {
        name: 'application/set-registration-initiation',
        description: '',
        types: ['.setreg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hydrostatix.sof-data',
        description: '',
        types: ['.sfd-hdstx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.spotfire.sfs',
        description: '',
        types: ['.sfs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.writer-global',
        description: '',
        types: ['.sgl'],
        alternatives: [],
    },
    {
        name: 'text/sgml',
        description: '',
        types: ['.sgm', '.sgml'],
        alternatives: [],
    },
    {
        name: 'application/x-sh',
        description: '',
        types: ['.sh'],
        alternatives: [],
    },
    {
        name: 'application/x-shar',
        description: '',
        types: ['.shar'],
        alternatives: [],
    },
    {
        name: 'application/shf+xml',
        description: '',
        types: ['.shf'],
        alternatives: [],
    },
    {
        name: 'text/vnd.wap.si',
        description: '',
        types: ['.si'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wap.sic',
        description: '',
        types: ['.sic'],
        alternatives: [],
    },
    {
        name: 'application/vnd.symbian.install',
        description: '',
        types: ['.sis', '.sisx'],
        alternatives: [],
    },
    {
        name: 'application/x-stuffit',
        description: '',
        types: ['.sit'],
        alternatives: [],
    },
    {
        name: 'application/x-stuffitx',
        description: '',
        types: ['.sitx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.koan',
        description: '',
        types: ['.skd', '.skm', '.skp', '.skt'],
        alternatives: [],
    },
    {
        name: 'text/vnd.wap.sl',
        description: '',
        types: ['.sl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wap.slc',
        description: '',
        types: ['.slc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-powerpoint.slide.macroenabled.12',
        description: '',
        types: ['.sldm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.presentationml.slide',
        description: '',
        types: ['.sldx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.epson.salt',
        description: '',
        types: ['.slt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.stardivision.math',
        description: '',
        types: ['.smf'],
        alternatives: [],
    },
    {
        name: 'application/smil+xml',
        description: '',
        types: ['.smi', '.smil'],
        alternatives: [],
    },
    {
        name: 'application/x-font-snf',
        description: '',
        types: ['.snf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.yamaha.smaf-phrase',
        description: '',
        types: ['.spf'],
        alternatives: [],
    },
    {
        name: 'application/x-futuresplash',
        description: '',
        types: ['.spl'],
        alternatives: [],
    },
    {
        name: 'text/vnd.in3d.spot',
        description: '',
        types: ['.spot'],
        alternatives: [],
    },
    {
        name: 'application/scvp-vp-response',
        description: '',
        types: ['.spp'],
        alternatives: [],
    },
    {
        name: 'application/scvp-vp-request',
        description: '',
        types: ['.spq'],
        alternatives: [],
    },
    {
        name: 'application/x-wais-source',
        description: '',
        types: ['.src'],
        alternatives: [],
    },
    {
        name: 'application/sparql-results+xml',
        description: '',
        types: ['.srx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.kodak-descriptor',
        description: '',
        types: ['.sse'],
        alternatives: [],
    },
    {
        name: 'application/vnd.epson.ssf',
        description: '',
        types: ['.ssf'],
        alternatives: [],
    },
    {
        name: 'application/ssml+xml',
        description: '',
        types: ['.ssml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.calc.template',
        description: '',
        types: ['.stc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.draw.template',
        description: '',
        types: ['.std'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wt.stf',
        description: '',
        types: ['.stf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.impress.template',
        description: '',
        types: ['.sti'],
        alternatives: [],
    },
    {
        name: 'application/hyperstudio',
        description: '',
        types: ['.stk'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-pki.stl',
        description: '',
        types: ['.stl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.pg.format',
        description: '',
        types: ['.str'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.writer.template',
        description: '',
        types: ['.stw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sus-calendar',
        description: '',
        types: ['.sus', '.susp'],
        alternatives: [],
    },
    {
        name: 'application/x-sv4cpio',
        description: '',
        types: ['.sv4cpio'],
        alternatives: [],
    },
    {
        name: 'application/x-sv4crc',
        description: '',
        types: ['.sv4crc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.svd',
        description: '',
        types: ['.svd'],
        alternatives: [],
    },
    {
        name: 'image/svg+xml',
        description: '',
        types: ['.svg', '.svgz'],
        alternatives: [],
    },
    {
        name: 'application/x-shockwave-flash',
        description: '',
        types: ['.swf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.arastra.swi',
        description: '',
        types: ['.swi'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.calc',
        description: '',
        types: ['.sxc'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.draw',
        description: '',
        types: ['.sxd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.writer.global',
        description: '',
        types: ['.sxg'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.impress',
        description: '',
        types: ['.sxi'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.math',
        description: '',
        types: ['.sxm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.sun.xml.writer',
        description: '',
        types: ['.sxw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.tao.intent-module-archive',
        description: '',
        types: ['.tao'],
        alternatives: [],
    },
    {
        name: 'application/x-tar',
        description: '',
        types: ['.tar'],
        alternatives: [],
    },
    {
        name: 'application/vnd.3gpp2.tcap',
        description: '',
        types: ['.tcap'],
        alternatives: [],
    },
    {
        name: 'application/x-tcl',
        description: '',
        types: ['.tcl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.smart.teacher',
        description: '',
        types: ['.teacher'],
        alternatives: [],
    },
    {
        name: 'application/x-tex',
        description: '',
        types: ['.tex'],
        alternatives: [],
    },
    {
        name: 'application/x-texinfo',
        description: '',
        types: ['.texi', '.texinfo'],
        alternatives: [],
    },
    {
        name: 'application/x-tex-tfm',
        description: '',
        types: ['.tfm'],
        alternatives: [],
    },
    {
        name: 'image/tiff',
        description:
            'TIFF, formerly known as Tag(ged) Image File Format, is an image format capable of storing multiple high quality images in a single file. <br /><br />A TIFF image may be uncompressed or use a compression scheme internally. Two of the most widely used compression schemes in TIFF files are lossless, including LZW and, for bitonal images CCITT Group 4, as used for facsimile transmission [fax]. JPEG baseline DCT-based lossy compression is also used.',
        types: ['.tif', '.tiff'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/TIFF',
            },
        ],
    },
    {
        name: 'application/vnd.tmobile-livetv',
        description: '',
        types: ['.tmo'],
        alternatives: [],
    },
    {
        name: 'application/x-bittorrent',
        description: '',
        types: ['.torrent'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-tool-template',
        description: '',
        types: ['.tpl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.trid.tpt',
        description: '',
        types: ['.tpt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.trueapp',
        description: '',
        types: ['.tra'],
        alternatives: [],
    },
    {
        name: 'application/x-msterminal',
        description: '',
        types: ['.trm'],
        alternatives: [],
    },
    {
        name: 'text/tab-separated-values',
        description: '',
        types: ['.tsv'],
        alternatives: [],
        furtherReading: [
            {
                title: 'IANA (Internet Assigned Numbers Authority)',
                url: 'https://www.iana.org/assignments/media-types/text/tab-separated-values',
            },
        ],
    },
    {
        name: 'application/x-font-ttf',
        description: '',
        types: ['.ttc', '.ttf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.simtech-mindmapper',
        description: '',
        types: ['.twd', '.twds'],
        alternatives: [],
    },
    {
        name: 'application/vnd.genomatix.tuxedo',
        description: '',
        types: ['.txd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mobius.txf',
        description: '',
        types: ['.txf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ufdl',
        description: '',
        types: ['.ufd', '.ufdl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.umajin',
        description: '',
        types: ['.umj'],
        alternatives: [],
    },
    {
        name: 'application/vnd.unity',
        description: '',
        types: ['.unityweb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.uoml+xml',
        description: '',
        types: ['.uoml'],
        alternatives: [],
    },
    {
        name: 'text/uri-list',
        description: '',
        types: ['.uri', '.uris', '.urls'],
        alternatives: [],
    },
    {
        name: 'application/x-ustar',
        description: '',
        types: ['.ustar'],
        alternatives: [],
    },
    {
        name: 'application/vnd.uiq.theme',
        description: '',
        types: ['.utz'],
        alternatives: [],
    },
    {
        name: 'text/x-uuencode',
        description: '',
        types: ['.uu'],
        alternatives: [],
    },
    {
        name: 'application/x-cdlink',
        description: '',
        types: ['.vcd'],
        alternatives: [],
    },
    {
        name: 'text/x-vcard',
        description: '',
        types: ['.vcf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.groove-vcard',
        description: '',
        types: ['.vcg'],
        alternatives: [],
    },
    {
        name: 'text/x-vcalendar',
        description: '',
        types: ['.vcs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.vcx',
        description: '',
        types: ['.vcx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.visionary',
        description: '',
        types: ['.vis'],
        alternatives: [],
    },
    {
        name: 'video/vnd.vivo',
        description: '',
        types: ['.viv'],
        alternatives: [],
    },
    {
        name: 'model/vrml',
        description: '',
        types: ['.vrml', '.wrl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.visio',
        description: '',
        types: [
            '.vsd',
            '.vss',
            '.vst',
            '.vsw',
            '.vsdx',
            '.vssx',
            '.vstx',
            '.vssm',
            '.vstm',
        ],
        alternatives: [],
        furtherReading: [
            {
                url: 'http://msdn.microsoft.com/en-us/library/office/ff768724.aspx',
                title: 'Microsoft\'s documentation of the Visio file format',
            },
            {
                url: 'https://learn.microsoft.com/en-us/office/client-developer/visio/introduction-to-the-visio-file-formatvsdx',
                title: 'Introduction to the Visio file format (.vsdx)',
            },
        ],
    },
    {
        name: 'application/vnd.vsf',
        description: '',
        types: ['.vsf'],
        alternatives: [],
    },
    {
        name: 'model/vnd.vtu',
        description: '',
        types: ['.vtu'],
        alternatives: [],
    },
    {
        name: 'application/voicexml+xml',
        description: '',
        types: ['.vxml'],
        alternatives: [],
    },
    {
        name: 'application/x-doom',
        description: '',
        types: ['.wad'],
        alternatives: [],
    },
    {
        name: 'video/mp2t',
        description:
            'MPEG Transport Stream (or MPEG-2 Transport Stream) is an MPEG multimedia format that allows interleaving more than one Packetized Elementary Stream in a single file. It is an alternative to MPEG Program Stream, designed to work better with certain kinds of streaming. It was introduced in the MPEG-2 standard. There is a variant format named M2TS, used with Blu-ray discs.<br /><br />Alternatively, you got here like I did by selecting a typescript file. That\'s wrong, that\'s your browser doing that. There\'s no official standard for typescript.',
        types: ['.ts'],
        alternatives: [],
        furtherReading: [
            {
                url: 'http://fileformats.archiveteam.org/wiki/MPEG_Transport_Stream',
                title: 'Let\'s Solve the File Format Problem!',
            },
            {
                url: 'https://en.wikipedia.org/wiki/MPEG_transport_stream',
                title: 'Wikipedia article on MPEG Transport Stream',
            },
        ],
    },
    {
        name: 'audio/wav',
        description:
            'The Waveform Audio File Format (WAV or WAVE) is a widely used audio format, originally developed by Microsoft and IBM and based on the RIFF wrapper format. The usual audio encoding in a .wav file is LPCM, considered an \'uncompressed\' encoding. Because of large file sizes, WAV is not well-suited for distributing audio such as songs or podcasts. WAV is used in MS-Windows to store sounds used in applications. It is also used as an archival format for first-generation (master) files, often with a metadata chunk as specified in the Broadcast Wave (BWF) standard.',
        types: ['.wav'],
        alternatives: [
            'audio/x-wav',
            'audio/vnd.wave',
            'audio/wave',
            'audio/x-pn-wav',
        ],
        furtherReading: [
            {
                url: 'http://fileformats.archiveteam.org/wiki/WAV',
                title: 'Let\'s Solve the File Format Problem!',
            },
        ],
    },
    {
        name: 'audio/x-ms-wax',
        description: '',
        types: ['.wax'],
        alternatives: [],
    },
    {
        name: 'image/vnd.wap.wbmp',
        description: '',
        types: ['.wbmp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.criticaltools.wbs+xml',
        description: '',
        types: ['.wbs'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wap.wbxml',
        description: '',
        types: ['.wbxml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-works',
        description: '',
        types: ['.wcm', '.wdb', '.wks', '.wps'],
        alternatives: [],
    },
    {
        name: 'video/x-ms-wm',
        description: '',
        types: ['.wm'],
        alternatives: [],
    },
    {
        name: 'audio/x-ms-wma',
        description: '',
        types: ['.wma'],
        alternatives: [],
    },
    {
        name: 'application/x-ms-wmd',
        description: '',
        types: ['.wmd'],
        alternatives: [],
    },
    {
        name: 'application/x-msmetafile',
        description: '',
        types: ['.wmf'],
        alternatives: [],
    },
    {
        name: 'text/vnd.wap.wml',
        description: '',
        types: ['.wml'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wap.wmlc',
        description: '',
        types: ['.wmlc'],
        alternatives: [],
    },
    {
        name: 'text/vnd.wap.wmlscript',
        description: '',
        types: ['.wmls'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wap.wmlscriptc',
        description: '',
        types: ['.wmlsc'],
        alternatives: [],
    },
    {
        name: 'video/x-ms-wmv',
        description: '',
        types: ['.wmv'],
        alternatives: [],
    },
    {
        name: 'video/x-ms-wmx',
        description: '',
        types: ['.wmx'],
        alternatives: [],
    },
    {
        name: 'application/x-ms-wmz',
        description: '',
        types: ['.wmz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wordperfect',
        description: '',
        types: ['.wpd'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-wpl',
        description: '',
        types: ['.wpl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.wqd',
        description: '',
        types: ['.wqd'],
        alternatives: [],
    },
    {
        name: 'application/x-mswrite',
        description: '',
        types: ['.wri'],
        alternatives: [],
    },
    {
        name: 'application/wsdl+xml',
        description: '',
        types: ['.wsdl'],
        alternatives: [],
    },
    {
        name: 'application/wspolicy+xml',
        description: '',
        types: ['.wspolicy'],
        alternatives: [],
    },
    {
        name: 'application/vnd.webturbo',
        description: '',
        types: ['.wtb'],
        alternatives: [],
    },
    {
        name: 'video/x-ms-wvx',
        description: '',
        types: ['.wvx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.hzn-3d-crossword',
        description: '',
        types: ['.x3d'],
        alternatives: [],
    },
    {
        name: 'application/x-silverlight-app',
        description: '',
        types: ['.xap'],
        alternatives: [],
    },
    {
        name: 'application/vnd.xara',
        description: '',
        types: ['.xar'],
        alternatives: [],
    },
    {
        name: 'application/x-ms-xbap',
        description: '',
        types: ['.xbap'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujixerox.docuworks.binder',
        description: '',
        types: ['.xbd'],
        alternatives: [],
    },
    {
        name: 'image/x-xbitmap',
        description: '',
        types: ['.xbm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.syncml.dm+xml',
        description: '',
        types: ['.xdm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.adobe.xdp+xml',
        description: '',
        types: ['.xdp'],
        alternatives: [],
    },
    {
        name: 'application/vnd.fujixerox.docuworks',
        description: '',
        types: ['.xdw'],
        alternatives: [],
    },
    {
        name: 'application/xenc+xml',
        description: '',
        types: ['.xenc'],
        alternatives: [],
    },
    {
        name: 'application/patch-ops-error+xml',
        description: '',
        types: ['.xer'],
        alternatives: [],
    },
    {
        name: 'application/vnd.adobe.xfdf',
        description: '',
        types: ['.xfdf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.xfdl',
        description: '',
        types: ['.xfdl'],
        alternatives: [],
    },
    {
        name: 'application/xhtml+xml',
        description: '',
        types: ['.xht', '.xhtml'],
        alternatives: [],
    },
    {
        name: 'image/vnd.xiff',
        description: '',
        types: ['.xif'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-excel',
        description: '',
        types: ['.xla', '.xlb', '.xlc', '.xlm', '.xls', '.xlt', '.xlw'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-excel.addin.macroenabled.12',
        description: '',
        types: ['.xlam'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-excel.sheet.binary.macroenabled.12',
        description: '',
        types: ['.xlsb'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-excel.sheet.macroenabled.12',
        description: '',
        types: ['.xlsm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        description: '',
        types: ['.xlsx'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-excel.template.macroenabled.12',
        description: '',
        types: ['.xltm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        description: '',
        types: ['.xltx'],
        alternatives: [],
    },
    {
        name: 'application/xml',
        description: '',
        types: ['.xml', '.xpdl', '.xsl'],
        alternatives: [],
    },
    {
        name: 'application/vnd.olpc-sugar',
        description: '',
        types: ['.xo'],
        alternatives: [],
    },
    {
        name: 'application/xop+xml',
        description: '',
        types: ['.xop'],
        alternatives: [],
    },
    {
        name: 'application/x-xpinstall',
        description: '',
        types: ['.xpi'],
        alternatives: [],
    },
    {
        name: 'image/x-xpixmap',
        description: '',
        types: ['.xpm'],
        alternatives: [],
    },
    {
        name: 'application/vnd.is-xpr',
        description: '',
        types: ['.xpr'],
        alternatives: [],
    },
    {
        name: 'application/vnd.ms-xpsdocument',
        description: '',
        types: ['.xps'],
        alternatives: [],
    },
    {
        name: 'application/vnd.intercon.formnet',
        description: '',
        types: ['.xpw', '.xpx'],
        alternatives: [],
    },
    {
        name: 'application/xslt+xml',
        description: '',
        types: ['.xslt'],
        alternatives: [],
    },
    {
        name: 'application/vnd.syncml+xml',
        description: '',
        types: ['.xsm'],
        alternatives: [],
    },
    {
        name: 'application/xspf+xml',
        description: '',
        types: ['.xspf'],
        alternatives: [],
    },
    {
        name: 'application/vnd.mozilla.xul+xml',
        description: '',
        types: ['.xul'],
        alternatives: [],
    },
    {
        name: 'image/x-xwindowdump',
        description: '',
        types: ['.xwd'],
        alternatives: [],
    },
    {
        name: 'chemical/x-xyz',
        description: '',
        types: ['.xyz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.zzazz.deck+xml',
        description: '',
        types: ['.zaz'],
        alternatives: [],
    },
    {
        name: 'application/zip',
        description:
            'ZIP is one of the most popular file compression formats. It was created in 1989 as the native format of the PKZIP program, which was introduced by Phil Katz in the wake of a lawsuit (which he lost) against him by the makers of the then-popular ARC program (and file format) for copyright and trademark infringement in an earlier program PKARC which had been file-compatible with ARC. <br /><br />This resulted in Katz creating a new file format, which rapidly overtook ARC in popularity (to a large extent because of BBS sysops, then the primary users of such compression, resenting the lawsuit). Many programs have been released for a variety of operating systems to compress and decompress ZIP files, and native support for the format is built into several popular operating systems.',
        types: ['.zip'],
        alternatives: [
            'application/x-zip-compressed',
            'application/zip-compressed',
        ],
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/zip',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/ZIP',
            },
        ],
    },
    {
        name: 'application/x-zip-compressed',
        description:
            'ZIP is one of the most popular file compression formats. It was created in 1989 as the native format of the PKZIP program, which was introduced by Phil Katz in the wake of a lawsuit (which he lost) against him by the makers of the then-popular ARC program (and file format) for copyright and trademark infringement in an earlier program PKARC which had been file-compatible with ARC. <br /><br />This resulted in Katz creating a new file format, which rapidly overtook ARC in popularity (to a large extent because of BBS sysops, then the primary users of such compression, resenting the lawsuit). Many programs have been released for a variety of operating systems to compress and decompress ZIP files, and native support for the format is built into several popular operating systems.',
        types: ['.zip'],
        alternatives: ['application/zip', 'application/zip-compressed'],
        deprecated: true,
        useInstead: 'application/zip',
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/zip',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/ZIP',
            },
        ],
    },
    {
        name: 'application/zip-compressed',
        description:
            'ZIP is one of the most popular file compression formats. It was created in 1989 as the native format of the PKZIP program, which was introduced by Phil Katz in the wake of a lawsuit (which he lost) against him by the makers of the then-popular ARC program (and file format) for copyright and trademark infringement in an earlier program PKARC which had been file-compatible with ARC. <br /><br />This resulted in Katz creating a new file format, which rapidly overtook ARC in popularity (to a large extent because of BBS sysops, then the primary users of such compression, resenting the lawsuit). Many programs have been released for a variety of operating systems to compress and decompress ZIP files, and native support for the format is built into several popular operating systems.',
        types: ['.zip'],
        alternatives: ['application/zip', 'application/x-zip-compressed'],
        deprecated: true,
        useInstead: 'application/zip',
        furtherReading: [
            {
                title: 'Internet Assigned Numbers Authority (IANA)',
                url: 'https://www.iana.org/assignments/media-types/application/zip',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/ZIP',
            },
        ],
    },
    {
        name: 'application/vnd.zul',
        description: '',
        types: ['.zir', '.zirz'],
        alternatives: [],
    },
    {
        name: 'application/vnd.handheld-entertainment+xml',
        description: '',
        types: ['.zmm'],
        alternatives: [],
    },
    {
        name: 'image/x-adobe-dng',
        description:
            'DNG (Digital Negative) is a digital camera raw image format developed by Adobe and released in 2004. Adobe also released a free DNG converter at this time which is continually being updated with compatible RAW file types. DNG is an extension of TIFF 6.0 and is compatible with TIFF/EP.<br /><br />Adobe intends for DNG to become an archival file type, creating an open standard for RAW files.',
        types: ['.dng'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/DNG',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-sony-arw',
        description:
            'Sony ARW is a raw image format used by some Sony digital cameras. It is based on TIFF.',
        types: ['.arw'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Sony_ARW',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-canon-cr2',
        description:
            'Canon RAW 2 (CR2) is a raw image format used by Canon cameras. It replaced CRW, starting with the 20D, 350D/Digital Rebel Xt and 1D. It was replaced by Canon RAW 3.',
        types: ['.cr2'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/CR2',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-canon-crw',
        description:
            'The Camera Image File Format, sometimes called the Canon RAW (CRW) format, was used by some early Canon digital cameras. It was replaced by the CR2 format starting with the EOS 20D in 2004. The .crw extension is sometimes used by CHDK, but this is a different file format.',
        types: ['.crw'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Camera_Image_File_Format',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-kodak-dcr',
        description:
            'This is another proprietary format, used by old Kodak DCS digital cameras. It is based on TIFF. The full-resolution image is in one of the sub-IFDs of the first IFD. A possible way to identify it is that it uses Compression code 65000 in an IFD or sub-IFD.',
        types: ['.dcr'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Kodak',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-epson-erf',
        description:
            'ERF files are generated by the Epson R-D1 and R-D1s digital rangefinder cameras.',
        types: ['.erf'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Epson_ERF',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-kodak-k25',
        description:
            'K25 format was used by model DC25. High resolution pictures are 140,352 bytes long and Standard resolution is 77,888.',
        types: ['.k25'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Kodak',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-kodak-kdc',
        description:
            'Kodak KDC mime and extension can be present for two formats with little in common. See further reading for more information.',
        types: ['.kdc'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Kodak',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-minolta-mrw',
        description:
            'MRW (Minolta RAW) is a raw image format used by Minolta (later Konica-Minolta) cameras. After the Sony purchase, Sony branded SLR use a different format: ARW.',
        types: ['.mrw'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Minolta_MRW',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-nikon-nef',
        description:
            'Nikon Electronic Format is Nikon\'s RAW digital image format for DSLR cameras. It is a close relative to TIFF, and has a standard TIFF header. NEF files are usually big-endian, with the exception of files from the Coolpix 5700, which were little-endian. Data can be either 12- or 14-bit (from 2005\'s D200 onwards), and depending on the camera may be uncompressed, losslessly compressed, or lossily compressed.',
        types: ['.nef'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Nikon',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-olympus-orf',
        description:
            'ORF, or Olympus RAW, is a raw image format produced by Olympus digital cameras. The format is TIFF-like, but with a different file signature. The full-size image is in the first IFD. There is also Exif data, with a MakerNote.\n\n',
        types: ['.orf'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Olympus_ORF',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-pentax-pef',
        description:
            'Pentax PEF (or Pentax RAW) is a raw image format associated with Pentax digital cameras. It is based on TIFF.',
        types: ['.pef', '.ptx'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Pentax_PEF',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-fuji-raf',
        description:
            'RAF, or Fujifilm RAW, is a raw image format used by some Fujifilm digital cameras.',
        types: ['.raf'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Fujifilm_RAF',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-panasonic-raw',
        description:
            'Panasonic RAW/RW2, also known as Leica RAW or RWL, is a raw image format used by some Panasonic and Leica digital cameras. It can also be known by a camera-specific name such as Lumix RAW or Panasonic LX3 RAW.<br /><br />Different cameras produce different versions of it, and at some point the .raw file extension was changed to .rw2. The term Panasonic RAW might refer to just the .raw files, but more likely includes both .raw and .rw2.',
        types: ['.raw', '.rw2', '.rwl'],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/Panasonic_RAW/RW2',
            },
        ],
        alternatives: [],
    },
    {
        name: 'audio/flac',
        description:
            'FLAC stands for Free Lossless Audio Codec, an audio format similar to MP3, but lossless, meaning that audio is compressed in FLAC without any loss in quality. <br /><br />It can encode audio with a PCM bit resolution up to 32 bits per sample and sampling rates up to 640 kHz. FLAC-encoded audio is usually found either in a native container (which has the extension .flac), or in an Ogg container (when it\'s known as OggFLAC).',
        types: ['.flac'],
        furtherReading: [
            {
                title: 'The National Archives',
                url: 'https://www.nationalarchives.gov.uk/PRONOM/fmt/279',
            },
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/FLAC',
            },
        ],
        alternatives: ['audio/x-flac'],
    },
    {
        name: 'image/x-sony-sr2',
        description:
            'SR2 is a raw image format produced by some makes of Sony cameras. The format is based upon TIFF.',
        types: ['.sr2'],
        furtherReading: [
            {
                title: 'The National Archives',
                url: 'https://www.nationalarchives.gov.uk/PRONOM/Format/proFormatSearch.aspx?status=detailReport&id=1936&strPageToDisplay=summary',
            },
        ],
        alternatives: [],
    },
    {
        name: 'image/x-sony-srf',
        description: '',
        types: ['.srf'],
        alternatives: [],
    },
    {
        name: 'image/x-sigma-x3f',
        description:
            'X3F (or Sigma X3F, or Foveon X3F) is a raw image format used by some Sigma digital cameras that use a "Foveon sensor".',
        types: ['.x3f'],
        alternatives: [],
        furtherReading: [
            {
                title: 'Let\'s Solve the File Format Problem!',
                url: 'http://fileformats.archiveteam.org/wiki/X3F',
            },
        ],
    },
];

export default mimeData;
