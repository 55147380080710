<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
    <h4 class="modal-title" id="myModalLabel">Change your email address?</h4>
</div>
<div class="modal-body" *ngIf="openState == 1">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            Your current email address is <strong>{{user.email}}</strong>
        </div>
    </div>
    <hr />
    <!--
        This is the ugliest hack I've ever seen.

        fake fields are a workaround for chrome autofill getting the wrong fields.
        Browsers stopped honouring autocomplete=off, which means anywhere they
        see an input type of password, the visitor's password (if stored) will
        be plastered.  They've removed the ability for the HTML to dictate
        whether it should be there or not.

        These two fields must be above the real fields

        Thanks browsers!
    -->
    <input style="visibility:hidden; width: 1px; height: 1px;" type="text" name="fakeusernameremembered"/>
    <input style="visibility:hidden; width: 1px; height: 1px;" type="password" name="fakepasswordremembered"/>
    <!-- // fake fields -->
    <div class="row">
        <div class="col-sm-4">New email address:</div>
        <div class="col-sm-8">
            <input
                type="email"
                required
                id="change-username-new-email"
                autocomplete="off"
                class="form-control"
                placeholder="Enter new email address"
                [(ngModel)]="newEmail"
            />
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">Password:</div>
        <div class="col-sm-8">
            <input
                type="password"
                required
                autocomplete="off"
                class="form-control"
                id="change-username-acct-password"
                placeholder="Enter your account password"
                [(ngModel)]="password"
                syncShowHidePassword
                identity="usernamechangepass"
            />
        </div>
    </div>
</div>
<div class="modal-body" *ngIf="openState == 2">
    <div class="row">
        <div class="col-sm-12">
            Please check your new email address, <strong>{{newEmail}}</strong>,
            and follow the verification instructions contained within the email
            to complete this process.
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="openState == 1">
    <button type="button"
        class="btn btn-primary"
        id="send-verification-email-dialog-btn"
        (click)="requestEmailChange()"
        [disabled]="spinner || !password || !newEmail"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>

        Send verification email
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>

<div class="modal-footer" *ngIf="openState == 2">
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
</div>