<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()"  aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title" >Email preferences</h4>
</div>
<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-4">Product notifications:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="product"
                        id="email-pref-product-enable"
                        value="1"
                        [(ngModel)]="product"
                    />
                    Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="product"
                        id="email-pref-product-disable"
                        value="0"
                        [(ngModel)]="product"
                    />
                Disabled
                </label>
            </div>
            <div class="subtext">Get notified when new features are added to your plan <br>(we send less than 4 per year)</div>
        </div>
    </div>

    <hr/>

    <div class="row">
        <div class="col-sm-4">Special offers:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="marketing"
                        id="email-pref-marketing-enable"
                        value="1"
                        [(ngModel)]="marketing"
                    />
                    Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="marketing"
                        id="email-pref-marketing-disable"
                        value="0"
                        [(ngModel)]="marketing"
                    />
                Disabled
                </label>
            </div>
            <div class="subtext">Get special marketing offers and promotions.</div>
        </div>
    </div>

    <hr/>

    <div class="row">
        <div class="col-sm-4">Rewards notifications:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="rewards"
                        id="email-pref-rewards-enable"
                        value="1"
                        [(ngModel)]="rewards"
                    />
                    Enabled
                </label>
            </div>
            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="rewards"
                        id="email-pref-rewards-disable"
                        value="0"
                        [(ngModel)]="rewards"
                    />
                Disabled
                </label>
            </div>
            <div class="subtext">Get an email notification when someone has used your referral code.</div>
        </div>
    </div>

    <hr/>
    <div class="row">
        <div class="col-sm-12">
            Note: Sync will never sell or share your email address with a third-party.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        [disabled]="spinner"
        type="button"
        class="btn btn-primary"
        (click)="save()"
    >
        <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
        Save settings
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()" >Close</button>
</div>