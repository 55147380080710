<form
    (submit)="avatarForm.valid && onSubmit()"
    [formGroup]="avatarForm"
    method="post"
    ngNativeValidate
>
    <div class="modal-header">
        <button
            type="button"
            class="close"
            (click)="activeModal.close()"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">User Avatar Setting</h4>
    </div>

    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <!-- Your avatar will appear where applicable. PNG at 150x400 pixels recommended.<br> -->
        <!-- <a class="syncblue" href="https://www.sync.com/help/white-label">Learn more...</a> -->
        <div class="row row-vertical-center">
            <div class="col-sm-4">
                Upload avatar<em style="color: red" *ngIf="!logo">*</em> :
            </div>
            <!-- <div class="col-sm-4" *ngIf= "!logo">
        <img [src]="defaultLogoPath" alt="Sync Logo">
    </div> -->
            <div class="col-sm-4" *ngIf="logo">
                <img
                    class="logo-dialog-custom"
                    [src]="'data:image/png;base64,' + logo"
                />
            </div>
            <div class="col-sm-4">
                <label for="dialog-display-avatar" class="btn btn-default">
                    <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
                    Browse...
                </label>
                <input
                    type="file"
                    style="display: none"
                    id="dialog-display-avatar"
                    formControlName="logo"
                    accept="image/*"
                    (change)="uploadFile($event.target.files)"
                />
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-default pull-left"
            (click)="clearAvatar()"
        >
            Reset to Default
        </button>
        <button
            type="submit"
            class="btn btn-primary"
            [disabled]="spinner || !avatarForm.valid || !logo"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-default"
            (click)="activeModal.close('')"
        >
            Close
        </button>
    </div>
</form>
