import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinksRoutingModule } from './links-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LinkListComponent } from './link-list/link-list.component';
import { LinkPasswordService } from './services/link-password.service';
import { LinkListService } from './services/link-list.service';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbPaginationModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogLinkComponent } from './dialog-link/dialog-link.component';
import { LinkManageComponent } from './link-manage/link-manage.component';
import { LinkSettingsComponent } from './link-settings/link-settings.component';
import { LinkSuspendedComponent } from './link-suspended/link-suspended.component';
import { LinkCreateComponent } from './link-create/link-create.component';
import { LinkDeleteComponent } from './link-delete/link-delete.component';
import { LayoutModule } from '../layout/layout.module';
import { LinkPrecreateSettingsComponent } from './link-precreate-settings/link-precreate-settings.component';
import { LinkEmailComponent } from './link-email/link-email.component';
@NgModule({
  imports: [
    CommonModule,
    LinksRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    SharedModule,
    LayoutModule
  ],
  providers: [
    LinkListService,
    LinkPasswordService
  ],
  exports: [],
  declarations: [LinkListComponent, DialogLinkComponent, LinkManageComponent, LinkSettingsComponent, LinkSuspendedComponent, LinkCreateComponent, LinkPrecreateSettingsComponent, LinkEmailComponent, LinkDeleteComponent],
  entryComponents: [DialogLinkComponent, LinkListComponent]
})
export class LinksModule { }
