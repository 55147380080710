import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsApiService {

  constructor(private apiService: ApiService) { }

  getList(opts: sync.IEventOptions): Promise<any> {
    const params: any = {};
    if (opts) {
        if (opts._id) {
            params._id = opts._id;
        }
        if (opts.shareId) {
            params.share_id = opts.shareId;
        }
        if (opts.date) {
            params.date = opts.date;
        }
        if (opts.lastServtime) {
            params.lt_servtime = opts.lastServtime;
        }
        if (opts.sliceOffset) {
            params.slice_offset = opts.sliceOffset;
        }
        if (opts.limit) {
            params.limit = opts.limit;
        }
        if (opts.eventTypes) {
            params.event_types = opts.eventTypes;
        }
    }
    return this.apiService.execute('eventslist', params);
}
}
