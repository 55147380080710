import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    HostBinding,
} from '@angular/core';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../../../reducers';
import { Store, select } from '@ngrx/store';
import { User } from '../../../models';
import { LoggerService } from '../../../../core/logger.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/api.service';
import { finalize, takeLast } from 'rxjs/operators';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sync-header-links',
    templateUrl: './header-links.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderLinksComponent implements OnInit, OnDestroy {
    public pendingShares: number; // user
    public eventCount: number; // user

    public imageCachekey: string; //32-char random string
    // @HostBinding("style.--primaryColor") only works in Angular 9+
    public primaryColor: string;
    public textColor: string;
    public linkColor: string;
    public logoHost: string;
    public whiteLabelLoaded: boolean;
    public avatarEndpoint = `${environment.logohost}avatar/`;
    public cpHost = environment.cphost;
    public userLoaded: boolean;

    private sub: Subscription;
    private sub1: Subscription;

    public user: User;

    constructor(
        private store: Store<fromRoot.State>,
        private api: ApiService,
        private log: LoggerService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.sub = this.store
            .pipe(select(fromRoot.getAuthState))
            .subscribe((data) => {
                if (data) {
                    // console.log('data loaded');
                    // console.log(data.user);
                    this.user = data.user;
                }
            });

        this.sub1 = this.store
            .pipe(select(fromRoot.getLinkFileListState))
            .subscribe((data) => {
                if (data.whitelabel_loaded) {
                    this.imageCachekey = data.image_cachekey;
                    this.primaryColor = data.header_primary_color;
                    this.textColor = data.header_text_color;
                    this.linkColor = data.link_text_color;
                    this.whiteLabelLoaded = data.whitelabel_loaded;
                    this.logoHost = environment.logohost;
                }
                //observe when iframe in comment-block completely loaded
                if (data.iframeReady) {
                    this.userLoaded = true;
                }
                // this.log.info('LINK FILE LIST');
                // this.log.info(data);
            });
    }
    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
    }

    public openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }

    /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer
            .bypassSecurityTrustStyle(`--headerPrimaryColor: ${this.primaryColor};
                                                        --headerTextColor: ${this.textColor};
                                                        --linkTextColor: ${this.linkColor};
                                                    `);
    }
}
