export class DirtyOutKeyItemDataKey {
    old_share_id: number;
    old_share_sequence: number;
    new_share_key: string;
    blob_id: number;
    data_key: string;
    share_key: string;
}

export class DirtyOutKeyItemShareKey {
    sharekey_id: string;
    enc_share_key: string;
    servtime: number;
}
