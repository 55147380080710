import { Deserializable } from './deserializable.model';

export class UserRewardItem implements Deserializable<UserRewardItem> {
    bonus_servtime = 0;
    status = 0;
    bonus_bytes = 0;
    bonus_type = '';
    bonus_type_text = '';
    bonus_text = '';
    bonus_from = '';
    bonus_from_id = '';
    comment = '';

    constructor(params?: {[k: string]: any}) {
        if (params) {
            return this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

