
<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    <h4 class="modal-title"><i class="fas fa-cog"></i> Password hint</h4>
</div>

<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            A password hint is a word or phrase that can help you remember your password. Your password hint will be emailed to you in the event that you've forgotten your password.
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-12">
            <div  *ngIf="user.has_password_hint">
                <i class="ion-checkmark-circled fa-lg"></i> Password hint is enabled.
                <a (click)="save()" class="syncblue showhand">Disable</a> or
                <a class="showhand syncblue" (click)="edit = !edit">
                    Edit
                </a>.
            </div>
            <p  *ngIf="!user.has_password_hint">
                <strong>You have not yet added a password hint. Enter one now:</strong>
            </p>
            <hr />
            <div class="form-group" *ngIf="!user.has_password_hint || edit">
                <input
                    type="text"
                    id="passhint"
                    [(ngModel)]="hint"
                    placeholder="Enter a hint to remember your password"
                    class="form-control"
                />
            </div><!-- /input-group -->
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        (click)="save()"
        [disabled]="spinner || !hint"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        {{(user.has_password_hint) ? 'Update' : 'Save'}}
    </button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>
