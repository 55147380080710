import { Directive, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
    selector: '[syncShowFocus]',
})
export class ShowFocusDirective implements OnChanges {
    @Input('syncShowFocus') syncShowFocus: boolean;

    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['syncShowFocus'] && changes['syncShowFocus'].currentValue) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 0);
        }
    }
}
