import { Component, Input, OnInit } from '@angular/core';
import { AppsService } from '../../apps/services/apps.service';
import { LoggerService } from '../../core/logger.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { ShareService } from '../share.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'sync-dialog-share-app-list',
    templateUrl: './dialog-share-app-list.component.html',
})
export class DialogShareAppListComponent implements OnInit {
    public currentItem: sync.IFile;
    public shareData: sync.IShareData;
    public shareId: number;
    public errors: Array<sync.IErrorCode> = [];
    public spinners = {
        loading: false,
        enabling: false,
        disabling: false,
    };
    public apps: sync.IApp[] = [];
    public isShareLimit: boolean;
    @Input() public item: sync.IFile;

    constructor(
        private appsService: AppsService,
        private loggerService: LoggerService,
        private shareService: ShareService,
        private fileListService: FileListService,
        private activeModal: NgbActiveModal,
        private router: Router
    ) {}

    async ngOnInit() {
        this.spinners.loading = true;
        this.currentItem = this.item;

        await this.checkShare();
        this.apps = await this.appsService.readApps(this.shareId);

        this.spinners.loading = false;
    }
    public async checkShare() {
        if (this.item.is_shared) {
            this.loggerService.info('Loading apps for a Shared dir');
            this.shareId = this.item.share_id;
            try {
                const share = await this.shareService.getShare(this.shareId);
                this.shareData = share;
            } catch (err) {
                this.handleErr(err);
            }
        } else {
            this.loggerService.info('Loading apps for an unshared dir');
            // This will let us simply get the apps from a user
            this.shareId = 0;
        }
    }

    public checkIfRemovingAllActiveApps(apps: sync.IApp[]): boolean {
        if (apps) {
            for (const cur of apps) {
                if (cur.enabled && !cur.selected) { return false; }
            }
            return true;
        } else {
            return false;
        }
    }

    public getSelectedAmtApps(apps: sync.IApp[]): number {
        let sum = 0;
        if (!apps) { return 0; }
        for (const cur of apps) {
            sum += cur.selected ? 1 : 0;
        }
        return sum;
    }
    public getEnabledAmtApps(apps: sync.IApp[]): number {
        let sum = 0;
        if (!apps) { return 0; }
        for (const cur of apps) {
            sum += cur.enabled ? 1 : 0;
        }
        return sum;
    }

    public async enableApps() {
        this.spinners.enabling = true;

        await this.checkShare();

        if (this.item.is_shared && this.shareData) {
            if (this.shareData.isAddApp === 0 && this.shareData.isOwner === 0) {
                this.errors.push({
                    code: 11203,
                    msg: 'You Do not have permission to add apps to this share.',
                });
                this.spinners.enabling = false;
                return;
            }
        }

        if (this.getEnabledAmtApps(this.apps) > 0) {
            if (!this.item.is_app_share) {
                this.loggerService.error(
                    'Sync Type (stAPP) isn\'t set. Not enabling more apps'
                );
                this.errors.push({ code: 11202 });
                this.spinners.enabling = false;
                return;
            } else if (!this.shareData || !this.shareData.app_integrated) {
                this.loggerService.error(
                    'Share Type (shAPP) isn\'t set. Not enabling more apps'
                );
                this.errors.push({ code: 11201 });
                this.spinners.enabling = false;
                return;
            } else {
                this.loggerService.info(
                    'Share and Webpaths look good. Enabling apps'
                );
            }
        } else {
            if (this.shareData && this.shareData.app_integrated) {
                if (this.item.is_app_share) {
                    // Even though no apps are active, somehow the sync type is already set.
                    // Its an error but lets not throw.
                    this.loggerService.error(
                        'Sync Type (stAPP) shouldn\'t be set if no apps are enabled!'
                    );
                } else {
                    this.loggerService.info(
                        'Setting user\'s Sync Type (share already exists). Enabling apps'
                    );
                    try {
                        await this.appsService.addAppToExistingShare(
                            this.item.sync_id,
                            this.shareId
                        );
                        this.item.is_app_share = 1;
                    } catch (err) {
                        this.handleErr(err);
                        return;
                    }
                    this.refreshPage();
                }
            } else {
                if (this.item.is_shared) {
                    this.loggerService.info(
                        'Adding app share to existing share. Enabling apps'
                    );
                    try {
                        await this.appsService.addAppToExistingShare(
                            this.item.sync_id,
                            this.shareId
                        );
                    } catch (err) {
                        this.handleErr(err);
                        return;
                    }
                    this.item.is_app_share = 1;

                    this.refreshPage();
                } else {
                    this.loggerService.info(
                        `Creating share on ${this.item.sync_id} and ${this.item.name}. Enabling apps`
                    );
                    try {
                        const shareId =
                            await this.appsService.addAppAndCreateShare(
                                this.item.sync_id,
                                this.item.name
                            );
                        this.shareId = shareId;
                        this.item.is_app_share = 1;
                        this.item.is_shared = 1;
                        this.item.is_hide_share = 1;
                        this.item.share_id = this.shareId;
                    } catch (err) {
                        this.handleErr(err);
                        return;
                    }
                }
            }
        }

        this.apps = await this.appsService.writeApps(
            this.shareId,
            this.apps,
            true
        );

        this.spinners.enabling = false;
        // this.$uibModalInstance.close();
    }

    public async disableApps() {
        this.spinners.disabling = true;

        await this.checkShare();

        if (this.item.is_shared && this.shareData) {
            if (this.checkIfRemovingAllActiveApps(this.apps)) {
                this.loggerService.info(`Removing all active apps on share.`);

                try {
                    // We need to remove the PERSONAL stAPP
                    await this.appsService.disableAppShare(this.item.sync_id);
                } catch (err) {
                    this.handleErr(err);
                    return;
                }

                this.item.is_app_share = 0;

                try {
                    const apps = await this.appsService.writeApps(
                        this.shareId,
                        this.apps,
                        false
                    );
                    this.apps = apps;
                } catch (err) {
                    this.handleErr(err);
                    return;
                }

                this.refreshPage();
            } else {
                this.loggerService.info(
                    `Removing some apps, other apps remain active. Leaving share intact.`
                );
                // No Op, we don't need to worry about ripping out the app share

                this.apps = await this.appsService.writeApps(
                    this.shareId,
                    this.apps,
                    false
                );
            }
        } else {
            this.loggerService.warn(
                'Share doesn\'t exist, can\'t remove apps from nothing.'
            );

            if (this.getEnabledAmtApps(this.apps) > 0) {
                this.loggerService.error(
                    'Apps should not be enabled since share doesn\'t exist'
                );
                this.errors.push({
                    code: 11201,
                    msg: 'Cannot disable app as share doesn\'t exit. Please contact support.',
                });
            }
        }

        this.spinners.disabling = false;
    }

    private handleErr(errData: any) {
        this.loggerService.error(
            `Dialog Share App List - Got error ${JSON.stringify(errData)}`
        );
        if (typeof errData == 'object' && errData.error_code) {
            this.errors.push(errData);
        } else if (typeof errData == 'object' && errData.code) {
            // Legacy (?) error
            this.errors.push(errData);
        } else {
            this.errors.push({
                code: 1000,
                msg: 'An unknown error occured. Please contact support.',
            });
        }
    }

    private refreshPage() {
        this.fileListService.reload();
        this.router.navigate(['/apps']);
    }

    public closeModal() {
        this.activeModal.close();
    }
}
