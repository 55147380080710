import { Component, OnInit, Input } from '@angular/core';
import { LinkListItem, User, LinkApiOutput, PermissionActions, LinkPreCreateOptions, BlendEvent } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkListService } from '../services/link-list.service';
import { BlendService } from '../../shared/services/blend.service';
import { renameLinkOptions } from '../../util/blend-property-names';
import { linkExpiryDateOptions } from '../../shared/models/multiadmin.model';

@Component({
    selector: 'sync-dialog-link',
    templateUrl: './dialog-link.component.html',
})
export class DialogLinkComponent implements OnInit {
    @Input() item: LinkListItem | sync.IFile;

    @Input() pid: number;

    @Input() openState: number;

    public user: User;
    public link: LinkApiOutput;
    public initialized = false;
    public linkPrecreateOptions: LinkPreCreateOptions;

    constructor(
        private activeModal: NgbActiveModal,
        private linkList: LinkListService,
        private userService: UserService,
        private blendService: BlendService,
        private linklistService: LinkListService
    ) { }

    ngOnInit() {
        this.user = this.userService.getUser();
        if (!this.item.is_publink) {
            const shareLink = this.userService.checkPermission(PermissionActions.SHARE_LINKS);
            const shareLinkExpiry = this.userService.checkPermission(PermissionActions.SHARE_LINK_EXPIRY);
            const shareLinkPassword = this.userService.checkPermission(PermissionActions.SHARE_LINK_PASSWORD);
            if (shareLink && (shareLinkExpiry || shareLinkPassword)) {
                this.openState = 4;
            } else {
                this.openState = 0;
                this.initialized = true;
            }
        } else {
            if (this.openState == undefined) {
                this.openState = 1;
            }
            this.linkList
                .getLink(this.item.sync_id, this.item.name)
                .then((data) => {
                    this.link = data;
                    this.initialized = true;
                });
        }
    }

    public async onStateChange(state: number) {
        if ((this.openState == 0 || this.openState == 2) && state == 1) {
            // The user
            const data = await this.linkList.getLink(
                this.item.sync_id,
                this.item.name
            );
            this.initialized = true;
            this.link = data;
        }
        if (this.openState == 4) {
            this.initialized = true;
        }
        this.openState = state;
    }

    public async onLinkPrecreateOptionsStateChange(linkPrecreateOptionsState: LinkPreCreateOptions) {
        this.linkPrecreateOptions = linkPrecreateOptionsState;
        await this.onStateChange(0);
        this.blendService.track(BlendEvent.SAVE_LINK_SETTINGS, renameLinkOptions({ ...this.linkPrecreateOptions, expiryTimeInDays: this.getExpiryIndays(), isExpiryRequired: true, type: this.item.type, file_extension: this.item.file_extension }));
    }

    getExpiryIndays() {
        const currDate = new Date();
        const selectedDate = new Date(this.linkPrecreateOptions.exp_servtime);
        const Difference_In_Time = selectedDate.getTime() - currDate.getTime();
        const Difference_In_Days = Math.round (Difference_In_Time / (1000 * 3600 * 24));
        const option = linkExpiryDateOptions.filter(op => op.value === this.linklistService.getLinkEnforcedSettings().linkExpiryDateDefault);
        return option.length ? Difference_In_Days : 0;
    }

    public onClose(refresh: boolean) {
        this.activeModal.close(refresh);
    }
}
