
import { AppsService } from '../services/apps.service';
import { ErrCode } from '../../shared/models';
import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
    selector: 'sync-dialog-app-info',
    templateUrl: './dialog-app-info.component.html'
})
export class DialogAppInfoComponent implements OnInit {

    public errcode: ErrCode;
    @Input() app: sync.IApp;

    public permissions: sync.IAppPermissions;
    public spinner = false;

    constructor(
        public activeModal: NgbActiveModal,
        private Logger: LoggerService,
        private AppsService: AppsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        // These are default permissions.
        this.permissions = {
            apACCOUNT: 1,
            apREAD: 1,
            apWRITE: 1,
            apSHARE: 0,
            apLINK: 1,
        };
    }

    public async revoke() {
        this.spinner = true;

        this.Logger.info(`Disabling app ${this.app.app_id}`);
        await this.AppsService.disableApp(this.app.app_id)
        .then(() => {
            this.spinner = false;
            this.router.navigate(['/account/apps']);
            this.activeModal.close();
        })
        .catch((ex) => {
            this.spinner = false;
            this.Logger.error('An error occurred revoking apps');
            this.Logger.error(ex);
            this.errcode = ErrCode.fromException(ex);
        });
    }

    public closeModal() {
        this.activeModal.close();
    }
}
