import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { ErrCode } from '../../shared/models';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProvisionWorkerService {
    private WorkerObj: Worker;

    constructor(
        private api: ApiService,
        private log: LoggerService
    ) { }

    public async execute(username: string, password: string, passReset: number, sso: number) {
        const pubkey = await this.api.fetchText('/key/password-recover/pubkey');

        return new Promise<sync.IProvisionData>((resolve, reject) => {
            this.WorkerObj = new Worker(environment.jslibpath + 'provision-worker.js?d=' + Date.now());
            this.WorkerObj.addEventListener('message', (ev: MessageEvent) => {
                const agentCmd = ev.data.agentcmd,
                    cmdData = ev.data.data;
                switch (agentCmd) {
                    case 'error':
                        this.log.error(cmdData);
                        this.log.error(ev.data.errcode);
                        const errcode = parseInt(ev.data.errcode, 10) || 1000;
                        this.log.error(errcode + ' error code download-chunk-worker');
                        reject(new ErrCode(errcode));
                        break;
                    case 'log':
                        this.log.info(cmdData);
                        break;
                    case 'runFinished':
                        console.log('Resolved promise ');
                        console.log(cmdData);
                        resolve(<sync.IProvisionData>cmdData);
                        break;
                    default:
                        this.log.error(`${agentCmd} does not exist`);
                        reject(new ErrCode(8221));
                        break;
                }
            }, false);
            this.WorkerObj.postMessage({
                workercmd: 'run',
                data: {
                    jsliburl: environment.jslibpath,
                    username: username,
                    password: password,
                    passReset: passReset,
                    resetPubKey: pubkey,
                    sso: sso
                }
            });
        });
    }

    public completed(): void {
        this.log.info('calling terminate on workerobj');
        this.WorkerObj.terminate();
        this.WorkerObj = null;
    }
}
