import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../core/api.service';

@Component({
    selector: 'sync-billing-invoice',
    templateUrl: './billing-invoice.component.html'
})
export class BillingInvoiceComponent implements OnInit {

    public invoice;
    constructor(
        private api: ApiService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        console.log('Loading invoice for ' + this.route.snapshot.params['id']);
        this.loadInvoice();
    }

    private async loadInvoice() {
        this.invoice = await this.api.execute('orderview', {
            invoice_id: this.route.snapshot.params['id']
        });
    }
}
