import { BaseApiOutput } from './base-api.model';

export class LinkNewApiOutput extends BaseApiOutput {
    cnt: number;
    cachekey: string;
    share_id: number;
    bg: number;
}

export class LinkNewApiInput {

    command = 'linknew';

    sync_id: number;
    label: string;
    enc_share_key: string;
    enc_password: string;
    enc_comment_key: string;
    salt: string;
    iterations: number;
}


