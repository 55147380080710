<div class="modal-header">
    <button type="button" class="close"  (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
        <span>Link Suspended: </span>
    </h4>
</div>
<div class="modal-body">
    <p class="alert alert-danger">
        This link has been suspended, please contact us at abuse@sync.com and
        quote: "{{link.cachekey}}"
    </p>
</div>

<div class="modal-footer" >

    <!-- <button
        ng-click="ctrl.deleteLink(ctrl.syncId)"
        class="btn btn-default pull-left"
    >Remove Link</button>

    <button ng-click="$close()"
            ng-show="ctrl.linksettings"
            class="btn btn-primary">
        Close
    </button> -->
</div>