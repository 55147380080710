import { Injectable } from '@angular/core';
import { ZuoraAccountService } from 'src/app/account/services/zuora-account.service';
import { LoggerService } from 'src/app/core/logger.service';
import { UserService } from 'src/app/core/user.service';
import { zAccountInfo } from '../models/api/orderprofile-api.model';
import { PermissionActions, PlanType } from '../models/enums';
import { BroadcastService } from './broadcast.service';
import { PlanTerm } from '../../shared/models/api/plandetails.model';
@Injectable({
  providedIn: 'root'
})
export class SkuService {

  public permissionActions = PermissionActions;
  public zprofile: zAccountInfo;
  public sku: string;
  public planLimit: number;

  constructor(
    private userService: UserService,
    private zuora: ZuoraAccountService,
    private log: LoggerService,
    private broadcastService: BroadcastService
  ) {
    this.broadcastService.on('sync.reset.user').subscribe(() => {
      this.sku = '';
      this.zprofile = null;
      this.planLimit = null;
    });
    this.broadcastService.on('sync.sessionnew.user').subscribe(() => {
      this.sku = '';
      this.zprofile = null;
      this.planLimit = null;
    });
  }

  public async getSku() {
    if (this.sku) {
      return this.sku;
    }

    if (!this.userService.isAuthenticated()) {
      return '';
    }

    this.sku = this.userService.get('plan_sku') || 'free';

    if (this.sku === 'free') {
      this.log.info('Sku not found, defaulting to free');
    }
    return this.sku;
  }

  // Function to tell if teams+ user using sku
  public isTeamsPlusUser(sku: string) {
    // expect the sku string as it can be reused at other places as well
    return sku ? (sku.includes('BPTSP') || sku.includes('BPTUP') as boolean) : false;
  }

  // Function Add to get the Product Plan SKU value product code like PSP-6T-Y-1 and we get PSP only.
  public async getProductPlanSKU(sku?: string): Promise<string> {
    if (!sku) {
      sku = await this.getSku();
    }
    return sku.split('-')[0];
  }

  // Function Add to get the  Plan Term value product code like PSP-6T-Y-1 and we get M or Y only.
  public getPlanTerm(plan?: string): string {

    let term = '';
    let planTerm = '';

    if (plan.split('-').length > 2) {
      term = plan.split('-')[2];
    }

    switch (term) {
      case 'M':
        planTerm = PlanTerm.MONTHLY;
        break;
      case 'Y':
        planTerm = PlanTerm.ANNUAL;
        break;
      default:
        planTerm = PlanTerm.ANNUAL;
        this.log.info('Could not determine plan term, defaulting to yearly');
        break;
    }

    return planTerm;
  }

  public async getPlanType(sku?: string): Promise<string> {
    const productPlan = await this.getProductPlanSKU(sku);
    const planType = PlanType[productPlan.toLowerCase()] || 'free';
    return planType;
  }

  public async isFreeUser(): Promise<boolean> {
    const isTeamsPlusUser =
      this.userService.get('is_multi_new') &&
        this.userService.get('is_unlimited_plan')
        ? true
        : false;
    return (await this.getSku()) == PlanType.free && !isTeamsPlusUser;
  }

  public async getPanLimit(): Promise<number> {
    if (this.planLimit) {
      return this.planLimit;
    }
    if (this.userService.isAuthenticated()) {
      this.planLimit = this.userService.get('plan_user_limit');
      if (!this.planLimit) {
        if (this.zprofile && this.zprofile.subscription) {
          this.planLimit = this.zprofile.subscription.quantity;
        } else if (this.userService.get('is_multi_new') && !this.zprofile) {
          this.zprofile = await this.zuora.getProfile();
          this.planLimit = this.zprofile.subscription.quantity;
        } else {
          this.planLimit = 1;
        }
      }
      return this.planLimit;
    } else {
      return 1;
    }
  }
}
