import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidateService } from '../../core/validate.service';
import { FeedbackService } from '../../notifications/feedback.service';
import { Constants } from '../../transfer/constants';
import { FileListService } from '../services/file-list.service';

@Component({
    selector: 'sync-new-folder',
    templateUrl: './new-folder.component.html',
})
export class NewFolderComponent implements OnInit {
    @Input('syncShowNewFolder') public showNewFolder: boolean;
    @Output('syncShowNewFolderChange') public showNewFolderChange = new EventEmitter<boolean>();
    @Input('syncNewFolder') public syncPid: number;
    public spinner = false;

   private pathlist: sync.IFile[];
   public MAX_FOLDER_NAME_LEN = Constants.MAX_FILE_NAME_LEN; // Maximum length of a folder name

   pathitemform = new FormGroup({
    pathname: new FormControl('', [Validators.maxLength(this.MAX_FOLDER_NAME_LEN), this.leadingSpace.bind(this), this.trailingChar.bind(this), this.reservedNames.bind(this),
    this.illegalCharacters.bind(this), this.exist.bind(this)])
});

    constructor(
        private fileListService: FileListService,
        private feedbackService: FeedbackService,
        private validateService: ValidateService
    ) {}

    leadingSpace(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return (fileName && fileName.charAt(0) !== ' ') ? null : { 'leadingSpace': true};
      }

    trailingChar(control: FormControl) {
        const fileName = control.value;
        const lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) { return true; }
        return !(lastChar === '.' || lastChar === ' ') ? null : { 'trailingChar': true};
    }
     reservedNames(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return !(/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i).test(fileName) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !(/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i).test(fileName) ? null : { 'reserved': true};
    }

    illegalCharacters(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) { return true; }
        return this.validateService.illegalCharacters(fileName) ? null : { 'illegal': true};
    }

    exist(control: FormControl) {
        const fileName = control.value;
        if (!this.pathlist) { return true; }
        if (this.validateService.isEmpty(fileName)) { return true; }
        const found = this.pathlist.filter((val) => {
            return (val.name.toLowerCase() == fileName.toLowerCase());
        });
        return !(found && found.length > 0) ? null : { 'exist': true};
    }

    ngOnInit() {
        this.fileListService.getListSubscription().subscribe((data) => {
            if (data && data.pathlist.length) {
                this.pathlist = data.pathlist;
            }
        });
    }

    public reset() {
        this.pathitemform.controls['pathname'].reset();
        this.showNewFolder = false;
        this.showNewFolderChange.emit(false);
        this.pathitemform.markAsPristine();
        this.spinner = false;
    }

    public async submit() {
        if (
            !this.pathitemform.valid ||
            !this.pathitemform.dirty ||
            !this.pathitemform.controls.pathname.value ||
            this.pathitemform.controls.pathname.value == ''
        ) {
            return;
        }
        const pid = this.syncPid,
            name = this.pathitemform.controls.pathname.value;
        this.spinner = true;
        try {
            await this.fileListService.mkdir(pid, name);
            this.fileListService.reload();
            this.reset();
            this.spinner = false;
        } catch (errData) {
            this.feedbackService.setFeedback('error', errData.msg);
        }
    }
}
