import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { SyncCryptService } from './sync-crypt.service';
import { LoggerService } from '../logger.service';
import {
    ErrCode,
    KeysNeedEncByShareApiOutput,
    DirtyOutTask,
    DirtyOutKeyItemDataKey,
    DirtyOutKeyItemShareKey,
    DirtyOutNeedEncItem,
    DirtyOutNeedEncDataKeys,
    KeysNeedEncBySyncApiOutput,
    DirtyOutTaskDataKeyResult,
    DirtyOutTaskShareNameResult,
    DirtyOutTaskResult,
    DirtyOutEncResultShare,
    DirtyOutEncResultSync,
    KeysSetEncApiOutput
} from '../../shared/models';


@Injectable()
export class DirtyOutService {

    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private log: LoggerService
    ) { }

    public async processDirtyOut(workList: DirtyOutTask[]) {
        const processlist: DirtyOutTaskResult[] = [];
        for (let i = 0, len = workList.length; i < len; i++) {
            const item = workList[i];
            switch (item.action) {
                case 'encnameshare':
                    processlist.push( await this.encShareName(item));
                    break;
                case 'encdatakey':
                    for (let j = 0, len2 = item.keys.length; j < len2; j++) {
                        processlist.push(await this.encDataKey(item, item.keys[j]));
                    }
                    break;
                default:
                    this.log.error('Processing dirty out, no action provided-' + item.toString());
                    break;
            }
        }
        if (processlist.length) {
            await this.api.execute('keyssetdirtyout', {
                items: processlist
            });
            return;
        } else {
            return;
        }
    }

    public async getKeyArrayForSyncId(sharedata: DirtyOutKeyItemShareKey[],
                                      syncId: number,
                                      plainName: string,
                                      dataKey?: string
    ) {
        const keysData: DirtyOutEncResultSync[] = [];
        for (let i = 0, len = sharedata.length; i < len; i++) {
            const shareitem = sharedata[i];
            let sharekey: string;
            try {
                sharekey = await this.crypt.sharekeyDecrypt(
                    shareitem.enc_share_key,
                    shareitem.sharekey_id
                );
            } catch (e) {
                const msg = ['Sharekey decrypt failed for ',
                syncId, ' key id = ', shareitem.sharekey_id,
                ' enc key = ' , shareitem.enc_share_key].join('');
                this.log.e(msg, e);
                throw new ErrCode(2000, msg);
            }
            if (!sharekey) {
                throw new Error('No share key provided');
            }
            try {
                const encShareName = await this.crypt.filenameEncrypt(plainName, sharekey);
                const encDataKey = (dataKey)
                    ? await this.crypt.datakeyEncrypt(dataKey, sharekey)
                    : undefined;
                const keyData: DirtyOutEncResultSync = {
                    enc_data_key: encDataKey,
                    sync_id: syncId,
                    sharekey_id: shareitem.sharekey_id,
                    enc_share_name: encShareName,
                    servtime: shareitem.servtime,
                };
                keysData.push(keyData);
            } catch (e) {
                const msg = ['Data key or file name failed ',
                syncId, ' key id = ', shareitem.sharekey_id,
                ' enc key = ' , shareitem.enc_share_key].join('');
                this.log.e(msg, e);
                throw new ErrCode(2010, msg);
            }
        }
        return keysData;
    }

    public async getShareKeyDataForSyncId(syncId: number) {
        const result = await this.api.execute<KeysNeedEncBySyncApiOutput>('keysneedencbysync', {
            sync_id: syncId
        });
        const sharedata: DirtyOutKeyItemShareKey[] = [];
        for (const [sharekeyId, encShareKey] of Object.entries(result.sharekeys)) {
            const item = new DirtyOutKeyItemShareKey();
            item.sharekey_id = sharekeyId;
            item.enc_share_key = encShareKey;
            item.servtime = result.servtime;
            sharedata.push(item);
        }
        return sharedata;
    }

    public async executeForSync(syncId: number,
                                plainName: string,
                                dataKey?: string
    ) {
        try {
            const sharedata = await this.getShareKeyDataForSyncId(syncId);
            const result = await this.getKeyArrayForSyncId(sharedata, syncId, plainName, dataKey);
            return result;
        } catch (e) {
            const msg = ['executeForSync ', syncId].join('');
            this.log.e(msg, e);
            throw new ErrCode(2010);
        }
    }
    public async executeForShare(shareId: number) {
        const data = await this.api.execute<KeysNeedEncByShareApiOutput>('keysneedencbyshare', { share_id: shareId });
        const setItems: DirtyOutEncResultShare[] = [];
        const sharekeys = data.sharekeys;

        if (!data.items || !data.items.length) {
            this.log.warn('No items to execute for share id ' + shareId);
            return;
        }

        for (let i = 0, len = data.items.length; i < len; i++) {
            const cur = data.items[i];
            const sharekey = await this.crypt.sharekeyDecrypt(
                 sharekeys[cur.new_key_id],
                 cur.new_key_id);
            const filename = await this.crypt.filenameDecrypt(cur.metaname);
            const encShareName = await this.crypt.filenameEncrypt(filename, sharekey);
            const datakeys = await this.datakeyProcess(cur, cur.data_keys, sharekeys, sharekey);

            setItems.push({
                sync_id: cur.sync_id,
                share_id: cur.share_id,
                sharekey_id: cur.new_key_id,
                servtime: cur.servtime,
                enc_share_name: encShareName,
                data_keys: datakeys
            });
        }
        // console.warn('Sending keyssetenc with ' , setItems);
        return await this.api.execute<KeysSetEncApiOutput>('keyssetenc', {
            items: setItems
        });
    }

    private async datakeyProcess(cur: DirtyOutNeedEncItem,
                                 datakeys: DirtyOutNeedEncDataKeys[],
                                 sharekeys: {[sharekey_id: string]: string},
                                 newShareKey: string
    ) {
        if (!datakeys || !datakeys.length) {
            this.log.info(cur.sync_id + ' has no datakeys to process');
            return [];
        }
        const newDataKeys: DirtyOutNeedEncDataKeys[] = [];
        for (let i = 0, len = datakeys.length; i < len; i++) {
                // console.log('looping datakeys.length');
            const curDk = datakeys[i];
            try {
                const plainShareKey = await this.crypt.sharekeyDecrypt(sharekeys[curDk.sharekey_id], curDk.sharekey_id);
                const dataKey = await this.crypt.datakeyDecrypt(curDk.enc_data_key, plainShareKey);
                const newEncDataKey = await this.crypt.datakeyEncrypt(dataKey, newShareKey);
                const result = new DirtyOutNeedEncDataKeys();
                result.sync_id = cur.sync_id;
                result.blob_id = cur.blob_id;
                result.enc_data_key = newEncDataKey;
                result.sharekey_id = curDk.sharekey_id;
                newDataKeys.push(result);
            } catch (ex) {
                // add logging but don't deal with the exception, re-throw
                this.log.error(`Error DirtyOut::datakeyProcess - datakey ${JSON.stringify(curDk)}`);
                this.log.error(`Error DirtyOut::datakeyProcess - current item: ${JSON.stringify(cur)}`);
                throw ex;
            }
        }
        return newDataKeys;
    }


    private async encDataKey(item: DirtyOutTask, key: DirtyOutKeyItemDataKey) {
        const old_sharekey_id = `${key.old_share_id}-${key.old_share_sequence}`,
              sharekey_id = `${item.share_id}-${item.share_sequence}`;

        try {
            const newShareKey = await this.crypt.sharekeyDecrypt(key.new_share_key, sharekey_id);
            const shareKey = await this.crypt.sharekeyDecrypt(key.share_key, old_sharekey_id);
            const datakey = await this.crypt.datakeyDecrypt(key.data_key, shareKey);
            const encDataKey = await this.crypt.datakeyEncrypt(datakey, newShareKey);

            const result = new DirtyOutTaskDataKeyResult();
            result.action = 'encdatakey';
            result.blob_id = key.blob_id;
            result.sync_id = item.sync_id;
            result.share_id = item.share_id;
            result.share_sequence = item.share_sequence;
            result.enc_data_key = encDataKey;

            return result;
        } catch (ex) {
            // add logging but don't deal with the exception, re-throw
            this.log.error(`Error DirtyOut::encDataKey - sharekey_id ${sharekey_id} - old_sharekey_id: ${old_sharekey_id}`);
            this.log.error(`Error DirtyOut::encDataKey - Item: ${JSON.stringify(item)}`);
            this.log.error(`Error DirtyOut::encDataKey - key: ${JSON.stringify(key)}`);
            throw ex;
        }
    }
    private async encShareName(item: DirtyOutTask) {
        const shareKeyId = `${item.share_id}-${item.share_sequence}`;

        try {
            const filename = await this.crypt.filenameDecrypt(item.enc_share_name);
            const sharekey = await this.crypt.sharekeyDecrypt(item.share_key, shareKeyId);
            const encShareName = await this.crypt.filenameEncrypt(filename, sharekey);

            const result = new DirtyOutTaskShareNameResult();
            result.action = 'encnameshare';
            result.sync_id = item.sync_id;
            result.share_id = item.share_id;
            result.share_sequence = item.share_sequence;
            result.enc_share_name = encShareName;

            return result;
        } catch (ex) {
            // add logging but don't deal with the exception, re-throw
            this.log.error(`Error DirtyOut::encShareName - sharekey_id ${shareKeyId}`);
            this.log.error(`Error DirtyOut::encShareName - Item: ${JSON.stringify(item)}`);
            throw ex;
        }
    }
}

