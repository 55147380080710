import { Directive, HostListener, Inject, Input } from '@angular/core';
import { BroadcastService } from '../shared/services/broadcast.service';

/**
 * @description
 * Toggles the rename form widget on a click event.
 *
 * Listens for `event:hide.tools` to hide the form input box
 */

@Directive({
    selector: '[syncPathRename]',
})
export class PathRenameDirective {
    @Input() selected: sync.IFile[] = [];

    constructor(
        private broadcastService: BroadcastService
    ) {}

    @HostListener('click', ['$event']) click(event) {
        setTimeout(() => {
            this.broadcastService.broadcast(
                'event:file-list.rename',
                this.selected[0].sync_id
            );
        }, 100);
    }
}
