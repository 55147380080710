import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sync-error-display-hash',
  templateUrl: './error-display-hash.component.html'
})
export class ErrorDisplayHashComponent implements OnInit {
  @Input('errors') errors: sync.IErrorCode[] = [];

  constructor() { }

  ngOnInit() {
  }

}
