import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    canLinkBrowserOpen,
    canBrowserOpen,
    getFileExt,
} from '../../shared/func';
import { DomSanitizer } from '@angular/platform-browser';
import { CompatService } from '../../core/compat.service';
import { UserService } from '../../core/user.service';
import { PermissionActions, User } from '../../shared/models';
import { Subscription } from 'rxjs';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { ApiService } from '../../core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadCompatComponent } from '../../transfer/download-compat/download-compat.component';
import { TransferService } from '../../transfer/transfer.service';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { WopiUpgradeComponent } from '../wopi-upgrade/wopi-upgrade.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FileItemService } from '../file-item.service';
import { DialogEditLinkAuthComponent } from '../dialog-edit-link-auth/dialog-edit-link-auth.component';

@Component({
    selector: 'sync-preview-menu',
    templateUrl: './preview-menu.component.html',
    styleUrls: [],
})
export class PreviewMenuComponent implements OnInit, OnDestroy {
    @Input('url') public url: string;
    @Input('item') public item: sync.IFile;
    @Input('transferItem') public transferItem: sync.ITransferItemDownload;
    @Input('upsell') public upsell: boolean;
    public errorMsg: string;
    public spinner = false;
    public isAuth: boolean;
    public user: User;
    private subscription: Subscription;
    public showComments: boolean;

    //white label attributes
    public isWhiteLabel: boolean;
    public linkTextColor: string;
    public buttonPrimaryColor: string;
    public buttonTextColor: string;
    public permissionActions = PermissionActions;

    //comment feature
    public allowComment: number;
    public isUploadAllowed: number;
    public compat = 0;
    public isFileEditAllowed: number;
    public isCwdShared: number;

    constructor(
        private CompatService: CompatService,
        private userService: UserService,
        private LinkPathList: LinkFileListService,
        private apiService: ApiService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private buildTransferItemService: BuildTransferItemService,
        private fileItemService: FileItemService,
        private transferService: TransferService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    async ngOnInit() {
        this.isAuth = this.userService.isAuthenticated();
        this.user = this.userService.getUser();

        //white label attributes
        this.subscription = this.LinkPathList.getSubscription().subscribe(
            async (data) => {
                if (data.loaded && data.sorted) {
                    this.isUploadAllowed = data.upload;
                    this.compat = data.compat;
                    this.isFileEditAllowed = data.file_edit;
                    this.isCwdShared = data.cwd.is_shared;
                    if (data.image_cachekey) {
                        this.isWhiteLabel = true;
                        this.linkTextColor = data.link_text_color;
                        this.buttonPrimaryColor = data.button_primary_color;
                        this.buttonTextColor = data.button_text_color;
                    }

                    //comment feature: show comment block if allow_comment = 1 or 2
                    if (data.allow_comment) {
                        this.allowComment = data.allow_comment;
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.buildTransferItemService.view.single = undefined;
        this.transferItem = undefined;
    }

    bypassSecurityTrustUrl(url) {
        if (url) {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }
        return null;
    }

    hasPermission(permissionKey) {
        return (this.userService.getPermssionByKey(permissionKey));
    }

    public isOpenable() {
        if (this.item.context == 'link' && (this.item.previewonly == 8 || !this.compat)) {
            return false;
        }
        if (
            this.item.context == 'link' &&
            this.fileItemService.isStreamable(this.item.kind)
        ) {
            return false;
        }
        if (
            this.item.context == 'link' &&
            getFileExt(this.item.name) == 'pdf' &&
            !this.item.link_is_business
        ) {
            return false;
        }
        if (this.isMSOffice()) {
            return false;
        }

        if (!canBrowserOpen(this.item.name)) {
            return false;
        }
        // return this.item.compat
        //     ? canLinkBrowserOpen(this.item.name)
        //     : canBrowserOpen(this.item.name);
        return true;
    }

    public async openFile() {
        if (this.item.context == 'applink') {
            this.url = this.item.compaturl;
        } else {
            this.url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_PREVIEW
            );
        }
        window.location.href = this.url;
    }

    public hasActions() {
        return (
            this.item.context == 'files' ||
            this.isOpenable() ||
            this.showDownload() ||
            this.showCompatDownload()
        );
    }

    public openLinkDialog() {
        this.CompatService.showLinkDialogPromise(
            this.item,
            this.item.is_publink ? 1 : 0
        ).then(async () => {
            const item = await this.fileItemService.format(
                await this.apiService.execute('pathget', {
                    sync_id: this.item.sync_id
                })
            );
            this.item.is_publink = item.is_publink;
        });
    }

    public downloadCompat() {
        if (this.item.context == 'applink') {
            window.location.href = this.item.compaturl_dl;
        } else {
            this.buildTransferItemService.getCompatUrl(this.item, this.buildTransferItemService.ACT_DOWNLOAD).
                then((url) => {
                    this.item.compaturl_dl = url;
                    window.location.href = this.item.compaturl_dl;
                });
        }
    }

    public async open() {
        if (this.item.compat && this.item.context == 'link') {
            const compatUrl = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_PREVIEW
            );
            window.location.href = compatUrl;
        } else if (this.item.compat && this.item.context == 'applink') {
            const compatUrl = this.item.compaturl;
            window.location.href = compatUrl;
        } else if (this.url) {
            window.location.href = this.url;
        } else {
            const tItem = await this.transferService.getFile(this.item, true);
            window.location.href = tItem.renderFile.url;
        }
    }

    public showCompatDownload() {
        if (this.item.context == 'link') {
            return false;
        } else if (this.item.size > 524288000) {
            return true;
        } else {
            return false;
        }
    }

    public showDownload() {
        if (
            (this.item.context == 'link' || this.item.context == 'applink') &&
            this.item.previewonly == 8
        ) {
            return false;
        } else {
            return true;
        }
    }

    public showOfficeView() {
        return (
            this.fileItemService.isMSOffice(this.item, 'view') &&
            this.isAuth &&
            this.user.is_office &&
            this.user.is_pro
        );
    }

    public showOfficeEdit() {
        const inLink = this.item.context !== 'files';
        if (inLink) {
            return (
                this.compat && this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared, false)
            );
        }
        return this.fileItemService.isMSOffice(this.item, 'edit') && this.isAuth;
    }

    public isMSOffice() {
        const app = this.fileItemService.getMSOfficeApp(this.item);
        if (app === 'None') {
            return false;
        }
        return true;
    }

    public newFeature() {
        return this.user.new_feature;
    }

    public openOfficeView() {
        this.router.navigate(['/file', this.item.sync_id, 'view', 'officefsv'],
            {
                queryParams: {
                    view: this.route.snapshot.queryParams['view'],
                    page: this.route.snapshot.queryParams['page']
                }
            });
    }

    public openOfficeEdit() {
        const { params, queryParams, fragment } = this.route.snapshot;

        if (this.item.context === 'link') {
            const isLinkEditAuth = this.fileItemService.isOfficeLinkEdit(this.item, this.isUploadAllowed, this.isFileEditAllowed, this.isCwdShared);

            if (isLinkEditAuth) {
                const navigationParams = ['/dl', params['cachekey'], 'view', 'officefse', this.item.sync_id];

                if (params['key']) { navigationParams.splice(2, 0, params['key']); }
                this.router.navigate(navigationParams, {
                    ...this.LinkPathList.decorateQueryParams(queryParams),
                    ...this.LinkPathList.decorateFragment(this.compat, fragment)
                });
            } else {
                this.modalService.open(DialogEditLinkAuthComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
            }
        } else if (this.item.context === 'files') {
            if (this.user.can_preview) {
                this.router.navigate(['/file', this.item.sync_id, 'view', 'officefse'], {
                    queryParams: {
                        view: queryParams['view'],
                        page: queryParams['page']
                    }
                });
            } else {
                const ref = this.modalService.open(WopiUpgradeComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: 'static',
                });
                ref.componentInstance.id = params['id'];
            }
        }
    }

    public async downloadOriginal() {
        this.item.blobtype = 'btFILE';
        if (this.item.compat && this.item.context == 'link') {
            this.url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_DOWNLOAD
            );
            window.location.href = this.url;
        } else if (this.item.compat && this.item.context == 'applink') {
            window.location.href = this.item.compaturl_dl;
        } else if (this.item.context == 'link') {
            this.transferService.queueDownload([this.item]);
        } else {
            this.url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_DOWNLOAD
            );
            window.location.href = this.url;
        }
    }

    public toggleComments() {
        this.showComments = !this.showComments;
    }
}
