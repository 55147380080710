
<div class="container-fluid container-fluid-wrap  tab-wrapper">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Account settings
            </div>
            <sync-error [errcode]="errcode"></sync-error>
            <div class="right-menu-button right-menu-button-push affix">
            </div>


            <ul class="nav nav-tabs">
                <li role="presentation">
                    <a routerLink="/account/info">Account</a>
                </li>
                <li role="presentation" class="active"><a routerLink="/account/billing">Billing</a></li>
                <li role="presentation"><a routerLink="/account/devices">Devices</a></li>
                <li role="presentation" *ngIf="user.new_feature"><a routerLink="/account/apps">Apps</a></li>
                <li role="presentation"><a routerLink="/account/security">Security</a></li>
            </ul>
        </div>
    </div>
    <div class="row content-account-summary" *ngIf="!checkPermission(permissionActions.VIEW_BILLING)">
        <div class="col-sm-12 content-box-withmenu">
            <span *ngIf="user.is_pro">Contact your Administrator for billing inquiries.</span>
            <span *ngIf="!user.is_pro">Get the most out of Sync by upgrading to Pro. <a routerLink="/account/upgrade">Upgrade now <i class="fa fa-solid fa-arrow-right"></i></a></span>
        </div>
    </div>
    <div class="row" *ngIf="checkPermission(permissionActions.VIEW_BILLING)">
        <div class="col-sm-12 content-box-withmenu content-account">
            <sync-billing-status [user]="user"></sync-billing-status>
            <hr />

            <div class="row">
                <div class="col-sm-3">Billing profile:</div>
                <div class="col-sm-9" *ngIf="checkPermission(permissionActions.MANAGE_BILLING) && !profileExists">
                    <!-- Zuora add profile -->
                    <a *ngIf="(!user.is_multi_new && !user.zuora_acct_key && user.plan_id == 1) || recordScrubbed" (click)="editProfile()" class="syncblue pull-right showhand">Add</a>
                </div>
                <div class="col-sm-9" *ngIf="zprofile && profileExists && ((user.is_multi_new && !user.zuora_acct_key) || (!user.is_multi_new && user.zuora_acct_key))">
                    <a *ngIf="checkPermission(permissionActions.MANAGE_BILLING)" (click)="editProfile()" class="syncblue pull-right showhand">Edit</a>

                    <ng-container
                        *ngIf="zprofile.name != zprofile.billToContact.firstName + ' ' + zprofile.billToContact.lastName">
                        {{zprofile.name}}<br />
                    </ng-container>

                    {{zprofile.billToContact.firstName}} {{zprofile.billToContact.lastName}}<br />
                    {{zprofile.billToContact.address1 | b64}}<br />
                    {{zprofile.billToContact.city}} {{zprofile.billToContact.state}} {{zprofile.billToContact.zipCode}} {{zprofile.billToContact.country}}<br />

                    <div *ngIf="zprofile.defaultPaymentMethod && zprofile.defaultPaymentMethod.id">
                        Payment method:
                        <span *ngIf="zprofile.defaultPaymentMethod.PaypalEmail">
                            PayPal: {{zprofile.defaultPaymentMethod.PaypalEmail}}
                        </span>
                        <span *ngIf="zprofile.defaultPaymentMethod.creditCardType">
                            {{zprofile.defaultPaymentMethod.creditCardType}}
                            {{zprofile.defaultPaymentMethod.creditCardNumber}}
                        </span>
                        <span *ngIf="zprofile.defaultPaymentMethod.paymentMethodType == 'Other'">
                            Bitcoin
                        </span>
                    </div>
                </div>
                <div class="col-sm-9" *ngIf="spinner">
                    <i class="fa fa-spin fa-spinner"></i> Loading ...
                </div>
            </div>

            <hr *ngIf="showPurchaseUsersOption && planDetails.plan_user_limit < usersLimit" />

            <div class="row" *ngIf="showPurchaseUsersOption && planDetails.plan_user_limit < usersLimit">
                <div class="col-sm-3">Purchase additonal users:</div>
                <div class="col-sm-9">
                    <a (click)="purchaseUsers()" class="syncblue pull-right showhand">Purchase</a>
                </div>
            </div>
            <hr />

            <div class="row">
                <div class="col-sm-3">Auto renew:</div>
                <div class="col-sm-9" *ngIf="!zprofile">
                    {{user.is_disableautorenew ? 'Disabled' : 'Enabled'}}
                    <a *ngIf="checkPermission(permissionActions.MANAGE_BILLING)" (click)="autoRenewDialog()"
                        class="syncblue pull-right showhand">Edit</a>
                </div>
                <div class="col-sm-9" *ngIf="zprofile">
                    {{ zprofile && zprofile.autoPay ? 'Enabled' : 'Disabled' }}

                    <span *ngIf="zprofile.defaultPaymentMethod && zprofile.defaultPaymentMethod.id">
                        <i *ngIf="zprofile.defaultPaymentMethod.paymentMethodType == 'Other'"
                            title="Bitcoin cannot modify auto renew settings."
                            class="syncblue pull-right fa fa-info-circle"></i>
                        <a *ngIf="checkPermission(permissionActions.MANAGE_BILLING) && zprofile.defaultPaymentMethod.paymentMethodType != 'Other'"
                            (click)="autoRenewDialog(zprofile)" class="syncblue pull-right showhand">Edit</a>
                    </span>
                </div>
            </div>

            <hr />


            <div class="row">
                <div class="col-sm-3">Invoice history:</div>
                <div class="col-sm-9">
                    <div *ngIf="emailSentSuccess" class="alert alert-success">
                        Your documents were sent successfully. Please allow up to 15 minutes to receive them.
                    </div>
                    <table class="table table-condensed">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody class="nopointer">
                            <tr
                                *ngIf="(!zprofile && invoices && invoices.length == 0) && (!zprofile || zprofile && zprofile.billingDocuments && !zprofile.billingDocuments.length)">
                                <td colspan="4">
                                    No invoices found.
                                </td>
                            </tr>
                            <ng-container *ngIf="zprofile && !spinner">
                                <tr *ngFor="let item of zprofile.billingDocuments">
                                    <td>{{item.number}}</td>
                                    <td>{{item.date | date:'mediumDate'}}</td>
                                    <td>
                                        <span class="subtext" [class.billing-invoice-unpaid]="item.balance > 0 && item.type === 'invoices'">
                                            {{ item.amount | currency}}
                                            {{ (item.balance === 0 && item.type === 'invoices' || item.type === 'creditmemos') ? 'Okay': 'Unpaid' }}
                                        </span>
                                        <span class="subtext" *ngIf="item.balance !== 0 && item.type === 'invoices'">
                                            - <a routerLink="/account/renew">Pay now</a>
                                        </span>
                                    </td>
                                    <td>
                                        <i *ngIf="checkPermission(permissionActions.MANAGE_BILLING)" (click)="emailInvoice(item.id)"
                                            class="showhand syncblue zmdi zmdi-email zmdi-hc-lg pull-right"></i>
                                        <!-- <a target="_blank" uiSref="sync.account.billing.invoice"  [uiParams]="{ id: item.id }">
                                        <i class="syncblue zmdi zmdi-print zmdi-hc-lg pull-right"></i>
                                    </a> -->

                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="!spinner">

                                <tr *ngFor="let item of invoices">
                                    <td>{{item.id}}</td>
                                    <td>
                                        {{item.start_servtime | date:'mediumDate'}}<br />
                                        <span class="subtext">Renews {{item.end_servtime | date:'mediumDate'}}</span>
                                    </td>
                                    <td>
                                        <span class="subcolor">{{item.total_amount | currency}} </span>
                                        <span class="subcolor" *ngIf="item.status_id == 0">Pending</span>
                                        <span class="subcolor" *ngIf="item.status_id == 1">Okay</span>
                                        <span class="subcolor" *ngIf="item.status_id == 2">Failed</span>
                                        <span class="subcolor" *ngIf="item.status_id == 10">Refunded</span>
                                    </td>
                                    <td>
                                        <a target="_blank" [routerLink]="['/account/billing/invoice', item.id]">
                                            <i class="syncblue zmdi zmdi-print zmdi-hc-lg pull-right"></i>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>