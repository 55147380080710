<div class="container panel-push">
    <div class="row">
        <div class="col-sm-2 col-md-3"></div>
        <!-- spacer column -->

        <div class="col-xs-12 col-sm-8 col-md-6">
            <!-- general errors -->
            <sync-error-display-hash
                [errors]="errors"
            ></sync-error-display-hash>

            <!-- verification code prompt when user arrives with malformed link -->
            <div [hidden]="!confirmSent" class="alert alert-info">
                We've sent an email to the person who invited you to the share
                to confirm the verification code. You'll get an email
                notification once this happens.
            </div>

            <div
                class="panel panel-default"
                *ngIf="missingPassword && !confirmSent"
            >
                <div class="panel-heading">
                    <h3 class="panel-title">
                        Join shared folder
                        <b
                            ><span
                                [innerHtml]="missingPasswordLabel | b64"
                            ></span
                        ></b>
                    </h3>
                </div>
                <div class="panel-body">
                    Enter the share verification code. This code can be found in
                    the share invitation email that was sent to you.
                    <div class="form-group">
                        <label> Enter verification code: </label>
                        <input
                            class="form-control col-sm-3"
                            type="text"
                            [(ngModel)]="wrapKey"
                        />
                    </div>
                </div>
                <div class="panel-footer text-right">
                    <span
                        (click)="shareNeedConfirm()"
                        class="syncblue showhand pull-left"
                        >No verification code?</span
                    >

                    <button
                        (click)="initializeInvite()"
                        class="btn btn-default"
                    >
                        <i
                            class="fa fa-spinner fa-spin"
                            [hidden]="!spinners.invite"
                        ></i>
                        Next step
                    </button>
                </div>
            </div>
            <!-- end verification code primpt when user arrives with malformed link -->

            <!-- join share panel -->
            <div
                class="panel panel-default"
                *ngIf="isLoaded && errors.length == 0"
            >
                <div class="panel-heading">
                    <h3 class="panel-title">Join a share folder</h3>
                </div>
                <div class="panel-body">
                    <span
                        [hidden]="!(!errors.length && inviteData.inviter_name)"
                    >
                        <!-- ctrl.inviteData.is_rejoin is not working -->
                        <b>{{ inviteData.inviter_name }}</b> has invited you to
                        join a shared folder. This folder will be added to your
                        Sync account. <br /><br />
                    </span>

                    <!-- label do not show -->
                    <!--
                  <span ng-show="ctrl.inviteData.label">
                      <span ng-bind="ctrl.inviteData.label | base64_decode"></span>
                  </span>
                  -->
                    <!-- label do not show -->

                    <!-- inviter message -->
                    <span [hidden]="!inviteData.inviter_msg">
                        <br /><br />{{ inviteData.inviter_msg }}
                    </span>

                    <!-- join share form -->
                    <form [formGroup]="sharefolder" [hidden]="errors.length">
                        <div
                            class="form-group"
                            [ngClass]="{
                                'has-error': !sharefolder.valid,
                                'has-success': sharefolder.valid
                            }"
                        >
                            <label>Folder name:</label>
                            <input
                                name="name"
                                type="text"
                                id="text-sharesinvite-folder-name"
                                required="true"
                                class="form-control"
                                placeholder="Enter a folder name..."
                                formControlName="name"
                                (change)="inviteData.name = $event.target.value"
                            />
                            <div
                                class="sync-tool-tip"
                                *ngIf="
                                    !sharefolder.controls.name.valid &&
                                    (sharefolder.controls.name.touched ||
                                        sharefolder.controls.name.dirty)
                                "
                            >
                                <i class="fas fa-exclamation-triangle"></i>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors
                                            ?.leadingSpace
                                    "
                                >
                                    Files cannot begin with a space
                                </span>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors
                                            ?.trailingChar
                                    "
                                >
                                    Files cannot end in a . or a space.
                                </span>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors
                                            ?.reserved
                                    "
                                >
                                    This name is a reserved and cannot be used.
                                </span>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors
                                            ?.illegal
                                    "
                                >
                                    The following characters are not allowed: \
                                    / : ? * &lt; &gt; &quot; |. Please enter a
                                    new name.
                                </span>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors?.exist
                                    "
                                >
                                    A folder 
                                    already exists. Please enter a new name.
                                </span>
                                <span
                                    *ngIf="
                                        sharefolder.controls.name.errors
                                            ?.required
                                    "
                                >
                                    The name cannot be blank.
                                </span>
                            </div>
                            <div
                                class="sync-tool-tip"
                                *ngIf="
                                    !sharefolder.controls.name.valid &&
                                    !(sharefolder.controls.name.touched ||
                                        sharefolder.controls.name.dirty)"
                            >
                            <span
                                    *ngIf="
                                        sharefolder.controls.name.errors?.exist
                                    "
                                >
                                An unshared version of this folder already exists. Please rename or delete this folder before trying to join this share.
                                </span>
                            </div>
                        </div>

                        <!-- end form group folder name -->

                        <!-- filemanager -->
                        <div class="form-group">
                            <label>Destination:</label>
                            <p>
                                <button
                                    class="btn btn-default"
                                    (click)="openDirPicker()"
                                >
                                    <i
                                        class="fa fa-spinner fa-spin"
                                        [class.hide]="!spinners.destination"
                                    ></i>
                                    <span [innerHtml]="destinationItem.name"
                                        >Sync Folder</span
                                    >
                                </button>
                            </p>
                        </div>
                    </form>
                    <!-- end share form -->
                </div>
                <div class="panel-footer text-right">
                    <button (click)="shareDecline()" id="decline-invitation-btn" class="btn btn-default">
                        <i
                            [ngClass]="{
                                'fa fa-spinner fa-spin': spinners.decline,
                                '': !spinners.decline
                            }"
                        ></i>
                        Decline
                    </button>
                    &nbsp;
                    <button
                        id="btn-shareinvite-join"
                        (click)="shareAccept()"
                        class="btn btn-primary"
                        [disabled]="!sharefolder.valid"
                        *ngIf="!isWhiteLabel"
                    >
                        <i
                            [ngClass]="{
                                'fa fa-spinner fa-spin': spinners.join,
                                '': !spinners.join
                            }"
                        ></i>
                        Join share {{progressText}}
                    </button>
                    <!-- custom white label -->
                    <button
                        id="btn-shareinvite-join"
                        (click)="shareAccept()"
                        class="btn"
                        [disabled]="!sharefolder.valid"
                        [ngStyle]="{
                            'background-color': buttonPrimaryColor,
                            'border-color': buttonPrimaryColor,
                            color: buttonTextColor
                        }"
                        *ngIf="isWhiteLabel"
                    >
                        <i
                            [ngClass]="{
                                'fa fa-spinner fa-spin': spinners.join,
                                '': !spinners.join
                            }"
                        ></i>
                        Join share {{progressText}}
                    </button>
                </div>
            </div>
            <!-- end join share panel -->

            <div class="col-sm-2 col-md-3"></div>
            <!-- spacer column -->
        </div>
    </div>
    <!-- row -->
</div>
<!-- container -->
