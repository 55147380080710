import { Component, OnInit, Input } from '@angular/core';
import { User, ErrCode, UserInfoSetApiOutput, BlendEvent } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { BlendService } from 'src/app/shared/services/blend.service';
@Component({
    selector: 'sync-dialog-analytics',
    templateUrl: './dialog-analytics.component.html'
})
export class DialogAnalyticsComponent implements OnInit {

    @Input() user: User;

    public status: string;
    public spinner: boolean;
    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService,
        private blendService: BlendService
    ) { }


    ngOnInit() {
        this.status = this.user.is_disableanalytics == 1 ? '1' : '0';
        console.log('dialog analytics ', this.status, ' - disableanalytics ', this.user.is_disableanalytics);
    }

    public async save() {

        this.spinner = true;
        console.log('dialog analytics ', this.status, ' - disableanalytics ', this.user.is_disableanalytics);

        try {
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
                disableanalytics: this.status,
            });
            const event_name = this.status == '0'  ? BlendEvent.ANALYTICS_OPT_IN : BlendEvent.ANALYTICS_OPT_OUT;
            this.blendService.track(event_name, {});
            this.userService.refresh();
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }
}
