<span
    *ngIf="hasActions() && !isWhiteLabel"
    [ngClass]="{'preview-menu': item.context != 'link' && item.context != 'applink'}"
>
    <ul
        class="dropdown-menu dropdown-regular"
        [ngClass]="{'right-menu-dropdown-menu-link': item.context == 'link' || item.context == 'applink', 'right-menu-dropdown-menu': item.context != 'link' && item.context != 'applink'}"
    >
        <li
            class="hidden-xs hidden-sm showhand"
            *ngIf="item.context == 'files' && hasPermission(permissionActions.SHARE_LINKS)"
        >
            <a (click)="openLinkDialog()" class="syncblue">
                <i class="fas fa-link dropdown-shim-link"></i>
                {{(item.is_publink) ? 'Manage link' : 'Share as link'}}
            </a>
        </li>
        <li *ngIf="isOpenable()" class="showhand">
            <a
                (click)="openFile()"
                target="_self"
                rel="noopener noreferrer"
                class="syncblue"
            >
                <i class="zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg"></i>
                Open
            </a>
        </li>
        <li *ngIf="showDownload()" class="showhand">
            <a
                (click)="downloadOriginal()"
                class="showhand tool syncblue"
            >
                <i class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"></i>
                Download
            </a>
        </li>
        <li
            class="hidden-xs hidden-sm showhand"
            *ngIf="item.context == 'files'"
        >
            <a
                [routerLink]="['/file', item.sync_id, 'history']"
                class="syncblue"
            >
                <i class="zmdi zmdi-time-restore zmdi-hc-fw zmdi-hc-lg"></i>
                Version history
            </a>
        </li>
        <li
            class="hidden-xs showhand"
            *ngIf="showOfficeView() && item.context != 'link' && item.context != 'applink'"
        >
            <a (click)="openOfficeView()" class="syncblue">
                <i class="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg"></i>
                View in Office
            </a>
        </li>
        <li
            class="hidden-xs showhand"
            *ngIf="showOfficeEdit() && item.context != 'applink'"
        >
            <a (click)="openOfficeEdit()" class="syncblue">
                <i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i>
                Edit in Office
            </a>
        </li>
        
    </ul>

    <div
        *ngIf="upsell"
        class="hidden-xs"
        style="clear: both; padding-top: 16px"
    >
        <div class="panel panel-default bgwhite">
            <div class="panel-body bgwhite">
                <i class="zmdi zmdi-star-circle zmdi-hc-lg syncblacklink"></i>
                <b>Upgrade to get:</b><br />

                <div style="margin: 8px 0px 8px 0px">
                    <i class="fa fa-check"></i> Document previews<br />
                </div>
                <a class="syncblue" routerLink="/account/upgrade"
                    >View file in browser <i class="fas fa-arrow-right"></i
                ></a>
            </div>
        </div>
    </div>
</span>

<!-- white label template -->
<span
    *ngIf="hasActions() && isWhiteLabel"
    [ngClass]="{'preview-menu': item.context != 'link' && item.context != 'applink'}"
>
    <ul
        class="dropdown-menu dropdown-regular"
        [ngClass]="{'right-menu-dropdown-menu-link': item.context == 'link' || item.context == 'applink', 'right-menu-dropdown-menu': item.context != 'link' && item.context != 'applink'}"
    >
        <li
            class="hidden-xs hidden-sm showhand"
            *ngIf="item.context == 'files' && hasPermission(permissionActions.SHARE_LINKS)"
        >
            <a
                (click)="openLinkDialog()"
                [style]="{'color': linkTextColor}"
            >
                <i class="fas fa-link dropdown-shim-link"></i>
                {{(item.is_publink) ? 'Manage link' : 'Share as link'}}
            </a>
        </li>
        <li *ngIf="isOpenable()" class="showhand">
            <a
                (click)="openFile()"
                target="_self"
                rel="noopener noreferrer"
                [style]="{'color': linkTextColor}"
            >
                <i class="zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg"></i>
                Open
            </a>
        </li>
        <li *ngIf="showDownload()" class="showhand">
            <a
                (click)="downloadOriginal()"
                class="showhand tool"
                [style]="{'color': linkTextColor}"
            >
                <i class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"></i>
                Download
            </a>
        </li>
        <li
            class="hidden-xs hidden-sm showhand"
            *ngIf="item.context == 'files'"
        >
            <a
                [routerLink]="['/file', item.sync_id, 'history']"
                [style]="{'color': linkTextColor}"
            >
                <i class="zmdi zmdi-time-restore zmdi-hc-fw zmdi-hc-lg"></i>
                Version history
            </a>
        </li>
        <li
            class="hidden-xs showhand"
            *ngIf="showOfficeView() && item.context != 'link' && item.context != 'applink'"
        >
            <a
                (click)="openOfficeView()"
                [style]="{'color': linkTextColor}"
            >
                <i class="zmdi zmdi-eye zmdi-hc-fw zmdi-hc-lg"></i>
                View in Office
            </a>
        </li>
        <li
            class="hidden-xs showhand"
            *ngIf="showOfficeEdit() && item.context != 'applink'"
        >
            <a
                (click)="openOfficeEdit()"
                [style]="{'color': linkTextColor}"
            >
                <i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i>
                Edit in Office
            </a>
        </li>
    </ul>

    <div
        *ngIf="upsell"
        class="hidden-xs"
        style="clear: both; padding-top: 16px"
    >
        <div class="panel panel-default bgwhite">
            <div class="panel-body bgwhite">
                <i class="zmdi zmdi-star-circle zmdi-hc-lg syncblacklink"></i>
                <b>Upgrade to get:</b><br />

                <div style="margin: 8px 0px 8px 0px">
                    <i class="fa fa-check"></i> Document previews<br />
                </div>
                <a
                    [style]="{'color': linkTextColor}"
                    routerLink="/account/upgrade"
                    >View file in browser <i class="fas fa-arrow-right"></i
                ></a>
            </div>
        </div>
    </div>
</span>
