<div class="container-fluid container-fluid-wrap tab-wrapper">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                Account settings
            </div>
            <sync-error [errcode]="error"></sync-error>
            <div class="right-menu-button right-menu-button-push affix">
            </div>

            <ul class="nav nav-tabs">
                <li role="presentation"><a routerLink="/account/info">Account</a></li>
                <li role="presentation"><a routerLink="/account/billing">Billing</a></li>
                <li role="presentation"><a routerLink="/account/devices">Devices</a></li>
                <li role="presentation" class="active"><a routerLink="/account/apps">Apps</a></li>
                <li role="presentation"><a routerLink="/account/security">Security</a></li>
            </ul>
        </div>
    </div>



    <div class="row">
        <div class="col-sm-12 content-box-withmenu">
            <table class="table list-table list-table-push">
                <thead>
                    <tr>
                        <th colspan="2">Name <span class="caret"></span></th>
                        <th>Folders</th>
                        <th>Date Linked</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="isAppsLoaded && !apps.length">
                        <td colspan="5" class="text-center linkshare-empty">
                            <h4>
                                <i class="zmdi zmdi-folder-person zmdi-hc-fw zmdi-hc-lg"></i><b>Link Apps to your account.</b>
                            </h4>
                            <div class="file-empty-text">
                                Increase your productivity by linking your favourite applications to your Sync account. Then shares files directly with those applications.
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isAppsLoaded">
                        <td colspan="5" class="text-center linkshare-empty">
                            <p>
                                <i class="fa fa-spin fa-spinner"></i>
                                Loading ...
                            </p>
                        </td>
                    </tr>
                    <ng-container *ngFor="let app of apps">
                        <tr id="app-list-item-{{app.app_id}}" (click)="openAppInfo(app)">
                            <td class="table-preview">
                                <img [src]="app.icon_link"/>
                            </td>
                            <td>
                                <a class="syncblacklink" >
                                    {{app.name}}
                                </a>
                                <div class="subtext" *ngIf="showInfo">
                                    App ID: {{app.app_id}}
                                </div>
                            </td>
                            <td>
                                {{app.num_folders}}
                            </td>
                            <td>
                                {{app.date_connected | date : 'longDate' : 'EST'}}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="table-info" *ngIf="apps">
                <i (click)="showInfo = !showInfo"
                    class="fa fa-info-circle fa-lg showhand"
                ></i>
                {{apps.length}} apps linked
            </div>

        </div> <!-- col-sm-12 affix -->
    </div> <!-- row -->


    <div style="padding-top: 24pt;">
        <button class="btn btn-default" routerLink="/apps">
            View connected folders
        </button>
        
    </div>

</div>