
import * as CoreActions from '../actions/core.actions';

export interface State {
    access_secret: string;
    access_token: string;
    meta_key: string;
    private_key: string;
    session_password: string;
    multiadmin_jwt_token: string;
}

export const initialState: State = {
    access_secret: '',
    access_token: '',
    meta_key: '',
    private_key: '',
    session_password: '',
    multiadmin_jwt_token: ''
};
export type Action = CoreActions.All;


export function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case CoreActions.CORE_SAVE_VALUE: {
            // console.log('NGRX: save ' + action.payload.key + ' = ' + action.payload.value)
            const obj = {};
            obj[action.payload.key] = action.payload.value;
            return Object.assign({}, state, obj);
        }
        case CoreActions.CORE_CLEAR:
            state = initialState;
            break;
        default:
            break;
    }
    return state;
}



export const getMeta = (state: State) => state.meta_key;
export const getPK = (state: State) => state.private_key;
