import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DispatchService } from '../../core/dispatch.service';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { FeedbackService } from '../../notifications/feedback.service';
import { ShareListService } from '../share-list.service';
import { LoggerService } from '../../core/logger.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { ShareNewService } from '../share-new.service';
import { ShareService } from '../share.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { Router } from '@angular/router';
import { UserService } from '../../core/user.service';
import { FileItemService } from 'src/app/file/file-item.service';
import { PermissionActions } from 'src/app/shared/models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'sync-share-list',
    templateUrl: './share-list.component.html',
})
export class ShareListComponent implements OnInit, OnDestroy {
    public shares: any;
    public filter = '';
    public showFilter = false;

    public isLoaded = false;

    public showInfo = false;
    public showRemoved = false;
    public predicate = 'name';
    public predicateReverse = false;
    public isTeamsPlusUser = false;

    public shareListInProgress = false;
    private shareListReloadSub: Subscription;

    public spinners = {
        confirm: 0,
        remove: false,
    };

    constructor(
        private Dispatch: DispatchService,
        private api: ApiService,
        private Base64: Base64Service,
        private feedbackService: FeedbackService,
        private shareListService: ShareListService,
        private fileItemService: FileItemService,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private shareService: ShareService,
        private shareNewService: ShareNewService,
        private broadcastService: BroadcastService,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
                this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.loadShare();

        // The notifications is running this code here.
        this.shareListReloadSub = this.broadcastService.on('event:share-list.reload').subscribe(() => {
            console.log('event reload');
            this.loadShare();
        });
    }

    ngOnDestroy() {
        if (this.shareListReloadSub) {
            this.shareListReloadSub.unsubscribe();
        }
    }

    public trackByFn(index, share) {
        return share.uniqid;
    }

    public hasShareFolderPermission() {
        return this.userService.getPermssionByKey(
            PermissionActions.SHARE_FOLDERS
        );
    }

    public async confirmUser(share: any) {
        this.spinners.confirm = share.shareId;

        const inviteKey = await this.shareNewService.sanitizeShare(
            share.share_id
        );
        await this.confirmUserApi(share, inviteKey);
        await this.loadShare();
        this.spinners.confirm = 0;
        this.feedbackService.setFeedback(
            'success',
            `An email has been sent to ${share.email} to accept the share`,
            5000
        );
    }

    /* API call for confirm user */
    public confirmUserApi(share: any, inviteKey: string): Promise<any> {
        return this.api.execute(
            'sharemailinvite',
            {
                share_id: share.share_id,
                emaillist: [share.email],
                enc_password: '',
                pm_b64: this.Base64.encode(
                    `You have been confirmed, please log in and join the share`
                ),
            },
            false
        );
    }

    private async loadShare() {
        if (this.shareListInProgress) { return; }

        this.isLoaded = false;
        const sharelist = await this.getList();
        this.shares = sharelist;
        if (this.shares.confirm) {
            this.shares.confirm.map((share) => {
                share.displayFileName = this.fileItemService.decryptName(
                    share,
                    this.shares.sharekeys
                );
                return share;
            });
        }
        this.isLoaded = true;
        const locData = this.Dispatch.getDipatchData();
        if (locData && locData.sync_id) {
            for (let i = 0, len = this.shares.active.length; i < len; i++) {
                if (this.shares.active[i].sync_id == locData.sync_id) {
                    this.shareService.openDetails(
                        this.shares.active[i].share_id
                    );
                    break;
                }
            }
            this.loggerService.info(this.shares);
            this.Dispatch.removeDispatch();
        }
        this.setPredicate(this.predicate, this.predicateReverse);
    }

    /* API call for getting share list */
    async getList() {
        try {
            this.shareListInProgress = true;
            const data = await this.api.execute('sharelist', {});
            await this.shareListService.processList(data);
            return data;
        } finally {
            this.shareListInProgress = false;
        }
    }

    openShareDetailsDailog(share, openState: string) {
        const webpath = Array.isArray(share) ? share[0] : share;
        const shareId = webpath.shareId || webpath.share_id;
        const state = !Number.isNaN(parseInt(openState, 10))
            ? parseInt(openState, 10)
            : 0;
        this.shareService.openDetails(shareId, state);
    }

    onToFiles(sync_id: number) {
        this.router.navigate(['/files', sync_id]);
    }
    onToInvite(cachekey: any) {
        this.router.navigate(['/shares/invite', cachekey]);
    }
    onToJoin(cachekey: any) {
        this.router.navigate(['/shares/rejoin'], { queryParams: { invite_id: cachekey } });
    }

    public setPredicate(field: string, predicateReverse): void {
        this.predicate = field;
        this.predicateReverse = predicateReverse;
        const isAsc = !predicateReverse;
        if (this.shares.active.length > 1) {
            this.shares.active.sort((a, b) => {
                if (isAsc) {
                    return a[field].toString().localeCompare(b[field]);
                } else {
                    return b[field].toString().localeCompare(a[field]);
                }
            });
        }
    }

    public dismissNotification(share: any) {
        const index = this.shares.pending.indexOf(share);
        const length = this.shares.pending.length;
        if (index !== -1) {
            // Set isDismissed property to true
            this.shares.pending[index].isDismissed = true;
            // Remove the share from the array
            this.shares.pending.splice(index, 1);
            this.shares = { ...this.shares }; // Update shares object to trigger change detection
        } else {
            this.loggerService.info('Share not found in pending shares array');
        }
    }

}
