<sync-header-links></sync-header-links>

<div class="container-fluid container-fluid-wrap container-fluid-wrap-preview">
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="navbar-header">
            <a class="navbar-brand" routerLink="/files">
                <i
                    class="zmdi zmdi-chevron-left zmdi-hc-2x"
                    style="margin-top: 3px"
                ></i>
            </a>
        </div>
        <div id="navbar">
            <div class="navbar-title" *ngIf="item">Error</div>
            <ul class="nav navbar-nav navbar-right">
                <li><a (click)="openContactSupport()">Help</a></li>
            </ul>
        </div>
    </nav>
    <div class="preview-wrap preview-wrap-no-overflow">
        <div class="preview-wrap-icon">
            <div class="preview-wrap-icon-inner">
                <img
                    class="preview-wrap-icon-inner-file-icon"
                    src="/images/icons/mime-unknown.svg"
                />
                <div class="preview-wrap-icon-inner-file-name">
                    Error, unable to preview this file.
                    <br />
                    {{errcode.code}} {{errcode.msg}}
                </div>
            </div>
        </div>
    </div>
</div>
