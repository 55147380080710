
<div class="modal-header">
  <button type="button" class="close" (click)="activeModal.close();">&times;</button>
  <h4 class="modal-title"><i class="fa fa-arrow-down" aria-hidden="true"></i> Download all as zip </h4>
</div>
<div class="modal-body">
  <sync-error [errcode]="errcode"></sync-error>
  <div  class="row">
      <div class="col-md-1">
          <i class="fa fa-check" style="font-size:36px;"></i>
      </div>
      <div class="col-md-6">
          Ready to Download
      </div>
      <div class="col-md-5 text-right">
          <a [href]="zipUrl" [download]="zipName" class="btn btn-primary">
              <i class="fa fa-arrow-down" aria-hidden="true"></i>
              Download now
          </a>
      </div>
  </div>
  <div *ngIf="spinner" class="row">
      <div class="col-md-1">
          <i class="fa fa-spinner fa-spin" style="font-size:36px;"></i>
      </div>
      <div class="col-md-6">
          Calculating size - please wait
      </div>
      <div class="col-md-5">
      </div>
  </div>
</div>
