import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '../../../core/notifications.service';
import { ValidateService } from '../../../core/validate.service';
import { BatchRenameService } from '../../../file-actions/batch-rename.service';
import { FileListService } from '../../../file-list/services/file-list.service';
import { FeedbackService } from '../../../notifications/feedback.service';
import { Constants } from '../../../transfer/constants';
import { BroadcastService } from '../../services/broadcast.service';

@Component({
    selector: 'sync-path-rename-form',
    templateUrl: './path-rename-form.component.html'
})
export class PathRenameFormComponent implements OnInit {
    public spinner: boolean;
    public batchView: sync.IBatchActionView;
    private pathlist: sync.IFile[];
    public originalname: string;
    public fileExtension: string;
    public extensionIndex: number;
    public maxFileLength = Constants.MAX_FILE_NAME_LEN;

    @Input('syncPathRenameForm') public item: sync.IFile; // filled out by directive

    renameform = new FormGroup({
        originalname: new FormControl('', [
            Validators.required,
            Validators.maxLength(this.maxFileLength),
            this.leadingSpace.bind(this),
            this.trailingChar.bind(this),
            this.reservedNames.bind(this),
            this.illegalCharacters.bind(this),
            this.exist.bind(this),
        ]),
    });

    constructor(
        private fileListService: FileListService,
        private feedbackService: FeedbackService,
        private validateService: ValidateService,
        private notificationsService: NotificationsService,
        private batchRenameService: BatchRenameService,
        private broadcastService: BroadcastService
    ) {}

    leadingSpace(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return fileName && fileName.charAt(0) !== ' '
            ? null
            : { leadingSpace: true };
    }

    trailingChar(control: FormControl) {
        const fileName = control.value;
        const lastChar = fileName ? fileName.charAt(fileName.length - 1) : '';
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ')
            ? null
            : { trailingChar: true };
    }
    reservedNames(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return !/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i.test(
            fileName
        ) &&
            fileName.toLowerCase() !== '.syncinfo' &&
            fileName.toLowerCase() !== 'thumbs.db' &&
            fileName.toLowerCase() !== 'desktop.ini' &&
            fileName.toLowerCase() !== 'sync.cache' &&
            fileName.toLowerCase() !== '$recycle.bin' &&
            fileName.indexOf('.~') !== 0 &&
            fileName.indexOf('~') !== 0 &&
            fileName.indexOf('~$') === -1 &&
            !/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i.test(
                fileName
            )
            ? null
            : { reserved: true };
    }

    illegalCharacters(control: FormControl) {
        const fileName = control.value;
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        return this.validateService.illegalCharacters(fileName) ? null : { 'illegal': true};
    }

    exist(control: FormControl) {
        const fileName = control.value;
        if (!this.pathlist) {
            return true;
        }
        if (this.validateService.isEmpty(fileName)) {
            return true;
        }
        const found = this.pathlist.filter((val) => {
            return val.name.toLowerCase() == fileName.toLowerCase();
        });
        return !(found && found.length > 0 && control.dirty) ? null : { exist: true };
    }

    ngOnInit() {
        this.batchView = this.batchRenameService.view;
        this.extensionIndex = this.item.name.lastIndexOf('.');
        if (this.extensionIndex > -1 && this.item.type === 'file') {
            this.originalname = this.item.name.substring(0, this.extensionIndex);
            this.fileExtension = this.item.name.substring(this.extensionIndex + 1, this.item.name.length);
        } else {
            this.originalname = this.item.name;
        }


        this.renameform.patchValue({ originalname: this.originalname });
        this.broadcastService.on('event:hide.tools').subscribe(() => {
            setTimeout(() => {
                this.reset();
            }, 0);
        });
        this.fileListService.getListSubscription().subscribe((data) => {
            if (data && data.pathlist.length) {
                this.pathlist = data.pathlist;
            }
        });
    }

    public reset() {
        this.batchView.total = 0;
        this.batchView.completed = 0;
        this.batchView.percent = 0;
        this.item.rename = false;
        this.renameform.patchValue({ originalname: null });
        this.renameform.markAsPristine();
        this.spinner = false;
    }

    public async submit() {
        try {
            if (!this.renameform.valid || !this.renameform.dirty) {
                return false;
            }
            if (this.renameform.value.originalname.toLowerCase() == this.item.name.toLowerCase()) {
                this.renameform.controls.originalname.setErrors({sameCase: true});
                this.renameform.setErrors({ 'invalid': true });
                return false;
            }
            if (this.extensionIndex > -1 && this.item.type === 'file') {
                this.renameform.controls.originalname.setValue([this.renameform.controls.originalname.value, this.fileExtension].join('.'));
            }
            this.spinner = true;
            this.notificationsService.stopNotificationLoop();

            this.item.type == 'dir'
                ? await this.batchRenameService.execute(
                      this.item.sync_id,
                      this.item.pid,
                      this.renameform.controls.originalname.value
                  )
                : await this.batchRenameService.executeFile(
                      this.item.sync_id,
                      this.item.pid,
                      this.renameform.controls.originalname.value
                  );
            this.reset();
            this.notificationsService.startNotificationLoop();

            this.broadcastService.broadcast('event:file-list.reload');
        } catch (error) {
            this.reset();
            this.feedbackService.setFeedback(
                'danger',
                `An error occurred renaming the ${this.item.type == 'dir' ? 'folder' : 'file'}`,
                10000
            );
        }
    }
}
