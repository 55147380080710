<div class="modal-header">
    <button
        (click)="closeDialog()"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" id="myModalLabel">
        {{ getActionName() | ucFirst }} {{ itemlist.length }} items to
        <b>{{ getDestination() }}</b>
    </h4>
</div>

<div class="modal-body modal-body-overflow">
    <div class="row">
        <div class="col-sm-6">
            <!-- show list of files to be moved or copied (eventually we do not want to show list, just totals) -->
            <table class="table list-table list-table-modal">
                <thead>
                    <tr>
                        <th colspan="4">Items to {{ getActionName() }}:</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- choose sync folder -->
                    <tr
                        *ngFor="
                            let item of itemlist
                                | orderBy: '[type,search_name]':false
                        "
                    >
                        <td class="table-modalchoosercheck"></td>
                        <td class="table-modalchoosericon">
                            <img
                                src="/images/icons/{{ item.cssclass }}.svg"
                                width="24"
                                height="24"
                            />
                        </td>
                        <td class="table-modalchoosername">
                            <div class="tdmore">
                                {{ item.name }}
                            </div>
                        </td>
                        <td class="table-modalchooseroptions">
                            <i
                                class="zmdi zmdi-close showhand"
                                [class.hide]="
                                    batchView.spinner == item.sync_id ||
                                    isCompleted(item.sync_id)
                                "
                                (click)="removeFromList(item)"
                            >
                            </i>

                            <span
                                [class.hide]="
                                    batchView.spinner !== item.sync_id
                                "
                            >
                                {{ batchView.percent }}%
                            </span>
                            <i
                                [class.hide]="!isCompleted(item.sync_id)"
                                class="zmdi zmdi-check"
                            ></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-sm-6">
            <!-- dir picker -->
            <sync-dir-picker
                [class.hide]="dirPickerSpinner"
                [skipShareFolders]="skipShareFolders"
                [headerTitle]="(getActionName() | ucFirst) + ' to:'"
            ></sync-dir-picker>
            <span *ngIf="dirPickerSpinner"><i class="fa fa-spin fa-spinner"></i> Loading ...</span>
        </div>
    </div>
    <!-- row -->
</div>

<div class="modal-footer">
    <button
        [disabled]="disableExecute()"
        (click)="execute()"
        class="btn btn-primary"
        id="copy-move-dialog-btn"
    >
        <i class="fa fa-spinner fa-spin" [class.hide]="!batchView.spinner"></i>
        {{ getActionName() | ucFirst }}
    </button>
    <button type="button" class="btn btn-default" (click)="closeDialog()">
        Cancel
    </button>
</div>
