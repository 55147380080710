
import * as Actions from '../actions/user-list.actions';


import { ErrCode, MultiAdminUserData } from '../shared/models';


export interface State {
    list: MultiAdminUserData[];
}

export const initialState: State = {
    list: [],
};


export function reducer(state = initialState, action: Actions.All) {
    switch (action.type) {
        case Actions.USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload
            });
        case Actions.USER_LIST_SELECT_TOGGLE:
            const origToggle = state.list.slice(0);
            for (let i = 0; i < origToggle.length; i++) {
                if (origToggle[i].user_id == action.payload.user_id) {
                    origToggle[i].active = !origToggle[i].active;
                }
            }
            return Object.assign({}, state, {
                list: origToggle
            });
        case Actions.USER_LIST_SELECT_ALL:
            const origAll = state.list.slice(0);
            for (let i = 0; i < origAll.length; i++) {
                origAll[i].active = action.payload.active;
            }
            return Object.assign({}, state, {
                list: origAll
            });
        case Actions.USER_LIST_SELECT_ALL_ACTIVE:
            const origAllActive = state.list.slice(0);
            for (let i = 0; i < origAllActive.length; i++) {
                if (origAllActive[i].user_status_str.toLowerCase() === 'active') {
                    origAllActive[i].active = true;
                } else {
                    origAllActive[i].active = false;
                }
            }
            return Object.assign({}, state, {
                list: origAllActive
            });
        case Actions.USER_LIST_SELECT_ALL_INVITED:
            const origAllInvited = state.list.slice(0);
            for (let i = 0; i < origAllInvited.length; i++) {
                if (origAllInvited[i].user_status_str.toLowerCase() !== 'active') {
                    origAllInvited[i].active = true;
                } else {
                    origAllInvited[i].active = false;
                }
            }
            return Object.assign({}, state, {
                list: origAllInvited
            });
        default:
            return state;
    }
}
