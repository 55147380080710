<div *ngIf="initialized && share.people.length > 1">
    <button
        class="btn btn-default"
        type="button"
        syncStopPropagation
        (click)="openDetails()"
    >
        Manage share folder
    </button>
    <div class="right-menu-button-info">
        <i class="zmdi zmdi-account-circle"></i>
        <span *ngIf="share.isSeeOthers == 1"
            > {{ share.people.length }} users collaborating</span
        >
    </div>
</div>
