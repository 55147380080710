export class ShareInviteData {
    name: string;
    originalFilename: string;
    inviter_name: string;
    inviter_email: string;
    inviter_msg: string;
    label: string;
    linkShareKeys: any;
    sharememberId: number;
    syncId: number;
    shareId:  number;
    shareSequence: number;
    pubkey_id: number;
    pubkey: string;
    displayName: string;
    sharekeyB64: string;
    salt: string;
    iterations: number;
}
