import { Deserializable } from './deserializable.model';

interface Option {
    key: string;
    value: string | boolean | number;
}
export class RolePermissionProps implements Deserializable<RolePermissionProps> {
    type: string;
    identity: string;
    label: string;
    required: true;
    options: Option[];
    defaultValue: string;
    order: number;

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

