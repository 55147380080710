import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { LoggerService } from '../../core/logger.service';
import { HttpClient } from '@angular/common/http';
import { TransferService } from '../../transfer/transfer.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { ApiService } from '../../core/api.service';
import { BlendEvent } from '../../shared/models';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-preview-text',
    templateUrl: './preview-text.component.html',
})
export class PreviewTextComponent implements OnInit, OnDestroy {
    public type: string; // binding from component.
    public transferItem: sync.ITransferItemDownload;
    public content: string;
    @Input('item') public item: sync.IFile;
    public spinner = false;
    public errorMsg: string;
    public url: string;
    public allowComment: 0 | 1 | 2;
    public isCommentExpanded: boolean;
    private sub: Subscription;

    constructor(
        private http: HttpClient,
        private Logger: LoggerService,
        private Transfer: TransferService,
        private LinkPathList: LinkFileListService,
        private buildTransferItemService: BuildTransferItemService,
        private apiService: ApiService,
        private blendService: BlendService,
    ) {}

    ngOnInit() {
        if (this.transferItem) {
            this.transferItem.renderFile.url = undefined;
            this.transferItem.renderFile.msblob = undefined;
            this.transferItem.renderFile.dl_ready = false;
        }

        this.reset();
        // console.log(this.item);
        if (this.item.compat && this.item.context == 'applink') {
            this.loadCompatDocApp();
        } else if (this.item.compat) {
            this.loadCompatDoc();
        } else {
            this.loadText();
        }

        this.sub = this.LinkPathList.getSubscription().subscribe((data) => {
            if (data.loaded && data.sorted) {
                this.allowComment = data.allow_comment;
            }
        });

        this.blendService.track(BlendEvent.VIEW_FILE, {
            type: 'text',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    private reset() {
        this.content = undefined;
        this.errorMsg = undefined;
        this.spinner = false;
    }

    private async loadText() {
        try {
            this.reset();
            this.spinner = true; // toggled in renderPdf
            const isPreview = true;
            const tItem = await this.Transfer.getFile(this.item, isPreview);
            if (tItem.renderFile.url) {
                this.Logger.info('loadText success for ' + this.item.sync_id);
                if (this.item.linkID) {
                    try {
                        this.Logger.info('Consume link');
                        this.consume([this.item.linkID]);
                    } catch (ex) {
                        this.Logger.e('Error consuming link ', ex);
                    }
                }
                // this.renderPdf(tItem.renderFile.url);
                this.renderText(tItem.renderFile.url);
            }
        } catch (err) {
            this.spinner = false;
            this.errorMsg = 'An error occurred loading the preview';
            this.Logger.error('loadText failed  for ' + this.item.sync_id);
            if (typeof err === 'object') {
                this.Logger.error(JSON.stringify(err));
            } else if (typeof err === 'string') {
                this.Logger.error(err);
            } else {
                this.Logger.error('Unknown error occurred');
            }
        }
    }

    public consume(links: Array<string>) {
         const publinks = [];
         for (let i = 0, len = links.length; i < len; i++) {
             publinks.push({publink_id: links[i]});
         }
         return this.apiService.execute('linkconsume', {links: publinks});
     }

    private loadCompatDocApp() {
        this.spinner = true;
        this.renderText(this.item.compaturl);
    }
    private async loadCompatDoc() {
        try {
            this.spinner = true;
            const url = await this.buildTransferItemService.getCompatUrl(
                this.item,
                this.buildTransferItemService.ACT_PREVIEW
            );
            this.Logger.info('loadCompatDoc success for ' + this.item.sync_id);
            if (this.item.linkID) {
                try {
                    this.Logger.info('Consume link');
                    this.consume([this.item.linkID]);
                } catch (ex) {
                    this.Logger.e('Error consuming link ', ex);
                }
            }
            this.renderText(url);
        } catch (err) {
            this.spinner = false;
            this.errorMsg = 'An error occurred loading the preview';
            this.Logger.error('loadCompatDoc failed  for ' + this.item.sync_id);
            if (typeof err === 'object') {
                this.Logger.error(JSON.stringify(err));
            } else if (typeof err === 'string') {
                this.Logger.error(err);
            } else {
                this.Logger.error('Unknown error occurred');
            }
        }
    }

    private async renderText(url: string) {
        try {
            this.url = url;
            const result = await this.http.get(url, {responseType: 'text'}).toPromise();
            this.content = result;
            this.spinner = false;
        } catch (err) {
            this.spinner = false;
            this.Logger.error(err);
            this.errorMsg = 'Could not load text file for preview';
        }
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }
}
