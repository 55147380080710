import { Injectable, Injector } from '@angular/core';
import { Base64Service } from '../base64.service';
import { LoggerService } from '../logger.service';
import { BrowserSupportService } from '../browser-support.service';

@Injectable({
    providedIn: 'root'
})
export class StorageMemoryService implements sync.IStorageMethod {
    public maxsize = 1024 * 1024 * 500;

    private blobtype: number;
    private CONTAINER_BLOB = 1;
    private CONTAINER_ARRAY = 2;

    private options: {[key: string]: sync.IStorageOpts} = {};
    private blobObj: {[key: string]: Blob[]} = {};
    private dataObj: {[key: string]: Array<number>} = {};

    constructor(
        private base64: Base64Service,
        private browser: BrowserSupportService,
        private log: LoggerService
    ) { }


    public init(initOpts: sync.IStorageOpts) {
        const start = Date.now();

        const key = initOpts.blobtype + '-' + initOpts.cachekey;
        this.options[key] = initOpts;
        if (this.browser.testBlob()) {
            if (this.blobObj[key]) {
                this.blobObj[key] = undefined;
                this.blobObj[key] = [];
            } else {
                this.getBlobObj(key);
            }
        } else {
            if (this.dataObj[key]) {
                this.dataObj[key] = undefined;
                this.dataObj[key] = [];
            } else {
                this.getDataObj(key);
            }
        }

        if (this.options[key].filesize > this.maxsize) {
            return Promise.reject({errcode: 3020});
        } else {
            this.log.d('  - ' + (Date.now() - start) + ' ms to initialize');
            return Promise.resolve(1);
        }
    }

    public async writeChunk(tItem: sync.ITransferItemDownload, bytearray: number[]|Uint8Array[], offset: number) {
        const key = tItem.blobtype + '-' + tItem.cachekey;
        const decStart = Date.now();

        if (this.browser.testBlob()) {
            const curBlob = this.getBlobObj(key);
            let blob: Blob;
            if (typeof bytearray === 'object' && bytearray instanceof Uint8Array) {
                blob = new Blob([bytearray], {type: 'application/octet-binary'});
            } else {
                blob = new Blob(<any>bytearray, {type: 'application/octet-binary'});
            }
            curBlob.push(blob);
        } else {
            let curData = this.getDataObj(key);
            curData = curData.concat(<number[]>bytearray);
        }
        this.log.d('  - ' + (Date.now() - decStart) + ' ms to write data');
        return Promise.resolve(1);
    }

    public async getRenderData(tItem: sync.ITransferItemDownload) {
        const data: sync.IRenderData = {
            type: '',
            url: '',
            blob: null,
            b64data: ''
        };
        const decStart = Date.now();

        const key = `${tItem.blobtype}-${tItem.cachekey}`,
            opt = this.options[key];
        if (this.browser.testBlob()) {
            const cur = this.getBlobObj(key),
                blob = new Blob(cur, { type: this.options[key].mimetype});
            data.type = 'blob';
            data.url = URL.createObjectURL(blob);
            data.blob = blob;
            if (blob.size != opt.filesize && tItem.blobtype == 'btFILE') {
                this.log.warn(`Decrypted blobsize ${blob.size} is not equal to ${opt.filesize}`);
            }
        } else {
            const cur = this.getDataObj(key),
                b64 = this.base64.encodeBits(cur);
            data.type = 'b64';
            data.url = [
                'data:',
                opt.mimetype,
                ';base64,',
                b64
            ].join('');
            data.b64data = b64;
        }
        this.log.d('  - ' + (Date.now() - decStart) + ' ms to getRenderData()');

        return Promise.resolve(data);
    }

    public exists(tItem: sync.ITransferItemDownload) {
        return Promise.reject(tItem);
    }

    public clearSpace(type: number) {
        return Promise.resolve(type);
    }

    private getBlobObj(key: string): Blob[] {
        if (this.blobObj[key] == undefined) {
            this.blobObj[key] = [];
        }
        return this.blobObj[key];
    }

    private getDataObj(key: string): Array<number> {
        if (this.dataObj[key] == undefined) {
            this.dataObj[key] = [];
        }
        return this.dataObj[key];
    }





}
