<!-- COMMENT INPUT BOX -->
<div class="comment-text-input-box" *ngIf="!comment">
    <div>
        <span *ngIf="user && !user.exists"
            ><strong>{{ user.display_name | b64 }}</strong></span
        >
    </div>
    <textarea
        *ngIf="user || commentInputBoxEnabled"
        class="comment-text-input"
        type="text"
        placeholder="enter a comment ..."
        [maxlength]="contentLength"
        minlength="1"
        [formControl]="commentCreationForm"
        (focus)="postButtonEnabled = true"
        (click)="enableAnonymousPrompt()"
    >
    </textarea>
    <sync-content-length-display
        [value]="commentCreationForm.value"
        [maxLength]="contentLength"
    ></sync-content-length-display>
    <!-- INPUT BOX BUTTONS -->
    <div *ngIf="(user || commentInputBoxEnabled) && postButtonEnabled">
        <button
            [ngClass]="
                link.image_cachekey ? 'btn-primary-custom' : 'btn-primary'
            "
            class="btn btn-sm"
            (click)="createComment()"
            [disabled]="!validContent(commentCreationForm.value)"
        >
            Post comment
        </button>
        <button
            class="btn btn-default btn-sm"
            style="margin-left: 10px"
            (click)="cancelNewComment()"
        >
            Cancel
        </button>
    </div>
    <!-- / INPUT BOX BUTTONS -->

    <!-- ANONYMOUS USER PROMPT -->
    <div *ngIf="!user && anonymousPromptEnabled" class="comment-signin-options">
        <span
            class="comment-signin-options-close pull-right showhand"
            [ngClass]="
                link.image_cachekey ? 'link-primary-custom' : 'link-primary'
            "
            (click)="hidePromptOptions()"
            >Close</span
        >
        <div class="comment-signin-options-options">
            <b>You are not signed in:</b>
        </div>
        <div>
            <a
                [href]="loginUrl + '?return_to_link=' + currentPath"
                [ngClass]="
                    link.image_cachekey ? 'link-primary-custom' : 'link-primary'
                "
            >
                <b>Sign in</b>
            </a>
            to post a comment.
        </div>

        <div
            *ngIf="link.allow_comment == 2"
            class="comment-signin-options-options"
        >
            Post
            <a
                [ngClass]="
                    link.image_cachekey ? 'link-primary-custom' : 'link-primary'
                "
                (click)="hidePromptOptions(); enableAnonymousForm()"
                >anonymously</a
            >
            (no sign in).
        </div>
    </div>
    <!-- / ANONYMOUS USER PROMPT -->

    <!-- ANONYMOUS FORM -->
    <form
        *ngIf="anonymousFormEnabled"
        [formGroup]="anonymousForm"
        (ngSubmit)="submitAnonymousForm()"
        ngNativeValidate
    >
        <span><b>Post anonymously:</b></span>
        <input
            class="comment-anonymous-form-input form-control"
            type="text"
            formControlName="name"
            placeholder="Enter your name ..."
        />
        <!-- <input class="comment-anonymous-form-input form-control" type="text" formControlName="email"
            placeholder="Enter your email ..."> -->
        <textarea
            id="comment-box"
            class="comment-text-input form-control"
            type="text"
            formControlName="content"
            placeholder="Enter a comment ..."
            [maxLength]="contentLength"
            [minLength]="1"
        ></textarea>
        <sync-content-length-display
            [value]="anonymousForm.get('content').value"
            [maxLength]="contentLength"
        ></sync-content-length-display>
        <div class="anonymous-form-buttons">
            <button
                type="submit"
                [ngClass]="
                    link.image_cachekey ? 'btn-primary-custom' : 'btn-primary'
                "
                class="btn btn-default btn-sm"
                style="margin-right: 5px"
                [disabled]="
                    anonymousForm.invalid ||
                    !validContent(anonymousForm.get('content').value) ||
                    !validContent(anonymousForm.get('name').value)
                "
            >
                Post comment
            </button>
            <button
                type="button"
                class="btn btn-default btn-sm"
                (click)="hideAnonymousForm()"
            >
                Cancel
            </button>
        </div>
    </form>
    <!-- / ANONYMOUS FORM -->
</div>

<!-- / COMMENT INPUT BOX -->
<sync-error [errcode]="errCode"></sync-error>
<!-- COMMENT OUTER BOX -->
<div *ngIf="comment" class="comment-outer-box">
    <!-- COMMENT TOP LEVEL BOX -->
    <div class="comment-top-level-box">
        <table class="comment-top-level-box-table">
            <tr>
                <!-- AVATAR FOR EXISTING COMMENT-->
                <!-- avatar with vertical line connecting replies -->
                <td
                    *ngIf="comment.replies && comment.replies.length > 0"
                    class="comment-top-level-avatar-box-with-line"
                >
                    <img
                        [src]="authorAvatar"
                        onerror="this.onerror=null;this.src='../../../images/sync-construction.png';"
                        class="img-circle comment-avatar"
                    />
                    <div class="comment-avatar-spacer"></div>
                </td>
                <!-- avatar without vertical line -->
                <td
                    *ngIf="!comment.replies || comment.replies.length == 0"
                    class="comment-top-level-avatar-box-no-line"
                >
                    <img
                        [src]="authorAvatar"
                        onerror="this.onerror=null;this.src='../../../images/sync-construction.png';"
                        class="img-circle comment-avatar"
                    />
                    <div class="comment-avatar-spacer"></div>
                </td>
                <!-- / AVATAR FOR EXISTING COMMENT -->

                <!-- COMMENT TEXT BOX AND BUTTONS -->
                <td class="comment-text-box">
                    <div class="comment-text-display" *ngIf="!editMode">
                        {{ comment.content }}
                    </div>
                    <textarea
                        *ngIf="editMode"
                        class="comment-text-input-edit"
                        [maxlength]="contentLength"
                        minlength="1"
                        [formControl]="commentEditionForm"
                        class="comment-text-input-edit"
                    >
                    </textarea>
                    <!-- COMMENT DETAIL -->
                    <sync-content-length-display
                        *ngIf="editMode"
                        [value]="commentEditionForm.value"
                        [maxLength]="contentLength"
                    ></sync-content-length-display>
                    <!-- COMMENT EDIT BUTTONS -->
                    <div class="comment-reply-input-buttons" *ngIf="editMode">
                        <button
                            [ngClass]="
                                link.image_cachekey
                                    ? 'btn-primary-custom'
                                    : 'btn-primary'
                            "
                            class="btn btn-sm"
                            (click)="updateComment()"
                            [disabled]="
                                commentEditionForm.invalid ||
                                !validContent(commentEditionForm.value)
                            "
                        >
                            Edit
                        </button>
                        <button
                            class="btn btn-default btn-sm"
                            style="margin-left: 10px"
                            (click)="cancelEditMode()"
                        >
                            Cancel
                        </button>
                    </div>
                    <!-- / COMMENT EDIT BUTTONS -->
                    <div
                        class="comment-text-box-details"
                        *ngIf="detailEnabled && user"
                    >
                        <span>Posted by: {{ comment.author_name | b64 }}</span
                        ><br />
                        <span>{{
                            comment.updated_at | date: "MMM dd, yyyy, h:mm a"
                        }}</span>

                        <span
                            *ngIf="
                                !editMode &&
                                !confirmedDelete &&
                                user.id == comment.author_id &&
                                tempSession == comment.temp_session
                            "
                        >
                            -
                            <button
                                [ngClass]="
                                    link.image_cachekey
                                        ? 'link-primary-custom'
                                        : 'link-primary'
                                "
                                class="text-only-btn"
                                (click)="editComment()"
                            >
                                Edit
                            </button>
                        </span>

                        <span
                            *ngIf="
                                !confirmedDelete &&
                                ((user.id == comment.author_id &&
                                    tempSession == comment.temp_session) ||
                                    user.id == ownerId)
                            "
                        >
                            -
                            <button
                                [ngClass]="
                                    link.image_cachekey
                                        ? 'link-primary-custom'
                                        : 'link-primary'
                                "
                                class="text-only-btn"
                                (click)="toggleConfirm()"
                            >
                                Delete
                            </button>
                        </span>

                        <span
                            *ngIf="
                                confirmedDelete &&
                                ((user.id == comment.author_id &&
                                    tempSession == comment.temp_session) ||
                                    user.id == ownerId)
                            "
                        >
                            -
                            <button
                                [ngClass]="
                                    link.image_cachekey
                                        ? 'link-primary-custom'
                                        : 'link-primary'
                                "
                                class="text-only-btn"
                                (click)="deleteComment()"
                                (blur)="toggleConfirm()"
                            >
                                Confirm
                            </button>
                        </span>

                        <span
                            *ngIf="
                                confirmedDelete &&
                                ((user.id == comment.author_id &&
                                    tempSession == comment.temp_session) ||
                                    user.id == ownerId)
                            "
                        >
                            -
                            <button
                                [ngClass]="
                                    link.image_cachekey
                                        ? 'link-primary-custom'
                                        : 'link-primary'
                                "
                                class="text-only-btn"
                                (click)="cancelConfirm()"
                            >
                                Cancel
                            </button>
                        </span>
                        <br />
                    </div>
                    <!-- / COMMENT DETAIL -->
                </td>
                <!-- / COMMENT TEXT BOX AND BUTTONS -->
            </tr>
        </table>
    </div>
    <!-- / COMMENT TOP LEVEL BOX -->

    <!-- REPLY TREE -->
    <table class="comment-replies-table">
        <tr *ngFor="let reply of comment.replies">
            <sync-reply
                [reply]="reply"
                [detailEnabled]="detailEnabled"
                [ownerId]="ownerId"
                [user]="user"
                [link]="link"
                [tempSession]="tempSession"
                (replyEditionEvent)="updateReply($event)"
                (replyDeletionEvent)="deleteReply($event)"
            ></sync-reply>
        </tr>
    </table>
    <!-- / REPLY TREE -->

    <!-- REPLY DETAIL TOGGLE BUTTONS -->
    <div class="comment-outer-box-button-bar" *ngIf="comment">
        <div class="comment-outer-box-buttons">
            <span
                [ngClass]="
                    link.image_cachekey ? 'link-primary-custom' : 'link-primary'
                "
                (click)="toggleReplyInputBox()"
                class="showhand text-only-btn"
                >Reply
            </span>
            <span
                *ngIf="user && user.exists"
                [ngClass]="
                    link.image_cachekey ? 'link-primary-custom' : 'link-primary'
                "
                (click)="toggleDetail()"
                class="showhand pull-right text-only-btn"
                >Details
            </span>
        </div>

        <!-- REPLY INPUT BOX -->
        <div
            *ngIf="
                replyInputBoxEnabled &&
                !replyAnonymousPromptEnabled &&
                !replyAnonymousFormEnabled
            "
        >
            <sync-reply
                [isInputBox]="true"
                [user]="user"
                [tempSession]="tempSession"
                [commentID]="comment._id"
                [link]="link"
                (replyCreationEvent)="createReply($event)"
                (toggleReplyInputBoxEvent)="toggleReplyInputBox()"
            ></sync-reply>
        </div>
        <!-- /REPLY INPUT BOX -->

        <!-- REPLY ANONYMOUS PROMPT -->
        <div
            *ngIf="
                replyAnonymousPromptEnabled &&
                !replyInputBoxEnabled &&
                !replyAnonymousFormEnabled
            "
            class="reply-signin-options"
        >
            <span
                class="comment-signin-options-close pull-right showhand"
                [ngClass]="
                    link.image_cachekey ? 'link-primary-custom' : 'link-primary'
                "
                (click)="hidePromptOptions()"
                >Close</span
            >
            <div class="comment-signin-options-options">
                <b>You are not signed in:</b>
            </div>
            <div>
                <a
                    [href]="loginUrl + '?return_to_link=' + currentPath"
                    [ngClass]="
                        link.image_cachekey
                            ? 'link-primary-custom'
                            : 'link-primary'
                    "
                >
                    <b>Sign in</b>
                </a>
                to post a reply.
            </div>

            <div
                *ngIf="link.allow_comment == 2"
                class="comment-signin-options-options"
            >
                Post
                <a
                    [ngClass]="
                        link.image_cachekey
                            ? 'link-primary-custom'
                            : 'link-primary'
                    "
                    (click)="hidePromptOptions(); enableRepyAnonymousForm()"
                    >anonymously</a
                >
                (no sign in).
            </div>
        </div>
        <!-- / REPLY ANONYMOUS PROMPT -->

        <!-- REPLY ANONYMOUS FORM -->
        <form
            *ngIf="
                replyAnonymousFormEnabled &&
                !replyAnonymousPromptEnabled &&
                !replyInputBoxEnabled
            "
            style="padding: 8px"
            [formGroup]="replyAnonymousForm"
            (ngSubmit)="submitReplyAnonymousForm()"
            ngNativeValidate
        >
            <span><b>Reply anonymously:</b></span>
            <input
                class="comment-anonymous-form-input form-control"
                type="text"
                formControlName="name"
                placeholder="Enter your name ..."
            />
            <!-- <input class="comment-anonymous-form-input form-control" type="text" formControlName="email"
                placeholder="Enter your email ..."> -->
            <textarea
                id="comment-box"
                class="comment-text-input form-control"
                type="text"
                formControlName="content"
                placeholder="Enter a comment ..."
            ></textarea>
            <sync-content-length-display
                [value]="replyAnonymousForm.get('content').value"
                [maxLength]="contentLength"
            ></sync-content-length-display>
            <div class="anonymous-form-buttons">
                <button
                    type="submit"
                    [ngClass]="
                        link.image_cachekey
                            ? 'btn-primary-custom'
                            : 'btn-primary'
                    "
                    class="btn btn-default btn-sm"
                    style="margin-right: 5px"
                    [disabled]="
                        replyAnonymousForm.invalid ||
                        !validContent(
                            replyAnonymousForm.get('content').value
                        ) ||
                        !validContent(replyAnonymousForm.get('name').value)
                    "
                >
                    Post reply
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-sm"
                    (click)="hideReplyAnonymousForm()"
                >
                    Cancel
                </button>
            </div>
        </form>
        <!-- / REPLY ANONYMOUS FORM -->
    </div>
    <!-- / REPLY DETAIL TOGGLE BUTTONS -->
</div>
<!-- COMMENT OUTER BOX -->
