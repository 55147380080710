import { BaseApiOutput, BaseApiInput } from './base-api.model';
import { UserRewardItem } from '../user-reward-item.model';

export class RewardsListApiOutput extends BaseApiOutput {
    referral_code: string;
    signup_referred: boolean;
    total: number;
    count: number;
    list: UserRewardItem[];

}

export class RewardsListApiInput extends BaseApiInput {
}


