import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sync-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnChanges {
  /**
  * @property {string} title
  * title of the modal
  */
  @Input() title: string;

  /**
  * @property {boolean} open
  * Flag to open modal
  * Default value is false. passing this value true will open the modal with given properties
  */
  @Input() open = false;

  /**
  * @property {boolean} showCancelButton
  * Flag to show cancel button
  * Default value is true. passing this value false will hide the cancel button from modal footer.
  */
  @Input() showCancelButton = true;

  /**
  * @property {Function} onClose
  * callback function on modal close
  */

  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  public activeModal: any;
  @Input() onClose: Function = () => { };

  constructor(
    public modalService: NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.open && changes.open.currentValue) {
      this.activeModal = this.modalService.open(this.modalContent, {
        backdropClass: 'in',
        windowClass: 'in',
        backdrop: 'static'
      });
      this.activeModal.result.then(() => {
        this.close();
      }, () => {
        this.close();
      });
    } else if (changes.open && changes.open.previousValue === true && changes.open.currentValue === false) {
      this.activeModal.close();
    }
  }

  public close() {
    this.onClose();
    this.activeModal.close();
  }

}
