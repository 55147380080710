import { Injectable } from '@angular/core';
import { EventsApiService } from './events-api.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { FileItemService } from '../file/file-item.service';
@Injectable({
    providedIn: 'root',
})
export class EventItemService {
    constructor(
        private eventsApiService: EventsApiService,
        private syncCryptService: SyncCryptService,
        private fileItemService: FileItemService
    ) {}

    public getEvent(eventId: string) {
        return this.eventsApiService.getList({ _id: eventId });
    }

    public format(event: any) {
        if (!event.items) {
            return event;
        }

        if (event.eventtype.indexOf('path-') > -1 && event.items.pathitems) {
            return this.formatPath(event);
        } else if (
            event.eventtype.indexOf('link-') > -1 &&
            event.items.enc_meta_name
        ) {
            return this.formatLink(event);
        } else if (
            event.eventtype.indexOf('share-') > -1 &&
            (event.items.meta_name || event.items.enc_meta_name)
        ) {
            return this.formatLink(event);
        } else {
            return event;
        }
    }
    public async processPathitem(path: any) {
        const encMetaName = path.enc_meta_name || path.meta_name;
        const fileSize = path.size;
        return await new Promise<void>(async (resolve, reject) => {
            if (!encMetaName) {
                resolve(path);
            } else {
                try {
                    const fname = await this.syncCryptService.filenameDecrypt(
                        encMetaName
                    );
                    path.name = fname;
                    path.file_size = this.fileItemService.bytesToSize(fileSize);
                    resolve(path);
                } catch (error) {
                    path.name = encMetaName;
                    resolve(path);
                }
            }
        });
    }

    private async formatLink(event: any) {
        const evtItem = await this.processPathitem(event.items);
        event.items = evtItem;
        return event;
    }

    private async formatPath(event: any) {
        const pathitemPromise: Promise<any>[] = [];
        for (let i = 0, len = event.items.pathitems.length; i < len; i++) {
            pathitemPromise.push(
                this.processPathitem(event.items.pathitems[i])
            );
        }
        const result = await Promise.all(pathitemPromise);
        event.items.pathitems = result;
        if (event.items.enc_meta_name) {
            const items = await this.processPathitem(event.items);
            event.items = items;
            return event;
        } else {
            return event;
        }
    }
}
