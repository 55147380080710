import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { MultiUserListApiOutput } from '../../shared/models/api/multiuserlist-api.model';
import { User, MultiUserData, ErrCode } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogMultiuserSettingsComponent } from '../dialog-multiuser-settings/dialog-multiuser-settings.component';
import { DialogMultiuserAddComponent } from '../dialog-multiuser-add/dialog-multiuser-add.component';
import { Router } from '@angular/router';

@Component({
    selector: 'sync-user-admin',
    templateUrl: './user-admin.component.html'
})
export class UserAdminComponent implements OnInit {
    public limit: number;
    public currentList: MultiUserData[] = [];
    public lastSyncTime: number;
    public user: User;
    public errcode: ErrCode;

    constructor(
        private api: ApiService,
        private modalService: NgbModal,
        private userService: UserService,
        private router: Router
    ) { }

    ngOnInit() {
        this.getList();
        this.user = this.userService.getUser();
    }

    public  async getList() {
        try {
            const result = await this.api.execute<MultiUserListApiOutput>('multiuserlist', {});
            this.currentList = result.list;
            this.limit = result.limit;
            this.lastSyncTime = result.last_filesync_time;
        } catch (ex) {
            console.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }

        // return this.MultiUserApi.getList()
        //     .then((data) => this.process(data));
    }
    public hasEmptySlots() {
        return (this.getAmount() < this.limit);
    }

    public getAmount() {
        return this.currentList.length + 1;
    }

    public openSettings(child: MultiUserData, openState: number) {
        const ref = this.modalService.open(DialogMultiuserSettingsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.child = child;
        ref.componentInstance.openState = openState;

        ref.result.then((result) => {
            if (result === true) {
                this.getList();
            }
        });
    }


    public addUser() {

        if (this.limit > 1) {
            const ref = this.modalService.open(DialogMultiuserAddComponent , {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static',
            });
            ref.componentInstance.hasEmptySlots = this.hasEmptySlots();
            ref.result.then((result) => {
                if (result === true) {
                    this.getList();
                }
            });
        } else {
            this.router.navigate(['/account/upgrade']);
        }
    }

}
