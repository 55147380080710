<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="modalInstance.close()"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Remove user access to {{ shareData.name }}</h4>
</div>
<div class="modal-body" *ngIf="removeMembers.length == 0">
    <div class="alert alert-danger">Error, no users selected</div>
</div>
<div class="modal-body" *ngIf="removeMembers.length > 0">
    <div class="row">
        <sync-error-display-hash [errors]="errors"></sync-error-display-hash>
        <div class="col-sm-12">
            If you remove
            <strong>
                <span *ngFor="let member of removeMembers">
                    {{ member.displayname }},
                </span>
            </strong>
            they won't be able to see future changes to this shared folder.
        </div>
    </div>

    <hr />
    <div class="row">
        <div class="col-sm-4">Remote wipe:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="selectedPurge"
                        [(ngModel)]="selectedPurge"
                        id="sharemember-remove-purge-true"
                        value="1"
                    />
                    Yes
                </label>
            </div>

            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="selectedPurge"
                        [(ngModel)]="selectedPurge"
                        id="sharemember-remove-purge-false"
                        value="0"
                    />
                    No (let user keep a copy of this shared folder)
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="spinner"
        (click)="removeSelectedMembers()"
    >
        <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
        Remove
    </button>
    <button
        type="button"
        class="btn btn-default"
        (click)="modalInstance.close()"
    >
        Cancel
    </button>
</div>
