import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class UserPasswordForgotListApiOutput extends BaseApiOutput {
    has_passwordhint: number;
    has_passwordreset: number;

}

export class UserPasswordForgotListApiInput extends BaseApiInput {
    email_b64: string;
}


