
import { Action } from '@ngrx/store';

import { ErrCode, LinkListItem } from '../shared/models';

export const RESET = '[LinkList] Reset';
export const REFRESH = '[LinkList] Refresh';
export const ERROR = '[LinkList] Error';
export const SUCCESS = '[LinkList] Success';
export const SORT = '[LinkList] Sort';

export class LinkListSuccessAction implements Action {
    readonly type = SUCCESS;
    constructor(public payload: LinkListItem[]) {}
}

export class LinkListResetAction implements Action {
    readonly type = RESET;
    constructor() {}
}

export class LinkListRefreshAction implements Action {
    readonly type = REFRESH;
    constructor() {}
}

export class LinkListErrorAction implements Action {
    readonly type = ERROR;
    constructor(public payload: ErrCode) {}
}

export class LinkListSortAction implements Action {
    readonly type = SORT;
    constructor(public payload: any) {}
}

export type All = LinkListRefreshAction
    | LinkListResetAction
    | LinkListSuccessAction
    | LinkListErrorAction
    | LinkListSortAction;


