<sync-header-previews [item]="item">
    <div class="hamburger-menu-item visible-xs" hamburgerMenu>
        <sync-preview-menu
            [item]="item"
            [url]="url"
        ></sync-preview-menu>
    </div>
</sync-header-previews>

<!-- CP TEMPLATE -->
<div
    *ngIf="item.context != 'link' && item.context != 'applink'"
    class="container-fluid container-fluid-wrap container-fluid-wrap-preview"
>
    <sync-preview-menu
        [upsell]="upsell"
        [item]="item"
        [url]="url"
    ></sync-preview-menu>
    <div class="preview-wrap preview-wrap-no-overflow">
        <div class="preview-wrap-icon" style="padding-bottom: 80px">
            <div class="preview-wrap-icon-inner">
                <img
                    class="preview-wrap-icon-inner-file-icon"
                    src="/images/icons/{{item.filetype.cssclass}}.svg"
                />
                <div class="preview-wrap-icon-inner-file-name">
                    {{item.name}} - {{item.size | bytesToSize }}
                </div>
                <a
                    *ngIf="isOpenable() && !isWhiteLabel"
                    (click)="openFile()"
                    target="_self"
                    rel="noopener noreferrer"
                    [class.disabled]="openSpinner"
                    type="button"
                    class="btn btn-primary btn-lg hidden-xs hidden-sm"
                >
                    <i
                        [ngClass]="{
                        'zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg' : !openSpinner,
                        'fa fa-spin fa-spinner': openSpinner}"
                    ></i>
                    Open
                </a>
                <!-- custom white label color -->
                <a
                    *ngIf="isOpenable() && isWhiteLabel"
                    (click)="openFile()"
                    target="_self"
                    rel="noopener noreferrer"
                    [class.disabled]="openSpinner"
                    type="button"
                    class="btn btn-lg hidden-xs hidden-sm"
                    [ngStyle]="{'background-color': buttonPrimaryColor,
                                'border-color': buttonPrimaryColor,
                                'color': buttonTextColor}"
                >
                    <i
                        [ngClass]="{
                        'zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg' : !openSpinner,
                        'fa fa-spin fa-spinner': openSpinner}"
                    ></i>
                    Open
                </a>
                <button
                    *ngIf="!item.previewonly && !isWhiteLabel"
                    (click)="downloadCompat()"
                    type="button"
                    class="btn btn-primary btn-lg hidden-xs hidden-sm"
                >
                    <i
                        class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"
                    ></i>
                    Download
                </button>
                <!-- custom white label color -->
                <button
                    *ngIf="!item.previewonly && isWhiteLabel"
                    (click)="downloadCompat()"
                    type="button"
                    class="btn btn-lg hidden-xs hidden-sm"
                    [ngStyle]="{'background-color': buttonPrimaryColor,
                                'border-color': buttonPrimaryColor,
                                'color': buttonTextColor}"
                >
                    <i
                        class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"
                    ></i>
                    Download
                </button>
                <div *ngIf="notice > 0">
                    <hr />
                    File is too large to preview.
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /CP TEMPLATE -->

<!-- LINK CONSUME TEMPLATE -->
<div
    *ngIf="item.context == 'link' || item.context == 'applink'"
    class="container-fluid preview-container"
>
    <div class="row">
        <!-- MAIN CONTENT COL -->
        <div class="col-md-8 col-lg-9" style="padding-right: 0px">
            <div class="preview-wrap-icon" style="padding-bottom: 80px">
                <div class="preview-wrap-icon-inner">
                    <img
                        class="preview-wrap-icon-inner-file-icon"
                        src="/images/icons/{{item.filetype.cssclass}}.svg"
                    />
                    <div class="preview-wrap-icon-inner-file-name">
                        {{item.name}} - {{item.size | bytesToSize }}
                    </div>
                    <a
                        *ngIf="isOpenable() && !isWhiteLabel"
                        (click)="openFile()"
                        target="_self"
                        rel="noopener noreferrer"
                        [class.disabled]="openSpinner"
                        type="button"
                        class="btn btn-primary btn-lg hidden-xs hidden-sm"
                    >
                        <i
                            [ngClass]="{
                            'zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg' : !openSpinner,
                            'fa fa-spin fa-spinner': openSpinner}"
                        ></i>
                        Open
                    </a>
                    <!-- custom white label color -->
                    <a
                        *ngIf="isOpenable() && isWhiteLabel"
                        (click)="openFile()"
                        target="_self"
                        rel="noopener noreferrer"
                        [class.disabled]="openSpinner"
                        type="button"
                        class="btn btn-lg hidden-xs hidden-sm"
                        [ngStyle]="{'background-color': buttonPrimaryColor,
                                    'border-color': buttonPrimaryColor,
                                    'color': buttonTextColor}"
                    >
                        <i
                            [ngClass]="{
                            'zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg' : !openSpinner,
                            'fa fa-spin fa-spinner': openSpinner}"
                        ></i>
                        Open
                    </a>
                    <button
                        *ngIf="!item.previewonly && !isWhiteLabel"
                        (click)="download()"
                        type="button"
                        class="btn btn-primary btn-lg hidden-xs hidden-sm"
                    >
                        <i
                            class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"
                        ></i>
                        Download
                    </button>
                    <!-- custom white label color -->
                    <button
                        *ngIf="!item.previewonly && isWhiteLabel"
                        (click)="download()"
                        type="button"
                        class="btn btn-lg hidden-xs hidden-sm"
                        [ngStyle]="{'background-color': buttonPrimaryColor,
                                    'border-color': buttonPrimaryColor,
                                    'color': buttonTextColor}"
                    >
                        <i
                            class="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg"
                        ></i>
                        Download
                    </button>
                    <div *ngIf="notice > 0">
                        <hr />
                        File is too large to preview.
                    </div>
                    <div *ngIf="!compat">
                        <hr />
                        Encrypted file. No preview.
                    </div>
                </div>
            </div>
        </div>
        <!-- / MAIN CONTENT COL -->

        <div class="col-md-4 col-lg-3 xs-fixed-comment" 
            [ngClass]="isCommentExpanded ? 'expanded' : ''">
            <sync-preview-menu
                [upsell]="upsell"
                [item]="item"
                [url]="url"
                class="hidden-xs"
            ></sync-preview-menu>
            <!-- comment feature -->
            <sync-comment-block
                *ngIf="allowComment"
                [item]="item"
                [ontoggle]="onCommentToggle"
            ></sync-comment-block>
        </div>
    </div>
</div>
<!-- /LINK CONSUME TEMPLATE -->
