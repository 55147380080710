import { BaseApiOutput, BaseApiInput } from './base-api.model';
import { MultiUserData } from '../multi-user-data.model';

export class MultiUserListApiOutput extends BaseApiOutput {
    limit: number;
    list: MultiUserData[];
    last_filesync_time: number;
}

export class MultiUserListApiInput extends BaseApiInput {
    cmd = 'multiuserlist';
}


// export class MultiUserListApi {
//     cmd = 'multiuserlist';

//     constructor() {
//     }

// }

