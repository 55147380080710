import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { FileItemService } from '../../file/file-item.service';
import { FileSelectionService } from '../services/file-selection.service';
import { DialogShareAppListComponent } from '../../shares/dialog-share-app-list/dialog-share-app-list.component';
import { ShareService } from '../../shares/share.service';
import { DialogCopyComponent } from '../../file-actions/dialog-copy/dialog-copy.component';
import { DialogPurgeComponent } from '../../file-actions/dialog-purge/dialog-purge.component';
import { DialogDeleteComponent } from '../../file-actions/dialog-delete/dialog-delete.component';
import { DialogRestoreComponent } from '../../file-actions/dialog-restore/dialog-restore.component';
import { noop } from 'rxjs';
import { canBrowserOpen, getFileExt } from '../../shared/func';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/api.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { DialogFileZipComponent } from '../dialog-file-zip/dialog-file-zip.component';
import { FileListService } from '../services/file-list.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { BlendService } from '../../shared/services/blend.service';
import { BlendEvent, PermissionActions } from '../../shared/models';
import { SkuService } from '../../shared/services/sku.service';
import { FeedbackService } from 'src/app/notifications/feedback.service';
import { StarredListService } from '../services/starred-list.service';

@Component({
    selector: 'sync-file-list-menu',
    templateUrl: './file-list-menu.component.html'
})
export class FileListMenuComponent implements OnInit {
    public selectedParams: sync.IFileSelectParams = {
        allDeleted: true,
        allNotDeleted: true,
        allShared: true,
        allViewable: true,
        allRootShare: true,
        allLink: true,
        allWritable: true,
        allOwned: true,
        isMultiUserChild: false,
        dirAmt: 0,
        fileAmt: 0,
    };
    public is_tagEnabled = false;
    private selected: sync.IFile[];
    public isAuth: boolean;
    @Input() dropDown: NgbDropdown;
    @Input() public showRightMenu = false;
    public isFreeUser = false;
    public total_favorite = 0;

    private checkFuncs: { [func: string]: Function } = {
        isDirs: this.isDirs,
        isFiles: this.isFiles,
        isLink: this.isLink,
        isNotLink: this.isNotLink,
        isViewable: this.isViewable,
        isOpenable: this.isOpenable,
        isSingle: this.isSingle,
        isShared: this.isShared,
        isRootShare: this.isRootShare,
        isNotShared: this.isNotShared,
        isMulti: this.isMulti,
        isMultiChild: this.isMultiChild,
        isNotMultiChild: this.isNotMultiChild,
        isDeleted: this.isDeleted,
        isNotDeleted: this.isNotDeleted,
        isInvalid: this.isInvalid,
        isWritable: this.isWritable,
        isNotWritable: this.isNotWritable,
        // isOwner: isOwner,
        isMSOfficeEdit: this.isMSOfficeEdit,
        isMSOfficeView: this.isMSOfficeView,

        isAppShare: this.isAppShare,
        canMakeAppShare: this.canMakeAppShare,
        isHideShare: this.isHideShare,
        isNotHideShare: this.isNotHideShare,

        hasPurgePermission: this.hasPurgePermission,
        hasShareFolderPermission: this.hasShareFolderPermission,
        hasAppsNewFeature: this.hasAppsNewFeature,

        hasShareLinkPermission: this.hasShareLinkPermission,
    };

    constructor(
        private fileSelectionService: FileSelectionService,
        private userService: UserService,
        private modalService: NgbModal,
        private fileItemService: FileItemService,
        private shareService: ShareService,
        private buildTransferItemService: BuildTransferItemService,
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private fileListService: FileListService,
        private broadcastService: BroadcastService,
        private skuService: SkuService,
        private feedbackService: FeedbackService,
        protected SyncCrypt: SyncCryptService,
        private blendService: BlendService,
        private starredListService: StarredListService
    ) {}

    async ngOnInit() {
        this.fileListService.getListSubscription().subscribe((data) => {
            if (data.loaded && data.sorted) {
                this.total_favorite = data.total_favorite;
            }
        });
        this.isAuth = this.userService.isAuthenticated();
        this.fileSelectionService.watchSelected().subscribe((selected) => {
            this.selected = selected;
            this.initSelectedParams();
            this.selected.map((item, idx, state) => {
                if (!item.active) {
                    return;
                }
                if (item.type === 'dir') {
                    this.selectedParams.dirAmt += 1;
                } else if (item.type === 'file') {
                    this.selectedParams.fileAmt += 1;
                } else {
                }
                if (item.filetype && item.filetype.viewable !== true) {
                    this.selectedParams.allViewable = false;
                }
                if (item.is_deleted == 0) {
                    this.selectedParams.allDeleted = false;
                }
                if (item.is_deleted == 1) {
                    this.selectedParams.allNotDeleted = false;
                }
                if (item.is_publink != 1) {
                    this.selectedParams.allLink = false;
                }
                if (item.is_shared != 1 || item.is_hide_share == 1) {
                    this.selectedParams.allShared = false;
                }
                if (item.is_share_root != 1) {
                    this.selectedParams.allRootShare = false;
                }
                if (item.is_readonly == 1) {
                    this.selectedParams.allWritable = false;
                }
            });
        });
        this.isFreeUser = await this.skuService.isFreeUser();
    }

    initSelectedParams() {
        this.selectedParams.allDeleted = true;
        this.selectedParams.allDeleted = true;
        this.selectedParams.allNotDeleted = true;
        this.selectedParams.allShared = true;
        this.selectedParams.allViewable = true;
        this.selectedParams.allRootShare = true;
        this.selectedParams.allLink = true;
        this.selectedParams.allOwned = true;
        this.selectedParams.allWritable = true;
        this.selectedParams.dirAmt = 0;
        this.selectedParams.fileAmt = 0;
        this.selectedParams.isMultiUserChild =
            this.userService.get('is_multi_child');
    }

    public isVisible(filterFuncs: string[]) {
        let visible = true;
        if (
            this.selectedParams.fileAmt == 0 &&
            this.selectedParams.dirAmt == 0
        ) {
            return false;
        }
        for (let i = 0, len = filterFuncs.length; i < len; i++) {
            const func: any = this.checkFuncs[filterFuncs[i]] || noop();
            if (func.bind(this)(this.selectedParams) == false) {
                visible = false;
                break;
            }
        }

        return visible;
    }

    public async downloadCompat() {
        if (this.selected[0].context == 'applink') {
            window.location.href = this.selected[0].compaturl_dl;
        } else {
            this.buildTransferItemService.getCompatUrl(this.selected[0], this.buildTransferItemService.ACT_DOWNLOAD).
                then((url) => {
                    this.selected[0].compaturl_dl = url;
                    window.location.href = this.selected[0].compaturl_dl;
                });
        }
    }

    public async openFile() {
        if (this.isMSOfficeEdit()) {
            this.openOfficeEdit();
        } else {
            if (this.selected[0].context == 'applink') {
                window.location.href = this.selected[0].compaturl;
            } else {
                const url = await this.buildTransferItemService.getCompatUrl(
                    this.selected[0],
                    this.buildTransferItemService.ACT_PREVIEW
                );
                this.selected[0].compaturl = url;
                window.location.href = this.selected[0].compaturl;
            }
        }
    }

    private isWritable(selected: sync.IFileSelectParams) {
        return selected.allWritable;
    }
    private isNotWritable(selected: sync.IFileSelectParams) {
        return !selected.allWritable;
    }
    private isDirs(selected: sync.IFileSelectParams) {
        return selected.fileAmt === 0 && selected.dirAmt > 0;
    }
    private isFiles(selected: sync.IFileSelectParams) {
        return selected.fileAmt > 0 && selected.dirAmt == 0;
    }
    private isLink(selected: sync.IFileSelectParams) {
        return selected.allLink;
    }
    private isNotLink(selected: sync.IFileSelectParams) {
        return !selected.allLink;
    }
    private isViewable(selected: sync.IFileSelectParams) {
        const item = this.selected[0];
        if (!item) { return false; }
        return (
            this.fileItemService.isText(item) ||
            this.fileItemService.isDocument(item) ||
            this.fileItemService.isImage(item) ||
            this.fileItemService.isPDF(item)
        );
    }
    public isOpenable() {
        const item = this.selected[0];
        if (!item) { return false; }
        if (item.context == 'link' && item.previewonly == 8) {
            return false;
        }
        if (
            item.context == 'link' &&
            getFileExt(item.name) == 'pdf' &&
            !item.link_is_business
        ) {
            return false;
        }
        if (!canBrowserOpen(item.name)) {
            return false;
        }
        return true;
    }
    private isSingle(selected: sync.IFileSelectParams) {
        return selected.fileAmt + selected.dirAmt === 1;
    }
    private isShared(selected: sync.IFileSelectParams) {
        return selected.allShared;
    }
    private isNotShared(selected: sync.IFileSelectParams) {
        return !selected.allShared;
    }
    private isMulti() {
        return true;
    }
    private isMultiChild(selected: sync.IFileSelectParams): boolean {
        return selected.isMultiUserChild;
    }
    private isNotMultiChild(selected: sync.IFileSelectParams): boolean {
        return !selected.isMultiUserChild;
    }
    private isDeleted(selected: sync.IFileSelectParams) {
        return selected.allDeleted;
    }
    private isNotDeleted(selected: sync.IFileSelectParams) {
        return selected.allNotDeleted;
    }

    private isInvalid(selected: sync.IFileSelectParams) {
        return !selected.allNotDeleted && !selected.allDeleted;
    }

    private isMSOfficeEdit() {
        return (this.fileItemService.isMSOffice(this.selected[0], 'edit') && this.isAuth);
    }

    private isMSOfficeView() {
        return this.fileItemService.isMSOffice(this.selected[0], 'view');
    }

    private isAppShare() {
        return this.selected[0] && this.selected[0].is_app_share;
    }
    private canMakeAppShare() {
        return (
            this.selected[0] && !this.selected[0].is_app_share &&
            ((this.selected[0].is_shared && this.selected[0].is_share_root) ||
                !this.selected[0].is_shared)
        );
    }

    private isHideShare() {
        return this.selected[0] && this.selected[0].is_hide_share;
    }
    private isNotHideShare() {
        return this.selected[0] && !this.selected[0].is_hide_share;
    }
    // function isOwner(selected: sync.IFileSelectParams) {
    //     return selected.allOwned;
    // }

    private isRootShare(selected: sync.IFileSelectParams) {
        return selected.allRootShare;
    }

    private hasPurgePermission(selected: sync.IFileSelectParams) {
        // console.log('hasPurgePermission ' + this.userService.get('is_disablepurge'));
        return this.userService.get('is_disablepurge') == 0;
    }

    private hasShareLinkPermission(selected: sync.IFileSelectParams) {
        return (this.userService.getPermssionByKey(PermissionActions.SHARE_LINKS));
    }

    private hasShareFolderPermission() {
        return (this.userService.getPermssionByKey(PermissionActions.SHARE_FOLDERS));
    }

    private hasAppsNewFeature() {
        // return (this.userService.get('new_feature') == 1);
        return (
            this.userService.get('new_feature') == 1 &&
            this.userService.get('app_count') > 0
        );
    }

    public openAppList() {
        const ref = this.modalService.open(DialogShareAppListComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.item = this.selected[0];
    }

    public openShareDetailsDailog(share, openState: string) {
        const webpath = Array.isArray(share) ? share[0] : share;
        const shareId = webpath.shareId || webpath.share_id;
        const state =
            !Number.isNaN(parseInt(openState, 10)) ? parseInt(openState, 10) : 0;
        this.shareService.openDetails(shareId, state);
    }

    public openOfficeView() {
        this.router.navigate(['/file', this.selected[0].sync_id, 'view', 'officefsv'], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        });
    }

    public openOfficeEdit() {
        this.router.navigate(['/file', this.selected[0].sync_id, 'view', 'officefse'], {
            queryParams: {
                view: this.route.snapshot.queryParams['view'],
                page: this.route.snapshot.queryParams['page']
            }
        });
    }

    public openCopyDialog(action: string) {
        const ref = this.modalService.open(DialogCopyComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.selected = this.selected;
        ref.componentInstance.action = action;
        ref.componentInstance.shouldDuplicate = action == 'copy' && this.selected.every(item => item.type == 'file');

        if (action == 'move') {
            this.blendService.track(BlendEvent.BUTTON_CLICKED, {
                button_text: 'Move',
                num_items: this.selected.length
            });
        }
    }

    public openPurgeDialog() {
        const ref = this.modalService.open(DialogPurgeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.selected = this.selected;
    }

    public openDeleteDialog() {
        const ref = this.modalService.open(DialogDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.selected = this.selected;
    }

    public openRestoreDialog() {
        const ref = this.modalService.open(DialogRestoreComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.selected = this.selected;
    }
    public async duplicateFile() {
        const parentId = this.selected[0].pid;
        let parentName = '';
        if (this.userService.get('syncus_sync_id') != parentId) {
            if (this.userService.get('web_sync_id') == parentId) {
                parentName = 'Vault';
            } else {
                const parent: any = await this.apiService.execute('pathget', {
                    sync_id: parentId,
                });
                parentName = await this.SyncCrypt.filenameDecrypt(parent.enc_name);
            }
        }

        const ref = this.modalService.open(DialogCopyComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });

        ref.componentInstance.selected = this.selected;
        ref.componentInstance.action = 'copy';
        ref.componentInstance.shouldDuplicate = true;
        ref.componentInstance.parentDir = {
            sync_id: parentId,
            name: parentName
        };
    }

    public downloadZipFile() {
        const ref = this.modalService.open(DialogFileZipComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        this.blendService.track(BlendEvent.DOWNLOAD_AS_ZIP, {});
    }


    public async addFavoriteTag() {
        const file_extension = [];
        let file_size = 0;
        const mime_type = [];
        let isDir: Boolean;
        this.starredListService.setTotalFavorite(this.total_favorite);
        const user = this.userService.getUser();
        const sync_id_list: number[] = [];
        const pathlist = this.selected;
        try {
            if (this.isFreeUser && this.total_favorite + pathlist.length > 3) {
                this.starredListService.openAccountUpgradeDialog();
            } else {
                pathlist.map((item) => {
                    item.is_favorite = true;
                    item.is_tagEnabled = true;
                    this.is_tagEnabled = true;
                    if (item.sync_id) {
                        sync_id_list.push(item.sync_id);
                    }
                });
                await this.starredListService.addTag(sync_id_list, user.uid);
                pathlist.map((item) => {
                    item.is_tagEnabled = false;
                    this.is_tagEnabled = false;

                    file_extension.push(item.file_extension);
                    file_size += item.size;
                    const mimeType = (item.mime_type);
                    if (mime_type.indexOf(mimeType) === -1) {
                        mime_type.push(mimeType);
                    }
                    isDir = item.type == 'dir' ? true : false;
                });

                this.blendService.track(
                    BlendEvent.ADD_FAVORITE_TAG,
                    isDir
                        ? {}
                        : {
                            fileSize: this.fileItemService.bytesToSize(file_size),
                            mimeType: mime_type
                        }
                );
            }
        } catch (error) {
            this.feedbackService.setFeedback(
                'danger',
                `Failed to add star`,
                1801
            );
            this.broadcastService.broadcast('event:file-list.reload');
        }
    }

    public async removeFavoriteTag() {
        const file_extension = [];
        let file_size = 0;
        const mime_type = [];
        let isDir: Boolean;

        this.starredListService.setTotalFavorite(this.total_favorite);
        const user = this.userService.getUser();
        const sync_id_list: number[] = [],
            pathlist = this.selected;
        try {
            pathlist.map((item) => {
                item.is_favorite = false;
                item.is_tagEnabled = true;
                this.is_tagEnabled = true;
                file_extension.push(item.file_extension);
                file_size += item.size;
                const mimeType = (item.mime_type);
                if (mime_type.indexOf(mimeType) === -1) {
                    mime_type.push(mimeType);
                }
                isDir = item.type == 'dir' ? true : false;
                if (item.sync_id) {
                    sync_id_list.push(item.sync_id);
                }
            });
            await this.starredListService.removeTag(sync_id_list);
            pathlist.map((item) => {
                item.is_tagEnabled = false;
                this.is_tagEnabled = false;

            });

            this.blendService.track(
                BlendEvent.REMOVE_FAVORITE_TAG,
                isDir
                    ? {}
                    : {
                        fileSize: this.fileItemService.bytesToSize(file_size),
                        mimeType: mime_type
                    }
            );
        } catch (error) {
            this.feedbackService.setFeedback(
                'danger',
                `Failed to remove star`,
                1802
            );
            this.broadcastService.broadcast('event:file-list.reload');
        }
    }

    public isEnabled(name: string): boolean {
        const pathlist = this.selected;
        const unTagged = pathlist.find((item) => !item.is_favorite);
        const tagged = pathlist.find((item) => item.is_favorite);

        if (name == 'add-fav' && unTagged && !tagged) {
            return true;
        } else if (name == 'remove-fav' && tagged && !unTagged) {
            return true;
        }
        return false;
    }
}
