import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as UserListActions from '../../actions/user-list.actions';
import { MultiAdminUserData } from '../../shared/models';
@Injectable({
    providedIn: 'root'
})
export class UserSelectionService {
    private selected: MultiAdminUserData[] = [];
    public selectTypes = {
        active: 'active',
        invited: 'invited',
        all: 'all'
    };

    constructor(
        private store: Store<fromRoot.State>,
    ) {
        this.init();
    }

    private init() {
        this.watchSelected()
            .subscribe((result) => {
                this.selected = result;
            });
    }

    public setList(item: MultiAdminUserData[]) {
        this.selected = [];
        return this.store.dispatch(new UserListActions.UserListSuccessAction(item));
    }

    public watchSelected() {
        return this.store.select(fromRoot.getUserListSelected);
    }

    public getSelected() {
        return this.selected;
    }

    public toggle(item: MultiAdminUserData) {
        this.store.dispatch(new UserListActions.UserListSelectToggleAction(item));
    }
    public selectAll(selectType, active = false) {
        if (selectType === this.selectTypes.active) {
            this.store.dispatch(new UserListActions.UserListSelectAllActiveAction());
        } else if (selectType === this.selectTypes.invited) {
            this.store.dispatch(new UserListActions.UserListSelectAllInvitedAction());
        } else {
            this.store.dispatch(new UserListActions.UserListSelectAllAction({
                active
            }));
        }
    }
}
