import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'sync-dialog-share-details',
    templateUrl: './dialog-share-details.component.html',
})
export class DialogShareDetailsComponent implements OnInit {
    @Input() public shareData: any;
    @Input() public openState: number;
    @Input() public shareService: any;

    constructor(
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {}

    public closeDialog() {
        this.activeModal.close();
    }

    public async onStateChange(state) {
        const share = await this.shareService.getShare(this.shareData.shareId);
        this.shareData = share;
        this.openState = state;
    }
}
