import { Deserializable } from './deserializable.model';
export class EncFile implements Deserializable<EncFile> {

    public loc_id = '';
    public metaname = '';
    public name = '';

    constructor(params?: {[k: string]: any}) {
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}
