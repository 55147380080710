import { Injectable } from '@angular/core';
import { ApiService } from '../core/api.service';
import { Base64Service } from '../core/base64.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { ErrCode } from '../shared/models';
import { BaseApiOutput } from 'src/app/shared/models';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { ShareAcceptApiOutput } from '../shared/models/api/shareaccept-api.model';
import { JoblockService } from '../core/joblock.service';

@Injectable({
    providedIn: 'root',
})
export class ShareInviteService {

    public shareAcceptJob: BaseApiOutput;
    public bg: number;

    constructor(
        private apiService: ApiService,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private base64Service: Base64Service,
        private store: Store<State>,
        private joblockService: JoblockService,
    ) {}

    public async getInvite(
        inviteId: string,
        wrapKey: string,
        shareId?: number
    ): Promise<sync.IShareInviteData> {
        const data = await this.apiService.execute<any>(
            'shareinvite',
            {
                invite_id: inviteId,
                share_id: shareId,
            },
            false
        );
        return await this.process(data, wrapKey);
    }

    public async getInvitePublic(
        inviteId: string,
        wrapKey: string
    ): Promise<sync.IShareInviteData> {
        const data = await this.apiService.execute<any>('shareinvitepublic', {
            invite_id: inviteId,
        });
        return await this.process(data, wrapKey);
    }

    public decline(inviteData: sync.IShareInviteData): Promise<any> {
        return this.apiService.execute('shareaccept', {
            uniqid: inviteData.sharememberId,
            accept: 0,
        });
    }

    public async accept(
        wrapKey: string,
        syncPid: number,
        inviteData: sync.IShareInviteData
    ) {
        try {
            const encTask = wrapKey
                ? await this.syncCryptService.encLinkShareKeyListWithPubkeys(
                      wrapKey,
                      inviteData
                  )
                : await this.syncCryptService.encLinkShareKeyWithPubkeys(
                      inviteData.linkShareKeys,
                      inviteData.sharekeyB64,
                      inviteData.pubkey,
                      inviteData.pubkey_id
                  );

            this.loggerService.info(
                'accept inviteData = ' + JSON.stringify(encTask)
            );
            const encName = await this.syncCryptService.filenameEncrypt(
                inviteData.name
            );
            const shareAcceptJob = await this.apiService.execute<ShareAcceptApiOutput>('shareaccept', {
                uniqid: inviteData.sharememberId,
                enc_name: encName,
                accept: 1,
                sync_pid: syncPid,
                sharedata: encTask,
                displayname: this.base64Service.encode(inviteData.displayName),
                bg: 1
            });

            const bg = shareAcceptJob['bg'];
            if (bg == 1) {
                const response = await this.joblockService.pollJobStatus();
            }

            return shareAcceptJob;
        } catch (err) {
            this.loggerService.error('An error occurred accepting data');
            throw err;
        }
    }

    public needConfirm(inviteId: string) {
        return this.apiService.execute('shareneedconfirm', {
            invite_id: inviteId,
        });
    }

    private async process(
        data: any,
        wrapKey: string
    ): Promise<sync.IShareInviteData> {
        this.loggerService.info(`Process share invite ${data.share_key_id}`);

        const ret: sync.IShareInviteData = {
            linkShareKeys: data.link_sharekeys,
            sharekeyB64: '',
            name: '',
            originalFilename: '',
            inviter_name: this.base64Service.decode(data.inviter_name),
            inviter_email: data.inviter_email,
            inviter_msg: data.message
                ? this.base64Service.decode(data.message)
                : '',
            label: data.label,
            sharememberId: data.id,
            salt: data.salt,
            iterations: data.iterations,
            syncId: data.sync_id,
            shareId: parseInt(data.share_key_id.split('-')[0], 10),
            shareSequence: parseInt(data.share_key_id.split('-')[1], 10),
            pubkey_id: data.pubkeys.id,
            pubkey: data.pubkeys.key,
            displayName: this.base64Service.decode(data.displayname),
            //white label attributes
            image_cachekey: data.image_cachekey,
            header_primary_color: data.header_primary_color,
            header_text_color: data.header_text_color,
            button_primary_color: data.button_primary_color,
            button_text_color: data.button_text_color,
            link_text_color: data.link_text_color,
        };

        if (data.link_sharekeys) {
            try {
                if (!wrapKey) {
                    throw new ErrCode(1621, data.label);
                }
                const linkShareKey = await this.getLinkShareKey(
                    wrapKey,
                    data.salt,
                    data.iterations,
                    data.link_sharekeys[data.share_key_id]
                );
                const shareKeyB64 =
                    this.syncCryptService.bytesToB64(linkShareKey);
                try {
                    const fname = await this.syncCryptService.filenameDecrypt(
                        data.enc_share_name,
                        shareKeyB64
                    );
                    ret.sharekeyB64 = shareKeyB64;
                    ret.name = fname;
                    ret.originalFilename = fname;
                    return ret;
                } catch (err) {
                    this.loggerService.error(
                        'Could not decrypt the folder name share invite'
                    );
                    throw new ErrCode(1607);
                }
            } catch (err) {
                throw new ErrCode(1609);
            }
        } else if (data.sharekeys) {
            try {
                const sharekey = await this.syncCryptService.sharekeyDecrypt(
                    data.sharekeys[data.share_key_id],
                    data.share_key_id
                );

                try {
                    const fname = await this.syncCryptService.filenameDecrypt(
                        data.enc_share_name,
                        sharekey
                    );
                    ret.sharekeyB64 = sharekey;
                    ret.name = fname;
                    ret.originalFilename = fname;
                    return ret;
                } catch (err) {
                    this.loggerService.error(err);
                    this.loggerService.error(
                        'Share key exists, could not decrypt name'
                    );
                    this.loggerService.error(
                        'Could not decrypt the folder name share invite'
                    );
                    throw new ErrCode(1608);
                }
            } catch (err) {
                throw err;
            }
        }
    }

    private async getLinkShareKey(
        wrapKey: string,
        salt: string,
        it: number,
        encLinkShareKey: string
    ): Promise<ArrayLike<number>> {
        try {
            const sharekey = await this.getLinkShareKeyWrapper(
                wrapKey,
                salt,
                it
            );
            const res = await this.syncCryptService.linksharekeyDecrypt(
                encLinkShareKey,
                sharekey
            );
            return res;
        } catch (err) {
            this.loggerService.error('The share password is incorrect');
            this.loggerService.error('Could not decrypt link share key');
            throw new ErrCode(1620);
        }
    }

    public async getLinkShareKeyWrapper(
        wrap: string,
        salt: string,
        iterations: number
    ): Promise<string> {
        try {
            const wrapKeyBytes = await this.syncCryptService.convertToShareKey(
                wrap,
                salt,
                iterations
            );
            const sharekey = await this.syncCryptService.bytesToB64(wrapKeyBytes);
            if (!sharekey) {
                throw new ErrCode(2115);
            }
            return sharekey;
        } catch (err) {
            throw err;
        }
    }
}
