import { Injectable } from '@angular/core';
import { SyncCryptService } from './crypt/sync-crypt.service';
import { LoggerService } from './logger.service';
import { ErrCode } from '../shared/models';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DispatchService {


    constructor(
        private crypt: SyncCryptService,
        private log: LoggerService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public getDipatchData(): sync.IDispatchData {
        const locParam = this.route.snapshot.queryParams['loc'];
        if (!locParam) {
            return undefined;
        }
        let locData: sync.IDispatchData = {};
        try {
            const json = this.crypt.bytesToString(this.crypt.hexToBytes(locParam));
            locData = JSON.parse(json);
            if (!locData || !locData.action) {
                this.log.error('No action provided in location');
                return undefined;
            }
            return locData;
        } catch (ex) {
            this.log.e('An invalid string was passed to Dispatch, ignoring', ErrCode.fromException(ex));
            this.log.error('loc = ' + locParam);
            return undefined;
        }
    }

    public run() {
        // console.log('dispatch run');
        // console.log('state = ' , this.$stateParams);
        const locData: sync.IDispatchData = this.getDipatchData();
        if (!locData) {
            return;
        }
        const locTransition = {
            loc: this.route.snapshot.queryParams['loc']
        };

        const locWithPlanTransition = {
            loc: this.route.snapshot.queryParams['loc'],
            p: this.route.snapshot.queryParams['p'],
            rp: this.route.snapshot.queryParams['rp'],
            rpc: this.route.snapshot.queryParams['rpc'],
            promocode: this.route.snapshot.queryParams['promocode'],
            showform: this.route.snapshot.queryParams['showform'],
            discp: this.route.snapshot.queryParams['discp'],
            discrp: this.route.snapshot.queryParams['discrp'],
            discrpc: this.route.snapshot.queryParams['discrpc'],
            cnc: this.route.snapshot.queryParams['cnc'],
            allowtrial: this.route.snapshot.queryParams['allowtrial'],
            payrequired: this.route.snapshot.queryParams['payrequired'],
            ...(this.route.snapshot.queryParams['min_user'] ? {min_user: this.route.snapshot.queryParams['min_user']} : {}),
        };

        switch (locData.action) {
            case 'goto_events':
                // this.removeDispatch();
                this.router.navigate(['/events'], { queryParams: undefined });
                break;
            case 'default':
                // this.removeDispatch();
                this.router.navigate(['/files'], { queryParams: undefined });
                break;
            case 'goto_sync_pid':
                if (locData.sync_pid) {
                    this.router.navigate(['/files', locData.sync_pid], { queryParams: undefined });
                } else {
                    this.router.navigate(['/files'], { queryParams: undefined });
                }
                break;
            case 'manage_share':
                if (!this.router.url.includes('/shares')) {
                    this.router.navigate(['/shares'], { queryParams: locTransition });
                  }
                break;
            case 'manage_publink':
                if (!this.router.url.includes('/links')) {
                    this.router.navigate(['/links'], { queryParams: locTransition });
                  }
                break;
            case 'device_activate':
                this.router.navigate(['/account/devices'], { queryParams: locTransition });
                break;
            case 'more_space':
                this.router.navigate(['/account/upgrade'], { queryParams: locWithPlanTransition });
                break;
            default:
                this.router.navigate(['/files'], { queryParams: undefined });
        }

    }


    public removeDispatch() {
        this.router.navigate([], { relativeTo: this.route, queryParams: { loc: undefined } });
    }
}
