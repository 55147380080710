import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../core/api.service';
import { Base64Service } from '../core/base64.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { UserService } from '../core/user.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { DialogShareDetailsComponent } from './dialog-share-details/dialog-share-details.component';
import { LinkPasswordService } from '../links/services/link-password.service';
import { ShareMemberLeaveApiInput, ShareMemberLeaveApiOutput } from '../shared/models/api/sharememberleave-api.model';
import { JoblockService } from '../core/joblock.service';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    constructor(
        private apiService: ApiService,
        private base64Service: Base64Service,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private userService: UserService,
        private modalService: NgbModal,
        private broadcastService: BroadcastService,
        private linkPasswordService: LinkPasswordService,
        private jobLockService: JoblockService
    ) {}

    /**
     * @ngdoc method
     * @name  stopShare
     * @methodOf sync.service:Share
     * @description
     * Stops a share.  Only the owner can initiate this request.  Refreshes
     * the list of shares on completion.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @param {Boolean} purge Whether to purge the share
     * @return {Promise} The promise chain from the API call
     */
    public async stopShare(
        shareId: number,
        sharememberId: number,
        purge: boolean
    ): Promise<{success: number} | any> {
        try {
            const resp = await this.apiService.execute(
                'sharestop',
                {
                    sharemember_id: sharememberId,
                    share_id: shareId,
                    purge: purge,
                },
                false
            );
            this.broadcastService.broadcast('event:share-list.reload');
            return resp;
        } catch (err) {
            this.handleErr(err);
        }

    }

    /**
     * @ngdoc method
     * @name  leaveShare
     * @methodOf sync.service:Share
     * @description
     * Leaves a share voluntarily.  Initiated by a user who is not the owner
     * but no longer wants to be part of the share folder.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @return {Promise} The promise chain from the API call
     */
    public async leaveShare(shareId: number, sharememberId: number): Promise<any> {
        try {
            const input: ShareMemberLeaveApiInput = {
                share_id: shareId,
                sharemember_id: sharememberId,
                bg: 1, //use background job 1 = true, i.e. leave share button from manage share
            };
            const resp = await this.apiService.execute<ShareMemberLeaveApiOutput>('sharememberleave', input);

            const bg = resp['bg'];
            if (bg) {
                const response = await this.jobLockService.pollJobStatus();
                this.loggerService.info('Sharememberleave_JobComplete');
            }

            this.broadcastService.broadcast('event:share-list.reload');
            return true;
        } catch (err) {
            this.handleErr(err);
        }
    }

    /**
     * @ngdoc method
     * @name  getShare
     * @methodOf sync.service:Share
     * @description
     * Retrieves all data for a given share and set it on the view object
     * for binding to controllers/directives.
     * @param {Integer} shareId The share to retrieve
     * @return {Promise} The promise chain from the API call
     */
    public async getShare(shareId: number): Promise<sync.IShareData> {
        const data = await this.apiService.execute('shareget', { share_id: shareId });
        const res = await this.processShare(data);
        return res;
    }

    /**
     * @ngdoc method
     * @name  setPermission
     * @methodOf sync.service:Share
     * @description
     * Sets the label for a share
     * @param {Integer} shareId The share id
     * @param {String} label     The label in plain text
     * @returns {Promise} The promise from the API call
     */
    public async setLabel(shareId: number, label: string): Promise<any> {
        const labelB64 = this.base64Service.encode(label);
        try {
            const response = await this.apiService.execute(
                'sharelabel',
                {
                    share_id: shareId,
                    label: labelB64,
                },
                true
            );
            this.broadcastService.broadcast('event:share-list.reload');
            await this.getShare(shareId);
            return response;
        } catch (err) {
            this.handleErr(err);
        }

    }

    /**
     * @ngdoc method
     * @name  setPermission
     * @methodOf sync.service:Share
     * @description
     * Sets the permission of a share member.  Whatever the permission is,
     * it is toggled, e.g., if it was true or set, it will be false after.
     *
     * This is a network call for instant change.
     * @param {String} perm The permission to toggle.  Defined in the
     *                      Permission class of the backend api.
     * @param {Object} member The share member to set the permission on.
     * @return {Promise} The promise chain from converting a folder to share
     */
    public async setPermission(
        shareData: sync.IShareData,
        perm: string,
        member: sync.ISharememberData
    ) {
        const newVal = !member.permissions[perm] ? 1 : 0;
        this.loggerService.info(
            `Changing share permission ${perm} to new value ${newVal}`
        );

        try {
            if (['perINVITE', 'perOWNER'].includes(perm) && newVal == 1) {
                if (shareData.encPassword && member.pubkey) {
                    const pass = await this.syncCryptService.linkpasswordDecrypt(
                        shareData.encPassword
                    );
                    const newPass = await this.syncCryptService.linkpasswordEncrypt(
                        pass,
                        member.pubkey
                    );

                    const response =  await this.apiService.execute('grantinvitepermission', {
                      uniqid: member.sharememberId,
                      share_id: member.shareId,
                      enc_password: newPass
                    });

                    if (perm === 'perINVITE') { return response; }

                } else {
                    this.loggerService.error(
                        'Cannot find encPassword or member pubkey'
                    );
                    this.loggerService.error(
                        ' - encPassword = ' + shareData.encPassword
                    );
                    this.loggerService.error(' - member pubkey = ' + member.pubkey);
                    this.loggerService.error('Share ID: ' + shareData.shareId);
                    return;
                }
                // new sharememberapi set permission
            }

            return await this.apiService.execute('sharememberpermission', {
                uniqid: member.sharememberId,
                share_id: member.shareId,
                permission: perm,
                value: newVal
            });

        } catch (e) {
            return this.handleErr(e);
        }
    }


    /**
     * @ngdoc method
     * @name  resendInvite
     * @methodOf sync.service:Share
     * @description
     * Re-sends a share invite email to the  sharemember.
     * @param {Integer} shareId The share id
     * @param {Integer} sharememberId The sharemember's id
     * @return {Promise} The promise chain from the API call
     */
    public resendInvite(
        shareData: sync.IShareData,
        email: string
    ): Promise<any> {
        const inviteKeyP = shareData.encPassword
            ? this.syncCryptService.linkpasswordDecrypt(shareData.encPassword)
            : Promise.resolve('');

        return inviteKeyP.then((inviteKey) =>
            this.apiService.execute('sharemailinvite', {
              share_id: shareData.shareId,
              emaillist: [email],
              enc_password: inviteKey
          }, false)
        );
    }

    /**
     * @ngdoc method
     * @name  processMembers
     * @methodOf sync.service:Share
     * @description
     * Prepares the sharemember list for display in the CP
     * @return {Array}
     */
    public processMembers(people: Array<any>): Array<sync.ISharememberData> {
        const result: Array<sync.ISharememberData> = [];
        for (let i = 0, len = people.length; i < len; i++) {
            const p = people[i];
            result.push({
                sharememberId: p.uniqid,
                userId: p.userid,
                email: p.email,
                user_status_id: p.user_status_id, // refer to sync-core/api/lib/UserStatus
                displayname: this.base64Service.decode(p.displayname),
                status: p.status,
                pubkey: p.pubkey,
                pubkeyId: p.pubkey_id,
                shareId: p.share_id,
                permissions: p.permissions,
                isMe: p.userid == this.userService.get('id'),
                selected: false,
                num_apps: p.num_apps,
            });
        }
        return result;
    }

    public async openDetails(shareId: number, openState?: number): Promise<void> {
        const state = openState ? openState : 1;
        const ref = this.modalService.open(DialogShareDetailsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });

        ref.componentInstance.shareService = this;
        ref.componentInstance.shareData = await this.getShare(shareId);
        ref.componentInstance.openState = state;
        ref.result.then(() => {});
    }

    public async removeAppShare(syncId: number): Promise<any> {
        return await this.apiService.execute('shareintegrationremove', {
          sync_id: syncId
      });
    }

    private async processShare(data: any): Promise<sync.IShareData> {
        const share = data.share;

        if (share && share.is_owner && share.enc_password == 'NULL' && share.share_id) {
            const linkPass = await this.linkPasswordService.makeLinkPassword(24);
            const shareData = await this.syncCryptService.mkShareData(
                linkPass,
                this.userService.get('pubkey')
            );
            share.enc_password = shareData.enc_password;
            await this.apiService.execute('grantinvitepermission', {
                uniqid: share.uniqid,
                share_id: share.share_id,
                enc_password: share.enc_password
            });
        }

        const ret: sync.IShareData = {
            people: this.processMembers(share.people),
            peoplex: this.processMembers(share.peoplex),
            syncId: share.sync_id,
            encName: share.enc_name,
            label: this.base64Service.decode(share.label),
            name: '',
            inviteStatus: share.invite_status,
            isReadOnly: share.is_readonly,
            isOwner: share.is_owner,
            isSeeOthers: share.is_seeothers,
            isInvite: share.is_invite,
            isAddApp: share.is_addapp,
            shareId: share.share_id,
            shareSequence: share.share_sequence,
            sharememberId: share.uniqid,
            encPassword: share.enc_password,
            salt: share.salt,
            iterations: share.iterations,
            app_integrated: share.app_integrated,
        };
        try {
            const name = await this.syncCryptService.filenameDecrypt(
                share.enc_name
            );
            ret.name = name;
            return ret;
        } catch (error) {
            this.loggerService.error('Unable to decrypt the file name');
            this.loggerService.error(JSON.stringify(share));
            throw { code: 2023 };
        }
    }

    private handleErr(err: any): Promise<sync.IErrorCode> {
        let code: number;
        if (err.error_code) {
            code = err.error_code;
        } else if (err.code) {
            code = err.code;
        } else {
            code = 9000;
        }
        return Promise.reject({ code: code});
    }
}
