import { Component, OnInit, Input } from '@angular/core';
import { ErrCode, User } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { TwoFactorPubkeyCreateApiOutput } from '../../shared/models/api/twofactorpubkeycreate-api.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../core/user.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../auth/services/auth.service';
import { LoggerService } from '../../core/logger.service';

@Component({
    selector: 'sync-dialog-twofactor',
    templateUrl: './dialog-twofactor.component.html'
})
export class DialogTwofactorComponent implements OnInit {

    public curPass: string;
    public spinner: boolean;
    public errcode: ErrCode;
    public showGoogleAuthForm: boolean;
    public showBackupCodeDialog: boolean; //display backup code confirm dialog
    public twofakey: string;
    public verificationCode: string;
    public safeBackupCodeLink: SafeUrl;
    private backupCodeFile: string;
    private backupCodes: string[];
    public backupCodesString: string; //this is displayed in-browser for user to copy/paste
    public password: string; //plain password entered by user
    public qrdata: string;

    public twofaType;


    @Input() user: User;

    public TWOFATYPE_NONE   = '0';
    public TWOFATYPE_EMAIL  = '1';
    public TWOFATYPE_GOOGLE = '2';
    public isGoogleAuth2faDisabled = false;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private userService: UserService,
        private sanitizer: DomSanitizer,
        private auth: AuthService,
        private log: LoggerService,
    ) { }

    ngOnInit() {
        this.showBackupCodeDialog = false;
        this.backupCodesString = '';
        this.twofaType = this.user.twofa_type;
        this.isGoogleAuth2faDisabled = this.user.is_google_app_2fa_disabled === 1;
    }

    public async disable2FA() {
        try {
            await this.api.execute('twofactorupdate', {
                twofaType: this.twofaType,
                disable: 1
            });
            this.userService.refresh();
            this.activeModal.close();
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async update2FA() {
        console.log('two-factor type:', this.twofaType);
        try {
            const response = await this.api.execute('twofactorupdate', {
                twofaType: this.twofaType,
                disable: 0
            });
            this.twofaType = response['twofaType'];
            this.generateBackupCodes();
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    /**
     * call api for generating 2FA backup codes and set in-browser backup code
     */
    public async generateBackupCodes() {
        try {
            this.spinner = true;
            this.log.info('calling api to get backup codes');
            //password must be hashed before sending to api call. User cannot enter empty password to bypass this
            const hashedPass = (this.password) ? await this.auth.getHashedPassword(this.user.email, this.password) : '';
            const response = await this.api.execute('generateuserbackupcode', {password: hashedPass});

            //show download dialog for user to get the codes
            this.backupCodes = response['codes'];
            this.showBackupCodeDialog = true;
            this.backupCodes.forEach((code, idx) => {
                this.backupCodesString += code;
                if (idx != this.backupCodes.length - 1) {
                    this.backupCodesString += '\n';
                }
            });
            // this.userService.reload();
            this.userService.refresh();

        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;

    }

    /**
     * create a downloadable file containing backup codes
     */
    public downloadBackupCodes() {
        this.log.info('Creating download file');
        let text = 'Sync.com\'s back-up codes for 2-step verification:\n';
        const fileName = 'backup_codes';

        text += this.backupCodesString;

        const data = new Blob([text], {
            type: 'text/plain'
            });

        this.backupCodeFile = window.URL.createObjectURL(data);
        this.safeBackupCodeLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.backupCodeFile);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //save file for IE
            window.navigator.msSaveOrOpenBlob(data, fileName);
        } else {
            //save for other browsers: Chrome, Firefox
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = this.backupCodeFile;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
        }

        URL.revokeObjectURL(this.backupCodeFile);
        (document.getElementById('downloadCodes') as HTMLButtonElement).disabled = true;
        // this.activeModal.close(true);
    }


    public async updateGoogleAuth() {
        // console.log('update googleAuth', this.twofakey , ' - ' , this.verificationCode);
        try {
            const response = await this.api.execute('twofactorgoogleauth', {
                twofa_token: this.twofakey,
                verification_code: this.verificationCode
            });
            this.showGoogleAuthForm = false;
            this.twofaType = response['twofaType'];
            this.generateBackupCodes();
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
    }
    public async showGoogleAuth() {
        try {
            const data = await this.api.execute<TwoFactorPubkeyCreateApiOutput>('twofactorpubkeycreate', {});
// console.log('showGoogleAuth() return '  + data.twofakey);
            this.showGoogleAuthForm = true;
            this.twofakey = data.twofakey;
            this.qrdata = 'otpauth://totp/Sync' + '%20' + this.user.email + '?secret=' + data.twofakey + '&issuer=Sync';
        } catch (e) {
            this.errcode = ErrCode.fromException(e);
        }
    }

    /**
     * copy backup codes to clipboard
     *
     */
    copyCodesToClipboard() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.backupCodesString;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        const btnText = document.getElementById('clipboard').innerText;
        if (btnText == 'Copy to Clipboard') {
            document.getElementById('clipboard').innerText = 'Copied';
            (document.getElementById('clipboard') as HTMLButtonElement).disabled = true;
        } else {
            document.getElementById('clipboard').innerText = 'Copy to Clipboard';
        }
      }
}
