import { Deserializable } from './deserializable.model';
export class PubkeyData implements Deserializable<PubkeyData> {
    share_id?: number;
    share_sequence?: number;
    id: number;
    key: string;

    public deserialize(params: any) {
        Object.assign(this, params);
        return this;
    }
}
