import { Directive, HostListener } from '@angular/core';
import { TransferService } from '../../transfer/transfer.service';

@Directive({
    selector: '[syncDialogTransfer]'
})
export class DialogTransferDirective {
    constructor(private transferService: TransferService) {}

    @HostListener('click', ['$event']) click(event) {
        this.transferService.showTransfer();
    }
}
