import { Component, Injector, OnInit } from '@angular/core';
import { ErrCode } from '../../shared/models';
import { AppsService } from '../../apps/services/apps.service';
import { LoggerService } from '../../core/logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogAppInfoComponent } from '../../apps/dialog-app-info/dialog-app-info.component';

@Component({
    selector: 'sync-apps',
    templateUrl: './apps.component.html'
})
export class AppsComponent implements OnInit {
    public shares: any;

    public error: ErrCode;

    public isAppsLoaded = false;
    public showInfo = false;

    public apps: sync.IApp[] = [];

    constructor(
        private Logger: LoggerService,
        private modalService: NgbModal,
        private AppsService: AppsService
    ) { }

    async ngOnInit() {
        this.load();
    }

    public async load () {
        const resp = await this.AppsService.getApps()
        .catch((ex) => {
            this.Logger.error(ex);
            this.error = ErrCode.fromException(ex);
        });

        if (!resp || resp.success != 1) {
           this.apps = [];
           this.isAppsLoaded = true;
        } else {
            for (const app of resp.apps) {
                app.icon_link = `images/apps/${app.app_id}.svg`;
            }
            this.apps = resp.apps;
            this.isAppsLoaded = true;
        }
    }

    public openAppInfo(app: sync.IApp) {

        const ref = this.modalService.open(DialogAppInfoComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });

        ref.componentInstance.app = app;
    }
}
