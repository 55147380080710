import { of, from, Observable, throwError } from 'rxjs';
import { map, mergeMap, catchError, tap, withLatestFrom } from 'rxjs/operators';

import { Injector, Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { State, getLinkFileListState } from '../reducers';

import * as LinkFileListActions from '../actions/link-file-list.actions';
import { LinkFileListService } from '../link-consume/services/link-file-list.service';
import { LinkPathListApiInput } from '../shared/models';

@Injectable()
export class LinkFileListEffects {

    // get linkFileList(): LinkFileListService {
    //     return this.injector.get('LinkFileListService');
    // }
    // get auth(): AuthService {
    //     return this.injector.get('AuthService');
    // }
    // get user(): UserService {
    //     return this.injector.get('UserService');
    // }
    constructor(
        private injector: Injector,
        // private auth: AuthService,
        private actions$: Actions,
        private linkFileList: LinkFileListService,
        private store$: Store<State>
        // private user: UserService
    ) {
    }

    @Effect()
    load$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_LOAD),
        mergeMap((action: LinkFileListActions.LoadAction) => {

            return from(this.linkFileList.getData(
                action.payload.key,
                action.payload.publink_id,
                action.payload.sync_id,
                action.payload.passwordlock
            )).pipe(
                map(data => new LinkFileListActions.SuccessAction(data)),
                catchError((err) => of(new LinkFileListActions.ErrorAction(err)))
            );
        })
    );
    @Effect()
    loadapp$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_LOAD_APP),
        mergeMap((action: LinkFileListActions.LoadAppAction) => {

            return from(this.linkFileList.getDataExternal(
                action.payload.publink_id,
                action.payload.sync_id,
                action.payload.passwordlock
            )).pipe(
                map(data => new LinkFileListActions.SuccessAction(data)),
                catchError((err) => of(new LinkFileListActions.ErrorAction(err)))
            );
        })
    );
    @Effect()
    success$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_SUCCESS),
        withLatestFrom(this.store$.select(getLinkFileListState)),
        map(([action, storeState]) => {
            return new LinkFileListActions.SortAction({
                active: storeState.predicate,
                direction: storeState.direction,
            });
        })
    );
    @Effect()
    refresh$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_REFRESH),
        withLatestFrom(this.store$.select(getLinkFileListState)),
        map(([action, storeState]) => {
            const input = new LinkPathListApiInput();
            input.key = storeState.linkkey;
            input.sync_id = parseInt(storeState.sync_id, 10);
            input.publink_id = storeState.publink_id;
            input.passwordlock = storeState.passwordlock;

            return new LinkFileListActions.LoadAction(input);
        })
    );
    @Effect()
    cd$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_CD),
        withLatestFrom(this.store$.select(getLinkFileListState)),
        map(([action, storeState]) => {
            const input = new LinkPathListApiInput();
            input.key = storeState.linkkey;
            input.sync_id = (<LinkFileListActions.ChangeDirAction>action).payload;
            input.publink_id = storeState.publink_id;
            input.passwordlock = storeState.passwordlock;

            return new LinkFileListActions.LoadAction(input);
        })
    );
    @Effect()
    loadWhiteLabel$: Observable<Action> = this.actions$.pipe(
        ofType(LinkFileListActions.LINK_FILE_LIST_LOAD_WHITELABEL),
        mergeMap((action: LinkFileListActions.LoadWhiteLabelAction) => {

            return from(this.linkFileList.getWhiteLabel(action.payload))
            .pipe(
                map(data => new LinkFileListActions.WhiteLabelSuccessAction(data)),
                catchError((err) => of(new LinkFileListActions.ErrorAction(err)))
            );
        })
    );
}
