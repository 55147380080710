import { Injectable } from '@angular/core';
import { Base64Service } from '../core/base64.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { UserService } from '../core/user.service';

@Injectable({
    providedIn: 'root',
})
export class ShareListService {
    constructor(
        private syncCryptService: SyncCryptService,
        private loggerService: LoggerService,
        private userService: UserService,
        private base64Service: Base64Service
    ) {}


    /**
     * @todo decrypt data[type][0].enc_name values here instead of
     *       in the template.  We want to remove the decryptName filter.
     */

    public async processList(data: any) {
        for (let i = 0, len = data.active.length; i < len; i++) {
            // sharekey
            data.active[i].waitingamt = 0;
            data.active[i].name = await this.syncCryptService.filenameDecrypt(
                data.active[i].enc_name
            );
            const plist = data.active[i].people_list;
            if (plist.length) {
                let it = 0;
                for (let j = 0, plen = plist.length; j < plen; j++) {
                    if (
                        plist[j].status == 'Waiting' ||
                        plist[j].status == 'Waiting Signup' ||
                        plist[j].status == 'Confirm'
                    ) {
                        data.active[i].waitingamt += 1;
                    }
                    if (
                        plist[j].email != this.userService.get('email').toLowerCase()
                    ) {
                        it += 1;
                        data.active[i]['person' + it] = this.base64Service.decode(
                            plist[j].displayname
                        );
                    }
                }
            }
        }
        for (let i = 0, len = data.apps.length; i < len; i++) {
            // sharekey
            data.apps[i].waitingamt = 0;
            data.apps[i].name = await this.syncCryptService.filenameDecrypt(
                data.apps[i].enc_name
            );
            const plist = data.apps[i].people_list;
            if (plist.length) {
                let it = 0;
                for (let j = 0, plen = plist.length; j < plen; j++) {
                    if (
                        plist[j].status == 'Waiting' ||
                        plist[j].status == 'Waiting Signup' ||
                        plist[j].status == 'Confirm'
                    ) {
                        data.apps[i].waitingamt += 1;
                    }
                    if (
                        plist[j].email != this.userService.get('email').toLowerCase()
                    ) {
                        it += 1;
                        data.apps[i]['person' + it] = this.base64Service.decode(
                            plist[j].displayname
                        );
                    }
                }
            }
        }
        const newLeave = [];
        for (let l = 0, lenl = data.leave.length; l < lenl; l++) {
            if (data.leave[l].enc_name) {
                data.leave[l].name =
                    await this.syncCryptService.filenameDecrypt(
                        data.leave[l].enc_name
                    );
                newLeave.push(data.leave[l]);
            } else {
                this.loggerService.warn(
                    'No enc_name on share id ' +
                        data.leave[l].share_id +
                        ', skip'
                );
            }
        }
        data.leave = newLeave;
        return data;
    }
}
