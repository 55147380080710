import { Deserializable } from './deserializable.model';

export class MultiAdminUserData implements Deserializable<MultiAdminUserData> {
    user_id: number;
    job_id: number;
    email: string;
    web_sync_id: number;
    syncus_sync_id: number;
    display_name: string;
    user_status_id: number;
    user_status_str: string;
    disk_usage_bytes: number;
    disk_limit_bytes: number;
    last_filesync_time: number;
    roles: string[];
    isExpired?: boolean;
    active?: boolean;
    isLegacyToCNC?: boolean;

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

