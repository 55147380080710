import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfoSetApiOutput, ErrCode, BlendEvent } from '../../shared/models';
import { LoggerService } from '../../core/logger.service';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { BlendService } from 'src/app/shared/services/blend.service';

@Component({
    selector: 'sync-dialog-billing-profile-delete',
    templateUrl: './dialog-billing-profile-delete.component.html'
})
export class DialogBillingProfileDeleteComponent implements OnInit {

    public errcode: ErrCode;
    public loading: boolean;
    public spinner: boolean;
    public planExpiry: number;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private log: LoggerService,
        private zuora: ZuoraAccountService,
        private blendService: BlendService
    ) { }

    ngOnInit() {
        this.loadBillingProfile();
    }
    private async loadBillingProfile() {
        this.loading = true;
        try {
            const zprofile = await this.zuora.getProfile();
            if (zprofile.subscription) {
                this.planExpiry = new Date(zprofile.subscription.termEndDate).getTime();
                this.loading = false;
            } else {
                this.log.warn('This user is in zuora but does not have a valid subscription');
                this.loading = false;
            }
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
            this.log.error(`Error loading the billing profile ${JSON.stringify(ex)}`);
            this.loading = false;
        }
    }
    public async removeProfile() {
        this.spinner = true;
        try {
            await this.api.execute<UserInfoSetApiOutput>('userinfoset', {
                rm_billingprofile: 1
            });
            this.blendService.track(BlendEvent.BILLING_PROFILE_REMOVED);
            this.spinner = false;
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
            this.log.error(`Error removing billing profile ${JSON.stringify(ex)}`);
            this.spinner = false;
        }
    }

}
