import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordService } from '../../auth/services/password.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ErrCode, User } from '../../shared/models';

/**
 * Generic password confirmation dialog.
 *
 * It will return the user's plain text password when the dialog resolves.
 *
 * Usage:
 *      const ref = this.modalService.open(DialogConfirmPasswordComponent, {
 *          backdropClass: 'in',
 *          windowClass: 'in',
 *          backdrop: 'static',
 *          // size: 'lg'
 *      });
 *      const result = await ref.result;
 *
 *      if (typeof result === 'string') { // this is their password validated }
 *      if (result === false) { // they cancelled the confirmation }
 *
 */

@Component({
    selector: 'sync-dialog-confirm-password',
    templateUrl: './dialog-confirm-password.component.html',
    styleUrls: []
})
export class DialogConfirmPasswordComponent implements OnInit {

    public errcode: ErrCode = undefined;
    public password = '';
    public spinner = false;

    private user: User = undefined;

    constructor(
        public activeModal: NgbActiveModal,
        private log: LoggerService,
        private PasswordService: PasswordService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.user = this.userService.getUser();
    }

    public async check() {
        this.spinner = true;
        try {
            await this.PasswordService.passwordValid(this.user.email, this.password);
            this.spinner = false;
            this.log.info('Password is valid, continue');
            this.activeModal.close(this.password);
        } catch (ex) {
            this.spinner = false;
            if (ex instanceof ErrCode) {
                if (ex.code == 8101) {
                    // password incorrect
                    this.log.warn(`${ex.code} - user entered incorrect`);
                }
                this.errcode = ex;
            } else if (ex instanceof Error) {
                this.log.error(ex.toString());
                this.errcode = new ErrCode(1000, ex.toString());
            } else {
                this.log.error(ex.toString());
                this.log.error(ex);
                this.log.error('DialogConfirmPassword encountered unhandled error');
                this.errcode = new ErrCode(1000, 'An unknown error occcured');
            }
        }
    }

    public cancel() {
        this.activeModal.close(false);
    }
}
