import { Directive, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Dialog1UpComponent } from '../account/dialog1-up/dialog1-up.component';
import { BroadcastService } from '../shared/services/broadcast.service';

/**
 * @description
 *  The event published is "event:keydown:[keycode]" where keycode is the
 *  event keycode:
 *
 *     13 = enter,
 *     37 = left arrow key,
 *     39 = right arrow key,
 *     45 = insert
 *     ...
 *
 */

@Directive({
    selector: '[syncGlobalBinds]',
})
export class GlobalBindsDirective {
    konami_keys_map = {
        'ArrowUp' : 38,
        'ArrowDown' : 40,
        'ArrowLeft' : 37,
        'ArrowRight' : 39,
        'b' : 66,
        'a' : 65
    };
    konami_keys = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    konami_index = 0;

    constructor(
        private modalService: NgbModal,
        private broadcastService: BroadcastService
    ) {}

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this.broadcastService.broadcast('event:window.resize', e);
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(
        e: KeyboardEvent
    ) {
        this.broadcastService.broadcast('event:keydown:' +  e.which, e);
        if (e.key === this.konami_keys[this.konami_index++]) {
            if (this.konami_index === this.konami_keys.length) {
                this.konami_index = 0;
                const ref = this.modalService.open(Dialog1UpComponent, {
                    backdropClass: 'in',
                    windowClass: 'in',
                    backdrop: true,
                });
                ref.result.then(
                    () => {},
                    () => {}
                );
            }
        } else {
            this.konami_index = 0;
        }
    }
}
