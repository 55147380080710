import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ShareNewService } from '../share-new.service';
import { ShareService } from '../share.service';
import { JoblockService } from 'src/app/core/joblock.service';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { LoggerService } from '../../core/logger.service';
import { NotificationsService } from '../../core/notifications.service';

@Component({
    selector: 'sync-sharemember-remove',
    templateUrl: './sharemember-remove.component.html',
})
export class SharememberRemoveComponent implements OnInit {
    @Output() state = new EventEmitter<number>();
    @Input() public openState: number;
    @Input() public modalInstance: NgbModalRef;
    @Input() public shareData: sync.IShareData;
    public selectedPurge = '1'; // default true
    public spinner = false;
    public errors: sync.IErrorCode[] = [];
    @Output() public stateChangeEvent: EventEmitter<any> = new EventEmitter();

    public removeMembers: sync.ISharememberData[] = [];
    private remainMembers: sync.ISharememberData[] = [];
    public bg = 0;
    public removeMemberJob: any;

    constructor(
        private shareService: ShareService,
        private shareNewService: ShareNewService,
        private broadcastService: BroadcastService,
        private JobLockService: JoblockService,
        private loggerService: LoggerService,
        private store: Store<State>,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit() {
        this.removeMembers = [];
        this.remainMembers = [];
        this.shareData.people.map((cur) => {
            if (cur.selected) {
                this.removeMembers.push(cur);
            } else {
                this.remainMembers.push(cur);
            }
        });
    }

    public async postremoveShareMemberAction() {
        if (this.removeMemberJob.bg == 1) {
            const response = await this.JobLockService.pollJobStatus();
            this.loggerService.info({ func: 'postremoveShareMemberAction' });
        }
        this.broadcastService.broadcast('event:share-list.reload');
        this.spinner = false;
        this.stateChange(1);
    }

    public async removeSelectedMembers() {
        try {
            this.errors = [];
            this.spinner = true;
            this.removeMemberJob = await this.shareNewService.removeSelected(
                this.shareData,
                this.removeMembers,
                this.remainMembers,
                this.selectedPurge == '1'
            );

            if (this.removeMemberJob.bg == 0) {
                this.notificationsService.startNotificationLoop();
            }
            await this.postremoveShareMemberAction();

        } catch (err) {
            this.spinner = false;
            this.errors.push(err);
        }
    }

    public stateChange(change) {
        this.stateChangeEvent.next(change);
    }
}
