import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RunUploadFolderService } from '../services/run-upload-folder.service';

@Component({
    selector: 'sync-dialog-folder-upload',
    templateUrl: './dialog-folder-upload.component.html'
})
export class DialogFolderUploadComponent implements OnInit {
    public view: sync.IUploadFolderView;
    public worklist: sync.ITransferItemUpload[];

    constructor(
        public activeModal: NgbActiveModal,
        public runUploadFolderService: RunUploadFolderService
    ) {}

    ngOnInit() {
        this.view = this.runUploadFolderService.view;
    }

    public isFinished(): boolean {
        return (
            this.view.filePendingCount === 0 &&
            this.view.pendingBytes === 0 &&
            !this.view.isProcessing &&
            !this.view.isScanning
        );
    }

    public restart(): void {
        this.runUploadFolderService.restart();
    }

    public getMakeDirPercent() {
        return (
            (this.view.folderMadeCount / this.view.folderCount) *
            100
        ).toFixed(3);
    }

    public cancel(): void {
        this.runUploadFolderService.cancel();
        this.activeModal.close();
    }
}
