<div class="wrapper">
  <div *ngFor="let user of avatarUsers; let i = index" [ngbPopover]="displayNamePopover" placement="top">
    <sync-avatar [user]="user" [avatarIndex]="i" [disabled]="user.disabled"></sync-avatar>
    <ng-template #displayNamePopover>
      <div class="popover-wrapper">
        <div class="popover-container">
          <div class="popover-avatar">
            <sync-avatar [user]="user" [avatarIndex]="i" [disabled]="user.disabled"></sync-avatar>
          </div>
          <div class="avatar-info" [ngClass]="{'disabled' : user.disabled}">
            <span class="avatar-displayname">{{user.decodedDisplayName}}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="overflowUsersCount" [ngbPopover]="displayNamesPopover" placement="bottom">
    <sync-avatar [overflowCount]="overflowUsersCount" [avatarIndex]="maxDisplayAvatar-1"></sync-avatar>
    <ng-template #displayNamesPopover>
      <div class="popover-wrapper">
        <div *ngFor="let user of overflowUsers; let i = index" class="popover-container">
          <div class="popover-avatar">
            <sync-avatar [user]="user" [avatarIndex]="maxDisplayAvatar+i" [disabled]="user.disabled"></sync-avatar>
          </div>
          <div class="avatar-info" [ngClass]="{'disabled' : user.disabled}">
            <span class="avatar-displayname">{{user.decodedDisplayName}}</span>
          </div>
        </div>
        <div class="popover-view-all">
          <a (click)="onViewAll()">View All</a>
        </div>
      </div>
    </ng-template>
    <div class="hover-effect"></div>
  </div>
</div>