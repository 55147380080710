import { Directive, HostListener, Input } from '@angular/core';
import { CompatService } from '../../core/compat.service';

@Directive({
    selector: '[syncDialogLink]',
})
export class DialogboxLinkDirective {
    @Input('openState') openState: string;
    @Input('selected') selected: sync.IFile[] = [];

    constructor(private compatService: CompatService) {}

    @HostListener('click', ['$event']) click(event) {
        const item = Array.isArray(this.selected)
            ? this.selected[0]
            : this.selected;
        const initialState = item.is_publink ? 1 : 0;
        let state = initialState;
        if (this.openState && !Number.isNaN(parseInt(this.openState, 10))) {
            state = parseInt(this.openState, 10);
        }
        this.compatService.showLinkDialog(item, state);
    }
}
