import { Inject, Injectable } from '@angular/core';
import { Base64Service } from '../core/base64.service';
import { DirtyOutService } from '../core/crypt/dirty-out.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { UserService } from '../core/user.service';
import { BlendEvent, PubkeyData } from '../shared/models';
import { LinkPasswordService } from '../links/services/link-password.service';
import { ApiService } from '../core/api.service';
import { Observable, Subject, throwError } from 'rxjs';
import { NotificationsService } from '../core/notifications.service';
import { BlendService } from '../shared/services/blend.service';

@Injectable({
    providedIn: 'root',
})
export class ShareNewService {
    constructor(
        private loggerService: LoggerService,
        private base64Service: Base64Service,
        private userService: UserService,
        private syncCryptService: SyncCryptService,
        private DirtyOutService: DirtyOutService,
        private linkPasswordService: LinkPasswordService,
        private apiService: ApiService,
        private notificationsService: NotificationsService,
        private blendService: BlendService
    ) {}

    public async checkShare(syncId: number) {
        return await this.apiService.execute('sharecheck', {
            sync_id: syncId,
        });
    }

    public async addAppShare(syncId: number, shareId: number): Promise<any> {
        this.notificationsService.stopNotificationLoop();
        this.loggerService.info(`Adding Apps to share ${shareId}`);

        try {
            const data = await this.apiService
            .execute('shareintegrationadd', { sync_id: syncId });
        } catch (err) {
            this.loggerService.info(
                `ShareNew Error creating app share on syncId ${syncId}`
            );
            this.loggerService.error(err);
            throw err;
        }
        const linkPass = await this.sanitizeShare(shareId);

        const linkpassword =
            this.base64Service.encode(linkPass);

        try {
            await this.encShare(shareId, 1000, undefined);
            this.loggerService.info('Successfully added App Share');
            this.notificationsService.startNotificationLoop();
            return;
        } catch (err) {
            this.loggerService.info(
                `ShareNew Error adding member to share ${shareId}`
            );
            this.loggerService.error(err);
            this.notificationsService.startNotificationLoop();
            throw err;
        }

    }

    public mkShare(syncId: number, filename: string, label: string, sharememberInvite: sync.ISharememberInviteData, appShare?: boolean, bg?: number): Subject<any | sync.IErrorCode> {

        const subject = new Subject();
        let shareId;
            this.shareDataProcess(
                syncId,
                filename,
                label,
                sharememberInvite,
                subject,
                appShare,
                bg
            ).then(async (data: any) => {
                shareId = data.apiData.share_id;
                if (data.bg == 0) {
                    const emaillist = sharememberInvite.queue.concat(
                        sharememberInvite.roleQueue
                    );
                    if (!appShare) {
                        await this.apiService.execute('sharemailinvite', {
                            share_id: shareId,
                            emaillist: emaillist,
                            enc_password: data.inviteKey,
                            pm_b64: this.base64Service.encode(
                                sharememberInvite.privateMessage
                            ),
                        });
                    }
                    this.notificationsService.startNotificationLoop();
                }
                this.loggerService.info(
                    `Share created successfully ${shareId}`
                );
                this.blendService.track(BlendEvent.SHARED_FOLDER_CREATED);
                subject.next({
                    shareId: shareId,
                    inviteKey: data.inviteKey,
                    cachekey: data.apiData.cachekey,
                    sharekey: data.sharekey,
                    bg: data.bg,
                });
                subject.complete();
            }).catch((err) => {
                this.notificationsService.startNotificationLoop();
                this.loggerService.info(`ShareNew Error creating share ${shareId}`);
                this.loggerService.error(err);
                subject.error(err);
                subject.complete();
            });
        return subject;
    }

    public async shareMailInvite(
        shareId: number,
        inviteKey: string,
        sharememberInvite: sync.ISharememberInviteData,
        appShare?: boolean
    ) {
        this.loggerService.info({ func : 'shareMailInvite', shareId });
        try {
            const emaillist = sharememberInvite.queue.concat(
                sharememberInvite.roleQueue
            );
            if (!appShare) {
                await this.apiService.execute('sharemailinvite', {
                    share_id: shareId,
                    emaillist: emaillist,
                    enc_password: inviteKey,
                    pm_b64: this.base64Service.encode(
                        sharememberInvite.privateMessage
                    ),
                });
            }

            this.loggerService.info(`Share created successfully ${shareId}`);
            this.notificationsService.startNotificationLoop();
            return true;
        } catch (err) {
            this.notificationsService.startNotificationLoop();
            this.loggerService.info(`ShareNew Error creating share ${shareId}`);
            this.loggerService.error(err);
        }
    }

    public async postshareDataProcess(sync_id: number) {
        this.loggerService.info({ func: 'postshareDataProcess', sync_id });
        let cnt = 0;
        const subject = new Subject();
        let apiResult: any, inviteKey: string, shareId: number, newData: any;

        const data = await this.apiService.execute<any>('keysneedsharekeys', {
            sync_id: sync_id,
        });
        shareId = data.share_id;
        cnt = data.cnt;

        apiResult = data;
        let shareNewData = {};
        if (!data.enc_password) {
            const linkPass = await this.linkPasswordService.makeLinkPassword(
                24
            );
            inviteKey = linkPass;
            shareNewData = await this.syncCryptService.backfillShareData(
                linkPass,
                data.enc_share_keys,
                this.userService.get('pubkey')
            );
        }

        newData = shareNewData;
        this.loggerService.info('sanitizeShare encShareKeyListWithPubkeys');

        const result = await this.syncCryptService.encShareKeyListWithPubkeys(
            apiResult.enc_share_keys,
            apiResult.pubkeys
        );
        await this.setShareKeys(shareId, result, newData);

        await this.encShare(shareId, cnt, subject);
        return { apiData: data, shareId, cnt };
    }

    private async shareDataProcess(
        syncId: number,
        filename: string,
        label: string,
        sharememberInvite: sync.ISharememberInviteData,
        subject: Subject<any | sync.IErrorCode>,
        appShare?: boolean,
        bg?: number,
    ): Promise<any> {
        let shareId: number;
        let sharekey: string;
        let inviteKey: string;
        let cnt = 0;
        this.notificationsService.stopNotificationLoop();
        let apiData: any;

        const shareData: any = await this.mkShareData(
            syncId,
            filename,
            label,
            sharememberInvite,
            appShare,
            bg
        );
        sharekey = this.syncCryptService.bytesToB64(shareData.share_key);
        shareData.share_key = undefined;
        inviteKey = shareData.invite_key; // url password
        shareData.invite_key = undefined;

        const sData = await this.shareAdd(shareData);
        apiData = sData;
        shareId = apiData.share_id;
        cnt = apiData.cnt;
        if (apiData.bg == 0) {
            await this.sanitizeShare(shareId);
            await this.encShare(shareId, cnt, subject);
        }
        return { apiData, cnt, inviteKey, bg: apiData.bg, sharekey };
    }

    public async addMember(
        shareData: sync.IShareData,
        sharememberInvite: sync.ISharememberInviteData
    ): Promise<any> {
        this.notificationsService.stopNotificationLoop();
        this.loggerService.info(
            `Adding member to share ${JSON.stringify(shareData)}`
        );

        const emaillist = sharememberInvite.queue.concat(sharememberInvite.roleQueue);
        await this.apiService.execute('sharememberadd', {
            sync_id: shareData.syncId,
            share_id: shareData.shareId,
            pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
            emaillist: emaillist,
            permissions: sharememberInvite.permissions,
            displayname: sharememberInvite.displayName,
        });
        const linkPass = await this.sanitizeShare(shareData.shareId);

        const linkpassword = shareData.encPassword
            ? await this.syncCryptService.linkpasswordDecrypt(
                  shareData.encPassword
              )
            : this.base64Service.encode(linkPass);

        await this.apiService.execute(
            'sharemailinvite',
            {
                share_id: shareData.shareId,
                emaillist: emaillist,
                enc_password: linkpassword,
                pm_b64: this.base64Service.encode(
                    sharememberInvite.privateMessage
                ),
            },
            false
        );

        try {
            await this.encShare(shareData.shareId, 1000, undefined);
            this.loggerService.info('Successfully invited a share member');
            this.notificationsService.startNotificationLoop();
            return;
        } catch (err) {
            this.loggerService.info(
                `ShareNew Error adding member to share ${shareData.shareId}`
            );
            this.loggerService.error(err);
            this.notificationsService.startNotificationLoop();
            throw err;
        }
    }

    public async removeSelected(
        shareData: sync.IShareData,
        removeMembers: sync.ISharememberData[],
        remainMembers: sync.ISharememberData[],
        purge: boolean
    ) {
        this.notificationsService.stopNotificationLoop();
        const pubkeys: Array<PubkeyData> = [];
        if (removeMembers.length && remainMembers.length) {
            const sharekey: string = this.syncCryptService.bytesToB64(
                this.syncCryptService.mkShareKey()
            );
            for (let i = 0, len = remainMembers.length; i < len; i++) {
                if (remainMembers[i].pubkey) {
                    const pub = new PubkeyData();
                    (pub.share_id = shareData.shareId),
                        (pub.share_sequence = shareData.shareSequence);
                    pub.id = remainMembers[i].pubkeyId;
                    pub.key = remainMembers[i].pubkey;
                    pubkeys.push(pub);
                }
            }
            if (shareData.peoplex && shareData.peoplex.length) {
                this.loggerService.info(
                    `Encing ${shareData.peoplex.length} peoplex`
                );
                for (let i = 0, len = shareData.peoplex.length; i < len; i++) {
                    if (shareData.peoplex[i].pubkey) {
                        const pub = new PubkeyData();
                        (pub.share_id = shareData.shareId),
                            (pub.share_sequence = shareData.shareSequence);
                        pub.id = shareData.peoplex[i].pubkeyId;
                        pub.key = shareData.peoplex[i].pubkey;
                        pubkeys.push(pub);
                    }
                }
            }
            const selected: number[] = [];
            removeMembers.map((cur) => {
                selected.push(cur.sharememberId);
            });
            // if we don't have an encPassword, we backfill
            if (!shareData.encPassword) {
                this.loggerService.warn('No enc password, backfill required');
            }

            const encKeys = await this.syncCryptService.encShareKeyWithPubkeys(
                sharekey,
                pubkeys
            );

            const linkShareKey =
                await this.syncCryptService.linksharekeyReEncrypt(
                    shareData.encPassword,
                    sharekey,
                    shareData.salt,
                    shareData.iterations
                );
            const resp = await this.apiService.execute('sharememberdelete', {
                share_id: shareData.shareId,
                sharemembers: selected,
                sharedata: encKeys,
                link_share_key: linkShareKey,
                purge: purge,
            });

            return resp;
        }
    }


    public async  sanitizeShare(shareId: number) {
        let apiResult: any, newData: any, inviteKey: string;
        this.loggerService.info(`sanitizeShare shareId = ${shareId}`);
        const data = await this.apiService.execute<any>('keysneedsharekeys', {
            share_id: shareId,
        });
        this.loggerService.info(
            `KeysApi need shareKeys ${JSON.stringify(data)}`
        );
        apiResult = data;
        let shareNewData = {};
        if (!data.enc_password) {
            const linkPass = await this.linkPasswordService.makeLinkPassword(
                24
            );
            inviteKey = linkPass;
            shareNewData = await this.syncCryptService.backfillShareData(
                linkPass,
                data.enc_share_keys,
                this.userService.get('pubkey')
            );
        }

        newData = shareNewData;
        this.loggerService.info('sanitizeShare encShareKeyListWithPubkeys');

        const result = await this.syncCryptService.encShareKeyListWithPubkeys(
            apiResult.enc_share_keys,
            apiResult.pubkeys
        );
        await this.setShareKeys(shareId, result, newData);
        this.loggerService.info('sanitizeShare set sharekeys');
        return inviteKey;
    }
    /**
     * @ngdoc method
     * @name  setShareKeys
     * @methodOf sync.service:KeysApi
     * @description
     * Creates a new share key and saves it.
     * @param  {Object} sharedata The share data to save
     * @returns {Promise} The promise from the API call
     */
    public setShareKeys(
        shareId: number,
        sharekeydata: sync.IShareKeyData[],
        sharenewData?: sync.IShareNewData
    ) {
        const result: { [key: string]: any } = {
            sharedata: sharekeydata,
            backfill_link: undefined,
        };

        if (sharenewData != undefined && (JSON.stringify({}) !== JSON.stringify(sharenewData))) {
            result.backfill_link = {
                share_id: shareId,
                link_sharekeys: sharenewData.link_sharekeys,
                salt: sharenewData.salt,
                enc_password: sharenewData.enc_password,
                iterations: sharenewData.iterations,
            };
        }
        return this.apiService.execute('keyssetsharekeys', result);
    }
    private async shareAdd(params: any) {
        let chkCount = 0;

        try {
            const apiData =  await this.apiService.execute('shareadd', params, false);
            return apiData;
        } catch (err) {
            const isShared = async function() {
                if (chkCount > 200) {
                    this.loggerService.error(
                        'Waited for 10 minutes for the share to complete'
                    );
                    throw { code: 1606 };
                }
                this.loggerService.warn('Checking if shared pathitem');
                try {
                    const pathitem: sync.IFile = await this.apiService.execute('pathget', {sync_id: params.sync_id});
                    if (pathitem.is_shared) {
                        err.share_id = pathitem.share_id;
                        throw err;
                    } else {
                        chkCount++;
                        setTimeout(() => {
                            isShared();
                        }, 3000);
                    }
                } catch (chkErr) {
                    this.loggerService.error(
                        'Failed getting path for isShared'
                    );
                    this.loggerService.error(chkErr);
                    chkCount++;
                    setTimeout(() => {
                        isShared();
                    }, 3000);
                }
            };
            if (err.httpstatus > 499 || err.httpstatus == 0) {
                isShared();
            } else {
                if (typeof err == 'object' && err.code == 1610) {
                    let syncId;
                    if (err.msg) {
                        syncId = parseInt(
                            err.msg.split(
                                'share in a share sync_id'
                            )[1],
                            10
                        );
                    }
                    throw { code: 1610, data: syncId };
                }
                this.loggerService.error('Rejecting shareAdd');
                throw err;
            }
        }

    }

    private async mkShareData(
        syncId: number,
        filename: string,
        label: string,
        sharememberInvite: sync.ISharememberInviteData,
        appShare?: boolean,
        bg?: number
    ) {
        const emaillist: string[] = sharememberInvite.queue.concat(sharememberInvite.roleQueue);

        if (!emaillist.length && !appShare) {
            this.handleErr({ error_code: 1605 });
        }

        const linkPass = await this.linkPasswordService.makeLinkPassword(24);
        const shareData = await this.syncCryptService.mkShareData(
            linkPass,
            this.userService.get('pubkey')
        );
        const encName = await this.syncCryptService.filenameEncrypt(
            filename,
            this.syncCryptService.bytesToB64(shareData.sharekey)
        );
        const params = {
            share_key: shareData.sharekey,
            invite_key: this.base64Service.encode(shareData.invite_key),
            enc_name: encName,
            sync_id: syncId,
            enc_share_key: shareData.enc_share_key,
            link_share_key: this.syncCryptService.bytesToB64(
                shareData.link_sharekey
            ),
            label: this.base64Service.encode(label),
            emaillist: emaillist,
            pm_b64: this.base64Service.encode(sharememberInvite.privateMessage),
            permissions: sharememberInvite.permissions,
            display_name: sharememberInvite.displayName,
            enc_password: shareData.enc_password,
            iterations: shareData.iterations,
            salt: shareData.salt,
            app_integrated: appShare ? true : false,
            bg : bg
        };
        return params;
    }

    private async encShareKeys(shareId: number, data: any, sharekey: string) {
        const linkPass = await this.linkPasswordService.makeLinkPassword(24);
        const shareData = await this.syncCryptService.mkShareData(
            linkPass,
            this.userService.get('pubkey')
        );
        let sharekeys;
        if (data.pubkeys.length) {
            sharekeys = await this.syncCryptService.encShareKeyWithPubkeys(
                sharekey,
                data.pubkeys
            );
        }
        await this.setShareKeys(shareId, sharekeys, shareData);
        return;
    }

    private async encMembers(shareId: number, sharekey: string): Promise<Promise<any>> {
        const keyData = await this.apiService.execute<any>('keysneedsharekeys', { share_id: shareId });
            if (keyData.pubkeys && keyData.pubkeys.length) {
                await this.encShareKeys(shareId, keyData, sharekey);
                return;
            } else {
                return;
            }
    }

    private async encShare(
        shareId: number,
        cnt: number,
        subject: Subject<any>,
        dfd?: Promise<any>
    ) {
        if (!dfd) {
            dfd = Promise.resolve();
        }
        this.loggerService.info('func: encShare ' + 'encShare ' + shareId + ' cnt = ' + cnt);

        try {
            const data = await this.DirtyOutService.executeForShare(shareId);
            this.loggerService.info('Execute for share ' + shareId);
            console.warn(data);
            if (!data || (Array.isArray(data) && data.length === 0)) {
                this.loggerService.info('No more items in list, stopping');
                if (subject) {
                    subject.next({
                        count: cnt,
                        completed: cnt,
                    });
                }
                return;
            } else if (data && data.completed) {
                if (subject) {
                    subject.next({
                        count: cnt,
                        completed: data.completed,
                    });
                }
                this.encShare(shareId, cnt, subject, dfd);
            } else {
                if (subject) {
                    subject.next({
                        count: cnt,
                        completed: cnt,
                    });
                }
                return;
            }
        } catch (err) {
            throw err;
        }
    }

    private handleErr(errData: any) {
        this.notificationsService.startNotificationLoop();

        if (typeof errData == 'object' && errData.error_code) {
            throw errData;
        } else {
            throw {
                code: 9000,
                msg: 'Unknown error creating a share',
            };
        }
    }
}
