<sync-error-display-hash [errors]="errors"></sync-error-display-hash>

<div class="row share_member_add">
    <div class="col-sm-4">Invite users by email:</div>
    <div class="col-sm-8">
        <form #f="ngForm" ngNativeValidate>
            <div class="input-group">
                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
                </ng-template>
                <input (blur)="queueInvite(f)" (focus)="onFocus()" id="text-sharemember-add-sharewith"
                    autocomplete="off" name="email" type="email" placeholder="Enter email addresse(s) to invite"
                    [(ngModel)]="emailfield" class="form-control sync-typeahead" [ngbTypeahead]="search" email
                    #email="ngModel" />
                <div class="input-group-btn">
                    <button (click)="queueInvite(f)" id="input-plus-btn" class="btn btn-primary">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
        </form>
        <div *ngIf="disableValidation && (!email?.valid || email.errors?.email)" class="sync-tool-tip">
            <i class="fas fa-exclamation-triangle"></i>
            <span *ngIf="email.errors.email">
                Invalid email address.
            </span>
        </div>
        <div class="shareuserbadges">
            <span class="badge" *ngFor="let email of sharememberInvite.queue">
                <span [innerHtml]="getContactName(email)"></span>
                <i (click)="removePerson(email)" class="fas fa-times"></i>
            </span>
        </div>
    </div>
</div>
<hr>
<div class="row" *ngIf="isRolesAllowed">
    <div class="col-sm-4">Invite users by role:</div>
    <div class="col-sm-8">
        <sync-multi-select-dropdown
            [isLoading]="isLoading"       
            [items]="rolesList"
            [showAll]="false"
            placeholder="Select ..."
            [selectedText] = "roleSelectedText"
            [showSearch]="false"
            (itemChange)="onRoleSelect($event)">
        </sync-multi-select-dropdown>
        <p style="margin-top: 20px;">
            Select roles to invite all users you have currently assigned to the selected roles.
        </p>
    </div>
</div>
<hr>
<div class="row">
    <div class="col-sm-4">User permissions:</div>
    <div class="col-sm-8">
        <div *ngIf="!isPro">
            <i  class="fas fa-check-square"></i> Can view and edit <br /> <a class="syncblue" routerLink="/account/upgrade" (click)="activeModal.close()">Upgrade your account</a> to set read only permissions.
        </div>
        <div *ngIf="isPro">
            <div class="radio radio-top">
                <label>
                    <input type="radio" name="permission" [(ngModel)]="permission"
                        (ngModelChange)="permissionChanged($event)" id="sharemember-add-permission-rw" value="1" />
                    Can view and edit
                </label>
            </div>

            <div class="radio radio-bottom">
                <label>
                    <input type="radio" name="permission" [(ngModel)]="permission"
                        (ngModelChange)="permissionChanged($event)" id="sharemember-add-permission-ro" value="2" />
                    View only
                </label>
            </div>
        </div>

    </div>
</div>
<hr>
<div class="row" *ngIf="isPro && !isOnTrial">
    <div class="col-sm-4">Optional message:</div>
    <div class="col-sm-8">
        <textarea class="form-control" name="pm" maxlength="350" [(ngModel)]="sharememberInvite.privateMessage"
            placeholder="Enter message for users"></textarea>
        <span class="subtext pull-right">{{sharememberInvite?.privateMessage?.length}}/350 characters</span>
    </div>
</div>