import { Injectable } from '@angular/core';
import { DownloadService } from './services/download.service';
import { LoggerService } from '../core/logger.service';
import { TransferItemService } from './services/transfer-item.service';
import { SyncCookieService } from '../core/sync-cookie.service';
import { BrowserSupportService } from '../core/browser-support.service';
import { TransferComponent } from './transfer/transfer.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BuildTransferItemService } from './services/build-transfer-item.service';
import { Constants } from './constants';
import { TransferStatus, TransferQueueType } from './transfer.model';
import { BlendEvent } from '../shared/models';
import { BroadcastService } from '../shared/services/broadcast.service';
import { FileItemService } from '../file/file-item.service';
import { BlendService } from '../shared/services/blend.service';

@Injectable({
    providedIn: 'root',
})
export class TransferService {

    public ModalInstance: NgbModalRef;

    constructor(
        private browserSupportService: BrowserSupportService,
        private syncCookieService: SyncCookieService,
        private transferItemService: TransferItemService,
        private downloadService: DownloadService,
        private loggerService: LoggerService,
        private modalService: NgbModal,
        private buildTransferItemService: BuildTransferItemService,
        private broadcastService: BroadcastService,
        private fileItemService: FileItemService,
        private blendService: BlendService
    ) {}

    public async queueUpload(files: FileList, isDragDrop: boolean): Promise<any> {
        const ulQueue: Promise<sync.ITransferItemUpload>[] = [];
        const file_ext = [];
        const mime_types = [];
        let file_size = 0;
        this.loggerService.info('queueing an upload of ' + files.length);

        if (!this.browserSupportService.testFileUpload()) {
            this.loggerService.error(
                'files were attempted to be uploaded but the browser does not support it'
            );
            return;
        }

        for (let i = 0, len = files.length; i < len; i++) {
            const file = files[i];
            if (file) {
                if (file.name.length <= Constants.MAX_FILE_NAME_LEN) {
                    ulQueue.push(this.buildTransferItemService.mkuploadItem(file));
                }
                file_ext.push(this.fileItemService.getFileExt(file.name));
                const mimeType = (this.fileItemService.getmimeType(this.fileItemService.getFileExt(file.name)));
                if (mime_types.indexOf(mimeType) === -1) {
                    mime_types.push(mimeType);
                }
                file_size += file.size;
                this.loggerService.warn(`Skipping file upload as length of filename exceeded ${Constants.MAX_FILE_NAME_LEN} characters`);
            }
        }

        const res = await Promise.all(ulQueue);
        await this.buildTransferItemService.addToQueue(res, TransferQueueType.UPLOAD);
        this.showTransfer();

        this.blendService.track(BlendEvent.UPLOAD_FILE, {
            isDragDrop: isDragDrop ? 'Yes' : 'No',
            mimeType: mime_types,
            fileSize: this.fileItemService.bytesToSize( file_size)
        });
        return true;
    }

    public async queueDownload(files: sync.IFile[]): Promise<any> {
        const dlQueue: Promise<sync.ITransferItemDownload>[] = [],
            mimeTypes = [];
        let total_size = 0; const file_ext = [];

        const keyData = await this.buildTransferItemService.getFileKeys(files).catch(err => this.handleKeyError(err));
        for (let i = 0, len = files.length; i < len; i++) {
            files[i].blobtype = 'btFILE';
            dlQueue.push(this.buildTransferItemService.mkDownloadItem(files[i], keyData));
            const mimeType = files[i].mime_type;
            if (mimeTypes.indexOf(mimeType) === -1) {
                mimeTypes.push(mimeType);
            }
            total_size = total_size + files[i].size;
            file_ext.push(files[i].file_extension);
        }
        const result = await Promise.all(dlQueue).catch(err => {
            this.loggerService.error('Error queing a file');
            throw new Error(err);
        });
        await this.buildTransferItemService.addToQueue(result, TransferQueueType.DOWNLOAD).catch(err => {
            this.loggerService.error('Error queuing a file for download');
            throw new Error(err);
        });
        this.showTransfer();
        this.broadcastService.broadcast('sync.track.blend', {
            eventName: BlendEvent.DOWNLOAD_FILE,
            parameters: {
                isCompat: 'No',
                mimeTypes: mimeTypes,
                fileSize: this.fileItemService.bytesToSize(total_size)
            }
        });
        return true;
    }

    public async queuePublicDownload(files: sync.IFile[]): Promise<any> {
        const dlQueue: Promise<sync.ITransferItemDownload>[] = [];

        const keyData = await this.buildTransferItemService.getFileKeys(files).catch((err) => this.handleKeyError(err));
        for (let i = 0, len = files.length; i < len; i++) {
            files[i].blobtype = 'btFILE';
            dlQueue.push(this.buildTransferItemService.mkDownloadItem(files[i], keyData));
        }
        const result = await Promise.all(dlQueue).catch(err => {
            this.loggerService.error('Error queuePublicDownload a file');
            throw new Error(err);
        });
        await this.buildTransferItemService.addToQueue(result, TransferQueueType.DOWNLOAD).catch(err => {
            this.loggerService.error('Error queuePublicDownload a file');
            throw new Error(err);
        });
        return true;
    }

    public async queuePublicUpload(files: FileList): Promise<any> {
        const ulQueue: Promise<sync.ITransferItemUpload>[] = [];
        this.loggerService.info('queueing a public upload of ' + files.length);

        if (!this.browserSupportService.testFileUpload()) {
            this.loggerService.error(
                'files were attempted to be uploaded but the browser does not support it'
            );
            return new Error('files were attempted to be uploaded but the browser does not support it');
        }

        for (let i = 0, len = files.length; i < len; i++) {
            const file = files[i];
            if (file && file.size !== 0) {
                ulQueue.push(this.buildTransferItemService.mkPublicUploadItem(file));
            } else {
                this.loggerService.warn('skipping a 0 byte file');
            }
        }

        const res = await Promise.all(ulQueue).catch((err) => {
            this.loggerService.error('An error queing upload');
            throw Error(err);
        });
        await this.buildTransferItemService.addToQueue(res, TransferQueueType.UPLOAD).catch((err) => {
            this.loggerService.error('An error queuing upload');
            throw Error(err);
        });
        this.showTransfer();
        return true;

    }

    /**
     * Downloads a specific file, bypassing the queue.  Used for previews.
     * Since previews can occur in a compat link, it must also handle this
     * case where no decryption occurs and the 'renderFile' object is
     * filled out with the necessary URL
     * @param  {Object} pathitem  A pathitem object from a Path List
     * @return {Promise} Promise chain of downloading a file
     */
    public async getFile(
        file: sync.IFile,
        preview?: boolean
    ): Promise<sync.ITransferItemDownload> {
        if (!file.blobtype) {
            file.blobtype = 'btFILE';
        }
        if (file.compat && file.context == 'applink' && file.compaturl) {
            // Don't get file keys as link may not have been backfilled
            const tItem = await this.buildTransferItemService.mkDownloadItem(file, undefined);
            tItem.type = this.transferItemService.TYPE_PREVIEW;
            if (tItem.linkpasswordlock) {
                this.syncCookieService.setDownloadPubLink(tItem.linkpasswordlock);
            }
            this.buildTransferItemService.view.single = tItem;
            this.buildTransferItemService.view.single.renderFile = {
                dl_ready: true,
                type: 'url',
                url: file.compaturl,
                savemethod: 'dlattr',
            };
            return this.buildTransferItemService.view.single;
        } else {
            const keyData = await this.buildTransferItemService.getFileKeys([file]).catch((err) => {
                this.loggerService.error('Could not get file');
                throw err;
            });
            const tItem = await this.buildTransferItemService.mkDownloadItem(file, keyData).catch((err) => {
                this.loggerService.error('Could not get file');
                throw err;
            });
            tItem.type = this.transferItemService.TYPE_PREVIEW;
            this.buildTransferItemService.view.single = tItem;
            if (file.compat && !this.isImageHeic(file)) {
                const url = await this.buildTransferItemService.mkCompatUrl(tItem, this.buildTransferItemService.ACT_PREVIEW).catch((err) => { throw err; });
                this.buildTransferItemService.view.single.renderFile = {
                    dl_ready: true,
                    type: 'url',
                    url: url,
                    savemethod: 'dlattr',
                };
                return this.buildTransferItemService.view.single;

            } else {
                let tItem;
                if (preview === true) {
                    tItem = await this.downloadService.downloadPreview(this.buildTransferItemService.view.single).catch((err) => { throw err; });
                } else {
                    tItem = await this.downloadService.downloadItem(this.buildTransferItemService.view.single).catch((err) => { throw err; });
                }
                return tItem;
            }
        }
    }
    public async getFileDocPreview(
        file: sync.IFile
    ): Promise<sync.ITransferItemDownload> {
        if (!file.blobtype) {
            file.blobtype = 'btFILE';
        }

        if (file.compat && file.context == 'applink' && file.compaturl) {
            // Don't get file keys as link may not have been backfilled
            let tItem = await this.buildTransferItemService.mkDownloadItem(file, undefined);
            tItem.type = this.transferItemService.TYPE_PREVIEW;
            if (tItem.linkpasswordlock) {
                this.syncCookieService.setDownloadPubLink(tItem.linkpasswordlock);
            }
            tItem.previewtoken = file.previewtoken;
            this.buildTransferItemService.view.single = tItem;
            this.buildTransferItemService.view.single.renderFile = {
                dl_ready: true,
                type: 'url',
                url: file.compaturl,
                savemethod: 'dlattr',
            };

            tItem = await this.downloadService.downloadCompat(this.buildTransferItemService.view.single).catch((err) => { throw err; });
            return tItem;
        } else {
            const keyData = await this.buildTransferItemService.getFileKeys([file]).catch((err) => { throw err; });
            let tItem = await this.buildTransferItemService.mkDownloadItem(file, keyData).catch((err) => { throw err; });
            tItem.type = this.transferItemService.TYPE_PREVIEW;
            this.buildTransferItemService.view.single = tItem;
            tItem = await this.downloadService.downloadPreview(this.buildTransferItemService.view.single).catch((err) => { throw err; });
            return tItem;
        }
    }

    public isImageHeic(item: sync.IFile): boolean {
        const name = item.name;
        const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        let canPreview = false;
        switch (ext) {
            case 'heic':
            case 'heif':
                canPreview = item.has_thumb2 != 0;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    }

    /**
     * @ngdoc method
     * @name  getThumb
     * @methodOf sync.service:Transfer
     * @description
     * Gets a thumbnail for a given path item, typically used in previews.
     * The thumbnail is stored using thumb storage
     *
     * @param  {Object} pathitem [description]
     * @param  {Array.Object} keyData  [description]
     * @param  {Array|ArrayBuffer} bytes    [description]
     * @return {Promise}          [description]
     */
    public async getThumb(
        file: sync.IFile,
        keyData: sync.IPathData,
        bytes: ArrayBuffer
    ) {
        file.blobtype = file.thumbData.blobtype;
        const tItem = await this.buildTransferItemService.mkDownloadItem(file, keyData);
        return await this.downloadService.saveThumb(tItem, bytes);
    }

    public reset() {
        const { download, upload } = this.buildTransferItemService.view;
        [download, upload].forEach(view => {
            view.queue = [];
            view.queueIdx = 0;
            view.isProcessing = false;
        });
    }

    public showTransfer() {
        this.hideTransfer();
        this.ModalInstance = this.modalService.open(TransferComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
            container: '#upload-modal'
        });
        this.ModalInstance.result.then(
            (ShouldClearCanceledItem = false) => {
                if (ShouldClearCanceledItem) {
                    this.removeCanceledItemsFromQueue();
                }
            },
            () => {
                this.removeCanceledItemsFromQueue();
            }
        );
    }

    public hideTransfer() {
        if (this.ModalInstance) {
            this.ModalInstance.close();
        }
    }

    private handleKeyError(err: any) {
        this.loggerService.error('Error retrieving keys');
        this.loggerService.error(err);
        try {
            const data = JSON.stringify(err);
            this.loggerService.error(data);
        } catch (e) {
            this.loggerService.error(e.toString());
        }
        return new Error(err);
    }

    /**
     * queue upload for blank document
     * @param file
     * @returns
     */
    public async queuePublicBlankDocUpload(file: File): Promise<any> {
        const ulQueue: Promise<sync.ITransferItemUpload>[] = [];
        this.loggerService.info('queueing a public upload of ' + file.size);

        if (!this.browserSupportService.testFileUpload()) {
            this.loggerService.error(
                'files were attempted to be uploaded but the browser does not support it'
            );
            return;
        }

        if (file && file.size !== 0) {
            ulQueue.push(this.buildTransferItemService.mkuploadItem(file));
        } else {
            this.loggerService.warn('skipping a 0 byte file');
        }

        const res = await Promise.all(ulQueue).catch((err) => {
            this.loggerService.error('An error queing upload');
            throw err;
        });
        await this.buildTransferItemService.addToQueue(res, TransferQueueType.UPLOAD);
        return true;
    }

    private removeCanceledItemsFromQueue() {
        const { download, upload } = this.buildTransferItemService.view;
        download.queue = download.queue.filter(transferItem => transferItem.status != TransferStatus.STATUS_UPLOAD_CANCELLED);
        upload.queue = upload.queue.filter(transferItem => transferItem.status != TransferStatus.STATUS_UPLOAD_CANCELLED);
    }
}
