import { ShareListMember } from './share-list-member.model';
import { Deserializable } from './deserializable.model';

export class ShareListActiveItem implements Deserializable<ShareListActiveItem> {
    enc_name: string;
    name: string;
    share_id: number;
    invite_status: string;
    cachekey: string;
    uniqid: number;
    sync_id: number;
    is_pending: 1 | 0;
    is_owner: 1 | 0;
    is_seeothers: 1 | 0;
    is_invite: 1 | 0;
    owner_name: string;
    people: number;
    people_list?: ShareListMember[];
    last_modified: number;
    inviter_username: string;
    label: string;
    person1: ShareListMember;
    person2: ShareListMember;
    person3: ShareListMember;
    person4: ShareListMember;

    public deserialize(input) {
        Object.assign(this, input);
        let it = 0;
        for (let i = 0, len = input.people_list.length; i < len; i++) {
            if (input.people_list[i].uniqid !== input.uniqid) {
                console.log('looping people');
                it += 1;
                const person = new ShareListMember().deserialize(input.people_list[i]);

                if        (it === 1) {
                    this.person1 = person;
                } else if (it === 2) {
                    this.person2 = person;
                } else if (it === 3) {
                    this.person3 = person;
                } else if (it === 4) {
                    this.person4 = person;
                }
            }
            // this.people_list.push(person);
        }
        return this;
    }
}
