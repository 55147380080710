import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogContactSupportComponent } from '../../../account/dialog-contact-support/dialog-contact-support.component';
import { UserService } from '../../../core/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrCode, ErrorMessages, User } from '../../models';

@Component({
    selector: 'sync-error',
    templateUrl: './error.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit {

    private _errCode;
    @Input() set errcode(errcode: ErrCode) {
        if (errcode && errcode.code) {
            if (ErrorMessages[errcode.code]) {
                // console.log('setting to new errcode msg ' + this.appConfig.errors[errcode.code])
                errcode.msg = this.sanitizer.bypassSecurityTrustHtml(ErrorMessages[errcode.code]);
            }
        }
        this._errCode = errcode;
    }
    public user: User;
    public modalOpen = false;

    get errcode(): ErrCode {
        return this._errCode;
    }

    constructor(
        private userService: UserService,
        private modalService: NgbModal,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.modalOpen = document.body.classList.contains('modal-open');
        this.user = this.userService.getUser();
    }
    openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }
    closeAlert() {
        this._errCode = null;
    }
}
