import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SlackLayoutComponent } from './layout/slack-layout/slack-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ResolveIsAdminResolver } from './shared/resolver/resolve-is-admin.resolver';
import { BaseGuard } from './shared/guards/base.guard';
import { FooterLayoutComponent } from './layout/footer-layout/footer-layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [BaseGuard]
  },
  {
    path: 'files',
    loadChildren: './file-list/file-list.module#FileListModule',
    data: { defaultSyncIdKey: 'syncus_sync_id' },
    canActivate: [AuthGuard]
  },
  {
    path: 'starred',
    loadChildren: './file-list/file-list.module#FileListModule',
    data: { defaultSyncIdKey: 'syncus_sync_id' },
    canActivate: [AuthGuard]
  },
  {
    path: 'starred/:sync_id',
    loadChildren: './file-list/file-list.module#FileListModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'files/:sync_id',
    loadChildren: './file-list/file-list.module#FileListModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'vault',
    loadChildren: './file-list/file-list.module#FileListModule',
    data: { defaultSyncIdKey: 'web_sync_id' },
    canActivate: [AuthGuard]
  },
  {
    path: 'vault/:sync_id',
    loadChildren: './file-list/file-list.module#FileListModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: './events/events.module#EventsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'recents',
    loadChildren: './recents/recents.module#RecentsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'health',
    loadChildren: './health/health.module#HealthModule',
  },
  {
    path: 'links',
    loadChildren: './links/links.module#LinksModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'file',
    loadChildren: './file/file.module#FileModule'
  },
  {
    path: 'shares',
    loadChildren: './shares/shares.module#SharesModule'
  },
  {
    path: 'signup-share',
    loadChildren: './shares/shares.module#SharesModule'
  },
  {
    path: 'dl',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'al',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'dl/:cachekey',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'al/:cachekey',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'dl/:cachekey/view/:type/:id',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'al/:cachekey/view/:type/:id',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule'
  },
  {
    path: 'comment-iframe',
    loadChildren: './link-consume/link-consume.module#LinkConsumeModule',
  },
  {
    path: 'account',
    loadChildren: './account/account.module#AccountModule',
  },
  {
    path: 'apps',
    loadChildren: './apps/apps.module#AppsModule',
  },
  {
    path: 'eapps',
    component: SlackLayoutComponent,
    loadChildren: './apps/apps.module#AppsModule',
  },
  {
    path: 'multi-user',
    component: FooterLayoutComponent,
    loadChildren: './multi-user/multi-user.module#MultiUserModule',
    canActivate: [AuthGuard],
    resolve: { isAdmin: ResolveIsAdminResolver }
  },
  { path: '', redirectTo: 'files', pathMatch: 'full' },
  { path: 'getspace', redirectTo : '/account/upgrade'},
  { path: 'upgrade', redirectTo : '/account/upgrade'},
  { path: '**', redirectTo : 'files'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
