import { Deserializable } from './deserializable.model';

export class Invoice implements Deserializable<Invoice> {

    id = 0;
    company_name = '';
    first_name = '';
    last_name = '';
    user_id = 0;
    username = '';
    status_id = 0;
    total_amount = 0;
    start_servtime = 0;
    end_servtime = 0;
    plan_name = '';
    plan_diskspace = 0;


    constructor(params?: {[k: string]: any}) {
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }

        return this;
    }
}


