<nav
    *ngIf="(item.context != 'link' && item.context != 'applink') && isReady && !imageCachekey"
    class="navbar navbar-default navbar-fixed-top"
>
    <div class="navbar-header">
        <a class="navbar-brand showhand" (click)="goBack()">
            <i
                class="zmdi zmdi-chevron-left zmdi-hc-2x"
                style="margin-top: 3px"
            ></i>
        </a>
    </div>
    <div id="navbar">
        <div class="navbar-title" *ngIf="item">
            {{ item.name }}
        </div>
        <div class="navbar-subtitle" *ngIf="item">
            Modified on {{ item.usertime | date: "short" }}
        </div>

        <ul class="nav navbar-nav navbar-right" *ngIf="user && user.exists && userLoaded && user.is_pro">
            <li><a (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>

<nav
    *ngIf="(item.context == 'link' || item.context == 'applink') && isReady && !imageCachekey"
    class="navbar navbar-default navbar-fixed-top-preview navbar-white"
    [ngClass]="{'navbar-single-view': item.pid == 0 }"
>
    <div class="navbar-header navbar-white-header">
        <a
            *ngIf="item.pid != 0"
            class="navbar-brand showhand"
            (click)="goBack()"
        >
            <i
                class="zmdi zmdi-chevron-left zmdi-hc-2x"
                style="margin-top: 3px"
            ></i>
        </a>
        <a
            *ngIf="item.pid == 0"
            class="navbar-brand showhand"
            href="https://www.sync.com/?_m=h73"
        >
            <img src="images/sync-q-lightbg.svg" />
        </a>
    </div>
    <div id="navbar-preview" class="navbar-white-preview">
        <div class="navbar-title" *ngIf="item">
            {{ item.name }}
        </div>
        <div class="navbar-subtitle" *ngIf="item">
            Modified on {{ item.usertime | date: "short" }}
        </div>

        <ul class="nav navbar-nav navbar-right navbar-white-right visible-xs visible-landscape hamburger-menu">
            <li class="dropdown" ngbDropdown>
                <a ngbDropdownToggle class="dropdown-toggle navrightdrop" data-toggle="dropdown">
                    <i class="fa-lg fas fa-bars"></i>
                </a>

                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-left dropdown-regular navrightdrop-ul">
                    <ng-container *ngTemplateOutlet="hamburgerMenuTpl"></ng-container>
                    <li *ngIf="(!user || !user.exists) && userLoaded">
                        <a href="https://www.sync.com/?_m=h73">Create account</a>
                    </li>

                    <li *ngIf="user && user.exists && userLoaded">
                        <a [href]="cpHost + '/account/info'">
                            <div class="tdmore account-dropdown-user">
                                {{ user.display_name | b64 }}
                            </div>
                        </a>
                    </li>
                    <li *ngIf="user && user.exists && userLoaded && user.is_pro"><a (click)="openContactSupport()">Help</a></li>
                </ul>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right navbar-white-right hidden-xs hidden-landscape">
            <li *ngIf="(!user || !user.exists) && userLoaded">
                <a href="https://www.sync.com/?_m=h73">Create account</a>
            </li>

            <!-- USER AVATAR AND NAME -->
            <!-- for wide display -->
            <li *ngIf="user && user.exists && userLoaded">
                <a [href]="cpHost + '/account/info'">
                    <div class="navbar-avatar-bigbox">
                        <img
                            class="img-circle navbar-avatar-header-bar"
                            [src]="avatarEndpoint + user.id"
                            onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                        />
                        <div class="navbar-avatar-smalltext">
                            Signed in as:
                        </div>
                        <div class="navbar-avatar-bigtext">
                            {{ user.display_name | b64 }}
                        </div>
                    </div>
                </a>
            </li>
            <!-- / USER AVATAR AND NAME -->

            <li><a *ngIf="user && user.exists && userLoaded && user.is_pro" (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>

<!-- ----------------------------------------------------------------------- -->
<!--                           CUSTOM WHITE LABEL                            -->
<!-- ----------------------------------------------------------------------- -->
<nav
    *ngIf="(item.context != 'link' && item.context != 'applink') && isReady && imageCachekey"
    class="navbar nav-custom navbar-fixed-top"
>
    <div class="navbar-header">
        <a class="navbar-brand showhand" (click)="goBack()">
            <i
                class="zmdi zmdi-chevron-left zmdi-hc-2x"
                style="margin-top: 3px"
            ></i>
        </a>
    </div>
    <div id="navbar">
        <div *ngIf="item">
            {{ item.name }}
        </div>
        <div class="navbar-subtitle-custom" *ngIf="item">
            Modified on {{ item.usertime | date: "short" }}
        </div>

        <ul class="nav navbar-nav navbar-right" *ngIf="user && user.exists && userLoaded && user.is_pro">
            <li><a (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>

<nav
    *ngIf="(item.context == 'link' || item.context == 'applink') && isReady && imageCachekey"
    class="navbar navbar-white-custom navbar-fixed-top-preview"
    [ngClass]="{'navbar-single-view': item.pid == 0 }"
>
    <div class="navbar-header navbar-white-header-custom">
        <a
            *ngIf="item.pid != 0"
            class="navbar-brand showhand"
            (click)="goBack()"
        >
            <i
                class="zmdi zmdi-chevron-left zmdi-hc-2x"
                style="margin-top: 3px"
            ></i>
        </a>
        <a
            *ngIf="item.pid == 0"
            class="navbar-brand showhand"
            href="https://www.sync.com/?_m=h73"
        >
            <img class="logo-custom" [src]="logoHost + imageCachekey" />
        </a>
    </div>
    <div id="navbar-preview" class="navbar-white-preview-custom">
        <table class="navbar-table-custom">
            <tr>
                <th>{{ item.name }}</th>
            </tr>
            <tr>
                <td>Modified on {{ item.usertime | date: "short" }}</td>
            </tr>
        </table>

        <!-- <div class="navbar-title-custom" *ngIf="item">
            {{item.name}}
        </div>
        <div class="navbar-subtitle-custom" *ngIf="item">
            Modified on {{item.usertime | date:'short'}}
        </div> -->

        <ul class="nav navbar-nav navbar-right navbar-white-right-custom visible-xs hamburger-menu">
            <li class="dropdown" ngbDropdown>
                <a ngbDropdownToggle class="dropdown-toggle navrightdrop" data-toggle="dropdown">
                    <i class="fa-lg fas fa-bars"></i>
                </a>

                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-left dropdown-regular navrightdrop-ul">
                    <ng-container *ngTemplateOutlet="hamburgerMenuTpl"></ng-container>
                    <li *ngIf="(!user || !user.exists) && userLoaded">
                        <a href="https://www.sync.com/?_m=h73">Create account</a>
                    </li>
        
                    <li *ngIf="user && user.exists && userLoaded">
                        <a [href]="cpHost + '/account/info'">
                            <div class="tdmore account-dropdown-user">
                                {{ user.display_name | b64 }}
                            </div>
                        </a>
                    </li>
                    <li *ngIf="user && user.exists && userLoaded && user.is_pro"><a (click)="openContactSupport()">Help</a></li>
                </ul>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right navbar-white-right-custom hidden-xs">

            <li *ngIf="(!user || !user.exists) && userLoaded">
                <a href="https://www.sync.com/?_m=h73">Create account</a>
            </li>

            <!-- for wide screens -->
            <li *ngIf="user && user.exists && userLoaded">
                <a [href]="cpHost + '/account/info'">
                    <div class="navbar-avatar-bigbox">
                        <img
                            class="img-circle navbar-avatar-header-bar"
                            [src]="avatarEndpoint + user.id"
                            onerror="this.onerror=null;this.src='../../../../../images/sync-construction.png';"
                        />
                        <div class="navbar-avatar-smalltext">
                            Signed in as:
                        </div>
                        <div class="navbar-avatar-bigtext">
                            {{ user.display_name | b64 }}
                        </div>
                    </div>
                </a>
            </li>

            <!-- / DROP-DOWN MENU -->

            <li><a *ngIf="user && user.exists && userLoaded && user.is_pro" (click)="openContactSupport()">Help</a></li>
        </ul>
    </div>
</nav>

<ng-template #hamburgerMenuTpl><ng-content select="[hamburgerMenu]"></ng-content></ng-template>
