import { LinkListItem } from '..';
import { BaseApiOutput } from './base-api.model';

export class LinkListApiOutput extends BaseApiOutput {
    linklist: LinkListItem[];
}

export class LinkListApiInput {
}


