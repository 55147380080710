import { ShareListActiveItem, ShareListInactiveItem } from '..';
import { BaseApiOutput } from './base-api.model';

export class ShareListApiOutput extends BaseApiOutput {
    pending: ShareListInactiveItem[];
    leave: ShareListInactiveItem[];
    confirm: ShareListInactiveItem[];
    active: ShareListActiveItem[];
}

export class ShareListApiInput {
}


