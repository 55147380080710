<div class="container-fluid container-fluid-wrap">
    <div class="row">
        <div class="col-sm-offset-3 col-sm-6">
            <sync-error-display-hash
                [errors]="errors"
            ></sync-error-display-hash>

            <div class="panel panel-default" *ngIf="!wrapKey">
                <div class="panel-heading">
                    <h3 class="panel-title">
                        <i class="ion-android-people fa-lg"></i>
                        Join a Shared Folder
                    </h3>
                </div>
                <div class="panel-body">
                    <p>
                        Enter (copy / paste) the verification code. This code
                        can be found in the share folder invitiation email you
                        just clicked.
                    </p>
                    <div class="form-group">
                        <label> Enter verification code: </label>
                        <input
                            class="form-control col-sm-3"
                            type="text"
                            [(ngModel)]="uWrapKey"
                        />
                    </div>
                </div>
                <div class="panel-footer text-right">
                    <button
                        [disabled]="!uWrapKey"
                        (click)="setWrapKey()"
                        class="btn btn-default"
                    >
                        <i
                            class="fa fa-spinner fa-spin"
                            [class.hide]="!spinners.invite"
                        ></i>
                        Next step
                    </button>
                </div>
            </div>
            <!-- end panel no wrap key -->
            <div *ngIf="wrapKey">
                <h2 class="text-center">
                    Join a Shared Folder <br /><small
                        >Create your free Sync account:</small
                    >
                </h2>

                <form class="text-left" #provisionForm="ngForm" (ngSubmit)="provision()" ngNativeValidate>
                    <br />
                    <div class="form-group">
                        <!--[if lt IE 10]>Email<![endif]-->
                        <label
                            class="field-label notshown"
                            for="signup-share-user"
                            >Your email address:</label
                        >
                        <input
                            type="email"
                            name="email"
                            id="signup-share-user"
                            class="form-control signup-input-user input-lg"
                            size="30"
                            placeholder="Email"
                            required
                            email
                            [(ngModel)]="username"
                            #email="ngModel"
                        />
                        <span class="help-block">
                            Note: This email address was specified for the
                            invite. If you signup with a different email address
                            you'll have to get the Sync user to re-invite you to
                            the shared folder.
                        </span>
                    </div>
                    <div class="form-group">
                        <!--[if lt IE 10]>Password<![endif]-->
                        <label
                            class="field-label notshown"
                            for="signup-share-pass"
                            >Choose a password:</label
                        >
                        <input
                            type="password"
                            name="pass"
                            id="signup-share-pass"
                            required
                            class="form-control input-lg signup-input-pass"
                            size="30"
                            placeholder="Password"
                            [(ngModel)]="password"
                            #pass="ngModel"
                            syncShowHidePassword
                            identity="shareprovisionpass"
                        />
                    </div>
                    <div class="form-group">
                        <!--[if lt IE 10]>Confirm Password<![endif]-->
                        <label
                            class="field-label notshown"
                            for="signup-share-pass2"
                            >Confirm password:</label
                        >
                        <input
                            type="password"
                            name="pass2"
                            id="signup-share-pass2"
                            class="form-control input-lg"
                            required
                            size="30"
                            placeholder="Confirm Password"
                            [(ngModel)]="password2"
                            #pass2="ngModel"
                            syncShowHidePassword
                            identity="shareprovisionpass"
                        />
                    </div>
                    <div class="form-group">
                        <label for="signup-share-reset">
                            <span class="help-block">
                                <input
                                    name="resetPassword"
                                    id="signup-share-reset"
                                    type="checkbox"
                                    [(ngModel)]="reset"
                                    #resetPassword="ngModel"
                                />
                                Enable forgotten password recovery (recommended)
                            </span>
                        </label>
                        <br />

                        <label for="signup-share-terms">
                            <span class="help-block">
                                <input
                                    name="conditions"
                                    id="signup-share-terms"
                                    type="checkbox"
                                    required
                                    [(ngModel)]="terms"
                                    #conditions="ngModel"
                                />

                                I accept the
                                <a
                                    href="https://www.sync.com/terms/"
                                    rel="noopener"
                                    target="_blank"
                                    style="text-decoration: underline"
                                    >Sync terms of service</a
                                >
                                and
                                <a
                                    href="https://www.sync.com/privacy"
                                    rel="noopener"
                                    target="_blank"
                                    style="text-decoration: underline"
                                    >privacy policy</a
                                >.
                            </span>
                        </label>
                    </div>
                    <div class="form-group">
                        <button
                            [disabled]="spinners.provision"
                            class="signup-input-submit btn btn-primary white btn-lg signup-button"
                            style="width: 100%"
                        >
                            <i
                                class="fa fa-spin fa-spinner"
                                [class.hide]="!spinners.provision"
                            ></i>
                            Create account and join share
                        </button>
                    </div>
                </form>
            </div>
            <!-- signup div -->

            <hr />

            <h2 class="text-center">Need help?</h2>
            <p class="text-center">
                <a
                    rel="noopener"
                    class="syncblue"
                    href="https://www.sync.com/support"
                >
                    Click here to contact our support team</a
                >
            </p>
            <br />
            <br />
        </div>
        <!-- col -->
    </div>
    <!-- row -->
</div>
<!-- container -->
