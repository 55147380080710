
<div class="modal-header">
  <button type="button" class="close" (click)="modalInstance.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Manage share {{shareData.name}}</h4>
</div>

<div class="modal-body">
  <sync-error-display-hash [errors]="errors" ></sync-error-display-hash>

  <div class="row">
      <div class="col-sm-4">Share label:</div>
      <div class="col-sm-8">
          <input
              type="text"
              placeholder="{{shareData.name}}"
              class="form-control"
              [(ngModel)]="shareData.label"
              maxlength="80"
          />
      </div>
  </div>
  
  <hr>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default pull-left" (click)="openPopup(5)">Unshare folder</button>

  <button
      type="button"
      class="btn btn-primary"
      (click)="setLabel()"
  >
      <i class="fa fa-spinner fa-spin" [class.hide]="!spinners.label"></i>
      Save settings
  </button>

  <button type="button" class="btn btn-default" (click)="modalInstance.close()">Cancel</button>
</div>
