import { Injectable } from '@angular/core';

import * as sjcl from 'sjcl';
@Injectable()
export class Base64Service {

    constructor() {
    }

    public encode(str: string) {
        if (!str) { return str; }
        return sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(str));
    }

    /**
     * @ngdoc method
     * @name  decode
     * @methodOf sync.service:Base64
     * @description
     * Decodes b64 string. Use sjcl for base64 work because of UTF8 issues.
     * @param  {string} b64 The string to decode
     * @return {string}     plain text string
     */
    public decode(b64: string) {
        if (!b64) { return b64; }
        try {
            return sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(b64));
        } catch (ex) {
            return b64;
        }
    }

    /**
     * @ngdoc method
     * @name  encodeBits
     * @methodOf sync.service:Base64
     * @description
     * Encode a bitArray as a b64 string
     * @param  {Array} bitArray The sjcl bitArray to decode
     * @return {string}     b64 string
     */
    public encodeBits(bitArray: sjcl.BitArray): string {
        return sjcl.codec.base64.fromBits(bitArray);
    }

    /**
     * @ngdoc method
     * @name  decodeBits
     * @methodOf sync.service:Base64
     * @description
     * Encode a b64 string into a bitArray
     * @param  {String} b64 The b64 string to encode to a sjcl bitarray
     * @return {Array}      a SJCL bitArray.
     */
    public decodeBits(b64: string): sjcl.BitArray {
        return sjcl.codec.base64.toBits(b64);
    }
}
