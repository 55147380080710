

<div class="container-fluid container-fluid-wrap">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">
                    Dropbox Import Utility
            </div>
        </div>
    </div>
    <!-- notification -->
    <!--div class="error-general-overlay" ngIf="errMsg">
        <div class="row" >
        <div class="col-xs-9" >

        <i class="fas fa-info-circle"></i>
            {{errMsg}}
        </div>
        <div class="col-xs-3 text-right"></div>
        </div>
    </div-->
    <div class="row">
        <div class="col-sm-12 content-box-withmenu content-box-no-header">
                
        <span *ngIf="loading">
            <h3>
                <i class="fa fa-spinner fa-spin"></i>
            </h3>
        </span>
        <div *ngIf="!code && !polldata && !loading" class="well text-center">
            Sync requires access to your Dropbox account to begin the migration process.<br/> 
            Please click the button below to give Sync access to your Dropbox files.<br/> <br/>
            <a href="https://www.dropbox.com/1/oauth2/authorize?redirect_uri=https%3A%2F%2Fcp.sync.com%2Faccount%2Fmigrate&response_type=code&client_id=v3jqnttjoy2x2oj"
                rel="noreferrer noopener"
                class="btn btn-success btn-lg"
                style="color:white;"
            >Link Dropbox account</a>
            <br/><br/>
        </div>

        <div *ngIf="code && !polldata && !loading" class="well text-center">
            Success! Your Dropbox account has been linked.<br/>
            Click the button below to import your files.<br/><br/>

            <span *ngIf="migrationstarted === false && !migratespinner">
                <i class="fas fa-exclamation-triangle"></i>
                Failed to start job, please try again later.
                <br/>
            </span>
            <button class="btn btn-success btn-lg"
                (click)="doMigrate()"
                [disabled]="migratespinner"
                *ngIf="migrationstarted !== true"
            >
                <i class="fa fa-spinner fa-spin" *ngIf="migratespinner"></i>
                Get Started
            </button>
            <span *ngIf="migrationstarted === true">
                Job successfully started
            </span>
            <br/><br/>
        </div>
        
        <div *ngIf="polldata && !loading" class="well">
            <span *ngIf="polldata.jobstatus == -1">
                <i class="fa-spin fa fa-refresh"></i>
                Running...
            </span>
            <span *ngIf="polldata.jobstatus == 0">
                <i class="fa-spin fa fa-refresh"></i>
                Pending...
            </span>
            <span *ngIf="polldata.jobstatus == 1">
                <i class="fa-spin fa fa-refresh"></i>
                Setting up transfer...
            </span>
            <span *ngIf="polldata.jobstatus == 2">
                <i class="fa-spin fa fa-refresh"></i>
                Getting file data from Dropbox...
            </span>
            <span *ngIf="polldata.jobstatus == 3">
                <i class="fa-spin fa fa-refresh"></i>
                Importing directories...
            </span>
            <span *ngIf="polldata.jobstatus == 4">
                <i class="fa-spin fa fa-refresh"></i>
                Importing files...
            </span>
            <span *ngIf="polldata.jobstatus == 5">
                <i class="ion-checkmark-circled fa-lg syncblue"></i>
                Finished
                <span class="pull-right">
                    Click
                    <a routerLink="/files" class="syncblue showhand">
                        here
                    </a>
                    to view your files
                </span>
            </span>
            <span *ngIf="polldata.jobstatus == 6">
                <i class="fa fa-exclamation-triangle fa-lg"></i>
                Error: 
                <a routerLink="/account/migrate" [queryParams]="{code:null}" class="syncblue showhand">
                    click here
                </a>
                to retry
            </span>
            <span *ngIf="polldata.jobstatus == 7"><i class="fa fa-exclamation-triangle fa-lg"></i>
                Error: 
                <a routerLink="/account/migrate" [queryParams]="{code:null}" class="syncblue showhand">
                    click here
                </a>
                to retry
            </span>
            <span *ngIf="polldata.jobstatus == 8">
                <i class="fa fa-exclamation-triangle fa-lg"></i>
                Cancelled 
                <a routerLink="/account/migrate" [queryParams]="{code:null}" class="syncblue showhand">
                    click here
                </a>
                to retry
            </span>
            <span *ngIf="polldata.jobstatus == 9">
                <i class="fa fa-exclamation-triangle fa-lg"></i>
                Not enough space: please
                <a routerLink="/account/upgrade" class="syncblue showhand">
                    upgrade
                </a>
                your account and try again.
                <br>
                Your current quota is {{user.disk_limit_bytes|bytesToSize}} and your Dropbox account contains {{polldata.totalbytes | bytesToSize}}.
            </span>
            <!--span *ngIf="polldata && [-1,0,1,2,3,4,5,6,7,8,9].indexof(polldata.jobstatus) == -1">Unknown status</span-->

            <br/><br/>

            <span class="sync-col-modalstatus" *ngIf="polldata.jobstatus < 6">
                {{jobprogresspercent | number:'1.1-1'}} %
                <ngb-progressbar
                animate="false"
                [value]="jobprogresspercent"
                [max]="100"
                class="progress modal-transfer-progress" 
                style="height:16px;margin:0px;padding:0px;background-color:white;margin-top:-3px;box-shadow: none;width:100%;"
                >
                </ngb-progressbar>

                <!--ngb-progressbar animate="false" value="jobprogresspercent">
                    {{jobprogresspercent | number}} %
                </ngb-progressbar-->
            </span>
            <hr/>

            <span *ngIf="polldata.jobstatus < 5">
                <span class="pull-right">
                    <i *ngIf="cancelerrcode" class="fas fa-exclamation-triangle"></i>
                    <span *ngIf="cancelerrcode == 2">Migration already cancelled</span>
                    <span *ngIf="cancelerrcode == 1">No migration job running</span>
                    <span *ngIf="cancelerrcode && [2, 1, undefined].indexOf(cancelerrcode) == -1">
                        Error cancelling the migration job, please try again later
                    </span>
                    <span *ngIf="cancelerrcode != 0 && cancelerrcode != 1 && cancelerrcode != 2">
                        <i class="fa fa-spinner fa-spin" *ngIf="jobspinner"></i>
                        <span *ngIf="jobspinner">
                            Cancelling...
                        </span>
                        <span [hidden]="jobspinner" class="syncblue showhand" (click)="stopMigrate()">
                            Cancel
                        </span>
                    </span>
                    <span *ngIf="cancelerrcode == 0">
                        Cancelled
                    </span>
                </span>
            </span>


            <span *ngIf="polldata.jobstatus > 1 && polldata.jobstatus < 5">

                <div>
                    <span [hidden]="polldata.jobstatus > 2">
                        Files detected: {{polldata.totalfiles | number}} files ({{polldata.totalbytes | bytesToSize}})<br/>
                        Files excluded: {{polldata.filesexcluded | number}} files ({{polldata.bytesexcluded | bytesToSize}})<br/>
                    </span>
                    <span *ngIf="polldata.jobstatus > 3 && polldata.jobstatus < 5 && polldata && polldata.curfiles.length > 0">
                        Transferring: {{polldata.curfiles[0].file_name}} ({{totalfiles - filesprocessed | number}} files remaining)<br/><br/>
                    </span>
                    <span *ngIf="polldata.jobstatus > 2 && polldata.jobstatus < 5" (click)="showDetails = !showDetails" class="syncblue showhand" style="margin-right:16px;">
                        {{showDetails ? 'Hide' : 'Show'}} details
                    </span>
                    <div *ngIf="showDetails">
                        <br/>
                        <span *ngIf="polldata.jobstatus > 2">
                            <b>
                                Job summary:
                            </b>
                            <br/>
                            Successful files: {{polldata.filesdone | number}} files ({{polldata.bytesdone | bytesToSize}})<br/>
                            Excluded files: {{polldata.filesexcluded | number}} files ({{polldata.bytesexcluded | bytesToSize}})<br/>
                            Failed files: {{polldata.filesfailed | number}} files ({{polldata.bytesfailed | bytesToSize}})<br/>
                            Total files: {{polldata.totalfiles | number}} files ({{polldata.totalbytes | bytesToSize}})<br/>

                            Successful folders: {{polldata.foldersdone | number}} folders<br/>
                            Excluded folders: {{polldata.foldersexcluded | number}} folders<br/>
                            Failed folders: {{polldata.foldersfailed | number}} folders<br/>
                            Total folders: {{polldata.totalfolders | number}} folders<br/>
                        </span>
                    </div>
                </div>
            </span>
        </div>
        
        <div *ngIf="!migrateeventsloading && migrateeventlist.length > 0">
            <div class="row">
                <div class="col-xs-12">
                    <div class="view-title">
                        History
                    </div>
                </div>
            </div>
        
            <div class="row">
                <div class="col-xs-12">
                    <table class="table list-table">
                        <thead>
                        </thead>
                        <tbody>
                            <tr class="sync-table-row"
                                *ngFor="let item of migrateeventlist"
                            >
                                <td class="sync-col-eventicon">
                                    <i class="fa fa-lg fa-download"></i>
                                </td>

                                <!--span sync-event-list-item
                                    event-type="item.eventtype"
                                    event-item="item"
                                    event-sharedata="shareSyncIds"
                                    class="sync-col-eventname">
                                    <div class="hidden-md hidden-lg">
                                        <span>
                                            {{item.servtime | date:'medium'}}
                                        </span>
                                    </div>
                                </span-->
                                <td class="table-eventname">
                                    <!--span syncEventListItem
                                        EventType="'migrate-done'"
                                        EventItem="item"
                                        EventSharedata="ctrl.shareSyncIds"
                                        class="sync-col-eventname">
                                    </span-->
                                    <span *ngIf="item.eventtype == 'migrate-done'">

                                        <!--TODO: reimplement isOverQuota(), need to figure out how to get bitwise operation in ngif-->

                                        <span *ngIf="item.items.jobstatus == 5 && !migrateEventIsOverQuota(item)">
                                            Your 
                                            <span *ngIf="item.items.jobtype == 'dropbox'">Dropbox</span>
                                            <span *ngIf="item.items.jobtype != 'dropbox'">[unknown type]</span>
                                                file migration
                                            <span *ngIf="item.items.hasfailedfiles == 0">has completed!</span>
                                            <span *ngIf="item.items.hasfailedfiles != 0">has completed, but with failed files!</span>
                                        </span>

                                        <span *ngIf="item.items.jobstatus == 5 && migrateEventIsOverQuota(item)">
                                            Your 
                                            <span *ngIf="item.items.jobtype == 'dropbox'">Dropbox</span>
                                            <span *ngIf="item.items.jobtype != 'dropbox'">[unknown type]</span>
                                            file import did not complete, because your account doesn't have enough space.
                                            Please <a routerLink="/account/upgrade" class="syncblue showhand">upgrade</a> your account and try again.
                                        </span>
                                        <span *ngIf="item.items.jobstatus == 6">
                                            Migration failed due to internal errors!
                                        </span>
                                        <span *ngIf="item.items.jobstatus == 7">
                                            Couldn't import file due to authorization errors!
                                        </span>
                                        <span *ngIf="item.items.jobstatus == 8">
                                            File migration was cancelled!
                                        </span>
                                        <span *ngIf="item.items.jobstatus == 9">
                                            Couldn't start your
                                            <span *ngIf="item.items.jobtype == 'dropbox'">Dropbox</span>
                                            <span *ngIf="item.items.jobtype != 'dropbox'">[unknown type]</span>
                                            migration since your account does not have enough free space. (required space: {{item.items.extra.total_bytes | bytesToSize}})
                                            Please <a routerLink="/account/upgrade" class="syncblue showhand">upgrade</a> your account to a premium plan.
                                        </span>
                                    </span>
                                    <span *ngIf="item.eventtype == 'migrate-started'">
                                        Your 
                                        <span *ngIf="item.items.jobtype == 'dropbox'">
                                            Dropbox
                                        </span>
                                        <span *ngIf="item.items.jobtype != 'dropbox'">
                                            [unknown type]
                                        </span>
                                        file migration has started.
                                    </span>
                                </td>
                    
                                <td class="table-eventdate">
                                    {{item.servtime | date:'medium'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
