import { SafeHtml } from '@angular/platform-browser';
import { ErrorMessages } from './error-messages.model';


export class ErrCode {
    constructor(public code: number, public msg?: string | SafeHtml, public data?: any) {

        if (!msg && ErrorMessages[code]) {
            this.msg = ErrorMessages[code];
        }
    }

    public static fromError(e: Error) {
        return new ErrCode(1000, e.message);
    }

    public static fromApiLegacy(e: any) {
        if (e.error_code) {
            return new ErrCode(e.error_code, e.error_msg, e.data);
        } else {
            return new ErrCode(1000);
        }
    }

    public static fromException(e: any, ctx?: string) {
        const err = new Error();
        console.error(`ErrCode.fromException call stack ${err.stack}`);
        if (e instanceof ErrCode) {
            return e;
        } else if (e.status > 0) {
            return new ErrCode(e.status, e.error.message);
        } else if (e.code > 0) {
            return new ErrCode(e.code, e.msg);
        } else if (e instanceof Error) {
            return ErrCode.fromError(e);
        } else if (e.error_code) {
            return ErrCode.fromApiLegacy(e);
        } else {
            if (ctx) {
                return new ErrCode(1000, ctx);
            } else {
                return new ErrCode(1000, 'An unknown error occurred');
            }
        }
    }
}
