import { Injectable } from '@angular/core';
import { Base64Service } from './base64.service';
import { SyncDigestService } from './crypt/sync-digest.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { environment } from '../../environments/environment';
import { PathListApiOutput, LinkPathListApiOutput } from '../shared/models/api';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from './logger.service';

// import ko

@Injectable({
    providedIn: 'root'
})
export class UrlService {

    private userId: number;
    private deviceId: number;

    public downloadhosts = [ '/mfs' ];
    public uploadhosts = [ '/mfs'];

    private hosts = {
        web: [ '/mfs' ],
        compat: [ '/mfs' ],
        preview: ['/mfs'],
        news: []
    };

    constructor(
        private base64: Base64Service,
        private digest: SyncDigestService,
        private http: HttpClient,
        private log: LoggerService,
        private store: Store<fromRoot.State>
    ) {
        this.init();
    }

    private init() {
        this.store
        .select(fromRoot.getAuthState)
        .subscribe((data) => {
            if (!data.authenticated) {
                this.userId = 0;
                this.deviceId = 0;
            } else if (data.user && data.authenticated) {
                this.userId = data.user.uid;
                this.deviceId = data.user.web_device_id;
            } else {
                this.userId = 0;
                this.deviceId = 0;
            }
        });
    }

    public setHostMulti(pathlist: PathListApiOutput | LinkPathListApiOutput) {
        const hostTypes = ['web', 'compat', 'preview', 'news'];
        hostTypes.forEach((hostType) => {
            if (pathlist['servers_' + hostType]) {
                this.setHostByType(hostType, pathlist['servers_' + hostType]);
            }
        });
        // this.hosts['web'].reverse();
        this.log.info(`Using hosts ${JSON.stringify(this.hosts)}`);
    }

    public setHostByType(type: string, hosts: string[]) {
        if (hosts && hosts.length) {
            hosts = hosts.map((val) => 'https://' + val);
            this.hosts[type] = hosts;
            if (type == 'web') {
                this.uploadhosts = hosts.slice(0);
                this.downloadhosts = hosts.slice(0);
                // this.uploadhosts.reverse();
                // this.downloadhosts.reverse();
            }

        }
    }

    public getHostByType(type: string): string[] {
        return this.hosts[type] || [];
    }

    // get $stateParams(): StateParams {
    //     return this.injector.get('$stateParams');
    // }

    // get $state(): StateService {
    //     return this.injector.get('$state');
    // }

    // handles both a shell-expanded string and plain string
    // e.g.,
    // https://{secure11.sync.com,secure21.sync.com}/proxyapi.fcgi?command=
    // and
    // https://secure11.sync.com/proxyapi.fcgi?command=
    // public setHosts(hosts: sync.IServerAddresses) {
    //     if (hosts.downloadbaseurl && hosts.downloadbaseurl.length) {
    //         let hostStr = this.base64.decode(hosts.downloadbaseurl[0]);
    //         this.downloadhosts = this.getHosts(hostStr);
    //     }
    //     if (hosts.uploadbaseurl && hosts.uploadbaseurl.length) {
    //         let hostStr = this.base64.decode(hosts.uploadbaseurl[0]);
    //         this.uploadhosts = this.getHosts(hostStr);
    //     }

    //     // This is a user id that is even.
    //     if (this.userId % 2 === 1) {
    //         // reverse the hosts.
    //         this.uploadhosts.reverse();
    //         this.downloadhosts.reverse();
    //     }
    //     // console.log(`U: ${angular.toJson(this.uploadhosts)}, D: ${angular.toJson(this.downloadhosts)}`);
    // }

    // private getHosts(hosts: string) {
    //     const braces = /\{(.*)\}/;
    //     return hosts.match(braces)
    //       ? hosts.match(braces)[1].split(',').map(item => hosts.replace(braces, item))
    //       : [ hosts ];
    // }

    /**
     * This method is used to proxy the request locally if a password is enabled on the acct.
     * @param cachekey link chache key
     */
    private getCompatDownloadPath(cachekey: string) {
        // console.log('getCompatDownloadHost ' + this.downloadhosts[0].replace('/proxyapi.fcgi?command=',''));
        // if not cors, return /mfs-${cachekey}
        return `/mfs-${cachekey}`;
    }

    /**
     * Gets the return to value from the URL and returns it as a URL Encoded
     * component to be added to the login/logout state.
     */
    public getReturnTo(): string {
        let returnTo = '';
        if (window.location.pathname.indexOf('logout') > -1 || window.location.pathname.indexOf('login') > -1) {
            returnTo = undefined;
        } else {
            returnTo = encodeURIComponent(window.location.pathname + window.location.search);
        }
        return returnTo;
    }

    public mkSubscribe() {
        return [
            '/sub?cid=t_',
            this.userId,
            '&timestamp=', Date.now()
        ].join('');
    }


    public mkApi(host: string, apiName: string) {
        return this.decorateUrl([
             host, '?command=', apiName
        ]);
    }

    // called in legacy, no "references" will be found - see upload-service
    public mkUpload(host: string, apiName: string): string {
        return this.decorateUrl([host, '/upload.fcgi?command=', apiName]);
    }

    public mkDownloadMulti() {
        return this.decorateUrl([this.downloadhosts[0], '/proxyapi.fcgi?command=downloadmulti']);
    }

    public async mkDownloadPubLink(tItem: sync.ITransferItemDownload, params: sync.ILinkSignParams, retry = 0): Promise<string> {
        let baseDomain = '';
        const host = (environment.production) ? 'compat' : 'web';
        this.log.info(`Make download publink host: ${this.hosts[host][retry]}`);
        try {
            const result = await this.http.get(
                this.hosts[host][retry] + '/isup.txt',
                { responseType: 'text'}
            ).toPromise();
            baseDomain = this.hosts[host][retry];
            return baseDomain + this.mkDownloadPubLinkPassword(tItem, params);
        } catch (ex) {
            this.log.warn(`Isup failed for ${this.hosts[host][retry]}/isup.txt, retryamt = ${retry}, hosts: ${this.hosts[host].length}`);
            if (this.hosts[host][retry + 1]) {
                this.log.warn(`Retrying ${host} publink ${retry}`);
                return await this.mkDownloadPubLink(tItem, params, retry + 1);
            } else {
                this.log.error(`Failed to find a valid host, falling back to proxy`);
                return this.mkDownloadPubLinkPassword(tItem, params);
            }
        }
    }

    public mkDownloadPubLinkPassword(tItem: sync.ITransferItemDownload, params: sync.ILinkSignParams): string {
        const qs: string[] = [];
        for (const key in params) {
            qs.push(`${key}=${params[key]}`);
        }
        qs.push('cachekey=' + tItem.cachekey);
        return [
            this.getCompatDownloadPath(tItem.cachekey),
            '/p/',
            encodeURIComponent(tItem.filename),
            '?',
            qs.join('&')
        ].join('');
    }
    public getDownloadPubLinkParams(tItem: sync.ITransferItemDownload, rsaDatakey: string, encHost: string): sync.ILinkSignParams {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * When given a file name such as: Naïve file.txt
         *
         * Modern browsers will support rfc5987:
         * Content-Disposition: attachment; filename*=UTF-8''Na%C3%AFve%20file.txt
         *
         * Other browsers (e.g., Safari 5) do their own thing:
         * Content-Disposition: attachment; filename=Naïve file.txt
         *
         * Therefore we combine the two in one-header which has proven to be
         * compatible with all browsers we have tested.
         *
         * Here is some information:
         *
         * - We need to ensure when a browser downloads file, the name remains
         * - The challenge is that HTTP headers can only be ASCII.
         * - Force downloads are done via Content-Disposition attachment
         *   HTTP header.
         *
         * - Therefore, we encodeURI(Naïve file.txt) which results in: Na%C3%AFve%20file.txt
         * - if given to the browser in a content-disposition header, the browser
         *   will literally download the file as "Na%C3%AFve%20file.txt" and not
         *   convert it.
         * - This is why we have the convulted Content-Disposition header.
         *
         * Please only alter it if you've tested it on every browser known to man.
         *  - or just don't edit it.
         *
         * https://stackoverflow.com/questions/93551/how-to-encode-the-filename-parameter-of-content-disposition-header-in-http
         */
        return {
            'sharelink_id': tItem.sync_id,
            'linkoid': tItem.link_owner_id,
            'linkcachekey': tItem.linkID,
            'mode': 101,
            'datakey': rsaDatakey.replace(/=/g, ''),
            'header1': this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            'header2': this.base64.encode([
                            'Content-Disposition: ',
                            tItem.disposition,
                            '; filename="',
                            encodeURIComponent(tItem.filename),
                            '";',
                            'filename*=UTF-8\'\'',
                            encodeURIComponent(tItem.filename),
                            ';'
                        ].join('')).replace(/=/g, ''),
            'uagent': this.digest.hash(navigator.userAgent),
            'ipaddress': 's',
            'errurl': encHost,
            'timestamp': Date.now(),
            'engine':  `ln-${environment.version}`,
        };
    }

    public mkPreviewPubUser(tItem: sync.ITransferItemDownload, rsaDatakey: string, timestamp: number): string {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * Don't touch unless you read the full comment in getDownloadPubLinkParams()
         *
         * @TODO merge the URL with the output of getDownloadPubLinkParams()
         */
        return this.decorateUrl([
            this.getCompatDownloadPath(tItem.cachekey),
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&action=stream',
            '&preview=pdf',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                    'Content-Disposition: ',
                    tItem.disposition,
                    '; filename="',
                    encodeURIComponent(tItem.filename),
                    '";',
                    'filename*=UTF-8\'\'',
                    encodeURIComponent(tItem.filename),
                    ';'
                ].join('')).replace(/=/g, ''),
            '&servtime=' , timestamp

        ]);
    }

    public mkDownloadPubUser(tItem: sync.ITransferItemDownload, rsaDatakey: string, timestamp: number): string {
        /**
         * The weird Content-Disposition line is a requirement due to oddities with
         * UTF-8 names, browsers and HTTP headers requirement to be ascii.
         *
         * Don't touch unless you read the full comment in getDownloadPubLinkParams()
         *
         * @TODO merge the URL with the output of getDownloadPubLinkParams()
         */
        return this.decorateUrl([
            this.getCompatDownloadPath(tItem.cachekey),
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                    'Content-Disposition: ',
                    tItem.disposition,
                    '; filename="',
                    encodeURIComponent(tItem.filename),
                    '";',
                    'filename*=UTF-8\'\'',
                    encodeURIComponent(tItem.filename),
                    ';'
                ].join('')).replace(/=/g, ''),
            '&servtime=' , timestamp

        ]);
    }

    public internalDownloadMfsUrl(tItem: sync.ITransferItemDownload, rsaDatakey: string, timestamp: number): string {
        return this.decorateUrl([
            '/u/', encodeURIComponent(tItem.filename),
            '?cachekey=', tItem.cachekey,
            '&datakey=', rsaDatakey.replace(/=/g, ''),
            '&mode=101',
            '&api_version=1',
            '&header1=',
            this.base64.encode('Content-Type: ' + tItem.mimetype).replace(/=/g, ''),
            '&header2=',
            this.base64.encode([
                'Content-Disposition: ',
                tItem.disposition,
                '; filename="',
                encodeURIComponent(tItem.filename),
                '";',
                'filename*=UTF-8\'\'',
                encodeURIComponent(tItem.filename),
                ';'
            ].join('')).replace(/=/g, ''),
            '&servtime=', timestamp

        ]);
    }

    public async internalDownloadPubLink(tItem: sync.ITransferItemDownload, params: sync.ILinkSignParams, retry = 0): Promise<string> {
        let baseDomain = '';
        const host = (environment.production) ? 'compat' : 'web';
        this.log.info(`Make download publink host: ${this.hosts[host][retry]}`);
        try {
            const result = await this.http.get(
                this.hosts[host][retry] + '/isup.txt',
                { responseType: 'text' }
            ).toPromise();
            baseDomain = this.hosts[host][retry];
            return baseDomain + this.internalDownloadPubLinkPassword(tItem, params);
        } catch (ex) {
            this.log.warn(`Isup failed for ${this.hosts[host][retry]}/isup.txt, retryamt = ${retry}, hosts: ${this.hosts[host].length}`);
            if (this.hosts[host][retry + 1]) {
                this.log.warn(`Retrying ${host} publink ${retry}`);
                return await this.internalDownloadPubLink(tItem, params, retry + 1);
            } else {
                this.log.error(`Failed to find a valid host, falling back to proxy`);
                return this.internalDownloadPubLinkPassword(tItem, params);
            }
        }
    }

    public internalDownloadPubLinkPassword(tItem: sync.ITransferItemDownload, params: sync.ILinkSignParams): string {
        const qs: string[] = [];
        for (const key in params) {
           qs.push(`${key}=${params[key]}`);
        }
        qs.push('cachekey=' + tItem.cachekey);
        qs.push('nocache=1');
        return [
            '/p/',
            encodeURIComponent(tItem.filename),
            '?',
            qs.join('&')
        ].join('');
    }

    public mkDownloadUrl(tItem: sync.ITransferItemDownload, offset: number, reqLength: number, retry = 0): string {
        // console.error('MAKE  DOWNLOAD URL = ' + this.hosts['web'][0])
        const urlParts = [this.hosts['web'][retry], '/proxyapi.fcgi?command=download',
                '&cachekey=', tItem.cachekey,
                '&blobtype=', tItem.blobtype,
                '&offset=', offset,
                '&engine=', 'cp-' + environment.version,
                '&userid=', this.userId || 0,
                '&deviceid=', this.deviceId || 0,
                '&devicetypeid=3'
        ];
        if (tItem.blobtype == 'btFILE') {
            urlParts.push('&length=' + reqLength);
        }
        return this.decorateUrl(urlParts);
    }

    public mkExtApiUrl(path: string): string {
        return this.decorateUrl([
            environment.extapihost,
            `/${path}`,
            '?'
        ]);
    }


    private decorateUrl(urlParts: Array<(string | number)>): string {
        return urlParts.concat([
            '&engine=', 'cp-' + environment.version,
            '&userid=', this.userId,
            '&deviceid=', this.deviceId,
            '&devicetypeid=3'
        ]).join('');
    }
}
