import { Injectable } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as FileListActions from '../../actions/file-list.actions';
import {
    ErrCode,
    PathListApiOutput,
    EncFile,
} from '../../shared/models';
import { UrlService } from '../../core/url.service';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../core/logger.service';
import { FileItemService } from '../../file/file-item.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogAccountUpgradeComponent } from '../../account/dialog-account-upgrade/dialog-account-upgrade.component';
import { FileListService } from './file-list.service';

@Injectable({
    providedIn: 'root',
})
export class StarredListService {
    private totalFavoriteSubject = new BehaviorSubject<number>(0);
    total_favorite$: Observable<number> =
        this.totalFavoriteSubject.asObservable();

    constructor(
        private api: ApiService,
        private crypt: SyncCryptService,
        private dirtyOut: DirtyOutService,
        private store: Store<fromRoot.State>,
        private url: UrlService,
        private log: LoggerService,
        private fileItemService: FileItemService,
        private fileListService: FileListService,
        private modalService: NgbModal
    ) {}

    public async addTag(
        syncId: number[],
        UserId: number
    ): Promise<PathListApiOutput> {
        try {
            const d = await this.api.execute<PathListApiOutput>('addtag', {
                sync_id: syncId,
                user_id: UserId,
            });
            this.store.dispatch(
                new FileListActions.FileListTotalFavoriteAction(
                    this.totalFavoriteSubject.value + syncId.length
                )
            );
            this.totalFavoriteSubject.next(
                this.totalFavoriteSubject.value + syncId.length
            );
            return d;
        } catch (err) {
            throw new ErrCode(1801, 'Failed to add star');
        }
    }

    public showFavorites(syncId: number) {
        this.store.dispatch(
            new FileListActions.FileListShowFavoritesAction({ syncId })
        );
    }

    public getFavoritsList() {
        return this.store.select(fromRoot.getFavoritsListState);
    }
    public async showFavoritesList(
        syncId: number,
        pathlist: sync.IFile[] = []
    ): Promise<sync.IFile[]> {
        try {
            const d = await this.api.execute<PathListApiOutput>('gettags', {
                sync_id: syncId,
            });
            const cwd = await this.fileItemService.format(d.cwd);
            this.store.dispatch(new FileListActions.FileListSetCwdAction(cwd));
            if (d.parents) {
                const parents: EncFile[] = [];
                for (let i = 0, len = d.parents.length; i < len; i++) {
                    const name =
                        d.parents[i].metaname == 'web' ||
                        d.parents[i].metaname == 'Web Uploads'
                            ? 'Vault'
                            : await this.crypt.filenameDecrypt(
                                  d.parents[i].metaname
                              );
                    parents.push(
                        new EncFile({
                            loc_id: d.parents[i].loc_id,
                            metaname: d.parents[i].metaname,
                            name: name,
                        })
                    );
                }
            }
            if (d.pathlist && d.pathlist.length) {
                for (let i = 0, len = d.pathlist.length; i < len; i++) {
                    if (
                        pathlist.some((file: sync.IFile) => {
                            return d.pathlist[i].sync_id === file.sync_id;
                        })
                    ) {
                        this.log.error(
                            `Duplicate sync ids '${d.pathlist[i].sync_id}' in pid '${syncId}'`
                        );
                        throw new ErrCode(
                            1800,
                            'Unable to access your starred files/folders right now. Please retry later.'
                        );
                    }
                    const file = d.pathlist[i];
                    file.rootpath = d.pathlist[i].rootpath;
                    pathlist.push(await this.fileItemService.format(file));
                }
                return pathlist;
            } else {
                return pathlist;
            }
        } catch (error) {
            throw ErrCode.fromException(error);
        }
    }

    public async removeTag(sync_id: number[]): Promise<PathListApiOutput> {
        try {
            const d = await this.api.execute<PathListApiOutput>('removetag', {
                sync_id: sync_id,
            });
            this.store.dispatch(
                new FileListActions.FileListTotalFavoriteAction(
                    this.totalFavoriteSubject.value - sync_id.length
                )
            );
            this.totalFavoriteSubject.next(
                this.totalFavoriteSubject.value - sync_id.length
            );
            return d;
        } catch (err) {
            throw new ErrCode(1802, 'Failed to remove star');
        }
    }

    public async setTotalFavorite(total_favorite: number) {
        this.store.dispatch(
            new FileListActions.FileListTotalFavoriteAction(total_favorite)
        );
        this.totalFavoriteSubject.next(total_favorite);
    }

    public openAccountUpgradeDialog() {
        const ref = this.modalService.open(DialogAccountUpgradeComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: true,
        });
        ref.componentInstance.featureName = 'Star';
        ref.componentInstance.title = 'Get more with Sync Pro';
        ref.componentInstance.message =
            'Upgrade to Pro to instantly star more than 3 files.';
        ref.componentInstance.bodyHeader =
            ' Supercharge your Sync with these Pro features';
        ref.componentInstance.bodyItems = [
            'Unlimited stars',
            'Go from gigabytes to terabytes of storage',
            'Save space with Sync CloudFiles',
            'Unlimited sharing and file requests',
            '30-day money back guarantee',
        ];
    }
}
