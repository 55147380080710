

<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Previewer preferences</h4>
</div>

<div *ngIf="user.can_preview">
    <div class="modal-body">

        <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p>
                You can now use Office Online to better integrate your workflow within Sync.
                Edit and view Microsoft Word documents, Excel spreadsheets and Powerpoint slideshows all from your web
                panel.
            </p>
        </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-sm-4">Previewer:</div>
            <div class="col-sm-8">
                <div class="radio radio-top">
                    <label>
                        <input type="radio" name="is_office" id="office-pref-product-enable" value="1" [(ngModel)]="is_office" />
                        Office Online
                    </label>
                </div>
                <div class="radio radio-bottom">
                    <label>
                        <input type="radio" name="is_office" id="office-pref-product-disable" value="0" [(ngModel)]="is_office" />
                        Sync
                    </label>
                </div>
            </div>
        </div>

        <div *ngIf="is_office === '1'">
            <hr />

            <div class="row">
                <div class="col-sm-4">Office 365 Account Type:</div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input type="radio" name="is_office_personal" id="office-pref-product-enable" value="0" [(ngModel)]="is_office_personal" />
                            Business
                        </label>
                    </div>
                    <div class="radio radio-bottom">
                        <label>
                            <input type="radio" name="is_office_personal" id="office-pref-product-disable" value="1" [(ngModel)]="is_office_personal" />
                            Personal
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-sm-12">
                <div class="subtext">
                    View the <a href="https://www.sync.com/help/office-online">Sync + Office Online documentation</a> for more more information.
                </div>
            </div>
        </div>



    </div>
    <div class="modal-footer">
        <button id="isoffice-save" type="button" class="btn btn-default pull-left le" (click)="reset()">
        Reset my preference
        </button>

        <button [disabled]="spinner" type="button" class="btn btn-primary" (click)="save()">
            <i *ngIf="spinner" class="fa fa-spin fa-spinner"></i>
            Save settings
        </button>
        <button type="button" class="btn btn-default" (click)="activeModal.close()">
        Close
        </button>
    </div>
</div>

<div *ngIf="!user.can_preview">
    <div class="modal-body">

        <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p>
                Upgrade to Sync Pro to edit and view Microsoft Word documents, Excel spreadsheets and Powerpoint slideshows all from your web panel.
            </p>
        </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-sm-4">Previewer:</div>
            <div class="col-sm-8">
                <div class="radio radio-top">
                    <label>
                        <input type="radio" name="is_office" id="office-pref-product-enable" value="1" disabled=true [(ngModel)]="is_office" />
                        Office Online
                    </label>
                </div>
                <div class="radio radio-bottom">
                    <label>
                        <input type="radio" name="is_office" id="office-pref-product-disable" value="0" disabled=true [(ngModel)]="is_office" />
                        Sync
                    </label>
                </div>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-sm-12">
                <div class="subtext">
                    View the <a href="https://www.sync.com/help/office-online">Sync + Office Online documentation</a> for more more information.
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            type="button"
            routerLink="/account/upgrade"
            (click)="activeModal.close()"
        >Upgrade</button>
        <button type="button" class="btn btn-default" (click)="activeModal.close()">
        Close
        </button>
    </div>
</div>