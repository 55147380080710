import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../core/api.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { LoggerService } from '../core/logger.service';
import { NotificationsService } from '../core/notifications.service';
import { BroadcastService } from '../shared/services/broadcast.service';
import { BatchActionService } from './batch-action.service';

@Injectable({
    providedIn: 'root'
})
export class BatchRestoreService extends BatchActionService {

    constructor(
        public broadcastService: BroadcastService,
        public loggerService: LoggerService,
        public syncCryptService: SyncCryptService,
        public notificationsService: NotificationsService,
        public apiService: ApiService
    ) {
        super(
            broadcastService,
            loggerService,
            syncCryptService,
            notificationsService,
            apiService
        );
    }

    public execute(syncIds: number[]): Subject<any> {
        const subject = new Subject();
        this.loggerService.info(`batchRestore(${syncIds.toString()})`);
        this.ACTION = this.ACT_RESTORE;
        this.view.globalCancel = false;
        this.view.completedBatch = [];
        this.view.total = syncIds.length;
        this.runBatchItems(syncIds, subject);
        return subject;
    }

    protected async prepareItemForAction(syncId: number): Promise<any> {
        if (this.view.globalCancel) {
            return this.handleCancel();
        } else {
            this.resetProgress();
            this.view.spinner = syncId;
            try {
                const dumpList = await this.dumpDirs(syncId, -1);
                const dumpItems = await this.dropShare(dumpList);
                const syncIds = await this.convertToArray(dumpItems);
                this.view.total = syncIds.length;
                if (this.view.globalCancel) {
                    return this.handleCancel();
                }
                return await this.runProcessTask(syncIds, 0);
            } catch (err) {
                console.log('batch-restore prepareItemForAction err: ', err);
                return this.loggerService.handleError(
                    'batch-restore prepareItemForAction',
                    err
                );
            }
        }
    }

    protected apiExecuteTask(syncId: number): Promise<any> {
        return this.apiService.execute('batchrestore', { sync_id: syncId });
    }
}
