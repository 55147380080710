import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytesToSize'
})
export class BytesToSizePipe implements PipeTransform {

    transform(bytes: number, args?: any): any {
      if (!bytes && bytes !== 0) { return ''; }
      // var sizes = ['bytes', 'KB', 'MB', 'GB'];
      const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      if (bytes === 0) { return '0 bytes'; }
      const x = Math.log(bytes) / Math.log(1024);
      const i = Math.floor(x);
      if (i === 0) { return (bytes / Math.pow(1024, i)) + ' ' + sizes[i]; }
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

}
