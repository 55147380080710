import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private eventsDispatcher = new Subject<any>();

  constructor() {}

  broadcast(type: string, payload?: any) {
    this.eventsDispatcher.next({ type, payload });
  }
  public on(type: string): Observable<unknown> {
    return this.eventsDispatcher.asObservable().pipe(
      filter((eventDetails) => eventDetails.type === type),
      map((eventDetails) => eventDetails.payload),
    );
  }

}
