import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { ShareListService } from '../share-list.service';
import { LoggerService } from '../../core/logger.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { ShareService } from '../share.service';
import { UserService } from '../../core/user.service';
import { BroadcastService } from 'src/app/shared/services/broadcast.service';
import { Router } from '@angular/router';
import { PermissionActions } from 'src/app/shared/models';
import { Subscription } from 'rxjs';
@Component({
    selector: 'sync-external-share-list',
    templateUrl: './external-share-list.component.html',
})
export class ExternalShareListComponent implements OnInit, OnDestroy {
    public shares: any;
    public externalShares: any;
    public filter = '';
    public showFilter = false;

    public isLoaded = false;

    public showInfo = false;
    public showRemoved = false;
    public predicate = 'name';
    public predicateReverse = false;
    public externalShareCount = 0;
    public isTeamsPlusUser = false;

    public shareListInProgress = false;
    private shareListReloadSub: Subscription;

    public spinners = {
        confirm: 0,
        remove: false,
    };

    constructor(
        private api: ApiService,
        private shareListService: ShareListService,
        private loggerService: LoggerService,
        private syncCryptService: SyncCryptService,
        private shareService: ShareService,
        private userService: UserService,
        private router: Router,
        private broadcastService: BroadcastService,
    ) {}

    ngOnInit() {
        this.isTeamsPlusUser =
            this.userService.get('is_multi_new') &&
                this.userService.get('is_unlimited_plan')
                ? true
                : false;
        this.loadShare();

        // The notifications is running this code here.
        this.shareListReloadSub = this.broadcastService.on('event:share-list.reload').subscribe(() => {
            console.log('event reload');
            this.loadShare();
        });
    }

    ngOnDestroy() {
        if (this.shareListReloadSub) {
            this.shareListReloadSub.unsubscribe();
        }
    }

    public trackByFn(index, share) {
        return share.uniqid;
    }

    public hasShareFolderPermission() {
        return this.userService.getPermssionByKey(
            PermissionActions.SHARE_FOLDERS
        );
    }

    private async loadShare() {
        if (this.shareListInProgress) { return; }

        if (this.isTeamsPlusUser) {
            this.isLoaded = false;
            const sharelist = await this.getList();
            this.shares = sharelist;
            const isShareAdmin = this.shares.active.find(
                (share) => share.is_owner === 1
            )
                ? true
                : false;

            this.externalShares = isShareAdmin
                ? this.shares.active.filter((share) => share.is_owner === 1)
                : [];
            this.externalShareCount = isShareAdmin
                ? this.externalShares.length
                : 0;
            this.isLoaded = true;
        } else {
            this.isLoaded = true;
            this.externalShareCount = 0;
            this.externalShares = [];
        }
    }

    /* API call for getting share list */
    async getList() {
        try {
            this.shareListInProgress = true;
            const data = await this.api.execute('sharelist', { external_user: 1 });
            await this.shareListService.processList(data);
            return data;
        }
        finally {
            this.shareListInProgress = false;
        }
    }

    openShareDetailsDailog(share, openState: string) {
        const webpath = Array.isArray(share) ? share[0] : share;
        const shareId = webpath.shareId || webpath.share_id;
        const state = !Number.isNaN(parseInt(openState, 10))
            ? parseInt(openState, 10)
            : 0;
        this.shareService.openDetails(shareId, state);
    }

    onToFiles(sync_id: number) {
        this.router.navigate(['/files', sync_id]);
    }

    public setPredicate(field: string, predicateReverse): void {
        this.predicate = field;
        this.predicateReverse = predicateReverse;
        const isAsc = !predicateReverse;
        if (this.shares.active.length > 1) {
            this.shares.active.sort((a, b) => {
                if (isAsc) {
                    return a[field].toString().localeCompare(b[field]);
                } else {
                    return b[field].toString().localeCompare(a[field]);
                }
            });
        }
    }
}
