import { WebPath } from '..';
import { BaseApiOutput } from './base-api.model';
import { EncFile } from '../enc-file.model';

export class PathListApiOutput extends BaseApiOutput {
    cwd: sync.IFile;
    hist_id: number;
    pathlist: sync.IFile[];
    sync_id: number;
    progress: number;
    servers: sync.IServerAddresses;
    servers_web: string[];
    servers_preview: string[];
    servers_compat: string[];
    last_metaname_digest: string;
    parents: EncFile[];
    total_favorite: number;
}

export class PathListApiInput {
    sync_id: number;
    showdeleted: number;
    hist_id: number;
    offset_metaname_digest: string;
    is_vault: boolean;
}


