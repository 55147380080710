import { Component, OnInit, Input } from '@angular/core';
import { MultiUserData, User, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordService } from '../../auth/services/password.service';

@Component({
    selector: 'sync-dialog-multiuser-settings',
    templateUrl: './dialog-multiuser-settings.component.html'
})
export class DialogMultiuserSettingsComponent implements OnInit {

    @Input() child: MultiUserData;
    @Input() openState: number;
    @Input() user: User;

    public errcode: ErrCode;

    // openState = 1
    public disablePurge: string;
    public pass1: string;
    public pass2: string;
    public parentPass: string;

    // openState = 2
    public rmPurge: string;

    public spinner: boolean;

    constructor(
        private api: ApiService,
        public activeModal: NgbActiveModal,
        private password: PasswordService
    ) { }

    ngOnInit() {
        this.spinner = false;
        this.disablePurge = this.child.has_disable_purge.toString();
        this.rmPurge = '0';
    }

    public async removeUser() {
        this.spinner = true;
        try {
            await this.api.execute('multiuserupdate', {
                action: 'remove',
                email: this.child.email,
                is_purge: parseInt(this.rmPurge, 10)
            });
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async saveSettings() {
        this.spinner = true;
        try {
            if (!this.pass1 || !this.pass2 || !this.parentPass) {
                this.errcode = new ErrCode(8305);
                this.spinner = false;
                return;
            }
            if (this.pass1 && !this.password.isPasswordStrong(this.pass1)) {
                this.errcode = new ErrCode(8304);
                this.spinner = false;
                return;
            }
            if (this.pass1 && this.pass2 && this.parentPass) {
                if (this.pass1 != this.pass2) {
                    this.errcode = new ErrCode(8121);
                    this.spinner = false;
                    return;
                }
                await this.password.resetPasswordMultiUser(
                    this.child.user_id,
                    this.parentPass,
                    this.pass1
                );
            }
            await this.api.execute('multiuserpermissions', {
                child_user_id: this.child.user_id,
                disablepurge: this.disablePurge
            });
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;

    }
}
