import { Injectable, Injector } from '@angular/core';
import { LoggerService } from '../../core/logger.service';
import { StorageService } from '../../core/storage/storage.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { TransferItemService } from './transfer-item.service';

import { TransferStatus } from '../transfer.model';
import { ErrCode } from '../../shared/models';
import { DownloadWorkerService } from './download-worker.service';
import { DownloadLegacyService } from './download-legacy.service';
import { BrowserSupportService } from '../../core/browser-support.service';
import { ApiService } from '../../core/api.service';
import { CryptEngine } from '../../shared/models/crypt-engine.model';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    private _Downloader: sync.IDownloadMethod;
    private _storeThumbs: sync.IStorageMethod;
    // public maxtransfersize = 1024 * 1024 * 100; // 25mb
    // public maxpreviewsize = 1024 * 1024 * 10; // 10mb
    public savemethod: string;
    // old = 131072
    // 1mb = 1048576
    // 2mb = 2097152
    // 3mb = 3145728
    // 4mb = 4194304
    // 5mb = 5242880
    // 10mb = 10485760
    public chunksize = 1024 * 1024 * 25; // 10mb

    constructor(
        private browser: BrowserSupportService,
        private crypt: SyncCryptService,
        private downloadLegacy: DownloadLegacyService,
        private downloadWorker: DownloadWorkerService,
        private log: LoggerService,
        private storage: StorageService,
        private transferItem: TransferItemService,
        private api: ApiService,
    ) {
        this.init();
    }

    private async init() {

        let Downloader: sync.IDownloadMethod; // :sync.IDownloadChunk
        switch (this.browser.getEncryptionEngine()) {
            case CryptEngine.NATIVE: Downloader = this.downloadWorker; break;
            case CryptEngine.BUFFER: Downloader = this.downloadWorker; break;
            case CryptEngine.LEGACY: Downloader = this.downloadLegacy; break;
            default: Downloader = this.downloadLegacy; break;
        }
        this._Downloader = Downloader;
        this.log.info('TRANSFER download method = ' + this.browser.getEncryptionEngine());
        this._storeThumbs = this.storage.getMemoryStorage();

    }
    public async downloadCompat(tItem: sync.ITransferItemDownload) {
        await this.storage.prepare();
        return this.downloadPlain(this.storage.getMemoryStorage(), tItem);
    }

    public async downloadPreview(tItem: sync.ITransferItemDownload) {
        await this.storage.prepare();
        return this.download(this.storage.getMemoryStorage(), tItem);

    }
    public async downloadItem(tItem: sync.ITransferItemDownload) {
        await this.storage.prepare();
        return this.download(this.storage.getDefaultStorage(), tItem);
    }


    public async saveThumb(tItem: sync.ITransferItemDownload, bytes: ArrayBuffer): Promise<sync.ITransferItem> {
        const fileSize = bytes.byteLength - Math.ceil(bytes.byteLength / 131072) * 36;
        try {
            const start = Date.now();
            this._storeThumbs.init({
                dirname: 'syncpreview',
                filesize: fileSize,
                filename: tItem.filename,
                mimetype: tItem.mimetype,
                cachekey: tItem.cachekey,
                blobtype: tItem.blobtype,
                path: ''
            });
            this.log.d('  - ' + (Date.now() - start) + ' ms to initialize - resolved');
            const decStart = Date.now();
            const data = await this.crypt.filedataDecrypt(new Uint8Array(bytes),
                tItem.data_key,
                0);
            const decEnd = Date.now();
            this.log.d('  - ' + (decEnd - decStart) + ' ms to decrypt data');

            const dlData = {
                bytearray: data,
                offset: 0,
                reqlength: 0,
                chunkLength: bytes.byteLength,
                tItem: tItem
            };
            const packetAmt = Math.ceil(dlData.offset / 131072),
                gcmOffset = packetAmt * 36;
            let writeOffset = dlData.offset - gcmOffset;
            if (writeOffset < 0) {
                writeOffset = 0;
            }
            const wr = Date.now();
            await this._storeThumbs.writeChunk(dlData.tItem, dlData.bytearray, writeOffset);
            const we = Date.now();
            this.log.d('  - ' + (we - wr) + ' ms of ThumbStore.writeChunk()');
            const renderData = await  this._storeThumbs.getRenderData(tItem);
            const re = Date.now();
            this.log.d('  - ' + (re - we) + ' ms of ThumbStore.getRenderData()');

            const renderFile = await this.prepareRenderData(renderData);
            const pe = Date.now();
            this.log.d('  - ' + (pe - re) + ' ms of prepareRenderData()');

            tItem.renderFile = renderFile;
            tItem.status = TransferStatus.STATUS_SUCCESS;
            this.log.d('  - ' + (Date.now() - start) + ' ms to saveThumb()');
        } catch (err) {
            tItem.status =  (err && err.errcode) ? err.errcode : TransferStatus.STATUS_ERROR;
            this.log.error('Caught an error in saveData for thumbs');
            this.log.error(err);
            // Logger.error(' - ERRCODE = ' + data.errcode);
        }
        return tItem;
    }

    private async download(store: sync.IStorageMethod, tItem: sync.ITransferItemDownload) {
        const stTime = Date.now();
        tItem = this.checkDownload(store, tItem);
        if (tItem.status !== TransferStatus.STATUS_WAITING) {
            this.log.warn('Item is not waiting, returning ' + tItem.status);
            return tItem;
        }
        tItem.status = TransferStatus.STATUS_WORKING;

        await store.init({
            dirname: 'syncpreview',
            filesize: tItem.filesize,
            filename: tItem.filename,
            mimetype: tItem.mimetype,
            cachekey: tItem.cachekey,
            blobtype: tItem.blobtype,
            path: ''
        });

        try {
            tItem = await this.processChunks(store, tItem);
            // console.log('--------------- tItem = ' + tItem.status);
            if (tItem.status >= TransferStatus.STATUS_ERROR) {
                return tItem;
            }
            const renderData = await store.getRenderData(tItem);
            const renderFile = this.prepareRenderData(renderData);
            tItem.renderFile = renderFile;
            const timeTaken = (Date.now() - stTime) / 1000;
            const bps = tItem.filesize / timeTaken;
            this.log.info(`Download completed ${timeTaken} seconds ${bps / 1000} kbps`);
            tItem.status = TransferStatus.STATUS_SUCCESS;
            this._Downloader.completed(tItem);
        } catch (ex) {
            if (!tItem.status || tItem.status == TransferStatus.STATUS_WORKING) {
                tItem.status = TransferStatus.STATUS_ERROR;
            }
        }


        return tItem;
    }


    private async downloadPlain(store: sync.IStorageMethod, tItem: sync.ITransferItemDownload) {
        const stTime = Date.now();
        tItem = this.checkDownload(store, tItem);
        if (tItem.status !== TransferStatus.STATUS_WAITING) {
            this.log.warn('Item is not waiting, returning ' + tItem.status);
            return tItem;
        }
        tItem.status = TransferStatus.STATUS_WORKING;

        await store.init({
            dirname: 'syncpreview',
            filesize: tItem.filesize,
            filename: tItem.filename,
            mimetype: tItem.mimetype,
            cachekey: tItem.cachekey,
            blobtype: tItem.blobtype,
            path: ''
        });

        try {


            const result = await this.api.fetchFilePlain(tItem)
            .catch((err) => {
                this.log.error('An error occured fetching plain file');
                tItem.status = TransferStatus.STATUS_ERROR;
            });

            // console.log(result);
            if (result) {
                this.storeData(store, {
                    bytearray: new Uint8Array(result, 0),

                    // We get these values all at once, so no need to keep track of position
                    offset: 0,
                    reqlength: 0,
                    chunkLength: 0,
                    tItem: tItem,
                });
            } else {
                this.log.error('An error occured');
            }





            // tItem = await this.processChunks(store, tItem);
            // console.log('--------------- tItem = ' + tItem.status);
            if (tItem.status >= TransferStatus.STATUS_ERROR) {
                return tItem;
            }
            const renderData = await store.getRenderData(tItem);
            const renderFile = this.prepareRenderData(renderData);
            tItem.renderFile = renderFile;
            const timeTaken = (Date.now() - stTime) / 1000;
            const bps = tItem.filesize / timeTaken;
            this.log.info(`Download completed ${timeTaken} seconds ${bps / 1000} kbps`);
            tItem.status = TransferStatus.STATUS_SUCCESS;
            this._Downloader.completed(tItem);
        } catch (ex) {
            if (!tItem.status || tItem.status == TransferStatus.STATUS_WORKING) {
                tItem.status = TransferStatus.STATUS_ERROR;
            }
        }


        return tItem;
    }

    private checkDownload(StorageMethod: sync.IStorageMethod, tItem: sync.ITransferItemDownload): sync.ITransferItemDownload {
        if (StorageMethod.maxsize > 0 && tItem.filesize > StorageMethod.maxsize) {
            this.log.warn('File is too large for this browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Max StorageFactory size: ' + StorageMethod.maxsize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = TransferStatus.STATUS_ERR_SIZE;
        } else if ((!tItem.filedata.viewable && this.browser.getFileSaveMethod() == 'none')) {
            this.log.warn('File cannot be opened in this browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = TransferStatus.STATUS_ERR_OPEN;
        } else if (tItem.filedata.viewable &&
            this.browser.getFileSaveMethod() == 'none' &&
            tItem.type != this.transferItem.TYPE_PREVIEW
        ) {
            this.log.warn('File save is incompatible with your browser');
            this.log.warn(' - filesize: ' + tItem.filesize);
            this.log.warn(' - Save method: ' + this.browser.getFileSaveMethod());
            this.log.warn(' - StorageFactory method: ' + this.browser.getFileStorageMethod());
            tItem.status = 3011;
        } else if (!this.browser.testXhr2()) {
            tItem.status = 3012;
        }
        return tItem;
    }


    private async storeData(StorageMethod: sync.IStorageMethod, dlData: sync.IStoreData): Promise<sync.IStoreData> {
        const packetAmt = Math.ceil(dlData.offset / 131072),
            gcmOffset = packetAmt * 36;
        let writeOffset = dlData.offset - gcmOffset;
        if (writeOffset < 0) {
            writeOffset = 0;
        }
        await StorageMethod.writeChunk(dlData.tItem, dlData.bytearray, writeOffset);
        return dlData;
    }



    private async processChunks(StorageMethod: sync.IStorageMethod, tItem: sync.ITransferItemDownload) {

        // console.log(tItem.status + ' --- status');
        let offset = 0;
        while (offset < tItem.enc_filesize) {
        // for (let len = tItem.enc_filesize, offset = 0; offset < len; offset + this.chunksize) {
            this.log.d(offset + ' --- < --- ' + tItem.enc_filesize);
            if (offset >= tItem.enc_filesize) {
                tItem.status = TransferStatus.STATUS_SUCCESS;
                return tItem;
            }
            const stTime = Date.now();
            const remaining = tItem.enc_filesize - offset;
            const reqChunk = (remaining > this.chunksize) ? this.chunksize : remaining;
            try {
                const result = await this._Downloader.getChunk(tItem, offset, reqChunk);
                const dlData = await this.storeData(StorageMethod, result);
                this.log.info(`processChunk ${(Date.now() - stTime)} ms`);

                this.log.info('filesize: ' + tItem.enc_filesize + ' Offset = ' + offset + ' reqChunk = ' + reqChunk + ' -- received = ' + dlData.chunkLength);
                // received chunk length, make next request with that offset
                offset += dlData.chunkLength;

                if (dlData.chunkLength + offset == offset) {
                    this.log.error('Chunk length was 0, something has gone wrong');
                    tItem.status = TransferStatus.STATUS_ERROR;
                    return tItem;
                }
                if (offset >= tItem.enc_filesize) {
                    tItem.status = TransferStatus.STATUS_SUCCESS;
                    return tItem;
                } else if (tItem.blobtype.indexOf('btTHUMB') > -1) {
                    // when it's a thumbnail, we don't know the size of the file.
                    // MFS should always give the thumbnail in it's entirety so
                    // if we got a response, we should assume it's the entire
                    // thumbnail.
                    return tItem;
                }
            } catch (err) {
                this.log.error(err);
                this.log.error('Error downloading chunk or storing it');
                tItem.status =  (err && err.errcode) ? err.errcode : TransferStatus.STATUS_ERROR;
                return tItem;
            }
        }
        return tItem;
    }

    private prepareRenderData(renderData: sync.IRenderData): sync.IRenderFile {
        const decStart = Date.now();
        const renderFile: sync.IRenderFile = {
                dl_ready: true,
                type: renderData.type,
                url: renderData.url,
                savemethod: this.browser.getFileSaveMethod()
            };
        // if (tItem.type == this.TransferItem.TYPE_PREVIEW)
        // this.Logger.info(`prepareRender save method = ${renderFile.savemethod}`);
        if (renderFile.savemethod == 'msblob') {
            renderFile.msblob = renderData.blob;
        }
        this.log.d('  - ' + (Date.now() - decStart) + ' ms to prepareRenderData()');
        return renderFile;
    }

}
