import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

import { User, OrderListApiOutput, InvoiceListItem, ErrCode, PermissionActions } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogAutorenewComponent } from '../dialog-autorenew/dialog-autorenew.component';
import { Subscription } from 'rxjs';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { zAccountInfo } from '../../shared/models/api/orderprofile-api.model';
import { LoggerService } from '../../core/logger.service';
import { DialogEmailBillingDocComponent } from '../dialog-email-billing-doc/dialog-email-billing-doc.component';
import { UserService } from '../../core/user.service';
import { DialogMultiAdminuserAddComponent } from '../../multi-admin/dialog-multiadminuser-add/dialog-multiadminuser-add.component';
import { PlanDetailsApiOutput } from '../../shared/models/api/plandetails.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sync-billing',
    templateUrl: './billing.component.html'
})
export class BillingComponent implements OnInit, OnDestroy {
    public user: User;
    public invoices: InvoiceListItem[];
    public zprofile: zAccountInfo;
    public zuoraDocs: {
        id: string;
        type: string;
        amount: number;
        balance: number;
        date: string;
    }[];
    public permissionActions = PermissionActions;

    public errcode: ErrCode;

    public profileExists = false;
    public recordScrubbed = false;
    public spinner = false;
    public emailSpinner = '';

    public emailSentSuccess: boolean;

    public showPurchaseUsersOption: boolean;
    public planDetails: PlanDetailsApiOutput;

    private sub: Subscription;
    public usersLimit: number;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private zuora: ZuoraAccountService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.usersLimit = this.userService.MAX_USERS;
        this.sub = this.store
            .select(fromRoot.getAuthState)
            .subscribe((data) => {
                if (!data.authenticated) {
                    console.warn('User is not authenicated');
                    this.user = new User();
                    // this.initialized = false;
                    return;
                }
                if (data.user) {
                    console.log('Setting user');
                    this.user = data.user;
                    // this.initialized = true;
                }
            });
        this.init();
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }


    public editProfile() {
        this.router.navigate(['/account/billing/profile']);
    }

    public autoRenewDialog() {
        const ref = this.modalService.open(DialogAutorenewComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.zprofile = this.zprofile;
        ref.result.then(() => this.loadBillingProfile());
    }

    private async loadProfileWithInterval() {
        let billingProfileRetryTimeout = 1000;
        // stop retrying after 120000 miliseconds (2 minutes).
        while (billingProfileRetryTimeout < 120000) {
            this.zprofile = await this.zuora.getProfile();
            billingProfileRetryTimeout += billingProfileRetryTimeout;
            if (this.route.snapshot.queryParams['newprofile'] == 0 && this.zprofile && !this.zprofile.defaultPaymentMethod) {
                break;
            } else if (this.route.snapshot.queryParams['newprofile'] == 1 && this.zprofile && this.zprofile.defaultPaymentMethod) {
                break;
            } else {
                await new Promise(resolve => {
                    setTimeout(() => resolve(true), billingProfileRetryTimeout);
                });
            }
        }
        return this.zprofile;
    }

    private async loadBillingProfile() {
        try {
            this.spinner = true;
            if (this.checkPermission(this.permissionActions.VIEW_BILLING) || this.route.snapshot.queryParams['newprofile']) {
                if (this.route.snapshot.queryParams['newprofile']) {
                    this.zprofile = await this.loadProfileWithInterval();
                } else {
                    this.zprofile = await this.zuora.getProfile();
                }
                this.recordScrubbed = (this.zprofile.name === 'Record scrubbed');
                if (this.zprofile && this.zprofile.accountNumber && !this.recordScrubbed) {
                    this.profileExists = true;
                }

                // sort by time, then if same, invoice number
                this.zprofile.billingDocuments = this.zprofile.billingDocuments
                    .sort(function (a, b) {
                        let result = 0;
                        const dateDiff = new Date(a.date).getTime() - new Date(b.date).getTime();
                        if (dateDiff == 0) {
                            result = a.number.toString().localeCompare(b.number,
                                undefined,
                                { numeric: true, sensitivity: 'base' });
                        } else {
                            result = dateDiff;
                        }
                        return result * -1;
                    });
            } else if (this.user.plan_id == 1) {
                // free account
            } else {
                this.errcode = new ErrCode(8505);

            }
            this.spinner = false;
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            this.log.e('errcode loading billing profile', ex);
        }
        // console.log('loadBillingProfile', this.profile);
    }

    public async emailInvoice(id: string) {

        const docs = this.zprofile.billingDocuments.filter((val) => val.id === id);

        if (docs.length !== 1) {
            this.log.error(`Error locating document id ${id} from billing documents ${JSON.stringify(this.zprofile.billingDocuments)}`);
            this.errcode = new ErrCode(1658);
            return;
        }
        const ref = this.modalService.open(DialogEmailBillingDocComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.doc = docs[0];
        ref.result.then((val) => {
            this.emailSentSuccess = val;
            window.setTimeout(() => this.emailSentSuccess = false, 5000);
        });
    }

    public purchaseUsers(): void {
        const ref = this.modalService.open(DialogMultiAdminuserAddComponent , {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.openState = 3;

        ref.componentInstance.plan_user_limit = this.planDetails.plan_user_limit;
        ref.componentInstance.plan_product_id = this.planDetails.plan_product_id;
        ref.componentInstance.plan_rate_plan_id = this.planDetails.plan_rate_plan_id;
        ref.componentInstance.plan_rate_plan_charge_id = this.planDetails.plan_rate_plan_charge_id;

    }

    private async init() {
        await this.loadBillingProfile();

        // only load invoices if user is NOT in zuora
        // if (!this.user.zuora_acct_key) {
        const result = await this.api.execute<OrderListApiOutput>('orderlist', {});
        this.invoices = result.invoicelist;
        // }
        if (this.userService.checkPermission(this.permissionActions.ADD_EDIT_USERS)) {
            this.planDetails = await this.api.execute<PlanDetailsApiOutput>('getPlanDetails', {});
            this.showPurchaseUsersOption = true;
        }
    }
}
