import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class MigrateStatusApiOutput extends BaseApiOutput {
    success: number;
    jobstatus: string;
    filesdone: number;
    filesfailed: number;
    filesexcluded: number;
    totalfiles: number;
    bytesdone: number;
    bytesfailed: number;
    bytesexcluded: number;
    totalbytes: number;
    foldersdone: number;
    foldersfailed: number;
    foldersexcluded: number;
    totalfolders: number;
    curfiles: number[];
}

export class MigrateStatusApiInput extends BaseApiInput {
    type: string;
    checkmongo: number;
}
