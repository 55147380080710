import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { User, OrderSessionNewApiOutput, ErrCode, PermissionActions } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { LoggerService } from '../../core/logger.service';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserService } from '../../core/user.service';


@Component({
    selector: 'sync-renew',
    templateUrl: './renew.component.html'
})
export class RenewComponent implements OnInit, OnDestroy {

    public user: User;
    public errcode: ErrCode;
    public cancelInFuture: boolean;
    public unableToRenew: boolean;
    public url: SafeUrl;
    public spinner: boolean;
    public permissionActions = PermissionActions;

    private unsub = new Subject<void>();


    constructor(
        private api: ApiService,
        private injector: Injector,
        private log: LoggerService,
        private sanitize: DomSanitizer,
        private store: Store<fromRoot.State>,
        private syncCookie: SyncCookieService,
        private zuora: ZuoraAccountService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.store
            .pipe(
                select(fromRoot.getAuthState),
                takeUntil(this.unsub)
            )
            .subscribe((data) => {
                if (!data.authenticated) {
                    this.user = new User();
                    // this.initialized = false;
                    return;
                }
                if (data.user) {
                    this.user = data.user;
                    this.doRenew(data.user);
                    this.unsub.next();
                    this.unsub.complete();
                    // this.initialized = true;
                }
            });
    }

    ngOnDestroy() {

    }

    public async doRenew(user: User) {
        if (this.userService.checkPermission(this.permissionActions.MANAGE_BILLING)) {
            this.spinner = true;
            const zprofile = await this.zuora.getProfile();
            if (zprofile.subscription) {
                if (zprofile.subscription.status === 'Cancelled') {
                    this.cancelInFuture = zprofile.subscription.status === 'Cancelled';
                    this.spinner = false;
                    return;
                } else {
                    const result = await this.api.execute<OrderSessionNewApiOutput>('ordersessionnew', {});
                    if (result && result.sessionId) {
                        this.syncCookie.setOrderSessionId(result.sessionId);

                        const unsafeUrl = (zprofile.balance > 0 && zprofile.unpaidInvoiceCount > 0)
                            ? `${environment.ordershost}/invoice`
                            : `${environment.ordershost}/renew`;
                        this.url = this.sanitize.bypassSecurityTrustUrl(unsafeUrl);
                        window.location.replace(unsafeUrl);
                    } else {
                        this.errcode = new ErrCode(8550);
                    }
                    this.spinner = false;
                }
            } else {
                this.errcode = new ErrCode(8551);
                this.spinner = false;
            }
        } else {
            this.unableToRenew = true;
        }
    }

}
