import { Component, OnInit, Input } from '@angular/core';
import { MultiAdminUserData, User, ErrCode, Role, ModalCloseStates } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordService } from '../../auth/services/password.service';
import { MultiAdminService } from '../../core/multiadmin.service';

@Component({
    selector: 'sync-dialog-multiadminuser-settings',
    templateUrl: './dialog-multiadminuser-settings.component.html'
})
export class DialogMultiAdminuserSettingsComponent implements OnInit {

    @Input() child: MultiAdminUserData;
    @Input() openState: number;
    @Input() user: User;
    @Input() multiadminService: MultiAdminService;
    @Input() rolesList: Role[];

    public errcode: ErrCode;

    // openState = 1
    public pass1: string;
    public pass2: string;
    public parentPass: string;

    // openState = 2
    public rmPurge: string;

    public spinner: boolean;
    public role: string;

    constructor(
        private api: ApiService,
        public activeModal: NgbActiveModal,
        private password: PasswordService
    ) { }

    ngOnInit() {
        this.spinner = false;
        this.role = this.child.roles[0];
        this.rmPurge = this.child.user_status_str === 'email sent' ? '1' : '0';
    }

    public async removeActiveUser() {
        this.spinner = true;
        try {
            await this.multiadminService.deleteProvisionedUser(this.child.user_id, this.child.roles);
            await this.api.execute('multiuserupdate', {
                action: this.child.user_status_str === 'email sent' ? 'suspenduser' : 'remove',
                email: this.child.email,
                is_purge: parseInt(this.rmPurge, 10)
            });
            this.activeModal.close(ModalCloseStates.REMOVED);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async removeProvisioningUser() {
        this.spinner = true;
        try {
            await this.multiadminService.deleteProvisioningUser(this.child.job_id);
            this.activeModal.close(ModalCloseStates.REMOVED);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async changePassword() {
        this.spinner = true;
        try {
            if (!this.pass1 || !this.pass2 || !this.parentPass) {
                this.errcode = new ErrCode(8305);
                this.spinner = false;
                return;
            }
            if (this.pass1 && !this.password.isPasswordStrong(this.pass1)) {
                this.errcode = new ErrCode(8304);
                this.spinner = false;
                return;
            }
            if (this.pass1 && this.pass2 && this.parentPass) {
                if (this.pass1 != this.pass2) {
                    this.errcode = new ErrCode(8121);
                    this.spinner = false;
                    return;
                }
            }
            await this.password.resetPasswordMultiUser(
                this.child.user_id,
                this.parentPass,
                this.pass1
            );
            this.activeModal.close(ModalCloseStates.PASSWORD_UPDATED);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;

    }

    public async removeUserTeamInvite() {
        this.spinner = true;
        try {
            const result = await this.multiadminService.deleteProvisioningUser(this.child.job_id);
            if (result.success) {
                this.activeModal.close(ModalCloseStates.REMOVED);
            }
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    public async updateUserRole() {
        this.errcode = null;
        this.spinner = true;
        if (this.role === this.child.roles[0]) {
            this.errcode = new ErrCode(8123, `This user is already assigned to this role, please select a new one.`);
            this.spinner = false;
            return;
        }
        try {
            const result = await this.multiadminService.updateUserRole(this.child.user_id, this.child.roles[0], this.role);
            if (result.success) {
                this.activeModal.close(ModalCloseStates.USER_ROLE_UPDATED);
            }
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }
}
