import { environment } from '../../../../../environments/environment';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
    OnChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    Reply,
    ReplyCreationParams,
    ReplyDeleteData,
    ReplyUpdateData,
} from '../../../models/commments.model';
import { User } from '../../../models';
import { DomSanitizer } from '@angular/platform-browser';
import * as fromLinkFileList from '../../../../reducers/link-file-list.reducer';

@Component({
    selector: 'sync-reply',
    templateUrl: './reply.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ReplyComponent implements OnInit, OnChanges {
    @Input() isInputBox: boolean; // true if this component is for new reply input
    @Input() detailEnabled: boolean; // true if reply detail is displayed
    @Input() user: User | undefined;
    @Input() reply: Reply | undefined;
    @Input() commentID: string;
    @Input() ownerId: number;
    @Input() tempSession: string; //base64 string represents a session token for anonymous user, empty if authenticated
    @Input() link: fromLinkFileList.State;
    @Output() replyCreationEvent = new EventEmitter<ReplyCreationParams>();
    @Output() replyEditionEvent = new EventEmitter<ReplyUpdateData>();
    @Output() replyDeletionEvent = new EventEmitter<ReplyDeleteData>();
    @Output() toggleReplyInputBoxEvent = new EventEmitter();

    replyCreationForm: FormControl;
    replyEditionForm: FormControl;
    userAvatar: string; // current user avatar
    authorAvatar: string; // comment author avatar
    avatarEndpoint = `${environment.logohost}avatar`;
    confirmedDelete: boolean;
    editMode: boolean;
    buttonPrimaryColor: string;
    buttonTextColor: string;
    contentLength = 200;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        const contentValidators = [
            Validators.maxLength(this.contentLength),
            Validators.minLength(1),
        ];
        if (this.isInputBox) {
            this.replyCreationForm = new FormControl('', contentValidators);
        }
        if (this.reply) {
            this.replyEditionForm = new FormControl(
                { value: this.reply.content, disabled: true },
                contentValidators
            );
        } else {
            this.replyEditionForm = new FormControl(
                { value: '', disabled: true },
                contentValidators
            );
        }

        if (this.user && this.user.exists) {
            this.userAvatar = `${this.avatarEndpoint}/${this.user.id}`;
        } else {
            this.userAvatar = '../../../images/anonymous.png';
        }

        if (this.reply && !this.reply.temp_session) {
            this.authorAvatar = `${this.avatarEndpoint}/${this.reply.author_id}`;
        } else {
            this.authorAvatar = '../../../images/anonymous.png';
        }

        if (this.link.image_cachekey) {
            this.buttonPrimaryColor = this.link.button_primary_color;
            this.buttonTextColor = this.link.button_text_color;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user) {
            this.user = changes.user.currentValue;
        }

        if (changes.tempSession) {
            this.tempSession = changes.tempSession.currentValue;
        }

        if (changes.detailEnabled) {
            this.detailEnabled = changes.detailEnabled.currentValue;
        }
        if (changes.link) {
            this.link = changes.link.currentValue;
        }
    }

    createReply() {
        let newReply: ReplyCreationParams;
        newReply = {
            parent_id: this.commentID,
            author_id: this.user.id,
            author_name: this.user.display_name,
            content: this.replyCreationForm.value as string,
            temp_session: this.tempSession,
        };
        this.replyCreationEvent.emit(newReply);
        this.replyCreationForm.reset();
    }

    editReply() {
        this.toggleEditMode();
        this.replyEditionForm.enable();
    }

    updateReply() {
        let data: ReplyUpdateData;
        data = {
            parent_id: this.reply.parent_id,
            reply_id: this.reply.reply_id,
            author_id: this.user.id,
            content: this.replyEditionForm.value as string,
            temp_session: this.tempSession,
        };

        this.replyEditionEvent.emit(data);
        this.editMode = false;
        this.replyEditionForm.disable();
    }

    deleteReply() {
        let data: ReplyDeleteData;
        data = {
            parent_id: this.reply.parent_id,
            reply_id: this.reply.reply_id,
            author_id: this.user.id,
            temp_session: this.tempSession,
        };

        this.replyDeletionEvent.emit(data);
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    toggleReplyInputBox() {
        this.toggleReplyInputBoxEvent.emit();
    }

    toggleConfirm() {
        this.confirmedDelete = !this.confirmedDelete;
    }

    cancelEditMode() {
        this.editMode = false;
        this.replyEditionForm.setValue(this.reply.content);
    }

    cancelReplyCreation() {
        this.replyCreationForm.reset();
        this.replyCreationForm.setValue('');
    }

    cancelConfirm() {
        this.confirmedDelete = false;
    }

    validContent(value: string | undefined) {
        return value != undefined && value.trim().length !== 0;
    }

    /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer
            .bypassSecurityTrustStyle(`--buttonPrimaryColor: ${this.buttonPrimaryColor};
                                                        --buttonTextColor: ${this.buttonTextColor};
                                                    `);
    }
}
