<div *ngIf="isLoaded" class="copyright-notice">
    &copy;
    {{ curDate | date: "yyyy" }} Sync.com Inc.
</div>

<div class="container-fluid container-fluid-wrap">
    <sync-error [errcode]="errcode"></sync-error>

    <!-- header row top bar contains title, menu, options -->
    <div class="row">
        <!-- global actions moved outside the right column to fix z-index issues with file list -->
        <!-- quick hack this is duplicated at bottom for mobile view -->
        <div class="right-menu affix hidden-xs hidden-sm">
            <div class="sticky-message dropdown-menu right-menu-dropdown-menu"
                *ngIf="showPayNowBanner && user && user.is_on_trial">
                <div class="sticky-message-title" style="color: #fff !important">
                    Payment Due
                    <button (click)="showPayNowBanner = false" type="button" class="close" style="opacity: 1"
                        aria-label="Close">
                        <span aria-hidden="true" style="color: #fff !important">&times;</span>
                    </button>
                </div>
                <div class="sticky-message-body" style="color: #000 !important">
                    We hope you're enjoying Sync. Just a reminder to
                    <a routerLink="/account/upgrade">pay now</a> to continue
                    using Sync Pro.
                </div>
            </div>
            <div class="sticky-message dropdown-menu right-menu-dropdown-menu"
                *ngIf="showFreeTrialReminder && user && user.is_on_trial">
                <div class="sticky-message-title" style="color: #fff !important">
                    Free Trial
                    <button (click)="showFreeTrialReminder = false" type="button" class="close" style="opacity: 1"
                        aria-label="Close">
                        <span aria-hidden="true" style="color: #fff !important">&times;</span>
                    </button>
                </div>
                <div class="sticky-message-body" style="color: #000 !important">
                    We hope you're enjoying Sync. Just a reminder to add a
                    <a routerLink="/account/billing/profile">billing profile</a>
                    <span *ngIf="planExpiry">by {{ planExpiry | date: 'MMMM d, yyyy' }}</span>
                </div>
            </div>
            <sync-file-list-menu class="dropdown-menu right-menu-dropdown-menu dropdown-regular file-list-menu-dropdown"
                [showRightMenu]="showFileMenu" *ngIf="hasSelected()">
            </sync-file-list-menu>
            <ul *ngIf="!hasSelected() && (!is_starred || !isRoot)" class="dropdown-menu right-menu-dropdown-menu dropdown-regular"
                style="z-index: 0">
                <li class="showhand" *ngIf="showGetstarted">
                    <a title="Get started bonus" routerLink="/account/getstarted" style="color: #ffa500 !important">
                        <i class="zmdi zmdi-star-circle zmdi-hc-fw zmdi-hc-lg" style="color: #ffa500 !important"></i>
                        Get started bonus
                    </a>
                </li>
                <li class="showhand" *ngIf="!isReadOnly">
                    <a title="Upload" syncDialogTransfer>
                        <i *ngIf="!transferView.isUpload && !transferView.isDownload"
                            class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-cloud-upload"></i>
                        <i *ngIf="transferView.isUpload || transferView.isDownload" 
                            class="fa fa-spin fa-sync fa-fw"
                            style="margin:0px 4px;"></i>
                        {{ transferView.transferLabel }}
                    </a>
                </li>
                <li class="showhand" *ngIf="folderTransferView.isProcessing || folderTransferView.isScanning">
                    <a title="Upload" (click)="openUploadFolderModal()">
                        <i class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-cloud-upload"></i>
                        Uploading Folder
                        <i class="fa fa-spin fa-sync fa-fw pull-right" style="margin-top:3px;"></i>
                    </a>
                </li>
                <li class="showhand hidden-sm hidden-xs" *ngIf="!cwd?.is_shared && !isReadOnly && hasPermission(permissionActions.SHARE_FOLDERS)">
                    <a syncDialogShareNew [pid]="syncId">
                        <i class="zmdi zmdi-folder-person zmdi-hc-fw zmdi-hc-lg"></i>
                        New shared folder
                    </a>
                </li>
                <li class="showhand" *ngIf="!isReadOnly">
                    <a id="file-list-create-new-folder-btn" title="Create a new folder" syncStopPropagation
                        (click)="showNewFolder = !showNewFolder">
                        <i class="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"></i>
                        New folder
                    </a>
                </li>
                <li class="showhand" id="create-file-path-btn" *ngIf="!isReadOnly">
                    <a title="Create" (click)="openCreateBlankDocumentModal()">
                        <!-- <i class="fas fa-plus zmdi-hc-fw zmdi-hc-lg" style="float: left; margin: 3.5px 3px 0px 0px;"></i> -->
                        <i class="zmdi zmdi-plus-circle zmdi-hc-fw zmdi-hc-lg"></i>
                        Create file
                    </a>
                </li>
                <li class="hidden-sm hidden-xs showhand">
                    <a title="Toggle deleted files" id="deleted-files-btn" *ngIf="!isReadOnly" (click)="toggleDeleted()">
                        <img style="margin: 0px 6.5px 0px 2px; float: left" *ngIf="showDeleted != 1"
                            src="images/baseline-delete-24px.svg" />
                        <img style="margin: 0px 6.5px 0px 2px; float: left" *ngIf="showDeleted == 1"
                            src="images/delete-empty.svg" />
                        <!-- <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg "></i> -->
                        <span *ngIf="showDeleted != 1">Show deleted files</span>
                        <span *ngIf="showDeleted == 1">Hide deleted files</span>
                    </a>
                </li>
            </ul>
        </div>
        <!-- end global actions -->

        <!-- affixed header box sets padding for right menu -->
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title" id="header-title-path">
                <a *ngIf="parents?.length > 3" (click)="navigateToRoot()">
                    <i class="fas fa-angle-double-left"></i>
                </a>
                <ng-container *ngFor="
                        let item of parents;
                        let index = index;
                        let last = last
                    ">
                    <ng-container *ngIf="!last && parents?.length - index <= 3">
                        <a class="showhand" (click)="navigateToSubFolder(item)">
                            {{ item.name }}
                        </a>
                        <i class="fas fa-angle-right showhand"></i>
                    </ng-container>
                    <strong *ngIf="last && parents?.length - index <= 3">
                        {{ item.name }}
                    </strong>
                </ng-container>
            </div>
            <!-- sharing widget for pathlist-->
            <div class="right-menu-button affix">
                <!-- is share -->
                <sync-inline-share-info *ngIf="isLoaded && cwd.is_shared" [syncInlineShareInfo]="cwd.share_id">
                </sync-inline-share-info>
                <!-- is share -->
            </div>
            <!-- sharing widget for pathlist-->

            <!-- thead in seaparate table, otherwise need JS plugin for affix -->

            <table class="table list-table list-table-header">
                <thead>
                    <tr>
                        <th class="table-checkbox hidden-xs hidden-sm">
                            <div auto-close="always" ngbDropdown class="dropdown">
                                <a syncStopPropagation ngbDropdownToggle aria-haspopup="true" aria-expanded="true" class="showhand" id="dropdown-menu-left">
                                    <span class="caret"></span>
                                </a>
                                <ul ngbDropdownMenu class="dropdown-menu showhand">
                                    <li *ngIf="!showDeleted" id="dropdown-select-all-btn">
                                        <a syncStopPropagation ngbDropdownToggle (click)="selectAll(true)">Select All</a>
                                    </li>
                                    <li *ngIf="!showDeleted" id="dropdown-files-btn">
                                        <a syncStopPropagation ngbDropdownToggle (click)="selectType('type', 'file')">Files</a>
                                    </li>
                                    <li *ngIf="!showDeleted" id="dropdown-folders-btn">
                                        <a syncStopPropagation ngbDropdownToggle (click)="selectType('type', 'dir')">Folders</a>
                                    </li>
                                    <li *ngIf="showDeleted">
                                        <a syncStopPropagation ngbDropdownToggle (click)="
                                                selectType('is_deleted', true)
                                            ">Select all</a>
                                    </li>
                                </ul>
                            </div>
                        </th>
                        <th class="table-filename">
                            <span (click)="setPredicate('name')" class="showhand">
                                Name
                                <i [ngClass]="getPredicateClass('name')"></i>
                            </span>
                        </th>
                        <th class="table-preview"></th>
                        <th class="table-favorite"></th>
                        <th class="table-date hidden-sm hidden-xs">
                            <span (click)="setPredicate('usertime')" class="showhand">
                                Modified
                                <i [ngClass]="getPredicateClass('usertime')"></i>
                            </span>
                        </th>
                        <th class="table-size hidden-sm hidden-xs">
                            <span (click)="setPredicate('size')" class="showhand">
                                Size
                                <i [ngClass]="getPredicateClass('size')"></i>
                            </span>
                        </th>
                        <th class="table-shares hidden-xs"></th>
                        <th class="table-actions">
                            <i class="showhand fas fa-sm fa-fw" [ngClass]="{
                                    'fa-th': !showGrid,
                                    'fa-th-list': showGrid
                                }" (click)="toggleView()"></i>

                            <i syncStopPropagation id="file-list-filter-btn" (click)="toggleFilter()"
                                class="fas fa-filter fa-sm showhand" title="Filter this folder"></i>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
        <!-- col-sm-12 affix -->
    </div>
    <!-- row -->

    <div class="row drop-box-height" syncUploadDropBox>
        <div class="col-sm-12 content-box-withmenu" style="visibility: hidden">
            <div style="visibility: visible;">
                <div id="upload-modal"></div>
                <!-- hack so parent element doesn't overlay nav, and z-index is correct -->
                <div class="actions-global" [class.hide]="!(showNewFolder && !isReadOnly)">
                    <sync-new-folder [syncNewFolder]="cwd?.sync_id" [(syncShowNewFolder)]="showNewFolder"></sync-new-folder>
                </div>
                <div [class.hide]="!showFilter" class="actions-global">
                    <sync-filter-list [(filterText)]="filter" [(filterToggle)]="showFilter"></sync-filter-list>
                </div>
                <div class="text-center" *ngIf="showLoading">
                    <i class="fa fa-spin fa-spinner"></i> Loading ...
                </div>
                <div [class.hide]="!(!list?.length && isLoaded)" class="file-empty">
                    <div [class.hide]="favSpinner" class="text-center file-empty-inner">
                        <h4>
                            <img [class.hide]="!(isVault && !showDeleted && !(is_starred && isRoot))" src="images/icons/icon-vault.svg" width="36"
                                height="36" style="padding-bottom: 4px" />
                            <img [class.hide]="!(!isVault && !showDeleted && !(is_starred && isRoot))" src="images/icons/dir.svg" width="36"
                                height="36" style="padding-bottom: 4px" />
                            <img [class.hide]="!(!isVault && !showDeleted && (is_starred && isRoot))" src="images/icons/star.svg" width="36"
                                height="36" style="padding-bottom: 4px" />
                            <span *ngIf="!showDeleted && !is_starred" [innerHtml]="
                                    isVault && isRoot
                                        ? 'Vault is empty'
                                        : 'Folder is empty'
                                ">Folder is empty</span>
                            <span *ngIf="is_starred && !isRoot">Folder is empty</span>    
                            <span *ngIf="!showDeleted && (is_starred && isRoot)">Star your first file or folder</span>
                            <span *ngIf="showDeleted && list?.length == 0">No deleted items in this folder:
                                <a (click)="toggleDeleted()" class="showhand syncblue">show active files</a>.</span>
                        </h4>

                        <div class="file-empty-text" [class.hide]="
                                !(
                                    !isVault &&
                                    !showDeleted && is_starred && isRoot
                                )
                            ">
                            Mark a file or folder with a star in your 
                            <a class="syncblue" rel="noopener" routerLink="/files">files</a> or 
                            <a class="syncblue" rel="noopener" routerLink="/vault">vault</a> to
                            quickly access it from this page.
                        </div>
                        <div class="file-empty-text" [class.hide]="
                                !(
                                    !isVault &&
                                    !userService?.user?.install_desktop &&
                                    !showDeleted
                                ) || (is_starred && isRoot)
                            ">
                            Upload folders or files by dragging them here, or
                            click the upload button to select files manually.
                            <br /><br />Don't forget to
                            <a class="syncblue" rel="noopener" href="https://www.sync.com/install">install</a>
                            the Sync desktop app on your computer, for faster
                            uploading of large data sets.
                        </div>
                        <div class="file-empty-text" [class.hide]="
                                !(
                                    !isVault &&
                                    userService?.user?.install_desktop &&
                                    !showDeleted 
                                ) || (is_starred && isRoot)
                            ">
                            Upload folders or files by dragging them here, or
                            click the upload button to select files manually.
                        </div>
                        <div class="file-empty-text" [class.hide]="!(isVault && !showDeleted && !is_starred)">
                            Files added to the Vault do not get synced to your
                            other devices - allowing you to free up space by
                            archiving your files in the cloud. <br /><br />From
                            the
                            <a class="syncblue" href="files">files tab</a> or
                            the
                            <a class="syncblue" rel="noopener" href="https://www.sync.com/install">desktop app</a>
                            you can move or copy any of your files or folders
                            into the Vault.
                        </div>
                    </div>
                </div>

                <div class="row grid-container" *ngIf="showGrid && list">
                    <!-- start repeating grid -->
                    <div class="col-xs-4 col-sm-3 col-lg-2 grid-square-container" [ngClass]="{
                            'row-deleted': item.is_deleted,
                            active: item.active
                        }" *ngFor="let item of filterList(); trackBy: trackByFn" id="sync-id-{{ item.id }}">
                        <div class="grid-overlay-box grid-overlay-box-right hidden-xs hidden-sm showhand"
                            style="display: none">
                            <i syncStopPropagation (click)="selectToggle(item)" [ngClass]="{
                                    'fas fa-check-square': item.active,
                                    'far fa-square': !item.active
                                }"></i>
                        </div>

                        <div class="grid-overlay-box grid-overlay-box-left showhand" ngbDropdown #ngbdd="ngbDropdown" autoClose="outside">
                            <i syncStopPropagation (click)="selectOne(item);openMenu(ngbdd);" title="File actions"
                                class="fas fa-ellipsis-h pull-right dropdown-toggle" ngbDropdownToggle></i>
                                <sync-file-list-menu ngbDropdownMenu [dropDown]="ngbdd" class="file-list-menu-dropdown"></sync-file-list-menu>
                        </div>

                        <div class="grid-square">
                            <div class="grid-square-image">
                                <a class="filelink showhand" [routerLink]="itemLink(item)" [queryParams]="getQueryParams(item)" [syncPathActivateAction]="item">
                                    <img [src]="
                                            item.thumbsrc
                                                ? getImage(item.thumbsrc)
                                                : getImage(item.imgsrc)
                                        " class="img-responsive showhand" />
                                </a>
                            </div>
                        </div>

                        <div class="grid-square-filename">
                            <a class="showhand filelink" *ngIf="!item.rename" [routerLink]="itemLink(item)" [syncPathActivateAction]="item" [queryParams]="getQueryParams(item)">
                                {{ item.name }}
                            </a>
                            <sync-path-rename-form syncStopPropagation *ngIf="item.rename" [syncPathRenameForm]="item">
                            </sync-path-rename-form>
                        </div>
                    </div>
                </div>

                <table *ngIf="!showGrid && list" class="table list-table path-list" id="filemanager">
                    <tbody>
                        <tr id="sync-id-{{ item.id }}" [ngClass]="{
                                'row-deleted': item.is_deleted,
                                active: item.active
                            }" *ngFor="
                                let item of filterList();
                                trackBy: trackByFn
                            ">
                            <td class="table-checkbox hidden-xs hidden-sm">
                                <i syncStopPropagation (click)="selectToggle(item)" [ngClass]="{
                                        'fas fa-check-square':
                                            item.active && showFileMenu,
                                        'far fa-square':
                                            !item.active ||
                                            (item.active && !showFileMenu)
                                    }"></i>
                            </td>
                            <td class="table-preview">
                                <a class="filelink showhand" [routerLink]="itemLink(item)" [syncPathActivateAction]="item" [queryParams]="getQueryParams(item)">
                                    <img [src]="
                                            item.thumbsrc
                                                ? getImage(item.thumbsrc)
                                                : getImage(item.imgsrc)
                                        " />
                                </a>
                            </td>
                            <td class="table-filename">
                                <sync-path-rename-form syncStopPropagation *ngIf="item.rename"
                                    [syncPathRenameForm]="item">
                                </sync-path-rename-form>

                                <div class="tdmore" *ngIf="!item.rename">
                                    <a *ngIf="!item.rename" [routerLink]="itemLink(item)" [syncPathActivateAction]="item" [queryParams]="getQueryParams(item)" [attr.title]="item.name"
                                        class="filelink showhand">{{ item.name }}</a>
                                </div>
                                <div class="tdmore subtext" *ngIf="showInfo">
                                    Sync ID: {{ item.sync_id }}
                                </div>
                            </td>
                            <td *ngIf="!showDeleted" class="table-favorite hidden-sm hidden-xs">
                                <div *ngIf="!item.is_favorite" [ngClass]="{'disabled-element': item.is_tagEnabled}" class="tdmore" (click)="addFavoriteTag(item.sync_id)">
                                    <i class="zmdi zmdi-star-outline zmdi-hc-fw zmdi-hc-lg"></i>
                                </div>
                                <div *ngIf="item.is_favorite" [ngClass]="{'disabled-element': item.is_tagEnabled}" class="tdmore syncblue" (click)="removeFavoriteTag(item.sync_id)">
                                    <i class="zmdi zmdi-star zmdi-hc-fw zmdi-hc-lg"></i>
                                </div>
                            </td>
                            <td class="table-date hidden-sm hidden-xs">
                                <div class="tdmore">
                                    <span [innerHtml]="
                                            item.type !== 'dir'
                                                ? item.date_medium
                                                : '--'
                                        "></span>
                                </div>
                            </td>
                            <td class="table-size hidden-sm hidden-xs">
                                <div class="tdmore">
                                    <span [innerHtml]="
                                            item.type !== 'dir'
                                                ? item.filesize
                                                : '--'
                                        "></span>
                                </div>
                            </td>
                            <td class="table-shares hidden-xs">
                                <!-- Icons -->
                                <table style="position: absolute; margin-top: -7px">
                                    <tr>
                                        <td>
                                            <i class="fas fa-bolt" [ngStyle]="{
                                                    opacity:
                                                        !item.is_deleted &&
                                                        item.type == 'dir' &&
                                                        item.is_share_root ==
                                                            1 &&
                                                        item.is_app_share
                                                            ? '1'
                                                            : '0'
                                                }"></i>
                                        </td>
                                        <td>
                                            <i class="zmdi zmdi-accounts zmdi-hc-lg" [ngStyle]="{
                                                    opacity:
                                                        !item.is_deleted &&
                                                        item.type == 'dir' &&
                                                        item.is_share_root ==
                                                            1 &&
                                                        !item.is_hide_share
                                                            ? '1'
                                                            : '0'
                                                }"></i>
                                        </td>
                                        <td>
                                            <i class="fas fa-link" [ngStyle]="{
                                                    opacity:
                                                        !item.is_deleted &&
                                                        item.is_publink == 1
                                                            ? '1'
                                                            : '0'
                                                }"></i>
                                        </td>
                                    </tr>
                                </table>
                                <div class="dropdown share-btn" placement="bottom-right" *ngIf="
                                        !item.is_deleted &&
                                        !isReadOnly &&
                                        !item.is_readonly &&
                                        (item.type == 'dir' ? (item.is_shared || hasPermission(permissionActions.SHARE_FOLDERS)) : hasPermission(permissionActions.SHARE_LINKS))
                                    " ngbDropdown #ngb="ngbDropdown">
                                    <button syncStopPropagation style="
                                            position: absolute;
                                            top: -19px;
                                            right: 2px;
                                        " class="btn btn-default" type="button" ngbDropdownToggle (click)="
                                            selectOne(item);openMenu(ngb);
                                        " aria-haspopup="true" aria-expanded="false">
                                        Share <span class="caret"></span>
                                    </button>
                                    <ul role="menu" class="dropdown-menu dropdown-menu-right dropdown-regular"
                                        ngbDropdownMenu>
                                        <li *ngIf="hasPermission(permissionActions.SHARE_LINKS)">
                                            <a syncDialogLink [selected]="item" class="showhand" [innerHtml]="
                                                    item.is_publink
                                                        ? 'Manage link'
                                                        : 'Share as link'
                                                "></a>
                                        </li>
                                        <li *ngIf="
                                                hasPermission(permissionActions.SHARE_FOLDERS) &&
                                                (!item.is_shared &&
                                                    item.type == 'dir') ||
                                                (item.is_shared &&
                                                    item.is_share_root &&
                                                    item.is_hide_share)
                                            ">
                                            <a syncDialogShareNew [selected]="item" class="showhand">
                                                Invite users to collaborate
                                            </a>
                                        </li>
                                        <li *ngIf="
                                                item.is_share_root &&
                                                !item.is_hide_share
                                            ">
                                            <a (click)="
                                                    openShareDetails(item, '0')
                                                " class="showhand">
                                                Manage share
                                            </a>
                                        </li>
                                        <li *ngIf="
                                                ((!item.is_shared &&
                                                    item.type == 'dir') ||
                                                    item.is_share_root) &&
                                                !item.is_app_share &&
                                                hasAppsNewFeature
                                            ">
                                            <a (click)="openAppList()" selected="item" class="showhand">
                                                Connect apps
                                            </a>
                                        </li>
                                        <li *ngIf="
                                                item.is_share_root &&
                                                item.is_app_share
                                            ">
                                            <a (click)="openAppList()" selected="item" class="showhand">
                                                Manage apps
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="table-actions">
                                <div class="dropdown actions-dropdown actions-dropdown-right" placement="bottom-right" ngbDropdown #ngbdd="ngbDropdown" autoClose="outside">
                                    <i syncStopPropagation (click)="selectOne(item);openMenu(ngbdd);" id="webpath-option-btn" title="File actions"
                                        class="fas fa-ellipsis-h pull-right dropdown-toggle" ngbDropdownToggle></i>
                                        <sync-file-list-menu ngbDropdownMenu [dropDown]="ngbdd" class="file-list-menu-dropdown"></sync-file-list-menu>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="table-pagination pull-right">
                    <nav aria-label="Page navigation">
                        <ngb-pagination *ngIf="paginate.total > paginate.itemsPerPage" [maxSize]="10"
                            [collectionSize]="paginate.total" [(page)]="paginate.currentPage"
                            (pageChange)="changePage()" [pageSize]="paginate.itemsPerPage" [boundaryLinks]="true">
                        </ngb-pagination>
                    </nav>
                </div>
                <div class="table-info">
                    <i (click)="showInfo = !showInfo" class="fa fa-info-circle fa-lg showhand"></i>
                    <span>
                        <sync-display-pagination [paginate]="paginate"></sync-display-pagination>
                    </span>
                </div>
            </div>
            <!-- end hack -->
        </div>
    </div>
</div>

<div class="right-menu affix hidden-md hidden-lg hidden-xl">
    <div class="sticky-message dropdown-menu right-menu-dropdown-menu"
        *ngIf="showPayNowBanner && user && user.is_on_trial">
        <div class="sticky-message-title" style="color: #fff !important">
            Payment Due
            <button (click)="showPayNowBanner = false" type="button" class="close" style="opacity: 1"
                aria-label="Close">
                <span aria-hidden="true" style="color: #fff !important">&times;</span>
            </button>
        </div>
        <div class="sticky-message-body" style="color: #000 !important">
            We hope you're enjoying Sync. Just a reminder to
            <a routerLink="/account/upgrade">pay now</a> to continue using Sync
            Pro.
        </div>
    </div>
    <div class="sticky-message dropdown-menu right-menu-dropdown-menu"
        *ngIf="showFreeTrialReminder && user && user.is_on_trial">
        <div class="sticky-message-title" style="color: #fff !important">
            Free Trial
            <button (click)="showFreeTrialReminder = false" type="button" class="close" style="opacity: 1"
                aria-label="Close">
                <span aria-hidden="true" style="color: #fff !important">&times;</span>
            </button>
        </div>
        <div class="sticky-message-body" style="color: #000 !important">
            We hope you're enjoying Sync. Just a reminder to add a
            <a routerLink="/account/billing/profile">billing profile</a>
            <span *ngIf="planExpiry">by {{ planExpiry | date: 'MMMM d, yyyy' }}</span>
        </div>
    </div>
    <sync-file-list-menu [showRightMenu]="showFileMenu" *ngIf="hasSelected()" class="file-list-menu-dropdown"></sync-file-list-menu>
    <div class="right-menu-icon">
        <a title="right-menu-button" (click)="showRightMenu = !showRightMenu">
            <i 
            class="zmdi zmdi-hc-fw zmdi-hc-lg showhand"
            [ngClass]="{
                'zmdi-plus-circle': !showRightMenu,
                'zmdi-close-circle': showRightMenu
            }"></i>
        </a>
    </div>

    <ul *ngIf="!hasSelected() && showRightMenu" class="dropdown-menu right-menu-dropdown-menu dropdown-regular" style="z-index: 0;bottom:30px;">
        <li class="showhand" *ngIf="!isReadOnly">
            <a title="Upload" syncDialogTransfer (click)="showRightMenu = false;">
                <i *ngIf="!transferView.isUpload && !transferView.isDownload"
                    class="zmdi zmdi-hc-fw zmdi-hc-lg zmdi-cloud-upload"></i>
                <i *ngIf="transferView.isUpload || transferView.isDownload" 
                    class="fa fa-spin fa-sync fa-fw"
                    style="margin:0px 4px;"></i>
                {{ transferView.transferLabel }}
            </a>
        </li>

        <li class="showhand" *ngIf="!isReadOnly">
            <a id="file-list-create-new-folder-btn" title="Create a new folder" syncStopPropagation
                (click)="showNewFolder = !showNewFolder">
                <i class="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"></i>
                New folder
            </a>
        </li>
        <li class="showhand" *ngIf="!isReadOnly">
            <a title="Create" (click)="openCreateBlankDocumentModal()">
                <!-- <i class="fas fa-plus zmdi-hc-fw zmdi-hc-lg" style="float: left; margin: 3.5px 3px 0px 0px;"></i> -->
                <i class="zmdi zmdi-plus-circle zmdi-hc-fw zmdi-hc-lg"></i>
                Create file
            </a>
        </li>
        <li class="showhand" *ngIf="!isReadOnly">
            <a title="Toggle deleted files" *ngIf="!isReadOnly" (click)="toggleDeleted()">
                <img style="margin: 0px 6.5px 0px 2px" *ngIf="showDeleted != 1" src="images/baseline-delete-24px.svg" />
                <img style="margin: 0px 6.5px 0px 2px" *ngIf="showDeleted == 1" src="images/delete-empty.svg" />
                <!-- <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg "></i> -->
                <span *ngIf="showDeleted != 1">Show deleted files</span>
                <span *ngIf="showDeleted == 1">Hide deleted files</span>
            </a>
        </li>
    </ul>
</div>