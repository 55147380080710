import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'sync-display-pagination',
    templateUrl: './display-pagination.component.html'
})
export class DisplayPaginationComponent implements OnInit {
    public viewOffset: number;
    public viewEnd: number;
    public viewTotal: number;
    public viewPerPage: number;
    @Input() public paginate: sync.IPaginate;

    constructor() {}

    ngOnInit() {}
}
