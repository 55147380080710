<!-- modalcontent -->
<div class="modal-header">
    <button
        (click)="activeModal.close()"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Create a new document</h4>
</div>
<div class="modal-body">
    <div class="row">
        <sync-error-display-hash
            class="col-sm-12"
            [errors]="errors"
        ></sync-error-display-hash>
    </div>
    <div class="row">
        <div class="col-sm-4">Select a document type:</div>
        <div class="col-sm-8">
            <div class="modal-body-div" *ngFor="let doc of documents">
                <input
                    type="radio"
                    name="radiobtn"
                    id="{{ doc.id }}"
                    value="{{ doc.extension }}"
                    [(ngModel)]="selectedDocType"
                    (click)="onSelectDocType(doc.extension)"
                />
                <label for="{{ doc.id }}">
                    <img
                        class="modal-body-svg"
                        src="images/icons/{{ doc.svg }}"
                    />
                    {{ doc.name }}</label
                >
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-sm-4">File name:</div>
        <div class="col-sm-8">
            <input
                id="create-blank-document-text"
                class="form-control input-lg"
                type="text"
                placeholder="Enter file name"
                [(ngModel)]="fileName"
                (change)="change()"
                [maxlength]="maxFileNameLength"
            />
        </div>
    </div>
    <div style="margin-top: 10px" class="hidden-md hidden-lg hidden-xl"></div>
</div>

<div class="modal-footer">
    <button
        syncStopPropagation
        (click)="activeModal.close()"
        type="button"
        class="btn btn-default"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary pull-right"
        id="create-dialog-btn"
        (click)="createDocument()"
    >
        Create
    </button>
</div>
