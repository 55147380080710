import { Injectable } from '@angular/core';
import { CryptBufferService } from './crypt-buffer.service';
import { CryptLegacyService } from './crypt-legacy.service';
import { LoggerService } from '../logger.service';
import {
    ErrCode,
    PubkeyData,
    ShareInviteData,
    ShareKeyData,
    ShareKeyList,
    ShareNewCryptData,
} from '../../shared/models';

import * as unorm from 'unorm';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as CoreActions from '../../actions/core.actions';
import { environment } from '../../../environments/environment';
import { CryptNativeService } from './crypt-native.service';
import { BrowserSupportService } from '../browser-support.service';
import { CryptEngine } from '../../shared/models/crypt-engine.model';
import { CryptMethodService } from '../../shared/models/crypt-method-service.model';

@Injectable()
export class SyncCryptService {

    /**
     * Do not modify these values, it will break encryption system wide by
     * creating bad data that nothing will be able to decrypt, other than
     * a cp which has these same values.
     *
     * @see https://syncdev.atlassian.net/wiki/spaces/OV/pages/1507348/File+Data
     */
    public readonly GCM_PACKET_SIZE = 128 * 1024;
    public readonly GCM_PAYLOAD_SIZE = 128 * 1024 - 36;

    /**
     * These prefixes are used to identify encryption method.  E.g., a 1: prefix
     * signifies a meta key encrypted string for a file name.
     *
     * Do not alter these prefixes, but add new ones if required.
     */
    public readonly ENC_PREFIX_FILENAME = 1;
    public readonly ENC_PREFIX_DATAKEY = 2;
    public readonly ENC_PREFIX_APP_LINK_DATAKEY = 3;
    public readonly ENC_PREFIX_DIGEST = 2;
    public readonly ENC_PREFIX_PASSWORD = 30;
    public readonly ENC_PREFIX_LINKPASSWORD = 50;
    public readonly ENC_PREFIX_PASSRESET = 51;

    private mCrypt: CryptMethodService;

    // this cache is used for memoization to optimize some encryption time
    private mCache: { [cache: string]: any } = {
        filename: {},
        datakey: {},
        sharekey: {},
        linkpassword: {},
    };

    constructor(
        private BrowserSupport: BrowserSupportService,
        private CryptNative: CryptNativeService,
        private CryptBuffer: CryptBufferService,
        private CryptLegacy: CryptLegacyService,
        private Logger: LoggerService,
        private store: Store<fromRoot.State>
    ) {
        switch (this.BrowserSupport.getEncryptionEngine()) {
            case CryptEngine.NATIVE: this.setCryptMethod(CryptNative); break;
            case CryptEngine.BUFFER: this.setCryptMethod(CryptBuffer); break;
            case CryptEngine.LEGACY: this.setCryptMethod(CryptLegacy); break;
            default: this.setCryptMethod(CryptLegacy); break;
        }
    }

    /**
     * Sets the crypt library to use.
     */
    public setCryptMethod(crypt: CryptMethodService) {
        this.mCrypt = crypt;
        this.Logger.info(`Using CryptEngine.${this.BrowserSupport.getEncryptionEngine()}`);
    }

    /**
     * Encrypts a value for storage in the browser's local storage
     * @param val The plain text value to encrypt using storage encrypt method
     */
    public async storeEncrypt(val: string): Promise<string> {
        try {
            const sessionPass = await this.getSessionPassword();
            return await this.mCrypt.passwordEncrypt(val, sessionPass, 1000);
        } catch (ex) {
            throw new ErrCode(2130);
        }
    }

    /**
     * Decrypts a value for storage in the browser's local storage.
     * @param encVal The encrypted value
     */
    public async storeDecrypt(key: string): Promise<string> {
        return await new Promise<string>((resolve, reject) => {
            const b = this.store.select(fromRoot.getCoreState);
            const sub = b.subscribe(async (data) => {
                if (!data || !data[key]) {
                    reject(
                        new Error(`The key ${key} does not exist in core state`)
                    );
                }
                const sessionPass = await this.getSessionPassword();
                const ret = await this.mCrypt.passwordDecrypt(
                    data[key],
                    sessionPass,
                    1000
                );
                resolve(ret);
                if (sub) {
                    sub.unsubscribe();
                }
            });
        });
        //     // });
        // } catch (ex) {
        //     throw new ErrCode(2131);
        // }
    }

    public async userkeyDecrypt(encKey, password: string) {
        try {
            // must use cryptlegacy because people's passwords will contain weird chars
            return await this.CryptLegacy.passwordDecrypt(
                encKey,
                password,
                10000
            );
        } catch (ex) {
            this.Logger.e('Error decrypting user keys', ex);
            throw new ErrCode(2140);
        }
    }
    public async userkeyEncrypt(plain, password: string) {
        try {
            // must use cryptlegacy because people's passwords will contain weird chars
            return await this.CryptLegacy.passwordEncrypt(
                plain,
                password,
                10000
            );
        } catch (ex) {
            this.Logger.e('Error encrypting user key', ex);
            throw new ErrCode(2141);
        }
    }
    public async userpasswordEncrypt(password: string, pubkey: string) {
        try {
            return await this.CryptLegacy.asymmetricEncrypt(pubkey, password);
        } catch (ex) {
            this.Logger.e('Error encrypting user key', ex);
            throw new ErrCode(2141);
        }
    }

    /**
     * Decrypts a data key
     * @param encDataKey base64 number prefix of encrypted data key
     * @param sharekey base64 of the share key (decrypted)
     */
    public async datakeyDecrypt(
        encDataKey: string,
        sharekey: string
    ): Promise<string> {
        if (!this.isValidPrefix(encDataKey, this.ENC_PREFIX_DATAKEY)) {
            throw new ErrCode(2010);
        }
        if (this.isMemoized('datakey', encDataKey) !== undefined) {
            return this.isMemoized('datakey', encDataKey);
        }
        const bytes = this.mCrypt.b64ToBytes(
            this.rmPrefix(encDataKey, this.ENC_PREFIX_DATAKEY)
        );
        try {
            const dk = await this.decryptDataKey(sharekey, bytes);
            this.memoize('datakey', encDataKey, dk);
            return dk;
        } catch (ex) {
            this.Logger.error('Unable to decrypt data key');
            this.Logger.error(ex);
            throw new ErrCode(2011);
        }
    }

    /**
     * Decrypts a comment data key
     * @param encDataKey base64 number prefix of encrypted comment data key
     * @param sharekey base64 of the share key (decrypted)
     */
    public async commentDatakeyDecrypt(
        encDataKey: string,
        sharekey: string
    ): Promise<string> {
        if (!this.isValidCommentPrefix(encDataKey)) {
            throw new ErrCode(2010);
        }
        if (this.isMemoized('datakey', encDataKey) !== undefined) {
            return this.isMemoized('datakey', encDataKey);
        }
        const prefix = encDataKey.split(':')[0];
        let bytes: ArrayLike<number>;
        if (prefix === this.ENC_PREFIX_APP_LINK_DATAKEY.toString()) {
            bytes = this.mCrypt.b64ToBytes(
                this.rmPrefix(encDataKey, this.ENC_PREFIX_APP_LINK_DATAKEY)
            );
        } else {
            bytes = this.mCrypt.b64ToBytes(
                this.rmPrefix(encDataKey, this.ENC_PREFIX_DATAKEY)
            );
        }

        try {
            const dk = await this.decryptDataKey(sharekey, bytes);
            this.memoize('datakey', encDataKey, dk);
            return dk;
        } catch (ex) {
            this.Logger.error('Unable to decrypt data key');
            this.Logger.error(ex);
            throw new ErrCode(2011);
        }
    }

    public async datakeyEncrypt(
        dataKey: string,
        sharekey: string
    ): Promise<string> {
        try {
            const encKey = await this.encryptDataKey(dataKey, sharekey);
            return [this.ENC_PREFIX_DATAKEY, encKey].join(':');
        } catch (ex) {
            throw ex;
        }
    }

    /**
     * encrypts datakey for comments on slack links using sharekey.
     * The ecrypted datakey has a different prefix than that for comments on dl links and other datakey types
     */
    public async appLinkDatakeyEncrypt(
        dataKey: string,
        sharekey: string
    ): Promise<string> {
        try {
            const encKey = await this.encryptDataKey(dataKey, sharekey);
            return [this.ENC_PREFIX_APP_LINK_DATAKEY, encKey].join(':');
        } catch (ex) {
            throw ex;
        }
    }

    /**
     * This method will always return a string.  Whether it fails or not.
     * @param encName The encrypted name b64 num prefix
     * @param sharekey the b64 of the decrypted share key
     */
    public async filenameDecrypt(
        encName: string,
        sharekey?: string
    ): Promise<string> {
        if (!this.isValidPrefix(encName, this.ENC_PREFIX_FILENAME)) {
            throw new ErrCode(2009);
        }
        // if (this.isMemoized('filename', encName) !== undefined) {
        //     return Promise.resolve(this.isMemoized('filename', encName));
        // }
        const encNameBytes = this.CryptLegacy.b64ToBytes(
            this.rmPrefix(encName, this.ENC_PREFIX_FILENAME)
        );
        const key = await this.getKeyOrMeta(sharekey);

        try {
            // Must use CryptMethodLegacy due to UTF8 characters being 32bit words
            const rawBytes = this.CryptLegacy.getPartialBytes(encNameBytes, 96),
                iv = this.CryptLegacy.getPartialBytes(encNameBytes, 0, 96);
            let fname: string;
            try {
                const nameBytes = await this.CryptLegacy.symmetricDecrypt(
                    this.CryptLegacy.getPartialBytes(<number[]>key, 256),
                    rawBytes,
                    iv
                );
                fname = this.CryptLegacy.bytesToString(nameBytes);
                this.memoize('filename', encName, fname);
            } catch (ex) {
                this.Logger.e(`Unable to decrypt the file name ${encName}`, ex);
                fname = encName;
            }
            return fname;
        } catch (ex) {
            if (sharekey === undefined) {
                this.Logger.error('Unable to retrieve the meta key');
            } else {
                this.Logger.error('unable to retrieve the share key');
            }
            this.Logger.e('An error occurred in filenameDecrypt', ex);
            return encName;
        }
    }

    public async filenameEncrypt(
        plain: string,
        sharekey?: string
    ): Promise<string> {
        let sanitizedPlain: string;
        try {
            sanitizedPlain = unorm.nfc(plain);
        } catch (e) {
            this.Logger.error('Unable to check nfc encoding on file name');
            this.Logger.error('Continuing');
            sanitizedPlain = plain.toString();
        }
        const plainBytes = this.CryptLegacy.stringToBytes(sanitizedPlain);

        const key = await this.getKeyOrMeta(sharekey);

        try {
            // Must use CryptMethodLegacy due to UTF8 characters being 32bit words
            const hmacData = await this.CryptLegacy.getApiHmac(
                this.CryptLegacy.getPartialBytes(<number[]>key, 0, 256),
                plainBytes
            );
            const siv = this.CryptLegacy.getPartialBytes(hmacData, 0, 96);
            const encName = await this.CryptLegacy.symmetricEncrypt(
                this.CryptLegacy.getPartialBytes(<number[]>key, 256),
                plainBytes,
                siv
            );
            return [
                this.ENC_PREFIX_FILENAME,
                this.CryptLegacy.bytesToB64(encName),
            ].join(':');
        } catch (ex) {
            this.Logger.e('Error encrypting file name', ex);
            throw new ErrCode(2022);
        }
    }

    public async sharekeyDecrypt(
        encShareKey: string,
        shareKeyId: string,
        privatekey?: string
    ): Promise<string> {
        if (!shareKeyId) {
            this.Logger.error(
                'Missing share key id, stopping before any bad data can be created'
            );
            throw new ErrCode(2124);
        }
        if (this.isMemoized('sharekey', shareKeyId)) {
            return Promise.resolve(this.isMemoized('sharekey', shareKeyId));
        }

        const pk = await this.getKeyOrPrivate(privatekey);

        try {
            const sharekeyB64 = await this.CryptLegacy.asymmetricDecrypt(
                pk,
                encShareKey
            );
            this.memoize('sharekey', shareKeyId, sharekeyB64);
            return sharekeyB64;
        } catch (ex) {
            this.Logger.e(
                'Error attempting asymmetric decryption' +
                    ' for ' +
                    shareKeyId +
                    ' ' +
                    encShareKey,
                ex
            );
            throw new ErrCode(2123);
        }
    }

    public async sharekeyEncrypt(
        sharekey: string,
        pubkey: string
    ): Promise<string> {
        return await this.CryptLegacy.asymmetricEncrypt(pubkey, sharekey);
    }

    /**
     * Encrypts a file digest of a file
     * @param  {String|Array} digest The file's digest as bytes or hex
     * @param  {String|Array} key    The data key
     * @return {Promise}
     */
    public async filedigestEncrypt(
        digest: ArrayLike<number>,
        key: ArrayLike<number>
    ): Promise<string> {
        const iv = this.mCrypt.getRandom(96);
        const encDigest = await this.mCrypt.symmetricEncrypt(
            this.mCrypt.getPartialBytes(key, 0, 256),
            digest,
            iv
        );
        return this.ENC_PREFIX_DIGEST + ':' + this.mCrypt.bytesToB64(encDigest);
    }

    public async filedataEncrypt(
        data: ArrayLike<number>,
        key: ArrayLike<number>,
        offset: number
    ): Promise<ArrayLike<number>> {
        offset = offset || 0;
        const iv = this.getRandom(96);

        return await this.mCrypt.symmetricEncrypt(
            this.mCrypt.getPartialBytes(key, 0, 256),
            data,
            iv,
            this.mCrypt.packHeader(offset)
        );
    }

    /**
     * File Data decrypt
     * @param  {Array} data   [description]
     * @param  {Array|String} key    [description]
     * @param  {Integer} offset [description]
     * @return {Promise}
     */
    public async filedataDecrypt(
        data: ArrayLike<number>,
        key: ArrayLike<number>,
        offset: number
    ): Promise<ArrayLike<number>> {
        const header = this.mCrypt.getPartialBytes(data, 0, 96),
            iv = this.mCrypt.getPartialBytes(data, 96, 192),
            encdata = this.mCrypt.getPartialBytes(data, 192);
        if (!this.checkHeaderOffset(header, offset)) {
            throw new ErrCode(2020);
        }
        return await this.mCrypt.symmetricDecrypt(
            this.mCrypt.getPartialBytes(key, 0, 256),
            encdata,
            iv,
            header
        );
    }
    /**
     * Appends file data.  Since file data can be a read-only ArrayBuffer,
     * this function combines them.  appendedPayload is appended AFTER the
     * payload.length byte offset
     * @param  {ArrayLike<number>} payload         [description]
     * @param  {ArrayLike<number>} appendedPayload [description]
     * @return {ArrayLike<number>}
     */
    public filedataAppend(
        appendedPayload: ArrayLike<number>[],
        maxLength: number
    ): ArrayLike<number> {
        return this.mCrypt.filedataAppend(appendedPayload, maxLength);
    }
    /**
     * Prepares to send data.  For the legacy encryption engine, Int32's need
     * to have their byte order swapped
     * @param  {Array|ArrayBuffer} data [description]
     * @return {ArrayBuffer}      [description]
     */
    public prepareDataSend(data: ArrayLike<number>): ArrayLike<number> {
        return this.mCrypt.prepareDataSend(data);
    }

    /**
     * Checks if packed in header offset matches the expected offset
     * @param  {Array|ArrayBuffer} header [description]
     * @param  {Integer} offset [description]
     * @return {Boolean}
     */
    public checkHeaderOffset(
        header: ArrayLike<number>,
        offset: number
    ): boolean {
        let isValid = true;
        if (offset % this.GCM_PACKET_SIZE !== 0) {
            this.Logger.error(
                'offset is not a multiple of ' + this.GCM_PACKET_SIZE
            );
            isValid = false;
        }
        const expected_offset =
            (offset / this.GCM_PACKET_SIZE) * this.GCM_PAYLOAD_SIZE;
        const stored_offset = this.mCrypt.unpackHeader(header);
        if (expected_offset != stored_offset && stored_offset > -1) {
            isValid = false;
            this.Logger.error(
                'offset mismatch: want ' +
                    expected_offset +
                    ' got ' +
                    stored_offset +
                    ' header:' +
                    header
            );
        }
        // else {
        //     this.Logger.info('offset: want ' + expected_offset +
        //               ' got ' + stored_offset +
        //               ' header:' + header);
        // }
        return isValid;
    }

    /**
     * Encrypts a list of share keys with a list of pubkeys using RSA.
     * @param encShareKeys An array of share key data
     * @param pubkeys an array of pubkey data
     * @param privatekey an optional private key value.
     */
    public async encShareKeyListWithPubkeys(
        encShareKeys: ShareKeyData[],
        pubkeys: PubkeyData[],
        privatekey?: string
    ): Promise<ShareKeyData[]> {
        if (!encShareKeys.length) {
            this.Logger.error('encShareKeyListWithPubkeys - no share keys');
        }
        if (!pubkeys.length) {
            this.Logger.warn('encShareKeyListWithPubkeys - no pubkeys keys');
            return Promise.resolve([]);
        }
        try {
            const newEncKeys: Promise<ShareKeyData[]>[] = [];
            for (let i = 0; i < encShareKeys.length; i++) {
                const curShareKey = encShareKeys[i];

                const encKey: Promise<ShareKeyData[]> = this.sharekeyDecrypt(
                    curShareKey.enc_share_key,
                    [curShareKey.share_id, curShareKey.share_sequence].join(
                        '-'
                    ),
                    privatekey
                ).then((sharekey) => {
                    return this.encShareKeyWithPubkeys(
                        sharekey,
                        pubkeys,
                        curShareKey.share_id,
                        curShareKey.share_sequence
                    );
                });
                newEncKeys.push(encKey);
            }

            const encKeys = await Promise.all(newEncKeys);
            let result: ShareKeyData[] = [];
            for (let i = 0, len = encKeys.length; i < len; i++) {
                result = result.concat(encKeys[i]);
            }
            return result;
        } catch (ex) {
            this.Logger.e('An error occurred encShareKeyListWithPubkeys', ex);
            throw new ErrCode(2123);
        }
    }

    /**
     * Encrypts a list of linkesharekeys with a list of pubkeys.
     * @param wrapKey the wrap key in plain text
     * @param inviteData the data required for a share invite
     */
    public async encLinkShareKeyListWithPubkeys(
        wrapKey: string,
        inviteData: ShareInviteData
    ): Promise<ShareKeyData[]> {
        const shareDataArray: Promise<ShareKeyData>[] = [];
        const wrapKeyBytes = await this.convertToShareKey(
            wrapKey,
            inviteData.salt,
            inviteData.iterations
        );

        for (const [key, val] of Object.entries(inviteData.linkShareKeys)) {
            const res = this.linksharekeyDecrypt(
                <string>val,
                this.mCrypt.bytesToB64(wrapKeyBytes)
            ).then(
                (rawShareKeyBytes) => {
                    const shareId: number = parseInt(key.split('-')[0], 10);
                    const shareSequence: number = parseInt(
                        key.split('-')[1],
                        10
                    );
                    const rawShareKey = this.mCrypt.bytesToB64(
                        rawShareKeyBytes
                    );
                    return this.sharekeyEncrypt(rawShareKey, inviteData.pubkey)
                        .then((encShareKey) => {
                            const data: ShareKeyData = {
                                id: inviteData.pubkey_id,
                                share_id: shareId,
                                share_sequence: shareSequence,
                                enc_share_key: encShareKey,
                            };
                            // console.log('shareData encLinkShareKeys ' + JSON.stringify(data));
                            return data;
                        })
                        .catch((err) => {
                            this.Logger.error(
                                'ShareKeyEncrypt failed in encLinkShareKeyWithPubkeys'
                            );
                            throw new ErrCode(2122);
                        });
                },
                (err) => {
                    this.Logger.error(
                        'linksharekey decrypt failed for ' + key + ': ' + val
                    );
                    this.Logger.error(err);
                    throw new ErrCode(2121);
                }
            );
            shareDataArray.push(res);
        }
        try {
            const shareData = await Promise.all(shareDataArray);
            return shareData;
        } catch (ex) {
            this.Logger.e('Unable to encLinkShareKeyWithPubkeys', ex);
            throw new ErrCode(2120);
        }
    }

    /**
     * Encrypts 1 share key with all pubkeys.
     *
     * TODO: make shareid and sharesequence required
     * @param  {String} sharekey      The share key
     * @param  {Array.Objects} pubkeyarray   An array of pubkey objects
     * @param  {Integer} shareid       Share id
     * @param  {Integer} sharesequence Share sequencer
     * @return {Promise}
     */
    public async encShareKeyWithPubkeys(
        sharekey: string,
        pubkeys: PubkeyData[],
        shareId?: number,
        shareSequence?: number
    ): Promise<ShareKeyData[]> {
        if (!pubkeys || pubkeys.length === 0) {
            this.Logger.warn('encShareKeyWIthPubkeys called without pubkeys');
            return Promise.resolve([]);
        }
        const newKeys: Promise<ShareKeyData>[] = [];
        try {
            for (let i = 0, len = pubkeys.length; i < len; i++) {
                const publicKey = pubkeys[i];
                const data = this.sharekeyEncrypt(sharekey, publicKey.key).then(
                    (encShareKey) => {
                        return new Promise<ShareKeyData>((resolve, reject) => {
                            resolve(<ShareKeyData>{
                                id: publicKey.id,
                                share_id: shareId || publicKey.share_id,
                                share_sequence:
                                    shareSequence || publicKey.share_sequence,
                                enc_share_key: encShareKey,
                            });
                        });
                    }
                );
                newKeys.push(data);
            }
            return await Promise.all(newKeys);
        } catch (ex) {
            this.Logger.e('An error occurred encShareKeyWithPubkeys', ex);
            this.Logger.error(`${shareId}-${shareSequence} error`);
            throw new ErrCode(2124);
        }
    }

    public async encLinkShareKeyWithPubkeys(
        linkShareKeys: ShareKeyList,
        rawShareKey: string,
        publicKey: string,
        pubkeyId: number
    ): Promise<ShareKeyData[]> {
        const shareDataArray: Array<Promise<ShareKeyData>> = [];
        // A share will have link share keys or share keys depending on the state
        // of the share.  Since this is called and potentially undefined for linksharekeys,
        // return an empty array quickly.
        if (!linkShareKeys) {
            return [];
        }
        for (const [key, val] of Object.entries(linkShareKeys)) {
            const shareId: number = parseInt(key.split('-')[0], 10);
            const shareSequence: number = parseInt(key.split('-')[1], 10);
            const shareData = this.sharekeyEncrypt(rawShareKey, publicKey)
                .then((encShareKey) => {
                    const data: ShareKeyData = {
                        id: pubkeyId,
                        share_id: shareId,
                        share_sequence: shareSequence,
                        enc_share_key: encShareKey,
                    };

                    // console.log('shareData encLinkShareKeys ' + JSON.stringify(data));
                    return data;
                })
                .catch((err) => {
                    this.Logger.error(
                        'ShareKeyEncrypt failed in encLinkShareKeyWithPubkeys'
                    );
                    throw new ErrCode(2122);
                });
            shareDataArray.push(shareData);
        }

        try {
            const shareData = await Promise.all(shareDataArray);
            return shareData;
        } catch (ex) {
            this.Logger.e('Unable to encLinkShareKeyWithPubkeys', ex);
            throw new ErrCode(2120);
        }
    }

    public async mkShareData(
        inviteKey: string,
        pubkey: string
    ): Promise<ShareNewCryptData> {
        const salt: string = this.mCrypt.bytesToHex(this.mCrypt.getRandom(128)),
            it = 10000,
            sharekey = this.mkShareKey(),
            iv = this.mCrypt.getRandom(96);

        if (!pubkey) {
            this.Logger.error('Missing public key for mkShareData()');
            throw new ErrCode(2005);
        }

        const wrapKeyBytes = await this.keyStretch(inviteKey, salt, it, 32 * 8);
        const encPassword = await this.linkpasswordEncrypt(
            this.mCrypt.bytesToB64(this.mCrypt.stringToBytes(inviteKey)),
            pubkey
        );
        const linkShareKey = await this.mCrypt.symmetricEncrypt(
            wrapKeyBytes,
            sharekey,
            iv
        );
        const encShareKey = await this.sharekeyEncrypt(
            this.mCrypt.bytesToB64(sharekey),
            pubkey
        );
        const resolveData: ShareNewCryptData = {
            invite_key: inviteKey,
            enc_password: encPassword,
            link_sharekey: linkShareKey,
            link_sharekeys: {},
            link_share_key_b64: this.mCrypt.bytesToB64(linkShareKey),
            enc_share_key: encShareKey,
            salt: salt,
            sharekey: sharekey,
            iterations: it,
        };
        return resolveData;
    }

    public async backfillShareData(
        linkPass: string,
        encShareKeys: ShareKeyData[],
        pubkey: string
    ): Promise<ShareNewCryptData> {
        const salt: string = this.mCrypt.bytesToHex(this.mCrypt.getRandom(128)),
            it = 10000;
        const keys: Promise<ShareKeyData>[] = [];
        // console.log('linkPass = --' + linkPass + '--');
        // console.log(encShareKeys);
        const linkpassEnc = this.mCrypt.bytesToB64(
            this.mCrypt.stringToBytes(linkPass)
        );
        const encPassword = await this.linkpasswordEncrypt(linkpassEnc, pubkey);
        const wrapKeyBytes = await this.keyStretch(linkPass, salt, it, 32 * 8);

        // console.log('wrapKeyBytes.length = ' +  wrapKeyBytes.length)
        for (let i = 0, len = encShareKeys.length; i < len; i++) {
            const item = encShareKeys[i];
            const shareKeyId = `${item.share_id}-${item.share_sequence}`;
            if (!shareKeyId) {
                this.Logger.error(
                    'No share key id found for backfill, stopping to prevent bad data'
                );
                throw new ErrCode(
                    1000,
                    'Invlaid share key id passed to backfill share'
                );
            }
            this.Logger.info(
                'backfillShareData() Pushing keys for ' + shareKeyId
            );
            const res = this.sharekeyDecrypt(
                item.enc_share_key,
                shareKeyId
            ).then((sharekey) => {
                const shareKeyBytes = this.mCrypt.b64ToBytes(sharekey);
                return this.mCrypt
                    .symmetricEncrypt(
                        wrapKeyBytes,
                        shareKeyBytes,
                        this.mCrypt.getRandom(96)
                    )
                    .then((encShareKey) => {
                        return <ShareKeyData>{
                            share_id: item.share_id,
                            share_sequence: item.share_sequence,
                            enc_share_key: this.mCrypt.bytesToB64(encShareKey),
                        };
                    });
            });
            keys.push(res);
        }

        const result = await Promise.all(keys);

        const shareData: ShareNewCryptData = {
            salt: salt,
            iterations: it,
            invite_key: undefined,
            link_share_key_b64: undefined,
            enc_share_key: undefined,
            sharekey: undefined,
            enc_password: encPassword,
            link_sharekey: undefined,
            link_sharekeys: {},
        };
        for (let i = 0, len = result.length; i < len; i++) {
            shareData.link_sharekeys[
                `${result[i].share_id}-${result[i].share_sequence}`
            ] = result[i].enc_share_key;
        }
        return shareData;
    }

    /**
     * Encrypts the link password for storage with the user's pk.
     * @param  {String} password Base64
     * @param  {String} pubkey   Base64
     * @return {Promise}
     */
    public async linkpasswordEncrypt(
        password: string,
        pubkey: string
    ): Promise<string> {
        const encPass = await this.CryptLegacy.asymmetricEncrypt(
            pubkey,
            password
        );
        return this.ENC_PREFIX_LINKPASSWORD + ':' + encPass;
    }
    /**
     * Decrypts a link password, used to display to the creator the
     * password
     * @param  {String} enc_password [description]
     * @param  {String|null} privatekey   [description]
     * @return {Promise}
     */
    public async linkpasswordDecrypt(
        encPassword: string,
        privatekey?: string
    ): Promise<string> {
        if (!this.isValidPrefix(encPassword, this.ENC_PREFIX_LINKPASSWORD)) {
            this.Logger.error(
                'Unknown link password prefix' +
                    encPassword +
                    '--' +
                    this.ENC_PREFIX_LINKPASSWORD
            );
            throw new ErrCode(2007);
        }
        // if (this.isMemoized('linkpassword', encPassword)) {
        //     return this.isMemoized('linkpassword', encPassword);
        // }

        const encPass = this.rmPrefix(
            encPassword,
            this.ENC_PREFIX_LINKPASSWORD
        );
        const privkey = await this.getKeyOrPrivate(privatekey);
        // console.warn('Private key = ' + privkey + ' - encpass ' + encPass + ' - ' + encPassword);
        try {
            const password = await this.CryptLegacy.asymmetricDecrypt(
                privkey,
                encPass
            );
            // this.memoize('linkpassword', encPassword, password);
            // console.log('linkpassword encPassword ' + password);
            return password;
        } catch (ex) {
            this.Logger.e('Could not decrypt the link password', ex);
            throw new ErrCode(2100);
        }
    }

    public async userPasswordDecrypt(
        encPassword: string,
        privatekey?: string
    ): Promise<string> {
        // enc password doesn't have a prefix, so need for valodation
        const privkey = await this.getKeyOrPrivate(privatekey);
        try {
            const password = await this.CryptLegacy.asymmetricDecrypt(
                privkey,
                encPassword
            );
            return password;
        } catch (ex) {
            this.Logger.e('Could not decrypt the user password', ex);
            throw new ErrCode(2100);
        }
    }

    public async linksharekeyDecrypt(
        linksharekey: string,
        key: string
    ): Promise<ArrayLike<number>> {
        try {
            const keyBytes = this.mCrypt.b64ToBytes(key);
            const bytes = this.mCrypt.b64ToBytes(linksharekey);
            const raw = this.mCrypt.getPartialBytes(bytes, 96),
                iv = this.mCrypt.getPartialBytes(bytes, 0, 96);
            return await this.mCrypt.symmetricDecrypt(
                keyBytes,
                raw,
                iv
            );
        } catch (err) {
            throw err;
        }
    }

    public async linksharekeyReEncrypt(
        encPassword: string,
        sharekey: string,
        salt: string,
        iterations: number
    ): Promise<string> {
        const iv = this.mCrypt.getRandom(96),
            it: number = iterations || 10000;

        try {
            const inviteKeyB64 = await this.linkpasswordDecrypt(encPassword);
            const inviteKey = this.mCrypt.bytesToString(
                this.mCrypt.b64ToBytes(inviteKeyB64)
            );
            const shareKeyBytes = this.mCrypt.b64ToBytes(sharekey);
            this.Logger.info('Converting share key to bytes to re-encrypt');
            const wrapKeyBytes = await this.keyStretch(
                inviteKey,
                salt,
                it,
                32 * 8
            );
            const encLinkShareKey = await this.mCrypt.symmetricEncrypt(
                wrapKeyBytes,
                shareKeyBytes,
                iv
            );
            return this.mCrypt.bytesToB64(encLinkShareKey);
        } catch (ex) {
            this.Logger.e('Could not re-encrypt link share key', ex);
            throw new ErrCode(2125);
        }
    }

    /**
     * @ngdoc method
     * @name  prepareResetKey
     * @methodOf sync.service:SyncCrypt
     * @description
     * Prepares the reset key by generating a new representation of the plain
     * text password as pbkdf2.
     * @param  {String} encKey    The key to derive the password from.  We only
     *                            need the salt from this value to pbkdf2
     *                            the new password for.
     * @param  {String} plainPass The user's plain text password
     * @param  {String} pubkey    The public key to use when RSA encrypting the
     *                            new RSA result
     * @return {Promise<string>}  A 51:[base64] string fo the password
     */
    public async prepareResetKey(
        encKey: string,
        plainPass: string,
        pubkey: string
    ) {
        const encKeyBytes = this.mCrypt.b64ToBytes(encKey.substring(3));
        const salt = this.mCrypt.getPartialBytes(encKeyBytes, 0, 96);
        return await this.prepareNewPassword(plainPass, salt, pubkey);
    }

    public async prepareMigrationKeys(
        pubkey: string
    ): Promise<sync.IMigrationKeys> {
        const metakey = await this.storeDecrypt('meta_key');
        const privkey = await this.storeDecrypt('private_key');

        const aeskey = this.mCrypt.getRandom(256);

        const encPass = await this.passwordResetEncrypt(
            pubkey,
            this.bytesToB64(aeskey)
        );

        const encMeta = await this.mCrypt.passwordEncrypt(
            metakey,
            this.bytesToB64(aeskey),
            10000
        );
        const encPriv = await this.mCrypt.passwordEncrypt(
            privkey,
            this.bytesToB64(aeskey),
            10000
        );

        return {
            encMeta: encMeta,
            encPriv: encPriv,
            encPass: encPass,
        };
    }

    /**
     * @ngdoc method
     * @name  prepareResetPasswords
     * @methodOf sync.service:SyncCrypt
     * @description
     * Prepares new pbkdf2 passwords with the user's NEW plain text password
     * for use in re-encrypting and completing the password reset
     * @param  {String} plainPass The user's plain text password
     * @param  {String} pubkey    The public key to use when RSA encrypting the
     *                            new RSA result
     * @return {Promise<sync.IPasswordResetKeys>}  Reset password data
     */
    public async prepareResetPasswords(
        plainPass: string,
        pubkey: string
    ): Promise<sync.IPasswordResetKeys> {
        const metaSalt: ArrayLike<number> = this.mCrypt.getRandom(96);
        const privSalt: ArrayLike<number> = this.mCrypt.getRandom(96);

        const encPassMeta = await this.prepareNewPassword(
            plainPass,
            metaSalt,
            pubkey
        );
        const encPassPriv = await this.prepareNewPassword(
            plainPass,
            privSalt,
            pubkey
        );

        return {
            encPassMeta: encPassMeta,
            encPassPriv: encPassPriv,
            metaSalt: this.mCrypt.bytesToB64(metaSalt),
            privSalt: this.mCrypt.bytesToB64(privSalt),
        };
    }

    /**
     * @ngdoc method
     * @name  prepareNewPassword
     * @methodOf sync.service:SyncCrypt
     * @description
     * Takes the user's plain text password and a given salt and then returns
     * the PBKDF2 stretched password RSA encrypted with the given pubkey
     * @param  {String} plainPass  The user's plain text password
     * @param  {ArrayLike<number>} The random salt to use in the pbkdf2 op
     * @param  {String} pubkey     The public key to use when RSA encrypting the
     *                             new RSA result
     * @return {Promise<string>}   The 51:[base64] string result
     */
    public async prepareNewPassword(
        plainPass: string,
        salt: ArrayLike<number>,
        pubkey: string
    ) {
        const stretchBytes = await this.keyStretch(plainPass, salt, 10000, 256);
        const payload = this.mCrypt.bytesToB64(stretchBytes);
        return await this.passwordResetEncrypt(pubkey, payload);
    }
    /**
     * @ngdoc method
     * @name passwordResetEncrypt
     * @methodOf sync.service:SyncCrypt
     * @description
     * Password reset uses the server public key.
     * @return {Promise}
     */
    public async passwordResetEncrypt(pubkey: string, payload: string) {
        pubkey = pubkey.split('\n').join('%');
        const encPass = await this.CryptLegacy.asymmetricEncrypt(
            pubkey,
            payload
        );
        return `${this.ENC_PREFIX_PASSRESET}:${encPass}`;
    }

    /**
     * Encrypts Billing information.  Specifically used for credit card
     * numbers and CC expiry date
     *
     * DEPRECATED, DO NOT USE
     * @param  {String} plainB64 [description]
     * @return {Promise}          [description]
     */
    public async billingEncrypt(plainB64: string): Promise<string> {
        const pubkey = [
            '-----BEGIN PUBLIC KEY-----',
            'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWx5UWnw5PmM1MGYbZZM',
            'taKAdsoAbw+PpacnzK1QXKrY/SJyF8nsn/6TFNkSKdfR61efQyRWvjS/VIctzOHJ',
            'xHylWDj9SAoQX4WVXEtQxzPEpLJDylVu3TeLvO/y0IcwD2J9On1Z67SDghiC1Hhe',
            'u+9Wc6Df3/SXZJlfI+Rd+orXNSy85w/rhhqiVhX3Oet+vLfhB42ZthbjqXrRgAwX',
            '1N3ZrsfTkRPecgLUI9G893VWm+KgvVCkyZ2DE/9KyLG7KjITbjjzzLaIJXSDW28s',
            '5KicIwss4Xn8WAH5ZqiWvXsi6Pqh3bvosOXeaSJQyATs/lrqBXAgDydz1EB3kjGP',
            '1QIDAQAB',
            '-----END PUBLIC KEY-----',
        ].join('%');
        return await this.CryptLegacy.asymmetricEncrypt(pubkey, plainB64);
    }

    /**
     * @ngdoc method
     * @name  compatDatakeyEncrypt
     * @methodOf sync.service:SyncCrypt
     * @description
     * The compatible links requires that the data key be RSA encrypted
     * prior to being operated on.  This method will encrypt the datakey
     * @param  {String} datakeyB64 [description]
     * @return {Promise}          [description]
     */
    public compatDatakeyEncrypt(datakeyB64: string): Promise<string> {
        let pubkey: string;
        pubkey = environment.production
            ? [
                  '-----BEGIN PUBLIC KEY-----',
                  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsRA0ObSDjWc1ErNAQeN5',
                  '9WJLFNTBLHP5pGsDnRNXJfX0GkGB/PRV3vTv7OOUllZgy2J4sSnc/lZit50DcuNk',
                  'TAFU3BvNxh2qJfdVxhzdSPRw2hFnnEz4rN9+VuCbEcz4QGiVX2j3jqZLJyioJr5Q',
                  'ei+UeAcOnjHBP47H2On4sMdDyec2pSjTCsh0ZzfqSJRPRgzPJnDwwjCuBTbrV4XK',
                  'z/wfw9zFoNmwouu4z72Yg8JPO7DS0jmHR1z1CZwKdoq1BXyg9F3w+eRfaV9lQZ2e',
                  'SGbUGps3CYiHYrgqTwAfHEH1CK7ENGQW6Dd41k27N1EJyZKEN56c6G/+lHEGts20',
                  'FQIDAQAB',
                  '-----END PUBLIC KEY-----',
              ].join('%')
            : [
                  '-----BEGIN PUBLIC KEY-----',
                  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAk5AJGSZ+ZgkEu68ZJlE9',
                  '86T2xpQPyZnbVzwzO3YqfbFAyJ+3pEJ0VTGryi9yMTNWAY7D+CrZDsy5rOj6nICG',
                  '4QtaC0umrx0cyhFgQX/aa9MbedDh7WzGoSN9gX+RU2IqwA8RiIZhxXhS4pA4lSM0',
                  '9Geu+GOoXyqk9mzvMq+cIuWzqs9axtzKQXaOFsiF+4+It79iJes4NI5IbGKgCu/7',
                  'KIYse1NaD1eBCQtBw6+vVV5z+xUDdJXfaiIXvdl6fRhgL8gm8/AD+e0u3bh+glgs',
                  'LFRyfQcuyjGHzLdLzTjD+kZHFD38xFDS0a7VRSUbZfwYOSulGfD2ylh46UKJ9eaS',
                  '7QIDAQAB',
                  '-----END PUBLIC KEY-----',
              ].join('%');
        return this.CryptLegacy.asymmetricEncrypt(pubkey, datakeyB64);
    }
    public mkDataKey(): ArrayLike<number> {
        return this.mCrypt.getRandom(256);
    }
    public mkShareKey(): ArrayLike<number> {
        return this.mCrypt.getRandom(512);
    }

    public mkIv(): ArrayLike<number> {
        return this.mCrypt.getRandom(96);
    }

    public getRandom(bits) {
        return this.mCrypt.getRandom(bits);
    }
    /**
     * This is NOT async, and not a promise.
     * @return {String} Hex
     */
    public getRandomHex(bits: number): string {
        return this.mCrypt.bytesToHex(this.mCrypt.getRandom(bits));
    }

    public convertToShareKey(
        inviteKey: string,
        salt: ArrayLike<number> | string,
        iterations: number
    ): Promise<ArrayLike<number>> {
        return this.keyStretch(inviteKey, salt, iterations, 32 * 8);
    }

    /**
     * Stretches a key with a lower iteration count
     * @param  {String|Array} plain      [description]
     * @param  {String|Array} salt       [description]
     * @param  {Integer} iterations [description]
     * @param  {Integer} tlen       [description]
     * @return {Promise}
     */
    public keyStretch(
        plain: string,
        salt: ArrayLike<number> | string,
        iterations: number,
        tlen: number
    ): Promise<ArrayLike<number>> {
        return this.mCrypt.keyStretch(plain, salt, iterations, tlen);
    }
    public keyStretchSlow(
        plain: string,
        salt: ArrayLike<number> | string,
        iterations: number,
        tlen: number
    ): Promise<ArrayLike<number>> {
        return this.mCrypt.keyStretch(plain, salt, iterations, tlen);
    }

    // export the helper functions
    public hexToBytes(hex: string): ArrayLike<number> {
        return this.mCrypt.hexToBytes(hex);
    }
    public stringToBytes(str: string): ArrayLike<number> {
        return this.mCrypt.stringToBytes(str);
    }
    public b64ToBytes(b64: string): ArrayLike<number> {
        return this.mCrypt.b64ToBytes(b64);
    }
    public bytesToString(bytes: ArrayLike<number>): string {
        return this.mCrypt.bytesToString(bytes);
    }
    public bytesToB64(bytes: ArrayLike<number>): string {
        return this.mCrypt.bytesToB64(bytes);
    }
    public bytesToHex(bytes: ArrayLike<number>): string {
        return this.mCrypt.bytesToHex(bytes);
    }
    public arraybufferToBytes(buffer: ArrayBuffer): ArrayLike<number> {
        return this.mCrypt.arraybufferToBytes(buffer);
    }

    public async signApiReq<T extends BaseApiInput>(defaults: T): Promise<T> {
        // console.log('input');
        // console.log(defaults);
        const state = Object.assign({}, defaults);
        return await new Promise<T>((resolve, reject) => {
            const sel = this.store.select(fromRoot.getSignApiData);

            const sub = sel.subscribe(async (data) => {
                if (!data.access_token || !data.uid || !data.web_device_id) {
                    this.Logger.info('No data, returning unsigned');
                    return resolve(defaults);
                }
                try {
                    const accessToken = data.access_token;
                    if (!accessToken) {
                        this.Logger.error(
                            'No accesstoken - does not exist ' +
                                JSON.stringify(data)
                        );
                        return resolve(defaults);
                    }
                    const accessSecret = await this.storeDecrypt(
                        'access_secret'
                    );
                    if (!accessSecret) {
                        this.Logger.error(
                            'No accessSecret - does not exist ' +
                                JSON.stringify(data)
                        );
                        return resolve(defaults);
                    }
                    const servtime = defaults.servtime || Date.now();
                    const sigArray = await this.mCrypt.getApiHmac(
                        this.mCrypt.stringToBytes(accessSecret),
                        this.mCrypt.stringToBytes(
                            [
                                accessToken,
                                data.uid,
                                data.web_device_id,
                                servtime,
                            ].join('')
                        )
                    );
                    state.servtime = servtime;
                    state.access_token = accessToken;
                    state.signature = this.mCrypt.bytesToHex(sigArray);
                    resolve(state);
                    if (sub) {
                        sub.unsubscribe();
                    }
                } catch (ex) {
                    this.Logger.error('Error signing API request');
                    this.Logger.error(ex);
                }
            });
        });
    }

    private isValidPrefix(crypted: string, prefix: number): boolean {
        if (this.getPrefix(crypted) === prefix) {
            return true;
        } else {
            this.Logger.error(`Invalid prefix: ${crypted} != ${prefix}`);
            return false;
        }
    }

    private isValidCommentPrefix(crypted: string) {
        const prefix = this.getPrefix(crypted);
        if (
            prefix === this.ENC_PREFIX_APP_LINK_DATAKEY ||
            prefix === this.ENC_PREFIX_DATAKEY
        ) {
            return true;
        }
        this.Logger.error(
            `Invalid prefix: ${crypted}. Should be either ${
                this.ENC_PREFIX_APP_LINK_DATAKEY
            } or ${prefix === this.ENC_PREFIX_DATAKEY}`
        );
    }

    private rmPrefix(crypted: string, prefix: number): string {
        const prefixStr: string = prefix.toString();
        const prefixLen = prefixStr.length;
        // add +1 to account for the colon character
        // e.g., 23:[b64 payload];
        return crypted.substring(prefixLen + 1);
    }

    /**
     * Parses the crypted base64 string to determine the prefix.
     */
    private getPrefix(crypted: string): number {
        return crypted && crypted.indexOf(':') > 0
            ? parseInt(crypted.substring(0, crypted.indexOf(':')), 10)
            : 0;
    }

    /**
     * Gets the session password from browser storage. While in browser
     * storage, it is stored encrypted.  If it does not exist, it will
     * create a 96 bit hex string randomly.
     * @return {String} [description]
     */
    private async getSessionPassword(): Promise<string> {
        return await new Promise<string>((resolve, reject) => {
            const b = this.store.select(fromRoot.getCoreState);
            const sub = b.subscribe((data) => {
                if (
                    data.session_password === undefined ||
                    data.session_password === ''
                ) {
                    const sessionPass = this.mCrypt.bytesToHex(
                        this.mCrypt.getRandom(96)
                    );
                    this.store.dispatch(
                        new CoreActions.SetValueAction({
                            key: 'session_password',
                            value: sessionPass,
                        })
                    );
                    resolve(sessionPass);
                } else {
                    resolve(data.session_password);
                }
                if (sub) {
                    sub.unsubscribe();
                }
            });
        });
    }

    private shareKeyToBytes(sharekey: string): ArrayLike<number> {
        const bytes = this.mCrypt.b64ToBytes(sharekey);
        if (this.mCrypt.checkBitLength(bytes, 512)) {
            return bytes;
        } else {
            this.Logger.error('Sharekey was an invalid length');
            return null;
        }
    }
    private async getKeyOrMeta(sharekey?: string): Promise<ArrayLike<number>> {
        try {
            let keystr: string;
            if (!sharekey) {
                keystr = await this.storeDecrypt('meta_key');
            } else {
                keystr = sharekey;
            }
            return Promise.resolve(this.CryptLegacy.b64ToBytes(keystr));
        } catch (ex) {
            if (sharekey === undefined) {
                this.Logger.error('Unable to retrieve the meta key');
            } else {
                this.Logger.error('unable to retrieve the share key');
            }
            this.Logger.e('Unable to retrieve key', ex);
            throw new ErrCode(2001);
        }
    }

    private async getKeyOrPrivate(privatekey: string): Promise<string> {
        try {
            return !privatekey
                ? await this.storeDecrypt('private_key')
                : Promise.resolve(privatekey);
        } catch (ex) {
            this.Logger.e(
                'Error retrieving private key in sharekey decrypt',
                ex
            );
            throw new ErrCode(2006);
        }
    }
    private isMemoized(type: string, key: string): any {
        if (key in this.mCache[type]) {
            return this.mCache[type][key];
        }
        return undefined;
    }

    private memoize(type: string, key: string, value: any) {
        this.mCache[type][key] = value;
    }

    private async decryptDataKey(sharekey: string, bytes: ArrayLike<number>) {
        const sharekeyBytes = this.shareKeyToBytes(sharekey);
        if (sharekeyBytes === null) {
            throw new ErrCode(2015);
        }

        const iv = this.mCrypt.getPartialBytes(bytes, 0, 96),
            rawDk = this.mCrypt.getPartialBytes(bytes, 96);
        const datakey = await this.mCrypt.symmetricDecrypt(
            this.mCrypt.getPartialBytes(sharekeyBytes, 0, 256),
            rawDk,
            iv
        );
        return this.mCrypt.bytesToB64(datakey);
    }

    private async encryptDataKey(dataKey: string, sharekey: string) {
        const dkBytes = this.mCrypt.b64ToBytes(dataKey);
        const sharekeyBytes = this.shareKeyToBytes(sharekey);
        if (sharekeyBytes === null) {
            throw new ErrCode(2015);
        }
        const iv = this.mkIv();
        try {
            const encDkBytes = await this.mCrypt.symmetricEncrypt(
                this.mCrypt.getPartialBytes(sharekeyBytes, 0, 256),
                dkBytes,
                iv
            );
            return this.mCrypt.bytesToB64(encDkBytes);
        } catch (ex) {
            this.Logger.e('Error datakey encrypt', ex);
            throw new ErrCode(2012);
        }
    }
}
