import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sync-footer-layout',
  templateUrl: './footer-layout.component.html'
})
export class FooterLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
