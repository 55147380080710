import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor(private userService: UserService) {
    // do not initialize in dev mode
    if (!environment.isDev) {
      this.initSentry();
    }
  }

  private initSentry() {
    Sentry.init({
      environment: environment.production ? 'production' : 'staging',
      dsn: 'https://b2f86663e750e4d983cc2f812f75272a@o4507827516342272.ingest.de.sentry.io/4508003052027984',
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', /^\//],
        }),
      ],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        const is_disableanalytics = this.userService.get('is_disableanalytics'); // Get the user from the event
        // Not sending events if user has disabled analytics
        if (is_disableanalytics) {
          return null; // Do not send the event
        } else {
          event.extra = event.extra || {};

          if (hint.originalException != null) {
            try {
              // Only proceed if it's an instance of Error
              if (hint.originalException instanceof Error) {
                const keys = Object.keys(hint.originalException);

                if (keys.length) {
                  event.extra.errorProperties = {};

                  // Capture the first 10 enumerable properties
                  keys.slice(0, 10).forEach(key => {
                    event.extra.errorProperties[key] = hint.originalException[key];
                  });

                  // Optionally add stack trace if available
                  if (hint.originalException.stack) {
                    event.extra.stack = hint.originalException.stack;
                  }
                }
              } else {
                console.warn('[sentry] originalException is not an instance of Error:', hint.originalException);
              }
            } catch (error) {
              console.warn('[sentry] Failed to assign enumerable error properties to extras', error, hint.originalException);
            }
          }

          // attaching user id to sentry error
          event.user = this.userService.get('id');

          return event;
        }
      },
    });
  }

  public logError(error: any, additionalContext: any) {
    if (!environment.isDev) {
      Sentry.captureException(error, (scope: Sentry.Scope) => {
        scope.setExtra('context', additionalContext);
        return scope;
      });
    }
  }
}
