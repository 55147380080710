export interface IComment {
    author_id: number; //userID
    author_name: string;
    content: string;
    updated_at: Date;
    temp_session: string;
}
export interface CommentMetadata extends IComment {
    loc_id: string;
    replies: Reply[];
}

export interface Reply extends IComment {
    parent_id: string;
    reply_id: string;
}

export interface IResponse {
    success: number;
    status: number;
    message?: string;
}

export class SuccessRes implements IResponse {
    public success: number;
    constructor(public status = 200, public data?: unknown, public message?: string) {
        this.success = 1;
        this.status = status;
    }
}


export interface AnonymousCreationParams {
    name: string;
    email: string;
    content?: string;
    commentID?: string;
}

export interface Comment extends CommentMetadata {
    _id: string;
}

export interface LocIdCountPair {
    [key: string]: number;
}

/* -------------------------------------------------------------------------- */
/*                             DATA CARRIER TYPES                             */
/* -------------------------------------------------------------------------- */

export type CommentCreationParams = Pick<CommentMetadata, CommentCreationProperties>;

export type CommentUpdateParams = Pick<Comment, CommentUpdateProperties>;

export type ReplyUpdateParams = Pick<Reply, ReplyUpdateProperties>;

export type ReplyCreationParams = Pick<Reply, ReplyCreationProperties>;

export type DeleteParams = Pick<Comment, DeleteProperties>;

export type CommentDeleteData = Pick<Comment, CommmentDeleteDataProperties>;
export type CommentUpdateData = Pick<Comment, CommentUpdateDataProperties>;
export type ReplyUpdateData = Pick<Reply, ReplyUpdateProperties>;
export type ReplyDeleteData = Pick<Reply, ReplyDeleteDataProperties>;


/* -------------------------------------------------------------------------- */
/*                                 ENUM TYPES                                 */
/* -------------------------------------------------------------------------- */

export enum CommentCreationProperties {
    loc_id = 'loc_id',
    author_id = 'author_id',
    author_name = 'author_name',
    content = 'content',
    temp_session = 'temp_session',
}

export enum CommentUpdateProperties {
    author_id = 'author_id',
    content = 'content',
    temp_session = 'temp_session',
}

export enum ReplyCreationProperties {
    parent_id = 'parent_id',
    author_id = 'author_id',
    author_name = 'author_name',
    content = 'content',
    temp_session = 'temp_session',
}

export enum ReplyUpdateProperties {
    parent_id = 'parent_id',
    reply_id = 'reply_id',
    content = 'content',
    author_id = 'author_id',
    temp_session = 'temp_session',
}

export enum DeleteProperties {
    author_id = 'author_id',
    temp_session = 'temp_session',
}

export enum CommentUpdateDataProperties {
    _id = '_id',
    content = 'content',
    author_id = 'author_id',
    temp_session = 'temp_session',
}

export enum CommmentDeleteDataProperties {
    _id = '_id',
    author_id = 'author_id',
    temp_session = 'temp_session',
}

export enum ReplyDeleteDataProperties {
    parent_id = 'parent_id',
    reply_id = 'reply_id',
    author_id = 'author_id',
    temp_session = 'temp_session',
}
