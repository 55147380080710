import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ValidateService } from '../../core/validate.service';
import { DialogDirPickerComponent } from '../../file-list/dialog-dir-picker/dialog-dir-picker.component';
import { FileListService } from '../../file-list/services/file-list.service';
import { DirPickerService } from '../../file-list/dir-picker.service';
import { ShareInviteService } from '../share-invite.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiOutput } from '../../shared/models';
import { WebSocketService } from '../../core/websocket.service';
import { Subscription } from 'rxjs';
import { MessageType } from '../../shared/models/websocket/websocket-message';
import { ProgressPayload } from '../../shared/models/websocket/action-payload';
import { FeatureService } from '../../shared/services/feature.service';

@Component({
    selector: 'sync-share-invite',
    templateUrl: './share-invite.component.html',
})
export class ShareInviteComponent implements OnInit, OnDestroy {
    public cachekey: string;
    public wrapKey: string;
    public shareId: number;

    public confirmSent: boolean;

    public errors: Array<sync.IErrorCode>;
    public missingPasswordLabel: string;
    public missingPassword: boolean;
    public isLoaded: boolean;

    public validate: sync.IValidateService;

    public destinationItem = {
        sync_id: 0,
        name: 'Sync Folder',
    };

    public spinners = {
        join: false,
        decline: false,
        destination: false,
        invite: false,
    };

    public inviteData: sync.IShareInviteData;

    protected chkFileList: string[] = [];

    // white label attributes
    public isWhiteLabel: boolean;
    public buttonPrimaryColor: string;
    public buttonTextColor: string;
    public shareAcceptJob: BaseApiOutput;
    public bg: number;

    private progressSubscription: Subscription;
    public progressText: string;
    private isWsProgressAllowed = false;

    sharefolder = new FormGroup({
        name: new FormControl('', [
            Validators.required,
            this.leadingSpace.bind(this),
            this.trailingChar.bind(this),
            this.reservedNames.bind(this),
            this.illegalCharacters.bind(this),
            this.exist.bind(this),
        ]),
        displayName: new FormControl('', Validators.required),
    });

    constructor(
        private loggerService: LoggerService,
        private userService: UserService,
        private fileListService: FileListService,
        private validateService: ValidateService,
        private modalService: NgbModal,
        private dirPickerService: DirPickerService,
        private shareInviteService: ShareInviteService,
        private route: ActivatedRoute,
        private router: Router,
        private webSocketService: WebSocketService,
        public featureService: FeatureService
    ) { }

    leadingSpace(control: FormControl) {
        const name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return name && name.charAt(0) !== ' ' ? null : { leadingSpace: true };
    }

    trailingChar(control: FormControl) {
        const name = control.value;
        const lastChar = name ? name.charAt(name.length - 1) : '';
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ')
            ? null
            : { trailingChar: true };
    }
    reservedNames(control: FormControl) {
        const name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return !/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i.test(name) &&
            name.toLowerCase() !== '.syncinfo' &&
            name.toLowerCase() !== 'thumbs.db' &&
            name.toLowerCase() !== 'desktop.ini' &&
            name.toLowerCase() !== 'sync.cache' &&
            name.toLowerCase() !== '$recycle.bin' &&
            name.indexOf('.~') !== 0 &&
            name.indexOf('~') !== 0 &&
            name.indexOf('~$') === -1 &&
            !/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i.test(
                name
            )
            ? null
            : { reserved: true };
    }

    illegalCharacters(control: FormControl) {
        const name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return /^[^\/:\*\?"<>\|]+$/.test(name) ? null : { illegal: true };
    }

    exist(control: FormControl) {
        const name = control.value;
        if (!this.chkFileList) {
            return true;
        }
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        const found = this.chkFileList.filter((val) => {
            return val.toLowerCase() == name.toLowerCase();
        });
        return !(found && found.length > 0) ? null : { exist: true };
    }

    async ngOnInit() {
        this.errors = [];
        this.cachekey = this.route.snapshot.params['invite_id'] || this.route.snapshot.queryParams['invite_id'];

        if (!this.router.url.includes('/shares/rejoin') && this.route.snapshot.queryParams['invite_id']) {
            this.router.navigate([`/shares/invite/${this.cachekey}`]);
        }

        this.shareId = parseInt(this.route.snapshot.queryParams['share_id'], 10);
        this.wrapKey = this.route.snapshot.fragment;
        if (!this.cachekey) {
            this.errors.push({
                code: 1702,
                msg: 'The url is missing the invite id',
            });
        } else {
            this.initializeInvite();
        }

        this.isWsProgressAllowed = await this.featureService.isAllowed('websocketProgress');
    }

    ngOnDestroy(): void {
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }

    public async shareAccept() {
        try {
            this.spinners.join = true;

            if (this.isWsProgressAllowed) {
                this.progressSubscription = this.webSocketService
                    .getMessageHandler<ProgressPayload>(MessageType.SHARE_ACCEPT_PROGRESS)
                    .subscribe((progress) => {
                        if (progress.share_id === this.inviteData.shareId) {
                            this.progressText = `${progress.progressPercentage}%`;
                        }
                    });
            }

            this.shareAcceptJob = await this.shareInviteService.accept(
                this.wrapKey,
                this.destinationItem.sync_id,
                this.inviteData
            );
            this.loggerService.info('share-invite -> share accept job complete');
            this.spinners.join = false;
            this.router.navigate(['/shares']);
        } catch (err) {
            this.loggerService.error(err);
            this.loggerService.error('Could not accept the share');
            this.handleErr({ code: 1612 });
        }
    }

    public async shareDecline() {
        try {
            this.spinners.decline = true;
            await this.shareInviteService.decline(this.inviteData);
        } catch (err) {
            this.loggerService.error(err);
            this.loggerService.error('Could not decline the share');
            this.handleErr({ code: 1613 });
        } finally {
            this.spinners.decline = false;
            this.router.navigate(['/shares']);
        }
    }

    public async shareNeedConfirm() {
        await this.shareInviteService.needConfirm(this.cachekey);
        this.confirmSent = true;
        this.isLoaded = false;
    }

    public getDestination() {
        return this.dirPickerService.getDestination();
    }

    public openDirPicker() {
        const ref = this.modalService.open(DialogDirPickerComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });

        ref.result.then((value) => {
            // when the dir is chosen, trigger getting new path list and
            // then re-run validation, basically to check if name exists.
            this.chkFileList = [];
            return this.getCurrentList(value.sync_id).then(() => {
                this.destinationItem.sync_id = value.sync_id;
                this.destinationItem.name = value.name;
                this.spinners.destination = false;
            });
        });
    }

    public async initializeInvite() {
        try {
            this.spinners.invite = true;
            this.errors = [];
            const result = await this.shareInviteService.getInvite(
                this.cachekey,
                this.wrapKey,
                this.shareId
            );
            this.inviteData = result;
            this.sharefolder.setValue({
                name: this.inviteData.name ? this.inviteData.name : '',
                displayName: this.inviteData.displayName
                    ? this.inviteData.displayName
                    : '',
            });
            const res = await this.getCurrentList(this.userService.get('syncus_sync_id'));
            this.spinners.invite = false;
            this.isLoaded = true;
            this.missingPassword = false;
            this.destinationItem.sync_id =
                this.userService.get('syncus_sync_id');
            this.destinationItem.name = 'Sync Folder';
            if (this.inviteData.image_cachekey) {
                this.isWhiteLabel = true;
                this.buttonTextColor = this.inviteData.button_text_color;
                this.buttonPrimaryColor = this.inviteData.button_primary_color;
            }
            return res;
        } catch (err) {
            if (err.code == 1621) {
                if (err.msg) {
                    this.missingPasswordLabel = err.msg;
                }
                this.spinners.invite = false;
                this.isLoaded = false;
                this.missingPassword = true;
            } else {
                this.handleErr(err);
            }
        }
    }

    private handleErr(err: any) {
        if (typeof err == 'object' && err.code) {
            this.errors.push({
                code: err.code,
                msg: err.msg,
            });
        } else if (typeof err == 'object' && err.error_code) {
            this.errors.push({
                code: err.error_code,
            });
        } else {
            this.errors.push({ code: 1611 });
        }
    }

    private async getCurrentList(syncId: number) {
        const data = await this.fileListService.getDataNoState(syncId, '0');
        data.pathlist.map((cur) => {
            this.chkFileList.push(cur.name);
        });
        return this.chkFileList;
    }
}


