<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="close()"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Unshare {{ shareData.name }}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            Everyone will be removed from this folder. You will still keep a
            copy of this folder in Sync.
        </div>
    </div>

    <hr />
    <div class="row">
        <div class="col-sm-4">Remote wipe:</div>
        <div class="col-sm-8">
            <div class="radio radio-top">
                <label>
                    <input
                        type="radio"
                        name="selectedPurge"
                        [(ngModel)]="selectedPurge"
                        id="share-delete-purge-true"
                        value="1"
                        checked=""
                    />
                    Yes
                </label>
            </div>

            <div class="radio radio-bottom">
                <label>
                    <input
                        type="radio"
                        name="selectedPurge"
                        [(ngModel)]="selectedPurge"
                        id="share-delete-purge-false"
                        value="0"
                    />
                    No (let users keep a copy of this shared folder)
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" id="unshare-dialog-btn" (click)="stopShare()">
        <i class="fa fa-spin fa-spinner" [class.hide]="!spinner"></i>
        Unshare
    </button>
    <button type="button" class="btn btn-default" (click)="close()">
        Cancel
    </button>
</div>
