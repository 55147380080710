<div class="modal-header">
        <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Delete device?</h4>
    </div>
<div class="modal-body">
    <div class="row">
        <div class="col-sm-12">
            This will delete this device and disable it from working with Sync.
        </div>
    </div>

    <hr/>

    <div class="row">
        <div class="col-sm-4">Confirm:</div>
        <div class="col-sm-8">
            <div class="checkbox">
                <label>
                    <input name="confirm" type="checkbox" [(ngModel)]="confirm" value="1">
                    I confirm that I want to delete this device
                </label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button 
        type="button"
        class="btn btn-primary"
        (click)="delete()"
        [disabled]="!confirm"
    >Delete</button>
    <button type="button" class="btn btn-default" (click)="activeModal.close()">Cancel</button>
</div>
