import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { FileListService } from './services/file-list.service';
import { FileSelectionService } from './services/file-selection.service';
import { ThumbnailsService } from './services/thumbnails.service';
import { FileListComponent } from './file-list/file-list.component';
import { ErrorModule } from '../error/error-module';
import { DirPickerComponent } from './dir-picker/dir-picker.component';
import { DialogDirPickerComponent } from './dialog-dir-picker/dialog-dir-picker.component';
import { NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NewFolderComponent } from './new-folder/new-folder.component';
import { UtilModule } from '../util/util-module';
import { FilterListComponent } from './filter-list/filter-list.component';
import { FileListMenuComponent } from './file-list-menu/file-list-menu.component';
import { PathActivateActionDirective } from '../file-actions/path-activate-action.directive';
import { QueuePathDownloadDirective } from '../transfer/queue-path-download.directive';
import { PathRenameDirective } from '../file-actions/path-rename.directive';
import { DialogCreateBlankDocumentComponent } from './dialog-create-blank-document/dialog-create-blank-document.component';
import { FileListRoutingModule } from './file-list-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { InlineShareInfoComponent } from './inline-share-info/inline-share-info.component';
import { DialogFileZipComponent } from './dialog-file-zip/dialog-file-zip.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FileListRoutingModule,
        LayoutModule,
        SharedModule,
        NgbModule,
        NgbModalModule,
        UtilModule,
        ErrorModule,
        NgbDropdownModule,
        NgbPaginationModule,
    ],
    exports : [
        DirPickerComponent,
        FilterListComponent
    ],
    providers: [FileListService, FileSelectionService, ThumbnailsService],
    declarations: [
        DirPickerComponent,
        DialogDirPickerComponent,
        FilterListComponent,
        FileListMenuComponent,
        NewFolderComponent,
        PathActivateActionDirective,
        QueuePathDownloadDirective,
        PathRenameDirective,
        FileListComponent,
        DialogCreateBlankDocumentComponent,
        InlineShareInfoComponent,
        DialogFileZipComponent
    ],
    entryComponents: [
        DirPickerComponent,
        DialogDirPickerComponent,
        FileListMenuComponent,
        FilterListComponent,
        NewFolderComponent,
        FileListComponent,
        DialogCreateBlankDocumentComponent,
        InlineShareInfoComponent,
        DialogFileZipComponent
    ]
})

export class FileListModule { }




