
<div class="container-fluid container-fluid-wrap">
    <div class="row">
        <div class="col-sm-12 affix header-box-withmenu">
            <div class="header-title">User admin</div>

            <div class="right-menu-button right-menu-button-push affix">
                <button
                    (click)="addUser()"
                    class="btn btn-default"
                    type="button"
                >+ Add user</button>
                <div class="right-menu-button-info" *ngIf="limit > 1">
                    <i class="zmdi zmdi-account-circle "></i>
                    Users available:{{limit - getAmount()}}/{{limit}}
                </div>
            </div>

            <ul class="nav nav-tabs">
                <li role="presentation" class="active"><a>Users</a></li>
                <li role="presentation"><a  (click)="addUser()">+ Add user</a></li>
            </ul>
        </div>
    </div>
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12 content-box-withmenu">
            <table class="table list-table list-table-push">
                <thead>
                    <tr>
                        <th class="table-username">Name</th>
                        <th class="table-useractivity">Last active</th>
                        <th class="table-userusage hidden-xs">Usage</th>
                        <th class="table-usersettings"></th>
                        <th class="table-actions"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-username">
                            <div class="tdmore">
                                <strong>You</strong>
                            </div>
                            <div class="tdmore subtext">{{user.account_type}}</div>
                        </td>
                        <td class="table-useractivity"><div class="tdmore">{{lastSyncTime | date:'medium'}}</div></td>
                        <td class="table-userusage hidden-xs">
                            <div class="tdmore">
                                {{user.disk_usage_bytes | bytesToSize }}
                            </div>
                        </td>
                        <td class="table-usersettings"></td>
                        <td class="table-actions"></td>
                    </tr>

                    <tr *ngFor="let person of currentList">
                        <td class="table-username">
                            <div class="tdmore">
                                <strong>{{person.email}}</strong>
                            </div>
                            <div class="tdmore subtext">
                                {{person.plan_name}}
                            </div>
                        </td>
                        <td class="table-useractivity">
                            <div class="tdmore">{{person.last_filesync_time | date:'medium'}}</div>
                        </td>
                        <td class="table-userusage hidden-xs">
                            <div class="tdmore" title="{{person.disk_usage_bytes | bytesToSize }} / {{person.disk_limit_bytes | bytesToSize}}">
                                {{person.disk_usage_bytes | bytesToSize }}
                            </div>
                        </td>
                        <td class="table-usersettings">
                            <div class="tdmore">
                                <span *ngIf="person.has_disable_purge">(no purge)</span>
                            </div>
                        </td>
                        <td class="table-actions">

                            <div ngbDropdown placement="bottom-right" class="dropdown actions-dropdown">
                                <i ngbDropdownToggle class="fas fa-ellipsis-h pull-right dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"></i>
                                <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-regular" style="width:230px;" aria-labelledby="dropdownMenu1">
                                    <li>
                                        <a (click)="openSettings(person, 1)">
                                            <i class="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg"></i>
                                            User settings
                                        </a>
                                    </li>
                                    <li>
                                        <a (click)="openSettings(person, 2)">
                                            <i class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i>
                                            Remove user
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="table-pagination pull-right">
                    <!-- <nav aria-label="Page navigation" >
                        <ul class="pagination">
                        <li>
                        <a href="#" aria-label="Previous">
                            <span aria-hidden="true"><i class="fas fa-angle-left"></i></span>
                        </a>
                        </li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li>
                        <a href="#" aria-label="Next">
                            <span aria-hidden="true"><i class="fas fa-angle-right"></i></span>
                        </a>
                        </li>
                        </ul>
                    </nav> -->

            </div>

            <div class="table-info">
                <!-- <i class="fas fa-info-circle"></i>
                Showing 1 to 50 of 180 items -->
            </div>
        </div>
    </div>
</div>



    <!--





    <div class="container">

        <div class="row">
            <div class="col-xs-12">
                <div class="view-title">
                Multi-user
                </div>
            </div>
        </div>

        <div sync-error-display-hash="errors"></div>

        <div class="accounttabwrap">
        <uib-tabset active="activeTabIdx">
        <uib-tab>
            <uib-tab-heading>Users</uib-tab-heading>
            <div class="bordertop">

                <div class="row tabrow">
                    <div class="col-xs-12">

                    <h3>
                    Available: {{limit - getAmount()}} / {{limit}}
                    </h3>

                    </div>

                </div>

        <div class="row tabrow">

        <div class="col-sm-12">

            <ul class="sync-table" style="border-left:0px; border-right:0px;" >

            <li class="sync-table-header">
                <span class="sync-col-accountleft subcolor">
                Current Users
                </span>
            </li>

            <li class="sync-table-header">

                <span class="sync-col-accountleft">
                   <strong>You</strong><br /> <span ng-bind="userPlan"></span>
                </span>


                <span class="sync-col-modalstatus">

                </span>
            </li>

            <li class="sync-table-row"
                ng-repeat="person in currentList |orderBy:'displayName'">

                <span class="sync-col-multiuser-details">
                   <strong>{{person.displayName}}</strong> ({{person.email}})<br /> {{person.planName}}
                   <br />Added: {{person.signupDate|date:'medium'}}
                    <br />Last active: {{person.lastFilesyncTime | date:'medium'}}
                 <br />Usage: {{person.diskUsageBytes | bytesToSize }} / {{person.diskLimitBytes | bytesToSize}}


                </span>

                <span class="sync-col-multiuser-action">
                    <div uib-dropdown class="dropdown" >
                        <i
                            uib-dropdown-toggle
                            title="Manage this user"
                            class="showhand ion-android-more-vertical"
                        ></i>
                        <ul role="menu" uib-dropdown-menu class="pull-right dropdown-menu showhand">
                            <li class="dropdown-header">
                                <h5>{{person.email}}</h5>
                            </li>

                            <li class="divider"></li>
                            <li ng-show="person.hasPasswordReset">
                                <a ng-click="changePassword(person)">
                                    <i class="fa fa-key"></i>
                                    Change password
                                </a>
                            </li>
                            <li class="disabled" ng-show="!person.hasPasswordReset">
                                <a ng-click="">
                                    <i class="fa fa-key"></i>
                                    Change password disabled
                                </a>
                            </li>
                            <li class="divider"></li>
                            <li class="dropdown-header">
                                <h5>Permissions</h5>
                            </li>
                            <li>
                                <a data-sync-stop-propagation ng-click="togglePurge(person)">
                                    <i ng-class="{
                                        'fa fa-spinner fa-spin': spinners.disablepurge,
                                        'ion-android-checkbox': person.hasDisablePurge && !spinners.disablepurge,
                                        'ion-android-checkbox-outline-blank': !person.hasDisablePurge  && !spinners.disablepurge}"
                                    ></i>
                                    Disallow purge
                                </a>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <a ng-click="confirmRemove(person);">
                                    <i class="fa fa-user-times"></i>
                                    Remove user
                                </a>
                            </li>
                        </ul>
                    </div>
                </span>
            </li>

            </ul>

        </div>
                </div>
        </div>
        </uib-tab>
        <uib-tab>
            <uib-tab-heading><i class="fa fa-plus"></i>
               Add User
            </uib-tab-heading>
            <div class="bordertop">


                <div class="row tabrow">
                    <div class="col-xs-12">

                    <h3>
                    Available: {{limit - getAmount()}} / {{limit}}
                    </h3>

                    </div>

                </div>


                 <div class="row tabrow" ng-show="!hasEmptySlots()">
                    <div class="col-sm-12">
                    You'll need to remove a current user, or purchase additional users to add more.

                    <p class="help-block black" style="padding-top:10px;">Select number of additional users to add:</p>

                    <div style="float:left;">
                    <select
                    ng-model="addUserAmount"
                    class="form-control"
                    style="width:150px;"
                    >
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    </select>
                    </div>
                    <div style="float:left; margin-left:10px;">
                    <button ng-click="orderAddUser()" class="btn btn-primary" type="submit">Next step</button>
                    </div>
                    <p style="clear:both;padding-top:10px;">
                    Click next step to get instant quote and purchase.
                    </p>

                    </div>
                </div>




                <div class="row tabrow" ng-show="hasEmptySlots()">
                    <div class="col-sm-6">
                    <form ng-submit="addQueue()">
                    <p><strong>Add an existing Sync user:</strong></p>

                    <span class="help-block">
                        Use this option if the user already has a Sync account.
                    </span>

                    <hr />

                    <div class="form-group ">
                        <label>Username:</label>
                        <input
                            type="email"
                            required
                            class="form-control"
                            ng-model="person"
                            placeholder="Enter email address"
                        />
                    </div>
                    <div class="form-group ">
                        <label>Ref code: <a class="syncblue" target="_blank" href="https://www.sync.com/help/how-do-i-find-the-ref-code">(how do I find the ref code?)</a></label>
                        <input
                            type="text"
                            required
                            class="form-control"
                            ng-model="personCode"
                            placeholder="Enter ref code"
                        />
                    </div>
                    <div class="form-group">
                        <button
                            class="btn btn-primary"
                            ng-disabled="spinners.add">
                            <i class="fa fa-spinner fa-spin" ng-show="spinners.add"></i>
                            Add user</button>
                    </div>

                    <span class="help-block">
                    Users will be upgraded to PRO, and billing and upgrade options for the user will be managed exclusively under your account (these options will no longer be available for the user).
                    </span>
                    </form>
                    </div>
                    <div class="col-sm-6">

                    <p><strong>Create and add a new Sync PRO user:</strong></p>

                     <span class="help-block">
                        Use this option if the user doesn't have a Sync account.
                    </span>

                    <hr />

                <form ng-submit="signup()">
                <div class="form-group">
                    <label>Username:</label>
                    <input
                        type="email"
                        required
                        ng-model="newusername"
                        class="form-control"
                        id="text-provision-newusername"
                        name="newusername"
                        placeholder="Enter email address"
                    />
                </div>

                <div class="form-group">
                    <label>Password:</label>
                    <div class="input-group">
                        <input
                            required
                            type="password"
                            ng-model="newpassword"
                            class="form-control"
                            id="text-provision-newpassword"
                            name="newpassword"
                            placeholder="Enter password"
                        />
                        <span class="input-group-btn">
                        <button
                           id="btn-provision-login"
                            type="submit"
                            class="btn btn-primary"
                            ng-disabled="spinners.provision"
                        >Sign up and add
                            <i ng-class="{'fa fa-chevron-right': !spinners.provision,
                                          'fa fa-spin fa-spinner': spinners.provision}"></i>
                        </button>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        <input type="checkbox" ng-model="passReset" />
                        Enable password reset
                    </label>
                </div>
                </form>


                    <span class="help-block">
                    New users will be sent an email verification to activate their account.
                    You will also need to provide new users with the password, or instruct them to issue a password reset (if enabled), to create their own password.
                    <br />
                    <br />
                    Billing and upgrade options for the user will be managed exclusively under your account.
                    </span>



                    </div>

                </div>



            </div>
        </uib-tab>
            </uib-tabset>



        </div>
    </div>

    </div> -->
