import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackService } from '../../notifications/feedback.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ShareService } from '../share.service';
import { JoblockService } from 'src/app/core/joblock.service';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { LoggerService } from '../../core/logger.service';

@Component({
    selector: 'sync-share-delete',
    templateUrl: './share-delete.component.html',
})
export class ShareDeleteComponent implements OnInit {

    @Input() public openState: number;
    @Input() public shareData: sync.IShareData;
    public selectedPurge = '1'; // default true
    @Input() public modalInstance: NgbModalRef;
    public spinner = false;
    public errors: sync.IErrorCode[] = [];
    public bg = 0;
    public stopShareJob: any;

    constructor(
        private feedbackService: FeedbackService,
        private shareService: ShareService,
        private broadcastService: BroadcastService,
        private JobLockService: JoblockService,
        private loggerService: LoggerService,
        private store: Store<State>,
    ) {}

    ngOnInit() {}

    public async poststopShareAction() {
        if (this.stopShareJob.bg == 1) {
            const response = await this.JobLockService.pollJobStatus();
        }
        this.broadcastService.broadcast('event:file-list.reload');
        this.broadcastService.broadcast('event:share-list.reload');
        this.spinner = false;
        this.feedbackService.setFeedback(
            'success',
            `The share ${this.shareData.name} has been stopped`,
            2000
        );
        this.modalInstance.close();
    }

    public async stopShare() {
        try {
            this.spinner = true;
            this.errors = [];
            this.stopShareJob = await this.shareService.stopShare(
                this.shareData.shareId,
                this.shareData.sharememberId,
                this.selectedPurge == '1'
            );
            this.poststopShareAction();
        } catch (err) {
            this.spinner = false;
            this.errors.push(err);
        }
    }

    close() {
        this.modalInstance.close();
    }
}
