import { CommentBlockComponent } from '../comment-block/comment-block.component';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Base64Service } from '../../../../core/base64.service';
import { ApiService } from '../../../../core/api.service';
import { CommentService } from '../../../../core/comment.service';
import { LoggerService } from '../../../../core/logger.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
    EventEmitter,
    OnDestroy,
    SimpleChanges,
    HostBinding,
    OnChanges
} from '@angular/core';
import {
    CommentDeleteData,
    CommentUpdateData,
    ReplyDeleteData,
    ReplyUpdateData,
    AnonymousCreationParams,
    Comment,
    CommentCreationParams,
    Reply,
    ReplyCreationParams,
    DeleteParams,
} from '../../../models/commments.model';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { IFile } from 'sync';
import * as fromLinkFileList from '../../../../reducers/link-file-list.reducer';
import { User } from '../../../models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrCode } from '../../../models/err-code.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'sync-comment',
    templateUrl: './comment.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CommentComponent implements OnInit, OnDestroy, OnChanges {
    //a comment entity that will be displayed in the comment block, undefined if this comment entity is for user input
    @Input() comment: Comment | undefined;
    @Input() user: User | undefined;
    @Input() cdk: string; //comment decrypted data key used for comment encryption/decryption
    @Input() isInputBox: boolean; //flag to display this entity as an input box
    @Input() ownerId: number;
    @Input() tempSession: string; //base64 string represents a session token for anonymous user, empty if authenticated
    @Input() locId: string; // [share id]-0-[publink cachekey]
    @Input() item: IFile;
    @Input() publinkId: string;
    @Input() link: fromLinkFileList.State;

    @Output() commentCreationEvent = new EventEmitter<CommentCreationParams>();
    @Output() commentEditionEvent = new EventEmitter<CommentUpdateData>();
    @Output() commentDeletionEvent = new EventEmitter<CommentDeleteData>();
    @Output() commentGetEvent = new EventEmitter();
    @Output() replyCreationEvent = new EventEmitter<ReplyCreationParams>();
    @Output() replyEditionEvent = new EventEmitter<ReplyUpdateData>();
    @Output() replyDeletionEvent = new EventEmitter<ReplyDeleteData>();
    @Output()
    anonymousCreationEvent = new EventEmitter<AnonymousCreationParams>();

    commentCreationForm: FormControl;
    commentEditionForm: FormControl;
    userAvatar: string; // current user avatar
    authorAvatar: string; // comment author avatar
    avatarEndpoint = `${environment.logohost}avatar`;
    detailEnabled: boolean;
    postButtonEnabled: boolean;
    confirmedDelete: boolean;
    buttonPrimaryColor: string;
    buttonTextColor: string;
    linkTextColor: string;
    editMode: boolean;
    commentInputBoxEnabled: boolean;
    replyInputBoxEnabled: boolean;
    anonymousPromptEnabled: boolean;
    anonymousFormEnabled: boolean;
    currentPath: string;
    loginUrl: string;
    anonymousForm: FormGroup;
    viewDetailAnonymousForm: FormGroup;
    viewDetailPromptEnabled: boolean;
    viewDetailAnonymousFormEnabled: boolean;
    replyAnonymousPromptEnabled: boolean;
    replyAnonymousFormEnabled: boolean;
    replyAnonymousForm: FormGroup;
    contentLength = 200;
    anonymousNameLength = 35;
    errCode: ErrCode;
    private replies: Reply[];
    private movementEvents = ['keydown', 'click']; //types of user movement that delays reloading the page

    constructor(
        private log: LoggerService,
        private commentService: CommentService,
        private api: ApiService,
        private base64: Base64Service,
        private sanitizer: DomSanitizer,
        private fb: FormBuilder,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.postButtonEnabled = false;
        const contentValidators = [
            Validators.maxLength(this.contentLength),
            Validators.minLength(1),
        ];
        const nameValidators = [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(this.anonymousNameLength),
        ];
        if (this.comment) {
            this.replies = this.comment.replies;
        }

        if (this.isInputBox) {
            this.commentCreationForm = new FormControl('', contentValidators);
        }

        if (this.comment) {
            this.commentEditionForm = new FormControl(
                { value: this.comment.content, disabled: true },
                contentValidators
            );
            this.viewDetailAnonymousForm = this.fb.group({
                name: ['', nameValidators],
                email: ['', Validators.email],
            });
            this.replyAnonymousForm = this.fb.group({
                name: ['', nameValidators],
                email: ['', Validators.email],
                content: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(1),
                        Validators.maxLength(this.contentLength),
                    ],
                ],
            });
        } else {
            this.commentEditionForm = new FormControl(
                { value: '', disabled: true },
                contentValidators
            );
        }

        //set avatar for current user and comment author
        if (this.user && this.user.exists) {
            this.userAvatar = `${this.avatarEndpoint}/${this.user.id}`;
        } else {
            this.userAvatar = '../../../images/anonymous.png';
        }
        if (this.comment && !this.comment.temp_session) {
            this.authorAvatar = `${this.avatarEndpoint}/${this.comment.author_id}`;
        } else {
            this.authorAvatar = '../../../images/anonymous.png';
        }

        this.anonymousForm = this.fb.group({
            name: ['', nameValidators],
            email: ['', Validators.email],
            content: [
                '',
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(this.contentLength),
                ],
            ],
        });

        //comment feature
        if (this.link.image_cachekey) {
            this.buttonPrimaryColor = this.link.button_primary_color;
            this.buttonTextColor = this.link.button_text_color;
            this.linkTextColor = this.link.link_text_color;
        }

        this.commentInputBoxEnabled = true;
        const pathname = window.location.pathname.substring(
            1,
            window.location.pathname.length
        );
        this.currentPath = pathname.concat(window.location.hash); // append #hash part if any for enhanced privacy links
        this.loginUrl = `${environment.cphost}/login`;
        this.movementEvents.forEach((e) =>
            document.addEventListener(e, this.clearError.bind(this), false)
        );
    }

    ngOnDestroy() {
        this.movementEvents.forEach((e) =>
            document.removeEventListener(e, this.clearError.bind(this), false)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.comment) {
            this.replies = changes.comment.currentValue.replies;
        }
        if (changes.user) {
            this.user = changes.user.currentValue;
        }

        if (changes.link) {
            this.link = changes.link.currentValue;
        }
        if (changes.tempSession) {
            this.tempSession = changes.tempSession.currentValue;
        }
        if (changes.commentInputBoxEnabled) {
            this.commentInputBoxEnabled =
                changes.commentInputBoxEnabled.currentValue;
        }
    }

    createComment() {
        let newComment: CommentCreationParams;
        newComment = {
            loc_id: this.route.snapshot.params['key'],
            author_id: this.user.id,
            author_name: this.user.display_name,
            content: this.commentCreationForm.value as string,
            temp_session: this.tempSession,
        };

        this.commentCreationEvent.emit(newComment);
        this.commentCreationForm.reset();
        this.postButtonEnabled = false;
    }

    editComment() {
        this.editMode = true;
        this.commentEditionForm.enable();
    }

    updateComment() {
        let data: CommentUpdateData;
        data = {
            _id: this.comment._id as string,
            author_id: this.user.id,
            content: this.commentEditionForm.value as string,
            temp_session: this.tempSession,
        };

        this.commentEditionEvent.emit(data);
        this.editMode = false;
        this.commentEditionForm.disable();
    }

    deleteComment() {
        let data: CommentDeleteData;
        data = {
            _id: this.comment._id,
            author_id: this.user.id,
            temp_session: this.tempSession,
        };

        this.commentDeletionEvent.emit(data);
    }

    async createReply(newReply: ReplyCreationParams) {
        const contentLength = newReply.content.length;
        try {
            //encrypt reply content
            newReply.content = await this.commentService.encryptContent(
                this.cdk,
                newReply.content
            );
            const input = {
                servtime: Date.now(),
                access_token: '',
                signature: '',
                ...newReply,
                content_length: contentLength.toString(),
            };
            const signedInput = await CommentBlockComponent.signRequest(input);
            //api call to comment server, posting a reply
            const response = await this.commentService.createReply(
                newReply,
                signedInput
            );
            this.log.info('Reply has been created');
            this.commentGetEvent.emit();
            this.sendNotification('POST');
            this.replyInputBoxEnabled = false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async updateReply(data: ReplyUpdateData) {
        try {
            //encrypt reply content
            const contentLength = data.content.length;
            data.content = await this.commentService.encryptContent(
                this.cdk,
                data.content
            );

            const input = {
                servtime: Date.now(),
                access_token: '',
                signature: '',
                ...data,
                content_length: contentLength.toString(),
            };
            const signedInput = await CommentBlockComponent.signRequest(input);

            const response = await this.commentService.updateReply(
                data,
                signedInput
            );
            this.log.info('Reply has been updated');
            this.commentGetEvent.emit();
            this.sendNotification('PATCH');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async deleteReply(data: ReplyDeleteData) {
        const deleteParams: DeleteParams = {
            author_id: data.author_id,
            temp_session: encodeURIComponent(data.temp_session), //encode special  characters
        };
        const input = {
            servtime: Date.now(),
            access_token: '',
            signature: '',
            ...deleteParams,
        };
        try {
            const signedInput = await CommentBlockComponent.signRequest(input);

            const response = await this.commentService.deleteReply(
                data,
                signedInput
            );
            this.log.info('Reply has been deleted');
            this.commentGetEvent.emit();
            this.sendNotification('DEL', false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private handleError(errResponse) {
        if (errResponse instanceof HttpErrorResponse) {
            this.errCode = new ErrCode(
                errResponse.status,
                errResponse.error.message || errResponse.message,
                errResponse.error.detail || errResponse.name
            );
        } else {
            this.errCode = ErrCode.fromException(errResponse);
        }
    }

    private clearError() {
        this.errCode = null;
    }

    toggleReplyInputBox() {
        if (!this.user) {
            this.replyAnonymousPromptEnabled = true;
        } else {
            this.replyInputBoxEnabled = !this.replyInputBoxEnabled;
        }
    }

    submitAnonymousForm() {
        this.anonymousCreationEvent.emit(this.anonymousForm.value);
        this.anonymousForm.reset();
        this.anonymousFormEnabled = false;
        this.commentInputBoxEnabled = true;
    }

    submitReplyAnonymousForm() {
        this.anonymousCreationEvent.emit({
            commentID: this.comment._id,
            ...this.replyAnonymousForm.value,
        });
        this.replyAnonymousForm.reset();
        this.replyAnonymousFormEnabled = false;
    }

    submitViewDetailAnonymousForm() {
        this.anonymousCreationEvent.emit(this.viewDetailAnonymousForm.value);
        this.viewDetailAnonymousForm.reset();
        this.viewDetailPromptEnabled = false;
        this.viewDetailAnonymousFormEnabled = false;
        this.detailEnabled = true;
        this.hideViewDetailAnonymousForm();
    }

    toggleDetail() {
        // if (!this.user) {
        //     this.viewDetailPromptEnabled = true;
        // }
        this.detailEnabled = !this.detailEnabled;
    }

    hideAnonymousForm() {
        this.anonymousFormEnabled = false;
        this.commentInputBoxEnabled = true;
        this.anonymousForm.reset();
    }

    hideViewDetailAnonymousForm() {
        this.viewDetailPromptEnabled = false;
        this.viewDetailAnonymousFormEnabled = false;
        this.viewDetailAnonymousForm.reset();
    }

    /**
     *send request to create email and event notification to the php api
     *if fails, do not throw errors since this feature is non-essential
     */
    private sendNotification(method: string, sendEmail = true) {
        if (!this.link.comment_notification) {
            this.log.info('Comment notification is disabled');
            return;
        }

        //create event for this user
        if (this.user.exists) {
            this.notify(this.user.id, method, false);
        }

        //send notification to link owner if this user is not link owner (to avoid double notifications)
        if (this.user.id !== this.ownerId) {
            this.notify(this.ownerId, method);
        }

        //send notification to parent author if this user is not parent comment author AND not link owner
        if (
            this.user.id !== this.comment.author_id &&
            this.comment.author_id != this.ownerId
        ) {
            this.notify(this.comment.author_id, method);
        }
    }

    toggleConfirm() {
        this.confirmedDelete = !this.confirmedDelete;
    }

    cancelEditMode() {
        this.editMode = false;
        this.commentEditionForm.reset();
        this.commentEditionForm.setValue(this.comment.content);
    }

    cancelConfirm() {
        this.confirmedDelete = false;
    }

    cancelNewComment() {
        this.postButtonEnabled = false;
        this.commentCreationForm.reset();
        this.commentCreationForm.setValue('');
    }

    hidePromptOptions() {
        this.viewDetailPromptEnabled = false;
        this.replyAnonymousPromptEnabled = false;
        this.anonymousPromptEnabled = false;
        this.postButtonEnabled = false;
        this.commentInputBoxEnabled = true;
    }

    hideReplyAnonymousForm() {
        this.replyAnonymousFormEnabled = false;
        this.replyAnonymousPromptEnabled = false;
        this.replyAnonymousForm.reset();
    }

    enableViewDetailAnonymousForm() {
        this.viewDetailAnonymousFormEnabled = true;
    }

    enableAnonymousPrompt() {
        if (!this.user) {
            this.anonymousPromptEnabled = true;
            this.commentInputBoxEnabled = false;
        }
    }

    enableAnonymousForm() {
        this.commentInputBoxEnabled = false;
        this.anonymousFormEnabled = true;
    }

    enableRepyAnonymousForm() {
        this.replyAnonymousFormEnabled = true;
        this.replyAnonymousPromptEnabled = false;
    }

    private notify(recipientId: number, method: string, sendEmail = true) {
        this.api.execute('notifycomment', {
            publink_id: this.publinkId,
            link_owner_id: this.ownerId,
            recipient_id: recipientId,
            display_name: this.user.exists
                ? this.user.display_name
                : this.base64.encode('An anonymous user'),
            link_name: this.base64.encode(this.item.name),
            link_url: this.base64.encode(window.location.href),
            method: method,
            send_email: sendEmail,
        });
    }

    validContent(value: string | undefined) {
        return value != undefined && value.trim().length !== 0;
    }

    private getLinkKey() {
        return this.route.snapshot.params['key'] || this.route.snapshot.fragment;
    }

     /**
     * this function updates css variables based on changes of this component's attributes
     */
    @HostBinding('attr.style')
    public get customColorsCss(): any {
        return this.sanitizer
            .bypassSecurityTrustStyle(`--buttonPrimaryColor: ${this.buttonPrimaryColor};
                                                        --buttonTextColor: ${this.buttonTextColor};
                                                        --linkTextColor: ${this.linkTextColor};
                                                    `);
    }
}
