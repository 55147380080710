import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../core/notifications.service';
import { FeedbackService } from '../feedback.service';
import { UserService } from '../../core/user.service';

@Component({
    selector: 'sync-notifications',
    templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
    public notifications: any;
    public feedbackView: any;
    public featureBanner = false;

    constructor(
        private feedbackService: FeedbackService,
        private notificationsService: NotificationsService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.notifications = this.notificationsService.settings;
        const plantype_id = this.userService.get('plantype_id');
        this.featureBanner = plantype_id && plantype_id > 1 && localStorage.getItem('collaborativeLinks') == 'true' ? true : false;
        this.feedbackView = this.feedbackService.view;
        this.notificationsService.startNotificationLoop();
    }

    public restartNotifications() {
        return this.notificationsService.startNotificationLoop();
    }

    public closeFeedback(key?: string) {
        if (key == 'collaborativeLinks') {
            this.featureBanner = false;
            localStorage.removeItem('collaborativeLinks');
            return;
        } // Close specific notification
        return this.feedbackService.hideFeedback();
    }
}
