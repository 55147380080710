import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Subject, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ApiService } from '../core/api.service';
import { Base64Service } from '../core/base64.service';
import { DirtyOutService } from '../core/crypt/dirty-out.service';
import { SyncCryptService } from '../core/crypt/sync-crypt.service';
import { SyncDigestService } from '../core/crypt/sync-digest.service';
import { LoggerService } from '../core/logger.service';
import { SyncCookieService } from '../core/sync-cookie.service';
import { UrlService } from '../core/url.service';
import { UserService } from '../core/user.service';
import { LinkFileListService } from '../link-consume/services/link-file-list.service';
import { BaseApiInput, BlendEvent, DirtyOutEncResultSync, ErrCode } from '../shared/models';
import { FinishBackupApiInput } from '../shared/models/api/finishbackup-api.model';
import { TransferStatus } from './transfer.model';
import { BlendService } from '../shared/services/blend.service';
import * as crc32 from 'crc-32';
import { BrowserSupportService } from '../core/browser-support.service';
import { CryptEngine } from '../shared/models/crypt-engine.model';

enum TransferItemProgressType {
    CRYPT,
    NETWORK
}
export class FileUploader {
    /*
     * max size of the upload chunk.  A 100mb file is divided into CHUNKSIZE
     * bytes when transferred to MFS.
     * - MFS has a 300mb limit of chunk size.
     *
     * When tuning this value, consider the larger chunks are generally better
     * however, it also stresses the client computer more.
     */
    private readonly CHUNKSIZE = 1024 * 1024 * 100;
    /*
     * the maximum file size to allow to be read into memory.  If the file
     * is larger than this value, we will read the file in chunks into memory
     * else, we'll read the whole thing.
     */
    private readonly MAX_SIZE_READ_MEMORY = 1024 * 1024 * 256;

    private readonly MAX_RETRY_PER_UPLOAD_HOST = 2;

    /**
     * Max file sizes used in thumbnail creation.  Note HEIC have a smaller
     * size.  Value is in bytes
     */
    private readonly MAX_SIZE_IMG_THUMB = 1024 * 1024 * 20;
    private readonly MAX_SIZE_HEIC_THUMB = 1024 * 1024 * 5;

    private syncDigestService: SyncDigestService;
    private loggerService: LoggerService;
    private apiService: ApiService;
    private userService: UserService;
    private syncCryptService: SyncCryptService;
    private urlService: UrlService;
    private http: HttpClient;
    private dirtyOutService: DirtyOutService;
    private base64Service: Base64Service;
    private linkPathListService: LinkFileListService;
    private syncCookieService: SyncCookieService;
    private blendService: BlendService;
    private BrowserSupport: BrowserSupportService;

    constructor(private injector: Injector) {
        this.syncDigestService = this.injector.get(SyncDigestService);
        this.loggerService = this.injector.get(LoggerService);
        this.apiService = this.injector.get(ApiService);
        this.userService = this.injector.get(UserService);
        this.syncCryptService = this.injector.get(SyncCryptService);
        this.urlService = this.injector.get(UrlService);
        this.http = this.injector.get(HttpClient);
        this.dirtyOutService = this.injector.get(DirtyOutService);
        this.base64Service = this.injector.get(Base64Service);
        this.linkPathListService = this.injector.get(LinkFileListService);
        this.syncCookieService = this.injector.get(SyncCookieService);
        this.blendService = this.injector.get(BlendService);
        this.BrowserSupport = this.injector.get(BrowserSupportService);
    }

    public initSha1Digest(
        tItem: sync.ITransferItemUpload
    ): sync.ITransferItemUpload {
        tItem.sha1_digest = this.syncDigestService.init();
        return tItem;
    }

    /**
     * Uploads a given transfer Item.  The tItem is passed via reference
     * and modified within these methods.
     * @param tItem TransferItemUpload
     * @returns ITransferItemStats
     */
    public async uploadItem(
        tItem: sync.ITransferItemUpload
    ): Promise<sync.ITransferItemStats> {
        const startMs = window.performance.now();
        // Reset all values since each uploadItem
        tItem.sha1_digest = this.syncDigestService.init();
        tItem.encTime = 0;
        tItem.digestTime = 0;
        tItem.appendTime = 0;
        tItem.sendTime = 0;
        tItem.readTime = 0;
        let preUploadTime = 0;
        let uploadTime = 0;
        let finishUploadTime = 0;
        let startUpload = 0;
        let startFinishUpload = 0;

        if (tItem.status === TransferStatus.STATUS_UPLOAD_CANCELLED) {
            return Promise.reject({ errcode: TransferStatus.STATUS_UPLOAD_CANCELLED });
        }
        tItem.status = TransferStatus.STATUS_WORKING;
        const startPreUpload = window.performance.now();

        try {
            const item = await this.preUpload(tItem);
            preUploadTime = window.performance.now() - startPreUpload;
            startUpload = window.performance.now();
            const tItemUploaded = await this.getFile(item);
            this.loggerService.info(
                'Encryption time = ' + tItem.encTime + ' ms'
            );
            uploadTime = window.performance.now() - startUpload;

            /*
                unaccounted time is time spent doing the upload that is not
                already accounted for, usually that is just overhead
                within the code.  A high value here means there is an
                unoptimized piece of code running
            */
            const unaccountedTime = (
                uploadTime - tItem.sendTime - tItem.encTime -
                tItem.digestTime - tItem.appendTime - tItem.readTime
            ).toFixed(2);
            // prettier-ignore
            tItemUploaded.stats = [
                1,                          // version
                preUploadTime.toFixed(2),   // pathpreupload api call
                uploadTime.toFixed(2),      // total time to upload
                tItem.sendTime.toFixed(2),   // time on network
                tItem.encTime.toFixed(2),    // time encryption
                tItem.digestTime.toFixed(2), // time sha1 digest
                tItem.appendTime.toFixed(2), // time building payload
                tItem.readTime.toFixed(2),   // time reading fileobject
                unaccountedTime,            // unaccounted time
                tItemUploaded.filesize      // file size
            ].join(',');

            startFinishUpload = window.performance.now();
            if (tItemUploaded.linkID) {
                await this.finishUploadPublic(tItemUploaded);
            } else {
                await this.finishUpload(tItemUploaded);
            }

            finishUploadTime = window.performance.now() - startFinishUpload;
            const timeTaken = window.performance.now() - startMs;
            const bps = tItem.filesize / (timeTaken / 1000);
            // prettier-ignore

            this.blendService.track(BlendEvent.UPLOAD, {
                totalTimeTakenInSeconds: +(timeTaken / 1000).toFixed(2),
                preUploadInMiliseconds: +preUploadTime.toFixed(2),
                uploadInMiliseconds: +uploadTime.toFixed(2),
                sendTimeInMiliseconds: +tItem.sendTime.toFixed(2),
                encryptTimeInMiliseconds: +tItem.encTime.toFixed(2),
                digestTimeInMiliseconds: +tItem.digestTime.toFixed(2),
                appendTimeInMiliseconds: +tItem.appendTime.toFixed(2),
                readTimeInMiliseconds: +tItem.readTime.toFixed(2),
                finishUploadInMiliseconds: +finishUploadTime.toFixed(2),
                unaccountedInMiliseconds: +unaccountedTime,
                fileSizeInBytes: +tItem.filesize,
                speedInKBPS: +(bps / 1024).toFixed(2),
            });

            this.loggerService.info(
                [
                    ' Upload completed successfully for ',
                    tItem.fileobj.name, ': ',
                    (timeTaken / 1000).toFixed(2), 'seconds total, ',
                    ' pre-upload:', preUploadTime.toFixed(2), 'ms, ',
                    ' upload: ', uploadTime.toFixed(2), 'ms ',
                    ' sendTime: ', tItem.sendTime.toFixed(2), 'ms ',
                    ' encrypt time : ', tItem.encTime.toFixed(2), 'ms ',
                    ' digest time: ', tItem.digestTime.toFixed(2), 'ms ',
                    ' append time: ', tItem.appendTime.toFixed(2), 'ms ',
                    ' read time: ', tItem.readTime.toFixed(2), 'ms ',
                    ' finish-upload: ', finishUploadTime.toFixed(2), 'ms ',
                    ' unaccounted:', unaccountedTime, 'ms ',
                    ' file size: ', tItem.filesize, 'bytes ',
                    'at ', (bps / 1024).toFixed(2), ' kBps',
                ].join(' ')
            );
            tItem.progress_percent = 100;
            tItem.status = TransferStatus.STATUS_SUCCESS;
            tItem.sha1_digest = undefined;
            // console.log('UPLOAD SUCCESS ', tItem.filedata);
            return {
                size: tItem.filesize,
                sendTime: tItem.sendTime,
                encTime: tItem.encTime,
                elapsed: timeTaken,
                elapsedPreUpload: preUploadTime,
                elapsedUpload: uploadTime,
                elapsedFinishUpload: finishUploadTime,
                bps: bps,
            };
        } catch (data) {
            this.loggerService.error('AN ERROR OCCURRED UPLOADING!!');
            if (typeof data === 'object' && data.errors && data.errors.length) {
                tItem.status =
                    data.errors[0].error_code || TransferStatus.STATUS_ERROR;
                this.loggerService.error(data.errors[0].error_msg);
            } else if (data.errcode) {
                tItem.status = data.errcode;
            } else if (data.code) {
                tItem.status = data.code;
            } else {
                tItem.status = TransferStatus.STATUS_ERROR;
            }
            this.loggerService.error('TransferItem.status = ' + tItem.status);
            throw data;
        }
    }

    private async preUpload(
        tItem: sync.ITransferItemUpload
    ): Promise<sync.ITransferItemUpload> {
        if (tItem.linkID) {
            try {
                const data = await this.apiService.execute<any>(
                    'pathpreuploadpublic',
                    {
                        sync_pid: tItem.sync_pid,
                        enc_share_name: tItem.enc_share_name,
                        publink_id: tItem.linkID,
                    }
                );
                tItem.user_id = data.user_id;
                tItem.sync_id = data.sync_id;
                tItem.servtime = data.servtime;
                tItem.backup_id = data.backup_id;
                tItem.device_id = data.device_id;
                tItem.device_sig_b64 = data.device_sig_b64;
                return tItem;
            } catch (err) {
                this.loggerService.e(`Path preuploadpublic call failed for linkId ${tItem.linkID} sync_pid ${tItem.sync_pid}`, err);
                throw new ErrCode(7215);
            }
        } else {
            try {
                const data = await this.apiService.execute<any>(
                    'pathpreupload',
                    {
                        sync_pid: tItem.sync_pid,
                        enc_name: tItem.enc_name,
                    }
                );
                tItem.user_id = this.userService.get('uid');
                tItem.sync_id = data.sync_id;
                tItem.servtime = data.servtime;
                tItem.share_id = data.share_id;
                tItem.share_sequence = data.share_sequence;
                tItem.backup_id = data.backup_id;
                tItem.device_id = data.device_id;
                tItem.device_sig_b64 = data.device_sig_b64;
                tItem.enc_share_key = data.enc_share_key;
                tItem.share_key_id = data.share_key_id;
                if (!tItem.share_key) {
                    try {
                        const sharekey =
                            await this.syncCryptService.sharekeyDecrypt(
                                data.enc_share_key,
                                data.share_key_id
                            );
                        tItem.share_key = sharekey;
                        return tItem;
                    } catch (err) {
                        this.loggerService.e(`Upload failed, could not decrypt share key ${data.share_key_id}`, err);
                        throw err;
                    }
                } else {
                    return tItem;
                }
            } catch (err) {
                this.loggerService.e(`Path preupload call failed for sync_pid ${tItem.sync_pid}`, err);
                throw new ErrCode(7215);
            }
        }
    }

    /**
     * Wrapper function to get a file by using FileReader and reading in
     * chunks.  This calls function processPiece recursively until the
     * entire file is read, encrypted and uploaded
     *
     * getFileLarge will bypass loading the file at this point and instead
     * read in chunks via loadFileLarge.
     *
     * This optimization exists since small files fit in memory so we can
     * decrease the amount of times FileReader needs to get instantiated.
     * @param  {ITransferItemUpload} tItem The current TransferItem
     * @return {Promise}      Resolved/rejected when file is completely uploaded
     */
    private async getFile(tItem: sync.ITransferItemUpload): Promise<sync.ITransferItemUpload> {
        if (tItem.filesize >= this.MAX_SIZE_READ_MEMORY) {
            return await this.getFileLarge(tItem).toPromise();
        } else {
            return await this.getFileSmall(tItem).toPromise();
        }
    }
    private getFileLarge(tItem: sync.ITransferItemUpload): Subject<sync.ITransferItemUpload> {
        this.loggerService.info(
            'FileUploader.getFileLarge(' + tItem.filesize + ')'
        );
        const subject = new Subject<sync.ITransferItemUpload>(),
            payload: sync.IUploadPayload = {
                pieces: [],
                datalen: 0,
                data: [],
                offset: 0,
                chunklen: 0,
                enc_offset: 0,
            };
        this.processPiece(tItem, payload, subject);
        return subject;
    }

    private getFileSmall(
        tItem: sync.ITransferItemUpload
    ): Subject<sync.ITransferItemUpload> {
        this.loggerService.info(
            'FileUploader.getFileSmall(' + tItem.filesize + ')'
        );
        const subject = new Subject<sync.ITransferItemUpload>(),
            payload: sync.IUploadPayload = {
                pieces: [],
                datalen: 0,
                data: [],
                offset: 0,
                chunklen: 0,
                enc_offset: 0,
            };
        const start = window.performance.now();
        const Reader = new FileReader();
        Reader.onload = async (evt: any) => {
            const end = window.performance.now();
            tItem.readTime = tItem.readTime + (end - start);
            this.loggerService.info(
                'FileReader resolved file ' + (end - start) + ' ms'
            );
            tItem.fileobjdata = evt.target.result as ArrayBuffer;
            if (
                tItem.filesize != tItem.fileobj.size ||
                (tItem.fileobj.lastModified !== undefined &&
                    tItem.filedate !=
                    new Date(tItem.fileobj.lastModified).getTime())
            ) {
                this.loggerService.error(
                    'Source file has changed since being queued'
                );
                subject.error({ errcode: 7012 });
            }
            this.processPiece(tItem, payload, subject);
        };
        Reader.onerror = (evt: any) => {
            if (evt.target.error) {
                this.loggerService.error(evt.target.error.toString());
            }
            this.loggerService.error('Error reading input file');
            subject.error({ errcode: 7010 });
        };
        Reader.readAsArrayBuffer(tItem.fileobj);
        return subject;
    }

    private async processPiece(
        tItem: sync.ITransferItemUpload,
        payload: sync.IUploadPayload,
        subject: Subject<sync.ITransferItemUpload>
    ): Promise<void | Subject<sync.ITransferItemUpload>> {
        if (tItem.status === TransferStatus.STATUS_UPLOAD_CANCELLED) {
            subject.error({ errcode: TransferStatus.STATUS_UPLOAD_CANCELLED });
            return subject;
        }
        const queue = tItem.chunkqueue.shift();
        if (payload.pieces.length === 0) {
            payload.offset = queue.offset;
            payload.enc_offset = queue.enc_offset;
        }
        tItem.status = TransferStatus.STATUS_ENC_UPLOAD;
        let buffer: ArrayBuffer;
        try {
            buffer = await this.loadPiece(tItem, queue.offset, queue.chunklen);
        } catch (ex) {
            this.loggerService.e('Failed to load piece', ex);
            return subject.error(new ErrCode(7216));
        }

        const bytearray = this.syncCryptService.arraybufferToBytes(buffer);

        const beginDigest = window.performance.now();
        tItem.sha1_digest = this.syncDigestService.update(
            tItem.sha1_digest,
            bytearray
        );
        tItem.digestTime += window.performance.now() - beginDigest;

        const beginFileDataEnc = window.performance.now();
        let encByteArray: ArrayLike<number>;
        try {
            encByteArray = await this.syncCryptService.filedataEncrypt(
                bytearray,
                tItem.data_key,
                queue.offset
            );
            this.updateProgress(TransferItemProgressType.CRYPT, tItem, encByteArray.length);
        } catch (ex) {
            throw new ErrCode(2050);
        }
        tItem.encTime += window.performance.now() - beginFileDataEnc;

        // must .push() because encByteArray could be instances of Uint8Array
        payload.pieces.push(encByteArray);
        payload.datalen += encByteArray.length;
        payload.chunklen += queue.chunklen;
        // console.log ((end - start) + ' ms enc time added for this chunk');
        // this.encTime = this.encTime + (end - start);

        if (payload.chunklen >= this.CHUNKSIZE || !tItem.chunkqueue.length) {
            if (tItem.status === TransferStatus.STATUS_UPLOAD_CANCELLED) {
                subject.error({
                    errcode: TransferStatus.STATUS_UPLOAD_CANCELLED,
                });
                return subject;
            }
            this.loggerService.warn('Network transfer started');
            const startAppend = window.performance.now();
            payload.data = this.syncCryptService.filedataAppend(
                payload.pieces,
                payload.datalen
            );
            tItem.appendTime += window.performance.now() - startAppend;

            this.loggerService.info(
                `Payload contains ${payload.data.length} chunks`
            );

            tItem.status = TransferStatus.STATUS_UPLOAD; // STATUS_UPLOAD

            const _s = window.performance.now();
            this.sendChunk(tItem, payload).subscribe(
                (event: any) => {
                    if (event.type === HttpEventType.Response) {
                        const data = event.body;
                        const _e = window.performance.now();
                        tItem.sendTime = tItem.sendTime + (_e - _s);
                        // console.log((_e - _s) + ' ms for send now ')
                        tItem.blob_id = data.chunks[0].blob_id;
                        tItem.cachekey = data.chunks[0].cachekey;
                        payload.pieces = [];
                        payload.datalen = 0;
                        payload.data = [];
                        payload.offset = 0;
                        payload.chunklen = 0;
                        payload.enc_offset = 0;
                        if (tItem.chunkqueue.length) {
                            this.processPiece(tItem, payload, subject);
                        } else {
                            subject.next(tItem);
                            subject.complete();
                        }
                    }
                    if (event.type === HttpEventType.UploadProgress) {
                        this.updateProgress(TransferItemProgressType.NETWORK, tItem, payload.offset + event.loaded);
                        // let bytes = payload.offset + event.loaded;
                        // let p =
                        //     Math.round((bytes / tItem.filesize) * 100 * 100) /
                        //     100 /
                        //     2;
                        // tItem.progress_percent = p >= 100 ? 99 : p;
                        // tItem.bytes_sent =
                        //     bytes > tItem.filesize ? tItem.filesize : bytes;
                    }
                },
                (err) => {
                    subject.error(err);
                }
            );
        } else {
            if (tItem.chunkqueue.length) {
                this.processPiece(tItem, payload, subject);
            } else {
                subject.next(tItem);
            }
        }
        return subject;
    }

    private updateProgress(type: TransferItemProgressType, tItem: sync.ITransferItemUpload, bytes: number) {
        switch (type) {
            case TransferItemProgressType.CRYPT:
                tItem.progress_crypt += bytes; break;
            case TransferItemProgressType.NETWORK:
                tItem.progress_network = bytes; break;
        }
        const progressBytes = tItem.progress_crypt + tItem.progress_network;
        // uncomment to debug the progress bar
        // console.log(['updateProgress: ',
        //     'bytes added: ' , bytes,
        //     'bytes_progress: ', progressBytes,
        //     'total_bytes: ', (tItem.filesize * 2),
        //     'math.round: ' , Math.round(progressBytes / (tItem.filesize * 2) * 100),
        // ].join(' '))
        const pct = Math.round(progressBytes / (tItem.filesize * 2) * 100);
        tItem.progress_percent = pct >= 100 ? 99 : pct;
    }

    /**
     * Loads individual chunks.  For large files we return a portion of the file
     * via the FileReader, but if it's smaller we can just slice off the
     * arraybuffer
     * @param tItem TransferItem
     * @param offset byte offset
     * @param len  length to read
     * @returns ArrayBuffer
     */
    private loadPiece(
        tItem: sync.ITransferItemUpload,
        offset: number,
        len: number
    ): Promise<ArrayBuffer> {
        if (tItem.filesize >= this.MAX_SIZE_READ_MEMORY) {
            return this.loadPieceLarge(tItem, offset, len);
        } else {
            return this.loadPieceSmall(tItem, offset, len);
        }
    }

    private loadPieceLarge(
        tItem: sync.ITransferItemUpload,
        offset: number,
        len: number
    ): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const stop = len + offset || tItem.fileobj.size - 1;
            const Reader = new FileReader();
            const start = window.performance.now();
            Reader.onload = (evt: any) => {
                const end = window.performance.now();
                tItem.readTime = tItem.readTime + (end - start);
                const bytesRead = (evt.target.result as ArrayBuffer).byteLength;
                if (bytesRead !== len) {
                    this.loggerService.error(
                        [
                            'Attempting to read the file returned ',
                            bytesRead,
                            ' when we asked for ',
                            len,
                        ].join('')
                    );
                    reject({ errcode: 7010 });
                } else {
                    // this.Logger.info('FileReader resolved file ' + bytesRead);
                    resolve(evt.target.result as ArrayBuffer);
                }
            };
            Reader.onerror = (evt: any) => {
                this.loggerService.error('Error reading file');
                reject({ errcode: 7010 });
            };
            Reader.readAsArrayBuffer(tItem.fileobj.slice(offset, stop));
        });
    }
    private loadPieceSmall(
        tItem: sync.ITransferItemUpload,
        offset: number,
        len: number
    ): Promise<ArrayBuffer> {
        const stop = len + offset || tItem.fileobj.size - 1;

        return Promise.resolve(tItem.fileobjdata.slice(offset, stop));
    }

    // Utility function to calculate CRC32 and return it as a hexadecimal string
    private getCrc32Hex(payload: ArrayLike<number>): string {
        // legacy engine uses sjcl lib
        // sjcl doesn't support typed array, so don't send crc32 if legacy
        if (this.BrowserSupport.getEncryptionEngine() === CryptEngine.LEGACY) {
            return 'DeadBeef';
        }
        // Ensure payload is converted to Uint8Array
        const uint8Array = new Uint8Array(payload);  // Convert to Uint8Array
        const crc32result = crc32.buf(uint8Array);  // Compute CRC32
        const unsignedCrc32Result = crc32result >>> 0;  // Ensure it's an unsigned 32-bit integer
        return unsignedCrc32Result.toString(16).padStart(8, '0');  // Convert to hex string, padded to 8 digits
    }

    private sendChunk(
        tItem: sync.ITransferItemUpload,
        payload: sync.IUploadPayload
    ): Subject<any> {
        const encChunkLen =
            payload.chunklen +
            Math.ceil(
                payload.chunklen / this.syncCryptService.GCM_PAYLOAD_SIZE
            ) *
            36;

        const crc32Hex = this.getCrc32Hex(payload.data);  // Get CRC32 as a hexadecimal string

        return this.uploadMultiChunk(
            this.syncCryptService.prepareDataSend(payload.data),
            {
                cachekey: tItem.cachekey || '',
                enc_offset_byte: payload.enc_offset,
                enc_chunk_size_bytes: encChunkLen, // account for gcm tag
                payload_crc32: crc32Hex,
                payload_len: encChunkLen, // account for gcm tag

                // authentication data
                device_sig_b64: tItem.device_sig_b64,
                servtime: tItem.servtime,
                backup_id: tItem.backup_id,
                user_id: tItem.user_id || this.userService.get('uid'),
                device_id: tItem.device_id,

                // modified_epochtime: new Date(tItem.filedate).getTime(),
                // size_bytes: tItem.filesize,
                // offset_byte: payload.offset,
                // length_bytes: payload.chunklen,
                // blob_id: tItem.blob_id
            },
            0
        );
    }

    private uploadMultiChunk(
        payload: any,
        json: any,
        attempt: number = 0,
        sub?: Subject<any>
    ): Subject<any> {
        const subject = sub || new Subject<any>();
        const formData = new FormData();
        const retryAttempt = attempt;
        let blob = new Blob([payload]);

        const startTime = window.performance.now();

        formData.append(
            'json',
            JSON.stringify({
                device_sig_b64: json.device_sig_b64,
                // used for auth
                backup_id: json.backup_id,
                device_id: json.device_id,
                user_id: json.user_id,
                servtime: json.servtime,
                chunks: [
                    {
                        object_type: 'btFILE',
                        cachekey: json.cachekey || '',
                        payload_crc32: json.payload_crc32,
                        enc_offset_byte: json.enc_offset_byte,
                        payload_len: json.payload_len,
                        enc_chunk_size_bytes: json.enc_chunk_size_bytes,
                    },
                ],
            })
        );
        formData.append('payload0', blob, 'payload0.data');

        if (blob.size !== json.payload_len) {
            this.loggerService.error(`Upload size mismatch. ${blob.size} != ${json.payload_len}`);
            subject.error({ code: 7011 });
            return subject;
        }

        const hostIndex = retryAttempt % this.urlService.uploadhosts.length;
        const url = this.urlService.mkUpload(
            this.urlService.uploadhosts[hostIndex],
            'uploadmultichunk'
        );

        const httpOptions = new Headers();
        httpOptions.append('Content-Type', undefined);

        this.http
            .post(url, formData, { reportProgress: true, observe: 'events' })
            .pipe(
                timeout(100000000),
                catchError((err) => {
                    if (err instanceof TimeoutError) {
                        this.loggerService.error('upload timed out');
                        this.loggerService.error(
                            `INPUT: ${JSON.stringify(json)}`
                        );
                        this.loggerService.error(
                            'Upload timed out on first chunk'
                        );
                        return throwError({ errcode: 7005 });
                    } else {
                        blob = null;
                        this.loggerService.error(status + ' ' + url);
                        this.loggerService.error(
                            'uploadChunk xhr onabort ' + JSON.stringify(err)
                        );
                        return throwError({ errcode: 7000 });
                    }
                })
            )
            .subscribe(
                (response: any) => {
                    const endTime = window.performance.now();
                    this.loggerService.info(
                        'Upload transfer took ' + (endTime - startTime) + ' ms'
                    );
                    const jsonData: any = response.body;
                    // errcode 2 means the API thinks I should retry
                    if (
                        jsonData &&
                        jsonData.success == 0 &&
                        jsonData.errcode == 2
                    ) {
                        if (jsonData.errors) {
                            this.loggerService.error(
                                JSON.stringify(jsonData.errors)
                            );
                        }
                        this.loggerService.warn(
                            'Error received errcode 2, retrying'
                        );
                        this.retryUpload(payload, json, retryAttempt, subject);
                        return;
                    } else if (
                        jsonData &&
                        jsonData.success === 0 &&
                        !jsonData.errcode
                    ) {
                        this.loggerService.error(response.status + ' ' + url);
                        if (jsonData.errors) {
                            this.loggerService.error(
                                JSON.stringify(jsonData.errors)
                            );
                        }
                        this.loggerService.error(
                            `INPUT: ${JSON.stringify(json)}`
                        );
                        this.loggerService.error(
                            `OUTPUT: ${JSON.stringify(response)}`
                        );
                        this.loggerService.error(
                            'uploadChunk success == 0, errcode = 7020 in upload chunk'
                        );
                        return subject.error({ errcode: 7020 });
                    } else if (jsonData && jsonData.success == 0) {
                        this.loggerService.error(response.status + ' ' + url);
                        this.loggerService.error(
                            `INPUT: ${JSON.stringify(json)}`
                        );
                        this.loggerService.error(`OUTPUT: ${response}`);
                        if (jsonData.errors) {
                            this.loggerService.error(
                                JSON.stringify(jsonData.errors)
                            );
                        }
                        this.loggerService.error(
                            'uploadChunk success == 0 error in upload chunk'
                        );
                        return subject.error({ errcode: 7020 });
                    } else {
                        this.loggerService.info(
                            status + ' ' + url + ' success'
                        );
                        if (jsonData) {
                            if (
                                jsonData.success == 1 &&
                                jsonData.chunks.length == 1
                            ) {
                                subject.next(jsonData.chunks[0]);
                            } else {
                                this.loggerService.error(
                                    'An unexpected result was received during upload'
                                );
                                this.loggerService.error(
                                    `INPUT: ${JSON.stringify(json)}`
                                );
                                this.loggerService.error(
                                    `OUTPUT: ${JSON.stringify(jsonData)}`
                                );
                                subject.error({ errcode: 7020 });
                            }
                        }
                    }
                    subject.next(response);
                },
                (resp) => {
                    this.loggerService.error(
                        `${resp.status} ${resp.xhrStatus} ${url}`
                    );
                    console.log(resp);
                    if (resp.status === 0 || !resp) {
                        this.loggerService.error(
                            'uploadChunk status = 0 || !response ' + url
                        );
                        this.loggerService.error(
                            `INPUT: ${JSON.stringify(json)}`
                        );
                        this.loggerService.error(
                            `OUTPUT: ${JSON.stringify(resp)}`
                        );
                        subject.error({ errcode: 7023 });
                        return;
                    } else if (resp.status === -1 || resp.status >= 500) {
                        this.retryUpload(payload, json, retryAttempt, subject);
                        return;
                    } else {
                        this.loggerService.error('An unknown error occurred');
                        this.loggerService.error(
                            `INPUT: ${JSON.stringify(json)}`
                        );
                        this.loggerService.error(
                            `OUTPUT: ${JSON.stringify(resp)}`
                        );
                        subject.error({ errcode: 7000 });
                    }
                }
            );
        return subject;
    }

    private retryUpload(payload: any, json: any, retryAttempt: number, subject: Subject<any>): void {
        const nextRetryAttempt = retryAttempt + 1;
        const totalRetries = this.urlService.uploadhosts.length * this.MAX_RETRY_PER_UPLOAD_HOST;

        if (nextRetryAttempt < totalRetries) {
            this.loggerService.warn(`Retrying upload with host ${this.urlService.uploadhosts[nextRetryAttempt % this.urlService.uploadhosts.length]}`);
            setTimeout(() => {
                this.uploadMultiChunk(payload, json, nextRetryAttempt, subject);
            }, 300 * (nextRetryAttempt + 1));
        } else {
            this.loggerService.error(`Failed all retry attempts. INPUT: ${JSON.stringify(json)}`);
            subject.error({ code: 7000 });
        }
    }

    /**
        Send job to setjob api. The job would be inserted in resque for thumbnail generation.
     */
    private async sendThumbnailJob(
        compat_url: string,
        type: string,
        enc_datakey: string,
        cachekey: string,
        user_id: number,
        device_id: number
    ): Promise<any> {
        return this.apiService
            .execute('setjob', {
                job_name: 'publink.ThumbnailGeneration',
                compat_url: compat_url,
                type: type,
                enc_datakey: enc_datakey,
                cachekey: cachekey,
                user_id: user_id,
                device_id: device_id,
            })
            .then((response) => {
                return response;
            });
    }

    private async getThumbnail(tItem: sync.ITransferItemUpload) {
        let thumbnailProcessing = false;
        try {
            let mfsUrl: string,
                host = window.location.href;
            if (window.location.search) {
                host = host.replace(window.location.search, '');
            }

            const rsaDatakey = await this.syncCryptService.compatDatakeyEncrypt(
                this.syncCryptService.bytesToB64(tItem.data_key)
            );
            const encHost = await this.syncCryptService.compatDatakeyEncrypt(
                this.base64Service.encode(host)
            );

            const pathListSubscription = this.linkPathListService
                .getSubscription()
                .subscribe(async (data) => {
                    if (data.loaded && data.sorted) {
                        const uploadedItem = data.pathlist.find((val) => {
                            if (val.enc_share_name === tItem.enc_share_name) {
                                return true;
                            }
                        });

                        if (
                            uploadedItem !== undefined &&
                            !thumbnailProcessing
                        ) {
                            thumbnailProcessing = true;
                            tItem.link_owner_id = uploadedItem.link_owner_id;
                            tItem.sync_id = uploadedItem.sync_id;
                            let params =
                                this.urlService.getDownloadPubLinkParams(
                                    tItem,
                                    rsaDatakey,
                                    encHost
                                );
                            const result = await this.apiService
                                .execute<any>('linksignrequest', {
                                    req: params,
                                })
                                .catch((err) => {
                                    throw err;
                                });
                            params = result.response;

                            this.syncCookieService.deleteCookie('passwordlock');
                            this.syncCookieService.deleteCookie(
                                'thumbnail_sign'
                            );

                            if (uploadedItem.linkpasswordlock) {
                                this.syncCookieService.setDownloadPubLink(
                                    uploadedItem.linkpasswordlock
                                );
                                mfsUrl =
                                    this.urlService.internalDownloadPubLinkPassword(
                                        tItem,
                                        params
                                    );
                                mfsUrl = window.location.origin + mfsUrl;
                            } else {
                                mfsUrl =
                                    await this.urlService.internalDownloadPubLink(
                                        tItem,
                                        params
                                    );
                            }
                            const sendThumbnailJobResponse =
                                await this.sendThumbnailJob(
                                    this.base64Service.encode(mfsUrl),
                                    tItem.filedata.mimetype,
                                    this.base64Service.encode(
                                        rsaDatakey.replace(/=/g, '')
                                    ),
                                    tItem.cachekey,
                                    parseInt(uploadedItem.user_id, 10),
                                    data.cwd.event_device_id
                                );

                            if (pathListSubscription) {
                                pathListSubscription.unsubscribe();
                            }
                        }
                    }
                });
        } catch (err) {
            this.loggerService.error(
                `Error in getThumbnail for public uploads:: ${err}`
            );
        }
    }

    private async finishUploadPublic(
        tItem: sync.ITransferItemUpload
    ): Promise<void> {
        const data_key = tItem.data_key,
            filedigest = this.syncDigestService.finish(tItem.sha1_digest),
            filedigestPromise = this.syncCryptService.filedigestEncrypt(
                filedigest,
                tItem.data_key
            ),
            datakeyPromise = this.syncCryptService.datakeyEncrypt(
                this.syncCryptService.bytesToB64(data_key),
                tItem.share_key
            );

        if (this.allowThumbnails(tItem)) {
            this.getThumbnail(tItem);
        }

        return Promise.all([filedigestPromise, datakeyPromise]).then(
            async (result) => {
                const keys: DirtyOutEncResultSync[] = [
                    {
                        sync_id: tItem.sync_id || tItem.sync_pid,
                        sharekey_id: tItem.share_key_id,
                        enc_share_name: tItem.enc_share_name,
                        servtime: tItem.servtime,
                        enc_data_key: result[1],
                    },
                ];
                await this.finishUploadFiles({
                    cachekey: tItem.cachekey,
                    backup_id: tItem.backup_id,
                    publink_id: tItem.linkID,
                    blob_id: tItem.blob_id,
                    size_bytes: tItem.filesize,
                    piece_size_bytes: tItem.filesize,
                    file_digest: result[0],
                    piece_digest: result[0],
                    enc_piece_size_bytes: tItem.filesize + 36 * tItem.chunkamt, // account for gcm tag
                    share_id: tItem.share_id,
                    share_sequence: tItem.share_sequence,
                    keys: keys,
                    sync_pid: tItem.sync_pid,
                    user_id: tItem.user_id,
                    servtime: tItem.servtime,
                    device_id: tItem.device_id,
                    device_sig_b64: tItem.device_sig_b64,
                    enc_name: tItem.enc_name,
                    enc_piece_digest: 'DeadBeef',
                    payload_crc32: 'DeadBeef',
                    stats: tItem.stats
                } as FinishBackupApiInput);
                tItem.sha1_digest = undefined;
                return;
            }
        );
    }

    private finishUploadFiles(json: FinishBackupApiInput) {
        let xhr = new XMLHttpRequest();
        let formData = new FormData();
        formData.append('json', JSON.stringify(json));
        return new Promise<any>((resolve, reject) => {
            xhr.open(
                'POST',
                this.urlService.mkUpload(
                    this.urlService.uploadhosts[0],
                    'webfinishbackup'
                ),
                true
            );
            // xhr.setRequestHeader('X-SYNC-UPLOAD-METRICS', '');
            xhr.ontimeout = (evt) => {
                xhr = null;
                formData = null;
                this.loggerService.error('Finish upload timed out');
                return reject({ errcode: 7005 });
            };
            xhr.onload = () => {
                const status = xhr.status === 1223 ? 204 : xhr.status;
                const response = xhr.response;
                // const statustext = xhr.statusText || '';
                this.loggerService.info(status + ' is the status');
                xhr = null;
                formData = null;
                if (status === 0 || !response) {
                    this.loggerService.error(
                        'webfinishbackup An unknown error occurred, no response received and status = 0 during finish backup'
                    );
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    this.loggerService.error(`OUTPUT: ${response}`);
                    reject({ errcode: 7023 });
                }
                if (status === -1 || status >= 500) {
                    this.loggerService.error('finishUpload() status ' + status);
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    this.loggerService.error(`OUTPUT: ${response}`);
                    reject({ errcode: 7020 });
                }
                let jsonData: any = {};
                try {
                    jsonData = JSON.parse(response);
                } catch (ex) {
                    this.loggerService.error(
                        'Exception parsing finishUpload() response ' +
                        ex.toString()
                    );
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    this.loggerService.error(`OUTPUT: ${response}`);
                    reject({ errcode: 7020 });
                }

                if (
                    parseInt(jsonData.success, 10) === 0 &&
                    jsonData.errcode === 2
                ) {
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    this.loggerService.error(`OUTPUT: ${response}`);
                    reject({ errcode: 7000 });
                } else if (
                    parseInt(jsonData.success, 10) === 0 &&
                    !jsonData.errcode
                ) {
                    this.loggerService.error(
                        'webfinishbackup success == 0, errcode = 7022'
                    );
                    this.loggerService.error(`OUTPUT: ${response}`);
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    return reject({ errcode: 7022 });
                } else if (jsonData.success == 1) {
                    return resolve(jsonData);
                } else {
                    this.loggerService.error(
                        'webfinishbackup case from response output'
                    );
                    this.loggerService.error(`OUTPUT: ${response}`);
                    this.loggerService.error(`INPUT: ${JSON.stringify(json)}`);
                    return reject({ errcode: 7024 });
                }
            };
            const reqError = (evt: Event) => {
                xhr = null;
                formData = null;
                reject({ errcode: 7000 });
            };
            xhr.onerror = reqError;
            xhr.onabort = reqError;


            xhr.send(formData);
        });
    }

    public async buildFinishBackup(
        tItem: sync.ITransferItemUpload
    ): Promise<FinishBackupApiInput> {
        const keysArray = await this.dirtyOutService.executeForSync(
            tItem.sync_id || tItem.sync_pid,
            tItem.filename,
            this.syncCryptService.bytesToB64(tItem.data_key)
        );

        const digest = this.syncDigestService.finish(tItem.sha1_digest);
        const linkid = tItem.linkID || undefined;
        const encFileDigest = await this.syncCryptService.filedigestEncrypt(
            digest,
            tItem.data_key
        );

        const finishBackupPayload: FinishBackupApiInput = {
            cachekey: tItem.cachekey,
            publink_id: linkid,
            blob_id: tItem.blob_id,
            size_bytes: tItem.filesize,
            piece_size_bytes: tItem.filesize,
            file_digest: encFileDigest,
            piece_digest: encFileDigest,
            enc_piece_size_bytes: tItem.filesize + 36 * tItem.chunkamt, // account for gcm tag
            share_id: tItem.share_id,
            share_sequence: tItem.share_sequence,
            keys: keysArray,
            sync_pid: tItem.sync_pid,
            // user_id: tItem.user_id || this.User.get('uid'),
            // servtime: tItem.servtime,
            // device_id: tItem.device_id,
            // device_sig_b64: tItem.device_sig_b64,
            enc_name: tItem.enc_name,
            stats: tItem.stats
        };
        if (this.allowThumbnails(tItem)) {
            this.syncCookieService.deleteCookie('thumbnail_sign');
            const ts = Date.now();

            const rsaDatakey = await this.syncCryptService.compatDatakeyEncrypt(
                this.syncCryptService.bytesToB64(tItem.data_key)
            );

            let mfsUrl = await this.urlService.internalDownloadMfsUrl(
                tItem,
                rsaDatakey,
                ts
            );
            const input = new BaseApiInput();
            input.servtime = ts;
            const defaults = await this.syncCryptService
                .signApiReq(input)
                .catch((err) => {
                    throw err;
                });
            mfsUrl =
                window.location.origin +
                mfsUrl +
                '&access_token=' +
                defaults.access_token;
            finishBackupPayload.compat_url = this.base64Service.encode(mfsUrl);
            finishBackupPayload.type = tItem.filedata.mimetype;
            finishBackupPayload.enc_datakey = this.base64Service.encode(
                rsaDatakey.replace(/=/g, '')
            );
            this.syncCookieService.setThumbnailSignature(defaults.signature);
        }
        return finishBackupPayload;
    }

    private async finishUpload(tItem: sync.ITransferItemUpload): Promise<any> {
        const finishBackupPayload: FinishBackupApiInput =
            await this.buildFinishBackup(tItem);
        await this.apiService.execute('webfinishbackup', finishBackupPayload);

        tItem.sha1_digest = undefined;
        return;
    }

    private allowThumbnails(tItem: sync.ITransferItemUpload): boolean {
        let allowThumbs = false;
        if (
            this.isImage(tItem) &&
            tItem.fileobj.type === 'image/heic' &&
            tItem.fileobj.size <= this.MAX_SIZE_HEIC_THUMB
        ) {
            allowThumbs = true;
        } else if (
            this.isImage(tItem) &&
            tItem.fileobj.size <= this.MAX_SIZE_IMG_THUMB
        ) {
            allowThumbs = true;
        }

        return allowThumbs;
    }

    private isImage(item: sync.ITransferItemUpload): boolean {
        const name = item.filename;
        const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        let canPreview = false;
        switch (ext) {
            case 'jpg':
            case 'png':
            case 'jpeg':
            case 'gif':
            case 'heic':
            case 'webp':
            case 'avif':
            case 'tiff':
                canPreview = true;
                break;
            default:
                canPreview = false;
        }
        return canPreview && ext != name.toLowerCase();
    }
}
