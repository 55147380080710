
import { Action } from '@ngrx/store';

import { ErrCode, MultiAdminUserData } from '../shared/models';
// import { Sort } from '@angular/material';
export const USER_LIST_SUCCESS = '[UserList] Success';
export const USER_LIST_SELECT_ALL = '[UserList] Select all';
export const USER_LIST_SELECT_ALL_ACTIVE = '[UserList] Select all active';
export const USER_LIST_SELECT_ALL_INVITED = '[UserList] Select all invited';
export const USER_LIST_SELECT_TOGGLE = '[UserList] Select toggle';
export class UserListSuccessAction implements Action {
    readonly type = USER_LIST_SUCCESS;
    constructor(public payload: MultiAdminUserData[]) {}
}

export class UserListSelectAllAction implements Action {
    readonly type = USER_LIST_SELECT_ALL;
    constructor(public payload: any) {}
}
export class UserListSelectAllActiveAction implements Action {
    readonly type = USER_LIST_SELECT_ALL_ACTIVE;
}
export class UserListSelectAllInvitedAction implements Action {
    readonly type = USER_LIST_SELECT_ALL_INVITED;
}

export class UserListSelectToggleAction implements Action {
    readonly type = USER_LIST_SELECT_TOGGLE;
    constructor(public payload: MultiAdminUserData) {}
}

export type All = UserListSuccessAction
    | UserListSelectAllAction
    | UserListSelectToggleAction
    | UserListSelectAllActiveAction
    | UserListSelectAllInvitedAction;
