import { Injectable, Injector } from '@angular/core';
import { LoggerService } from '../logger.service';
import { StorageFilesystemService } from './storage-filesystem.service';
import { StorageMemoryService } from './storage-memory.service';
import { BrowserSupportService } from '../browser-support.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _Storage: sync.IStorageMethod;
    public maxsize: number;


    constructor(
        private browser: BrowserSupportService,
        private log: LoggerService,
        private storefs: StorageFilesystemService,
        private storemem: StorageMemoryService
    ) { }

    public async prepare() {
        this.log.info('Preparing storage service');
        if (this._Storage) {
            this.log.info('Storage is set');
            return;
        }
        const method = this.browser.getFileStorageMethod();
        switch (method) {
            case 'filesystem':
                try {
                    this.log.info('TRANSER: testing filesystem');
                    await this.storefs.test();
                    this.log.info(`TRANSFER STORAGE: 'filesystem'`);
                    this._Storage = this.storefs;
                    this.maxsize = this.storefs.maxsize;
                } catch (err) {
                    this.log.info('TRANSFER STORAGE: memory-filesystemfail');
                    this.log.warn('Detected chrome incognito, falling back to memory');
                    this.log.warn(err);
                    this._Storage = this.storemem;
                    this.maxsize = this.storemem.maxsize;
                }
                break;
            case 'memory':
            default:
                this._Storage = this.storemem;
                this.maxsize = this.storemem.maxsize;
                this.log.info(`TRANSFER STORAGE: 'memory'`);
                break;
        }
        this.log.info('TRANSFER SAVE: ' + this.browser.getFileSaveMethod());
        this.log.info('storage init completed');
    }

    public getMemoryStorage(): sync.IStorageMethod {
        return this.storemem;
    }
    public getDefaultStorage(): sync.IStorageMethod {
        return this._Storage;
    }
}
