import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sync-dialog-edit-link-auth',
  templateUrl: './dialog-edit-link-auth.component.html',
})
export class DialogEditLinkAuthComponent implements OnInit {

  public currentPath: string;
  public loginUrl: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    const pathname = window.location.pathname.substring(
      1,
      window.location.pathname.length
    );
    this.currentPath = pathname.concat(window.location.hash); // append #hash part if any for enhanced privacy links
    this.loginUrl = `${environment.cphost}/login`;
  }

}
