import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sync-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  public curDate: Date;

  constructor() { }

  ngOnInit() {
    this.curDate = new Date();
  }

}
