<!-- modalcontent -->
<div class="modal-header">
    <button (click)="activeModal.close()" type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Preview Preferences</h4>
</div>

<div class="modal-body">
    <sync-error [errcode]="errcode"></sync-error>
    <div class="row">
        <div class="col-sm-12">
            <p>
                You can now use Office Online to better integrate your workflow within Sync.
                <br>
                Edit and view Microsoft Word documents, Excel spreadsheets and Powerpoint slideshows all from your web
                panel.
            </p>
            <img src="images/ms-office-integration.svg"
                style="display: block; margin-left: auto; margin-right: auto; width: 50%;">
        </div>
    </div>

    <hr />

    <div class="row">
        <div class="col-sm-12">
            <div class="subtext">
                View the <a href="https://www.sync.com/help/office-online">Sync + Office Online documentation</a> for
                more more information.
            </div>
        </div>
    </div>


</div>

<div class="modal-footer">

    <button id="isoffice-save" type="button" class="btn btn-primary" (click)="optin()">
        Use Office
    </button>
    <button id="isoffice-save" type="button" class="btn btn-default" (click)="optout()">
        Keep using Sync
    </button>
</div>