<div class="modal-header">
    <button type="button" class="close" (click)="onCancel()"><i class="ion-android-close fa-lg"></i></button>
    
    <h4 class="modal-title">
        <i class="ion-android-people fa-lg"></i>
        Delete Role
    </h4>
</div>
    
<div class="modal-body">
      <div class="alert alert-warning">
        Are you sure you want to delete this role? Users in this role will be moved to the default role.
      </div>
</div>
    
<div class="modal-footer">
    <button 
        id="btn-share-new-cancel" 
        type="button" 
        class="btn btn-default" 
        (click)="onCancel()">
        No
    </button>
    
    <button
        (click)="onConfirm()"
        class="btn btn-primary pull-right"
    >
    Yes
    </button>
</div>