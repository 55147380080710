import { Injectable } from '@angular/core';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { ErrCode } from '../../shared/models';

@Injectable({
    providedIn: 'root',
})
export class LinkPasswordService {
    constructor(
        private base64: Base64Service,
        private log: LoggerService,
        private crypt: SyncCryptService,
        private user: UserService
    ) {}

    // create a list of valid characters to create a link password from
    public PASSWORDCHARS = 'abcdefghijkmnpqrstuvwxyz23456789';

    /**
     * Validates that the password they enter is of the allowed characters.
     *
     * Alpha numeric plus - and _. All characters allowed here must be
     * URL safe (so no equals signs)
     *
     * This function's return value is set up to work with the ui-validate
     * @param  {String} value Plain text version of their password.
     * @returns {Boolean}      Returns true if the password is NOT valid
     */
    public validatePassword(value: string) {
        return !/[^a-zA-Z0-9\-_]/gi.test(value);
    }

    /**
     * Makes a new link password.  Uses SyncCrypt's prng and a list of
     * characters which hopefully don't look a like.  Creates 32^32
     * potential passwords.
     * @param  {Integer=} len Optional length value.  Defaults to 32. It must
     *                       be divisible by 8.
     * @returns {Promise}      The password formatted and separated by dashes
     *                       "-" at every 8th character.
     */
    public makeLinkPassword(len?: number) {
        let i = 0,
            charString = '';
        const password: string[] = [],
            chars: string[] = [],
            passlen = len || 32;

        const rand = this.crypt.getRandom(passlen * 32);
        if (passlen % 8 !== 0) {
            throw new Error('Password length must be divisible by 8');
        }
        for (i = 0; i < passlen; i++) {
            // mod 32, 32 is len of PASSWORDCHARS value.
            chars.push(this.PASSWORDCHARS.charAt(Math.abs(rand[i]) % 32));
        }
        charString = chars.join('');

        // format password to be nice
        for (i = 0; i < passlen; i += 8) {
            password.push(charString.substring(i, i + 8));
        }
        return password.join('-');
    }

    /**
     * Gets a new-style password for a secure link.
     *
     * The password is a number prefix which tells us how it was encrypted
     * @param  {String} enc_password Number prefixed base64 representation
     *                               of the password.
     * @return {Promise}              Returns the password in plain text
     */
    public async getPassword(enc_password: string) {
        try {
            const pass = await this.crypt.linkpasswordDecrypt(enc_password);
            return this.base64.decode(pass);
        } catch (ex) {
            this.log.e('Error getting link password ', ex);
            throw ErrCode.fromException(ex);
        }
    }

    /**
     * Retrieves a legacy password which is just the enc_share_key
     * @param  {String} sharekey_id   The share key id.
     * @param  {String} enc_share_key Base64 of the encrypted share key
     * @return {Promise}               Base64 of the share key
     */
    public getLegacyPassword(sharekey_id: string, enc_share_key: string) {
        return this.crypt.sharekeyDecrypt(enc_share_key, sharekey_id);
    }

    /**
     * Converts the plain text link password to a valid share key.  The share
     * key will be 64 bytes (512 bits)
     * @param  {String} linkPassword The plain text password
     * @param  {Array} salt          A bit array of 16 bytes
     * @param  {Integer} iterations  An iteration count, default is 10000
     * @return {Promise}               A sjcl bitArray pkdbf2'd
     */
    public convertToShareKey(
        linkPassword: string,
        saltData: ArrayLike<number> | string,
        iterations: number
    ) {
        let salt = saltData;
        if (typeof saltData == 'string') {
            // salt will either be an array or a hex string
            salt = this.crypt.hexToBytes(<string>saltData);
        }
        return this.crypt.keyStretchSlow(
            linkPassword,
            salt,
            iterations || 10000,
            32 * 16
        );
    }

    /**
     * @ngdoc method
     * @name  makePasswordData
     * @methodOf sync.service:LinkPassword
     * @description
     * Generates all password data.
     * @param  {String} linkPassword The plain text password
     * @return {Promise}              Returns an object with the following:
     *                               {
     *                                   enc_share_key: enc_share_key,
     *                                   salt: sjcl.codec.hex.fromBits(salt),
     *                                   sharekey: sharekey,
     *                                   iterations: iterations,
     *                                   enc_password: '50:' + enc_password,
     *                                   enc_comment_key: comment_data_key
     *                               }
     */
    public async makePasswordData(linkPassword: string) {
        try {
            const pubkey = this.user.get('pubkey'),
                it = 10000;
            const saltData = this.crypt.getRandom(128);
            // .then((saltData: ArrayLike<number>) => {
            const salt = this.crypt.bytesToHex(saltData);
            const sharekey = await this.convertToShareKey(
                linkPassword,
                salt,
                it
            );
            const enc_password = await this.crypt.linkpasswordEncrypt(
                this.base64.encode(linkPassword),
                pubkey
            );
            const sharekeyB64 = this.crypt.bytesToB64(sharekey);
            const enc_share_key = await this.crypt.sharekeyEncrypt(
                sharekeyB64,
                pubkey
            );

            return {
                enc_share_key: enc_share_key,
                salt: salt,
                sharekey: sharekey,
                iterations: it,
                enc_password: enc_password,
            };
        } catch (ex) {
            this.log.e('make password data failed for link ', ex);
            throw ErrCode.fromException(ex);
        }
    }
}
