import { BaseApiOutput } from './base-api.model';


export class EmailExistsApiOutput extends BaseApiOutput {
    exists: 1|0;
}

export class EmailExistsApiInput {
    email_b64: string;
}

export class NewOrderResponse extends BaseApiOutput {
    success: 1|0;
}
