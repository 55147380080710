
export class BaseApiOutput {
    success: number;
    error_code = 0;
    error_msg: string = undefined;
    logout = 0;
    data?: any;
}

export class BaseApiInput {
    servtime = 0;
    access_token: string;
    signature: string;
    api_version = 2;
}

export class JwtTokenApiOutput {
    success: number;
    authenticated: number;
    token?: string;
}
