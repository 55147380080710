import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrCode } from 'src/app/shared/models';
@Component({
  selector: 'sync-dialog-file-zip',
  templateUrl: './dialog-file-zip.component.html'
})
export class DialogFileZipComponent implements OnInit {
  public errcode: ErrCode;
  public zipUrl = '';
  public zipName = '';
  public spinner = '';
  constructor(
    public activeModal: NgbActiveModal,
  ) { }
  ngOnInit() {
  }
}
