import { BaseApiOutput } from './base-api.model';


export class GetloginsaltApiOutput extends BaseApiOutput {
    salt: string;
}

export class GetloginsaltApiIntput {
    username: string;
    password: string;
    twoFa: string;
}


