<div class="dropdown" ngbDropdown>
    <button class="btn btn-block text-left dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" data-display="static" [title]="selected"
        ngbDropdownToggle>
        <span>
            {{selected ? selected : (placeholder ? placeholder : 'Select')}}
        </span>
    </button>
    <div class="dropdown-menu" ngbDropdownMenu>

        <!-- search -->
        <div *ngIf="showSearch" class="dropdown-item dropdown-item-search" (click)="$event.stopPropagation();">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search">
                <div class="input-group-append">
                    <span class="input-group-text h-100">
                        <img src="/assets/icons/search.svg" alt="" title="Bootstrap">
                    </span>
                </div>
            </div>
        </div>

        <!-- Items -->
        <div class="dropdown-item-checkboxes">

            <!-- all -->
            <div class="dropdown-item" (click)="$event.stopPropagation();" *ngIf="showAll && all.visible"
                [ngClass]="{ 'checked': all.checked }">
                <!-- checkbox -->
                <div class="dropdown-checkbox">
                    <input type="checkbox" [id]="'chkItem' + all.id" (change)='onChange($event, all)'
                        [checked]="all.checked">
                    <label class="dowpdown-label" [for]="'chkItem' + all.id">
                        <span class="pl-2" [title]="all.name">{{all.name}}</span>
                    </label>
                </div>
            </div>

            <ng-container *ngFor="let item of filtered; let i = index; trackBy: trackByID">
                <div class="dropdown-item" (click)="$event.stopPropagation();" *ngIf="item.visible"
                    [ngClass]="{ 'checked': item.checked }">
                    <!-- checkbox -->
                    <div class="dropdown-checkbox">
                        <input type="checkbox" [id]="'chkItem' + item.id" (change)='onChange($event, item)'
                            [checked]="item.checked" [disabled]="item.disabled">
                        <label class="dowpdown-label" [ngClass]="{ 'disabled': item.disabled }" [for]="'chkItem' + item.id">
                            <span class="pl-2" [title]="item.name">{{item.displayName || item.name}}</span>
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="dropdown-item" *ngIf="isLoading">
            Loading ...
        </div>

        <!-- not found -->
        <div class="dropdown-item" *ngIf="!isLoading && isEmpty">
            No item found
        </div>

        <!-- status -->
        <div *ngIf="showStatus" class="dropdown-count text-dark">
            <span><b>Count:</b> {{multiSelectItems.length}}</span>
            <span><b>Checked:</b> {{checked}}</span>
            <span *ngIf="search"><b>Search Count:</b> {{filtered.length}}</span>
        </div>
    </div>
</div>