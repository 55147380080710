import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User, ErrCode, PermissionActions } from '../../shared/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { UserService } from '../../core/user.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Subscription, Subject } from 'rxjs';


@Component({
  selector: 'sync-dialog-custom-brand',
  templateUrl: './dialog-custom-brand.component.html'
})
export class DialogCustomBrandComponent implements OnInit, OnDestroy {

  @Input() user: User;
  public logoInput: File; //user input

  public errcode: ErrCode;
  public customBrandForm: FormGroup;

  //white label attributes
  public logo: string; //either default image path or base64 image
  private defaultLogo: string;
  public headerPrimaryColor: string;
  private defaultHeaderPrimaryColor: string;
  public headerTextColor: string;
  private defaultHeaderTextColor: string;
  public buttonPrimaryColor: string;
  private defaultButtonPrimaryColor: string;
  public buttonTextColor: string;
  private defaultButtonTextColor: string;
  public linkTextColor: string;
  private defaultLinkTextColor: string;
  public permissionActions = PermissionActions;
  public showCustomBranding: boolean;

  private sub: Subscription;
  public spinner: boolean;

//   private sub: Subscription;
  private unsubscribe = new Subject<void>();



  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private logger: LoggerService,
    private api: ApiService,
    private userService: UserService,
    private store: Store<fromRoot.State>,
  ) { }

  ngOnInit() {
    // this.user.is_multi_admin = true;
    this.showCustomBranding = (this.user.has_whitelabel && !this.user.is_multi_child && !this.user.is_multi_admin) || (this.user.has_whitelabel &&  (this.user.is_multi_admin || this.checkPermission(this.permissionActions.MANAGE_BILLING)));
    //check if custom logo exists before loading default values
    this.sub = this.store
    .select(fromRoot.getAuthUser)
    .subscribe((data) => {
        if (data.whitelabel) {
          this.defaultLogo = data.whitelabel.image_data;
          this.defaultHeaderPrimaryColor = data.whitelabel.header_primary_color;
          this.defaultHeaderTextColor = data.whitelabel.header_text_color;
          this.defaultButtonPrimaryColor = data.whitelabel.button_primary_color;
          this.defaultButtonTextColor = data.whitelabel.button_text_color;
          this.defaultLinkTextColor = data.whitelabel.link_text_color;
        }
        });
    if (!this.defaultLogo) {
      this.defaultLogo = '';
      this.defaultHeaderPrimaryColor = '#00ADEF';
      this.defaultHeaderTextColor = '#FFFFFF';
      this.defaultButtonPrimaryColor = '#00ADEF';
      this.defaultButtonTextColor = '#FFFFFF';
      this.defaultLinkTextColor = '#00ADEF';
      this.logger.info('default whitelabel values loaded');
    }

    this.errcode = null;
    this.logoInput = null;
    //set things to saved settings or default
    this.logo = this.defaultLogo;
    this.headerPrimaryColor = this.defaultHeaderPrimaryColor;
    this.headerTextColor = this.defaultHeaderTextColor;
    this.buttonPrimaryColor = this.defaultButtonPrimaryColor;
    this.buttonTextColor = this.defaultButtonTextColor;
    this.linkTextColor = this.defaultLinkTextColor;
    this.spinner = false;
    this.createForm();
    this.OnChanges();
  }

  public checkPermission(permName: PermissionActions): boolean {
    return this.userService.checkPermission(permName);
  }


  /**
   * sets things to Sync settings
   */
  private async setPreferencesToDefault() {
    try {
      await this.api.execute('uploadwhitelabel',
      {'logoBase64': '',
      'headerPrimaryColor': '',
      'headerTextColor': '',
      'buttonPrimaryColor': '',
      'buttonTextColor': '',
      'linkTextColor': '',
    });
    this.userService.refresh();
    this.activeModal.close(true);
    } catch (ex) {
      this.errcode = ErrCode.fromException(ex);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
        this.sub.unsubscribe();
    }
  }

  private createForm() {
    const regex = new RegExp('^#[0-9A-F]{6}$', 'i'); //case-insensitive
    //logo input is not required if a custom logo exists
    if (this.logo) {
      this.customBrandForm = this.fb.group({
        logo: [this.logoInput],
        headerColorPicker: [this.headerPrimaryColor, Validators.pattern(regex)],
        headerTextColorPicker: [this.headerTextColor, Validators.pattern(regex)],
        buttonColorPicker: [this.buttonPrimaryColor, Validators.pattern(regex)],
        buttonTextColorPicker: [this.buttonTextColor, Validators.pattern(regex)],
        linkTextColorPicker: [this.linkTextColor, Validators.pattern(regex)],
      });
    } else {
      this.customBrandForm = this.fb.group({
        logo: [this.logoInput, Validators.required],
        headerColorPicker: [this.headerPrimaryColor, Validators.pattern(regex)],
        headerTextColorPicker: [this.headerTextColor, Validators.pattern(regex)],
        buttonColorPicker: [this.buttonPrimaryColor, Validators.pattern(regex)],
        buttonTextColorPicker: [this.buttonTextColor, Validators.pattern(regex)],
        linkTextColorPicker: [this.linkTextColor, Validators.pattern(regex)],
      });
    }

  }

  async onSubmit() {
    //api call to store the image
    // this.logger.info('upload info: ' + this.logo + " " + this.primaryColor + " " + this.textColor);
    try {
      await this.api.execute('uploadwhitelabel',
      {'logoBase64': this.logo,
      'headerPrimaryColor': this.headerPrimaryColor,
      'headerTextColor': this.headerTextColor,
      'buttonPrimaryColor': this.buttonPrimaryColor,
      'buttonTextColor': this.buttonTextColor,
      'linkTextColor': this.linkTextColor,
    });
    this.logger.info('whitelabel header color: ' + this.headerPrimaryColor);
    this.logger.info('whitelabel header text color: ' + this.headerTextColor);
    this.logger.info('whitelabel button color: ' + this.buttonPrimaryColor);
    this.logger.info('whitelabel button text color: ' + this.buttonTextColor);
    this.logger.info('whitelabel link text color: ' + this.linkTextColor);
    this.userService.refresh();
    this.activeModal.close(true);
    } catch (ex) {
      this.errcode = ErrCode.fromException(ex);
    }

  }


  resetPreferences() {
    this.errcode = null;
    this.customBrandForm.reset();
    this.setPreferencesToDefault();
    this.customBrandForm.get('headerColorPicker').setValue(this.defaultHeaderPrimaryColor);
    this.customBrandForm.get('headerTextColorPicker').setValue(this.defaultHeaderTextColor);
    this.customBrandForm.get('buttonColorPicker').setValue(this.defaultButtonPrimaryColor);
    this.customBrandForm.get('buttonTextColorPicker').setValue(this.defaultButtonTextColor);
    this.customBrandForm.get('linkTextColorPicker').setValue(this.defaultLinkTextColor);
  }

  async uploadFile(files: FileList) {
    if (!files || files.length == 0) {
      return;
    }
    this.errcode = null;
    this.setSpinner();
    const logoFile = files.item(0);
    const path = '/upload/whitelabel';
    const formData: FormData = new FormData();
    formData.append('file', logoFile, logoFile.name);
    //api call for image preprocessing
    await this.httpClient.post(environment.imageconversionhost + path, formData).subscribe(
      (response) => {
        if (response['success']) {
          this.logo = response['imgdata'];
          this.setSpinner();
        }
      },
        (err) => {
          this.errcode = new ErrCode(err.error.error_code, 'The file format is unsupported. Please try again');
          this.setSpinner();
      }
    );
  }

  //since Angular 7, two-way binding and form group combination is outdated, thus onChanges() is used
  OnChanges(): void {
    this.customBrandForm.valueChanges.subscribe((value) => {
      this.headerPrimaryColor = value.headerColorPicker;
      this.headerTextColor = value.headerTextColorPicker;
      this.buttonPrimaryColor = value.buttonColorPicker;
      this.buttonTextColor = value.buttonTextColorPicker;
      this.linkTextColor = value.linkTextColorPicker;
    });

  }

  setSpinner() {
      this.spinner = !this.spinner;
  }

}
