<form (submit)="customBrandForm.valid && onSubmit()" [formGroup]="customBrandForm" method="post" ngNativeValidate>
  <div class="modal-header">
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"><span
        aria-hidden="true">&times;</span></button>
    <h4 class="modal-title">Custom Branding Preferences</h4>
  </div>

  <ng-template [ngIf]="showCustomBranding">
    <div class="modal-body">
      <sync-error [errcode]="errcode"></sync-error>
      Your logo and colors will appear when your team shares files or folders.<br>
      PNG at 400 x 150 pixels recommended.<br>
      <a class="syncblue" href="https://www.sync.com/help/white-label">Learn more...</a>
      <div class="row row-vertical-center">
        <div class="col-sm-4">Upload logo<em style="color:red" *ngIf="!logo">*</em> :</div>
        <!-- <div class="col-sm-4" *ngIf= "!logo">
          <img [src]="defaultLogoPath" alt="Sync Logo">
        </div> -->
        <div class="col-sm-4" *ngIf="logo">
          <img class="logo-dialog-custom" [src]="'data:image/png;base64,'+logo" />
        </div>
        <div class="col-sm-4">
          <label for="dialog-custom-brand-logo" class="btn btn-default">
            <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
            Browse...
          </label>
          <input type="file" style="display:none" id="dialog-custom-brand-logo" formControlName="logo" accept="image/*"
            (change)="uploadFile($event.target.files)">
        </div>
      </div>

      <hr style="height:1px;border-width:0;color:gray;background-color:gray">

      <div class="row">
        <div class="col-sm-4">Header Bar Color:</div>
        <div class="col-sm-4">
          <input class="showhand" type="color" id="headerColorPicker" formControlName="headerColorPicker"
            [value]="headerPrimaryColor">
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-sm-4">Header Bar Text Color:</div>
        <div class="col-sm-4">
          <input class="showhand" type="color" id="headerTextColorPicker" formControlName="headerTextColorPicker"
            [value]="headerTextColor">
        </div>
      </div>

      <hr style="height:1px;border-width:0;color:gray;background-color:gray">

      <div class="row">
        <div class="col-sm-4">Button Color:</div>
        <div class="col-sm-4">
          <input class="showhand" type="color" id="buttonColorPicker" formControlName="buttonColorPicker"
            [value]="buttonPrimaryColor">
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-sm-4">Button Text Color:</div>
        <div class="col-sm-4">
          <input class="showhand" type="color" id="buttonTextColorPicker" formControlName="buttonTextColorPicker"
            [value]="buttonTextColor">
        </div>
      </div>

      <hr style="height:1px;border-width:0;color:gray;background-color:gray">

      <div class="row">
        <div class="col-sm-4">Link Text Color:</div>
        <div class="col-sm-4">
          <input class="showhand" type="color" id="linkTextColorPicker" formControlName="linkTextColorPicker"
            [value]="linkTextColor">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">Preview:</div>
      </div>
      <div class="row" style="padding-top:6px;padding-left: 15px; padding-right: 15px;">
        <div class="col-sm-12 row-vertical-center" style="overflow: visible; min-height:50px;"
          [style.background]="headerPrimaryColor" *ngIf="logo">
          <img class="logo-dialog-custom" [src]="'data:image/png;base64,'+logo" style="float: left;" />
          <div>
            <p [style.color]="headerTextColor" style="padding-left: 40px;">
              Header Text
            </p>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 6px;padding-left: 15px; padding-right: 15px;border:0px gray" *ngIf="logo">
        <button class="btn" [style.background-color]="buttonPrimaryColor" [style.color]="buttonTextColor"
          style="padding-left:20px">
          Button
        </button>
        <a [style.color]="linkTextColor" style="float:right" href="">Link Text</a>
      </div>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default pull-left" (click)="resetPreferences()">
        Reset to Sync
      </button>
      <button type="submit" class="btn btn-primary"
        [disabled]="spinner || !customBrandForm.valid || !logo">Save</button>
      <button type="button" class="btn btn-default" (click)="activeModal.close()">Close</button>
    </div>
  </ng-template>

  <ng-template [ngIf]="(user.has_whitelabel && user.is_multi_child && !user.is_multi_new) || (user.has_whitelabel && !checkPermission(permissionActions.MANAGE_BILLING))">
    <div class="modal-body">
      Please contact your {{ user.is_multi_new ? 'admin' : 'Pro Teams Advanced administrator' }} to configure this feature.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="activeModal.close()">Close
      </button>
    </div>
  </ng-template>

  <ng-template [ngIf]="!user.has_whitelabel">
    <div class="modal-body">
      Please upgrade your account to <b>{{(user.is_multi_child || user.is_multi_admin) ? "Pro Teams Unlimited" : "Pro Solo Professional" }}</b> to add a custom logo to your links!
    </div>
    <div class="modal-footer">
      <a routerLink="/account/upgrade" type="button" class="btn btn-primary" (click)="activeModal.close()">Upgrade
        Account
      </a>

      <button type="button" class="btn btn-default" (click)="activeModal.close()">Close
      </button>
    </div>
  </ng-template>
</form>