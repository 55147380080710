<sync-preview-error
    *ngIf="!initialized && errcode"
    [errcode]="errcode"
    [syncId]="locId"
></sync-preview-error>

<sync-preview-error
    *ngIf="initialized && errcode"
    [errcode]="errcode"
    [syncId]="locId"
></sync-preview-error>

<sync-preview-image
    *ngIf="type == 'image' && initialized"
    [item]="item"
    [list]="list"
    [syncId]="locId"
></sync-preview-image>

<sync-preview-default
    *ngIf="type == 'default' && initialized"
    [item]="item"
    [upsell]="upsell"
    [notice]="notice"
></sync-preview-default>

<sync-preview-doc
    *ngIf="type == 'doc' && initialized"
    [item]="item"
    (stateChange)="onStateChange($event)"
></sync-preview-doc>
<sync-preview-text
    *ngIf="type == 'text' && initialized"
    [item]="item"
></sync-preview-text>

<sync-preview-pdf
    *ngIf="type == 'pdf' && initialized"
    [item]="item"
></sync-preview-pdf>

<sync-preview-wopi
    *ngIf="
        (type == 'office' || type == 'officefse' || type == 'officefsv') &&
        initialized
    "
    [item]="item"
    (stateChange)="onStateChange($event)"
></sync-preview-wopi>

<div class="container-fluid" *ngIf="item?.context == 'link'">
    <div class="link-upsell">
        <span class="hidden-xs" *ngIf="isBusiness == 0">
            Share your own files absolutely free with
            <a
                rel="noopener"
                href="https://www.sync.com/?_m=h73"
                target="_blank"
                class="syncblue showhand"
                >Sync.com</a
            >
        </span>
        <a
            (click)="showReportDialog()"
            class="syncblue pull-right showhand"
            *ngIf="!isWhiteLabel"
            >Report link</a
        >
        <a
            (click)="showReportDialog()"
            class="pull-right showhand"
            [ngStyle]="{'color':linkTextColor}"
            *ngIf="isWhiteLabel"
            >Report link</a
        >
    </div>
</div>
