import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class UserSsoApiOutput extends BaseApiOutput implements UserKeys {
    user_id: number;
    enc_meta_key: string;
    enc_priv_key: string;
    userattr: UserSession & AccessTokens;

}

export class UserSsoApiInput extends BaseApiInput {
    key: string;
    twofacode?: string;
}


