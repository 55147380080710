export class EmailLinkInvite {

    constructor(
        public email: string,
        public msg: string,
        public displayname: string,
        public cachekey: string,
        public linkname: string,
        public linkUrl: string
    ) {}
}
