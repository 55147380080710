import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogLinkComponent } from '../links/dialog-link/dialog-link.component';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as LinkListActions from '../actions/link-list.actions';
import { LinkListItem } from '../shared/models';
@Injectable({
    providedIn: 'root'
})
export class CompatService {

    constructor(
        private modalService: NgbModal,
        private store: Store<fromRoot.State>
    ) { }


    public showLinkDialog(item: LinkListItem | sync.IFile, openState: number) {
        const ref = this.modalService.open(DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;

        ref.result.then((result) => {
            if (result === true) {
                this.store.dispatch(new LinkListActions.LinkListRefreshAction());
            }
        });
    }

    public showLinkDialogPromise(item: LinkListItem | sync.IFile, openState: number) {
        const ref = this.modalService.open(DialogLinkComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.item = item;
        ref.componentInstance.openState = openState || 1;
        return ref.result;
    }
}
