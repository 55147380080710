import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { ErrorMessages } from '../../shared/models';

@Component({
  selector: 'sync-error-display',
  templateUrl: './error-display.component.html'
})
export class ErrorDisplayComponent implements OnInit {
  @Input('errorItem') public errorItem: any;
  public errorCode: number;
  public errorMsg: SafeHtml;
  public user_display_name: string;
  public currentPath: string;

  constructor(
    private loggerService: LoggerService,
    private userService: UserService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    console.log('this.errorItem', this.errorItem);
    this.user_display_name = this.userService.get('display_name');

    this.currentPath = window.location.pathname.substring(
      1,
      window.location.pathname.length
    );
    let code: number;
    let msg = '';
    if (!this.errorItem) {
      return;
    }

    if (typeof this.errorItem === 'string') {
      code = 1000;
      msg = this.errorItem;
    } else if (typeof this.errorItem === 'object') {
      if (this.errorItem.code) {
        code = this.errorItem.code;
        msg = this.errorItem.msg;
      } else if (this.errorItem.error_code !== undefined) {
        // straight outta compton errrm API
        code = this.errorItem.error_code;
        msg = this.errorItem.error_msg;
      } else {
        code = 1000;
        msg = 'An uknown error occurred';
      }
    } else {
      code = 1000;
      msg = 'An unknown error occurred';
    }

    this.errorCode = code;
    this.errorMsg = this.formatMsg(code, msg);
    this.loggerService.error(
      `An error occurred ${this.errorCode}: ${this.errorMsg}`
    );
  }



  private formatMsg(code: number, msg?: string): SafeHtml {
    const defaultMsg = msg || ErrorMessages[code];
    const codeStr = code !== 0 ? `(#${code}) ` : '';
    return this.sanitizer.bypassSecurityTrustHtml(codeStr + defaultMsg);
  }

}
