import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromRoot from '../../reducers';
import { PlanDetails, User } from '../../shared/models';
import { SkuService } from '../../shared/services/sku.service';
import { LoggerService } from '../../core/logger.service';

@Component({
  selector: 'sync-plan-details',
  templateUrl: './plan-details.component.html'
})
export class PlanDetailsComponent implements OnInit, OnDestroy {
  @Input('plan') plan: PlanDetails;
  @Input('view-term') viewTerm: 'annual' | 'monthly' = 'annual';
  @Input('teams-qty') teamsQty = 0;
  @Input('user-limit') userLimit: number[];
  @Input('is-disabled') isDisabled = false;
  @Input('on-upgrade') onUpgrade: any;
  @Input('plan-expiry') planExpiry: number;
  @Input('is-trial') isTrial: boolean;
  @Input('is-current-plan') isCurrentPlan: boolean;
  @Input() isTrialUserWithoutCC: boolean;
  @Input() currentTerm: string;

  public showTrial = false;
  public user: User;
  private subscription: Subscription;
  public showPlanUpgradedNote = false;
  public isButtonDisabled = false;
  public planSKU = '';

  constructor(
    private store: Store<fromRoot.State>,
    private skuService: SkuService,
    private loggerService: LoggerService
  ) { }

  async ngOnInit() {
    this.subscription = this.store.select(fromRoot.getAuthUser)
      .subscribe(async (data) => {
          this.user = data;
          this.loggerService.info('Generating plans for user.sku = ' + this.user.sku);
          this.planSKU = await this.skuService.getProductPlanSKU(this.user.sku);
          if (this.plan.family == 'BPT') {
            if (this.planSKU === 'PS' && this.teamsQty < 3) {
              this.teamsQty = 3;
            } else if (this.planSKU === 'PSP' && this.teamsQty < 4) {
              this.teamsQty = this.user.is_on_trial ? 3 : 4;
            }
          }
      });
    this.viewTerm = this.plan[this.viewTerm].name ? this.viewTerm : this.viewTerm === 'annual' ? 'monthly' : 'annual';

    this.onTermChange(this.viewTerm);
  }
  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

  public onTermChange(viewTerm): void {
    this.viewTerm = viewTerm;
    if (this.plan['trialPlan'][viewTerm]) {
      this.showTrial = true;
    } else {
      this.showTrial = false;
    }
  }

  // add this function when current plan is Solo and upgraded to the team+ on standard plan.
  public onQtyChange(event): void {
    this.showPlanUpgradedNote = false;
    this.isButtonDisabled = false;
    // handle special user limit rules for BPTSP
    if (this.plan[this.viewTerm].product_sku === 'BPTSP') {

      if (this.planSKU === 'PS') {
        this.loggerService.info(`qty change PS -> BPTSP, chose ${event.target.value} setting warning`);
        // Set minimum users when going from PS-x-x sku to BPTSP-x-x to 3
            this.showPlanUpgradedNote = (event.target.value < 3);
        this.isButtonDisabled = (event.target.value < 3);
        } else if (this.planSKU === 'PSP' && !this.user.is_on_trial) {
          this.loggerService.info(`qty change PSP -> BPTSP, chose ${event.target.value} setting warning`);
          // Set minimum users when going from PSP-x-x sku to BPTSP-x-x to 4
            this.showPlanUpgradedNote = (event.target.value < 4);
        this.isButtonDisabled = (event.target.value < 4);
        } else {
            this.showPlanUpgradedNote = false;
            this.isButtonDisabled = false;
        }
    }

  }
}
