import { BaseApiOutput, BaseApiInput } from './base-api.model';

export class RequestHintApiOutput extends BaseApiOutput {
    code: string;
}

export class RequestHintApiInput extends BaseApiInput {
    email: string;
}


