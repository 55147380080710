import { Pipe, PipeTransform } from '@angular/core';
import { Base64Service } from '../../core/base64.service';

@Pipe({
    name: 'b64'
})
export class B64Pipe implements PipeTransform {

    constructor(
        private base64: Base64Service
    ) {}
    transform(value: string, encode?: boolean): string {
        if (encode === true) {
            return this.base64.encode(value);
        } else {
            return this.base64.decode(value);
        }
    }

}
