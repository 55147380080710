import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';
import { Device, ErrCode } from '../../shared/models';


@Component({
    selector: 'sync-dialog-device-activate',
    templateUrl: './dialog-device-activate.component.html'
})
export class DialogDeviceActivateComponent implements OnInit {

    @Input() device: Device;

    public errcode: ErrCode;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService
    ) { }

    ngOnInit() {
    }

    public async activate() {
        try {
            await this.api.execute('deviceactivate', {
                device_id: this.device.id
            });
            this.activeModal.close(true);
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
        }
    }
}
