import { Component, OnInit, Input } from '@angular/core';
import { User, OrderSessionNewApiOutput, PermissionActions } from '../../shared/models';
import { ZuoraAccountService } from '../services/zuora-account.service';
import { LoggerService } from '../../core/logger.service';
import { zAccountInfo, ZBillingQueue } from '../../shared/models/api/orderprofile-api.model';
import { ApiService } from '../../core/api.service';
import { SyncCookieService } from '../../core/sync-cookie.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../core/user.service';
import { Router } from '@angular/router';
import { SkuService } from '../../shared/services/sku.service';
import { PlanTerm } from '../../../../src/app/shared/models/api/plandetails.model';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-billing-status',
    templateUrl: './billing-status.component.html',
})
export class BillingStatusComponent implements OnInit {

    @Input() user: User;
    public zprofile: zAccountInfo;
    public zbillingqueue: ZBillingQueue[] = [];
    public zbillingqueuepending: ZBillingQueue[] = [];
    public planName: string;
    public planSku: string;
    public planStart: number;
    public planExpiry: number;
    public paidThru: number;
    public planExpiryClose: boolean;
    public cancelInFuture: boolean;
    public permissionActions = PermissionActions;
    public currentTerm: string;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private syncCookie: SyncCookieService,
        private zuora: ZuoraAccountService,
        private userService: UserService,
        private router: Router,
        private skuService: SkuService,
        private blendService: BlendService,
    ) { }

    ngOnInit() {
        this.planName = this.user.plan_name;
        if (this.checkPermission(this.permissionActions.VIEW_BILLING)) {
            this.loadBillingProfile();
        } else {
            this.planExpiry = this.user.account_duedate;
            this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
        }
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    private async loadBillingProfile() {
        try {
            const data = await this.zuora.getProfileAndQueue();
            this.zprofile = data.zprofile;
            this.zbillingqueue = data.zbillingqueue;
            this.zbillingqueuepending = this.zbillingqueue.filter((bq) => bq.status === 'pending');
            if (this.zprofile.subscription) {
                this.planName = this.zprofile.subscription.productFullName;
                this.planSku = this.zprofile.subscription.sku;
                this.cancelInFuture = this.zprofile.subscription.status === 'Cancelled';

                this.planExpiry = this.user.is_on_trial
                    ? this.userService.dateConversionForTrials(this.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime()
                    : this.getDateFromTerm(this.zprofile.subscription.termEndDate);
                this.planStart = this.getDateFromTerm(this.zprofile.subscription.termStartDate);
                this.paidThru = this.getDateFromTerm(this.zprofile.paidThroughDate);

                this.currentTerm = this.skuService.getPlanTerm(this.planSku);

                switch (this.currentTerm) {
                    case PlanTerm.ANNUAL:
                        this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
                        break;
                    case PlanTerm.MONTHLY:
                        this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 15);
                        break;

                    default:
                        this.planExpiryClose = (this.planExpiry <= Date.now() + 86400000 * 30);
                        break;
                }
                console.log(`${this.planExpiry} <= ${Date.now() + 8640000 * 30} > 86400000 * 30`);
            }
        } catch (ex) {
            this.log.e('Error loading billing profile', ex);
        }
    }
    public canRenew() {
        return ((this.planExpiry && this.planExpiryClose) || (this.zprofile && this.zprofile.balance !== 0)) && !this.cancelInFuture && !this.user.is_on_trial;
    }
    public async renew() {
        if (this.checkPermission(this.permissionActions.MANAGE_BILLING)) {
            const result = await this.api.execute<OrderSessionNewApiOutput>('ordersessionnew', {});
            if (result && result.sessionId) {
                this.syncCookie.setOrderSessionId(result.sessionId);
                window.location.href = `${environment.ordershost}/renew`;
                console.log('this user is in zuora, redirect to renew');
            }
        }
    }

    public onUpgradeClick() {
        if (this.user.is_on_trial && this.zprofile && !this.zprofile.defaultPaymentMethod) {
            this.router.navigate(['/account/billing/profile']);
        } else {
            this.blendService.addEventsForUpgradeButton();
            this.router.navigate(['/account/upgrade']);
        }
    }

    private getDateFromTerm(term: string) {
        const termDateStrings = term.split('-');
        if (termDateStrings.length !== 3) {
            this.log.error(`An invalid term received ${term}`);
        }
        return new Date(
            parseInt(termDateStrings[0], 10),
            parseInt(termDateStrings[1], 10) - 1, // js date months starts at 0
            parseInt(termDateStrings[2], 10)
        ).getTime();
    }



}
