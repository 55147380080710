import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LinkItem, LinkDeleteApiOutput, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { LoggerService } from '../../core/logger.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as FileListActions from '../../actions/file-list.actions';
import * as LinkListActions from '../../actions/link-list.actions';

@Component({
    selector: 'sync-link-delete',
    templateUrl: './link-delete.component.html'
})
export class LinkDeleteComponent implements OnInit {

    @Input() link: LinkItem;


    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public errcode: ErrCode;
    public spinner: boolean;

    constructor(
        private api: ApiService,
        private log: LoggerService,
        private store: Store<fromRoot.State>
    ) { }

    ngOnInit() {
        this.spinner = false;
    }

    public closeDialog() {
        this.close.emit(false);
    }

    public async delete() {
        try {
            this.errcode = null;
            this.spinner = true;
            await this.api.execute<LinkDeleteApiOutput>('linkdelete', {sync_id: this.link.sync_id});
            this.spinner = false;
            this.store.dispatch(new FileListActions.FileListReloadAction());
            this.close.emit(true);
        } catch (e) {
            this.log.e('Error deleting link ' + this.link.cachekey, e);
            this.errcode = ErrCode.fromException(e);
            this.spinner = false;
        }
    }
}
