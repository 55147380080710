import { Directive, HostListener, Inject, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogShareNewComponent } from '../../shares/dialog-share-new/dialog-share-new.component';

@Directive({
    selector: '[syncDialogShareNew]',
})
export class DialogShareNewDirective {
    @Input('pid') pid: number;
    @Input('selected') selected: sync.IFile[] = [];

    constructor(
      private modalService: NgbModal,
    ) {
    }

    @HostListener('click', ['$event']) click(event) {
        this.openShareNewDailog();
    }

    openShareNewDailog() {
        const ref = this.modalService.open(DialogShareNewComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.selected = (Array.isArray(this.selected)) ? this.selected : [this.selected];
        ref.componentInstance.pid = this.pid;
    }
}
