<div>
  <h4>
      <i class="fa fa-exclamation-triangle"></i>
      <span [innerHtml]="errorMsg" >An error occurred</span>
  </h4>
  <p *ngIf="errorCode == 1610 && errorItem.data">
      The existing nested share (id: {{errorItem.data}}
      <a href="/files/{{errorItem.data}}" target="_blank">
          can be viewed here
      </a>
  </p>

  <p *ngIf="errorCode == 1101">
      This link is invalid. Please contact the owner of the link and ensure
      that you are using the correct address.
  </p>
  <div *ngIf="errorCode == 1600">
      There are three reasons this would happen:
      <ol>
          <li>
              You've already accepted or declined this share and the link is
              old.
          </li>
          <li>
              Your invite was removed by the share owner before you had a
              chance to act on it. In this case, please contact the share
              owner to re-invite you. You will receive a new link to join this
              share afterwards.
          </li>
          <li>
              The address you were provided contains an error or was modified
              by your email client. Ensure the link was sent correctly and
              when you clicked it in your email, the entire link was
              clickable. You can always copy/paste the address into your
              browser.
          </li>
      </ol>
  </div>
  <div *ngIf="errorCode == 1601">
      It is possible you're logged in as a different user than this invitation
      was sent to. You're logged in as
      <b><span [innerHtml]="user_display_name | b64"></span></b>.
      <a href="/login?return_to={{currentPath}}">Login</a>
  </div>

  <p *ngIf="errorCode == 2110">
      The password provided for this link was incorrect. Please double check
      and ensure the password was entered correctly. The password is case
      sensitive. If you need further assistance, please contact the person who
      sent you the link, or feel free to
      <a
          class="showhand"
          syncOpenDialog
          >contact us</a
      >.
  </p>

  <p *ngIf="errorCode == 3010">
      Safari and a few older web browsers are not capable of downloading
      encrypted files. Please try this link again using Google Chrome or
      Firefox. Just looking out for your security.
  </p>

  <p *ngIf="errorCode >= 2000 && errorCode <= 2100">
      It appears that the encryption keys for this file are corrupt. Please
      contact support and tell them File ID <span [innerHtml]="syncId"></span> is
      missing it's
      <span *ngIf="errorCode == 2000">share key</span>
      <span *ngIf="errorCode == 2010">data key</span>
  </p>

  <p *ngIf="errorCode == 3050">
      Private browsing prevents us from storing and decrypting the file in
      your browser. Please disable private browsing and try to download the
      file again.
  </p>

  <!-- <strong class="visible-xs-inline"><br />If you're on a mobile device you may want to try this link on a desktop using one of the web browsers mentioned.</strong> -->
</div>
