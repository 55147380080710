import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../core/logger.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { TransferStatus } from '../../transfer/transfer.model';
import { TransferService } from '../../transfer/transfer.service';
import { BlendEvent } from '../../shared/models';
import { BlendService } from '../../shared/services/blend.service';

@Component({
    selector: 'sync-preview-pdf',
    templateUrl: './preview-pdf.component.html',
})
export class PreviewPdfComponent implements OnInit, OnDestroy {
    public type: string; // binding from component.
    public transferItem: sync.ITransferItemDownload;
    public content: string;
    @Input() public item: sync.IFile;
    public spinner = false;
    public errorMsg: string;
    public url: string;
    public allowComment: 0 | 1 | 2;
    public isCommentExpanded: boolean;

    private sub: Subscription;

    constructor(
        private linkPathListService: LinkFileListService,
        private transferService: TransferService,
        private buildTransferItemService: BuildTransferItemService,
        private loggerService: LoggerService,
        private blendService: BlendService,
    ) {}

    ngOnInit() {
        this.sub = this.linkPathListService
            .getSubscription()
            .subscribe((data) => {
                if (data.loaded && data.sorted) {
                    this.allowComment = data.allow_comment;
                }
            });
        if (this.transferItem) {
            this.transferItem.renderFile.url = undefined;
            this.transferItem.renderFile.msblob = undefined;
            this.transferItem.renderFile.dl_ready = false;
        }
        this.reset();
        this.loadPdf();
        this.blendService.track(BlendEvent.VIEW_FILE, {
            type: 'pdf',
            preview_preference: 'N/A',
            fileSize: this.item.filesize,
            mimeType: this.item.mime_type
        });
    }

    private reset() {
        this.content = undefined;
        this.errorMsg = undefined;
        this.spinner = false;
    }

    private loadPdf() {
        this.spinner = true; // toggled in renderPdf
        const isPreview = true;
        this.transferService.getFile(this.item, isPreview).then(
            (tItem) => {
                if (
                    tItem.status == TransferStatus.STATUS_ERR_SIZE ||
                    tItem.status == TransferStatus.STATUS_ERR_OPEN
                ) {
                    // Logger.error('Cannot view file');
                    this.errorMsg = 'Unable to view this file.';
                }

                if (tItem.renderFile.url) {
                    // this.url = tItem.renderFile.url;
                    this.loggerService.info(
                        'loadPdf success for ' +
                            this.item.sync_id +
                            '  ' +
                            tItem.renderFile.url
                    );
                    this.renderPdf(tItem.renderFile.url);
                }
            },
            (err) => {
                this.spinner = false;
                this.errorMsg = 'An error occurred loading the preview';
                this.loggerService.error(
                    'loadPdf failed  for ' + this.item.sync_id
                );
                if (typeof err === 'object') {
                    this.loggerService.error(JSON.stringify(err));
                } else if (typeof err === 'string') {
                    this.loggerService.error(err);
                } else {
                    this.loggerService.error('Unknown error occurred');
                }
            }
        );
    }

    private renderPdf(url: string) {
        if (!PDFJS.PDFViewer || !PDFJS.getDocument) {
            alert('PDFJS is not currently installed');
        }
        PDFJS.workerSrc = '/workers/pdf.worker.min.js';
        PDFJS.imageResourcesPath = '/assets/pdfjs/images/';

        // var SEARCH_FOR = ''; // try 'Mozilla';

        const container = document.getElementById('viewerContainer');

        // // (Optionally) enable hyperlinks within PDF files.
        const pdfLinkService = new PDFJS.PDFLinkService();

        const pdfViewer = new PDFJS.PDFViewer({
            container: container,
            linkService: pdfLinkService,
        });
        pdfLinkService.setViewer(pdfViewer);

        // (Optionally) enable find controller.
        // var pdfFindController = new PDFJS.PDFFindController({
        //     pdfViewer: pdfViewer
        // });
        // pdfViewer.setFindController(pdfFindController);

        container.addEventListener('pagesinit', () => {
            // We can use pdfViewer now, e.g. let's change default scale.
            pdfViewer.currentScaleValue = 'page-width';

            // if (SEARCH_FOR) { // We can try search for things
            // pdfFindController.executeCommand('find', {query: SEARCH_FOR});
            // }
        });

        // Loading document.
        PDFJS.getDocument(url).then(
            (pdfDocument: any) => {
                // Document loaded, specifying document for the viewer and
                // the (optional) linkService.
                // console.log('getting document ' , DEFAULT_URL, ' pdfDocument = ', pdfDocument)
                pdfViewer.setDocument(pdfDocument);

                pdfLinkService.setDocument(pdfDocument, null);
                this.spinner = false;
            },
            (err: any) => {
                if (typeof err === 'object') {
                    this.loggerService.error(JSON.stringify(err) );
                    if (err.name == 'InvalidPDFException') {
                        this.errorMsg =
                            'Failed to load the document - PDF was invalid nor not downloaded correctly (' +
                            err.message +
                            ')';
                    } else {
                        this.errorMsg = 'Failed to load the document';
                    }
                } else if (typeof err === 'string') {
                    this.loggerService.error(err);
                } else {
                    this.errorMsg = 'Failed to load the document';
                    this.loggerService.error('Unknown error occurred');
                }
                this.spinner = false;
            }
        );
    }

    public onCommentToggle = (isCommentExpanded: boolean) => {
        this.isCommentExpanded = isCommentExpanded;
    }

    ngOnDestroy(): void {
        // must reset this since it never gets unset and causes old images
        // to briefly flash on screen.
        this.buildTransferItemService.view.single = undefined;
    }
}
