import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../core/api.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { UserService } from '../../core/user.service';
import { BaseApiOutput } from '../../shared/models';
import { ShareNewService } from '../../shares/share-new.service';
import { ShareListService } from '../../shares/share-list.service';
import { ShareService } from '../../shares/share.service';


@Injectable({
    providedIn: 'root'
})
export class AppsService {


    constructor(
        private Logger: LoggerService,
        private User: UserService,
        private base64: Base64Service,
        private api: ApiService,
        private shareService: ShareService,
        private shareNewService: ShareNewService,
        private shareListService: ShareListService
    ) {}



    public OauthValidate(nonce: string, redirect_url: string): Promise<AppsOauthValidateOutput> {
        return this.api.execute('appsoauthvalidate', {
            nonce: nonce,
            url: redirect_url,
        });
    }

    public OauthComplete(nonce: string, email: string): Promise<AppsOauthCompleteOutput> {
        return this.api.execute('appsoauthcomplete', {
            nonce: nonce,
            email: email,
        });
    }

    public getApps(share_id?: number): Promise<AppsGetAppsOutput>  {
        if (!share_id) {
            return this.api.execute('appsgetapps', {});
        } else {
            return this.api.execute('appsgetapps', {share_id: share_id});
        }
    }

    public setApps(share_id: number, set_status: boolean, app_ids: number[]): Promise<AppsWriteAppsOutput>  {
        return this.api.execute('appssetapps', {
            share_id: share_id,
            set_status: set_status,
            app_ids: app_ids
        });
    }

    public getDirs(app_id: number): Promise<AppsGetDirsOutput>  {
        return this.api.execute('appsgetdirs', {app_id: app_id});

    }

    public revokeApps(app_ids: number[]): Promise<any> {
        return this.api.execute('appsrevoke', {app_ids: app_ids});
    }

    public async readApps(shareId: number): Promise<sync.IApp[]> {
        const resp = await this.getApps(shareId);

        if (resp.success == 0) {
            this.Logger.error('Could not read apps from API');
            throw({code: 11200});
        } else {
            if (resp.apps.length == 0) {
            } else {
                for (const app of resp.apps) {
                    app.selected = false;
                }
                return resp.apps;
            }
        }
    }

    public async writeApps(shareId: number, apps: sync.IApp[], status: boolean): Promise<sync.IApp[]> {
        if (shareId === 0) {
            this.Logger.error('Could not read share as Share ID = 0 ');
            throw ({ code: 11201, msg: 'This folder has become invalid.' });
        }

        const appIds: number[] = [];
        apps.map((cur) => {
            if (cur.selected) {
                appIds.push(cur.app_id);
            }
        });

        this.Logger.info(`${status ? 'Enabling' : 'Disabling'} apps ${JSON.stringify(appIds)} to share ${shareId}`);

        const resp = await this.setApps(shareId, status, appIds)
        .catch(err => {
            this.Logger.error('WriteApps failed');
            this.Logger.error(err);
            throw err;
        });

        if (resp.success == 0) {
            this.Logger.error('Writing apps failed with success == 0');
            throw resp;
        } else {
            for (const app of resp.apps) {
                app.selected = false;
            }
            return resp.apps;
        }
    }

    public async addAppToExistingShare(sync_id: number, share_id: number): Promise<void> {
        await this.shareNewService.addAppShare(sync_id, share_id)
        .then((shareInfo) => {
            this.Logger.info('Added App to Existing Share');
            // What to do after add?

        })
        .catch(err => {
            this.Logger.error('Adding Apps to existing share failed');
            this.Logger.error(err);
            throw err;
        });
    }

    public async addAppAndCreateShare(sync_id: number, dir_name: string): Promise<number> {
        const sharememberInvite: sync.ISharememberInviteData = {
            permissions: {
                'perDOWNLOAD': 1,
                'perUPLOAD': 1,
                'perINVITE': 0,
                'perSEEOTHERS': 1,
                'perADDAPP': 0
            },
            displayName: this.base64.decode(this.User.get('display_name')),
            queue: [],
            roleQueue: [],
            roleName: '',
            privateMessage: ''
        };

        return await this.shareNewService.mkShare(sync_id, dir_name, dir_name, sharememberInvite, true).toPromise()
            .then((shareInfo) => {
                this.Logger.info('Made share with share ID: ' + shareInfo.shareId);

                return shareInfo.shareId;

            }).catch(err => {
                this.Logger.error('Making share for apps failed');
                this.Logger.error(err);
                throw err;
            });
    }

    public async disableAppShare(sync_id: number) {
        await this.shareService.removeAppShare(sync_id)
        .then(data => {
            if (data.success == 1)  {
                this.Logger.info(`Successfully removed apps from ${sync_id}`);

                // this.modalInstance.close();
            } else {
                this.Logger.error(`Could not remove apps from ${sync_id}`);
                this.Logger.error(data);
                throw data;
            }
        }).catch(err => {
            this.Logger.error(`Could not remove apps - ${err}`);
            this.Logger.error(err);
            throw err;
        });
    }

    public async disableApp(app_id: number) {
        this.Logger.info(`Apps Service - Disabling app ${app_id}`);

        const data = await this.api.execute('sharelist', {});
        const sharelist = await this.shareListService.processList(data);
        const dirlist = await this.getDirs(app_id);

        for (const dir of dirlist.dirs) {
            for (const share of sharelist.apps) {
                if (
                    dir.share_id == share.share_id &&
                    share.app_integrated &&
                    share.num_apps === 1
                ) {

                    this.Logger.info(`Share ${dir.share_id} is last removing app ${app_id}. Disabling users app-share. on ${share.sync_id}`);
                    await this.disableAppShare(share.sync_id);
                }
            }
        }

        await this.revokeApps([app_id]);
    }
}



export interface AppsOauthValidateOutput extends BaseApiOutput {
    app: {
        id: string,
        name: string
    };
}

export interface AppsOauthCompleteOutput extends BaseApiOutput {
    token: string;
}

export interface AppsGetAppsOutput extends BaseApiOutput {
    apps: sync.IApp[];
}

interface AppsWriteAppsOutput extends BaseApiOutput {
    apps: sync.IApp[];
}

interface AppsGetDirsOutput extends BaseApiOutput {
    active: boolean;
    dirs: {
        app_id: number,
        name: string,
        share_id: number,
        user_id: number,
    }[];
    user: {
        email: string
    };
}

