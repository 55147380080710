<form syncStopPropagation [formGroup]="renameform" ngNativeValidate>
    <div
        [ngClass]="{
            'has-success': renameform.valid,
            'has-error': !renameform.valid
        }"
    >
        <div class="input-group">
            <label for="originalname" class="sr-only">New name</label>
            <input
                id="path-rename-text"
                name="originalname"
                type="text"
                class="form-control"
                [readonly]="spinner"
                placeholder="Enter a new name..."
                formControlName="originalname"
                style="z-index: 0"
                (keyup.escape)="reset()"
                required
            />
            <span class="input-group-btn">
                <button
                    style="z-index: 0"
                    (click)="submit()"
                    class="btn btn-primary"
                    id="path-rename-btn"
                >
                    <i
                        class="fa fa-spinner fa-spin"
                        [class.hide]="!spinner"
                    ></i>
                    <span [class.hide]="!(spinner && item.type == 'dir')">
                        {{ batchView.percent / 2 }}%
                    </span>
                    Rename
                </button>
            </span>
        </div>

        <div
            class="sync-tool-tip"
            *ngIf="
                !renameform.controls.originalname.valid &&
                renameform.controls.originalname.dirty
            "
        >
            <i class="fas fa-exclamation-triangle"></i>
            <span *ngIf="renameform.controls.originalname.errors?.leadingSpace">
                Files cannot begin with a space
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.trailingChar">
                Files cannot end in a . or a space.
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.reserved">
                This name is a reserved and cannot be used.
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.illegal">
                The following characters are not allowed: \ / : ? * &lt; &gt;
                &quot; |
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.exist">
                A file or folder already exists with this name.
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.sameCase">
                The new name must be different (case-insensitive).
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.required">
                Can't rename without a name.
            </span>
            <span *ngIf="renameform.controls.originalname.errors?.maxlength">
                The length of the name should not be greater than {{maxFileLength}}
            </span>
            <span
                [class.hide]="err.code == -1"
                *ngFor="let err of batchView.errors"
                [innerHtml]="err.msg"
            ></span>
        </div>
    </div>
</form>
