import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogContactSupportComponent } from '../../../../account/dialog-contact-support/dialog-contact-support.component';

@Component({
    selector: 'sync-header-nosignup',
    templateUrl: './header-nosignup.component.html'
})
export class HeaderNosignupComponent implements OnInit {


    constructor(
        private modalService: NgbModal
    ) { }

    ngOnInit() {
    }


    public openContactSupport() {
        const ref = this.modalService.open(DialogContactSupportComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
    }
}
