import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { User, ErrCode } from '../../shared/models';
import { ApiService } from '../../core/api.service';
import { AuthService } from '../../auth/services/auth.service';
import { LoggerService } from '../../core/logger.service';
@Component({
  selector: 'sync-dialog-username-change',
  templateUrl: './dialog-username-change.component.html',
  styleUrls: ['./dialog-username-change.component.css']
})
export class DialogUsernameChangeComponent implements OnInit {

    @Input() user: User;

    public errcode: ErrCode;
    public openState = 1;
    public spinner: boolean;
    public curEmail: string;
    public newEmail: string;
    public password: string;

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private auth: AuthService,
        private log: LoggerService) {}

    public ngOnInit() {
        this.newEmail = '';
        this.password = '';
        this.openState = 1;
        this.errcode = undefined;
        this.spinner = false;
    }
    public async requestEmailChange() {

        this.spinner = true;
        try {
            const hashPass = await this.auth.getHashedPassword(this.user.email, this.password);
            await this.api.execute('requestemailchange', {
                newEmail: this.newEmail.toLowerCase(),
                password: hashPass
            });
            this.openState = 2;
            // this.userService.refresh();
        } catch (ex) {
            const err = ErrCode.fromException(ex);
            console.log(ex);
            this.log.e('Error trying to request an email change', err);
            if (err.code == 9429) {
                this.log.error('User is ratelimited for request email change');
                this.errcode = new ErrCode(8009, 'Rate-limited for sending too many email change requests');
            } else if (err instanceof ErrCode) {
                this.errcode = err;
            }
            this.log.error('An error occurred');
        }
        this.spinner = false;

    }
}
