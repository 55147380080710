import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ErrCode, PathListApiInput } from '../../shared/models';
import { FileItemService } from '../file-item.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { ApiService } from '../../core/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'sync-preview',
    templateUrl: './preview.component.html'
})

export class PreviewComponent implements OnInit, OnDestroy {
    @Input() public type: string; // binding from component.
    public item: sync.IFile;
    public list: sync.IFile[] = [];
    public locId: number;
    public initialized = false;
    public ctx: string;
    public upsell = false;
    public notice = 0;
    public errcode: ErrCode;
    public isPro = 0; // not used, but the template requires it to be set

    private subscription: Subscription;
    private PAGE_TITLE = 'Sync.com';

    //white label attributes
    public isWhiteLabel: boolean;
    public linkTextColor: string;
    private prevRouterState: any;
    public notPreviewable = false;

    constructor(
        private pageTitleSevice: Title,
        private fileItemService: FileItemService,
        private fileListService: FileListService,
        private linkPathListService: LinkFileListService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.prevRouterState = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.pageTitleSevice.setTitle(this.PAGE_TITLE);
        this.locId = this.route.snapshot.params['id'];
        this.ctx = this.route.snapshot.queryParams['ctx'];

        if (!this.type) {
            this.type = this.route.snapshot.params['type'];
        }
        this.initialize();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.router.routeReuseStrategy.shouldReuseRoute = this.prevRouterState;
    }

    public onStateChange(event: any) {
        this.type = event.type;
        this.upsell = event.upsell;
        this.notPreviewable = true;
        console.log('onstatechange , ', event.type, event.upsell);
        this.initialize();
    }

    private async initialize() {
        this.initialized = false;
        let dispatched = false;
        try {
            this.item = await this.fileItemService.format(
                await this.apiService.execute('pathget', {
                    sync_id: this.locId,
                })
            );
            const previewType = this.fileItemService.previewFileType(this.item);
            if (!this.notPreviewable && this.type !== previewType && this.type != 'officefse' && this.type != 'officefsv') {
                this.type = previewType;
                this.changeUrlType(this.type);
            }

            this.pageTitleSevice.setTitle(
                `${this.PAGE_TITLE} - ${this.item.name}`
            );
            if (
                (this.type != 'default' && this.type != 'office' && this.type != 'officefse' && this.type != 'officefsv') &&
                !this.fileItemService.isPreviewable(this.item)
            ) {
                this.type = 'default';
                this.notice = 1;
            }
            if (this.type == 'image') {
                this.subscription = this.fileListService
                    .getListSubscription()
                    .subscribe((data) => {
                        if (data && data.pathlist && data.pathlist.length) {
                            this.list = data.pathlist.filter((val) => {
                                return this.fileItemService.isImage(val);
                            });
                            this.initialized = true;
                        } else if (!dispatched) {
                            const input = new PathListApiInput();
                            input.sync_id = this.item.pid;
                            input.hist_id = 0;
                            input.showdeleted = 0;
                            this.fileListService.dispatch(input);
                            dispatched = true;
                        }
                    });
            } else {
                this.initialized = true;
            }

            //white label attributes
            this.subscription = this.linkPathListService
                .getSubscription()
                .subscribe((data) => {
                    if (data.loaded && data.sorted) {
                        if (data.image_cachekey) {
                            this.isWhiteLabel = true;
                            this.linkTextColor = data.link_text_color;
                        }
                    }
                });
        } catch (ex) {
            this.errcode = ErrCode.fromException(ex);
            this.type = 'error';
            this.initialized = true;
        }
    }

    changeUrlType(newType: string): void {
        const fullUrl = this.router.url;

        // Replace the last segment (e.g., /default) with the new type (e.g., /doc)
        // Regex: /\/[^\/]*$/ matches the last segment after the final '/' in the URL
        // Example: /file/15000002/view/default -> /file/15000002/view/doc
        const newUrl = fullUrl.replace(/\/[^\/]*$/, `/${newType}`);

        this.router.navigateByUrl(newUrl);
    }
}
