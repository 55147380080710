
import {throwError as observableThrowError, Observable, of } from 'rxjs';
import { map, retryWhen, concat, delay, take, switchMap, timeout, retry } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SyncCryptService } from './crypt/sync-crypt.service';
import { ErrCode } from '../shared/models';
import { BaseApiOutput } from '../shared/models/api';
import { UrlService } from './url.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private crypt: SyncCryptService,
        private http: HttpClient,
        private injector: Injector,
        private log: LoggerService,
        private url: UrlService,
        private router: Router
    ) { }

    public exec<T extends BaseApiOutput>(name: string, params: any, retryEnabled = true): Observable<T> {
        const url = this.url.mkApi(environment.apihosts[0], name);
        return this.http
            .post<T>(url, params)
            .pipe(
                map((result) => {
                    if (result.success === 0) {
                        this.log.error(`200 ${url} error occurred`);
                        switch (result.error_code) {
                            case 8050:
                            case 8051:
                                // suspended
                                this.router.navigate(['/account-suspended'], { queryParams:  result.data });
                                break;
                            case 8054:
                                // incomplete
                                this.router.navigate(['/account-suspended'], { queryParams: { ...result.data, m: 2 } });
                                break;
                            case 8055:
                                // migrating
                                this.router.navigate(['/account-suspended'], { queryParams:  { ...result.data, m: 1 } });
                                break;
                            case 8052:
                                // accounting hold
                                this.router.navigate(['/accounting-hold'], { queryParams:  result.data });
                                break;

                            case 8053:
                                // accounting hold
                                this.router.navigate(['/account-closed']);
                                break;
                            default:
                                this.log.error('Received an error code from the server ' + result.error_code);
                                break;
                        }
                        throw ErrCode.fromApiLegacy(result);
                    } else if (result.success === 1 && result.logout === 1 && name != 'sessiondelete') {
                        this.log.error(`Received a logout from API on ${url}`);
                        this.handleLogout();
                        return;
                    }
                    this.log.info(`200 ${url}  successful`);
                    return result;
                }),
                retryWhen((errors) => {
                    return errors.pipe(
                        delay(1000),
                        take(3),
                        switchMap((httpErr) => {
                            if ((httpErr instanceof HttpErrorResponse) && retryEnabled === true) {
                                let shouldRetry = of(httpErr) ; //= observableThrowError(httpErr);
                                this.log.error(`ERROR INPUT: ${JSON.stringify(params)}`);
                                this.log.error(`ERROR OUTPUT: ${httpErr.status} ${httpErr.statusText} :: ${httpErr.url}`);
                                // this.log.error(`Error output ${JSON.stringify(response)}`);
                                switch (httpErr.status) {
                                    case 401:
                                        //this.handleLogout();
                                        shouldRetry = observableThrowError(httpErr);
                                        break;
                                    case 400:
                                    case 429:
                                    case 500:
                                        // do not retry, this is an error
                                        shouldRetry = observableThrowError(httpErr);
                                        break;
                                    case 501:
                                    case 502:
                                    case 503:
                                    case 504:
                                        // this may be a temporary error
                                        // shouldRetry = observableThrowError(httpErr);
                                        break;
                                    default:
                                        // default is to retry
                                        shouldRetry = observableThrowError(httpErr);
                                        break;
                                }
                                return shouldRetry;
                            } else {
                                // httpErr is an API result
                                return observableThrowError(httpErr);
                            }
                        }),
                        concat(observableThrowError(new ErrCode(9000)))
                    );
            })
        );
    }
    /**
     * This sends an unsigned request ot the API
     * @param name the API name
     * @param params the API input parameters
     */
    public async send<T extends BaseApiOutput>(name: string, params: any) {
        try {
            const response = await this.exec<T>(name, params).toPromise();
            if (!response) {
                throw new ErrCode(9000);
            }
            return <T>response;
        } catch (err) {
            this.handleError(name, params, err);
        }
    }


    /**
     * This builds the HTTP request to the EXTAPI.
     * Retry is enabled always. Regardless of the error produced from the ExtApi
     */
    private execExt<T extends BaseApiOutput>(path: string, method: string, params?: any): Observable<T> {
        const retryEnabled = true;
        const url = this.url.mkExtApiUrl(path);
        // console.log(`Extapi requests to ${url}`);
        if (method === 'GET') {
            // Note: params for GET are query params
            return this.http
            .get<T>(url, {params: params})
            .pipe(
                map((result) => {
                    if (result.success === 0) {
                        this.log.error(`EXTAPI 200 ${url} error occurred`);
                        this.log.error('EXTAPI Received an error code from the server ' + result.error_code);
                        throw ErrCode.fromApiLegacy(result);
                    }
                    this.log.info(`200 ${url}  successful`);
                    return result;
                }),
                retryWhen((errors) => {
                    return errors.pipe(
                        delay(1000),
                        take(3),
                        switchMap((httpErr) => {
                            if ((httpErr instanceof HttpErrorResponse) && retryEnabled === true) {
                                let shouldRetry = of(httpErr) ; //= observableThrowError(httpErr);
                                this.log.error(`EXTAPI ERROR INPUT: ${JSON.stringify(params)}`);
                                this.log.error(`EXTAPI ERROR OUTPUT: ${httpErr.status} ${httpErr.statusText} :: ${httpErr.url}`);
                                // this.log.error(`Error output ${JSON.stringify(response)}`);

                                if (httpErr.status > 300 && httpErr.status < 500) {
                                    // Throw on 3xx and 4xx
                                    shouldRetry = observableThrowError(httpErr);
                                } else {
                                    // Retry on 5xx
                                }
                                return shouldRetry;
                            } else {
                                // httpErr is an API result
                                return observableThrowError(httpErr);
                            }
                        }),
                        concat(observableThrowError(new ErrCode(9000)))
                    );
                })
            );
        } else if (method === 'POST' ) {
            // Note: params for POST are the body
            return this.http
            .post<T>(url, params)
            .pipe(
                map((result) => {
                    if (result.success === 0) {
                        this.log.error(`EXTAPI 200 ${url} error occurred`);
                        this.log.error('EXTAPI Received an error code from the server ' + result.error_code);
                        throw ErrCode.fromApiLegacy(result);
                    }
                    this.log.info(`200 ${url}  successful`);
                    return result;
                }),
                retryWhen((errors) => {
                    return errors.pipe(
                        delay(1000),
                        take(3),
                        switchMap((httpErr) => {
                            if ((httpErr instanceof HttpErrorResponse) && retryEnabled === true) {
                                let shouldRetry = of(httpErr) ; //= observableThrowError(httpErr);
                                this.log.error(`EXTAPI ERROR INPUT: ${JSON.stringify(params)}`);
                                this.log.error(`EXTAPI ERROR OUTPUT: ${httpErr.status} ${httpErr.statusText} :: ${httpErr.url}`);
                                // this.log.error(`Error output ${JSON.stringify(response)}`);

                                if (httpErr.status > 300 && httpErr.status < 500) {
                                    // Throw on 3xx and 4xx
                                    shouldRetry = observableThrowError(httpErr);
                                } else {
                                    // Retry on 5xx
                                }

                                return shouldRetry;
                            } else {
                                // httpErr is an API result
                                return observableThrowError(httpErr);
                            }
                        }),
                        concat(observableThrowError(new ErrCode(9000)))
                    );
                })
            );
        }
    }

    /**
     * This sends an unsigned request to the EXTERNAL API
     * @param name the API name
     * @param params the API input parameters
     */
    public async sendExt<T extends BaseApiOutput>(path: string, method: string, params?: any): Promise<T> {
        try {
            // const input = await this.crypt.signApiReq(params);
            const response = await this.execExt<T>(path, method, params).toPromise();
            if (!response) {
                throw new ErrCode(9000);
            }
            return <T>response;
        } catch (err) {
            this.handleError(name, params, err);
        }
    }

    // sends a signed request
    public async execute<T extends BaseApiOutput>(name: string, params: any, retry = true): Promise<T> {
        try {
            const input = await this.crypt.signApiReq(params);
            const response = await this.exec<T>(name, input, retry).toPromise();
            if (!response) {
                throw new ErrCode(9000);
            }
            return <T>response;
        } catch (err) {
            this.handleError(name, params, err);
        }
    }

    public async fetchText(url: string) {
        const response = await this.http.get(url, { observe: 'response', responseType: 'text' }).toPromise();
        return response.body;
    }
    public async fetchThumbs(items: any) {
        const response = await this.http.post(
            this.url.mkDownloadMulti(),
            { items: items },
            { responseType: 'arraybuffer' }).toPromise();
        return response;
    }
    public fetchFile(tItem: sync.ITransferItemDownload, offset: number, reqLength: number, retry = 0) {

        const req = new HttpRequest(
            'GET',
            this.url.mkDownloadUrl(tItem, offset, reqLength, retry),
            {
                reportProgress: true,
                responseType: 'arraybuffer',
            }
        );

        return this.http.request(req)
        .pipe(
            retryWhen((errors) => {
                return errors.pipe(
                    delay(1000),
                    take(2),
                    switchMap((httpErr) => {
                        let shouldRetry = of(httpErr) ; //= observableThrowError(httpErr);
                        if (httpErr instanceof HttpErrorResponse) {
                            // let shouldRetry = of(httpErr);
                            console.log(httpErr);
                            this.log.warn(`Received status ${httpErr.status} ${httpErr.statusText}`);
                            switch (httpErr.status) {
                                case -1:
                                case 0:
                                        // do not retry, this is an error
                                    this.log.error('Status -1, timeout ' + tItem.cachekey);
                                    // shouldRetry = observableThrowError(new ErrCode(7101));
                                    break;
                                case 404:
                                    this.log.error('404 error ' + tItem.cachekey);
                                    shouldRetry = observableThrowError(new ErrCode(7102));
                                    break;
                                case 500:
                                case 501:
                                case 502:
                                case 503:
                                case 504:
                                    // this may be a temporary error
                                    // shouldRetry = observableThrowError(httpErr);
                                    break;
                                case 510:
                                    this.log.error('Blobchunk failed for ' + tItem.cachekey);
                                    shouldRetry = observableThrowError(new ErrCode(7103));
                                    break;
                                default:
                                    // default is to retry
                                    this.log.error('Unknown error occurred ' + tItem.cachekey);
                                    shouldRetry = observableThrowError(new ErrCode(7100));
                                    break;
                            }
                            return shouldRetry;
                        } else {
                            // httpErr is an API result
                            return observableThrowError(httpErr);
                        }
                    }),
                    concat(observableThrowError(new ErrCode(7100)))

                );
            })
        );
    }

    public fetchFilePlain(tItem: sync.ITransferItemDownload, retry = 0): Promise<ArrayBuffer> {

        const req = new HttpRequest(
            'GET',
            tItem.renderFile.url,
            {
                reportProgress: false,
                responseType: 'arraybuffer',
            }
        );

        return new Promise<ArrayBuffer>(async (resolve, reject) => {

            this.http.request<ArrayBuffer>(req)
                .pipe(
                    retryWhen((errors) => {
                        return errors.pipe(
                            delay(1000),
                            take(2),
                            switchMap((httpErr) => {
                                let shouldRetry = of(httpErr) ; //= observableThrowError(httpErr);
                                if (httpErr instanceof HttpErrorResponse) {
                                    // let shouldRetry = of(httpErr);
                                    console.log(httpErr);
                                    this.log.warn(`Received status ${httpErr.status} ${httpErr.statusText}`);
                                    switch (httpErr.status) {
                                        case -1:
                                        case 0:
                                                // do not retry, this is an error
                                            this.log.error('Status -1, timeout ' + tItem.cachekey);
                                            // shouldRetry = observableThrowError(new ErrCode(7101));
                                            break;
                                        case 404:
                                            this.log.error('404 error ' + tItem.cachekey);
                                            shouldRetry = observableThrowError(new ErrCode(7102));
                                            break;
                                        case 500:
                                        case 501:
                                        case 502:
                                        case 503:
                                        case 504:
                                            // this may be a temporary error
                                            // shouldRetry = observableThrowError(httpErr);
                                            break;
                                        case 510:
                                            this.log.error('Blobchunk failed for ' + tItem.cachekey);
                                            shouldRetry = observableThrowError(new ErrCode(7103));
                                            break;
                                        default:
                                            // default is to retry
                                            this.log.error('Unknown error occurred ' + tItem.cachekey);
                                            shouldRetry = observableThrowError(new ErrCode(7100));
                                            break;
                                    }
                                    return shouldRetry;
                                } else {
                                    // httpErr is an API result
                                    return observableThrowError(httpErr);
                                }
                            }),
                            concat(observableThrowError(new ErrCode(7100)))

                        );
                    })
                )
                .subscribe( async resp => {
                    if (resp.type === HttpEventType.Response) {

                        const respBuffer = <ArrayBuffer>resp.body;
                        resolve(respBuffer);

                    } else {
                        this.log.info('Recieved HTTP event : ' + resp);
                    }
                }, err => {
                    // reject(err);
                    this.log.e('Error subscribing to fetch file in download-worker', err);
                    if (this.url.downloadhosts[retry + 1]) {
                        this.log.warn(`Retrying download on host ${this.url.downloadhosts[retry + 1]}`);
                        resolve(this.fetchFilePlain(tItem, retry + 1));
                    } else {
                        this.log.error(`Attempted ${retry} retries and failed, rejecting file`);
                        reject(err);
                    }
                });
        });


    }


    public subscribe() {
        const headers = new HttpHeaders({
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '600000',
        });
        return this.http.get(this.url.mkSubscribe(), { headers: headers })
            .pipe(
                retry(3)
            );
    }

    public subscribeJob(jobId) {
        const headers = new HttpHeaders({
            'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Pragma': 'no-cache',
            'Expires': '0',
            'timeout': '600000',
        });
        return this.http.get('/sub?cid=' + jobId, { headers: headers })
            .pipe(
                retry(99)
            );
    }


    private handleLogout() {
        const returnTo = this.url.getReturnTo();
        this.router.navigate(['/logout'], { queryParams: { return_to: returnTo } });
    }

    private handleError(name, params, err) {
        if (err instanceof HttpErrorResponse) {
            if (err.status === 429) {
                throw new ErrCode(9429, 'IP has been rate limited for 30 minutes to due to incorrect login attempts');
            } else if (err.error && err.error.error_code) {
                throw new ErrCode(err.error.error_code, err.error.error_msg);
            } else if (err.status) {
                throw new ErrCode(parseInt('9' + err.status, 10));
            } else {
                throw ErrCode.fromApiLegacy(err.error);
            }
        } else if (err.error_code != undefined && err.error_msg != undefined) {
            if (err.error_code == 0 && err.error_msg.indexOf('NO CONFIG DATABASE') > -1) {
                this.log.error('no config database ' + err.error_msg);
                throw new ErrCode(this.getCodeForShardErr(err.error_msg));
            } else {
                this.log.error(`API error ${name}`);
                this.log.error(`ERROR INPUT: ${JSON.stringify(params)}`);
                this.log.error(`ERROR OUTPUT ${JSON.stringify(err)}`);
                throw ErrCode.fromApiLegacy(err);
            }
        } else if (err instanceof ErrCode) {
            this.log.error(`API error ${name}`);
            this.log.error(`ERROR INPUT: ${JSON.stringify(params)}`);
            this.log.error(`ERROR OUTPUT ${JSON.stringify(err)}`);
            if (err.code === 1680 || err.code === 1666) {
                throw new ErrCode(err.code);
            }
            throw err;
        } else if (err.success === 0) {
            this.log.error(`ERROR INPUT: ${JSON.stringify(params)}`);
            this.log.error(`ERROR OUTPUT ${JSON.stringify(err)}`);
            throw new ErrCode(9000);
        } else {
            throw new ErrCode(9000);
        }
    }

    private getCodeForShardErr(errMsg: string): number {
        let code = 1100;
        if (errMsg.indexOf('linkcachekeys_') > -1) {
            code = 1101;
        } else if (errMsg.indexOf('invitecachekeys_') > -1)  {
            code = 1102;
        } else if (errMsg.indexOf('refcodes_') > -1) {
            code = 1103;
        } else if (errMsg.indexOf('verifyusers_') > -1) {
            code = 1104;
        }

        return code;
    }

}
