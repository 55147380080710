import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { SyncCryptService } from '../../core/crypt/sync-crypt.service';
import { FileListService } from '../../file-list/services/file-list.service';
import { FeedbackService } from '../../notifications/feedback.service';
import { DirPickerService } from '../../file-list/dir-picker.service';
import { BatchCopyService } from '../batch-copy.service';
import { NotificationsService } from '../../core/notifications.service';
import { BatchMoveService } from '../batch-move.service';
import { BlendEvent, DirtyOutKeyItemShareKey } from '../../shared/models';
import { BlendService } from 'src/app/shared/services/blend.service';

@Component({
    selector: 'sync-dialog-copy',
    templateUrl: './dialog-copy.component.html'
})
export class DialogCopyComponent implements OnInit {

    @Input() public action: string;
    @Input() public selected: sync.IFile[] = [];
    @Input() public shouldDuplicate = false;
    @Input() public parentDir: sync.IFile;
    private BatchType: sync.IBatchAction;
    public skipShareFolders = 0;
    public batchView: sync.IBatchActionView;
    public itemlist: sync.IFile[];
    public dirPickerSpinner: boolean;

    constructor(
        private feedbackService: FeedbackService,
        public activeModal: NgbActiveModal,
        private dirPickerService: DirPickerService,
        private batchCopyService: BatchCopyService,
        private notificationsService: NotificationsService,
        private batchMoveService: BatchMoveService,
        protected SyncCrypt: SyncCryptService,
        private DirtyOut: DirtyOutService,
        private FileList: FileListService,
        private blendService: BlendService
    ) {}

    async ngOnInit() {
        this.BatchType = this.action == 'copy' ? this.batchCopyService : this.batchMoveService;
        this.skipShareFolders = 0;
        this.batchView = this.BatchType.view;
        this.itemlist = this.selected;
        this.dirPickerService.view.skipSyncIds = [];
        for (let i = 0, len = this.itemlist.length; i < len; i++) {
            this.dirPickerService.view.skipSyncIds.push(this.itemlist[i].sync_id);
            if (this.itemlist[i].is_share_root && this.action !== 'copy') {
                this.skipShareFolders = 1;
            }
        }
        this.BatchType.init();
        this.dirPickerService.init(this.dirPickerService.INIT_ALL);
        if (this.shouldDuplicate && this.parentDir) {
            this.dirPickerSpinner = true;
            await this.dirPickerService.cd(this.parentDir);
        }
        this.dirPickerSpinner = false;
    }

    public isCompleted(syncId: number) {
        return this.BatchType.isCompleted(syncId);
    }

    public exists<T>(key: string, value: T) {
        return this.dirPickerService.exists(key, value);
    }
    public getDestination() {
        return this.dirPickerService.getDestination();
    }

    public removeFromList(listitem: sync.IFile) {
        for (let i = this.itemlist.length - 1; i >= 0; i--) {
            if (this.itemlist[i].sync_id == listitem.sync_id) {
                this.itemlist.splice(i, 1);
                break;
            }
        }
    }

    public getActionName() {
        if (this.shouldDuplicate && this.dirPickerService.view.selected && this.dirPickerService.view.selected.sync_id === this.itemlist[0].pid && this.action == 'copy') {
            return 'duplicate';
        } else {
            return this.action;
        }
    }

    public disableExecute(): boolean {
        return (
            this.batchView.spinner > 0 ||
            !this.dirPickerService.view.selected ||
            !this.dirPickerService.view.selected.sync_id ||
            (this.itemlist.length > 0 && this.dirPickerService.view.selected.sync_id === this.itemlist[0].pid && !this.shouldDuplicate)
        );
    }

    public showRow(item: sync.IFile): boolean {
        for (let i = this.itemlist.length - 1; i >= 0; i--) {
            if (item.search_name == this.itemlist[i].name.toLowerCase()) {
                return false;
            }
        }
        return true;
    }

    public sortList(a, b) {
        if (a.type < b.type) {
            return -1;
        } else if (a.type > b.type) {
            return 1;
        } else {
            if (a.search_name < b.search_name) {
                return -1;
            } else if (a.search_name > b.search_name) {
                return 1;
            }
        }
    }

    private async getDuplicateFileName(filename: string) {
        const data = await this.FileList.getPathList();

        const filesInCurrentFolder = await Promise.all(data.map(async file => {
            return await this.SyncCrypt.filenameDecrypt(file.enc_name);
        }));

        const dotIndex = filename.lastIndexOf('.');
        let newFileName = filename;
        if (dotIndex == -1) {
            newFileName = filename + ' copy';
            while (filesInCurrentFolder.includes(newFileName)) {
                newFileName += ' copy';
            }
            return newFileName;
        } else {
            newFileName = filename.substring(0, dotIndex);
            while (filesInCurrentFolder.includes(newFileName + filename.substring(dotIndex))) {
                newFileName += ' copy';
            }
            return newFileName + filename.substring(dotIndex);
        }
    }

    public async execute() {
        const sync_id_array: number[] = [],
            list = this.itemlist
                ? this.itemlist.sort((a, b) => this.sortList(a, b))
                : [],
            duplicateNameMap: { [key: number]: sync.INameData } = {};
        let files = 0,
            directories = 0,
            sharedata: DirtyOutKeyItemShareKey[];

        try {
            for (let i = list.length - 1; i >= 0; i--) {
                if (list[i].pid == this.dirPickerService.view.selected.sync_id && this.action != 'copy') {
                    this.BatchType.view.errors.push({ code: 3610 }); //'Cannot ' + ctrl.action + ' to the same location');
                    return;
                }

                sync_id_array.push(list[i].sync_id);
                if (list[i].type && list[i].type.toUpperCase() === 'DIR') {
                    directories++;
                } else {
                    files++;
                }

                if (this.shouldDuplicate && this.dirPickerService.view.selected && this.dirPickerService.view.selected.sync_id === list[i].pid && this.action == 'copy') {
                    this.batchView.spinner = 1;
                    const duplicateName = await this.getDuplicateFileName(list[i].name);
                    if (!sharedata) {
                        sharedata = await this.DirtyOut.getShareKeyDataForSyncId(list[i].pid);
                    }
                    const share_names = await this.DirtyOut.getKeyArrayForSyncId(sharedata, list[i].pid, duplicateName);

                    const new_name = await this.SyncCrypt.filenameEncrypt(duplicateName);
                    duplicateNameMap[list[i].sync_id] = {
                        share_names,
                        new_name
                    };
                    this.batchView.spinner = 0;
                }
            }

            await this.notificationsService.stopNotificationLoop();
            await (<any>this.BatchType).execute(
                sync_id_array,
                this.dirPickerService.view.selected.sync_id,
                this.action == 'move',
                duplicateNameMap
            );
            const destination = this.getDestination();
            const actionMsg = this.action === 'move' ? 'moved' : (this.getActionName() == 'duplicate' ? 'duplicated' : 'copied');
            let successMsg = `${list.length} file${
                files > 1 ? 's' : ''
            } ${actionMsg} to ${destination}`;
            if (directories && files) {
                successMsg = `${list.length} items ${actionMsg} to ${destination}`;
            } else if (directories) {
                successMsg = `${list.length} folder${
                    directories > 1 ? 's' : ''
                } ${actionMsg} to ${destination}`;
            }
            this.feedbackService.setFeedback('success', successMsg, 10000);
            switch (this.action) {
                case 'move':
                    this.blendService.track(BlendEvent.MOVE_ITEMS, { num_items: list.length });
                    break;
            }
        } catch (error) {
            const destination = this.getDestination();
            let errorMsg = `${list.length} file${
                files > 1 ? 's' : ''
            } failed to ${this.action} to ${destination}`;
            if (directories && files) {
                errorMsg = `${list.length} items failed to ${this.action} to ${destination}`;
            } else if (directories) {
                errorMsg = `${list.length} folder${
                    directories > 1 ? 's' : ''
                } failed to ${this.action} to ${destination}`;
            }
            this.feedbackService.setFeedback('danger', errorMsg, 10000);
        } finally {
            this.notificationsService.startNotificationLoop();
            this.activeModal.close();
        }
    }

    public closeDialog() {
        if (this.BatchType.view.spinner !== 0) {
            const c = window.confirm('Are you sure you want to cancel?');
            if (c) {
                this.BatchType.cancel();
                this.activeModal.close();
            }
        } else {
            this.activeModal.close();
        }
    }
}
