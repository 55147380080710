import { BaseApiOutput } from './base-api.model';

export class LinkDeleteApiOutput extends BaseApiOutput {
}

export class LinkDeleteApiInput {

    command = 'linkdelete';

    sync_id: number;
}


