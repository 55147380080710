import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';

import { FileListService } from '../services/file-list.service';
import { PathListApiInput, ErrCode, EncFile, User, BlendEvent, PermissionActions } from '../../shared/models';
import { FileSelectionService } from '../services/file-selection.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../core/user.service';
import { LoggerService } from '../../core/logger.service';
import { ThumbnailsService } from '../services/thumbnails.service';
import { DispatchService } from '../../core/dispatch.service';
import { ShareService } from '../../shares/share.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogShareAppListComponent } from '../../shares/dialog-share-app-list/dialog-share-app-list.component';
import { DialogCreateBlankDocumentComponent } from '../dialog-create-blank-document/dialog-create-blank-document.component';
import { ZuoraAccountService } from '../../account/services/zuora-account.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { BuildTransferItemService } from '../../transfer/services/build-transfer-item.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { RunUploadFolderService } from 'src/app/transfer/services/run-upload-folder.service';
import { UploadFolderService } from 'src/app/transfer/upload-folder.service';
import { FileItemService } from 'src/app/file/file-item.service';
import { FeedbackService } from 'src/app/notifications/feedback.service';
import { BlendService } from 'src/app/shared/services/blend.service';
import { SkuService } from '../../shared/services/sku.service';
import { StarredListService } from '../services/starred-list.service';

@Component({
    selector: 'sync-file-list',
    templateUrl: './file-list.component.html',
})
export class FileListComponent implements OnInit, OnDestroy {
    @Input('defaultSyncId') private defaultSyncId: number;
    public curDate = new Date();
    public isLoaded = false;
    public list: Array<sync.IFile>;
    public parents: EncFile[];
    public cwd: sync.IFile;
    public isReadOnly = 0;
    public predicate = 'default';
    public predicateReverse: boolean;
    public paginate: sync.IPaginate = {
        total: 0,
        currentPage: 0,
        itemsPerPage: 100,
        numPages: 0,
        viewOffset: 0,
        viewEnd: 0,
    };
    public filter: string | { [k: string]: any } = '';
    public isVault: boolean;
    public is_starred: boolean;
    public isRoot: boolean;
    public showDeleted: number;
    public showfavorites = false;
    public syncId: number;
    public histId: number;
    public page = 1;
    public loadingProgress = 0;
    public transferView: sync.ITransferView;
    public folderTransferView: sync.IUploadFolderView;
    public showLoading = false;
    public showFilter = false;
    public showGrid = false;
    public showFileMenu = false;
    public showGetstarted = false;
    public showRightMenu = false;
    public user: User;
    private subscription: Subscription;
    private userSubscription: Subscription;
    private eventSubscription: Subscription;
    private starredListSubscription: Subscription;
    public errcode: ErrCode;

    public hasAppsNewFeature = false;
    public showNewFolder: boolean;
    public dropdown: NgbDropdown;
    public showInfo = false;
    public showFreeTrialReminder = false;
    public showPayNowBanner = false;
    public planExpiry: number;
    public filteredIds: any[];
    public permissionActions = PermissionActions;
    public total_favorite = 0;
    public is_FreeUser: boolean;
    public is_tagEnabled = true;
    public favSpinner: boolean;
    public previousUrl: string;
    public saveRouterStrategyReuseLogic: any;

    @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

    @ViewChild('ngbdd') ngbdd: NgbDropdown;

    constructor(
        private broadcastService: BroadcastService,
        private dispatchService: DispatchService,
        private fileListService: FileListService,
        private fileSelectionService: FileSelectionService,
        private loggerService: LoggerService,
        private thumbnailsService: ThumbnailsService,
        private buildTransferItemService: BuildTransferItemService,
        public userService: UserService,
        private modalService: NgbModal,
        private shareService: ShareService,
        private _sanitizer: DomSanitizer,
        private zuora: ZuoraAccountService,
        private store: Store<fromRoot.State>,
        private route: ActivatedRoute,
        private router: Router,
        private runUploadFolderService: RunUploadFolderService,
        private uploadFolderService: UploadFolderService,
        private fileItemService: FileItemService,
        private feedbackService: FeedbackService,
        private blendService: BlendService,
        private skuService: SkuService,
        private starredListService: StarredListService
    ) { }


    async ngOnInit() {
        this.saveRouterStrategyReuseLogic = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) => false;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Check if it's a back navigation
                if (this.isBackNavigation()) {
                    // Reload your component or perform any necessary actions
                    this.reloadComponent();
                }
            }
        });
        if (this.route.snapshot.queryParams['sync_id']) { this.router.navigate(['/files']); }
        this.userSubscription = this.store
            .pipe(select(fromRoot.getAuthUser))
            .subscribe((data) => {
                this.user = data;
            });

        this.route.data.subscribe(data => {
            if (data.defaultSyncIdKey) {
                this.defaultSyncId = this.userService.get(data.defaultSyncIdKey);
            }
        });
        // console.warn('FILE LIST CONTROLLER');
        this.showGetstarted = !this.userService.get('has_gettingstarted');

        this.transferView = this.buildTransferItemService.view;
        this.folderTransferView = this.runUploadFolderService.view;

        this.broadcastService.on('event:hide.tools').subscribe(() => {
            this.showNewFolder = false;
            this.showFilter = false;
            this.selectAll(false);
        });
        this.broadcastService.on('event:keydown:45').subscribe(() => (this.showNewFolder = true));
        this.eventSubscription = this.broadcastService.on('event:file-list.reload').subscribe((params) => this.reload(params));
        this.broadcastService.on('event:file-list.rename').subscribe((params: any) => this.setRenameState(params));

        this.histId = -1;
        this.isVault = this.router.url.includes('/vault') ? true : false;
        this.is_starred = this.router.url.includes('/starred') ? true : false;
        this.favSpinner = this.is_starred ? true : false;
        this.fileListService.getCwdSubscription().subscribe((data) => {
            if (data) {
                this.cwd = data;
            }
        });
        this.isRoot = this.syncId == this.userService.get('syncus_sync_id') || this.syncId == this.userService.get('web_sync_id');

        this.subscription = this.fileListService
            .getListSubscription()
            .subscribe((data) => {
                // console.log('======================');
                // console.log('file list subscription');
                // console.log(data);
                // @TODO once this is ported to ng6, most of the subscription can be
                // tightened up to select specific areas to prevent excessive loading

                if (data.cwd) {
                    this.cwd = data.cwd;
                    this.isReadOnly = this.cwd.is_readonly;
                    this.isRoot = data.cwd.sync_id == this.defaultSyncId;
                    this.syncId = this.cwd.sync_id;
                    // if (data.cwd.name === 'Camera Upload' && data.cwd.pid == this.defaultSyncId) {
                    //     this.predicateReverse = false;
                    //     this.setPredicate('usertime');
                    // }
                }
                if (data.parents) {
                    this.parents = data.parents;
                }
                if (data.progress > 0 && data.progress < 92) {
                    this.showLoading = true;
                } else if (data.progress >= 100) {
                    this.showLoading = false;
                } else {
                    this.showLoading = false;
                }
                this.loadingProgress = data.progress;

                if (data.error) {
                    this.errcode = data.error;
                }
                if (data.loaded && data.sorted) {
                    this.isRoot = this.syncId == this.userService.get('syncus_sync_id') || this.syncId == this.userService.get('web_sync_id');
                    this.total_favorite = data.total_favorite;
                    if (data.pathlist.length && this.shouldLoadList(data)) {
                        if (data.showdeleted) {
                            this.list = data.pathlist.filter((item) => {
                                if (item.is_deleted) {
                                    return true;
                                }
                            });
                        } else {
                            this.list = data.pathlist;
                        }
                        if (
                            this.is_starred &&
                            !this.starredListSubscription &&
                            this.syncId == this.userService.get('syncus_sync_id')
                        ) {
                            this.showFavoriteList();
                        }
                    } else {
                        this.list = [];
                    }
                    // this.isLoaded = true;
                    this.histId = data.hist_id;
                    this.paginate.total = this.list.length;
                    this.paginate.numPages = Math.ceil(
                        this.list.length / this.paginate.itemsPerPage
                    );
                    if (
                        this.route.snapshot.queryParams['page'] &&
                        parseInt(this.route.snapshot.queryParams['page'], 10) <=
                        this.paginate.numPages
                    ) {
                        this.paginate.currentPage =
                            parseInt(this.route.snapshot.queryParams['page'], 10) || 1;
                    } else {
                        this.paginate.currentPage = 1;
                    }
                    this.thumbnailsService.getThumbs(this.list, this.paginate);
                    this.isLoaded = data.loaded;
                }
            });
        this.predicate = this.route.snapshot.queryParams['predicate']
            ? this.route.snapshot.queryParams['predicate']
            : 'default';
        this.predicateReverse =
            this.route.snapshot.queryParams['predicateReverse'] == 'false' ? false : true;
        this.fileListService.sortList(this.predicate, this.predicateReverse);

        this.showGrid = this.route.snapshot.queryParams['view'] == 'grid';

        const input = new PathListApiInput();
        input.sync_id = this.route.snapshot.params['sync_id']
            ? parseInt(this.route.snapshot.params['sync_id'], 10)
            : this.defaultSyncId;
        input.hist_id = parseInt(this.route.snapshot.queryParams['histId'], 10) || 0;
        input.showdeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
        input.is_vault = this.isVault;
        this.fileListService.dispatch(input);

        // this.paginate.currentPage = parseInt(this.route.snapshot.queryParams['page'], 10) || 1,
        this.showDeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
        this.histId = parseInt(this.route.snapshot.queryParams['histId'], 10) || 0;
        if (this.route.snapshot.queryParams['loc']) {
            this.dispatchService.run();
        }
        this.syncId = this.route.snapshot.params['sync_id']
            ? parseInt(this.route.snapshot.params['sync_id'], 10)
            : this.defaultSyncId;
        this.isRoot = this.defaultSyncId == this.syncId;
        // this.getList(this.syncId);

        this.hasAppsNewFeature =
            this.userService.get('new_feature') === 1 &&
            this.userService.get('app_count') > 0;
        if (
            this.userService.get('is_on_trial') &&
            this.userService.checkPermission(PermissionActions.MANAGE_BILLING)
        ) {
            this.loadBillingDetails();
        }
        this.is_FreeUser = await this.skuService.isFreeUser();

    }

    filterList() {
        const filterString = this.filter ? this.filter.toLowerCase() : '';
        let filter = this.filter
            ? this.list.filter((item: any) =>
                item.name.toLowerCase().includes(filterString) ||
                item.date_medium.toLowerCase().includes(filterString) ||
                item.filesize.toLowerCase().includes(filterString)

            )
            : this.list;
        this.filteredIds = this.filter ? filter.map(item => item.id) : [];
        filter = filter.slice(
            (this.paginate.currentPage - 1) * this.paginate.itemsPerPage,
            (this.paginate.currentPage - 1) * this.paginate.itemsPerPage +
            this.paginate.itemsPerPage
        );

        const filterCount = filter.length;
        // this.loggerService.d(
        //     'fitler count changed ' + typeof this.filter + ' -- ' + filterCount
        // );
        if (typeof this.filter == 'string' && this.filter.length > 1) {
            //this.paginate.total = filterCount;
            this.paginate.numPages = Math.ceil(
                filterCount / this.paginate.itemsPerPage
            );
        } else if (this.list && this.list.length) {
            this.paginate.total = this.list.length;
            this.paginate.numPages = Math.ceil(
                this.list.length / this.paginate.itemsPerPage
            );
        } else {
            // this.Logger.warn('ended up igetSubscriptionn incorrect filterCount')
        }
        return filter;
    }

    navigateToRoot() {
        this.router.navigate(['..'], {
            relativeTo: this.route,
            queryParams: { sync_id: null },
            queryParamsHandling: 'merge'
        });
    }
    private isBackNavigation(): boolean {
        const isBack = this.router.url === this.previousUrl;
        this.previousUrl = this.router.url; // Update the previous URL for the next check
        return isBack;
    }
    private reloadComponent(): void {
        // Perform any actions needed before reloading the component
        // For example, fetch data, reset variables, etc.

        // Reload the component by navigating to the same route
        this.router.navigate([this.router.url]);
    }
    navigateToSubFolder(item) {
        this.router.navigate(['..', item.loc_id], {
            relativeTo: this.route,
            queryParams: { page: undefined },
            queryParamsHandling: 'merge'
        });
    }

    hasPermission(permissionKey) {
        return (this.userService.getPermssionByKey(permissionKey));
    }

    public trackByFn(index, item) {
        return item.sync_id;
    }

    getImage(image) {
        if (image) {
            return this._sanitizer.bypassSecurityTrustUrl(image);
        }
        return null;
    }

    openShareDetails(selected, openState) {
        const webpath = Array.isArray(selected) ? selected[0] : selected;
        const shareId = webpath.shareId || webpath.share_id;
        const state =
            !Number.isNaN(parseInt(openState, 10)) ? parseInt(openState, 10) : 0;
        this.shareService.openDetails(shareId, state);
    }

    ngOnDestroy() {
        // console.error('ON DESTROY IS CALLED ON FILE LIST CONTROLLER');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
        if (this.starredListSubscription) {
            this.starredListSubscription.unsubscribe();
        }
        this.fileListService.reset();
        this.router.routeReuseStrategy.shouldReuseRoute = this.saveRouterStrategyReuseLogic;
    }

    setRenameState(syncId: number) {
        if (syncId && syncId > 0) {
            this.list.map((cur) => {
                if (cur.sync_id == syncId) {
                    cur.rename = true;
                    return;
                }
            });
        }
    }

    reload(params: any) {
        if (this.histId && params && params.hist_id == this.histId) {
            this.loggerService.info(
                'skipping file list refresh ' +
                this.histId +
                ' - ' +
                params.hist_id
            );
            return;
        }

        if (this.isLoaded) {
            const input = new PathListApiInput();
            input.sync_id = this.cwd.sync_id;
            input.hist_id = 0;
            input.showdeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
            input.is_vault = this.isVault;
            this.isLoaded = false;
            if (this.is_starred && this.syncId == this.userService.get('syncus_sync_id')) {
                this.showFavoriteList();
                this.isLoaded = true;
            } else {
                this.fileListService.dispatch(input);
            }
        } else {
            this.loggerService.info(
                'skipping file list refresh, isLoaded = false'
            );
        }
    }

    toggleFilter() {
        this.showFilter = !this.showFilter;
        if (this.showFilter === true) {
            this.paginate.currentPage = 1;
        } else if (!this.showFilter && this.route.snapshot.queryParams['page']) {
            this.paginate.currentPage = parseInt(this.route.snapshot.queryParams['page'], 10);
        }
    }

    toggleDeleted() {
        const prev = this.router.routeReuseStrategy.shouldReuseRoute;
        const showDel = this.showDeleted == 1 ? 0 : 1;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { showDeleted: showDel },
            queryParamsHandling: 'merge'
        }).then(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = prev;
        });
    }

    changePage() {
        const params =
            this.paginate.currentPage > 1
                ? { page: this.paginate.currentPage }
                : { page: undefined };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge'
        });
        this.thumbnailsService.getThumbs(this.list, this.paginate);
    }

    getPredicateClass(field: string): string {
        let cssClass = 'fa fa-sortx text-muted';
        if (
            this.predicate &&
            this.predicate.indexOf(field) > -1 &&
            this.predicateReverse
        ) {
            cssClass = 'fa fa-sort-down';
        } else if (
            this.predicate &&
            this.predicate.indexOf(field) > -1 &&
            !this.predicateReverse
        ) {
            cssClass = 'fa fa-sort-up';
        }
        return cssClass;
    }

    setPredicate(field: string): void {
        if (this.predicate == 'name' && this.predicateReverse === true) {
            this.predicate = 'default';
            this.predicateReverse = true;
        } else {
            this.predicate = field;
            this.predicateReverse = !this.predicateReverse;
        }
        this.router.navigate([], {
            relativeTo: this.route, queryParams: {
                predicate: this.predicate,
                predicateReverse: this.predicateReverse,
            },
            queryParamsHandling: 'merge'
        });
        this.fileListService.sortList(this.predicate, this.predicateReverse);
    }

    toggleView() {
        this.broadcastService.broadcast('event:hide-tools');
        const newView = this.showGrid ? 'list' : 'grid';
        this.showGrid = !this.showGrid;
        this.router.navigate([], {
            relativeTo: this.route, queryParams: {
                view: newView,
            },
            queryParamsHandling: 'merge'
        });
    }

    hasSelected() {
        const selected = this.fileSelectionService.getSelected();
        return selected.length && this.showFileMenu;
    }

    select(item: sync.IFile) {
        item.active = true;
        this.fileSelectionService.select(item);
    }

    selectToggle(item: sync.IFile) {
        this.fileSelectionService.toggle(item);
        this.showFileMenu = this.fileSelectionService.getSelected().length > 0;
    }

    selectOne(item: sync.IFile) {
        this.selectAll(false);
        this.fileSelectionService.select(item);
    }

    openMenu(clickedDropdown: NgbDropdown) {
        this.dropdowns.toArray().forEach((el) => {
            if (el !== clickedDropdown) { el.close(); }
        });
    }

    openUploadFolderModal() {
        this.uploadFolderService.showFolderUpload();
    }

    selectType(field: string, value: any) {
        this.selectAll(false);
        this.fileSelectionService.selectType(field, value, this.filteredIds);
        this.showFileMenu = true;
    }

    selectAll(val: boolean = true): void {
        this.showFileMenu = val;
        this.fileSelectionService.selectAll(val, this.filteredIds);
    }

    public openAppList() {
        const ref = this.modalService.open(DialogShareAppListComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(() => this.fileSelectionService.getSelected()[0]);
    }

    public openCreateBlankDocumentModal() {
        const ref = this.modalService.open(DialogCreateBlankDocumentComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(() => { });
    }

    private async loadBillingDetails() {
        const data = await this.zuora.getProfileAndQueue();
        if (
            data.zprofile &&
            data.zprofile.subscription &&
            data.zprofile.subscription.termEndDate
        ) {
            this.planExpiry = this.userService.dateConversionForTrials(data.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
        }
        if (data.zprofile && data.zprofile.defaultPaymentMethod) {
            this.showFreeTrialReminder = false;
            if (!data.zprofile.autoPay) {
                this.showPayNowBanner = true;
            }
        } else {
            this.showFreeTrialReminder = true;
            this.showPayNowBanner = false;
        }
    }

    private shouldLoadList(data) {
        if (this.isRoot) {
            return (
                data.pathlist.length &&
                data.pathlist[0].is_vault === this.isVault
            );
        } else {
            return data.sync_id == +(this.route.snapshot.params['sync_id'] || 0);
        }
    }

    public itemLink(item: sync.IFile) {
        this.user = this.userService.getUser();
        const ctx =
            item.context == 'link' || item.context == 'applink' ? 'p' : 'u';
        let type = 'default';
        if (item.type == 'dir') {
            if (this.router.url.includes('files')) { return `/files/${item.sync_id}`; }
            if (this.router.url.includes('vault')) { return `/vault/${item.sync_id}`; }
        } else if (this.fileItemService.isImage(item)) {
            type = 'image';
        } else if (this.fileItemService.isText(item)) {
            type = 'text';
        } else if (
            this.fileItemService.isPDF(item) &&
            (item.context == 'link' || item.context == 'applink') &&
            item.previewonly
        ) {
            type = 'doc';
        } else if (
            this.fileItemService.isPDF(item) &&
            item.context == 'files'
        ) {
            type = 'doc';
        } else if (this.display_office(item)) {
            type = 'office';
        } else if (this.display_doc(item)) {
            type = 'doc';
        } else {
            type = 'default';
        }
        return `/file/${item.sync_id}/view/${type}`;
    }

    getQueryParams(item: sync.IFile) {
        let queryParams = {};
        if (item.type == 'dir') {
            queryParams = this.route.snapshot.queryParams;
        } else {
            for (const [key, val] of Object.entries(this.route.snapshot.queryParams)) {
                if (key === 'view' || key === 'page') {
                    queryParams[key] = val;
                }
            }
        }
        return queryParams;
    }

    private display_doc(item: sync.IFile) {
        return (
            this.fileItemService.isDocument(item) &&
            // only compat links
            ((item.compat &&
                (item.context == 'link' || item.context == 'applink')) ||
                item.context == 'files')
        );
    }

    private display_office(item: sync.IFile) {
        return (
            this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_office === 1
        );
    }

    public async showFavoriteList() {
        this.showfavorites = this.showfavorites ? false : true;
        if (this.is_starred) {
            this.subscription.unsubscribe();
            await this.starredListService.showFavorites(this.syncId);
            this.starredListSubscription = this.starredListService
                .getFavoritsList()
                .subscribe((data) => {
                    if (data.error) {
                        this.showfavorites = false;
                        this.favSpinner = false;
                        this.errcode = new ErrCode(
                            1800,
                            'Unable to access your starred right now. Please retry later.'
                        );
                    } else {
                        if (this.is_starred && this.isRoot && this.predicate == 'default') {
                            this.list = data.pathlist
                                .filter((item) => item.is_favorite)
                                .sort((a, b) => b.tag_id - a.tag_id);
                            this.favSpinner = false;
                        } else {
                            this.list = data.pathlist
                                .filter((item) => item.is_favorite);
                            this.favSpinner = false;
                        }
                    }
                });
            this.blendService.track(BlendEvent.VIEW_FAVORITE_LIST, {});
        } else {
            this.starredListSubscription.unsubscribe();
            this.broadcastService.broadcast('event:file-list.reload');
            this.showfavorites = false;
            this.favSpinner = false;
        }
    }

    public async addFavoriteTag(SyncId: number) {
        let file_extension = '', file_size = 0, mime_type = '', isDir: Boolean;
        this.starredListService.setTotalFavorite(this.total_favorite);
        try {
            if (this.is_FreeUser && this.total_favorite >= 3) {
                this.starredListService.openAccountUpgradeDialog();
            } else {
                this.list.map((item) => {
                    if (item.sync_id == SyncId && !item.is_favorite) {
                        item.is_favorite = true;
                        item.is_tagEnabled = true;
                    }
                });
                await this.starredListService.addTag([SyncId], this.user.uid);
                this.list.map((item) => {
                    if (item.sync_id == SyncId && item.is_favorite) {
                        item.is_tagEnabled = false;

                        file_extension = item.file_extension;
                        file_size += item.size;
                        mime_type = item.mime_type;
                        isDir = item.type == 'dir' ? true : false;

                    }
                });

                this.blendService.track(
                    BlendEvent.ADD_FAVORITE_TAG,
                    isDir
                        ? {}
                        : {
                            fileSize: this.fileItemService.bytesToSize(file_size),
                            mimeType: mime_type
                        }
                );
            }
        } catch (err) {
            this.feedbackService.setFeedback(
                'danger',
                `Failed to add star`,
                1801
            );
            this.broadcastService.broadcast('event:file-list.reload');
        }
    }


    public async removeFavoriteTag(sync_id: number) {
        let file_extension = '', file_size = 0, mime_type = '', isDir: Boolean;

        this.starredListService.setTotalFavorite(this.total_favorite);
        try {
            this.list.map((item) => {
                if (item.sync_id == sync_id && item.is_favorite) {
                    item.is_favorite = false;
                    item.is_tagEnabled = true;
                    file_extension = item.file_extension;
                    file_size += item.size;
                    mime_type = item.mime_type;
                    isDir = item.type == 'dir' ? true : false;
                }
            });
            await this.starredListService.removeTag([sync_id]);
            this.list.map((item) => {
                if (item.sync_id == sync_id && !item.is_favorite) {
                    item.is_tagEnabled = false;
                }
            });
            if (this.showfavorites) {
                this.starredListSubscription.unsubscribe();
                this.showfavorites = false;
                this.showFavoriteList();
            }
            this.blendService.track(
                BlendEvent.REMOVE_FAVORITE_TAG,
                isDir
                    ? {}
                    : {
                        fileSize: this.fileItemService.bytesToSize(file_size),
                        mimeType: mime_type
                    }
            );
        } catch (err) {
            this.feedbackService.setFeedback(
                'danger',
                `Failed to remove star`,
                1802
            );
            this.broadcastService.broadcast('event:file-list.reload');
        }
    }
}
