import { Component, Input, OnInit } from '@angular/core';
import { User, Role, ErrCode, Field, StandardField, MultiAdminUserData, AlertMessage, AlertMessageType, ModalCloseStates, PermissionObjects, PermissionActions, FieldIdentities } from '../../shared/models';
import { UserService } from '../../core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogMultiAdminuserSettingsComponent } from '..//dialog-multiadminuser-settings/dialog-multiadminuser-settings.component';
import { DialogMultiAdminuserAddComponent } from '../dialog-multiadminuser-add/dialog-multiadminuser-add.component';
import { MultiAdminService } from '../../core/multiadmin.service';
import { LoggerService } from '../../core/logger.service';
import { FormGroup } from '@angular/forms';
import { FieldControlService } from '../../core/field-control.service';
import { ValidateService } from '../../core/validate.service';
import { DialogMultiAdminuserDeleteComponent } from '../dialog-multiadminuser-delete/dialog-multiadminuser-delete.component';
import { UserSelectionService } from '../services/user-selection.service';
import { ApiService } from '../../core/api.service';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { ActivatedRoute, Router } from '@angular/router';


const defaultRole = new Role({
    name: '',
    permissions: [],
    hidden: false,
    editable: true,
    users: []
});
@Component({
    selector: 'sync-user-multi-admin',
    templateUrl: './user-multi-admin.component.html',
    providers: [FieldControlService, MultiAdminService]
})
export class UserMultiAdminComponent implements OnInit {
    public rolesActive = false;
    public limit: number;
    public currentUser: MultiAdminUserData;
    public currentList: MultiAdminUserData[] = [];
    public rolesList: Role[] = [];
    public filteredRoleList: Role[] = [];
    public fields: Field<string | number | boolean>[] = [];
    public user: User;
    public errcode: ErrCode;
    public roleEditorOpen = false;
    public isAddNewRole = false;
    public isRolesDataFetched = false;
    public usersInProvision = false;
    private inputValidationErr = { roleName: '' };
    private plan_product_id: string;
    private plan_rate_plan_id: string;
    private plan_rate_plan_charge_id: string;
    public permissionActions = PermissionActions;

    // Edit version of the role
    public roleObject = new Role({
        name: '',
        permissions: [],
        hidden: false,
        editable: true,
        users: []
    });
    // Unedited version of the role
    public unEditedRole = new Role({
        name: '',
        permissions: [],
        hidden: false,
        editable: true,
        users: []
    });

    public spinner = false;
    public deleteSpinner = false;
    public alerts = [];

    public form!: FormGroup;
    public showUsersLoader = false;
    public usersLimit: number;
    public roleFilter = '';
    public isUserSelected: boolean;
    public selectedUsers: MultiAdminUserData[] = [];
    public showRemoveUsersModal = false;
    public removeUsersAlert: AlertMessage;
    public updateUsersAlert: AlertMessage;
    public selectedType = '';
    public rmPurge = '0';
    public totalUsers = 0;
    public showChangeRolesModal = false;
    public role = '';
    constructor(
        private modalService: NgbModal,
        private userService: UserService,
        private multiadminService: MultiAdminService,
        private log: LoggerService,
        private fieldControlService: FieldControlService,
        private validateService: ValidateService,
        public UserSelect: UserSelectionService,
        private api: ApiService,
        private broadcastService: BroadcastService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            if (data.rolesActive) {
                this.rolesActive = data.rolesActive;
            }
        });
        if (!this.userService.checkPermission(this.permissionActions.VIEW_USERS) && !this.userService.checkPermission(this.permissionActions.VIEW_ROLES)) {
            // Error. Not a multi_admin at all. Shouldn't have navigated here (url OR button)
            this.log.error('User isn\'t allowed to view users');
            this.router.navigate(['/files']);
            return;
        }
        this.user = this.userService.getUser();

        if (this.rolesActive && !this.user.is_unlimited_plan) {
            this.rolesActive = false;
            this.log.error('User isn\'t allowed to view roles');
            this.router.navigate(['/multi-user/users']);
            return;
        }

        this.usersLimit = this.userService.MAX_USERS;
        this.roleFilter = this.route.snapshot.queryParams['selectedRole'] || '';
        this.getList();
        this.broadcastService.on('event:hide.tools').subscribe(() => this.unselectAll());
    }

    public checkPermission(permName: PermissionActions): boolean {
        return this.userService.checkPermission(permName);
    }

    public closeAlert() {
        this.alerts = [];
    }

    public resetDefaultRolesObjects() {
        this.roleObject = new Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });

        this.unEditedRole = new Role({
            name: '',
            permissions: [],
            hidden: false,
            editable: true,
            users: []
        });
    }

    public addRole() {
        const newRole = JSON.parse(JSON.stringify(defaultRole));
        this.resetDefaultRolesObjects();
        this.roleObject.name = '';
        this.roleObject.permissions = newRole.permissions;
        this.roleEditorOpen = true;
        this.isAddNewRole = true;
        this.fields = this.multiadminService.createFields(this.alterRoleData(newRole as Role), !this.isAddNewRole);
        this.form = this.fieldControlService.toFormGroup(this.fields as StandardField[]);
    }

    public editRole(role: Role) {
        const newRole = JSON.parse(JSON.stringify(role));
        this.resetDefaultRolesObjects();
        this.unEditedRole = { ...newRole };
        this.roleObject.name = newRole.name;

        this.roleObject.permissions = this.roleObject.name === this.userService.DEFAULT_ROLE ? newRole.permissions.map(perm => perm.editable = false) : newRole.permissions;
        this.roleEditorOpen = true;
        this.isAddNewRole = false;
        this.fields = this.multiadminService.createFields(this.alterRoleData(newRole as Role), (['Administrator', 'Default User'].includes(newRole.name)));
        this.form = this.fieldControlService.toFormGroup(this.fields as StandardField[]);
    }

    private alterRoleData(role: Role) {
        const manageUserPermission = {
            action: PermissionActions.MANAGE_USERS,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: PermissionObjects.ADMIN,
            value: 0
        };
        const manageRolePermission = {
            action: PermissionActions.MANAGE_ROLES,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: PermissionObjects.ADMIN,
            value: 0
        };
        const defaultLinkExpiryPermission = {
            action: PermissionActions.DEFAULT_SHARE_LINK_EXPIRY_TIME,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: PermissionObjects.SHARE,
            value: 7
        };
        const defaultLinkPasswordPermission = {
            action: PermissionActions.DEFAULT_SHARE_LINK_PASSWORD,
            editable: role.name !== this.userService.DEFAULT_ROLE,
            hidden: false,
            object: PermissionObjects.SHARE,
            value: 0
        };
        role.permissions.filter(perm => perm.object === PermissionObjects.ADMIN).forEach(perm => {
            if (perm.action === PermissionActions.VIEW_USERS && perm.value && perm.editable && !perm.hidden && manageUserPermission.value != 2) {
                manageUserPermission.value = 1;
            }
            if (perm.action === PermissionActions.ADD_EDIT_USERS && perm.value && perm.editable && !perm.hidden) {
                manageUserPermission.value = 2;
            }
            if (perm.action === PermissionActions.VIEW_ROLES && perm.value && perm.editable && !perm.hidden && manageRolePermission.value != 2) {
                manageRolePermission.value = 1;
            }
            if (perm.action === PermissionActions.ADD_EDIT_ROLES && perm.value && perm.editable && !perm.hidden) {
                manageRolePermission.value = 2;
            }
        });
        role.permissions = role.permissions.filter(perm => perm.object !== PermissionObjects.ADMIN);
        role.permissions.filter(perm => perm.object === PermissionObjects.SHARE).forEach(perm => {
            if (perm.action === PermissionActions.DEFAULT_SHARE_LINK_EXPIRY_TIME) {
                defaultLinkExpiryPermission.value = perm.value as number;
            }
            if (perm.action === PermissionActions.DEFAULT_SHARE_LINK_PASSWORD) {
                defaultLinkPasswordPermission.value = perm.value as number;
            }
        });
        role.permissions.push(manageUserPermission, manageRolePermission, defaultLinkExpiryPermission, defaultLinkPasswordPermission);
        return role;
    }

    public parentFieldPropChange(controlName: string, subFields = []) {
        const value = this.checkSubFieldHidden(controlName);
        const isCanShareLink = controlName === FieldIdentities.CANSHARELINKS;
        const isCanShareLinkExpiryRequired = controlName === FieldIdentities.CANSHARELINKEXPIRYREQUIRED;
        const isCanShareLinkPasswordRequired = controlName === FieldIdentities.CANSHARELINKPASSWORDREQUIRED;
        if (!value) {
            const shareLinkExpiry = this.form.controls[FieldIdentities.CANSHARELINKEXPIRYREQUIRED];
            if (shareLinkExpiry && (isCanShareLink)) { shareLinkExpiry.setValue(false); }

            const defaultShareLinkExpiry = this.form.controls[FieldIdentities.DEFAULTSHARELINKEXPIRYTIME];
            if (defaultShareLinkExpiry && (isCanShareLink || isCanShareLinkExpiryRequired)) { defaultShareLinkExpiry.setValue(7); }

            const shareLinkPassword = this.form.controls[FieldIdentities.CANSHARELINKPASSWORDREQUIRED];
            if (shareLinkPassword && (isCanShareLink)) { shareLinkPassword.setValue(false); }

            const defaultShareLinkPassword = this.form.controls[FieldIdentities.DEFAULTSHARELINKPASSWORD];
            if (defaultShareLinkPassword && (isCanShareLink || isCanShareLinkPasswordRequired)) { defaultShareLinkPassword.setValue(0); }
        } else {
            if (subFields.length > 0) { subFields.map(sf => this.form.controls[sf.key].setValue(sf.value)); }
        }
    }

    public checkSubFieldHidden(controlName: string) {
        let val = true;
        if (controlName !== '') {
            const fieldVal = this.form.controls[controlName].value;
            if ((typeof fieldVal === 'string' || fieldVal instanceof String) && fieldVal !== '') {
                val = fieldVal === 'true';
            } else {
                val = fieldVal;
            }
        }
        return val;
    }

    public async getList() {
        try {
            this.showUsersLoader = true;
            const result = await this.multiadminService.getUserList();
            this.totalUsers = result.users.length;
            if (this.totalUsers < 1) {
                this.showUsersLoader = false;
            }
            this.currentList = [];
            if (!this.isRolesDataFetched) {
                this.getRolesList();
                this.getDefaultPermissions();
            }
            this.currentUser = null;
            result.users.forEach(user => {
                if (!this.roleFilter || user.roles.toString().includes(this.roleFilter)) {
                    if (user.email === this.user.email && user.user_id === this.user.id) {
                        this.currentUser = user;
                    } else {
                        user.user_status_str = this.multiadminService.statusHandler(user.user_status_str);
                        this.currentList.push(user);
                    }
                }
            });
            let atLeastOnePending = false;
            this.currentList.forEach((user) => user.user_status_str === 'pending' || user.user_status_str === 'working' ? (atLeastOnePending = true, this.usersInProvision = true) : null);
            this.UserSelect.setList(this.currentList);
            this.checkSelected();
            if (!atLeastOnePending) {
                this.usersInProvision = false;
            }
            this.limit = result.user_limit;
            this.plan_product_id = result.plan_product_id;
            this.plan_rate_plan_id = result.plan_rate_plan_id;
            this.plan_rate_plan_charge_id = result.plan_rate_plan_charge_id;
            this.isRolesDataFetched = true;
            this.showUsersLoader = false;
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
    }
    public async getRolesList() {
        try {
            const response = await this.multiadminService.getRoles();
            this.rolesList = (response as Role[])['roles'] as Role[];
            this.filteredRoleList = this.rolesList.filter(role => role.name !== 'Administrator');
            this.role = this.filteredRoleList.length ? this.filteredRoleList[0].name : '';
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
    }
    public async getDefaultPermissions() {
        try {
            const resultUser = await this.multiadminService.getCurrentUserPermissions(this.user.id);
            defaultRole.permissions = resultUser.permissions;
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
    }

    public async deleteRole() {
        this.errcode = null;
        if (!this.unEditedRole.name) { return; }

        const ref = this.modalService.open(DialogMultiAdminuserDeleteComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
            size: 'lg'
        });

        ref.componentInstance.openState = 3;
        const isConfirm: boolean = await ref.result;
        if (!isConfirm) { return; }

        try {
            this.deleteSpinner = true;
            const isSuccess = await this.multiadminService.deleteRole(this.unEditedRole.name);
            await this.getRolesList();
            if (isSuccess) { this.createAlert('success', 'Role deleted'); } else { this.createAlert('danger', 'Failed to delete role'); }
            this.deleteSpinner = false;
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
            this.deleteSpinner = false;
        }
        this.roleEditorOpen = false;
        this.resetDefaultRolesObjects();
    }

    public async addUpdateRole() {
        this.errcode = null;
        const oldRoleObject = { ...this.roleObject } as Role;
        this.roleObject.name = this.form.controls[FieldIdentities.ROLENAME].value;

        // Make sure there is no two roles with same name
        if (!this.isAddNewRole && this.roleObject.name !== oldRoleObject.name) {
            const isEditRoleNameNotUnique = this.rolesList.filter(item => item.name === this.roleObject.name).length > 0;
            if (isEditRoleNameNotUnique) {
                this.inputValidationErr.roleName = 'This name is already in use, please try a different name';
                return;
            }
        }

        if (!this.validateService.isAlphaNumeric(this.roleObject.name)) {
            this.errcode = new ErrCode(1701);
            return;
        }
        this.spinner = true;
        this.roleObject.permissions.forEach(permission => {
            if (permission.object === PermissionObjects.METADATA) {
                permission.value = Date.now();
            } else {
                for (const [key] of Object.entries(this.form.controls)) {
                    const val = this.form.controls[key].value;
                    switch (key) {
                        case FieldIdentities.CANMANAGEUSERS:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.VIEW_USERS) {
                                permission.value = val >= 1;
                            }
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.ADD_EDIT_USERS) {
                                permission.value = val == 2;
                            }
                            break;
                        case FieldIdentities.CANMANAGEROLES:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.VIEW_ROLES) {
                                permission.value = val >= 1;
                            }
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.ADD_EDIT_ROLES) {
                                permission.value = val == 2;
                            }
                            break;
                        case FieldIdentities.CANVIEWUSERS:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.VIEW_USERS) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANADDEDITUSERS:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.ADD_EDIT_USERS) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANVIEWROLES:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.VIEW_ROLES) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANADDEDITROLES:
                            if (permission.object === PermissionObjects.ADMIN && permission.action === PermissionActions.ADD_EDIT_ROLES) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANVIEWBILLING:
                            if (permission.object === PermissionObjects.BILLING && permission.action === PermissionActions.VIEW_BILLING) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANMANAGEBILLING:
                            if (permission.object === PermissionObjects.BILLING && permission.action === PermissionActions.MANAGE_BILLING) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANPURGEFILES:
                            if (permission.object === PermissionObjects.FILES && permission.action === PermissionActions.PURGE_FILES) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANFORCE2FALOGIN:
                            if (permission.object === PermissionObjects.SETTINGS && permission.action === PermissionActions.FORCE_2FA_ON_FIRST_LOGIN) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANSHARELINKS:
                            if (permission.object === PermissionObjects.SHARE && permission.action === PermissionActions.SHARE_LINKS) {
                                permission.value = val;
                            }
                            break;
                        case FieldIdentities.CANSHARELINKEXPIRYREQUIRED:
                            if (permission.object === PermissionObjects.SHARE && permission.action === PermissionActions.SHARE_LINK_EXPIRY) {
                                if (typeof val === 'string') {
                                    permission.value = val === 'true'; // dynamic radio input gives value only in string
                                } else {
                                    permission.value = val;
                                }
                            }
                            break;
                        case FieldIdentities.DEFAULTSHARELINKEXPIRYTIME:
                            if (permission.object === PermissionObjects.SHARE && permission.action === PermissionActions.SHARE_LINK_EXPIRY) {
                                permission.subValue = val;
                            }
                            break;
                        case FieldIdentities.CANSHARELINKPASSWORDREQUIRED:
                            if (permission.object === PermissionObjects.SHARE && permission.action === PermissionActions.SHARE_LINK_PASSWORD) {
                                if (typeof val === 'string') {
                                    permission.value = val === 'true'; // dynamic radio input gives value only in string
                                } else {
                                    permission.value = val;
                                }
                            }
                            break;
                        case FieldIdentities.DEFAULTSHARELINKPASSWORD:
                            if (permission.object === PermissionObjects.SHARE && permission.action === PermissionActions.SHARE_LINK_PASSWORD) {
                                permission.subValue = val;
                            }
                            break;
                        case FieldIdentities.CANSHAREFOLDERS:
                            if (permission.object === PermissionObjects.FOLDERS && permission.action === PermissionActions.SHARE_FOLDERS) {
                                permission.value = val;
                            }
                            break;
                    }
                }
            }
        });
        try {
            if (this.isAddNewRole) {
                await this.multiadminService.addRole(this.roleObject);
            } else {
                await this.multiadminService.addUpdateRole(
                    oldRoleObject,  // before update role object
                    this.roleObject // updated role object
                );
            }
            await this.getRolesList();
            this.createAlert('success', 'Role ' + (this.isAddNewRole ? 'saved.' : 'updated.'));
            this.spinner = false;
            this.roleEditorOpen = false;
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
            this.spinner = false;
        }
    }
    public hasEmptySlots() {
        return (this.totalUsers < this.limit);
    }

    public openSettings(child: MultiAdminUserData, openState: number) {
        const ref = this.modalService.open(DialogMultiAdminuserSettingsComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.componentInstance.user = this.user;
        ref.componentInstance.rolesList = this.filteredRoleList;
        ref.componentInstance.child = child;
        ref.componentInstance.openState = openState;
        ref.componentInstance.multiadminService = this.multiadminService;

        ref.result.then((result: ModalCloseStates) => {
            if (result === ModalCloseStates.REMOVED || result === ModalCloseStates.USER_ROLE_UPDATED) {
                this.getList();
                this.createAlert('success', result === ModalCloseStates.USER_ROLE_UPDATED ? 'User role updated successfully.' : 'Removed successfully.');
            }
            if (result === ModalCloseStates.PASSWORD_UPDATED) {
                this.createAlert('success', 'Password updated successfully.');
            }
        });
    }

    public async resendInviteEmail(child: MultiAdminUserData) {
        this.spinner = true;
        try {
            const resent = await this.multiadminService.resendInviteEmail(child.user_id, child.email, child.roles.toString());
            if (resent.success) {
                this.createAlert('success', 'Email sent');
            }
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    public async resenduserTeamInviteEmail(child: MultiAdminUserData) {
        this.spinner = true;
        try {
            const resent = await this.multiadminService.resenduserTeamInviteEmail(child);
            resent.success ? this.createAlert('success', 'User invite sent') : this.createAlert('danger', 'User invite failed');
        } catch (ex) {
            this.log.error(ex);
            this.errcode = ErrCode.fromException(ex);
        }
        this.spinner = false;
    }

    private createAlert(type, message) {
        this.alerts.push({
            type: type,
            msg: message
        });
        setTimeout(() => {
            this.closeAlert();
        }, 5000);
    }

    public addUser(modalType: 'INVITE_USERS' | 'PURCHASE_USERS') {
        if (this.limit > 0) {
            const ref = this.modalService.open(DialogMultiAdminuserAddComponent, {
                backdropClass: 'in',
                windowClass: 'in',
                backdrop: 'static'
            });
            ref.componentInstance.hasEmptySlots = this.hasEmptySlots();
            ref.componentInstance.rolesList = this.filteredRoleList;
            ref.componentInstance.plan_user_limit = this.limit;
            ref.componentInstance.plan_product_id = this.plan_product_id;
            ref.componentInstance.plan_rate_plan_id = this.plan_rate_plan_id;
            ref.componentInstance.plan_rate_plan_charge_id = this.plan_rate_plan_charge_id;
            ref.componentInstance.remainingUsersAmount = this.limit - this.totalUsers;
            ref.componentInstance.multiadminService = this.multiadminService;
            ref.componentInstance.currentUser = this.currentUser;
            ref.componentInstance.currentList = this.currentList;
            if (modalType === 'INVITE_USERS') {
                ref.componentInstance.openState = 4;
                ref.result.then(() => this.getList());
            } else {
                ref.componentInstance.openState = 3;
            }

        } else {
            this.router.navigate(['/account/upgrade']);
        }
    }

    public redirectToUsers(selectedRole: string) {
        this.router.navigate(['/multi-user/users'], { queryParams: { selectedRole: selectedRole } });
    }

    public selectAllActive() {
        this.UserSelect.selectAll(this.UserSelect.selectTypes.active);
        this.checkSelected();
    }

    public selectAllInvited() {
        this.UserSelect.selectAll(this.UserSelect.selectTypes.invited);
        this.checkSelected();
    }

    public unselectAll() {
        this.isUserSelected = false;
        this.UserSelect.selectAll(this.UserSelect.selectTypes.all, false);
        this.checkSelected();
    }

    public isSelectionDisabled(userStatus: string): boolean {
        if (this.selectedType) {
            if (this.selectedType === this.UserSelect.selectTypes.active) {
                return userStatus.toLowerCase() !== this.UserSelect.selectTypes.active;
            } else {
                return userStatus.toLowerCase() === this.UserSelect.selectTypes.active;
            }
        }
        return false;
    }

    public selectToggle(user) {
        if (this.isSelectionDisabled(user.user_status_str)) {
            return;
        }
        this.UserSelect.toggle(user);
        this.checkSelected();
    }

    private checkSelected() {
        this.selectedUsers = this.UserSelect.getSelected();
        this.isUserSelected = this.selectedUsers.length > 0;
        if (this.isUserSelected) {
            if (this.selectedUsers[0].user_status_str.toLowerCase() === this.UserSelect.selectTypes.active) {
                this.selectedType = this.UserSelect.selectTypes.active;
            } else {
                this.selectedType = this.UserSelect.selectTypes.invited;
            }
        } else {
            this.selectedType = '';
        }
    }


    public openRemoveUsersModal = () => {
        this.showRemoveUsersModal = true;
    }

    public closeRemoveUsersModal = () => {
        this.showRemoveUsersModal = false;
        this.removeUsersAlert = null;
    }

    public removeUsers = async () => {
        this.spinner = true;
        const deletedUsers: string[] = [];
        const errorUsers: string[] = [];
        for (const user of this.selectedUsers) {
            try {
                if (user.user_status_str === 'invited' || user.user_status_str === 'declined' || user.user_status_str === 'error' || (user.isExpired && user.user_status_str !== 'email sent')) {
                    await this.multiadminService.deleteProvisioningUser(user.job_id);
                    deletedUsers.push(user.email);
                } else if (user.user_status_str === 'email sent' || user.user_status_str === 'Active' || (user.isExpired && user.user_status_str !== 'invited')) {
                    await this.multiadminService.deleteProvisionedUser(user.user_id, user.roles);
                    await this.api.execute('multiuserupdate', {
                        action: user.user_status_str === 'email sent' ? 'suspenduser' : 'remove',
                        email: user.email,
                        is_purge: parseInt(this.rmPurge, 10)
                    });
                    deletedUsers.push(user.email);
                } else {
                    errorUsers.push(user.email);
                }
            } catch (ex) {
                this.log.error('Exception occured while removing user: ' + user.user_id);
                errorUsers.push(user.email);
            }
        }
        this.spinner = false;
        this.rmPurge = '0';
        this.getList();
        if (deletedUsers.length === this.selectedUsers.length) {
            setTimeout(() => {
                this.closeRemoveUsersModal();
            }, 5000);
            if (deletedUsers.length > 5) {
                this.removeUsersAlert = new AlertMessage(`${deletedUsers.length} users are removed.`);
            } else {
                this.removeUsersAlert = new AlertMessage(deletedUsers.length > 1 ?
                    `${deletedUsers.slice(0, -1).join(', ') + ' and ' + deletedUsers.slice(-1)} are removed.` :
                    `${deletedUsers[0]} is removed.`);
            }
        } else {
            if (errorUsers.length > 5) {
                this.removeUsersAlert = new AlertMessage(`${errorUsers.length} users are failed to remove`, AlertMessageType.ERROR);
            } else {
                this.removeUsersAlert = new AlertMessage(errorUsers.length > 1 ?
                    `Failed to remove users- ${errorUsers.slice(0, -1).join(', ') + ' and ' + errorUsers.slice(-1)}` :
                    `Failed to remove user- ${errorUsers[0]}`, AlertMessageType.ERROR);
            }
        }
    }

    public getSelectedUserEmails() {
        if (!this.selectedUsers.length) {
            return '';
        }
        return this.selectedUsers.slice(0, -1).map(user => user.email).join(', ') + ' and ' + this.selectedUsers.slice(-1)[0].email;
    }

    public openChangeRolesModal() {
        this.showChangeRolesModal = true;
    }

    public closeChangeRolesModal = () => {
        this.showChangeRolesModal = false;
        this.updateUsersAlert = null;
        this.role = this.filteredRoleList.length ? this.filteredRoleList[0].name : '';
    }
    public async updateUserRole() {
        this.spinner = true;
        try {
            const userRoles = this.selectedUsers.map(user => {
                return { user_id: user.user_id, roleName: user.roles[0] };
            });
            const result = await this.multiadminService.updateMultipleUsersRole(userRoles, this.role);
            this.spinner = false;
            this.getList();
            if (result.success) {
                setTimeout(() => {
                    this.closeChangeRolesModal();
                }, 5000);
                if (this.selectedUsers.length > 5) {
                    this.updateUsersAlert = new AlertMessage(`${this.selectedUsers.length} users role updated successfully.`);
                } else {
                    this.updateUsersAlert = new AlertMessage(this.selectedUsers.length > 1 ?
                        `${this.selectedUsers.slice(0, -1).map(user => user.email).join(', ') + ' and ' + this.selectedUsers.slice(-1).map(user => user.email)} users role updated successfully.` :
                        `${this.selectedUsers[0].email} user role updated successfully.`);
                }
            } else {
                this.updateUsersAlert = new AlertMessage(`Failed to update role for select users`, AlertMessageType.ERROR);
            }
        } catch (ex) {
            this.spinner = false;
            this.getList();
            this.log.error('Exception occured while updating role' + JSON.stringify(ex));
            this.updateUsersAlert = new AlertMessage(`Failed to update role for select users`, AlertMessageType.ERROR);
        }
    }

    /**
     * Whether to show Remove user invite or not
     * @param person - User detail
     * @returns boolean
     */
    public removeUserInvite(person) {
        const isExpired = (person.isExpired && person.user_status_str !== 'email sent');
        const isLegacyToCNC = person.isLegacyToCNC;
        return ((['invited', 'declined', 'error'].includes(person.user_status_str) || isExpired) && !isLegacyToCNC);
    }
}
