<div class="modal-header">
    <button type="button" class="close" (click)="close.emit(false)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
        <span>Create Link - {{ item.name }}</span>
    </h4>
</div>

<form autocomplete="off" (submit)="linkPreSettingsForm.valid && onSubmit()" [formGroup]="linkPreSettingsForm"
    method="POST" ngNativeValidate>
    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="row">
            <div class="col-sm-12">
                <b>Settings</b>
            </div>
        </div>
        <div *ngIf="isPro && item.type == 'dir'">
            <div class="row">
                <div class="col-sm-4">
                    Upload permissions:<br />(Users can send you files)
                </div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input type="radio" id="link-settings-upload-disabled" value="0" formControlName="upload" />
                            Disabled
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="radio" id="link-settings-upload-show" value="1" formControlName="upload" />
                            Enabled (show files in folder)
                        </label>
                    </div>
                    <div class="radio radio-top">
                        <label>
                            <input type="radio" id="link-settings-upload-hide" value="2" formControlName="upload" />
                            Enabled (hide files in folder)
                        </label>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div *ngIf="isPro && linkPreSettingsForm.value.upload !== '2'">
            <div class="row">
                <div class="col-sm-4">
                    File permissions:
                    <br />
                </div>
                <div class="col-sm-8">
                    <div class="radio radio-top">
                        <label>
                            <input type="radio" id="link-settings-preview-view" value="preview"
                                formControlName="previewonly" />
                            View only (no download)
                        </label>
                    </div>
                    <div class="radio radio">
                        <label [ngClass]="">
                            <input type="radio" id="link-settings-preview-download" value="download"
                                formControlName="previewonly" />
                            View and download
                        </label>
                    </div>
                    <!-- show the edit file radio button if file is not shared and edit feature is allowed -->
                    <div class="radio radio-top" *ngIf="item.type != 'file' || this.isMSOfficeFile">
                        <!-- disable edit file radio button if upload setting is disabled on dir -->
                        <fieldset
                            [disabled]="(item.type == 'dir' && linkPreSettingsForm.value.upload === '0') || item.is_shared"
                            [class.disable-label]="(item.type == 'dir' && linkPreSettingsForm.value.upload === '0') || item.is_shared">
                            <label>
                                <input type="radio" id="link-settings-preview-edit" value="edit"
                                    formControlName="previewonly" />
                                View, download and edit with MS Office*
                            </label>
                        </fieldset>
                        <i *ngIf="!item.is_shared">
                            <br />
                            *Edit available for authenticated users only
                        </i>
                        <i *ngIf="item.is_shared" [class.disable-label]="item.is_shared">
                            <br />
                            *This link cannot be made editable as it resides within a
                            shared folder. Links within shared folders are restricted from editing to maintain
                            collaborative integrity and data security.
                        </i>
                    </div>
                </div>
            </div>
            <hr />
        </div>
        <div class="row">
            <div class="col-sm-4">
                Link password:
                <br *ngIf="linkEnforcedSettings.isLinkPasswordRequired" />
                <i *ngIf="linkEnforcedSettings.isLinkPasswordRequired">Required</i>
            </div>
            <div class="col-sm-8 cover">
                <input type="password" id="link-precreate-settings-password" placeholder="Enter password"
                    class="form-control" formControlName="password" syncShowHidePassword identity="linkpass" />
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-sm-4">
                Expiry date:
                <br *ngIf="linkEnforcedSettings.isLinkExpiryDateRequired" />
                <i *ngIf="linkEnforcedSettings.isLinkExpiryDateRequired">Required</i>
            </div>
            <div class="col-sm-4">
                <div class="input-group">
                    <input type="text" name="expiryDate" id="link-settings-expiry" ngbDatepicker
                        placeholder="No expiry date" class="form-control" formControlName="expiryDate"
                        autocomplete="off" [minDate]="expDateMin" [startDate]="expDateMin" #d="ngbDatepicker"
                        (dateSelect)="onDateSelection()" />
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default" (click)="d.toggle()">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </span>
                </div>
                <a *ngIf="linkPreSettingsForm.get('expiryDate').value" class="showhand" (click)="clearExp()">Clear
                    expiry</a>
            </div>
            <div class="col-sm-4">
                <ngb-timepicker formControlName="expiryTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" id="save-settings-dialog-btn"
            [disabled]="!linkPreSettingsForm.valid">
            Create link
        </button>
        <button type="button" class="btn btn-default" id="close-manage-link" (click)="close.emit(false)">
            Close
        </button>
    </div>
</form>