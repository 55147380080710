import { Deserializable } from './deserializable.model';

export class Field<T> implements Deserializable<Field<T>> {
    value: T | undefined;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    disabled: boolean;
    options: { value: T, key: string }[];
    hidden: boolean;
    subFieldContext: string;
    subOf: string;
    subFields: Field<T>[];

    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        disabled?: boolean;
        options?: { value: T, key: string }[];
        hidden?: boolean;
        subFieldContext?: string;
        subOf?: string;
        subFields?: Field<T>[];
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.disabled = options.disabled;
        this.options = options.options || [];
        this.hidden = options.hidden;
        this.subFieldContext = options.subFieldContext || '';
        this.subOf = options.subOf || '';
        this.subFields = options.subFields || [];
    }

    public deserialize(params: any) {
        for (const [key, val] of Object.entries(params)) {
            if (key in this) {
                this[key] = val;
            }
        }
        return this;
    }
}

export type StandardField = Field<string | number | boolean>;
