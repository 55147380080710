<sync-header-previews [item]="items.current">
    <div class="hamburger-menu-item visible-xs" hamburgerMenu>
        <sync-preview-menu
            *ngIf="item.previewonly != 8"
            [item]="items.current"
            [url]="transferView?.single?.renderFile?.img_url || transferView?.single?.renderFile?.url || items?.current?.compaturl"
        ></sync-preview-menu>
    </div>
</sync-header-previews>

<!-- CP TEMPLATE -->
<div
    *ngIf="items.current.context != 'link' && item.context != 'applink'"
    class="container-fluid container-fluid-wrap container-fluid-wrap-preview"
>
    <sync-preview-menu
        *ngIf="item.previewonly != 8"
        [item]="items.current"
        [url]="transferView?.single?.renderFile?.img_url || transferView?.single?.renderFile?.url"
        [ngClass]="{
            'dropdown-preview-image': items.amount != 1
        }"
    ></sync-preview-menu>

    <sync-error [errcode]="errcode"></sync-error>

    <div
        class="preview-wrap preview-wrap-no-overflow"
        [ngClass]="{
            'preview-wrap-fullscreen': fullScreen
        }"
    >
        <div
            *ngIf="fullScreen"
            style="
                position: absolute;
                color: white;
                padding: 8px;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.6);
                font-size: 15px;
                margin: 16px;
            "
        >
            {{items.current.name}}
        </div>
        <div
            *ngIf="fullScreen"
            style="
                position: absolute;
                right: 0;
                color: white;
                padding: 8px 16px 8px 16px;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.6);
                font-size: 15px;
                margin: 16px;
            "
        >
            <i
                (click)="fullScreen = !fullScreen"
                class="fas fa-times showhand"
            ></i>
        </div>

        <div class="preview-wrap-icon">
            <div class="preview-wrap-icon-inner">
                <img
                    *ngIf="transferView?.single?.renderFile?.dl_ready &&  !item.previewonly"
                    [src]="imageURL"
                    class="preview-wrap-img img-responsive"
                    [ngClass]="{'preview-wrap-img-fullscreen' : fullScreen }"
                />

                <img
                    *ngIf="item.context == 'applink'"
                    [src]="imageURL"
                    class="preview-wrap-img img-responsive"
                    [ngClass]="{'preview-wrap-img-fullscreen' : fullScreen }"
                />

                <img
                    *ngIf="transferView?.single?.renderFile?.dl_ready && item.previewonly"
                    [src]="imageURL"
                    class="preview-wrap-img img-responsive"
                    syncPreventRightClick
                />

                <div *ngIf="!initialized" class="preview-loading-wrap">
                    <i class="fa fa-spin fa-spinner"></i>
                    Loading ...
                </div>
            </div>
        </div>
        <div
            *ngIf="items.amount > 1"
            class="preview-image-nav"
            [ngClass]="{
                'preview-image-nav-fullscreen': fullScreen
            }"
        >
            <i
                (click)="navigatePrev()"
                class="fa fa-arrow-circle-left showhand"
                aria-hidden="true"
            ></i>
            <i
                (click)="navigateNext()"
                class="fa fa-arrow-circle-right preview-image-right-arrow showhand"
                aria-hidden="true"
            ></i>
            <div class="preview-image-nav-count">
                {{items.idx}} of {{items.amount}}
            </div>
            <div class="pull-right">
                <i
                    *ngIf="!fullScreen && !item.previewonly"
                    (click)="open()"
                    class="showhand zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg hidden-md hidden-lg"
                ></i>
                <i
                    *ngIf="!fullScreen && !item.previewonly"
                    (click)="downloadOriginal()"
                    class="showhand zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg hidden-md hidden-lg"
                ></i>

                <i
                    class="zmdi zmdi-hc-fw zmdi-hc-lg showhand"
                    [ngClass]="{
                        'zmdi-fullscreen': !fullScreen,
                        'zmdi-fullscreen-exit': fullScreen
                    }"
                    placement="top"
                    ngbTooltip="Full screen"
                    (click)="fullScreen = !fullScreen"
                ></i>
            </div>
        </div>
    </div>
</div>
<!-- /container -->
<!-- / CP TEMPLATE -->

<!-- LINK CONSUME TEMPLATE -->
<div
    *ngIf="items.current.context == 'link' || item.context == 'applink'"
    class="container-fluid preview-container"
>
    <div class="row">
        <!-- MAIN CONTENT COL -->
        <div
            class="col-md-8 col-lg-9 preview-wrap-no-margin preview-wrap-no-overflow"
            [ngClass]="{
                        'preview-wrap-fullscreen': fullScreen
                        }"
        >
            <!-- <div
                class="preview-wrap preview-wrap-no-overflow"
                [ngClass]="{
                        'preview-wrap-fullscreen': fullScreen
                        }"
            > -->
            <sync-error [errcode]="errcode"></sync-error>
            <div
                *ngIf="fullScreen"
                style="
                    position: absolute;
                    color: white;
                    padding: 8px;
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, 0.6);
                    font-size: 15px;
                    margin: 16px;
                "
            >
                {{items.current.name}}
            </div>
            <div
                *ngIf="fullScreen"
                style="
                    position: absolute;
                    right: 0;
                    color: white;
                    padding: 8px 16px 8px 16px;
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, 0.6);
                    font-size: 15px;
                    margin: 16px;
                "
            >
                <i
                    (click)="fullScreen = !fullScreen"
                    class="fas fa-times showhand"
                ></i>
            </div>

            <div class="preview-wrap-icon">
                <div class="preview-wrap-icon-inner">
                    <img
                        *ngIf="transferView?.single?.renderFile?.dl_ready &&  !item.previewonly"
                        [src]="imageURL"
                        class="preview-wrap-img img-responsive"
                        [ngClass]="{'preview-wrap-img-fullscreen' : fullScreen }"
                    />

                    <img
                        *ngIf="item.context == 'applink' && !item.previewonly"
                        [src]="imageURL"
                        class="preview-wrap-img img-responsive"
                        [ngClass]="{'preview-wrap-img-fullscreen' : fullScreen }"
                    />

                    <img
                        *ngIf="transferView?.single?.renderFile?.dl_ready && item.previewonly"
                        [src]="imageURL"
                        class="preview-wrap-img img-responsive"
                        syncPreventRightClick
                    />

                    <div
                        *ngIf="!initialized"
                        class="preview-loading-wrap preview-wrap-img"
                    >
                        <i class="fa fa-spin fa-spinner"></i>
                        Loading ...
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- / MAIN CONTENT COL -->

        <div class="col-md-4 col-lg-3">
            <sync-preview-menu
                *ngIf="item.previewonly != 8"
                [item]="items.current"
                [url]="transferView?.single?.renderFile?.img_url || transferView?.single?.renderFile?.url || items?.current?.compaturl"
                class="hidden-xs"
            ></sync-preview-menu>
            <!-- GALLERY NAV TOOL -->
            <div
                *ngIf="items.amount > 1"
                class="right-menu-dropdown-menu dropdown-menu preview-image-nav-link"
                [ngClass]="{
                            'preview-image-nav-fullscreen': fullScreen
                        }"
            >
                <i
                    (click)="navigatePrev()"
                    class="fa fa-arrow-circle-left showhand"
                    aria-hidden="true"
                ></i>
                <i
                    (click)="navigateNext()"
                    class="fa fa-arrow-circle-right preview-image-right-arrow showhand"
                    aria-hidden="true"
                ></i>
                <div class="preview-image-nav-count">
                    {{items.idx}} of {{items.amount}}
                </div>
                <div class="pull-right">
                    <i
                        *ngIf="!fullScreen && !item.previewonly"
                        (click)="open()"
                        class="showhand zmdi zmdi-open-in-new zmdi-hc-fw zmdi-hc-lg hidden-md hidden-lg"
                    ></i>
                    <i
                        *ngIf="!fullScreen && !item.previewonly"
                        (click)="downloadOriginal()"
                        class="showhand zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-lg hidden-md hidden-lg"
                    ></i>

                    <i
                        class="zmdi zmdi-hc-fw zmdi-hc-lg showhand"
                        [ngClass]="{
                            'zmdi-fullscreen': !fullScreen,
                            'zmdi-fullscreen-exit': fullScreen
                        }"
                        placement="top"
                        ngbTooltip="Full screen"
                        (click)="fullScreen = !fullScreen"
                    ></i>
                </div>
            </div>
            <!-- / GALLERY NAV TOOL -->
            <!-- comment feature -->
            <sync-comment-block
                *ngIf="allowComment"
                [item]="items.current"
                class="xs-fixed-comment"
                [ngClass]="isCommentExpanded ? 'expanded' : ''"
                [ontoggle]="onCommentToggle"
            ></sync-comment-block>
        </div>
    </div>
</div>
<!-- /container -->
