import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '../../../core/logger.service';
import { AvatarUser } from '../../models/avatar-user.model';

@Component({
  selector: 'sync-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent implements OnInit {

  /**
  * @property {AvatarUser} user
  * User to display in Avatar.
  */
  @Input() user: AvatarUser;

  /**
  * @property {number} avatarIndex
  * If passed then respective avatar stack class with background and color will be applied. Otherwise default class will be applied.
  */
  @Input() avatarIndex: number;

  /**
  * @property {number} overflowCount
  * If passed then given number will be displayed as overflowing avatar.
  */
  @Input() overflowCount: number;

  /**
  * @property {boolean} disabled
  * Flag to show avatar as disabled
  * Default value is false. passing this value true will apply disabled class to avatar and popover info
  */
  @Input() disabled: boolean;

  public avatarEndpoint = `${environment.logohost}avatar/saved/`;
  public avatarUrl: string;
  public initial: string;
  constructor(
    private log: LoggerService,
  ) { }

  async ngOnInit() {
    if (this.user) {
      this.initial = this.user.decodedDisplayName[0];
      if (this.user.userid) {
          this.avatarUrl = `${this.avatarEndpoint}${this.user.userid}`;
      }
    }
  }

  handleMissingImage() {
    this.avatarUrl = '';
  }

}
