import { ErrCode } from '../err-code.model';
import { PubkeyData } from '../pubkey-data.model';
import { ShareKeyData } from '../share-key-data.model';
import { BaseApiOutput } from './base-api.model';

export class KeysNeedShareKeysApiOutput extends BaseApiOutput {
    share_id: number;
    invite_amount: number;
    enc_share_keys: ShareKeyData[];
    pubkeys: PubkeyData[];
    enc_password?: string;
}

export class KeysNeedShareKeysApiInput {
    sync_id: number;
}


